import * as Yup from "yup";
import { formattedPhoneNumberLength } from "../../utils/phoneNumberSupport";
import moment from "moment";

const SUPPORTED_FORMATS = ["image/jpg", "image/png", "image/jpeg", "image/gif"];
const FILE_SIZE = 160 * 1024;
export const FormikObj = (practitioner) => ({
    initialValues: {
        first_name: "",
        last_name: "",
        date_of_birth: "",
        phone_number: "",
        ext: "",
        address: "",
        city: "",
        country: "",
        region: "",
        postalCode: "",
        file: null,
        profileImage: null,
        id: "",
        designation:"",
        qualification:"",
        license:"",
        home_phone_number:""

    },
    validationSchema: Yup.object({
        first_name: Yup.string()
            .max(15, "Must be 15 characters or less")
            .required("Required first name field")
            .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
        last_name: Yup.string()
            .max(15, "Must be 15 characters or less")
            .required("Required last name field")
            .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
        date_of_birth: Yup.string().when("type", (type, schema) =>{
            if (practitioner === true) {
            return schema
                .required('Required date of birth field')
                .test('min-age', 'Age cannot be below 16 years. Please provide a valid age.', function (value) {
                const currentDate = moment();
                const birthDate = moment(value, /* 'YYYY-MM-DD' */  'MM-DD-YYYY'); 
                const age = moment.duration(currentDate.diff(birthDate)).years();
                return age >= 16;
                });
            }
            else {
            return schema.required('Required date of birth field');
            }
        }),
        phone_number: Yup.string()
        .matches(/^(?:\+?1[-. ]?)?\(?[0-9]\d{2}\)?[-. ]?\d{3}[-. ]?\d{4}$/, "Enter Valid Work Phone Number."),
        home_phone_number: Yup.string()
        .matches(/^(?:\+?1[-. ]?)?\(?[0-9]\d{2}\)?[-. ]?\d{3}[-. ]?\d{4}$/, "Enter Valid Home Phone Number."),
        // TODO Multilingual Support
        ext: Yup.string()
            .matches(/^\d{0,4}$/, 'Must be 0 to 4 digits.'),
        address: Yup.string()
            .required("Required address field")
            .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
        city: Yup.string()
            .required("Required city field")
            .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
        postalCode: Yup.string().when("country", (country, schema) =>
        country === "US"
            ? Yup.number().typeError("Zip code must be a number").required("Required field")
             : Yup.string().required("Required field").matches(/^[A-Za-z]\d[A-Za-z] [0-9][A-Za-z]\d$/, "Postal code must be in A1A 1A1 format.")
            ),
        country: Yup.string()
            .required("Required country field")
            .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
        region: Yup.string()
            .required("Required region field")
            .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),

        qualification: Yup.string()
            .max(50, "Must be 50 characters or less"),
        license: Yup.string()
            .max(20, "Must be 20 characters or less"),


        file: Yup.mixed()
            .nullable()
            .notRequired()
            .test("FILE_SIZE", "Uploaded file is too big.", (value) => !value || (value && value.size <= FILE_SIZE))
            .test("FILE_FORMAT", "Uploaded file has unsupported format.", (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))),
        profileImage: Yup.mixed()
            .nullable()
            .notRequired()
            .test("FILE_SIZE", "Uploaded file is too big.", (value) => !value || (value && value.size <= FILE_SIZE))
            .test("FILE_FORMAT", "Uploaded file has unsupported format.", (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))),
    }),
})