import { useQuery } from "react-query";
import { onError } from "../ReactQueryHooks/onError";
import { axios } from "../../lib/axios";

export function useThresholdPatientListQuery({ patientIds, onSuccess }) {
    const fetch = (patientIds) => {
        return axios.post(`threshold/view-patients`, { patientIds })
    }

    const query = useQuery("thresholdPatientList", () => fetch(patientIds),
        {
            select: (data) => data?.data,
            onSuccess,
            onError
        }
    );

    return { ...query }
}