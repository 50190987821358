/**
 * Splits a contact number into phone number and extension parts.
 *
 * @param {QuestionResponse} item - the question to investigate to see if answered. '.
 * @returns {boolean} indication of whether or not question is answered.
 */
export const isAnswered = (item) => {
    let answered = [];

    switch(item.questionType) {
        case "checkbox":
            answered = item.answer.filter(option => option.answer == true);
            break;
        case "freeText":
            answered = item.answer.filter(option => {
                return (option.answer.toString().trim().length > 0 && option.answer !== "<p><br></p>")
            });
            break;
        case "dropDown":
            answered = item.answer.filter(option => {
                return option.answer.toString().trim().length > 0
            });
            break;
        case "radio":
            answered = item.answer.filter(option => option.answer == true);
            break;
        default:
                break;
    }
    return answered.length > 0
}