import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import TextInput from "../../common/textfield/TextInput";
import SelectFiled from "../../common/textfield/SelectFiled";
import { useNavigate } from "react-router-dom";
import { Button } from "../../common/Button";
import { failed, success } from "../../common/Toastify";
import CountrySelect from "../../common/textfield/CountrySelect";
import RegionSelect from "../../common/textfield/RegionSelect";
import { FormikObj,COUNTRY,POSTAL_CODE,ZIP_CODE} from "./Constants";
import { useOrgQuery } from "../../../hooks/ReactQueryHooks/useOrgQuery";
import { createClinicLocation } from "../../api/ClinicLocation";
import TextArea from "../../common/textfield/TextArea";
import { Switch, Tooltip } from "@mui/material";
import { ArrowBack, Padding } from "@mui/icons-material";
import { createContactNumber } from "../../../utils/phoneNumberSupport";
import {categoryType} from "./Constants"
import "./Style.css"
import { useSelector } from "react-redux";

function AddClinicLocation() {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
  const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)
  const admin = userRoles?.includes('Super-Admin');

  const onSuccessOrg = (data) => {
    setOrganizations(data?.map((org) => (org = { ...org, value: org.id })));
  };
  useOrgQuery({ onSuccess: onSuccessOrg });

  useEffect(() => {
    if(!admin) {
      formik.setFieldValue("orgId",adminOrgs?.[0]?.id)
    }
  },[])

  const formik = useFormik({
    ...FormikObj,
    onSubmit: (values) => {
      values.postalCode = values?.postalCode?.toUpperCase();
      values.phone_number = createContactNumber(values);
      setBtnLoading(true);      
      createClinicLocation({
        ...values,
        orgName: organizations.filter((org) => org?.id === values?.orgId)?.[0]
          ?.name,
      })
        .then((res) => {
          navigate("/app/manage-clinic-locations");
          success(res.message);
        })
        .catch((res) =>
          failed(
            res?.response?.data?.message ||
              res?.response?.data?.error ||
              res.message
          )
        )
        .finally(() => setBtnLoading(false));
    },
  });
  const handleCountryChange = (newCountry) => {
    setCountry(newCountry);
    if (newCountry !== formik.values.country) {
      formik.setFieldValue("state", ""); // Reset Formik state only if the country is changing
      formik.setFieldValue("postalCode", "");
     // formik.setFieldValue("city", "");
      setRegion(""); // Reset component state only if the country is changing
      setInputValue("")
    }
  };
  const handlePostalCodeChange = (e) => {
    const { value } = e.target;
    // Remove spaces and non-alphanumeric characters
    const formattedValue = value.replace(/[^A-Za-z0-9]/g, "");
    // Add a space after every 3 letters
    const spacedValue = formattedValue.replace(
      /^([A-Za-z0-9]{3})([A-Za-z0-9]+)/,
      "$1 $2"
    );
    // Set the formatted value in the state or formik
    setInputValue(spacedValue);
    formik?.setFieldValue("postalCode", spacedValue);
  };

  const handleChangeCategory = (event)=>{
    let value = event.target.value
    let code
    if(value === "Lab") code = "MBL"
    else if(value === "Diagnostic") code = "RADDX"
    else if(value === "Service") code = "DX"
    else if(value === "Health Facility") code = "HOSP"
    else  code = "PHARM"
    formik?.setFieldValue("categoryType", value);
    formik?.setFieldValue("code", code);
  }

  const handleSFTToggle = (event)=>{
    formik?.setFieldValue("sftEnabled", event.target.checked);
  }

  return (
    <section className="common-listing">
      <div className="heading-wrap mb-3">
        <Tooltip title="Go back">
          <div
            style={{ marginRight: "1rem", cursor: "pointer" }}
            onClick={() => navigate(-1)}
          >
            <ArrowBack />
          </div>
        </Tooltip>
      </div>
      <div className="custom-card p-4">
        <form
          className="common-form border-fields"
          onSubmit={formik.handleSubmit}
        >
          <Row>
            {admin && (
              <Col>
              <SelectFiled
                keyField={"orgId"}
                label={"Select Organization"}
                formik={formik}
                options={organizations}
              />
            </Col>
            )}
            <Col>
              <TextInput
                keyField={"name"}
                label={"Name"}
                formik={formik}
                placeholder={"Name"}
              />
            </Col>
            <Col>
              <SelectFiled
                keyField={"categoryType"}
                label={"Type"}
                formik={formik}
                options={categoryType}
                onChange={handleChangeCategory}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextArea
                keyField={"description"}
                label={"Description"}
                formik={formik}
                placeholder={"Description..."}
              />
            </Col>
          </Row>
          <Row>
            <Col>Address Details</Col>
            <Col>Contact Details</Col>
          </Row>
          <div className="address-details-block mt-3">
            <div className="left-block">
              <Row>
                <Col>
                  <TextInput
                    keyField={"address"}
                    label={"Address"}
                    formik={formik}
                    placeholder={"Address"}
                  />
                </Col>
                <Col>
                <CountrySelect
                    country={country}
                    setCountry={handleCountryChange}
                    keyField={"country"}
                    label={COUNTRY}
                    required
                    formik={formik}
                  />
                </Col>
              </Row>
              <Row>
              <Col>
                  <TextInput
                    keyField={"city"}
                    label={"City"}
                    formik={formik}
                    placeholder={"City"}
                  />
                </Col>
                <Col>
                  <RegionSelect
                    country={country}
                    setRegion={setRegion}
                    region={region}
                    keyField={"state"}
                    defaultOptionLabel={
                      formik?.values?.country === "US"
                        ? "Select State"
                        : "Select Province"
                    }
                    label={
                      formik?.values?.country === "US" ? "State" : "Province"
                    }
                    required
                    formik={formik}
                  />
                </Col>
                <Col>
                  {formik?.values?.country === "US" ? (
                    <TextInput
                      keyField={"postalCode"}
                      label={
                        formik?.values?.country === "US" ? ZIP_CODE : POSTAL_CODE
                      }
                      formik={formik}
                      placeholder={
                        formik?.values?.country === "US" ? ZIP_CODE : POSTAL_CODE
                      }
                      maxLength={formik?.values?.country === "US" ? "5" : "7"}
                    />
                  ) : (
                    <TextInput
                      onChange={handlePostalCodeChange}
                      value={inputValue}
                      keyField={"postalCode"}
                      label={
                        formik?.values?.country === "US" ? ZIP_CODE : POSTAL_CODE
                      }
                      formik={formik}
                      placeholder={
                        formik?.values?.country === "US" ? ZIP_CODE : POSTAL_CODE
                      }
                      maxLength={formik?.values?.country === "US" ? "5" : "7"}
                    />
                  )}
                </Col>
              </Row>
            </div>
            <div className="right-block">
                <Row>
                  <Col>
                  <TextInput
                    keyField={"phone_number"}
                    type="phone"
                    label={"Phone Number"}
                    formik={formik}
                    placeholder={"Phone Number"}
                  />
                  </Col>
                  <Col>
                  <TextInput
                    keyField={"ext"}
                    type="ext"
                    label={"Ext."}
                    formik={formik}
                    placeholder={"Ext"}
                    required={false}
                  />
                  </Col>
                </Row>
                <Row>
                <Col>
                    <TextInput
                      keyField={"email"}
                      label={"Email Address"}
                      formik={formik}
                      placeholder={"Email Address"}
                      required={false}
                    />
                  </Col>
                  
                  <Col>
                    <TextInput
                      keyField={"fax"}
                      type="fax"
                      label={"Fax Number"}
                      formik={formik}
                      placeholder={"Fax Number"}
                      required={formik.values.sftEnabled ? true : false}
                    />
                  </Col>
                </Row>
            </div>
          </div>
          <Row className="mt-3">
            <Col>
              <label>Enable Secure File Transfer ( SFT ) </label>
              <Switch name="sftEnabled" onChange={handleSFTToggle} />
            </Col>
          </Row>
          <div className="btn-wrap" style={{ display: "flex" }}>
            <Button
              onClick={() => navigate("/app/manage-clinic-locations")}
              variant="secondary"
              title="Cancel"
            >
              Cancel
            </Button>
            <Button type="submit" isLoading={btnLoading}>
              Create
            </Button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default AddClinicLocation;
