import React, { useState } from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Checkbox from "../../common/textfield/Checkbox";
import { Form } from "react-bootstrap";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextInput from "../../common/textfield/TextInput";




export function SFTSettings({ formik, delivertoIndividualSwitch, onDeliverToIndSwitchChange, delivertoLocationSwitch, onDeliverToLocSwitchChange }) {
          
    return (
        <>
            {/* <Row>
                <Col>
                    <h5 style={{ color: "rgba(18, 31, 72, 1)" }}>SFT Settings</h5>
                </Col>
            </Row>

            <Row>
                <Col >
                    <FormControlLabel
                        control={
                            <>
                                <span style={{ marginLeft: '10px', marginRight: '8px' }}>SFT</span>
                                <span style={{ marginRight: '168px' }}>&nbsp;&nbsp;</span>
                                <Switch
                                    checked={sftSwitch}
                                    onChange={onSftSwitchChange} // Use the callback function
                                    color="primary"
                                />
                            </>
                        }
                    />
                </Col>
            </Row> */}
           
           
            <Row>
                <Col>
                    <FormControlLabel   
                        control={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ marginLeft: '10px', marginRight: '8px' }}>Delivery To Individual</span>
                                 <span style={{ marginRight: '25px' }}>&nbsp;&nbsp;</span>
                                <Switch
                                    checked={delivertoIndividualSwitch}
                                    onChange={onDeliverToIndSwitchChange}
                                    color="primary"
                                />
                            </div>
                        }
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormControlLabel
                        control={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ marginLeft: '10px', marginRight: '8px' }}>Delivery To Locations</span>
                                <span style={{ marginRight: '30px' }}>&nbsp;&nbsp;</span>
                                <Switch
                                    checked={delivertoLocationSwitch}
                                    onChange={onDeliverToLocSwitchChange}
                                    color="primary"
                                />
                            </div>
                        }
                    />
                </Col>
            </Row>
                    
        </>
    );
}
