import { axios } from "../../../../lib/axios";

export const createConditions = (payload) => {
    return axios.post(`/condition`, payload);
};

export const editCondition = ({ id, ...payload }) => {
    return axios.put(`/condition/${id}`, { ...payload });
};

export const getAllConditions = (patientId) => {
    return axios.get(`/condition?patientId=${patientId}`);
};

export const createSurgeryScreening = (payload) => {
    return axios.post(`/procedure`, payload);
};

export const editSurgeryScreening = ({ id, ...payload }) => {
    return axios.put(`/procedure/${id}`, { ...payload });
};

export const getAllSurgeryScreening = (patientId) => {
    return axios.get(`/procedure?patientId=${patientId}`);
};

export const createFamilyHistory = (payload) => {
    return axios.post(`/familyhistory`, payload);
};

export const editFamilyHistory = ({ id, ...payload }) => {
    return axios.put(`/familyhistory/${id}`, { ...payload });
};

export const getAllFamilyHistory = (patientId) => {
    return axios.get(`/familyhistory?patientId=${patientId}`);
};

export const createObservation = (payload) => {
    return axios.post(`/observation`, payload);
};

export const editObservation = ({ id, ...payload }) => {
    return axios.put(`/observation/${id}`, { ...payload });
};

export const getAllObservation = (patientId) => {
    return axios.get(`/observation?patientId=${patientId}&isSocialHistory=true`);
};