import React, { useEffect, useState } from "react";
import { useGetPatientByUsers } from "../../../hooks/ReactQueryHooks/useGetPatientById";
import { failed } from "../../common/Toastify";
import { decryptData, encryptData } from "../../EncryptDecrypt";
import { getPatientReadingLatest } from "../api/Patient";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./patients-detail.css";
import blankProfileImage from "../../../assets/images/blank-profile-picture.webp"
import { GetVitalsUnit } from "../Threashold/Constants";
import { ViewDate, safeAgeString } from "../../../utils/DateSupport";
import { EditIndividual } from "../../Admin/Individuals/edit-individual";
import { getSingleUser } from "../../api/Individual";
import { useNavigate } from "react-router-dom";
import { Hidden, Tooltip } from "@mui/material";
import { ConditionValueByOrgUnits } from "./Constants";
import { splitContactNumber } from "../../../utils/phoneNumberSupport.js";
import { getProgram } from "../../api/Program";
import EditProgram from "../../Admin/Program/EditProgram.js"
import { RelatedPerson } from "./Component/relatedPerson.js";
import { useOrgQuery } from "../../../hooks/ReactQueryHooks/useOrgQuery";
import moment from "moment";

const PatientCustomCard = ({from, isRPMEnable, patientName, patientId, patientEmail, orgVitalsUnit, disease, getPatientData, relatedPerson }) => {
    const [patientsData, setPatientsData] = useState([])  // for profile card
    const [patientImage, setPatientImage] = useState("")
    const [patientDOB, setPatientDOB] = useState(null);
    const [currentProgram, setCurrentProgram] = useState([])
    const [modalShow, setModalShow] = useState(false);
    const [organizations, setOrganizations] = useState([]);
    const [organizationsData, setOrganizationsData] = useState([]);
    const [programModalShow, setProgramModalShow] = useState(false);
    const [relatedPersonModalShow, setRelatedPersonModalShow] = useState(false);
    const navigate = useNavigate();
    const [patientData, setPatientData] = useState(false);
    const handleShow = (data) => {
        setModalShow(modalShow ? false : data);
    };
    const [currentProgramDetails, setCurrentProgramDetails] = useState([])
    const handleProgramShow = (programId) => {
        modalShow ?
            setProgramModalShow(false)
            :
            getProgram(programId)
                .then((res) => setProgramModalShow(res.result))
                .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message));
    }
    const handleRelatedPerson = () => {
        relatedPersonModalShow ? 
        setRelatedPersonModalShow(false)
        : setRelatedPersonModalShow(relatedPerson)
    }

    const handleView = () => {
        const apiBody = {
            id: patientId,
            type: "Patient"
        };
        getSingleUser(apiBody)
            .then((res) => {
                navigate("/app/patients-details", { state: { id: res?.data?.id, name: res?.data?.name[0]?.text, isDoctor: true } })
            })
    };

    useGetPatientByUsers({
        patientId, patientEmail, from, onSuccess: (data) => {
            setPatientImage(data?.photo?.base64Data);
            setPatientDOB(decryptData(data?.birthDate)?.replace(/-/g, '/'));
            setPatientData(data);
            getPatientData(data)
            setCurrentProgram(data?.program)
        }
    })

    useOrgQuery({
        onSuccess: (data) => {
            setOrganizationsData(data);
            setOrganizations(data?.map((org) => ({ name: org?.name, value: org?.id })))
        },
        refetchOnWindowFocus: false,
    })

    // usePatientEnrolledProgramsQuery({ patientId, onSuccess: (data) => { setCurrentProgram(data.sort((a, b) => a?.program?.addedAt < b?.program?.addedAt ? -1 : 1)?.pop()?.program?.title) } })
    const reading = patientsData?.map((ele) => ele?.valueQuantity?.value)
    const temperature = reading?.find((ele) => ele?.temperature)?.temperature
    const diastolic = reading?.find((ele) => ele?.diastolic)?.diastolic
    const systolic = reading?.find((ele) => ele?.systolic)?.systolic
    const glucose = reading?.find((ele) => ele?.glucose)?.glucose
    const weight = reading?.find((ele) => ele?.weight)?.weight
    const heartRate = reading?.find((ele) => ele?.heartRate)?.heartRate
    const oxygen = reading?.find((ele) => ele?.oxygen)?.oxygen
    const phoneNumber = splitContactNumber(patientData?.telecom?.find((ele) => ele?.system === "phone")?.value);

    const fetchProgramData = async (programId) => {
        try {
            const response = await getProgram(programId);
            setCurrentProgramDetails(response?.result?.condition);
        } catch (error) {
            failed(error?.response?.data?.message || error?.response?.data?.error || error.message);
        }
    };

    useEffect(() => {
        let enc_temp = encryptData("temperature");
        let enc_wt = encryptData("weight");
        let enc_bp = encryptData("blood_pressure");
        let enc_hrate = encryptData("heart_rate");
        let enc_glucose = encryptData("blood_glucose");
        let enc_oxygen = encryptData("oxygen");

        const apiBody = {
            "patientId": patientId,
            "conditionName": JSON.stringify([enc_temp, enc_wt, enc_bp, enc_hrate, enc_glucose, enc_oxygen]),
            // "date" : moment().utc().format("MM/DD/YYYY")
        }
        // Fetch patient readings only if the currentProgram has a conditionName
        // if (currentProgram?.conditionName) {
        //     const apiBody = {
        //         "patientId": patientId,
        //         "conditionName": [encryptData(currentProgram.conditionName)]
        //     }
        // }
        getPatientReadingLatest(apiBody)
            .then((res) => {
                setPatientsData(res.data)
            })
            .catch((res) => {
                failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
            });

        const programCurId = currentProgram?.id; // Assuming the currentProgram has an 'id' property
        if (programCurId) {
            fetchProgramData(programCurId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProgram])
    

    let hcnValue;
    if(patientData ){
        for (let i = 0; i < patientData?.patient_identifier?.length; i++) {    
            if (patientData?.patient_identifier[i].type === "HCN") {      
                hcnValue = patientData?.patient_identifier[i].value;
                break;
            }
        }
    }
    

    return (
        <div className="custom-card" style={{ padding: '10px', display:'flex' }}>
            {modalShow ?
                <EditIndividual
                    modalShow={modalShow}
                    handleShow={handleShow}
                    isReadOnly={false}
                    inactive={false}
                    typeOfUser={"Patient"}
                    refreshTable={handleView}
                    organizations={organizations}
                    organizationsData={organizationsData}
                /> : null}
            {relatedPersonModalShow ? <RelatedPerson modalShow={relatedPersonModalShow} handleShow={handleRelatedPerson}/>: null}
            <Container style={{overflow:"hidden", alignSelf:"center"}}>                
                <Row style = {{borderBottom: '1px solid rgba(0, 0, 0, 0.14)'}}>
                    <Col style={{paddingTop:"10px"}} xs={{ span: 6, offset: 0, order:1 }} md ={{order:0}}>
                        <Tooltip  title="View Details">
                            <h1 className="name-header" style={{ textTransform: "capitalize", cursor: 'pointer', color: "#0064a6", textDecoration: "underline" }} onClick={() => handleShow(patientData)} >{patientData?.name?.[0]?.text} </h1>
                        </Tooltip>
                        <div className= "profileUpper">DOB : {patientDOB ? (<><span>{ViewDate(patientDOB)}</span><span>{'   •  ' + safeAgeString(patientDOB)}</span><span style={{textTransform: "capitalize"}}>{', ' + patientData?.gender}</span></>) : ("-")}</div>
                        {/* <div className= "profileUpper">HCN : <span>{patientData?.healthCardNumber}</span></div>  */}
                        <div className= "profileUpper">HCN : <span>{hcnValue? hcnValue : "-"}</span></div> 
                    </Col>
                    <Col xxl={{ span: 2, offset: 4 }} lg={{offset:3, span:3}} md={{offset:0, span:3}}>
                        <div className="profile-new-img-wrap">
                            <img src={patientImage ? patientImage : blankProfileImage} alt="profile-pic" />
                        </div>
                    </Col>
                        
                </Row>
                <Row className ="profileText" style = {{borderBottom: '1px solid rgba(0, 0, 0, 0.14)'}}>
                    <Col md={{ span: 4, offset: 0}}>
                        <div>Address</div>
                        <div style = {{paddingBottom: '5px'}}>{patientData?.address?.[0]?.line?.[0]|| '-'}</div>
                        <div>Phone Number</div>
                        <div>{phoneNumber?.phone_number || '-'}</div>
                    </Col>
                    <Col>
                        <div>City</div>
                        <div>{patientData?.address?.[0]?.city || '-'}</div>
                        <div>Ext</div>
                        <div>{phoneNumber?.ext || '-'}</div>
                    </Col>
                    <Col md={{ span: 4, offset: 0}}>
                        <div>{patientData?.address?.[0]?.country === 'CA' ? "Province" : "State"}</div>
                        <div>{patientData?.address?.[0]?.state?.split('-')?.[1] || '-'}</div>
                        <div>Email</div>
                        <div>{patientData?.telecom?.find((ele) => ele?.system === "email")?.value || '-'}</div>
                    </Col>
                    <Col>
                        <div>Postal Code</div>
                        <div>{patientData?.address?.[0]?.postalCode || '-'}</div>
                        <div>Country</div>
                        <div>{patientData?.address?.[0]?.country || '-'}</div>
                    </Col>  
                        
                </Row>

                
                <Row className ="profileText" style={{alignItems:'center'}}>
                    <Col >
                        <div>Assigned Practitioner</div>
                        {patientData?.generalPractitioner?.[0]?.display ? patientData?.generalPractitioner?.map((gPractitioner) => {
                            return(
                                gPractitioner.display && 
                                <div style={{color:"#121f48", paddingBottom:"0px", wordWrap: "break-word", overflowWrap: "break-word"}}>
                                    {gPractitioner.display?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                </div>
                            )
                        }) : <div>-</div>}
                    </Col>
                    <Col>
                        <div>Organization</div>
                        <div>{patientData?.managingOrganization?.[0]?.display || '-'}</div>
                    </Col>
                    <Col>
                        <div /* onClick={() => handleRelatedPerson()} */
                        /* style={{ textDecoration: "underline", cursor: "pointer", color: "#0064a6", fontWeight: "100" }} */>
                            Circle of Care {/* + */}
                        </div>
                        {relatedPerson?.length > 0 ? relatedPerson?.map((relative) => {
                            return(
                                relative.name?.[0]?.family && 
                                <div style={{color:"#121f48", paddingBottom:"0px", wordWrap: "break-word", overflowWrap: "break-word"}}>
                                    {relative?.name?.[0]?.given?.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} {relative?.name?.[0]?.family?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}, {relative.relationship}
                                </div>
                            )
                            
                        }) : <div>-</div>}
                        {/* <div>Circle of Care +</div>
                        <div>{phoneNumber?.ext || '-'}</div>
                        <div>Circle of Care +</div>
                        <div>{phoneNumber?.ext || '-'}</div>
                        <div>Circle of Care +</div>
                        <div>{phoneNumber?.ext || '-'}</div> */}
                    </Col>

                </Row>
                
                
            </Container>
        </div>
    );
};

export default PatientCustomCard;
