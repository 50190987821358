import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "../../common/Button";
import { useFormik } from "formik";
import TextInput from "../../common/textfield/TextInput";
import { useGetPermissions } from "../../../hooks/ReactQueryHooks/useGetPermissions";
import { createRole } from "../../api/ManageRoles";
import { useOrgQuery } from "../../../hooks/ReactQueryHooks/useOrgQuery";
import SelectFiled from "../../common/textfield/SelectFiled";
import TextArea from "../../common/textfield/TextArea";
import { failed, success } from "../../common/Toastify";
import { useNavigate } from "react-router-dom";
import { FormikObj } from "./constant";
import { Tooltip } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useSelector } from "react-redux";

// CreateRole dialog
export function CreateRole() {
    const { data: Orgs } = useOrgQuery({ onSuccess: () => { } });
    const navigate = useNavigate();
    const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
    const [btnLoading, setBtnLoading] = useState(false);
    const [noSelectedRoles, setNoSelectedRoles] = useState(false);
    const [Admin_roles, setAdmin_roles] = useState([]);
    const [Clinical_roles, setClinical_roles] = useState([]);
    const [Practitioner_roles, setPractitioner_roles] = useState([]);

    const formik = useFormik({
        ...FormikObj,
        onSubmit: (values) => {
            const permissions = [...Admin_roles, ...Clinical_roles, ...Practitioner_roles].filter((role) => role.selected);
            if (permissions.length > 0) {
                noSelectedRoles && setNoSelectedRoles(false);
                setBtnLoading(true);
                createRole({ ...values, permissions, organization: values?.isDefault ? null : Orgs?.filter(({ name }) => name === values.organization)[0] })
                    .then((res) => {
                        navigate("/app/manage-roles");
                        success(res.message);
                    }).catch((res) => {
                        failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                    }).finally(() => setBtnLoading(false));
            } else setNoSelectedRoles(true);
        },
    });
    const { data: permissions } = useGetPermissions({ orgId: Orgs?.filter(({ name }) => name === formik?.values?.organization)[0]?.id || "" });

    const handleAdminRole = (e) => {
        const { name, checked } = e.target;
        const newAdmin_roles = Admin_roles.map((data) => (data.id === name ? { ...data, selected: checked } : data));
        setAdmin_roles(newAdmin_roles);
    };
    const handleClinicRole = (e) => {
        const { name, checked } = e.target;
        const newClinical_roles = Clinical_roles.map((data) => (data.id === name ? { ...data, selected: checked } : data));
        setClinical_roles(newClinical_roles);
    };
    const handlePractitionerRole = (e) => {
        const { name, checked } = e.target;
        const newPractitioner_roles = Practitioner_roles.map((data) => (data.id === name ? { ...data, selected: checked } : data));
        setPractitioner_roles(newPractitioner_roles);
    };

    useEffect(() => {
        setAdmin_roles(permissions?.admin);
        setClinical_roles(permissions?.clinical);
        setPractitioner_roles(permissions?.practitioner);
    }, [permissions]);

    return (
        <section className="common-listing">
            <div className="heading-wrap mb-3">
                <Tooltip title="Go back">
                    <div style={{ marginRight: "1rem", cursor: "pointer" }} onClick={() => navigate(-1)}>
                        <ArrowBack />
                    </div>
                </Tooltip>
            </div>
            <div className="custom-card p-4">
                <form className="common-form border-fields" onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col>
                            <TextInput keyField={"name"} label={"Role Name"} formik={formik} placeholder={"Role Name"} />
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextArea keyField={"description"} label={"Description"} formik={formik} placeholder={"Description..."} />
                        </Col>
                    </Row>
                    {userRoles?.includes('Super-Admin' || 'Practitioner')
                        ? <Row>
                            <Col>
                                <div className="field-wrap" style={{ margin: 0 }}>
                                    <label className="custom-checkbox" style={{ margin: 0, width: "fit-content" }}>
                                        Default Role
                                        <input style={{ width: "auto" }} type="checkbox" name={"default role"} checked={formik.values.isDefault} onChange={() => formik.setFieldValue("isDefault", !!!formik?.values?.isDefault)} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        : null}
                    {!formik?.values?.isDefault ? <Row>
                        <Col>
                            <SelectFiled keyField={"organization"} label={"Select Organization"} formik={formik} options={Orgs} readOnly={formik?.values?.isDefault} />
                        </Col>
                        <Col></Col>
                        <Col></Col>
                    </Row> : null}
                    <div className="check-select-wrap">
                        <h5>Administrative Permissions</h5>
                        <div className="form-outer-grid">
                            {Admin_roles?.map(({ module, selected, id }) => (
                                <div className="field-wrap" style={{ margin: 0 }} key={id}>
                                    <Tooltip title="To perform CRUD actions">
                                        <label className="custom-checkbox" style={{ margin: 0, width: "fit-content" }}>
                                            {module}
                                            <input style={{ width: "auto" }} type="checkbox" name={id} checked={selected} onChange={handleAdminRole} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </Tooltip>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="check-select-wrap">
                        <h5>Clinical Permissions</h5>
                        <div className="form-outer-grid">
                            {Clinical_roles?.map(({ module, selected, id }) => (
                                <div className="field-wrap" style={{ margin: 0 }} key={id}>
                                    <label className="custom-checkbox" style={{ margin: 0, width: "fit-content" }}>
                                        {module} 
                                        <input style={{ width: "auto" }} type="checkbox" name={id} checked={selected} onChange={handleClinicRole} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="check-select-wrap">
                        <h5>Practitioner Permissions</h5>
                        <div className="form-outer-grid">
                            {Practitioner_roles?.map(({ module, selected, id }) => (
                                <div className="field-wrap" style={{ margin: 0 }} key={id}>
                                    <label className="custom-checkbox" style={{ margin: 0, width: "fit-content" }}>
                                        {module}
                                        <input style={{ width: "auto" }} type="checkbox" name={id} checked={selected} onChange={handlePractitionerRole} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div style={{ color: "red" }}>{noSelectedRoles ? "Please select at least one Role!" : null}</div>
                    <div className="btn-wrap" style={{ display: "flex" }}>
                        <Button onClick={() => navigate("/app/dashboard")} variant="secondary" title="Cancel">
                            Cancel
                        </Button>
                        <Button type="submit" isLoading={btnLoading}>
                            Create
                        </Button>
                    </div>
                </form>
            </div>
        </section>
    );
}