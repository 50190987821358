import * as Yup from "yup";

export const tempStyleParticipants = {
    gridTemplateColumns: "repeat(2,1fr)",
    gridTemplateRows: "repeat(2,1fr)",
    display: "grid",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    marginTop: "0",
}

export const QuestionnaireFormikObj = {
    initialValues: {
        questionnaire: "",
        isQuestionsError: "",
        questionResponse: [],
        questionResponseId : ""
    },
    validationSchema: Yup.object({
       // questionnaire: Yup.string().required('Please select the Questionnaire.').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
    }),
}