import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    mediaStream: null,
    chatClient: null,
    currentUser: {},
    participants: [],
    isCameraLoading: false,
    currentSession: false,
    sessionChat: [],
    isCameraOn: false,
    isMicOn: false,
    isAudioStreaming: false,
}

export const ZoomSessionSlice = createSlice({
    name: 'ZoomSession',
    initialState,
    reducers: {
        updateZoomSession(state = initialState, action) {
            switch (action.payload.type) {
                case "mediaStream": {
                    return {
                        ...state,
                        mediaStream: action.payload.value,
                    };
                }
                case "chatClient": {
                    return {
                        ...state,
                        [action.payload.type]: action.payload.value,
                    };
                }
                case "currentUser": {
                    return {
                        ...state,
                        [action.payload.type]: action.payload.value,
                    };
                }
                case "participants": {
                    return {
                        ...state,
                        [action.payload.type]: action.payload.value,
                    };
                }
                case "isCameraLoading": {
                    return {
                        ...state,
                        [action.payload.type]: action.payload.value,
                    };
                }
                case "currentSession": {
                    return {
                        ...state,
                        [action.payload.type]: action.payload.value,
                    };
                }
                case "sessionChat": {
                    return {
                        ...state,
                        [action.payload.type]: action.payload.value,
                    };
                }
                case "isCameraOn": {
                    return {
                        ...state,
                        [action.payload.type]: action.payload.value,
                    };
                }
                case "isMicOn": {
                    return {
                        ...state,
                        [action.payload.type]: action.payload.value,
                    };
                }
                case "isAudioStreaming": {
                    return {
                        ...state,
                        [action.payload.type]: action.payload.value,
                    };
                }
                case "end": {
                    return initialState;
                }
                default:
                    return state;
            }
        },
    }
})

export const { updateZoomSession } = ZoomSessionSlice.actions;
export default ZoomSessionSlice.reducer;