import { useQuery } from "react-query";
import { axios } from "../../lib/axios";
import { onError } from "./onError"

export function useNotificationEventQuery({ onSuccess }) {

    const fetch = () => {
        return axios.get("event");
    };
    const query = useQuery(
        "notification-event",
        () => fetch(),
        {
            select: (data) => data?.data,
            onSuccess,
            onError
        }
    );

    return { ...query };
}
