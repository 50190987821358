import { useQuery } from "react-query";
import { axios } from "../../lib/axios";
import { onError } from "./onError";

export function useSurveyQuery({ onSuccess, }) {

    const fetch = () => {
        return axios.get("/questionnaire?purpose=survey")
    }

    const query = useQuery(
        "questionnaires-list",
        () => fetch(),
        {
            select: (data) => data.data,
            onSuccess,
            onError
        }
    );

    return { ...query };
}
