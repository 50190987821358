import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function SignatureCapture({ onSignatureCapture, closeSignatureModal }) {
  const signatureRef = useRef(null);

  const clearSignature = () => {
    signatureRef.current.clear();
  };

  const saveSignature = () => {
    const signatureImage = signatureRef.current.toDataURL();
    onSignatureCapture(signatureImage);
    closeSignatureModal();
  };

  return (
    <div>
      <u> <h5 style={{ textAlign: 'center' }}>Sign Here</h5></u>
      <hr />
      <div>
        <SignatureCanvas ref={signatureRef} canvasProps={{ width: 500, height: 150 }} />
      </div>
      <div >
        <Row>
          <Col style={{ textAlign: 'left' }}>
            <button type="button" onClick={clearSignature}>Clear </button>
          </Col>
          <Col> {/* <button onClick={saveSignature}>Save Signature</button> */}</Col>
          <Col style={{ textAlign: 'right' }}>
            <button type="button" onClick={saveSignature}>
              Save
            </button>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default SignatureCapture;