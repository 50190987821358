import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Table } from "react-bootstrap";
import { Button } from "../../common/Button";
import { noDataAvailText, viewNoteTilte } from "../Patient/Constants";
import {  TableCell } from "@mui/material";
import moment from "moment";
import CustomPagination from "../../common/components/Pagination/CustomPagination";
import "./Style.css"
import { ViewDate } from "../../../utils/DateSupport";

const ViewNote = ({ modalShow, handleShow, showNoteModal, newNoteData }) => {
    const [page, setPage] = useState(0);
    const rowsPerPage = 5;
    

    return (
      <Modal
        backdropClassName
        backdrop={"static"}
        show={modalShow}
        onHide={handleShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="custom-dialog"
      >
        <Modal.Header closeButton className="border-0">
          <div className="heading-wrapper">
              <div>
                <Modal.Title id="contained-modal-title-vcenter">
                  <span style={{color: "grey", fontSize: "smaller"}}>{modalShow?.resourceType === "Condition" ? "Condition" : modalShow?.resourceType === "Procedure" ? "Procedure" : modalShow?.resourceType === "FamilyMemberHistory" ? "Family History" : "Observation"}&gt;</span><span style={{color: "#0064a6", fontSize: "smaller"}}>{modalShow?.conditionName || modalShow?.procedureName || modalShow?.condition?.[0]?.name || modalShow?.observationName}</span>
                </Modal.Title>
              </div>
              <div>
              <Button
                onClick={() => showNoteModal(modalShow)}
                className="custom-btn"
                title="Add Document"
              >
                Add Note
              </Button>
              </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="table-wrap" style={{margin: 0}}>
            <Table responsive>
              <thead>
                <tr>
                  {viewNoteTilte?.map((title) => (
                    <th
                      key={title}
                      style={{
                        // textAlign: title === "Notes(s)" ? "center" : null,
                        width: title === "Date" || title === "Practitioner" ? "160px" : "auto",
                      }}
                    >
                      {title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {(newNoteData?.note?.length || modalShow?.note?.length) > 0 ? (
                  (newNoteData?.note || modalShow?.note)
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((data) => {
                      return (
                        <tr key={data?.id}>
                          <td>{ViewDate(data?.time)}</td>
                          <td>{data?.authorReference?.display.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</td>
                          <td style={{textAlign: "left", whiteSpace: "pre-wrap", wordBreak: "break-all"}}>{data?.text}</td>
                        </tr>
                      );
                    })
                ) : (
                  <tr>
                    <TableCell
                      colSpan={viewNoteTilte.length}
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {noDataAvailText}
                    </TableCell>
                  </tr>
                )}
              </tbody>
            </Table>
            {(newNoteData?.note?.length || modalShow?.note?.length) > 0 ? (
              <div>
                <CustomPagination
                  tableData={ newNoteData?.note || modalShow?.note }
                  totalRows={ newNoteData?.note?.length || modalShow?.note?.length }
                  rowsPerPage={rowsPerPage}
                  page={page}
                  handlePage={(pageNo) => setPage(pageNo)}
                />
              </div>
            ) : null}
          </div>
        </Modal.Body>
      </Modal>
    );
}
export default ViewNote