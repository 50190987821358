import { useState } from "react";
import { Modal } from "react-bootstrap";
import { axios } from "../../lib/axios";
import { Button } from "../common/Button";
import { failed, success } from "../common/Toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import TextInput from "../common/textfield/TextInput";


export function InviteInSeesion({ show, onHide, topic, password }) {
    const [loading, setLoading] = useState(false);

    const onModalHide = () => {
        onHide()
        formik.resetForm()
    }
    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Invalid email address").required("Required").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        }),
        onSubmit: (values) => {
            setLoading(true)
            axios.post("session/invite", {
                "email": values.email?.toLowerCase(),
                topic,
                password,
            }).then((res) => {
                success(res.message)
                onModalHide()
            })
                .catch(res => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                .finally(() => setLoading(false));
        },
    });

    return (
        <Modal
            show={show}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered className="custom-dialog"
            onHide={onModalHide}
            backdropClassName
            backdrop={'static'}
        >
            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">
                    Invite Other
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="common-form" onSubmit={formik.handleSubmit}>
                    <div className="field-wrap">
                        <TextInput keyField={"email"} label={"Email Address"} formik={formik} placeholder={"examplename@gmail.com"} />
                    </div>
                    <div className="btn-wrap" style={{ display: 'flex' }}>
                        <Button onClick={() => onModalHide()} variant="secondary" title="Cancel">Cancel</Button>
                        <Button type="submit" isLoading={loading}>Invite</Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}