import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../common/components/SearchBar";
import { Button } from "../../common/Button";
import CustomTable from "../../common/table/CustomTable";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import { DataFields, HeadCells } from "./Constants";
import { commonDisableAPI } from "../../api/CommonApis";
import { failed, success } from "../../common/Toastify";
import { EditGroup } from "./EditGroup";
import { useGroupsQuery } from "../../../hooks/ReactQueryHooks/useGroupsQuery";

function GroupList() {

    const navigate = useNavigate();
    const { getConfirmation } = useConfirmDialog();
    const [page, setPage] = useState(0);
    const [modalShow, setModalShow] = useState(false);
    const [filterData, setFilterData] = useState([]);
    const [groups, setGroups] = useState([]);
    const [deleting, setDeleting] = useState(false);

    const { isLoading, refetch, isFetching } = useGroupsQuery({ onSuccess: setGroups })

    const handleShow = (data) => { setModalShow(modalShow ? false : data) };
    const handleFilterData = (data) => {
        setFilterData(data)
    }

    const handleDelete = async (id, entryName) => {
        const confirmed = await getConfirmation({
            title: "Attention!",
            entryName,
            actionBtnTitle: "Delete"
        });
        if (confirmed) {
            setDeleting(id);
            commonDisableAPI({ key: "group", id }).then(() => {
                setFilterData(filterData.filter(data => data.id !== id));
                success("Group Deleted!");
            }).catch(res => failed(res?.response?.data?.message || res?.response?.data?.error || res.message)).finally(() => setDeleting(false));
        }
    }

    const selectedUserAction = ({ action, data }) => {
        switch (action) {
            case 'view':
                navigate("/app/group-patients-listing", { state: data });
                break;
            case 'edit':
                handleShow(data)
                break;
            case 'delete':
                handleDelete(data?.id, data?.name);
                break;
            default:
                return null;
        }
    };

    return (
        <section className="common-listing">
            {modalShow ? <EditGroup
                modalShow={modalShow}
                refreshTable={refetch}
                handleShow={handleShow}
            /> : null}
            <div className="heading-wrap h-change">
                <SearchBar page={page} setPage={setPage} list={groups} filterData={handleFilterData} />
                <div className="right-wrap" style={{ margin: 'left' }}>
                    <Button onClick={() => navigate("/app/add-group")} className="custom-btn" title="Add Group" style={{ width: 'auto' }}> + Create Group</Button>
                </div>
            </div>

            <CustomTable
                tableData={filterData}
                headCells={HeadCells}
                dataFields={DataFields}
                selectedUserAction={selectedUserAction}
                rowOptions={false}
                canView
                loading={isLoading}
                fetching={isFetching}
                deletingItem={deleting}
                page={page}
                setPage={setPage}
            />
        </section>
    );
}

export default GroupList;