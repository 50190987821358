import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import '../manage-group.css';
import { addPatientToThreshold } from "../../../apps/api/ThresholdGroup/index"
import { failed, success } from "../../../common/Toastify";
import { usePatientQuery } from "../../../../hooks/ReactQueryHooks/usePatientQuery";
import { useFormik } from "formik";
import * as Yup from "yup";
import { decryptData } from "../../../../../src/components/EncryptDecrypt";
import TextInput from "../../../common/textfield/TextInput";
import Select from 'react-select';
import { Button } from "../../../common/Button";

function AddPatientDialog({ show, onHide, data, alreadyAddedPatientIds }) {
    const [patientOption, setPatientOption] = useState([])
    const [btnLoading, setBtnLoading] = useState(false)
    const [selectedOption, setSelectedOption] = useState([]);
    useEffect(() => {
        if (show) {
            formik.setFieldValue("patientIds", []);
            formik.setFieldValue("name", data?.groupName || "");
            refetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show])
    const onSuccess = (Data) => {
        const patients = Data.filter((item) => data.patientIds?.includes(item.id) ? false : true).map((item) => {
            return {
                id: item.id,
                label: decryptData(item.name[0]?.text),
                value: item.id
            }
        })
        setPatientOption(patients)
    }
    const { refetch } = usePatientQuery({ onSuccess })

    const formik = useFormik({
        initialValues: {
            patientIds: "",
            name: ""
        },
        validationSchema: Yup.object({
            patientIds: Yup.array().required("Required Patient field"),
        }),
        onSubmit: (values) => {
            setBtnLoading(true)
            let newData = {
                groupId: data?.id,
                patientIds: selectedOption?.map(e => e.id)
            }

            addPatientToThreshold(newData).then((res) => {
                setBtnLoading(false);
                if (res.status === true) {
                    onHide()
                    setSelectedOption([])
                    success(res.message);
                } else {
                    failed(res.error);
                }
            }).catch(res => {
                setBtnLoading(false);
                failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
            });
        },
    });

    return (
        <Modal
            show={show}
            onHide={() => onHide()}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered className="custom-dialog"
            backdropClassName
            backdrop={'static'}
        >
            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">
                    Add Patient
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="common-form" onSubmit={formik.handleSubmit}>
                    <div className="field-wrap">
                        <TextInput
                            keyField={"name"}
                            label={"Group Name"}
                            formik={formik}
                            placeholder={"Group Name"}
                            disabled={true}
                        />
                    </div>
                    <div className="field-wrap">
                        <label>Select Patients </label>
                        <Select
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            isMulti
                            name="patientIds"
                            options={patientOption}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            autoFocus
                        />
                    </div>
                    <div className="btn-wrap" style={{ display: 'flex' }}>
                        <Button className="margin-10" variant="secondary" onClick={() => { onHide() }} title="Cancel">Cancel</Button>
                        <Button type="submit" isLoading={btnLoading}>Add Patient</Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}


export default AddPatientDialog
