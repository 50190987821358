import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import EditIcon from "../../../assets/images/edit.png";
import DeleteIcon from "../../../assets/images/delete.png";
import SearchBar from "../../common/components/SearchBar";
import { Button } from "../../common/Button";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Skeleton, TableCell, Tooltip } from "@mui/material";
import ViewIcon from "../../../assets/images/view.png";
import { useProgramQuery } from "../../../hooks/ReactQueryHooks/useProgramQuery";
import CustomPagination from "../../common/components/Pagination/CustomPagination";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import { failed, success } from "../../common/Toastify";
import { Spinner } from "react-bootstrap";
import { deleteProgram } from "../../api/Program";
import EditProgram from "./EditProgram";

function ProgramsList() {
    const { getConfirmation } = useConfirmDialog();
    const userType = useSelector((state) => state?.auth?.user?.['cognito:groups'])
    //React Query Test
    const onSuccess = (data) => {
        setProgramList(data);
    };
    const { isLoading, refetch, isFetching } = useProgramQuery({ onSuccess });
    const [modalShow, setModalShow] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [filterData, setFilterData] = useState([]);
    const [programList, setProgramList] = useState([]);
    const [deleting, setDeleting] = useState(false);
    const navigate = useNavigate();

    const [page, setPage] = useState(0);
    const rowsPerPage = 10;
    const totalRows = filterData.length;

    const handleFilterData = (data) => {
        setFilterData(data);
        setPage(0)
    };

    const handleShow = (data, isReadOnly) => {
        setModalShow(modalShow ? false : data);
        setReadOnly(isReadOnly);
    };

    const handleDelete = async (id, entryName) => {
        const confirmed = await getConfirmation({
            title: "Attention!",
            entryName,
            actionBtnTitle: "Delete",
        });
        if (confirmed) {
            setDeleting(id);
            deleteProgram(id).then(() => {
                setFilterData(filterData.filter(data => data.id !== id));
                success("Program Deleted!");
            }).catch(res => failed(res?.response?.data?.message || res?.response?.data?.error || res.message)).finally(() => setDeleting(false));
        }
    };

    return (
        <React.Fragment>
            <section className="common-listing">
                {modalShow ? <EditProgram modalShow={modalShow} handleShow={handleShow} refreshTable={refetch} isReadOnly={readOnly} /> : null}
                <div className="heading-wrap h-change">
                    <SearchBar page={page} setPage={setPage} list={programList} filterData={handleFilterData} />
                    <div className="right-wrap ml-auto" style={{ margin: "left" }}>
                        <Button onClick={() => navigate("/app/create-program")} className="custom-btn" title="Multiple assign rpm" style={{ width: "auto" }}>
                            Create Program
                        </Button>
                    </div>
                </div>

                <div className="table-wrap">
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>RPM Title</th>
                                <th>Description</th>
                                <th>Condition</th>
                                <th>Time Duration</th>
                                <th>Organization</th>
                                <th style={{ textAlign: "center" }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading || isFetching ? (
                                [1, 2, 3, 4, 5, 6, 7]?.map((val) => (
                                    <tr key={val}>
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                    </tr>
                                ))
                            ) : filterData?.length > 0 ? (
                                filterData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data) => {
                                    const hasAccess = data?.isEditable;
                                    return (
                                        <tr key={data?.id}>
                                            <td className="name-text">{data?.title}</td>
                                            <td>{data?.description}</td>
                                            <td>
                                                {data?.condition?.map((cname, index) => {
                                                    return <span key={index}>{(index ? ", " : "") + cname.name}</span>;
                                                })}
                                            </td>
                                            <td style={{ textAlign: "center" }}>{data?.duration?.value}</td>
                                            <td style={{ textAlign: "center" }}>{data?.managingOrganization?.[0]?.display || ""}</td>
                                            <td>
                                                <div className="action-wrap" style ={{justifyContent:'flex-end'}}>
                                                    {!hasAccess ?  null : (
                                                        <Tooltip title="Patient list">
                                                        <div onClick={() =>  navigate("/app/program-patients-listing", { state: data }) } className="view-btn" >
                                                            <img src={ViewIcon} alt="View" />
                                                        </div>
                                                        </Tooltip>
                                                    )}
                                                    {!hasAccess ? (
                                                        <Tooltip title={"View"}>
                                                            <Link to="" variant="primary" onClick={() => handleShow(data, true)} className="success-btn">
                                                                <img src={ViewIcon} alt="View" />
                                                            </Link>
                                                        </Tooltip>
                                                    ) : (
                                                        <Tooltip title={"Edit"}>
                                                            <Link to="" variant="primary" onClick={() => handleShow(data, false)} className="success-btn">
                                                                <img src={EditIcon} alt="Edit" />
                                                            </Link>
                                                        </Tooltip>
                                                    )}
                                                    <Tooltip title={hasAccess ? "Delete" : "You dont have permission"}>
                                                        <Link
                                                            to=""
                                                            variant="primary"
                                                            onClick={() => hasAccess && handleDelete(data?.id, data?.title)}
                                                            className="delete-btn"
                                                            style={{ opacity: hasAccess ? "1" : "0.2" }}
                                                        >
                                                            {deleting === data?.id ? (
                                                                <Spinner size="lg" className="text-current" style={{ color: "white", background: "transparent", margin: "0" }} />
                                                            ) : (
                                                                <img src={DeleteIcon} alt="Delete" />
                                                            )}
                                                        </Link>
                                                    </Tooltip>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <TableCell colSpan="5" style={{ textAlign: "center" }}>
                                        No Data Available
                                    </TableCell>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
                {filterData?.length > 0 ? <CustomPagination tableData={filterData} totalRows={totalRows} rowsPerPage={rowsPerPage} page={page} handlePage={(pageNo) => setPage(pageNo)} /> : null}
            </section>
        </React.Fragment>
    );
}

export default ProgramsList;
