import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import TextInput from '../../common/textfield/TextInput'
import SelectFiled from '../../common/textfield/SelectFiled'
import Radio from '../../common/textfield/Radio';
import Checkbox from '../../common/textfield/Checkbox';
import "./Fields.css"

const Fields = ({ question, formik, propIndex, Questions, setRadioValue, hideMandatory = false }) => {
  const [selfCallingTag, setSelfCallingTag] = useState("Yes");
  const [relationshipDropdownValue, setRelationshipDropdownValue] = useState("");
  const [locationDropdownValue, setLocationDropdownValue] = useState("");
  const [selfcallingIndex, setSelfcallingIndex] = useState(Questions.findIndex((obj)=> obj.tag === "RELATIONSHIP"));
  const [consentIndex, setConsentIndex] = useState(Questions.findIndex((obj)=> obj.tag === "LOCATION"));
  const telePhoneField = formik?.values?.questionResponse?.map((item)=> item?.tag === "TELEPHONE")?.answer?.[0]?.answer
  const pattern = /^(?:\+?1[-. ]?)?\(?[0-9]\d{2}\)?[-. ]?\d{3}[-. ]?\d{4}$/;

  const handleRadioChange = (val, tag) => {
    const newQuestionResponse = formik?.values?.questionResponse[propIndex].answer?.map((item) => ({
      ...item,
      answer: val === item?.option,
    }));
    formik.setFieldValue(`questionResponse[${propIndex}].answer`, newQuestionResponse);
    let foundSelfCalling;
    if(tag === "SELFCALLING" && Questions?.some((obj)=> obj?.tag === "SELFCALLING")) {
      foundSelfCalling = Questions?.find((obj) => obj?.tag === "SELFCALLING");
      setSelfCallingTag(foundSelfCalling?.answerOption.find((obj) => obj.text === val)?.text);
      setRadioValue(newQuestionResponse)
    }
  };

  const handleRadioChangeForConsent = (val, tag) => {
    const newQuestionResponse = formik?.values?.questionResponse[propIndex].answer?.map((item) => ({
      ...item,
      answer: val === item?.option,
    }));
    formik.setFieldValue(`questionResponse[${propIndex}].answer`, newQuestionResponse);
  };

  const handleLocationDropdownChange = useCallback((event) => {
    const value = event?.target?.value;
    let relationshipObject = null;
    let questionResponse = formik?.values?.questionResponse;
    setLocationDropdownValue(value)
    questionResponse.forEach((ele, index) => {
      if (ele.tag === "LOCATION") {
        relationshipObject = ele;
        relationshipObject.answer = relationshipObject?.answer?.map((item) => ({
          ...item,
          answer: (value === item?.value) ? value : "",
        }));

        const updatedAnswer = ele.answer.filter(item => item.answer === value);
        ele.answer = ele.answer.filter(item => item.answer !== value);
        ele.answer.unshift(...updatedAnswer);
        return;
      }
    });
  }, [formik]);

  const handleRelationshipDropdownChange = useCallback((event) => {
    const value = event?.target?.value;
    setRelationshipDropdownValue(value);
    let relationshipObject = null;
    let questionResponse = formik?.values?.questionResponse;

    questionResponse.forEach((ele, index) => {
      if (ele.tag === "RELATIONSHIP") {
        relationshipObject = ele;
        relationshipObject.answer = relationshipObject?.answer?.map((item) => ({
          ...item,
          answer: (value === item?.value) ? value : "",
        }));

        const updatedAnswer = ele.answer.filter(item => item.answer === value);
        ele.answer = ele.answer.filter(item => item.answer !== value);
        ele.answer.unshift(...updatedAnswer);
        return;
      }
    });
  }, [formik]);

  const handleExt = (event) => {
    let key = event.key;
    let regex = new RegExp("^[0-9]+$");
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  };

  if (question?.answerType === "freeText") {
    if (question?.tag === "TELEPHONE") {
      return (
        <Col>
            <Row>
            <h6>{question?.text}{question?.required && !hideMandatory ? <span style={{ color: "red" }}> *</span> : null}</h6>
            <Col>
              <TextInput
                keyField={`questionResponse[${propIndex}].answer[0].answer`}
                type="phone"
                formik={formik}
                placeholder={"Type here..."}
                required={false}
              />
            </Col>
            <Col>
              <TextInput
                keyField={`questionResponse[${propIndex}].answer[1].answer`}
                label={false}
                type="text"
                maxLength="4"
                formik={formik}
                placeholder={"Ext."}
                onKeyPress={handleExt}
                autoComplete="off"
              />
            </Col>
            <div>{formik?.errors?.questionResponse?.[propIndex] ? <div className="fields-error">Required question</div> : formik?.values?.questionResponse?.[propIndex]?.answer?.[0]?.answer !== "" ? !pattern.test(telePhoneField) ? formik?.values?.questionResponse?.[propIndex]?.answer?.[0]?.answer?.replace(/\D/g, '')?.length === 10 ? "" : <div className="fields-error">Enter Valid Phone Number.</div> : null : ""}</div>
          </Row>
        </Col>
      );
    } else if(question?.tag === "CALLER"){
      return (
        <Row>
          <h6>{question?.text}{question?.required && !hideMandatory ? <span style={{ color: "red" }}> *</span> : null}</h6>
          <Col>
          <TextInput
            keyField={`questionResponse[${propIndex}].answer[0].answer`}
            label={false}
            formik={formik}
            placeholder={"First Name"}
            hideRequired={true}
          />
          <div>{formik?.errors?.questionResponse?.[propIndex] ? formik.values.questionResponse?.[propIndex].answer[0]?.answer === "" ?  <div className="fields-error">Required First Name.</div> : null : null}</div>
        </Col>
        <Col>
          <TextInput
            keyField={`questionResponse[${propIndex}].answer[1].answer`}
            label={false}
            formik={formik}
            placeholder={"Last Name"}
            hideRequired={true}
          />
          <div>{formik?.errors?.questionResponse?.[propIndex] ? (formik.values.questionResponse?.[propIndex].answer[1]?.answer === "" || formik.values.questionResponse?.[propIndex].answer[1]?.answer === undefined) ?  <div className="fields-error">Required Last Name.</div> : null : null}</div>
          </Col>
        </Row>
      );
    } else {
      return (
        <Col>
          <h6>{question?.text}{question?.required && !hideMandatory ? <span style={{ color: "red" }}> *</span> : null}</h6>
          <TextInput
            keyField={`questionResponse[${propIndex}].answer[0].answer`}
            label={false}
            formik={formik}
            placeholder={"Type here..."}
            hideRequired={true}
          />
          <div>{formik?.errors?.questionResponse?.[propIndex] ? <div className="fields-error">Required question</div> : null}</div>
        </Col>
      );
    }
  }

  if (question?.answerType === "dropDown") {
    return (
      <Row>
        <Col>
          {(question?.tag !== "RELATIONSHIP"  && question.tag !== "LOCATION") && (
            <>
              <h6>{question?.text}{question?.required && !hideMandatory ? <span style={{ color: "red" }}> *</span> : null}</h6>
              <SelectFiled
                keyField={`questionResponse[${propIndex}].answer[0].answer`}
                label={false}
                formik={formik}
                options={question?.answerOption.map((options) => ({
                  name: (options?.name) ? options.name : options.text,
                  value: (options?.value) ? options.value : options.text
                }))}
                hideRequired={true}
              />
              <div>{formik?.errors?.questionResponse?.[propIndex] ? <div className="fields-error">Required question</div> : null}</div>
            </>
          )}
        </Col>
        <Col></Col>
        <Col></Col>
      </Row>
    );
  }

  if (question?.answerType === "radio") {
    return (
      <>
        <Col>
          {question?.tag !== "CONSENT" && (
          <Row>
            <Col>
            <h6>{question?.text}{question?.required && !hideMandatory ? <span style={{ color: "red" }}> *</span> : null}</h6>
            <div style={{display: "flex", justifyContent:"centre", flexDirection: "row"}}>  
            {question?.answerOption?.map((option, index) => (
              <Row key={option?.id} style={{marginLeft : "-10px" , marginRight: "5px"}}>
                <Radio
                  key={index}
                  keyField={`questionResponse[${propIndex}].answer[${index}]?.answer`}
                  name={`radio${propIndex}`}
                  value={(option?.value) ? option.value : option?.text}
                  radioLabel={(option?.name) ? option.name : option?.text}
                  isChecked={formik?.values?.questionResponse[propIndex]?.answer[index].answer}
                  handleChange={handleRadioChange}
                  formik={formik}
                  tag={question?.tag}
                  hideRequired={true}
                />
              </Row>
            ))}
            </div>
            <div>{formik?.errors?.questionResponse?.[propIndex] ? <div className="fields-error">Required question</div> : null}</div>
            </Col>
            <Col>
            {(selfCallingTag === "No" || question?.tag === "SELFCALLING" ? formik?.values?.questionResponse?.find((item)=> item?.tag === "SELFCALLING")?.answer.find((obj)=> obj?.option === "No")?.answer : "") && (  <>
              <h6>{Questions?.find((obj) => obj?.tag === "RELATIONSHIP")?.text}{(Questions?.find((obj) => obj?.tag === "RELATIONSHIP")?.text && selfCallingTag === "No") ? <span style={{ color: "red" }}> *</span> : null}</h6>
                <SelectFiled
                  keyField={`questionResponse[${selfcallingIndex}].answer[0].answer` /* relationshipDropdownValue */}
                  formik={formik}
                  label={false}
                  options={[
                    // { name: "Select", value: "" },
                    ...Questions?.find((obj) => obj?.tag === "RELATIONSHIP")?.answerOption.map((options) => ({
                      name: options.text,
                      value: options.text
                    }))
                  ]}
                  hideRequired={true}
                  onChange={handleRelationshipDropdownChange}
                />
               <div>{selfCallingTag === "No" && formik?.errors?.questionResponse?.[selfcallingIndex] ? <div className="fields-error">Required question</div> : null}</div>
               </>)}
            </Col>
          </Row>
          )}
          {question?.tag === "CONSENT" && 
          <Row>
              <Col>
              <h6>{question?.text}{question?.required && !hideMandatory ? <span style={{ color: "red" }}> *</span> : null}</h6>
              <div style={{display: "flex", justifyContent:"centre", flexDirection: "row"}}>
              {question?.answerOption?.map((option, index) => (
                <Row key={option?.id} style={{marginLeft : "-10px", marginRight: "5px"}}>
                  <Radio
                    key={index}
                    keyField={`questionResponse[${propIndex}].answer[${index}]?.answer`}
                    name={`radio${propIndex}`}
                    value={(option?.value) ? option.value : option?.text}
                    radioLabel={(option?.name) ? option.name : option?.text}
                    isChecked={formik?.values?.questionResponse[propIndex]?.answer[index].answer}
                    handleChange={handleRadioChangeForConsent}
                    formik={formik}
                    tag={question?.tag}
                    hideRequired={true}
                  />
                </Row>
              ))}
              </div>
              <div>{formik?.errors?.questionResponse?.[propIndex] ? <div className="fields-error">Required question</div> : null}</div>
              </Col>
              <Col>
                <h6>{Questions?.find((obj) => obj?.tag === "LOCATION")?.text}{(Questions?.find((obj) => obj?.tag === "LOCATION")?.text) ? <span style={{ color: "red" }}> *</span> : null}</h6>
                  <SelectFiled
                    keyField={`questionResponse[${consentIndex}].answer[0].answer`}
                    formik={formik}
                    label={false}
                    options={[
                      ...Questions?.find((obj) => obj?.tag === "LOCATION")?.answerOption.map((options) => ({
                        name: options.text,
                        value: options.text
                      }))
                    ]}
                    hideRequired={true}
                    onChange={handleLocationDropdownChange}
                  />
                <div>{formik?.errors?.questionResponse?.[consentIndex] ? <div className="fields-error">Required question</div> : null}</div>
              </Col>
          </Row>
          }
        </Col>
      </>
    );
  }

  if (question?.answerType === "checkbox") {
    return (
      <Row>
        <h6>{question?.text}{question?.required === true  && !hideMandatory ? <span style={{ color: "red" }}> *</span> : null}</h6>
        {question?.answerOption?.map((option, index) => (
          <Row key={option?.id}>
            <Checkbox
              key={index}
              keyField={`questionResponse[${propIndex}].answer[${index}].answer`}
              label={(option?.name)? option.name : option?.text}
              // value={(option?.value)? option.value : option?.text}  ** may need to reenable this for value codes
              formik={formik}
              checked={formik.values.questionResponse[propIndex]?.answer[index].answer}
              hideRequired={true}
            />
          </Row>
        ))}
        <div>{formik?.errors?.questionResponse?.[propIndex] ? <div className="fields-error">Required question</div> : null}</div>
      </Row>
    );
  }
};
  

export default Fields