import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import { editOrganization, editDiscover, createDiscover } from "../../api/Organization";
import { Button } from "../../common/Button";
import { failed, success } from "../../common/Toastify";
import TextInput from "../../common/textfield/TextInput";
import CountrySelect from "../../common/textfield/CountrySelect";
import RegionSelect from "../../common/textfield/RegionSelect";
import TextArea from "../../common/textfield/TextArea";
import {
    FormikObj,
    deviceUnitTypeOptions,
    devicesField,
    typeOfDeviceUnitTypeOptions,
    ORGANIZATION_DETAILS,
    PARENT_ORGANIZATION,
    NAME,
    DESCRIPTION,
    ADDRESS,
    COUNTRY,
    CITY,
    ZIP_CODE,
    POSTAL_CODE,
    PHONE_NUMBER,
    EXT,
    EMAIL_ADDRESS,
    APPOINTMENT_CANCEL_TIME,
    DEVICE_UNIT_TYPE,
    TYPE,
    CANCEL,
    UPDATE,
    operationTiming,
    transformTimingData,
    getDeviceUnit,
    getValidationSchema
} from "./Constants";
import SelectFiled from "../../common/textfield/SelectFiled";
import {
    createContactNumber,
    splitContactNumber,
} from "../../../utils/phoneNumberSupport";
import InputFile from "../../common/textfield/InputFile";
import { handleFileUpload } from "../../../utils/ImageToBase64";
import { useMedia } from "react-use";
import moment from "moment";
import GetCareSettings from "./GetCareSettings";
import { SurveySettings } from "./SurveySettings";
import { updateOrganizationLogo, updateOrganizationName } from "../../slice/authService"
import { useDispatch } from "react-redux";
import { useDiscoverIdQuery } from "../../../hooks/ReactQueryHooks/useDiscoverIdQuery";
import { ProductFeatures } from "./ProductFeatures";
import { useGetOrgLicensedProducts, useGetOrgLicensedProductsById } from "../../../hooks/ReactQueryHooks/useGetOrgLicensedProducts";
import { SFTSettings } from "./SFTSettings";
import { useGetOrgSftQuery } from "../../../hooks/ReactQueryHooks/useGetOrgSftQuery";
import { Tooltip, Switch } from "@mui/material";
import './organization.css';
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import OrganizationCard from "./OrganizationCard";
import { useSelector } from "react-redux";
import { axios } from "../../../lib/axios";

export function EditOrganization({ modalShow, handleShow, refreshTable, userRoles }) {
    const [btnLoading, setBtnLoading] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [country, setCountry] = useState("");
    const [region, setRegion] = useState("");
    const [file, setFile] = useState();
    const [waitingRoomTimings, setWaitingRoomTimings] = useState(operationTiming);
    const [waitingRoomTimingsError, setWaitingRoomTimingsError] = useState(false);
    const [imageError, setImageError] = useState("");
    const [rpmSwitch, setRpmSwitch] = useState(false);
    const [virtualCareSwitch, setVirtualCareSwitch] = useState(false);
    const [callCenterSwitch, setCallCenterSwitch] = useState(false);
    const { data: orgLicensedProducts } = useGetOrgLicensedProducts({})
    const [selectedTab, setSelectedTab] = useState("Details");
    const [delivertoIndividualSwitch, setDelivertoIndividualSwitch,] = useState(modalShow?.sft?.individualDelivery);
    const [delivertoLocationSwitch, setDelivertoLocationSwitch] = useState(modalShow?.sft?.locationDelivery);
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations);
    const userPermissions = useSelector((state) => state?.auth?.user?.permissions);
    const { data: orgSftQuery } = useGetOrgSftQuery({})
    const { getConfirmation } = useConfirmDialog();
    const isMobile = useMedia('(max-width: 768px)');
    const dispatch = useDispatch();
    const modalId = modalShow?.id;
    const onSuccess = (data) => {
        if (data?.length === 0) {
            setRpmSwitch(rpmSwitch);
            setVirtualCareSwitch(virtualCareSwitch);
            setCallCenterSwitch(callCenterSwitch);
        } else {
            setRpmSwitch(data?.licensedProduct.RPM);
            setVirtualCareSwitch(data.licensedProduct.virtualCare);
            setCallCenterSwitch(data.licensedProduct.callCenter);
        }

    }
    const { isLoading, refetch, isFetching } = useDiscoverIdQuery({ modalId, onSuccess });
    const { data: orgLicensedProductsById } = useGetOrgLicensedProductsById({ orgId: modalShow?.id });

    const formik = useFormik({
        ...FormikObj,
        validationSchema: () => getValidationSchema(selectedTab),
        onSubmit: async (values) => {
            const isHoursOfOperationFalse = !!values.isWaitingRoom && !!!values.hoursOfOperation
            let hasValues = Object.values(waitingRoomTimings).some(value => value?.startTime || value?.endTime)
            if (isHoursOfOperationFalse && !hasValues) {
                setWaitingRoomTimingsError(true)
            } else {
                setWaitingRoomTimingsError(false)
                setBtnLoading(true);
                let base64OrgLogo = "";
                if (values?.orgLogoURL) {
                    base64OrgLogo = await handleFileUpload(values?.orgLogoURL);
                }
                const fileExtension = values?.orgLogoURL?.type?.split("/")[1];
                const base64OrgLogo1 = {
                    data: base64OrgLogo,
                    extension: fileExtension // Add the file extension to the object
                };
                const deviceUnit = getDeviceUnit(values);
                values.phone_number = createContactNumber(values);
                if (values.typeOfDeviceUnitType === "group")
                    deviceUnit["groupName"] = values.deviceUnitType;

                let sft = values.sftEnabled
                    ? {
                        "enabled": values.sftEnabled,
                        "individualDelivery": delivertoIndividualSwitch,
                        "locationDelivery": delivertoLocationSwitch,
                        "expirationDays": values.expirationDays,
                        "privacyStatement": values.privacyStatement,
                        "supportStatement": values.supportStatement
                    }
                    : {
                        "enabled": values.sftEnabled,
                        // "privacyStatement": values.privacyStatement,
                        // "supportStatement": values.supportStatement
                    };

                let appointmentAutoAcceptance = {
                    "patientInitiated": values?.patientApptAutoAcceptance, //(via the Mobile App) 
                    "practitionerInitiated": values?.practitionerApptAutoAcceptance //(via the Web UI) 
                }

                let mfaSettings = {
                    enabled: values?.mfaEnabled
                }
                editOrganization(
                    { ...values, id: modalShow?.id },
                    modalShow?.partOfRefId === "000000" ? modalShow?.name : modalShow.partOf.display,
                    modalShow.partOfRefId,
                    deviceUnit,
                    values?.orgLogoURL ? base64OrgLogo1 : undefined,
                    isHoursOfOperationFalse ? transformTimingData(waitingRoomTimings) : null,
                    sft,
                    appointmentAutoAcceptance,
                    mfaSettings
                )
                    .then((res) => {
                        if (res.status === true) {

                            const requestBody = {
                                licensedProduct: {
                                    "RPM": rpmSwitch,
                                    "virtualCare": virtualCareSwitch,
                                    "callCenter": callCenterSwitch
                                },

                            };
                            editDiscover(modalId, requestBody).then((res) => {
                                // navigate("/app/manage-organization");
                                // success(res.message);
                            }).catch((res) => {
                                if (res.response.status === 404) {
                                    const requestBody = {
                                        licensedProduct: {
                                            "RPM": rpmSwitch,
                                            "virtualCare": virtualCareSwitch,
                                            "callCenter": callCenterSwitch
                                        },
                                        orgId: modalId,
                                    };
                                    createDiscover(requestBody).then((res) => {
                                        // navigate("/app/manage-organization");
                                        // success(res.message);
                                    }).catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                                        .finally(() => setBtnLoading(false));
                                } else {
                                    failed(res?.response?.data?.message || res?.response?.data?.error || res.message)
                                }
                            })
                                .finally(() => setBtnLoading(false));
                            success(res.message);
                            dispatch(updateOrganizationLogo(res?.data?.organizationLogo?.base64));
                            dispatch(updateOrganizationName(res?.data?.name));
                            handleShow();
                            refreshTable();
                        } else {
                            failed(res.error);
                        }
                    })
                    .catch((res) => {
                        failed(
                            res?.response?.data?.message ||
                            res?.response?.data?.error ||
                            res.message
                        );
                    })
                    .finally(() => setBtnLoading(false));
            }
        },
    });

    useEffect(() => {
        if (selectedTab === "Details") {
            formik.validateForm().then(errors => {
                let imageLogoError = errors?.orgLogoURL
                if (Object.keys(errors).length === 0 && !imageLogoError) {
                    setImageError("")
                } else {
                    if (imageLogoError) setImageError(imageLogoError)
                }
            })
        }
    }, [formik?.values?.orgLogoURL])

    useEffect(() => {
        if (formik.values.hoursOfOperation) {
            setWaitingRoomTimingsError(false);
        }
    }, [formik?.values?.hoursOfOperation]);

    useEffect(() => {
        if (waitingRoomTimings && Object.values(waitingRoomTimings).some(value => value?.startTime || value?.endTime)) {
            setWaitingRoomTimingsError(false);
        }
    }, [waitingRoomTimings]);

    useEffect(() => {
        fetchOrgnization(modalShow?.id)
    }, [adminOrgs?.[0]?.id])

    const fetchOrgnization = async (orgId) => {
        let response
        try {
            response = await axios.get(`organization/${orgId}`);
            if (response?.status) {
                setFile(response?.data?.organizationLogo?.base64)
            } else {
                failed(response?.error)
            }
        } catch (error) {
            failed(response?.error)
        }
    }

    useEffect(() => {
        if (modalShow?.id) {
            formik.setFieldValue("name", modalShow?.name || "");
            formik.setFieldValue("address", modalShow?.address?.[0]?.line?.[0] || "");
            formik.setFieldValue(
                "postalCode",
                modalShow?.address?.[0]?.postalCode || ""
            );
            setInputValue(modalShow?.address?.[0]?.postalCode || "");
            formik.setFieldValue("country", modalShow?.address?.[0]?.country || "");
            formik.setFieldValue("city", modalShow?.address?.[0]?.city || "");
            formik.setFieldValue("state", modalShow?.address?.[0]?.state || "");
            formik.setFieldValue(
                "phone_number",
                splitContactNumber(modalShow?.telecom[0]?.value || "")?.phone_number ||
                ""
            );
            formik.setFieldValue(
                "ext",
                splitContactNumber(modalShow?.telecom[0]?.value || "").ext
            );
            formik.setFieldValue("email", modalShow?.telecom?.[1]?.value || "");
            formik.setFieldValue("id", modalShow?.id || "");
            formik.setFieldValue("parent", modalShow?.partOf?.display || "");
            formik.setFieldValue("description", modalShow?.description || "");
            formik.setFieldValue(
                "appointment_cancel_time",
                modalShow?.appointmentCancellationTime?.unit || ""
            );
            formik.setFieldValue(
                "typeOfDeviceUnitType",
                modalShow?.deviceUnit?.type || ""
            );
            formik.setFieldValue(
                "deviceUnitType",
                modalShow?.deviceUnit?.groupName || ""
            );
            formik.setFieldValue("oxygen", modalShow?.deviceUnit?.oxygen?.oxygen);
            formik.setFieldValue(
                "heart_rate",
                modalShow?.deviceUnit?.heart_rate?.heartRate
            );
            formik.setFieldValue(
                "weight",
                modalShow?.deviceUnit?.weight?.weight || ""
            );
            formik.setFieldValue(
                "temperature",
                modalShow?.deviceUnit?.temperature?.temperature || ""
            );
            formik.setFieldValue(
                "blood_pressure",
                modalShow?.deviceUnit?.blood_pressure?.systolic || ""
            );
            formik.setFieldValue(
                "blood_glucose",
                modalShow?.deviceUnit?.blood_glucose?.glucose || ""
            );
            setCountry(modalShow?.address?.[0]?.country);
            setRegion(modalShow?.address?.[0]?.state);
            // setFile(modalShow?.organizationLogo?.url)
            formik.setFieldValue("isWaitingRoom", !!modalShow?.isWaitingRoom);
            formik.setFieldValue("satisfactionSurvey", !!modalShow?.surveySetting?.satisfactionSurvey);
            formik.setFieldValue("hoursOfOperation", !!modalShow?.hoursOfOperation);
            formik.setFieldValue("survey", modalShow?.surveySetting?.survey);

            formik.setFieldValue("expirationDays", (modalShow?.sft?.expirationDays / (60 * 60 * 24)) || 1);
            formik.setFieldValue("privacyStatement", modalShow?.sft?.privacyStatement || "");
            formik.setFieldValue("supportStatement", modalShow?.sft?.supportStatement || "");
            formik.setFieldValue("sftEnabled", modalShow?.sft?.enabled || "");
            formik.setFieldValue("active", modalShow?.active || true);
            formik.setFieldValue("patientApptAutoAcceptance", modalShow?.appointmentAutoAcceptance?.patientInitiated || "");
            formik.setFieldValue("practitionerApptAutoAcceptance", modalShow?.appointmentAutoAcceptance?.practitionerInitiated || "");
            formik.setFieldValue("mfaEnabled", modalShow?.mfaSettings?.enabled);



            if (!!modalShow?.isWaitingRoom && !!!modalShow?.hoursOfOperation) {
                const defaultOperationTimming = {}
                modalShow?.operationTiming?.forEach(({ weekDay, startTime, endTime }) =>
                    defaultOperationTimming[weekDay] = {
                        startTime: moment(startTime),
                        endTime: moment(endTime),
                    },
                )
                setWaitingRoomTimings(defaultOperationTimming);
            }
        } else {
            setCountry("");
            setRegion("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalShow]);

    const handleUnitOfDeviceChange = (key, value) => {
        formik.setFieldValue(key, value);
    };

    //validation if Country is selected but region not selected
    const handleCountryChange = (newCountry) => {
        setCountry(newCountry);
        if (newCountry !== formik.values.country) {
            formik.setFieldValue("state", ""); // Reset Formik state only if the country is changing
            formik.setFieldValue("postalCode", "");
            formik.setFieldValue("city", "");
            setRegion(""); // Reset component state only if the country is changing
            setInputValue("")
        }
    };

    const handleTimeChange = (day, value, key) => {
        key === "startTime"
            ? setWaitingRoomTimings({ ...waitingRoomTimings, [day]: { startTime: value, endTime: null } })
            : setWaitingRoomTimings({ ...waitingRoomTimings, [day]: { ...waitingRoomTimings[day], [key]: value } });
    };

    if (!formik.values?.id) {
        return null
    }
    const handlePostalCodeChange = (e) => {
        const { value } = e.target;
        // Remove spaces and non-alphanumeric characters
        const formattedValue = value.replace(/[^A-Za-z0-9]/g, "");
        // Add a space after every 3 letters
        const spacedValue = formattedValue.replace(
            /^([A-Za-z0-9]{3})([A-Za-z0-9]+)/,
            "$1 $2"
        );
        // Set the formatted value in the state or formik
        setInputValue(spacedValue);
        formik?.setFieldValue("postalCode", spacedValue);
    };

    const handleSwitchChange = (setter) => {
        setter((prevValue) => !prevValue);
    };
    const handleSFTToggle = (event) => {
        formik?.setFieldValue("sftEnabled", event.target.checked);
    };

    const handleMFAToggle = (event) => {
        formik.setFieldValue("mfaEnabled", event.target.checked);
    };
    const handleOrgActiveToggle = (event) => {
        formik?.setFieldValue("active", event.target.checked);
    }

    const handleApptAcceptance = (event, type) => {
        if (type === "patientApptAutoAcceptance") {
            formik?.setFieldValue("patientApptAutoAcceptance", event.target.checked);
        } else if (type === "practitionerApptAutoAcceptance") {
            formik?.setFieldValue("practitionerApptAutoAcceptance", event.target.checked);
        }
    }
    //   let margLeft =  formik.values.sftEnabled ? { marginLeft: '1px', marginRight: '-655px' } :  { marginLeft: '1px', marginRight: '-810px' } ;

    const isAdmin = userRoles?.includes('Super-Admin');
    const isPractitioner = userRoles?.includes('Practitioner');
    const isRPMEnabled = isPractitioner && orgLicensedProductsById?.RPM === true;
    const isVirtualCareEnabled = isPractitioner && orgLicensedProductsById?.virtualCare === true;

    /* Tabs */
    let tabTitles = [];
    if (isAdmin) {
        tabTitles = ["Details", "Virtual Visit", "RPM", "File Transfer", "Products"]
    } else if (isPractitioner) {

        // Details
        if (!tabTitles.includes("Details")) tabTitles.push("Details");
        if (userPermissions?.includes("Manage Organization")) {
            tabTitles.push("MFA");
        }
        // Virtual Visit
        if (isVirtualCareEnabled && !tabTitles.includes("Virtual Visit")) tabTitles.push("Virtual Visit");
        // RPM
        if (isRPMEnabled && !tabTitles.includes("RPM")) {
            if (!tabTitles.includes("Virtual Visit")) {
                // If "Virtual Visit" is not present, add "RPM" after "Details"
                const index = tabTitles.indexOf("Details") + 1;
                tabTitles.splice(index, 0, "RPM");
            } else {
                // If "Virtual Visit" is present, add "RPM" after it
                const index = tabTitles.indexOf("Virtual Visit") + 1;
                tabTitles.splice(index, 0, "RPM");
            }
        }
        // File Transfer
        if (!tabTitles.includes("File Transfer")) {
            if (tabTitles.includes("RPM")) {
                // If "RPM" is present, then add it after "RPM"
                const index = tabTitles.indexOf("RPM") + 1;
                tabTitles.splice(index, 0, "File Transfer");
            } else {
                if (tabTitles.includes("Virtual Visit")) {
                    // If "Virtual Visit" is present, add "File Transfer" after "Virtual Visit"
                    const index = tabTitles.indexOf("Virtual Visit") + 1;
                    tabTitles.splice(index, 0, "File Transfer");
                } else {
                    // If "Virtual Visit" is also not present, add "File Transfer" after "Details"
                    const index = tabTitles.indexOf("Details") + 1;
                    tabTitles.splice(index, 0, "File Transfer");
                }
            }
        }


    }

    const tabs = tabTitles.map(title => ({
        id: title,
        title: title,
        onClick: () => {
            if (selectedTab === "Virtual Visit") {
                formik.validateForm().then(errors => {
                    let isHoursOfOperationFalse = !!formik.values.isWaitingRoom && !!!formik.values.hoursOfOperation;
                    let hasValues = Object.values(waitingRoomTimings).some(value => value?.startTime || value?.endTime)
                    if (Object.keys(errors).length === 0 && !isHoursOfOperationFalse && hasValues) {
                        setSelectedTab(title);
                        setWaitingRoomTimingsError(false);
                    } else {
                        if (isHoursOfOperationFalse && !hasValues) {
                            setWaitingRoomTimingsError(true);
                        } else {
                            setWaitingRoomTimingsError(false);
                            if (Object.keys(errors).length === 0) {
                                setSelectedTab(title);
                            }
                        }
                    }
                });
            } else if (selectedTab === "Details") {
                formik.validateForm().then(errors => {
                    let imageLogoError = errors.orgLogoURL
                    if (Object.keys(errors).length === 0 && !imageLogoError) {
                        setSelectedTab(title);
                        setImageError("")
                    } else {
                        if (imageLogoError) setImageError(imageLogoError)
                    }
                })
            } else {
                formik.validateForm().then(errors => {
                    if (Object.keys(errors).length === 0) {
                        setSelectedTab(title);
                    }
                });
            }
        },
        selected: selectedTab === title
    }));

    /* Previous button */
    const handlePrevious = () => {
        const currentIndex = tabTitles.indexOf(selectedTab);
        const previousTab = tabTitles[currentIndex - 1];
        setSelectedTab(previousTab);
    };

    /* Next button */
    const handleNext = () => {
        if (selectedTab === "Virtual Visit") {
            formik.validateForm().then(errors => {
                let isHoursOfOperationFalse = !!formik.values.isWaitingRoom && !!!formik.values.hoursOfOperation;
                let hasValues = Object.values(waitingRoomTimings).some(value => value?.startTime || value?.endTime)
                if (Object.keys(errors).length === 0 && !isHoursOfOperationFalse && hasValues) {
                    const currentIndex = tabTitles.indexOf(selectedTab);
                    const nextTab = tabTitles[currentIndex + 1];
                    setSelectedTab(nextTab);
                    setWaitingRoomTimingsError(false);
                } else {
                    if (isHoursOfOperationFalse && !hasValues) {
                        setWaitingRoomTimingsError(true);
                    } else {
                        setWaitingRoomTimingsError(false);
                        if (Object.keys(errors).length === 0) {
                            const currentIndex = tabTitles.indexOf(selectedTab);
                            const nextTab = tabTitles[currentIndex + 1];
                            setSelectedTab(nextTab);
                        }
                    }
                }
            });
        } else if (selectedTab === "Details") {
            formik.validateForm().then(errors => {
                let imageLogoError = errors.orgLogoURL
                if (Object.keys(errors).length === 0 && !imageLogoError) {
                    const currentIndex = tabTitles.indexOf(selectedTab);
                    const nextTab = tabTitles[currentIndex + 1];
                    setSelectedTab(nextTab);
                    setImageError("")
                } else {
                    if (imageLogoError) setImageError(imageLogoError)
                }
            })
        } else {
            formik.validateForm().then(errors => {
                if (Object.keys(errors).length === 0) {
                    const currentIndex = tabTitles.indexOf(selectedTab);
                    const nextTab = tabTitles[currentIndex + 1];
                    setSelectedTab(nextTab);
                }
            });
        }
    };
    const handleExt = (event) => {
        let key = event.key;
        let regex = new RegExp("^[0-9]+$");
        if (!regex.test(key)) {
            event.preventDefault();
            return false;
        }
    };

    return (
        <Modal
            size="xl"
            show={modalShow}
            onHide={handleShow}
            aria-labelledby="contained-modal-title-vcenter"
            backdropClassName
            backdrop={"static"}
            centered
            className="custom-dialog popup-org"
        >
            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">
                    {ORGANIZATION_DETAILS}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form
                    className="common-form border-fields"
                    onSubmit={formik.handleSubmit}
                >
                    <Row>
                        {tabs.map(tab => (
                            <Col key={tab.id} sm={4} md={3} lg={2} className="mb-3 mb-lg-0" onClick={tab.onClick}>
                                <OrganizationCard title={tab.title} selected={tab.selected} />
                            </Col>
                        ))}
                    </Row><br />

                    {/* Details start */}
                    <div style={{ display: selectedTab === "Details" ? 'block' : 'none' }}>
                        <Row>
                            <Col>
                                {formik.values.parent === "Fonemed" ? null : (
                                    <TextInput
                                        readOnly={true}
                                        keyField={"parent"}
                                        label={PARENT_ORGANIZATION}
                                        required={false}
                                        formik={formik}
                                    />
                                )}
                            </Col>
                            <Col>
                                <TextInput
                                    keyField={"name"}
                                    label={NAME}
                                    formik={formik}
                                    placeholder={NAME}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextArea
                                    keyField={"description"}
                                    required={false}
                                    label={DESCRIPTION}
                                    formik={formik}
                                    placeholder={DESCRIPTION}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextInput
                                    keyField={"address"}
                                    label={ADDRESS}
                                    formik={formik}
                                    placeholder={ADDRESS}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <CountrySelect
                                    country={country}
                                    setCountry={handleCountryChange}
                                    keyField={"country"}
                                    label={COUNTRY}
                                    formik={formik}
                                />
                            </Col>
                            <Col>
                                <RegionSelect
                                    country={country}
                                    setRegion={setRegion}
                                    region={region}
                                    keyField={"state"}
                                    defaultOptionLabel={
                                        formik?.values?.country === "US"
                                            ? "Select State"
                                            : "Select Province"
                                    }
                                    label={formik?.values?.country === "US" ? "State" : "Province"}
                                    required
                                    formik={formik}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextInput
                                    keyField={"city"}
                                    label={CITY}
                                    formik={formik}
                                    placeholder={CITY}
                                />
                            </Col>

                            <Col>
                                {formik?.values?.country === "US" ? (
                                    <TextInput
                                        keyField={"postalCode"}
                                        label={
                                            formik?.values?.country === "US" ? ZIP_CODE : POSTAL_CODE
                                        }
                                        formik={formik}
                                        placeholder={
                                            formik?.values?.country === "US" ? ZIP_CODE : POSTAL_CODE
                                        }
                                        // max length for both zip code and postal code(plus space)
                                        maxLength={formik?.values?.country === "US" ? "5" : "7"}
                                    />
                                ) : (
                                    <TextInput
                                        onChange={handlePostalCodeChange}
                                        value={inputValue}
                                        keyField={"postalCode"}
                                        label={
                                            formik?.values?.country === "US" ? ZIP_CODE : POSTAL_CODE
                                        }
                                        formik={formik}
                                        placeholder={
                                            formik?.values?.country === "US" ? ZIP_CODE : POSTAL_CODE
                                        }
                                        // max length for both zip code and postal code(plus space)
                                        maxLength={formik?.values?.country === "US" ? "5" : "7"}
                                    />
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextInput
                                    type="phone"
                                    keyField={"phone_number"}
                                    label={PHONE_NUMBER}
                                    formik={formik}
                                    placeholder={PHONE_NUMBER}
                                />
                            </Col>
                            <Col>
                                {/* <TextInput
                                    keyField={"ext"}
                                    type="ext"
                                    label={EXT}
                                    formik={formik}
                                    placeholder={EXT}
                                    required={false}
                                /> */}
                                <TextInput
                                    keyField={"ext"}
                                    label={"Ext."}
                                    type="text"
                                    maxlength="4"
                                    formik={formik}
                                    placeholder={"Ext."}
                                    required={false}
                                    onKeyPress={handleExt}
                                    autocomplete="off"
                                />
                            </Col>
                            <Col>
                                <TextInput
                                    keyField={"email"}
                                    label={EMAIL_ADDRESS}
                                    formik={formik}
                                    placeholder={EMAIL_ADDRESS}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ maxWidth: "300px" }}>
                                <InputFile accept="image/jpeg, image/png" keyField={"orgLogoURL"} label={"Upload Logo"} formik={formik} placeholder={"Upload Logo"} setFile={setFile} file={file} imageUrl={file} />
                                <div>{imageError ? <div className="org-img-error">{imageError}</div> : null}</div>
                            </Col>
                        </Row>
                    </div>
                    {/* Details End */}

                    {/* MFA tab */}
                    <div style={{ display: selectedTab === "MFA" ? 'block' : 'none' }}>
                        <Row>
                            <Col><h5 style={{ color: "rgba(18, 31, 72, 1)" }}>Multi Factor Authentication Settings</h5></Col>
                        </Row>
                        <Row>

                            <Col>
                                <span >Enable</span>
                                <span style={{ marginRight: '175px' }}>&nbsp;&nbsp;</span>
                                <Switch name="mfaEnabled" onChange={handleMFAToggle} defaultChecked={modalShow?.mfaSettings?.enabled} />
                            </Col>
                        </Row>
                        <hr />

                        <Row>
                            <Col>
                                <p style={{ fontSize: '16px', marginTop: '10px' }}>
                                    By enabling MFA, add an extra layer of security to users under the organization. When enabled, users who do not have
                                    MFA enabled, will be enforced to setup MFA using Authenticator Apps or SMS based Authentication.
                                    This helps protect accounts from unauthorized access, even if password is compromised.
                                    We recommend keeping MFA enabled for enhanced security.
                                </p>
                            </Col>
                        </Row>

                        


                    </div>

                    {/* RPM start */}
                    {isAdmin || isRPMEnabled ?
                        <>
                            <div style={{ display: selectedTab === "RPM" ? 'block' : 'none' }}>
                                <Row>
                                    <Col>
                                        <SelectFiled
                                            keyField={"typeOfDeviceUnitType"}
                                            label={DEVICE_UNIT_TYPE}
                                            formik={formik}
                                            options={typeOfDeviceUnitTypeOptions}
                                            onChange={(e) =>
                                                formik.setFieldValue("typeOfDeviceUnitType", e.target.value)
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        {formik.values.typeOfDeviceUnitType === "group" ? (
                                            <SelectFiled
                                                keyField={"deviceUnitType"}
                                                label={TYPE}
                                                formik={formik}
                                                options={deviceUnitTypeOptions}
                                                onChange={(e) =>
                                                    formik.setFieldValue("deviceUnitType", e.target.value)
                                                }
                                            />
                                        ) : null}
                                    </Col>
                                </Row>
                                {formik.values.typeOfDeviceUnitType === "individual" ? (
                                    <Row>
                                        {devicesField?.map((field) => (
                                            <Col key={field.formikKey}>
                                                <SelectFiled
                                                    keyField={field.formikKey}
                                                    label={field.name}
                                                    formik={formik}
                                                    options={field.options}
                                                    onChange={(e) =>
                                                        handleUnitOfDeviceChange(field.formikKey, e.target.value)
                                                    }
                                                />
                                            </Col>
                                        ))}
                                    </Row>
                                ) : null}
                            </div>
                        </> : null}
                    {/* RPM end */}

                    {/* Virtual visit start */}
                    {isAdmin || isVirtualCareEnabled ?
                        <>
                            <div style={{ display: selectedTab === "Virtual Visit" ? 'block' : 'none' }}>
                                {userRoles?.includes('Super-Admin') || (userRoles?.includes('Practitioner') && orgLicensedProducts.virtualCare === true) ? <>
                                    <GetCareSettings formik={formik} waitingRoomTimings={waitingRoomTimings} waitingRoomTimingsError={waitingRoomTimingsError} isMobile={isMobile} handleTimeChange={handleTimeChange} />
                                    <hr />
                                    <SurveySettings formik={formik} />
                                    <hr /> </> : null}
                                {userRoles?.includes('Super-Admin') || userRoles?.includes('Practitioner') ?
                                    <>
                                        <Row>
                                            <Col><h5 style={{ color: "rgba(18, 31, 72, 1)" }}>Appointment Settings</h5></Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <span >Auto Acceptance - Patient Initiated</span>
                                                <span style={{ marginRight: '135px' }}>&nbsp;&nbsp;</span>
                                                <Switch name="patientApptAutoAcceptance" onChange={(e) => handleApptAcceptance(e, "patientApptAutoAcceptance")} defaultChecked={modalShow?.appointmentAutoAcceptance?.patientInitiated} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <span >Auto Acceptance - Practitioner Initiated</span>
                                                <span style={{ marginRight: '100px' }}>&nbsp;&nbsp;</span>
                                                <Switch name="practitionerApptAutoAcceptance" onChange={(e) => handleApptAcceptance(e, "practitionerApptAutoAcceptance")} defaultChecked={modalShow?.appointmentAutoAcceptance?.practitionerInitiated} />
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row>
                                            <Col>
                                                <TextInput
                                                    keyField={"appointment_cancel_time"}
                                                    label={APPOINTMENT_CANCEL_TIME}
                                                    formik={formik}
                                                    placeholder={APPOINTMENT_CANCEL_TIME}
                                                />
                                            </Col>
                                        </Row>
                                    </> : null}
                            </div>
                        </> : null}
                    {/* Virtual visit end */}

                    {/* File transfer start */}
                    <div style={{ display: selectedTab === "File Transfer" ? 'block' : 'none' }}>
                        <Row>
                            <Col><h5 style={{ color: "rgba(18, 31, 72, 1)" }}>SFT Settings</h5></Col>
                        </Row>
                        <Row>
                            {/* <Col style={margLeft}>SFT</Col>
                            <Col >
                            <Switch name="sftEnabled" onChange={handleSFTToggle} defaultChecked={modalShow?.sft?.enabled} />
                            </Col> */}
                            <Col>
                                <span >SFT</span>
                                <span style={{ marginRight: '175px' }}>&nbsp;&nbsp;</span>
                                <Switch name="sftEnabled" onChange={handleSFTToggle} defaultChecked={modalShow?.sft?.enabled} />
                            </Col>
                        </Row>
                        {formik.values.sftEnabled ?
                            <>
                                <SFTSettings
                                    delivertoIndividualSwitch={delivertoIndividualSwitch}
                                    delivertoLocationSwitch={delivertoLocationSwitch}
                                    onDeliverToIndSwitchChange={() => handleSwitchChange(setDelivertoIndividualSwitch)}
                                    onDeliverToLocSwitchChange={() => handleSwitchChange(setDelivertoLocationSwitch)}
                                />

                                <Row>
                                    <Col>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span>Expiration Days</span><span style={{ color: "red" }}>*</span>
                                            <span style={{ marginRight: '85px' }}>&nbsp;&nbsp;</span>
                                            <Tooltip title="Expiration days must be between 1 to 7">
                                                <div className="expiration-days-input"><TextInput
                                                    keyField={"expirationDays"}
                                                    formik={formik}
                                                    required={formik.values.sftEnabled ? true : false}
                                                />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col>
                                        <TextArea
                                            keyField={"privacyStatement"}
                                            required={formik.values.sftEnabled ? true : false}
                                            label={'Privacy Statement'}
                                            formik={formik}
                                            placeholder={''}
                                        />
                                    </Col>
                                    <Col></Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <TextArea
                                            keyField={"supportStatement"}
                                            required={formik.values.sftEnabled ? true : false}
                                            label={'Technical Support Statement'}
                                            formik={formik}
                                            placeholder={''}
                                        />
                                    </Col>
                                    <Col></Col>
                                </Row>

                            </> : null}
                    </div>
                    {/* File transfer End */}





                    {/* Products start */}
                    {userRoles?.includes('Super-Admin') && (
                        <div style={{ display: selectedTab === "Products" ? 'block' : 'none' }}>
                            {userRoles?.includes('Super-Admin') ? <>
                                <ProductFeatures
                                    rpmSwitch={rpmSwitch}
                                    virtualCareSwitch={virtualCareSwitch}
                                    callCenterSwitch={callCenterSwitch}
                                    onRpmSwitchChange={() => handleSwitchChange(setRpmSwitch)}
                                    onVirtualCareSwitchChange={() => handleSwitchChange(setVirtualCareSwitch)}
                                    onCallCenterSwitchChange={() => handleSwitchChange(setCallCenterSwitch)}
                                />
                                <hr /> </> : null}

                            {/* Organization Active/Inactive */}
                            {userRoles?.includes('Super-Admin') ? <>
                                <Row>
                                    <Col>
                                        <h5 style={{ color: "rgba(18, 31, 72, 1)" }}> Organization Active/Inactive Settings</h5>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <span >Active or Inactive </span>
                                        <span style={{ marginRight: '175px' }}>&nbsp;&nbsp;</span>
                                        <Switch name="active" onChange={handleOrgActiveToggle} defaultChecked={modalShow?.active} />
                                    </Col>
                                </Row>
                            </> : null}
                            {!formik.values.active ? <>
                                <div class="alert alert-warning" role="alert">
                                    Attention: Disabling organization will suspend access for all users for this organization, including practitioners, until it is enabled again.
                                </div>
                            </> : null}
                        </div>
                    )}
                    {/* Products End */}

                    <div className="btn-wrap">
                        {selectedTab === 'Details' && (
                            <Button onClick={handleShow} variant="secondary">{CANCEL}</Button>
                        )}
                        {selectedTab !== 'Details' && (
                            <Button onClick={handlePrevious} variant="secondary">Previous</Button>
                        )}
                        {(selectedTab !== 'Products' && userRoles?.includes('Super-Admin')) && (
                            <Button onClick={handleNext} variant="primary">Save & Next</Button>
                        )}
                        {(selectedTab !== 'File Transfer' && !userRoles?.includes('Super-Admin')) && (
                            <Button onClick={handleNext} variant="primary">Save & Next</Button>
                        )}
                        {(selectedTab === 'Products' && userRoles?.includes('Super-Admin')) && (
                            <Button type="submit" isLoading={btnLoading}>Update</Button>
                        )}
                        {(selectedTab === 'File Transfer' && !userRoles?.includes('Super-Admin')) && (
                            <Button type="submit" isLoading={btnLoading}>Update</Button>
                        )}
                    </div>
                </form>
            </Modal.Body>
        </Modal >
    );
}
