import React, { useEffect, useRef, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import Select from 'react-select';
import TextInput from "../../common/textfield/TextInput";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../common/Button";
import { failed, success } from "../../common/Toastify";
import { FormikObj, FormikObjEdit, categoryOptions, filterStatusOptions, handleExt, pagesOptions } from "./Constant";
import SelectFiled from "../../common/textfield/SelectFiled";
import { Tooltip } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { getPatientList, getSingleUser } from "../../api/Individual";
import { getLocationQuery, getOrgnizationForLocation } from "../../api/ClinicLocation";
import { decryptData } from "../../EncryptDecrypt";
import { editDocumentDashboard } from "../../api/FillableDocument.js";

function EditDocument() {
    const navigate = useNavigate();
    const location = useLocation()
    const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
    const user = useSelector((state) => state?.auth?.user)
    const [btnLoading, setBtnLoading] = useState(false);
    const [practionerList, setPractionerList] = useState([]);
    const [patientList, setPatientList] = useState([]);
    const [practitionerValue, setPractitionerValue] = useState({});
    const [patientValue, setPatientValue] = useState({});
    const [locationValue, setLocationValue] = useState({});
    const [locations, setLocations] = useState([]);
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)
    const {id, author, category, numPages, authenticator, subject, status, fromDocumentDashboard, submittedBy } = location?.state
    const filteredStatus = filterStatusOptions?.map(data => ({
      ...data,
      disabled: data.name === "New"
  }));

    const formik = useFormik({
        ...FormikObjEdit,
        onSubmit: async(values) => {
                setBtnLoading(true);
                if(submittedBy?.coding?.[0]?.code == "fax"){
                  values.locationId = ""
                  values.locationName = ""
                }
                editDocumentDashboard({ ...values , id, isForSRFax: true, orgId: adminOrgs?.[0]?.id, loginPractitionerName : user?.name?.[0]?.text})
                    .then((res) => {
                        if (res.status === true) {
                            fromDocumentDashboard ? navigate("/app/document-dashboard") : navigate("/app/dashboard", {state:{activeTab : true}}) 
                            success(res.message);
                        } else {
                            failed(res.error);
                        }
                    })
                    .catch((res) => {
                        failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                    })
                    .finally(() => setBtnLoading(false));
        },
    });

    const handleSource = (e)=>{
      setLocationValue(e)
      formik.setFieldValue("locationId", e?.value ? e?.value : "")
      formik.setFieldValue("locationName", e?.value === "other" ? "Other" : locations?.find((obj)=> obj?.value === e?.value)?.label)
    }

    const handleCategory = (e)=>{
      formik.setFieldValue("categoryCode", e.target.value)
      formik.setFieldValue("categoryDisplay", categoryOptions?.find((obj)=> obj?.value === e.target.value)?.name)
    }
    
    const handlePractitionerChange = (e)=>{
      setPractitionerValue(e)
      formik.setFieldValue("practitionerId", e?.value  ? e?.value : "")
      formik.setFieldValue("selectedPractitionerName", e?.value  ? practionerList?.find((obj)=> obj?.value === e?.value)?.label : "")
      if(status === "new"){
        formik.setFieldValue("status", e?.value  ? "assigned" : "new")
      }else{
        formik.setFieldValue("status", e?.value  ? status : "new")
      }
    }
    const handlePatientChange = (e)=>{
      formik.setFieldValue("patientId", e?.value ? e?.value : "")
      formik.setFieldValue("assignPatient", e?.value ? patientList?.find((obj)=> obj?.value === e?.value)?.label : "")
      setPatientValue(e)
    }

    const handleStatusChange =(e)=>{
      if(e.target.value === "new"){
        formik.setFieldValue("status", "assigned")
      }else{
        formik.setFieldValue("status", e.target.value)
      }
    }

    useEffect(() => {
      getSingleUser({ orgId : user?.organizations[0]?.id , type : "Practitioner"})
        .then((res) => {
          setPractionerList(res.data.map((ele) => ({
            id: ele.id,
            label: decryptData(ele.name[0]?.text?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')),
            value: ele.id,
          })))
        })
        .catch((res) => {
          console.log("error fetching practioner list",res)
        })

        getPatientList(user?.organizations[0]?.id)
        .then((res) => {
          setPatientList(res?.result?.map((ele) => ({
            id: ele.id,
            label: decryptData(ele.name[0]?.text?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')),
            value: ele.id,
          })))
        })
        .catch((res) => {
          console.log("error fetching practioner list",res)
        })

        fetchLocation()

        setPatientValue(subject?.reference !== "" ? {id : subject?.reference?.split("/")[1], label : subject?.display, value : subject?.reference?.split("/")[1]} : [])
        setPractitionerValue(authenticator?.[0]?.reference !== "" ? {id : authenticator?.[0]?.reference?.split("/")[1], label : authenticator?.[0]?.display, value : authenticator?.[0]?.reference?.split("/")[1]} : [])
        setLocationValue(submittedBy?.coding?.[0]?.code !== "fax" ? {id : author?.[0]?.reference?.split("/")[1], label : author?.[0]?.display, value : author?.[0]?.reference?.split("/")[1]} : [])
        submittedBy?.coding?.[0]?.code !== "fax" ? formik.setFieldValue("locationId", author?.[0]?.reference?.split("/")[1]) : formik.setFieldValue("locationId", author?.[0]?.display)
        formik.setFieldValue("locationName", author?.[0]?.display)
        formik.setFieldValue("categoryCode", category?.[0]?.coding?.[0]?.code)
        formik.setFieldValue("categoryDisplay", category?.[0]?.coding?.[0]?.display)
        formik.setFieldValue("pages", numPages)
        formik.setFieldValue("status", status)
        formik.setFieldValue("selectedPractitionerName", authenticator?.[0]?.display)
        formik.setFieldValue("assignPatient", subject?.display)
        formik.setFieldValue("practitionerId", authenticator?.[0]?.reference?.split("/")[1])
        formik.setFieldValue("patientId", subject?.reference?.split("/")[1])
    }, []);

    const fetchLocation = () =>{
      getOrgnizationForLocation().then((res)=>{
          getLocationQuery({orgnizationIds:  [user?.organizations[0]?.id], userRoles}).then((data)=>{
            setLocations(submittedBy?.coding?.[0]?.code !== "fax" ? [...data?.data?.map((obj)=> {
              return {
                id: obj?.id,
                value: obj?.id,
                label: obj?.name?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
              }
            }), {id: "other", value : "other", label : "Other"}] : []);
          })
      })
    }

    const handlePagesChange = (e)=>{
      let inputValue = e.target.value;
      if (inputValue.startsWith('0') && inputValue !== '0') {
        const newValue = inputValue.replace(/^0+/, '');
        formik.setFieldValue("pages", newValue)
      } else if (inputValue === '0') {
        formik.setFieldValue("pages", '')
      } else {
        formik.setFieldValue("pages", inputValue)
      }
    }

    return (
      <section className="common-listing">
        <div className="heading-wrap mb-3">
          <Tooltip title="Go back">
            <div
              style={{ marginRight: "1rem", cursor: "pointer" }}
              onClick={() =>
                fromDocumentDashboard
                  ? navigate("/app/document-dashboard")
                  : navigate("/app/dashboard", { state: { activeTab: true } })
              }
            >
              <ArrowBack />
            </div>
          </Tooltip>
          <h1>Document Details</h1>
        </div>
        <div className="custom-card p-4">
          <form
            className="common-form border-fields"
            onSubmit={formik.handleSubmit}
          >
            <Row>
              <Col>
                {submittedBy?.coding?.[0]?.code !== "fax" ? <div className="field-wrap">
                  <label className="searchSelectLabel">
                    Source <span style={{ color: "red" }}> *</span>
                  </label>
                  <Select
                    onChange={handleSource}
                    isMulti={false}
                    name="locationId"
                    options={locations}
                    value={locationValue}
                    className="search-select"
                    classNamePrefix="select"
                    isClearable={true}
                    autoFocus
                    isLoading={locations?.length > 0 ? false : true}
                  />
                  <div style={{ marginTop: "16px" }}>
                    {formik?.touched["locationId"] &&
                    formik?.errors["locationId"] ? (
                      <div className="error-text">
                        {formik?.errors["locationId"]}
                      </div>
                    ) : null}
                  </div>
                </div>  :
                <TextInput
                  keyField={"locationId"}
                  label={"Source"}
                  type="text"
                  formik={formik}
                  placeholder={"Source"}
                  disabled={true}
                />}
              </Col>
              <Col>
                <SelectFiled
                  keyField={"categoryCode"}
                  label={"Category"}
                  formik={formik}
                  options={categoryOptions}
                  onChange={handleCategory}
                />
              </Col>
              <Col>
                <TextInput
                  keyField={"pages"}
                  label={"Pages"}
                  type="text"
                  formik={formik}
                  placeholder={"Pages"}
                  onKeyPress={handleExt}
                  onChange={handlePagesChange}
                  autoComplete="off"
                  required={false}
                />
              </Col>
              <Col>
                <SelectFiled
                  keyField={"status"}
                  label={"Status"}
                  formik={formik}
                  options={filteredStatus}
                  onChange={handleStatusChange}
                  disabled={status === "new" ? true : false}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="field-wrap">
                  <label className="searchSelectLabel">
                    Assign Practitioner (Optional)
                  </label>
                  <Select
                    onChange={handlePractitionerChange}
                    isMulti={false}
                    name="practitionerId"
                    options={practionerList}
                    className="search-select"
                    classNamePrefix="select"
                    value={practitionerValue}
                    isClearable={true}
                    autoFocus
                    isLoading={practionerList?.length > 0 ? false : true}
                  />
                  <div>
                    {formik?.touched["practitionerId"] &&
                    formik?.errors["practitionerId"] ? (
                      <div className="error-text">
                        {formik?.errors["practitionerId"]}
                      </div>
                    ) : null}
                  </div>
                </div>
              </Col>
              <Col>
                <div className="field-wrap">
                  <label className="searchSelectLabel">
                    Assign Patient (Optional)
                  </label>
                  <Select
                    onChange={handlePatientChange}
                    isMulti={false}
                    name="patientId"
                    options={patientList}
                    className="search-select"
                    classNamePrefix="select"
                    isClearable={true}
                    value={patientValue}
                    autoFocus
                    isLoading={patientList?.length > 0 ? false : true}
                  />
                  <div>
                    {formik?.touched["patientId"] &&
                    formik?.errors["patientId"] ? (
                      <div className="error-text">
                        {formik?.errors["patientId"]}
                      </div>
                    ) : null}
                  </div>
                </div>
              </Col>
            </Row>
            <div
              className="btn-wrap"
              style={{ display: "flex", marginTop: "45px" }}
            >
              <Button
                onClick={() =>
                  fromDocumentDashboard
                    ? navigate("/app/document-dashboard")
                    : navigate("/app/dashboard", { state: { activeTab: true } })
                }
                variant="secondary"
                title="Cancel"
              >
                Cancel
              </Button>
              <Button type="submit" isLoading={btnLoading}>
                Save
              </Button>
            </div>
          </form>
        </div>
      </section>
    );
}

export default EditDocument;
