import Axios from 'axios';
import { apiUrl } from '../environment';
import store from "../components/store/Store"
import { userlogout } from '../components/slice/authService';


function authRequestInterceptor(config) {
    const token = localStorage.getItem("authentication");
    if (token) {
        config.headers.authorization = `${token}`;
    }
    config.headers.Accept = 'application/json';
    return config;
}

export const axios = Axios.create({
    baseURL: apiUrl,
});

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        // if (error.response?.data?.message === "The incoming token has expired") {
        //     //localStorage.clear();
        //     //dispatch(userlogout())
        // }
        if (error.response?.data?.message === "Unauthorized") {
            localStorage.clear();
            store.dispatch(userlogout())
        }
        return Promise.reject(error);
    }
);
