import React from "react";
import { Radio as MuiRadio } from '@mui/joy';


const Radio = ({ label, keyField, formik, required = false, tag="", name, value, handleChange = null, isChecked = false, radioLabel, hideRequired = false, readOnly = false, intialValue = "", showError = true, ...props }) => {
    const handleChangeMethod = (e) => {
        handleChange ? handleChange((props.useEvent) ? e : e.target.value, tag) : formik.setFieldValue(keyField, e.target.value)
    };

    return (
        <div>
            <MuiRadio
                checked={isChecked}
                onChange={handleChangeMethod}
                value={value}
                name={name}
                label={radioLabel}
                sx={{ mb: "16px" }}
            />
            {showError ? <div>
                {formik?.touched[keyField] && formik?.errors[keyField] ? (
                    <div className="error-text">{formik?.errors[keyField]}</div>
                ) : null}
            </div> : null}
        </div>
    );
};

export default Radio;
