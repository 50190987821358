import React, { useState } from 'react'

const InputFile = ({ label, icon = false, keyField, formik, accept = "image/*", disabled = false, rightIcon = false, required = false, file, setFile, imageUrl = null, ...props }) => {
    const [FileSelected, setFileSelected] = useState(false)
    function handleFileChange(event) {
        const files = event.target.files;
        formik.setFieldValue(keyField, files[0]);
        setFileSelected(files[0])
    }
    return (
        <div className="field-wrap">
            <label htmlFor={keyField}>
                {label}
                {required ? <span style={{ color: "red" }}> *</span> : " (Optional)"}
            </label>
            <div className={`${icon ? "input-icon" : ""}`}>
                {icon ? <img src={icon} alt="" title="" /> : null}
                { FileSelected?.type !== "application/pdf" && FileSelected ? <img height={100} width={200} src={URL?.createObjectURL(FileSelected)} alt={keyField} /> : imageUrl ? <img height={100} width={200} src={imageUrl} alt="selected file" /> :   FileSelected?.type !== "application/pdf" && <p>No Preview</p>}
                <input accept={accept} id={keyField} name={keyField} type="file" onChange={handleFileChange} />
                {rightIcon ? <img src={icon} alt="" title="" /> : null}
            </div>
            <div>{formik?.touched[keyField] && formik?.errors[keyField] ? <div className="error-text">{formik?.errors[keyField]}</div> : null}</div>
        </div>
    );
}

export default InputFile