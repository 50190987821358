import React from "react";
import Modal from 'react-bootstrap/Modal';
import DownloadIcon from '@mui/icons-material/Download';
import {Tooltip } from "@mui/material";

export function AttachementModal({ modalShow, handleShow, url, fromDocument }) {
  const fileExtension = url?.split('.').pop();
    return (
      <>
        <Modal
          backdropClassName
          backdrop={"static"}
          size="lg"
          show={modalShow}
          onHide={handleShow}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="custom-dialog"
        >
          <Modal.Header closeButton className="border-0">
            <Modal.Title
              id="contained-modal-title-vcenter"
              style={{ marginRight: "10px" }}
            >
             {fromDocument ? "Document" : "Attachment"}
            </Modal.Title>
            {fileExtension !== "pdf" && <Tooltip title="Download">
              <a href={url} className="view-btn" download>
                <DownloadIcon />
              </a>
            </Tooltip>}
          </Modal.Header>
          <Modal.Body>
            {fileExtension === "pdf" ?
              <iframe src={url} width="750" height="750"></iframe> : <img src={url} width="750" height="750"/>}
          </Modal.Body>
        </Modal>
      </>
    );
}