import * as Yup from "yup";

export const HeadCells = [
    {
        id: 'status',
        label: 'Status',
        type: 'text',
        nested: { key: "sendingDetails", value: { index: 0,  key: "status" } },
    }, 
    {
        id: 'subject_name',
        label: 'Patient name',
        type: 'text',
        nested: { key: "subject", value: { index: 0,  key: "name" } },
    },  
    {
        id: 'practitioner_name',
        label: 'Practitioner name',
        type: 'text',
        nested: { key: "sender", value: { index: 0,  key: "practitioner_name" } },
    }, 
    {
        id: 'send_time',
        label: 'Sent Time',
        type: 'dateTimeAmPm',
        nested: { key: "sendingDetails", value: { index: 0,  key: "timestamp" } },
    }, 
    {
        id: 'download_time',
        label: 'Download Time',
        type: 'dateTimeAmPm',
        nested: { key: "downloadDetails", value: { index: 0,  key: "timestamp" } },
    },
    {
        id: 'expire_time',
        label: 'Expire Time',
        type: 'dateTimeAmPm',
        nested: { key: "expiredTime" },
    },
    {
        id: 'document_type',
        label: 'Document type',
        type: 'text',
        nested: { key: "package", value: { index: 0,  key: "type" } },
    }, 
    {
        id: 'file_name',
        label: 'File Name',
        type: 'text',
        nested: { key: "package", value: { index: 0,  key: "name" } },
    },
];

export const HeadCellsFax = [
    {
        id: 'status',
        label: 'Status',
        type: 'text',
        nested: { key: "sendingDetails", value: { index: 0,  key: "status" } },
    }, 
    {
        id: 'subject_name',
        label: 'Patient name',
        type: 'text',
        nested: { key: "subject", value: { index: 0,  key: "name" } },
    },  
    {
        id: 'practitioner_name',
        label: 'Practitioner name',
        type: 'text',
        nested: { key: "sender", value: { index: 0,  key: "practitioner_name" } },
    }, 
    {
        id: 'send_time',
        label: 'Sent Time',
        type: 'dateTimeAmPm',
        nested: { key: "sendingDetails", value: { index: 0,  key: "timestamp" } },
    },  
    {
        id: 'document_type',
        label: 'Document type',
        type: 'text',
        nested: { key: "package", value: { index: 0,  key: "type" } },
    }, 
    {
        id: 'file_name',
        label: 'File Name',
        type: 'text',
        nested: { key: "package", value: { index: 0,  key: "name" } },
    },
];
export const DataFields = [  
    'status',
    'subject_name',  
    'practitioner_name', 
    'send_time',   
    'download_time',
    'expire_time', 
    'document_type',
    'file_name'
];

export const DataFieldsFax = [  
    'status',
    'subject_name',  
    'practitioner_name', 
    'send_time',  
    'document_type',
    'file_name'
];


export const listSendOptions = [ 
    { value: "Email", name: "Patient/Email" },
    { value: "FAX", name: "Location/Fax" }, 
    //{ value: "Device", name: "Device" },
]
 
 