import { useState } from "react";
import { Modal } from "react-bootstrap";
import { axios } from "../../lib/axios";
import { Button } from "../common/Button";
import { failed, success } from "../common/Toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import TextArea from "../common/textfield/TextArea";
import { encryptData } from "../EncryptDecrypt";


export function AddNote({ show, onHide, encounterId, patientName }) {
    const [loading, setLoading] = useState(false);
    const formik = useFormik({
        initialValues: {
            note: "",
        },
        validationSchema: Yup.object({
            note: Yup.string().required("Required note field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        }),
        onSubmit: (values) => {
            setLoading(true)
            axios.post("/note", {
                id: encounterId,
                "patientInstruction": encryptData(values.note),
            }).then((res) => { success(res.message); onHide() })
                .catch(res => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                .finally(() => setLoading(false));
        },
    });

    return (
        <Modal
            show={show}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered className="custom-dialog"
            onHide={onHide}
            backdropClassName
            backdrop={'static'}
        >
            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter" style={{ textTransform: "capitalize" }}>
                    Add Note for {patientName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="common-form" onSubmit={formik.handleSubmit}>
                    <div className="field-wrap">
                        <TextArea keyField={"note"} label={"Add Note"} formik={formik} />
                    </div>
                    <div className="btn-wrap" style={{ display: 'flex' }}>
                        <Button onClick={onHide} variant="secondary" title="Cancel">Cancel</Button>
                        <Button type="submit" isLoading={loading}>Save</Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}