import { axios } from "../../../lib/axios";

export const changePassword = (data) => {
    return axios.post(`auth/change-password`, {
        oldPassword: data?.currentPass,
        newPassword: data?.password,
    })
}
function addProfileImage(newData, base64ProfileImage,profileExtension) {
    return {
        ...newData,
        "photo": {
            "url": base64ProfileImage,
            "extension": profileExtension
        },
    }
} 
function addSign(newData, signatureImage,signExtension) { 
    return {
        ...newData,
        "signatureURL": {
            "url": signatureImage,
            "extension": signExtension
        },
    }
}
function addCertificate(newData, base64LicenseImage,fileExtension) {
    return {
        ...newData,
        "practitionerCertificate": {
            "url": base64LicenseImage,
            "extension": fileExtension
        }, 
    }
}
export const editUserProfile = (data, encryptedData, country, state, base64ProfileImage,profileExtension,base64LicenseImage,fileExtension,userData,signatureImage,signExtension) => {
   
    const apiData = { ...userData }
    
    let newData = {
        name: [
            {
                use: "usual",
                text: encryptedData.full_name,
                family: encryptedData.last_name,
                given: [encryptedData.first_name],
            },
        ],
        gender: apiData?.gender,
        birthDate: encryptedData.date_of_birth,
        telecom: [
            {
                system: "phone",
                value: encryptedData.phone_number,
                use: "work",
            },
            {
                system: "email",
                value: apiData?.telecom?.find((ele) => ele?.system === "email")?.value?.toLowerCase(),
                use: "work",
            },            
                {
                    system : "phone",
                    value : data?.home_phone_number,
                    use : "home"
                },
        ],
        address: [
            {
                use: "home",
                line: [encryptedData.address],
                country: country,
                city: encryptedData.city,
                state: state,
                postalCode: encryptedData.postalCode,
            },
        ],
        email: apiData?.telecom?.find((ele) => ele?.system === "email")?.value?.toLowerCase(),
     
        qualification : [
            {
                identifier: [
                    {
                        use : "usual",
                        type: {
                            text: "license"
                        },
                        value: data?.license
                    }
                ],
                code: {
                    coding: [
                        {
                            text : data?.qualification,
                            display : "college"
                        }
                    ]
                }
            }
        ],
        designation : data?.designation,      
        managingOrganization: apiData?.managingOrganization,
        type: apiData?.type,
        role: apiData?.role,
      //  scopeOfPractice: data?.scopeOfPractice
      scopeOfPractice: data?.jurisdiction 
    };
    if (base64ProfileImage !== "") {
        newData = addProfileImage(newData, base64ProfileImage,profileExtension)
    }
    if (base64LicenseImage !== "") {
        newData = addCertificate(newData, base64LicenseImage,fileExtension)
    }

    if (signatureImage !== "" ) { 
        newData = addSign(newData, signatureImage,signExtension)
    }
      return axios.put("/users", { ...newData, id: data?.id, });
}
