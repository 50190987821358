import { axios } from "../../../../lib/axios";

export const createMedicationRequest = (payload) => {
    return axios.post(`/medicationrequest`, payload);
};

export const editMedicationRequest = ({ id, ...payload }) => {
    return axios.put(`/medicationrequest/${id}`, { ...payload });
};

export const deleteMedicationRequest = (medicationId) => {
    return axios.put(`/medicationrequest/${medicationId}`, {
        active: false
    });
};