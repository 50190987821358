import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SearchBar from "../../common/components/SearchBar";
import CustomTable from "../../common/table/CustomTable";
import { UsersDataFields, UsersHeadCells } from "./Constants";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import { failed, success } from "../../common/Toastify";
import { ArrowBack } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { decryptData } from "../../EncryptDecrypt";
import { Button } from "../../common/Button";
import AddUser from "./AddUser";
import { editGroup } from "../../api/Group";
import { useGroupsQuery } from "../../../hooks/ReactQueryHooks/useGroupsQuery";
import { useUsersQuery } from "../../../hooks/ReactQueryHooks/useUsersQuery";

const GroupUsersListing = () => {
    let { state } = useLocation();
    const navigate = useNavigate();
    const { getConfirmation } = useConfirmDialog();
    const [filterData, setFilterData] = useState([]);
    const [page, setPage] = useState(0);
    const [deleting, setDeleting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [groupData, setGroupData] = useState({});
    const [usersList, setUsersList] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [alreadyAddedUserIds, setAlreadyAddedUserIds] = useState(false);
    const groupId = state?.id;
    const groupType = state?.type === "Patient" || state?.type === "Practitioner" ? state?.type : null;
    const groupIsDefault = !!state?.isDefault;
    const groupIsRoot = !!state?.isRoot;

    const onUsersSuccess = (data) => {
        if (data) {
            setUsersList(data)
            refetchGroup()
        }
    }

    const { isLoading, isFetching, } = useUsersQuery({
        queryParams: groupIsRoot
            ? state?.type === "Organization_Administrators"
                ? `isGetOrgAdmin=true`
                : null
            : `orgId=${state?.orgId || []}&type=${groupType}`,
        onSuccess: onUsersSuccess,
        refetchOnWindowFocus: false,
    });

    const onGroupSuccess = (data) => {
        if (data) {
            const groupMember = data?.[0]?.member?.map((item) => item?.entity?.id);
            setGroupData(data?.[0])
            setAlreadyAddedUserIds(groupMember)
            setSelectedUsers(groupIsDefault ? usersList?.filter((item) => !groupMember?.includes(item.id)) : usersList?.filter((item) => groupMember?.includes(item.id)));
        }
    }

    const { isFetching: groupIsFetching, refetch: refetchGroup } = useGroupsQuery({ onSuccess: onGroupSuccess, groupId, refetchOnWindowFocus: false, })

    const handleFilterData = (data) => {
        setFilterData(data);
    };

    const selectedUserAction = ({ action, data }) => {
        switch (action) {
            case "delete":
                handleDelete(data?.id, decryptData(data?.name?.[0]?.text));
                break;
            default:
                return null;
        }
    };

    const handleDelete = async (id, entryName) => {
        const confirmed = await getConfirmation({
            title: "Attention!",
            entryName,
            actionBtnTitle: "Delete",
        });
        if (confirmed) {
            setDeleting(id);
            let member = selectedUsers.filter((item) => groupIsDefault ? item.id === id : item.id !== id).map((item) => (
                {
                    entity: {
                        id: item.id,
                        reference: `${(groupIsDefault && groupType) ? groupType : item?.type?.[0]}/${item.id}`,
                        type: (groupIsDefault && groupType) ? groupType : item?.type?.[0]
                    },
                    inactive: groupIsDefault
                }
            ));
            member = groupIsDefault ? [...member, ...groupData?.member] : member;
            editGroup({ groupId, member, memberCount: groupIsDefault ? Math.abs(member.length - usersList.length) : member.length })
                .then((res) => {
                    const members = member.map((item) => item?.entity?.id)
                    setAlreadyAddedUserIds(members)
                    setSelectedUsers(groupIsDefault ? selectedUsers.filter((item) => !members?.includes(item.id)) : selectedUsers.filter((item) => members?.includes(item.id)));
                    success("User Deleted!");
                    refetchGroup()
                })
                .catch((err) => failed(err?.response?.data?.message || err.message))
                .finally(() => setDeleting(false));
        }
    };

    if(filterData) {
        filterData?.map((data) => {
            const modifiedName = data?.name?.[0]?.text?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
            data.name[0].text = modifiedName;
        });
      }

    return (
        <section className="common-listing">
            {showModal
                ? <AddUser
                    modalShow={showModal}
                    refreshTable={refetchGroup}
                    usersList={usersList}
                    groupId={groupId}
                    existingGroupMembers={groupData?.member}
                    alreadyAddedUserIds={alreadyAddedUserIds}
                    groupIsDefault={groupIsDefault}
                    groupUsersType={groupType}
                    selectedUsers={selectedUsers}
                    handleShow={() => setShowModal(false)}
                /> : null}
            <div className="heading-wrap h-change">
                <Tooltip title="Go back">
                    <div style={{ marginRight: "1rem", cursor: "pointer" }} onClick={() => navigate(-1)}>
                        <ArrowBack />
                    </div>
                </Tooltip>
                <SearchBar page={page} setPage={setPage} list={selectedUsers} filterData={handleFilterData} encoded={true} />
                <div className="right-wrap" style={{ margin: 'left' }}>
                    <Button isLoading={isLoading || isFetching || groupIsFetching} onClick={() => setShowModal(true)} className="custom-btn" title="Add Group" style={{ width: 'auto' }}>Add</Button>
                </div>
            </div>
            <CustomTable
                tableData={filterData}
                headCells={UsersHeadCells}
                dataFields={UsersDataFields}
                selectedUserAction={selectedUserAction}
                rowOptions={false}
                loading={isLoading}
                fetching={isFetching || groupIsFetching}
                deletingItem={deleting}
                canDelete={true}
                canEdit={false}
                page={page}
                setPage={setPage}
            />
        </section>
    );
};

export default GroupUsersListing;
