import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { axios } from "../../lib/axios";
import { onError } from "./onError"

export function useNotifyQuery({ onSuccess }) {
    const userType = useSelector((state) => state?.auth?.user?.['cognito:groups']);
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations);
    const orgIds = userType.includes("Super-Admin") ? null : adminOrgs?.map(org => org.id)
    const fetch = () => {
        return orgIds
            ? axios.get(`notification?orgId=${JSON.stringify(orgIds)}`)
            : axios.get("notification")
    }
    const query = useQuery(
        "notifications",
        fetch,
        {
            select: (data) => data.data,
            onSuccess,
            onError
        }
    );
    return { ...query };
}
