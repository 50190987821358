import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import TextInput from "../../common/textfield/TextInput";
import { useNavigate } from "react-router-dom";
import { Button } from "../../common/Button";
import { failed, success } from "../../common/Toastify";
import { createQuestionnaire } from "../../api/Questionnaire";
import { useQuestionQuery } from "../../../hooks/ReactQueryHooks/useQuestionQuery";
import { answerTypeOptions, appointmentType, purposeOption, SurveyFormikObj } from "./Constants";
import { Tooltip } from "@mui/material";
import { Add, ArrowBack, Remove } from "@mui/icons-material";
import SelectFiled from "../../common/textfield/SelectFiled";
import { useSelector } from "react-redux";
import { useSurveyQuery } from "../../../hooks/ReactQueryHooks/useSurveyQuery";
const iconStyle = {
    width: "35px",
    height: "35px",
    borderRadius: "10px",
    backgroundColor: "rgba(0, 93, 168, 0.12)",
    textAlign: "center",
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
}

function CreateSurveys() {
    // const { isLoading, isFetching } = useQuestionQuery({ onSuccess: () => { } });
    const onSuccess = (data) => { setDataList(data) }
    const { isLoading, refetch, isFetching } = useSurveyQuery({ onSuccess });
    const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
    const navigate = useNavigate();
    const [btnLoading, setBtnLoading] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [rows, setRows] = useState([
        {
            id: Date.now(),
            text: "",
            type: "",
            highRateText: "",
            lowRateText: "",
            required: "",
        }
    ]);

    // if(dataList?.[0]?.status ==='active' && dataList?.[0]?.status != undefined){navigate("/app/dashboard");}

    const formik = useFormik({
        ...SurveyFormikObj,
        onSubmit: async (values) => {
            setBtnLoading(true);
            const questions = values?.question?.map((row, index) => ({
                text: row.text,
                type: row.type,
                highRatingText: row.highRateText,
                lowRatingText: row.lowRateText,
                required: row.required,
            }));
            const requestBody = {
                title: values.title,
                keyString: values.keyString,
                question: questions,
                isDefault: true,
                //  purpose: values?.purpose, 
                purpose: "survey-appointment",
                description: values.description,
                status: 'active'
            };
            createQuestionnaire(requestBody).then((res) => {
                navigate("/app/manage-surveys");
                success(res.message);
            }).catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                .finally(() => setBtnLoading(false));
        },
    });

    const addRow = () => {
        const newRow = { id: Date.now(), text: "", type: "stars", highRateText: "", lowRateText: "", required: false, checked: "" };
        formik.setFieldValue("question", [...formik.values.question, newRow])
    };


    const removeRow = (index) => {
        const updatedQuestions = [...formik.values.question];
        updatedQuestions.splice(index, 1);
        formik.setFieldValue("question", updatedQuestions);
    };


    const handleAnswerTypeChange = (index, newValue) => {
        formik.setFieldValue(`question.[${index}].type`, newValue);
    };

    const handleIsMandatoryChange = (index) => {
        formik.setFieldValue(`required-${index}`);
        setRows((prevRows) => {
            const updatedRows = [...prevRows];
            updatedRows[index].required = formik.setFieldValue(`required-${index}`); // Toggle isMandatory
            return updatedRows;
        });
    };

    const handlePurposeChange = (e) => {
        formik.setFieldValue('purpose', e.target.value);
    }


    return (
        <section className="common-listing">
            <div className="heading-wrap mb-3">
                <Tooltip title="Go back">
                    <div
                        style={{ marginRight: "1rem", cursor: "pointer" }}
                        onClick={() => navigate(-1)}
                    >
                        <ArrowBack />
                    </div>
                </Tooltip>
            </div>
            <div className="custom-card p-4">
                <form
                    className="common-form border-fields"
                    onSubmit={formik.handleSubmit}
                >
                    <Row>
                        <Col>
                            <TextInput keyField={"title"} label={"Title"} formik={formik} placeholder={"Write Title"} />
                        </Col>
                        <Col>
                            <SelectFiled
                                keyField={"purpose"}
                                label={"Purpose"}
                                readOnly={true}
                                formik={formik}
                                options={purposeOption}
                            // onChange={handlePurposeChange}
                            />
                        </Col>
                    </Row>
                    <hr />
                    {formik.values.question?.map((row, index) => (
                        <div key={row.id}>
                            <Row >
                                <Col style={{ marginBottom: '-1.5rem' }}>
                                    <TextInput
                                        keyField={`question[${index}].text`}
                                        label={"Survey Question"}
                                        formik={formik}
                                        placeholder={"Write Here"}
                                        isError={formik?.touched?.question?.[index]?.text && formik?.errors?.question?.[index]?.text}
                                    />
                                </Col>
                                <Col style={{ display: "flex", alignItems: "center", gap: "1rem", marginBottom: '-1.5rem' }}>
                                    <SelectFiled
                                        keyField={`question.[${index}].type`}
                                        label={"Answer Type"}
                                        formik={formik}
                                        options={answerTypeOptions}
                                        onChange={(e) => handleAnswerTypeChange(index, e.target.value)}
                                        isError={formik?.touched?.question?.[index]?.type && formik?.errors?.question?.[index]?.type}
                                    />
                                    {index > 0 && (
                                        <div style={iconStyle}>
                                            <Remove
                                                style={{ width: "inherit", height: 'inherit', color: "rgba(0, 93, 168, 1)" }}
                                                onClick={() => removeRow(index)}
                                            />
                                        </div>
                                    )}
                                    {index === 0 && (
                                        <div style={iconStyle}>
                                            <Add
                                                style={{ width: "inherit", height: 'inherit', color: "rgba(0, 93, 168, 1)" }}
                                                onClick={addRow}
                                            />
                                        </div>
                                    )}
                                </Col>
                            </Row>
                            <Row > <Col style={{ marginBottom: '1.5rem' }}> </Col> </Row>
                            <Row >
                                {formik.values.question[index].type !== "freeText" && (
                                    <>
                                        <Col style={{ marginBottom: '0rem' }}>
                                            <TextInput
                                                keyField={`question[${index}].highRateText`}
                                                label={"High Rating Text"}
                                                formik={formik}
                                                placeholder={"Write Here"}
                                                required={false}
                                                isError={formik?.touched?.question?.[index]?.highRateText && formik?.errors?.question?.[index]?.highRateText}
                                            />
                                        </Col>
                                        <Col style={{ marginBottom: '0rem' }}>
                                            <TextInput
                                                keyField={`question[${index}].lowRateText`}
                                                label={"Low Rating Text"}
                                                formik={formik}
                                                placeholder={"Write Here"}
                                                required={false}
                                                isError={formik?.touched?.question?.[index]?.lowRateText && formik?.errors?.question?.[index]?.lowRateText}
                                            />
                                        </Col>
                                    </>
                                )}
                                <Col style={{ marginBottom: '0rem' }}>
                                    <div className="field-wrap" style={{ margin: 0, display: "flex", height: "100%", alignItems: "center" }}>
                                        <label className="custom-checkbox" style={{ margin: "15px 0 0", width: "fit-content" }}>
                                            Is Mandatory
                                            <input
                                                style={{ width: "auto" }}
                                                keyField={`required-${index}`}
                                                type="checkbox"
                                                checked={formik.values?.question[index].required}
                                                onChange={() => { formik.setFieldValue(`question[${index}].required`, !formik.values?.question[index].required) }}
                                            />
                                            <span keyField={`checked-${index}`} className="checkmark"></span>
                                        </label>
                                    </div>
                                </Col>
                                <Col></Col>
                            </Row>
                        </div>
                    ))}
                    <hr />
                    <Row>
                        <Col>
                            <TextInput keyField={"description"} label={"Thank You Message"} formik={formik} placeholder={"Write Message"} />
                        </Col>
                        <Col></Col>
                    </Row>
                    <div className="btn-wrap" style={{ display: 'flex' }}>
                        <Button onClick={() => navigate("/app/manage-surveys")} variant="secondary" title="Cancel">Cancel</Button>

                        <Button type="submit" isLoading={btnLoading} >
                            Create
                        </Button>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default CreateSurveys;
