import React, { useContext, useEffect, useRef, useState } from "react";
import './message.css';
import './audio-video.css';
import ChatIcon from '../../assets/images/Chat.svg';
import ExtendIcon from '../../assets/images/extend.svg';
import CallIcon from '../../assets/images/call-icon.svg';
import ZoomVideo from '@zoom/videosdk';
import ZoomContext from "../../ContextsAndProvider/Zoom-Context";
import { BindEvents, getSignature, toggleFullscreen } from "../Zoom/functions";
import { failed, info } from "../common/Toastify";
import { Tooltip } from "@mui/material";
import { MicOffOutlined, MicOutlined, VideocamOffOutlined, VideocamOutlined } from "@mui/icons-material";
import { decryptData } from "../EncryptDecrypt";
import { FullPageSpinner } from "../common/Spinner/FullPageSpinner";
import { useNavigate } from "react-router-dom";
import { ZoomChatBox } from "./ZoomChatBox";


function ZoomJoin({ topic, pass, userName, navigateOnEndMeet = false }) {
    const navigate = useNavigate()
    const [mediaStream, setMediaStream] = useState(null);
    const [chatClient, setChatClient] = useState(null);
    const [currentUser, setCurrentUser] = useState({});
    const [participants, setParticipants] = useState([]);
    const [currentSession, setCurrentSession] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('');
    const [sessionChat, setSessionChat] = useState([]);
    const [showChat, setShowChat] = useState(true);
    const [isCameraOn, setIsCameraOn] = useState(false);
    const [isCameraLoading, setIsCameraLoading] = useState(false);
    const [isMicOn, setIsMicOn] = useState(false);
    const [isAudioStreaming, setIsAudioStreaming] = useState(false);
    const chatInput = useRef();
    const zmClient = useContext(ZoomContext);
    const audioDecode = useRef();
    const audioEncode = useRef();

    const startVideo = () => {
        setIsCameraLoading(true)
        if (mediaStream.isCameraTaken() && mediaStream.isCaptureForbidden()) {
            failed("Check your camera, either it is taken or not available or don't have permission!", 4000);
        } else {
            mediaStream.startVideo({ videoElement: document.querySelector('#self-view-video') }).then(() => {
                // console.log("----------------current user started video----------");
                // console.log(zmClient.getAllUser());
                setIsCameraOn(true);
                setIsCameraLoading(false)
            }).catch((error) => {
                console.log("---------camera error----------", error);
                setIsCameraLoading(false)
                failed("Check your camera, either it is taken or not available or don't have permission!", 4000);
            })
        }
    }

    const startAudio = () => {
        var isSafari = window.safari !== undefined
        if (isSafari) {
            if (audioEncode.current && audioDecode.current) {
                mediaStream.startAudio()
                setIsAudioStreaming(true);
            } else {
                // console.log('safari audio has not finished initializing')
            }
        } else {
            mediaStream.startAudio()
            setIsAudioStreaming(true);
        }
    }


    useEffect(() => {
        const init = async () => {
            await zmClient.init('en-US');
            try {
                if (!currentSession) {
                    setIsLoading(true);
                    setLoadingText('Joining the session...');
                    getSignature({ topic: decryptData(topic), role: 0, password: decryptData(pass), userName }).then(async (res) => {
                        await zmClient.join(decryptData(topic), res.data.sessionToken, userName, decryptData(pass)).then((res) => {
                            setCurrentSession(res);
                            setCurrentUser(zmClient.getCurrentUserInfo());
                            const chat = zmClient.getChatClient();
                            setSessionChat(chat.getHistory());
                            const stream = zmClient.getMediaStream();
                            setParticipants(zmClient.getAllUser())
                            zmClient.on('chat-on-message', (payload) => {
                                setSessionChat(payload?.sender?.userId === zmClient?.getCurrentUserInfo()?.userId ? chat.getHistory() : [...chat.getHistory(), payload])
                            })

                            zmClient.on('media-sdk-change', (payload) => {
                                if (payload.type === 'audio' && payload.result === 'success') {
                                    if (payload.action === 'encode') {
                                        audioEncode.current = true
                                    } else if (payload.action === 'decode') {
                                        audioDecode.current = true
                                    }
                                }
                            })
                            // ensure that user has joined the stream
                            zmClient.on('command-channel-status', (payload) => {
                                payload === "Connected" && setIsLoading(false)
                            })

                            // if any user joins the session
                            zmClient.on('user-added', (payload) => {
                                localStorage.setItem("userJoined",1)
                                if(payload[0]?.isHost === true) {
                                    if(localStorage.getItem("userJoined") !== 1 || localStorage.getItem("userJoined") === null){
                                       payload[0].userId !== currentUser?.userId && info(`New User Joined, ${payload[0].displayName}`)
                                    }
                                }else{
                                    payload[0].userId !== currentUser?.userId && info(`New User Joined, ${payload[0].displayName}`)
                                }
                                setParticipants(zmClient.getAllUser())
                                // console.log("----- User Joined ----->", payload[0].userId, payload[0].displayName);
                            })

                            // if user left or removed from the session
                            zmClient.on('user-removed', (payload) => {
                                info(`${payload[0].displayName} left the session`)
                                setParticipants(zmClient.getAllUser())
                                // console.log(payload[0]?.userId, ' left the session', zmClient.getAllUser());
                            })

                            BindEvents(zmClient, stream)

                            setMediaStream(stream);
                            setChatClient(chat);
                        }).catch((err) => { throw err });
                    }).catch((err) => {
                        setIsLoading(false);
                        failed(err.reason || err.type)
                        navigateOnEndMeet ? navigateOnEndMeet() : navigate(-1)
                    });
                }
            } catch (e) {
                setIsLoading(false);
                failed(e.reason);
                navigate(-1)
            }
        };
        if (topic && pass && userName) init();
        return () => {
            ZoomVideo.destroyClient();
            zmClient.off('user-removed', () => { })
            zmClient.off('user-added', () => { })
            zmClient.off('media-sdk-change', () => { })
            zmClient.off('chat-on-message', () => { })
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pass, topic, userName, zmClient]);
    const handleCamera = () => {
        isCameraOn ? mediaStream.stopVideo() : startVideo()
        isCameraOn && setIsCameraOn(false)
    }
    const handleMic = () => {
        !isAudioStreaming && startAudio()
        isMicOn ? mediaStream.muteAudio() : mediaStream.unmuteAudio();
        setIsMicOn(!isMicOn)
    }

    const handleLeaveSession = () => {
        zmClient.leave().then(() => {
            // console.log("session left!");
            document.getElementById("participants-view").replaceChildren();
            navigateOnEndMeet ? navigateOnEndMeet() : navigate(-1)
            zmClient.off()
        }).catch((err) => console.log(err))
    }
    const handleSendMsg = (e) => {
        e.preventDefault();
        chatInput.current.value ? chatClient.sendToAll(chatInput.current.value) : chatInput.current.focus();
        chatInput.current.value = ""
    }
    useEffect(() => {
        participants.forEach((participant) => {
            if (participant.userId === currentUser.userId) return null
            const CanvasElem = document.querySelector(`#participant-canvas-${participant.userId}`)
            const AvatarElem = document.querySelector(`#participant-avatar-${participant.userId}`)
            if (participant.bVideoOn) {
                AvatarElem.style.display = "none";
                mediaStream.renderVideo(CanvasElem, participant.userId, 300, 160, 0, 0, 2)
            } else {
                CanvasElem.style.display = "none";
            }
        })
    }, [currentUser.userId, mediaStream, participants])

    const tempStyleParticipants = {
        gridTemplateColumns: "repeat(2,1fr)",
        gridTemplateRows: "repeat(2,1fr)",
        display: "grid",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "0",
    }

    if (!(topic && pass && userName)) {
        return <h1>Do not have the meeting details!</h1>
    }

    return (
        <section className="common-listing">
            {isLoading && <FullPageSpinner loadingText={loadingText} />}
            <div className="heading-wrap diff-style">
                <h1>
                    <span className="big-head">Welcome {currentUser.displayName}</span>
                </h1>
            </div>

            <div className="call-chat-outer">
                <div className="call-screen-wrap" style={{ aspectRatio: "16 / 9" }} id={'room'}>

                    <div className="my-view-block d-flex h-100 position-relative">
                        {/* -----------user's video------------- */}
                        <div className="my-view-video flex-fill d-flex justify-content-center">
                            <div className="inner">
                                <video className={`${participants.length > 1 ? "video-wrap" : ""} main-video-wrap`} id="self-view-video"></video>
                                {/*<canvas width="1920" height="1080" className="main-video-wrap" id="self-view-canvas"></canvas>*/}
                            </div>
                        </div>
                    </div>
                    {/* -----------participant's video------------- */}
                    <div id="participants-view" style={{ width: "100%" }}>
                        <div id="participants-view-inner" style={participants.length > 2 ? tempStyleParticipants : null}>
                            {participants.map((participant) => {
                                const name = participant?.displayName?.split(' ');
                                if (participant.userId === currentUser.userId) return null
                                return (
                                    <div className="participant-view" id={`${participant.userId}`} key={participant.userId}>
                                        <div className="dropdown-container" style={{ display: "none" }}>
                                            <div className="three-dots"></div>
                                            <div className="dropdown">
                                                <div id={`pin-${participant.userId}`}>Pinned</div>
                                            </div>
                                        </div>
                                        <canvas style={{ display: participant.bVideoOn ? "block" : "none" }} className="participant-canvas" id={`participant-canvas-${participant.userId}`}></canvas>
                                        <div style={{ display: participant.bVideoOn ? "none" : "flex" }} className="participant-avatar" id={`participant-avatar-${participant.userId}`}><div>{name?.[0]?.[0] || ""}{name?.[1]?.[1] || ""}</div></div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {/* -----------Tool bar for handling the session actions------------- */}
                    <div className="tool-wrap">
                        <Tooltip title="Speaker" onClick={handleMic}><span>{isMicOn ? <MicOutlined sx={{ color: "grey" }} /> : <MicOffOutlined sx={{ color: "grey" }} />}</span></Tooltip>
                        <Tooltip title="Video Cam" onClick={() => !isCameraLoading && handleCamera()}><span>{isCameraOn ? <VideocamOutlined sx={{ color: "grey" }} /> : <VideocamOffOutlined sx={{ color: "grey" }} />}</span></Tooltip>
                        <Tooltip title="Chat" onClick={() => setShowChat(!showChat)}><span><img src={ChatIcon} alt="Chat" /></span></Tooltip>
                        <Tooltip title="Full Screen" onClick={toggleFullscreen}><span><img src={ExtendIcon} alt="Full Screen" /></span></Tooltip>
                        <Tooltip className="call-wrap" title="Leave Call" onClick={handleLeaveSession}><span><img src={CallIcon} alt="Call" /></span></Tooltip>
                    </div>
                </div>
                {showChat
                    ? <ZoomChatBox currentUser={currentUser} sessionChat={sessionChat} chatInput={chatInput} handleSendMsg={handleSendMsg} />
                    : null}
            </div>
        </section>
    );
}

export default ZoomJoin;