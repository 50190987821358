import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
// import DownloadIcon from '@mui/icons-material/Download';
// import { downloadBtnText } from "../Patient/Constants";
// import {Tooltip } from "@mui/material";
import { useGetDocumentById } from "../../../hooks/ReactQueryHooks/useGetDocument";
import heic2any from 'heic2any';
import Box from '@mui/material/Box';
// import FileViewer from 'react-file-viewer';
import CircularProgress from '@mui/material/CircularProgress';

export function AttachementModal({ modalShow, handleShow, documentData, url, docData, fromDocument }) {
  const fileExtension = url?.split('.').pop();
  const [document, setDocument] = useState(null)
  const [convertedUrl, setConvertedUrl] = useState(null);
  const [loadingData, setLoadingData ] = useState(false);
  const [error, setError ] = useState("");

  const onSuccess = (data) => {
    setDocument(data);
  };
  useGetDocumentById({ onSuccess, id : docData?.id });


  const convertHeicToJpeg = async () => {
    try {
      setLoadingData(true);
      const blob = await fetch(documentData?.signedUrl).then(res => res.blob());
      const convertedBlob = await heic2any({ blob });
      const convertedUrl = URL.createObjectURL(convertedBlob);
      setConvertedUrl(convertedUrl);
      setLoadingData(false);
    } catch (error) {
      setConvertedUrl(documentData?.signedUrl);
      setLoadingData(false);
    }
  };

  useEffect(() => {
      if (fileExtension === "heic") {
        convertHeicToJpeg();
      } else {
        setConvertedUrl(documentData?.signedUrl);
        // Check if the image can be loaded
        const img = new Image();
        img.onload = () => {
          // console.log("Image loaded successfully.");
        };
        // error handler
        img.onerror = () => {
          setError("The file you are attempting to access is not compatible.");
        };
        img.src = documentData?.signedUrl;
      }

    return () => {
      if (convertedUrl) {
        URL.revokeObjectURL(convertedUrl);
      }
    };
  }, [url,fileExtension]);

  // const renderDocumentViewer = () => {
  //   if (fileExtension === "docx" || fileExtension === "doc") {
  //     if(convertedUrl) {
  //       return (
  //         <FileViewer fileType={fileExtension} filePath={convertedUrl} width="1100" height="1100"/>
  //       );
  //     }
  //   }
  // };

    return (
      <>
        <Modal
          backdropClassName
          backdrop={"static"}
          size="xl"
          show={modalShow}
          onHide={handleShow}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="custom-dialog"
        >
          <Modal.Header closeButton className="border-0">
            <Modal.Title
              id="contained-modal-title-vcenter"
              style={{ marginRight: "10px" }}
            >
             {/* {fromDocument ? "Document" : "Attachment"} */}
            </Modal.Title>
            {/* {fileExtension !== "pdf" && <Tooltip title={downloadBtnText}>
              <a href={url} className="view-btn" download>
                <DownloadIcon />
              </a>
            </Tooltip>} */}
          </Modal.Header>
          <Modal.Body>
          <Box sx={{ width: '100%', overflow: "hidden" }}>
            {loadingData ? 
              (
                  <div className="box-wrap" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "10vh" }}>
                      <CircularProgress />
                  </div>
              ) : 
              <>
                {fileExtension === "pdf" ?
                  document?.code === "AccessDenied" ?  <span>{document?.message}</span> : <iframe src={fromDocument ? document?.content?.[0]?.attachment?.base64String : convertedUrl} width="1100" height="1100"></iframe> : (error ? <span className="jpg-img-error">{error}</span> : <img src={fromDocument ? document?.content?.[0]?.attachment?.base64String : convertedUrl} width="1100" height="1100"/>)
                }
              </> 
            }
            </Box>
          </Modal.Body>
        </Modal>
      </>
    );
}