import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import TextInput from "../../common/textfield/TextInput";
import { Button } from "../../common/Button";
import SelectFiled from "../../common/textfield/SelectFiled";
import { failed, success } from "../../common/Toastify";
import TextArea from "../../common/textfield/TextArea";
import { createNotification, updateNotification } from "../../api/Notification";
import { FormikObj, deliveryOptions, recipientOptions, notificationTypeOptions } from "./Constants";
import TextEditor from "../../common/textfield/TextEditor";
import MultiSelect from "../../common/textfield/MultiSelect";
import { useNotificationEventQuery } from "../../../hooks/ReactQueryHooks/useNotificationEventQuery";
import { useSelector } from "react-redux";
import { useGroupsQuery } from "../../../hooks/ReactQueryHooks/useGroupsQuery";
import { useOrgQuery } from "../../../hooks/ReactQueryHooks/useOrgQuery";
const eventsToCheck = ["sfkZmDPckn","uhMBmFQckl", "hjBMFmcQlk","dsgWxEQumr"]

export function UpdateNotificationModal({ modalShow, handleShow, refreshTable, isReadOnly, isOverRide }) {
    const [btnLoading, setBtnLoading] = useState(false);
    const [eventList, setEventList] = useState([]);
    const [groups, setGroups] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [customGroupId, setCustomGroupId] = useState([]);
    const onSuccess = (data) => {
        setOrganizations(data?.map((org) => (org = { ...org, value: org.id })));
    };
    const { isLoading } = useOrgQuery({ onSuccess });

    useNotificationEventQuery({ onSuccess: (data) => setEventList(data?.map((event) => ({ value: event?.id, name: event?.eventName, id: event?.id }))) })
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)
    const isSystemTypeEvent = modalShow?.eventType === "system";

    const formik = useFormik({
        ...FormikObj,
        onSubmit: (values) => {
            setBtnLoading(true);
            if (isOverRide) {
                createNotification({
                    ...values,
                    eventName: eventList?.filter((event) => event?.id === values?.eventId)?.[0]?.name,
                    content: values.deliveryMechanisms.includes("push_notification") || values.deliveryMechanisms.includes("sms") ? values.content : "",
                    emailContent: values.deliveryMechanisms.includes("email") ? values.emailContent : ""
                })
                    .then((res) => {
                        handleShow();
                        refreshTable();
                        success(res.message);
                    })
                    .catch((err) => failed(err?.response?.data?.message || err.message))
                    .finally(() => setBtnLoading(false));
            } else {
                updateNotification({
                    ...values,
                    notificationId: modalShow?.id,
                    eventName: eventList?.filter((event) => event?.id === values?.eventId)?.[0]?.name,
                    content: values.deliveryMechanisms.includes("push_notification") || values.deliveryMechanisms.includes("sms") ? values.content : "",
                    emailContent: values.deliveryMechanisms.includes("email") ? values.emailContent : "",
                })
                    .then((res) => {
                        success(res.message);
                        handleShow();
                        refreshTable();
                    })
                    .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                    .finally(() => setBtnLoading(false));
            }
        },
    });

    useGroupsQuery({
        onSuccess: (data) => setGroups(data.map(({ name, id }) => ({ value: id, id, name }))),
        queryParams: isOverRide ? `orgId=["${formik?.values?.orgId}"]&isCustom=true` : `orgId=["${modalShow?.orgId}"]&isCustom=true`,
        enabled : isOverRide ? !!formik?.values.orgId : !!modalShow?.orgId,
    })

    useEffect(() => {
        if (modalShow?.id) {
            formik.setFieldValue("title", modalShow?.title || "");
            formik.setFieldValue("type", isOverRide ? "custom" : modalShow?.type || "");
            formik.setFieldValue("deliveryMechanisms", modalShow?.deliveryMechanisms || []);
            formik.setFieldValue("orgId", isOverRide ? adminOrgs[0]?.id : modalShow?.orgId);
            formik.setFieldValue("content", modalShow?.content || "");
            formik.setFieldValue("emailContent", modalShow?.emailContent || "");
            formik.setFieldValue("eventId", modalShow?.eventId || "");
            formik.setFieldValue("recipients", modalShow?.recipients || []);
            formik.setFieldValue("emailSubject", modalShow?.emailSubject || "");
            formik.setFieldValue("customGroupId", modalShow?.customGroupId || []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalShow]);

    if (!modalShow) {
        return null;
    }

    return (
        <Modal
            backdropClassName
            backdrop={'static'}
            show={modalShow} onHide={handleShow} size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered className="custom-dialog">
            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">{isOverRide ? "Over-ride" : isReadOnly ? "View" : "Edit"} Notification</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="common-form border-fields" onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col>
                            <SelectFiled readOnly={isReadOnly || isSystemTypeEvent || true} keyField={"type"} label={"Notification type"} formik={formik} placeholder={"What is the title of this notification?"} options={notificationTypeOptions} />
                        </Col>
                        <Col>
                            <SelectFiled readOnly={isReadOnly || isSystemTypeEvent || true} keyField={"eventId"} label={"Event/Topic"} formik={formik} placeholder={"Event name of your notification?"} options={eventList} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <MultiSelect readOnly={isReadOnly || isSystemTypeEvent} keyField={"recipients"} label={"Select Recipients"} formik={formik} options={recipientOptions} defaultValue={formik?.values?.recipients} isSelectAll={formik?.values?.recipients.length > 0 && formik?.values?.recipients.length === recipientOptions.length} isSelectAllEnable={true}/>
                        </Col>
                        <Col>
                        { isOverRide && formik?.values.type === "custom" ? <SelectFiled keyField={"orgId"} label={"Select Organization"} formik={formik} options={organizations} /> : null}
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col>
                            <MultiSelect readOnly={isReadOnly || isSystemTypeEvent} keyField={"deliveryMechanisms"} label={"Select Delivery Mechanism"} formik={formik} options={deliveryOptions} defaultValue={modalShow?.deliveryMechanisms} />
                        </Col>
                        {!isOverRide  ? (<Col>
                            {eventsToCheck.includes(formik?.values?.eventId)  ? <MultiSelect  required={false} keyField={"customGroupId"} label={"Custom Group"} formik={formik} options={groups} defaultValue={modalShow?.customGroupId} nodata={'No data found'}/> : null}
                        </Col>) :
                        (<Col>
                            {eventsToCheck.includes(formik?.values?.eventId)  ? <MultiSelect  required={false} keyField={"customGroupId"} label={"Custom Group"} formik={formik} options={groups} defaultValue={customGroupId} bindMethod={(val) => setCustomGroupId(val)} nodata={'No data found'} /> : null}
                        </Col>)}
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextInput readOnly={isReadOnly || isSystemTypeEvent} keyField={"title"} label={"Notification Title"} formik={formik} placeholder={"Title"} />
                        </Col>
                        <Col>
                            {formik?.values?.deliveryMechanisms.includes("email") ? <TextInput readOnly={isReadOnly || isSystemTypeEvent} keyField={"emailSubject"} label={"Email Subject"} formik={formik} placeholder={"Subject"} /> : null}
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row style={{ display: formik?.values?.deliveryMechanisms?.includes("push_notification") || formik?.values?.deliveryMechanisms?.includes("sms") ? "block" : "none" }}>
                        <Col>
                            <TextArea keyField={"content"} label={"Content"} formik={formik} placeholder={"Content..."} />
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row style={{ display: formik?.values?.deliveryMechanisms?.includes("email") ? "block" : "none" }}>
                        <Col>
                            <TextEditor keyField={"emailContent"} readOnly={isReadOnly} formik={formik} label={"Email Content"} defaultValue={formik.values.emailContent} />
                        </Col>
                    </Row>
                    <div style={{ color: "red" }}>{formik.errors.condition}</div>
                    {!isReadOnly ? (
                        <div className="btn-wrap">
                            <Button onClick={handleShow} variant="secondary" title="Cancel">
                                Cancel
                            </Button>
                            <Button type="submit" isLoading={btnLoading}>
                                {isOverRide ? "Create" : "Update Details"}
                            </Button>
                        </div>
                    ) : null}
                </form>
            </Modal.Body>
        </Modal>
    );
}
