import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../manage-group.css';
import { addThresholdGroup, updateThresholdGroup } from "../../../apps/api/ThresholdGroup/index"
import { failed, success } from "../../../common/Toastify";
import { Button } from "../../../common/Button";
// import { useNotifyQuery } from "../../../../hooks/ReactQueryHooks/useNotifyQuery";
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { useFormik } from "formik";
import MultiSelect from "../../../common/textfield/MultiSelect";
import { FormikObj, GetVitalsUnit, ageGroupOptions, } from "../Constants";
import TextInput from "../../../common/textfield/TextInput";
import { useSelector } from "react-redux";
import { convertVitalsBaseValueToOrgLevelUnit, convertVitalsValueToBaseValue } from "../../../Admin/Schedule/constants";
import { useGetOrgVitalsUnits } from "../../../../hooks/ReactQueryHooks/useGetOrgVitalsUnits";

function CreateGroupDialog({ show, onHide, orgId, onUpdateThreshold, data, isReadOnly=false }) {
    const [btnLoading, setBtnLoading] = useState(false);
    const [isConditionsEmpty, setIsConditionsEmpty] = useState(false);
    // const [notificationList, setNotificationList] = useState([]);
    // const [notification, setNotification] = useState("")
    const userOrg = useSelector((state) => state?.auth?.user?.organizations);
    const { data: orgVitalsUnit } = useGetOrgVitalsUnits({ orgId: userOrg[0]?.id })

    // const onSuccess = (data) => {
    //     setNotificationList(data);
    // };
    // const { refetch, } = useNotifyQuery({ onSuccess });

    const formik = useFormik({
        ...FormikObj,
        onSubmit: ({ age, groupName, bp, hr, bloodglucose, weight, temperature, oxygen, bpDiastolicbelowM, bpDiastolicAboveM, bpSystolicbelowM, bpSystolicAboveM, bpDiastolicbelowH, bpDiastolicAboveH, bpSystolicbelowH, bpSystolicAboveH, belowWeightM, aboveWeightM, belowWeightH, aboveWeightH, belowhrM, abovehrM, belowhrH, abovehrH, belowOxygenM, aboveOxygenM, belowOxygenH, aboveOxygenH, belowBloodglucoseM, aboveBloodglucoseM, belowBloodglucoseH, aboveBloodglucoseH, belowTempM, aboveTempM, belowTempH, aboveTempH }) => {
            if (bp || hr || bloodglucose || weight || temperature || oxygen) {
                setIsConditionsEmpty(false)
                let conditionData = {};
                if (bp) {
                    conditionData["blood_pressure"] = [
                        {
                            "diastolic": {
                                "type": "medium",
                                "below": convertVitalsValueToBaseValue(bpDiastolicbelowM, orgVitalsUnit?.blood_pressure?.systolic),
                                "above": convertVitalsValueToBaseValue(bpDiastolicAboveM, orgVitalsUnit?.blood_pressure?.systolic),
                            },
                            "systolic": {
                                "type": "medium",
                                "below": convertVitalsValueToBaseValue(bpSystolicbelowM, orgVitalsUnit?.blood_pressure?.systolic),
                                "above": convertVitalsValueToBaseValue(bpSystolicAboveM, orgVitalsUnit?.blood_pressure?.systolic),
                            }
                        },
                        {
                            "diastolic": {
                                "type": "high",
                                "below": convertVitalsValueToBaseValue(bpDiastolicbelowH, orgVitalsUnit?.blood_pressure?.systolic),
                                "above": convertVitalsValueToBaseValue(bpDiastolicAboveH, orgVitalsUnit?.blood_pressure?.systolic),
                            },
                            "systolic": {
                                "type": "high",
                                "below": convertVitalsValueToBaseValue(bpSystolicbelowH, orgVitalsUnit?.blood_pressure?.systolic),
                                "above": convertVitalsValueToBaseValue(bpSystolicAboveH, orgVitalsUnit?.blood_pressure?.systolic),
                            }
                        }
                    ]
                }
                if (weight) {
                    conditionData["weight"] = [
                        {
                            "type": "medium",
                            "below": convertVitalsValueToBaseValue(belowWeightM, orgVitalsUnit?.weight?.weight),
                            "above": convertVitalsValueToBaseValue(aboveWeightM, orgVitalsUnit?.weight?.weight)
                        },
                        {
                            "type": "high",
                            "below": convertVitalsValueToBaseValue(belowWeightH, orgVitalsUnit?.weight?.weight),
                            "above": convertVitalsValueToBaseValue(aboveWeightH, orgVitalsUnit?.weight?.weight)
                        }
                    ]
                }
                if (temperature) {
                    conditionData["temperature"] = [
                        {
                            "type": "medium",
                            "below": convertVitalsValueToBaseValue(belowTempM, orgVitalsUnit?.temperature?.temperature),
                            "above": convertVitalsValueToBaseValue(aboveTempM, orgVitalsUnit?.temperature?.temperature)
                        },
                        {
                            "type": "high",
                            "below": convertVitalsValueToBaseValue(belowTempH, orgVitalsUnit?.temperature?.temperature),
                            "above": convertVitalsValueToBaseValue(aboveTempH, orgVitalsUnit?.temperature?.temperature)
                        }
                    ]
                }
                if (oxygen) {
                    conditionData["oxygen"] = [
                        {
                            "oxygen": {
                                "type": "medium",
                                "below": convertVitalsValueToBaseValue(belowOxygenM, orgVitalsUnit?.oxygen?.oxygen),
                                "above": convertVitalsValueToBaseValue(aboveOxygenM, orgVitalsUnit?.oxygen?.oxygen)
                            }
                        },
                        {
                            "oxygen": {
                                "type": "high",
                                "below": convertVitalsValueToBaseValue(belowOxygenH, orgVitalsUnit?.oxygen?.oxygen),
                                "above": convertVitalsValueToBaseValue(aboveOxygenH, orgVitalsUnit?.oxygen?.oxygen)
                            }
                        },
                    ]
                }
                if (hr) {
                    conditionData["heart_rate"] = [
                        {
                            "heartRate": {
                                "type": "medium",
                                "below": convertVitalsValueToBaseValue(belowhrM, orgVitalsUnit?.heart_rate?.heartRate),
                                "above": convertVitalsValueToBaseValue(abovehrM, orgVitalsUnit?.heart_rate?.heartRate)
                            },
                        },
                        {
                            "heartRate": {
                                "type": "high",
                                "below": convertVitalsValueToBaseValue(belowhrH, orgVitalsUnit?.heart_rate?.heartRate),
                                "above": convertVitalsValueToBaseValue(abovehrH, orgVitalsUnit?.heart_rate?.heartRate)
                            },
                        },
                    ]
                }
                if (bloodglucose) {
                    conditionData["blood_glucose"] = [
                        {
                            "type": "medium",
                            "below": convertVitalsValueToBaseValue(belowBloodglucoseM, orgVitalsUnit?.blood_glucose?.glucose),
                            "above": convertVitalsValueToBaseValue(aboveBloodglucoseM, orgVitalsUnit?.blood_glucose?.glucose)
                        },
                        {
                            "type": "high",
                            "below": convertVitalsValueToBaseValue(belowBloodglucoseH, orgVitalsUnit?.blood_glucose?.glucose),
                            "above": convertVitalsValueToBaseValue(aboveBloodglucoseH, orgVitalsUnit?.blood_glucose?.glucose)
                        }
                    ]
                }

                let newData = {
                    groupName,
                    condition: conditionData,
                    age,
                    orgId,
                    // notifications: [
                    //     notification
                    // ]
                }
                if (data?.id) {
                    newData["id"] = data?.id;
                    setBtnLoading(true)
                    updateThresholdGroup(newData).then(async (res) => {
                        onHide();
                        success(res.message);
                        await onUpdateThreshold()
                    }).catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                        .finally(() => setBtnLoading(false));
                }
                else {
                    setBtnLoading(true)
                    addThresholdGroup(newData).then(async (res) => {
                        onHide();
                        success(res.message);
                        await onUpdateThreshold()
                    }).catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                        .finally(() => setBtnLoading(false));
                }
            } else {
                setIsConditionsEmpty(true)
            }
        }
    })

    useEffect(() => {
        // refetch();
        const is_blood_pressure = data?.condition?.blood_pressure,
            is_weight = data?.condition?.weight,
            is_oxygen = data?.condition?.oxygen,
            is_hr = data?.condition?.heart_rate,
            is_blood_glucose = data?.condition?.blood_glucose,
            is_temperature = data?.condition?.temperature;
        // setNotification(data?.notifications?.[0])
        formik.setFieldValue("groupName", data?.groupName || "")
        formik.setFieldValue("age", data?.age)
        formik.setFieldValue("bp", !!is_blood_pressure)
        formik.setFieldValue("hr", !!is_hr)
        formik.setFieldValue("oxygen", !!is_oxygen)
        formik.setFieldValue("weight", !!is_weight)
        formik.setFieldValue("bloodglucose", !!is_blood_glucose)
        formik.setFieldValue("temperature", !!is_temperature)

        formik.setFieldValue("bpDiastolicbelowM", convertVitalsBaseValueToOrgLevelUnit(is_blood_pressure?.[0]?.diastolic?.below, orgVitalsUnit?.blood_pressure?.systolic) || "")
        formik.setFieldValue("bpDiastolicAboveM", convertVitalsBaseValueToOrgLevelUnit(is_blood_pressure?.[0]?.diastolic?.above, orgVitalsUnit?.blood_pressure?.systolic) || "")
        formik.setFieldValue("bpSystolicbelowM", convertVitalsBaseValueToOrgLevelUnit(is_blood_pressure?.[0]?.systolic?.below, orgVitalsUnit?.blood_pressure?.systolic) || "")
        formik.setFieldValue("bpSystolicAboveM", convertVitalsBaseValueToOrgLevelUnit(is_blood_pressure?.[0]?.systolic?.above, orgVitalsUnit?.blood_pressure?.systolic) || "")

        formik.setFieldValue("bpDiastolicbelowH", convertVitalsBaseValueToOrgLevelUnit(is_blood_pressure?.[1]?.diastolic?.below, orgVitalsUnit?.blood_pressure?.systolic) || "")
        formik.setFieldValue("bpDiastolicAboveH", convertVitalsBaseValueToOrgLevelUnit(is_blood_pressure?.[1]?.diastolic?.above, orgVitalsUnit?.blood_pressure?.systolic) || "")
        formik.setFieldValue("bpSystolicbelowH", convertVitalsBaseValueToOrgLevelUnit(is_blood_pressure?.[1]?.systolic?.below, orgVitalsUnit?.blood_pressure?.systolic) || "")
        formik.setFieldValue("bpSystolicAboveH", convertVitalsBaseValueToOrgLevelUnit(is_blood_pressure?.[1]?.systolic?.above, orgVitalsUnit?.blood_pressure?.systolic) || "")

        formik.setFieldValue("belowWeightM", convertVitalsBaseValueToOrgLevelUnit(is_weight?.[0]?.below, orgVitalsUnit?.weight?.weight) || "")
        formik.setFieldValue("aboveWeightM", convertVitalsBaseValueToOrgLevelUnit(is_weight?.[0]?.above, orgVitalsUnit?.weight?.weight) || "")
        formik.setFieldValue("belowWeightH", convertVitalsBaseValueToOrgLevelUnit(is_weight?.[1]?.below, orgVitalsUnit?.weight?.weight) || "")
        formik.setFieldValue("aboveWeightH", convertVitalsBaseValueToOrgLevelUnit(is_weight?.[1]?.above, orgVitalsUnit?.weight?.weight) || "")

        formik.setFieldValue("belowhrM", convertVitalsBaseValueToOrgLevelUnit(is_hr?.[0]?.heartRate?.below, orgVitalsUnit?.heart_rate?.heartRate) || "")
        formik.setFieldValue("abovehrM", convertVitalsBaseValueToOrgLevelUnit(is_hr?.[0]?.heartRate?.above, orgVitalsUnit?.heart_rate?.heartRate) || "")
        formik.setFieldValue("belowhrH", convertVitalsBaseValueToOrgLevelUnit(is_hr?.[1]?.heartRate?.below, orgVitalsUnit?.heart_rate?.heartRate) || "")
        formik.setFieldValue("abovehrH", convertVitalsBaseValueToOrgLevelUnit(is_hr?.[1]?.heartRate?.above, orgVitalsUnit?.heart_rate?.heartRate) || "")

        formik.setFieldValue("belowOxygenM", convertVitalsBaseValueToOrgLevelUnit(is_oxygen?.[0]?.oxygen?.below, orgVitalsUnit?.oxygen?.oxygen) || "")
        formik.setFieldValue("aboveOxygenM", convertVitalsBaseValueToOrgLevelUnit(is_oxygen?.[0]?.oxygen?.above, orgVitalsUnit?.oxygen?.oxygen) || "")
        formik.setFieldValue("belowOxygenH", convertVitalsBaseValueToOrgLevelUnit(is_oxygen?.[1]?.oxygen?.below, orgVitalsUnit?.oxygen?.oxygen) || "")
        formik.setFieldValue("aboveOxygenH", convertVitalsBaseValueToOrgLevelUnit(is_oxygen?.[1]?.oxygen?.above, orgVitalsUnit?.oxygen?.oxygen) || "")

        formik.setFieldValue("belowBloodglucoseM", convertVitalsBaseValueToOrgLevelUnit(is_blood_glucose?.[0]?.below, orgVitalsUnit?.blood_glucose?.glucose) || "")
        formik.setFieldValue("aboveBloodglucoseM", convertVitalsBaseValueToOrgLevelUnit(is_blood_glucose?.[0]?.above, orgVitalsUnit?.blood_glucose?.glucose) || "")
        formik.setFieldValue("belowBloodglucoseH", convertVitalsBaseValueToOrgLevelUnit(is_blood_glucose?.[1]?.below, orgVitalsUnit?.blood_glucose?.glucose) || "")
        formik.setFieldValue("aboveBloodglucoseH", convertVitalsBaseValueToOrgLevelUnit(is_blood_glucose?.[1]?.above, orgVitalsUnit?.blood_glucose?.glucose) || "")

        formik.setFieldValue("belowTempM", convertVitalsBaseValueToOrgLevelUnit(is_temperature?.[0]?.below, orgVitalsUnit?.temperature?.temperature) || "")
        formik.setFieldValue("aboveTempM", convertVitalsBaseValueToOrgLevelUnit(is_temperature?.[0]?.above, orgVitalsUnit?.temperature?.temperature) || "")
        formik.setFieldValue("belowTempH", convertVitalsBaseValueToOrgLevelUnit(is_temperature?.[1]?.below, orgVitalsUnit?.temperature?.temperature) || "")
        formik.setFieldValue("aboveTempH", convertVitalsBaseValueToOrgLevelUnit(is_temperature?.[1]?.above, orgVitalsUnit?.temperature?.temperature) || "")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    return (
        <Modal
            show={show}
            onHide={() => onHide()}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered className="custom-dialog"
            backdropClassName
            backdrop={'static'}
        >
            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">
                    {data?.condition ? (isReadOnly ? "View Group": "Edit Group") : "Create Group"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="common-form" onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col>
                            <TextInput readOnly={isReadOnly} keyField={"groupName"} label={"Group Name"} formik={formik} placeholder={"Please enter the group name."} />
                        </Col>
                        <Col>
                            <MultiSelect readOnly={isReadOnly} required={false} options={ageGroupOptions?.map((value) => ({ value, id: value, name: value }))} keyField={"age"} formik={formik} label={"Age Group"} defaultValue={formik?.values?.age || []} />
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMore />} sx={{ fontWeight: "600" }}>
                                <FormControlLabel control={<Checkbox disabled={isReadOnly} onChange={() => { formik.setFieldValue("bp", !formik.values.bp); }}
                                    checked={formik.values.bp} />} label={`Blood Pressure  (${orgVitalsUnit?.blood_pressure?.systolic})`} />
                            </AccordionSummary>
                            <AccordionDetails>
                                <Row>
                                    <Col>
                                        <h6>Medium Range (Yellow)</h6>
                                        <Row>
                                            <Col>
                                                <p>Blood Pressure Systolic</p>
                                                <TextInput readOnly={isReadOnly} keyField={"bpSystolicbelowM"} label={"Alert if Value is Below"} formik={formik} placeholder={"Below systolic value?"} />
                                            </Col>
                                            <Col>
                                                <p>Blood Pressure Diastolic</p>
                                                <TextInput readOnly={isReadOnly} keyField={"bpDiastolicbelowM"} label={"Alert if Value is Below"} formik={formik} placeholder={"Below diastolic value?"} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <TextInput readOnly={isReadOnly} keyField={"bpSystolicAboveM"} label={"Alert if Value is Above"} formik={formik} placeholder={"Above systolic value?"} />
                                            </Col>
                                            <Col>
                                                <TextInput readOnly={isReadOnly} keyField={"bpDiastolicAboveM"} label={"Alert if Value is Above"} formik={formik} placeholder={"Above diastolic value?"} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <h6>High Range (Red)</h6>
                                        <Row>
                                            <Col>
                                                <p>Blood Pressure Systolic</p>
                                                <TextInput readOnly={isReadOnly} keyField={"bpSystolicbelowH"} label={"Alert if Value is Below"} formik={formik} placeholder={"Below systolic value?"} />
                                            </Col>
                                            <Col>
                                                <p>Blood Pressure Diastolic</p>
                                                <TextInput readOnly={isReadOnly} keyField={"bpDiastolicbelowH"} label={"Alert if Value is Below"} formik={formik} placeholder={"Below diastolic value?"} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <TextInput readOnly={isReadOnly} keyField={"bpSystolicAboveH"} label={"Alert if Value is Above"} formik={formik} placeholder={"Above systolic value?"} />
                                            </Col>
                                            <Col>
                                                <TextInput readOnly={isReadOnly} keyField={"bpDiastolicAboveH"} label={"Alert if Value is Above"} formik={formik} placeholder={"Above diastolic value?"} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMore />} sx={{ fontWeight: "600" }}>
                                <FormControlLabel control={<Checkbox disabled={isReadOnly} onChange={() => { formik.setFieldValue("bloodglucose", !formik.values.bloodglucose); }}
                                    checked={formik.values.bloodglucose} />} label={`Blood Glucose (${GetVitalsUnit({ orgVitalsUnit, conditionKey: "blood_glucose" })})`} />
                            </AccordionSummary>
                            <AccordionDetails>
                                <Row>
                                    <h6>Medium Range (Yellow)</h6>
                                    <Col>
                                        <TextInput readOnly={isReadOnly} keyField={"belowBloodglucoseM"} label={"Alert if Value is Below"} formik={formik} placeholder={"Below value?"} />
                                    </Col>
                                    <Col>
                                        <TextInput readOnly={isReadOnly} keyField={"aboveBloodglucoseM"} label={"Alert if Value is Above"} formik={formik} placeholder={"Above value?"} />
                                    </Col>
                                    <h6>High Range (Red)</h6>
                                    <Col>
                                        <TextInput readOnly={isReadOnly} keyField={"belowBloodglucoseH"} label={"Alert if Value is Below"} formik={formik} placeholder={"Below value?"} />
                                    </Col>
                                    <Col>
                                        <TextInput readOnly={isReadOnly} keyField={"aboveBloodglucoseH"} label={"Alert if Value is Above"} formik={formik} placeholder={"Above value?"} />
                                    </Col>
                                </Row>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMore />} sx={{ fontWeight: "600" }}>
                                <FormControlLabel control={<Checkbox disabled={isReadOnly} onChange={() => { formik.setFieldValue("weight", !formik.values.weight); }}
                                    checked={formik.values.weight} />} label={`Weight Scale (${GetVitalsUnit({ orgVitalsUnit, conditionKey: "weight" })})`} />
                            </AccordionSummary>
                            <AccordionDetails>
                                <Row>
                                    <h6>Medium Range (Yellow)</h6>
                                    <Col>
                                        <TextInput readOnly={isReadOnly} keyField={"belowWeightM"} label={"Alert if Value is Below"} formik={formik} placeholder={"Below value?"} />
                                    </Col>
                                    <Col>
                                        <TextInput readOnly={isReadOnly} keyField={"aboveWeightM"} label={"Alert if Value is Above"} formik={formik} placeholder={"Above value?"} />
                                    </Col>
                                    <h6>High Range (Red)</h6>
                                    <Col>
                                        <TextInput readOnly={isReadOnly} keyField={"belowWeightH"} label={"Alert if Value is Below"} formik={formik} placeholder={"Below value?"} />
                                    </Col>
                                    <Col>
                                        <TextInput readOnly={isReadOnly} keyField={"aboveWeightH"} label={"Alert if Value is Above"} formik={formik} placeholder={"Above value?"} />
                                    </Col>
                                </Row>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMore />} sx={{ fontWeight: "600" }}>
                                <FormControlLabel control={<Checkbox disabled={isReadOnly} onChange={() => { formik.setFieldValue("hr", !formik.values.hr); }}
                                    checked={formik.values.hr} />} label={`Heart Rate (${GetVitalsUnit({ orgVitalsUnit, conditionKey: "heart_rate" })})`} />
                            </AccordionSummary>
                            <AccordionDetails>
                                <Row>
                                    <h6>Medium Range (Yellow)</h6>
                                    <Col>
                                        <TextInput readOnly={isReadOnly} keyField={"belowhrM"} label={"Alert if Value is Below"} formik={formik} placeholder={"Below value?"} />
                                    </Col>
                                    <Col>
                                        <TextInput readOnly={isReadOnly} keyField={"abovehrM"} label={"Alert if Value is Above"} formik={formik} placeholder={"Above value?"} />
                                    </Col>
                                    <h6>High Range (Red)</h6>
                                    <Col>
                                        <TextInput readOnly={isReadOnly} keyField={"belowhrH"} label={"Alert if Value is Below"} formik={formik} placeholder={"Below value?"} />
                                    </Col>
                                    <Col>
                                        <TextInput readOnly={isReadOnly} keyField={"abovehrH"} label={"Alert if Value is Above"} formik={formik} placeholder={"Above value?"} />
                                    </Col>
                                </Row>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMore />} sx={{ fontWeight: "600" }}>
                                <FormControlLabel control={<Checkbox disabled={isReadOnly} onChange={() => { formik.setFieldValue("oxygen", !formik.values.oxygen); }}
                                    checked={formik.values.oxygen} />} label={`Oxygen (${GetVitalsUnit({ orgVitalsUnit, conditionKey: "oxygen" })})`} />
                            </AccordionSummary>
                            <AccordionDetails>
                                <Row>
                                    <h6>Medium Range (Yellow)</h6>
                                    <Col>
                                        <TextInput readOnly={isReadOnly} keyField={"belowOxygenM"} label={"Alert if Value is Below"} formik={formik} placeholder={"Below value?"} />
                                    </Col>
                                    <Col>
                                        <TextInput readOnly={isReadOnly} keyField={"aboveOxygenM"} label={"Alert if Value is Above"} formik={formik} placeholder={"Above value?"} />
                                    </Col>
                                    <h6>High Range (Red)</h6>
                                    <Col>
                                        <TextInput readOnly={isReadOnly} keyField={"belowOxygenH"} label={"Alert if Value is Below"} formik={formik} placeholder={"Below value?"} />
                                    </Col>
                                    <Col>
                                        <TextInput readOnly={isReadOnly} keyField={"aboveOxygenH"} label={"Alert if Value is Above"} formik={formik} placeholder={"Above value?"} />
                                    </Col>
                                </Row>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMore />} sx={{ fontWeight: "600" }}>
                                <FormControlLabel control={<Checkbox disabled={isReadOnly} onChange={() => { formik.setFieldValue("temperature", !formik.values.temperature); }}
                                    checked={formik.values.temperature} />} label={`Temperature (${GetVitalsUnit({ orgVitalsUnit, conditionKey: "temperature" })})`} />
                            </AccordionSummary>
                            <AccordionDetails>
                                <Row>
                                    <h6>Medium Range (Yellow)</h6>
                                    <Col>
                                        <TextInput readOnly={isReadOnly} keyField={"belowTempM"} label={"Alert if Value is Below"} formik={formik} placeholder={"Below value?"} />
                                    </Col>
                                    <Col>
                                        <TextInput readOnly={isReadOnly} keyField={"aboveTempM"} label={"Alert if Value is Above"} formik={formik} placeholder={"Above value?"} />
                                    </Col>
                                    <h6>High Range (Red)</h6>
                                    <Col>
                                        <TextInput readOnly={isReadOnly} keyField={"belowTempH"} label={"Alert if Value is Below"} formik={formik} placeholder={"Below value?"} />
                                    </Col>
                                    <Col>
                                        <TextInput readOnly={isReadOnly} keyField={"aboveTempH"} label={"Alert if Value is Above"} formik={formik} placeholder={"Above value?"} />
                                    </Col>
                                </Row>
                            </AccordionDetails>
                        </Accordion>
                    </Row>
                    {isConditionsEmpty && !isReadOnly ? <div className="error-text">Please select atleast any one condition</div> : null}
                    <br />
                    {/* <Row className="toggle-wrap">
                        <Col>
                            <div className="field-wrap">
                                <label>Alert Patient </label>
                                <select onChange={(e) => { setNotification(e.target.value) }} value={notification || ""}>
                                    <option value={""}>Select Notification</option>
                                    {notificationList?.length > 0
                                        ? notificationList.map(option => (
                                            <option key={option.id} value={option.id}>{option.title}</option>
                                        )) : <option value={"select"}>No Notifications found</option>}
                                </select>
                            </div>
                        </Col>
                        <Col></Col>
                        <Col></Col>
                    </Row> */}
                    {!isReadOnly? <div className="btn-wrap">
                        <Button variant="secondary" onClick={() => onHide()} title="Cancel">Cancel</Button>
                        <Button isLoading={btnLoading} variant="primary" type="Submit">{data ? "Update" : "Create"}</Button>
                    </div> : null}
                </form>
            </Modal.Body>
        </Modal >
    );
}
export default CreateGroupDialog
