import { useQuery } from "react-query";
import { axios } from "../../lib/axios";
import { onError } from "./onError"
import { useSelector } from "react-redux";

export function useGroupsQuery({ onSuccess, orgIds = false, groupId = false, queryParams = null, ...props }) {
    const userType = useSelector((state) => state?.auth?.user?.['cognito:groups']);
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations);

    const fetch = () => {
        return queryParams
            ? axios.get(`group?${queryParams}`)
            : groupId
                ? axios.get(`group?groupId=${groupId}`)
                : userType.includes("Super-Admin")
                    ? axios.get(`group`)
                    : axios.get(`group?orgId=${JSON.stringify(orgIds ? orgIds : adminOrgs?.map(org => org.id))}`);
    };
    const query = useQuery(
        ["groups", queryParams],
        () => fetch(),
        {
            select: (data) => data.data,
            onSuccess,
            onError,
            ...props,
        }
    );

    return { ...query };
}