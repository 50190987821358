import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import TextInput from "../../common/textfield/TextInput";
import { useNavigate } from "react-router-dom";
import { Button } from "../../common/Button";
import { failed, success } from "../../common/Toastify";
import CountrySelect from "../../common/textfield/CountrySelect";
import RegionSelect from "../../common/textfield/RegionSelect";
import { FormikObj } from "./Constants";
import { createHcn } from "../../api/HcnAuthority";
import TextArea from "../../common/textfield/TextArea";
import { Tooltip } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

function AddHcn() {
    const navigate = useNavigate();
    const [btnLoading, setBtnLoading] = useState(false);
    const [country, setCountry] = useState("");
    const [region, setRegion] = useState("");

    const formik = useFormik({
        ...FormikObj,
        onSubmit: (values) => {
            setBtnLoading(true);
            createHcn(values)
                .then((res) => {
                    if (res.status === true) {
                        navigate("/app/hcn-list");
                        success(res.message);
                    } else {
                        failed(res.error);
                    }
                })
                .catch((res) => {
                    failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                })
                .finally(() => setBtnLoading(false));
        },
    });

    return (
        <section className="common-listing">
            <div className="heading-wrap mb-3">
                <Tooltip title="Go back">
                    <div style={{ marginRight: "1rem", cursor: "pointer" }} onClick={() => navigate(-1)}>
                        <ArrowBack />
                    </div>
                </Tooltip>
            </div>
            <div className="custom-card p-4">
                <form className="common-form border-fields" onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col>
                            <CountrySelect country={country} setCountry={setCountry} keyField={"country"} label={"Country"} required formik={formik} whitelist={["CA"]} />
                        </Col>
                        <Col>
                            <RegionSelect
                                defaultOptionLabel={formik?.values?.country === "US" ? "Select State" : "Select Province"}
                                country={country} setRegion={setRegion} region={region} keyField={"state"}
                                label={formik?.values?.country === "US" ? "State" : "Province"}
                                formik={formik} />
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextInput keyField={"authority"} label={"Authority"} formik={formik} placeholder={"Authority"} />
                        </Col>
                        <Col>
                            <TextInput keyField={"display"} label={"Display"} formik={formik} placeholder={"Display"} />
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextArea keyField={"description"} label={"Description"} formik={formik} placeholder={"Description..."} />
                        </Col>
                        <Col></Col>
                    </Row>
                    <div className="btn-wrap" style={{ display: "flex" }}>
                        <Button onClick={() => navigate("/app/hcn-list")} variant="secondary" title="Cancel">
                            Cancel
                        </Button>
                        <Button type="submit" isLoading={btnLoading}>
                            Create
                        </Button>
                    </div>
                </form>
            </div>
        </section >
    );
}

export default AddHcn;
