import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { FormikObjOfCreateUnavailibilty, FormikObjOfEditUnavailibilty, unavailabilityStatusTypes } from './constants';
import { failed, success } from '../../common/Toastify';
import { createScheduleSlots, updateScheduleSlot } from '../../api/Schedule';
import moment from 'moment';
import { DateSelectorWithoutFormik } from '../../common/textfield/DateSelector';
import TimeSelector from '../../common/textfield/TimeSelector';
import SelectFiled from "../../common/textfield/SelectFiled";
import { getDaysBetweenDates } from '../../common/functions';
import { Button } from '../../common/Button';

const CreateUnavailability = ({ modalShow, scheduleCategory, editData, handleHide, scheduleId, refresh, startDate, endDate }) => {
    const [btnLoading, setBtnLoading] = useState(false);
    const [reserved, setReserved] = useState(false);
    const [selectedReservedType, setSelectedReservedType] = useState(null);
    const [isPast, setIsPast] = useState(moment().isAfter(moment(endDate)) || moment().isAfter(editData.date));

    const formik = useFormik({
        ...(editData ? FormikObjOfEditUnavailibilty : FormikObjOfCreateUnavailibilty),
        onSubmit: (values) => {
            if(moment(values?.startDate?.format("DD-MMM-YYYY")).isAfter(moment(values?.endDate?.format("DD-MMM-YYYY")))) {
                // this should never occur unless it is free text entry.
                formik.setFieldError("startDate", "Start date must be before end date");
                return false;
            }
            if((moment(values?.from).hour() > moment(values?.to).hour()) 
                || (moment(values?.from).hour() == moment(values?.to).hour()
                && moment(values?.from).minute() > moment(values?.to).minute()) ) {
                // this should never occur unless it is free text entry.
                formik.setFieldError("from", "Start time must be before end time");
                return false;
            }
            setBtnLoading(true);
            Promise.all([...getDaysBetweenDates(moment(formik.values?.startDate || formik.values?.date), moment(formik.values?.endDate || formik.values?.date))?.map((val) => {
                let start = moment(moment(val)?.format("YYYY-MM-DD") + ' ' + formik.values?.from?.format("HH:mm:00")).format();
                let end = moment(moment(val)?.format("YYYY-MM-DD") + ' ' + formik.values?.to?.format("HH:mm:00")).format();
                let status = formik.values?.status;

                if(start.length === 19) start += "Z";
                if(end.length === 19) end += "Z"
                
                start = moment.utc(start).format();
                end = moment.utc(end).format();
                let serviceCategory = null;
                if(formik?.values?.type){
                    serviceCategory = [scheduleCategory?.find(val => val?.value === formik?.values?.type)]
                }

                if (editData) {
                    return updateScheduleSlot({
                        slotId: editData.key,
                        start,
                        end,
                        status,
                        serviceCategory
                    })
                }
                return createScheduleSlots({
                    scheduleId,
                    start,
                    end,
                    status,
                    serviceCategory
                })
            })]).then((res) => {
                success("Saved unavailability slots");
                handleHide()
                refresh()
            }).catch((err) => failed(err?.response?.data?.message || err?.response?.data?.error || err?.message))
                .finally(() => setBtnLoading(false))
        },
    });

    const handleUnAvailabilityChange = (fieldName, val) => {
        if(fieldName === "type") {
            setSelectedReservedType(val);
            formik.setFieldValue(fieldName, val)
        }
        else{
            if(fieldName.toLowerCase().includes("date")) {
                if(val == null || val == "") {
                    formik.setFieldValue(fieldName, val || "");
                }
                else {
                    let isValidDate = moment(val, 'MMM-DD-YYYY').isValid();
                    let errorString = "";
            
                    if(!isValidDate) 
                        errorString = "Invalid date format";
            
                    (isValidDate) ? formik.setFieldValue(fieldName, val) : formik.setFieldError(fieldName, errorString)
                }
            }
            else 
                formik.setFieldValue(fieldName, val)
        }
        if(fieldName==="status"){
            if(val==="busy-reserved")
                setReserved(true)
            else{
                setReserved(false)
            }
        }
    };

    useEffect(() => {
        if (editData) {
            let serviceCategory = {...editData.serviceCategory};
            delete editData.serviceCategory;
            formik.setValues(editData)
            if(serviceCategory?.name){
                setReserved(true)
                formik.setFieldValue("type", serviceCategory.value)
            }
        }
    }, [editData])

    return (
        <Modal
            size="lg"
            show={modalShow}
            onHide={handleHide}
            aria-labelledby="contained-modal-title-vcenter"
            backdropClassName
            backdrop={'static'}
            centered className="custom-dialog">
            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">
                    {editData ? "Edit" : "Create" } Unavailability Slot
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="common-form border-fields" onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col>
                            {editData
                                ? <DateSelectorWithoutFormik formik={formik} disabled={isPast} keyField={'date'} type="readonly" readOnly={!!editData} onChange={val => handleUnAvailabilityChange("date", val)} value={formik.values?.date} label="Date" minDate={moment().format()} maxDate={endDate} />
                                : <DateSelectorWithoutFormik  formik={formik} disabled={isPast} keyField={'startDate'} type="readonly" readOnly={!!editData} onChange={val => handleUnAvailabilityChange("startDate", val)} value={formik.values?.startDate} label="Start Date" minDate={moment().isAfter(startDate) ? moment(): startDate} maxDate={endDate} />}
                        </Col>
                        <Col>
                            {editData ? null : <DateSelectorWithoutFormik disabled={isPast} formik={formik} keyField={'endDate'} type="readonly" readOnly={!!editData} onChange={val => handleUnAvailabilityChange("endDate", val)} value={formik.values?.endDate} label="End Date" minDate={formik.values?.startDate || moment().format()} maxDate={endDate} />}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TimeSelector label="From" minutesStep={5} minTime={startDate} maxTime={moment(endDate.format()).subtract(5, 'm')} disabled={isPast} value={formik.values?.from} onChange={val => handleUnAvailabilityChange("from", val)} error={formik.errors.from} />
                        </Col>
                        <Col>
                            <TimeSelector minTime={moment(formik.values?.from).add(5,"m")} maxTime={endDate} disabled={isPast} label="To" minutesStep={5} value={formik.values?.to} onChange={val => handleUnAvailabilityChange("to", val)} error={formik.errors.to} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SelectFiled keyField={"status"} disabled={isPast} label="Select Type" formik={formik} value={formik.values?.status} options={ editData ? unavailabilityStatusTypes.filter( item => item.id !== "busy-reserved") : unavailabilityStatusTypes} onChange={val => handleUnAvailabilityChange("status", val.target.value)} />
                        </Col>
                    </Row>
                    {reserved && <Row>
                        <Col>
                            <SelectFiled keyField={"type"} disabled={isPast} label="Select Schedule Type" formik={formik} value={formik.values?.type} options={scheduleCategory} onChange={val => handleUnAvailabilityChange("type", val.target.value)} />
                        </Col>
                    </Row>}
                    <div className="btn-wrap" style={{ display: "flex", gap: "3rem" }}>
                        <Button onClick={handleHide}>Cancel</Button>
                        <Button type="submit" disabled={isPast} isLoading={btnLoading}>{editData ? 'Update' : 'Create'}</Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default CreateUnavailability