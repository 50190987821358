import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { Event } from '@mui/icons-material';
import { safeAgeString } from "../../../utils/DateSupport";
import './date-picker.css';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TextField } from '@mui/material';

function ReactDatePicker({ keyField, formik, label, minDate = moment().subtract(200, "years"), maxDate = moment(), required = true, hasValue = false, readOnly = false, displayAge = false, ...props }) {
    const [value, setValue] = useState(moment());
    const [age, setAge] = useState((hasValue) ? safeAgeString(formik.values[keyField]) : "");
    const [open, setOpen] = useState(false)
    const [dateValue, setDateValue] = useState(false)
    const handleChange = (val) => { 
        setDateValue(true)
        const isValidDate = moment(val, 'MMM-DD-YYYY').isValid()
        if(val == null || val == ""){
            formik.setFieldValue( keyField, val || "");
          }else{
            if(isValidDate){
                formik.setFieldValue(keyField, moment(val).format("MM-DD-YYYY")?.replace(/-/g,'/'));
                setValue(val);
                setAge(safeAgeString(val));
            }else{
                formik.setFieldError(keyField, 'Invalid date format')
            }
          }        
    };  

    const handleKeyDown = (event) => {       
        if (event.keyCode === 8 && handleChange) {            
            handleChange(null);
        }
    };
    const handleInputClick = () => {
        if (!readOnly) setOpen(true);
    };

    useEffect(() => {
        if (readOnly || formik?.values[keyField] !== undefined) {
            setOpen(false);
        }
    }, [readOnly, moment(formik?.values[keyField]).format('DD') ]);

    useEffect(() => {
        setValue(null)
    }, [formik.values.type])
    

    return (
        <div className="field-wrap createPatientDOB">
            <label htmlFor={keyField}>
                {label}
                {required ? <span style={{ color: "red" }}> *</span> : " (Optional)"}
            </label>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                    label={false}
                    value={hasValue ? moment(formik.values[keyField], "MM-DD-YYYY") : value}
                    inputFormat='MMM-DD-YYYY'
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={handleChange}
                    readOnly={readOnly}
                    onKeyDown={handleKeyDown}
                    {...props}
                    closeOnSelect
                    onOpen={() => !readOnly && setOpen(true)}
                    onClose={() => !readOnly && setOpen(false)}
                    open={open}
                    sx={{
                        ".MuiInputBase-root": {
                            borderRadius: "10px",
                         },
                         ".MuiFilledInput-input": {
                            padding: "16.5px 14px",
                         }
                    }}
                   
                    renderInput={(params) => 
                            <TextField
                                {...params}
                                InputLabelProps={{ shrink: true }}
                                placeholder="YYYY-MM-DD"
                                inputProps={{
                                    ...params.inputProps,
                                    style: { textTransform: 'uppercase' }
                                }}
                                onBeforeInput={handleKeyDown}
                                onKeyDown={handleKeyDown}
                                // onClick={handleInputClick}  
                                error={false} 
                            />
                    }
                />
            </LocalizationProvider>
            <div>{ dateValue && formik?.errors[keyField] ? <div className="error-text">{formik?.errors[keyField]}</div> : ((hasValue || value) && displayAge)? <div style={{paddingLeft: "10px", marginTop: '5px'}}>{(formik.values[keyField].length > 0) ? safeAgeString(formik.values[keyField]) : age }</div>: null}</div>
            </div>
    )
}

export default ReactDatePicker;