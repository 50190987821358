import { axios } from "../../../lib/axios";


function programData(data, device) {
    return {
        title: data.title,
        description: data.description,
        condition: data.condition,
        duration: {
            unit: "Days",
            value: data.time,
        },
        organization: [data?.organization || "0"],
        device,
    };
}

export const getProgramList = (orgid = null) => {
    return axios.get(orgid ? `/program/get?orgId=${orgid}` : "/program/get");
};

export const getProgram = (programId = null) => {
    return axios.post(`/program/get?id=${programId}`);
};

export const createProgram = (data, device) => {
    const newData = programData(data, device)
    return axios.post(`program/create`, newData);
};

export const editProgram = (data, device, customGroupId, customGroup,) => {
    const newData = programData(data, device)
    return axios.post(`program/edit`, { ...newData, id: data.id, customGroupId, customGroup, });
};

export const deleteProgram = (programId) => {
    return axios.delete(`program/delete/${programId}`);
};

export const deleteEnrollPatients = (patientId, programId) => {
    return axios.post(`program/delete-enroll-patients`, { id: patientId , programId })
};

