import React, { Fragment, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { Col, Modal, Row } from 'react-bootstrap';
import { useFormik } from 'formik';
import { Button } from '../../common/Button';
import SelectFiled from '../../common/textfield/SelectFiled';
import Fields from './Fields';
import { getOrganization } from '../../api/Organization';
import { useSelector } from 'react-redux';
import { failed, success } from '../../common/Toastify';
import { axios } from '../../../lib/axios';
import { FormikObjInboundCall } from './Constant';
import moment from 'moment';
import PatientSelection from './PatientSelection';
import AddPatient from './AddPatient';
import { useNavigate } from 'react-router-dom';
import { editEncounter } from '../../apps/api/AppointmentRequests';
import { generatePass } from '../../apps/AppointmentRequests/Constants';
import { createQuestionnaireResponse } from '../../api/Questionnaire';
import BookAppointment from '../../apps/AppointmentRequests/BookAppointment';
import { getSingleUser } from '../../api/Individual';
import { LOCATIONS } from '../../common/location';

const steps = ['Select Organization', 'Intake Questionnaire', 'Patient Selection', 'How to proceed?'];
export const genderOptions = [
    { value: "male", name: "Male" },
    { value: "female", name: "Female" },
    { value: "other", name: "Other" },
];


export default function InBoundCall({ encounterId, encounteredData, modalShow, handleShow}) {
  const user = useSelector((state) => state?.auth?.user);
  const navigate = useNavigate()
  const [btnLoading, setBtnLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [patientModal, setPatientModal] = useState(false)
  const [skipped, setSkipped] = useState(new Set());
  const [Questions, setQuestions] = useState([])
  const [isWaitingRoom, setIsWaitingRoom] = useState(null)
  const [organizations, setOrganizations] = useState([]);
  const [appointmentModal, setAppointmentModal] = useState(false)
  const [radioValue, setRadioValue] = useState([]);
  const [patient, setPatient] = useState(null);
  const [questionnairResId, setQuestionnairResId] = useState('')
  const [practitionerData, setPractitionerData] = useState(null)
  const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)

  const formik = useFormik({
    ...FormikObjInboundCall,
    onSubmit: (values, { setFieldError }) => {
      let topic = user?.name[0]?.text?.toLowerCase()+'fonemed';   
      topic = topic.replace(" ", "")+Math.round(new Date().getTime()/1000);
      let password= generatePass();
      if (activeStep === 0) {
        if (values?.orgId === "") {
          setFieldError(`orgId`, "Required field");
          return true;
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      } else if (activeStep === 1) {
        const telePhoneField = formik?.values?.questionResponse?.find((item)=> item?.tag === "TELEPHONE")?.answer?.[0]?.answer
        const pattern = /^(?:\+?1[-. ]?)?\(?[0-9]\d{2}\)?[-. ]?\d{3}[-. ]?\d{4}$/;
        const errList = values?.questionResponse?.map((question, index) => {
          if (question?.required) {
            if (question?.answerType === "radio") {
              if (!question?.answer?.some((item) => !!item?.answer)) {
                setFieldError(`questionResponse[${index}]`, "Required field");
                return true;
              }
            } else if (question?.answerType === "freeText" || question?.answerType === "dropDown") {
              // if (question?.answer?.[0]?.answer?.length < 1 || !question?.answer?.[0]?.answer?.replace(/\s/g, "").length || question?.answer?.[1]?.answer?.length < 1 || !question?.answer?.[1]?.answer?.replace(/\s/g, "").length) {
                if(question.tag === "RELATIONSHIP" && question?.answerType === "dropDown"){
                  if(question?.answer[0]?.answer === '' && (radioValue.some(option => option?.answer === true && option?.option === 'No'))) {
                    setFieldError(`questionResponse[${index}]`, "Required field");
                    return true;
                  }
                } else if(question.tag !== "RELATIONSHIP" && question?.answerType === "dropDown" && question?.answer[0]?.answer === ''){
                  setFieldError(`questionResponse[${index}]`, "Required field");
                  return true;
                }
                if(question?.answerType === "freeText" && question?.tag === "CALLER"){
                  if(question?.answer?.[0]?.answer === "" || question?.answer?.[0]?.answer === undefined) {
                    setFieldError(`questionResponse[${index}]`, "Required field");
                    return true;
                  }
                  if(question?.answer?.[1]?.answer === "" || question?.answer?.[1]?.answer === undefined) {
                    setFieldError(`questionResponse[${index}]`, "Required field");
                    return true;
                  }
                } else if(question?.answerType === "freeText" && question?.tag !== "CALLER"){
                  if (question?.answer?.[0]?.answer === "" || question?.answer?.[0]?.answer === undefined) {
                    setFieldError(`questionResponse[${index}]`, "Required field");
                    return true;
                }
                } 

                const firstName =  values?.questionResponse.find((obj)=> obj.tag === "CALLER")?.answer?.[0]?.answer
                const lastName =  values?.questionResponse.find((obj)=> obj.tag === "CALLER")?.answer?.[1]?.answer
                const indexAnswer = values?.questionResponse.findIndex((obj)=> obj.tag === "CALLER")
                if(firstName === '' || (lastName === undefined || lastName === '')){
                  setFieldError(`questionResponse[${indexAnswer}]`, "Required field");
                  return true;
                }
              // }
            } else if (question?.answerType === "checkbox") {
              if (!question?.answer?.some((item) => !!item?.answer)) {
                setFieldError(`questionResponse[${index}]`, "Required field");
                return true;
              }
            }
           
          }
          return false;
        });

    
        if(formik?.values?.questionResponse?.length === 0 || formik?.values?.questionResponse?.length === undefined || formik?.values?.questionResponse?.length === null ){
          return true;
        }
        if(!pattern.test(telePhoneField)){
          return true 
        }
        
        if (!errList?.includes(true)) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }

        localStorage.setItem("firstName", values?.questionResponse.find((obj)=> obj.tag === "CALLER")?.answer?.[0]?.answer)
        localStorage.setItem("lastName", values?.questionResponse.find((obj)=> obj.tag === "CALLER")?.answer?.[1]?.answer)
      } else if (activeStep === 2) {
        if (values?.patientId === "") {
          setFieldError(`patientId`, "Select patient before proceed");
          return true;
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      }else if(activeStep === 3){
        if(values.howToProcced === ""){
          setFieldError(`howToProcced`, "Required field");
          return true;
        }else{
          const requestedPeriod = [{start: moment().format("YYYY-MM-DDTHH:mm:ss[Z]"), end: moment().format("YYYY-MM-DDTHH:mm:ss[Z]")}] 
          const getLocationQuestion = values.questionResponse.find(question => question.tag === "LOCATION");
          let location = getLocationQuestion?.answer?.[0]?.answer;
          let scopeOfJurisdiction = LOCATIONS[location];
          if (!scopeOfJurisdiction) {
            scopeOfJurisdiction = location;
          }
          if(values?.howToProcced === "COMPLETE_CALL") {
              setBtnLoading(true) 
                let concatenatedAnswerExt;
                let telephoneAnswer;
                let concatenatedName;
                let nameAnswer;
                const nameQuestion = values.questionResponse.find(question => question.tag === "CALLER");
                if (nameQuestion) {
                     nameAnswer = nameQuestion?.answer;
                     concatenatedName =  nameQuestion?.answer?.[1] !== undefined ?  nameAnswer[0]?.answer + " " + nameAnswer[1]?.answer : nameAnswer[0]?.answer ;
                     let namePatient =  getValueQuestionnairRadio("SELFCALLING") === "Yes" ? values?.patientName : concatenatedName;
                      nameAnswer.splice(1, 1);
                      nameAnswer[0].answer = namePatient.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
                      nameAnswer[0].required = true;
                      nameAnswer[0].option = null;
                      nameAnswer[0].value = null;
                      nameAnswer[0].name = null;
                }
                const telephoneQuestion = values.questionResponse.find(question => question.tag === "TELEPHONE");
                  if (telephoneQuestion) {
                    telephoneAnswer = telephoneQuestion?.answer;
                    concatenatedAnswerExt =  telephoneQuestion?.answer?.[1] !== undefined ?  telephoneAnswer[0]?.answer + " ext " + telephoneAnswer[1]?.answer : telephoneAnswer[0]?.answer ;
                      telephoneAnswer.splice(1, 1);
                      telephoneAnswer[0].answer = concatenatedAnswerExt;
                      telephoneAnswer[0].required = true;
                      telephoneAnswer[0].option = null;
                      telephoneAnswer[0].value = null;
                      telephoneAnswer[0].name = null;
                  }
                const questionResponse = values.questionResponse?.map((item) => ({
                  id: item?.linkId,
                  question: item?.text,
                  answer: item?.tag === 'TELEPHONE' ? telephoneAnswer : item.answer,
                  questionType: item?.answerType,
                  required: item?.required,
                }));
                createQuestionnaireResponse({ patientID: values.patientId, encounterId: "", questionResponse: questionResponse, appointmentId: "", encounterId: encounterId, questionnaire: values.questionnaireId })
                      .then(async(resQuestionnair) => {
                        localStorage.removeItem("firstName")
                        localStorage.removeItem("lastName")
                        if(resQuestionnair?.status === true){
                          const questionnairInfo = {"questionnaireResponseId": {reference: `QuestionnaireResponse/${resQuestionnair?.data?.id}`}, callerName: values?.questionResponse.find((obj)=> obj?.tag === "CALLER")?.answer?.[0]?.answer, preferredChannel: "phone"}
                          if(isTagPresent("TELEPHONE")){
                            questionnairInfo["callerNumber"] = getValueQuestionnair("TELEPHONE")
                          } 
                          if(isTagPresent("RELATIONSHIP") && isTagPresent("SELFCALLING")){
                            if(getValueQuestionnairRadio("SELFCALLING") === "Yes") questionnairInfo["callerRelationship"] = "Self"
                            else questionnairInfo["callerRelationship"] = getValueQuestionnairDropDown("RELATIONSHIP")
                          }
                          let encounterPayload = {
                            patient : {
                              reference: `Patient/${values.patientId}`,
                              type: "Patient",
                              display: values?.patientName
                            },
                            orgId : adminOrgs?.[0]?.id,
                            questionnairInfo,
                            requestedPeriod : {start: encounteredData?.period?.start, end: moment().format("YYYY-MM-DDTHH:mm:ss[Z]")},
                            practitionerId : practitionerData?.link?.[0]?.target?.reference?.split("/")?.[1],
                            practitionerName : user?.name?.[0]?.text,
                            proceedWith : values?.howToProcced,
                            encounterId: encounterId,
                            actor: {
                              reference: `Practitioner/${user["custom:unique_id"]}`,
                              display: user?.name?.[0]?.text
                            }
                          }
                          if(getLocationQuestion){
                            encounterPayload = {
                              ...encounterPayload,
                              patientLocation : scopeOfJurisdiction
                            }
                          } 
                          editEncounter(encounterPayload).then((resEncounter)=>{
                            if(resEncounter.status === true){
                              navigate("/app/phone-call-create", {state: {practitionerName: user?.name?.[0]?.text, practitionerId: user["custom:unique_id"], patientId: values?.patientId, patientName: values?.patientName, encounterId: encounterId, currentUser: {patient: {display: values?.patientName, }}, proceedWith:  values?.howToProcced, requestedPeriod: requestedPeriod, questionnairInfo: questionnairInfo, period: encounteredData.period}});
                              handleShow();
                              setBtnLoading(false)
                            }
                          }).catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                          .finally(() => setBtnLoading(false));
                        }
                      })
                      .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                      .finally(() => setBtnLoading(false));
          }else if(values?.howToProcced === "SCHEDULE"){
            let concatenatedName;
            let nameAnswer;
            let concatenatedAnswerExt;
            let telephoneAnswer;
            const nameQuestion = values.questionResponse.find(question => question.tag === "CALLER");
            const telephoneQuestion = values.questionResponse.find(question => question.tag === "TELEPHONE");
            if (nameQuestion) {
                 nameAnswer = nameQuestion?.answer;
                 concatenatedName =  nameQuestion?.answer?.[1] !== undefined ?  nameAnswer[0]?.answer + " " + nameAnswer[1]?.answer : nameAnswer[0]?.answer ;
                 let namePatient =  getValueQuestionnairRadio("SELFCALLING") === "Yes" ? values?.patientName : concatenatedName;
                  nameAnswer.splice(1, 1);
                  nameAnswer[0].answer = namePatient.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
                  nameAnswer[0].required = true;
                  nameAnswer[0].option = null;
                  nameAnswer[0].value = null;
                  nameAnswer[0].name = null;
                }
                if (telephoneQuestion) {
                  telephoneAnswer = telephoneQuestion?.answer;
                  concatenatedAnswerExt =  telephoneQuestion?.answer?.[1] !== undefined ?  telephoneAnswer[0]?.answer + " ext " + telephoneAnswer[1]?.answer : telephoneAnswer[0]?.answer ;
                    telephoneAnswer.splice(1, 1);
                    telephoneAnswer[0].answer = concatenatedAnswerExt;
                    telephoneAnswer[0].required = true;
                    telephoneAnswer[0].option = null;
                    telephoneAnswer[0].value = null;
                    telephoneAnswer[0].name = null;
                }
                const questionResponse = values.questionResponse?.map((item) => ({
                  id: item?.linkId,
                  question: item?.text,
                  answer: item?.tag === 'CALLER' ? nameAnswer : item.answer,
                  questionType: item?.answerType,
                  required: item?.required,
                }));
                setBtnLoading(true)
                createQuestionnaireResponse({
                  patientID: values?.patientId,
                  encounterId: '',
                  questionResponse: questionResponse,
                  appointmentId: '',
                  questionnaire: values.questionnaireId
                })
                  .then((res) => {
                    setQuestionnairResId(res.data.id)
                    setAppointmentModal(true);
                    setBtnLoading(false)
                  })
                  .catch((res) => {
                    failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                  })
                  .finally(() => {
                    setBtnLoading(false);
                  }); 
          }else if(values?.howToProcced === "WAITING_ROOM"){
            setBtnLoading(true)
            let concatenatedName;
            let nameAnswer;
            const nameQuestion = values.questionResponse.find(question => question.tag === "CALLER");
            if (nameQuestion) {
                 nameAnswer = nameQuestion?.answer;
                 concatenatedName =  nameQuestion?.answer?.[1] !== undefined ?  nameAnswer[0]?.answer + " " + nameAnswer[1]?.answer : nameAnswer[0]?.answer ;
                  let namePatient =  getValueQuestionnairRadio("SELFCALLING") === "Yes" ? values?.patientName : concatenatedName;
                  nameAnswer.splice(1, 1);
                  nameAnswer[0].answer = namePatient.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
                  nameAnswer[0].required = true;
                  nameAnswer[0].option = null;
                  nameAnswer[0].value = null;
                  nameAnswer[0].name = null;
            }
            const questionResponse = values.questionResponse?.map((item) => ({
              id: item?.linkId,
              question: item?.text,
              answer: item.answer,
              questionType: item?.answerType,
              required: item?.required,
            }));
            createQuestionnaireResponse({ patientID: values?.patientId, encounterId: "", questionResponse: questionResponse, appointmentId: "",  encounterId : encounterId, questionnaire: values.questionnaireId })
              .then(async(resQuestionnair) => {
                localStorage.removeItem("firstName")
                localStorage.removeItem("lastName")
                if(resQuestionnair?.status === true){
                const questionnairInfo = {"questionnaireResponseId": {reference: `QuestionnaireResponse/${resQuestionnair?.data?.id}`}, callerName: values?.questionResponse.find((obj)=> obj?.tag === "CALLER")?.answer?.[0]?.answer, preferredChannel: "phone"}
                if(isTagPresent("TELEPHONE")){
                  questionnairInfo["callerNumber"] = getValueQuestionnair("TELEPHONE")
                } 
                if(isTagPresent("RELATIONSHIP") && isTagPresent("SELFCALLING")){
                  if(getValueQuestionnairRadio("SELFCALLING") === "Yes") questionnairInfo["callerRelationship"] = "Self"
                  else questionnairInfo["callerRelationship"] = getValueQuestionnairDropDown("RELATIONSHIP")
                }
                let encounterPayload = {
                  patient : {
                    reference: `Patient/${values?.patientId}`,
                    type: "Patient",
                    display: values?.patientName
                  },
                  orgId : adminOrgs?.[0]?.id,
                  questionnairInfo,
                  requestedPeriod : [{start: encounteredData?.period?.start, end: moment().format("YYYY-MM-DDTHH:mm:ss[Z]")}],
                  practitionerId : practitionerData?.link?.[0]?.target?.reference?.split("/")?.[1],
                  practitionerName : user?.name?.[0]?.text,
                  proceedWith : values?.howToProcced,
                  status: "arrived",
                  encounterId : encounterId,
                  period: {start: encounteredData?.period?.start, end: moment().format("YYYY-MM-DDTHH:mm:ss[Z]")},
                  actor: {
                    reference: `Practitioner/${user["custom:unique_id"]}`,
                    display: user?.name?.[0]?.text
                  }
                }
                if(getLocationQuestion){
                  encounterPayload = {
                    ...encounterPayload,
                    patientLocation : scopeOfJurisdiction
                  }
                } 
                editEncounter(encounterPayload).then((resEncounter)=>{
                  if(resEncounter.status === true){
                    navigate("/app/manage-queue", {state: {practitionerName: user?.name?.[0]?.text, practitionerId: user["custom:unique_id"], patientId: values?.patientId, patientName: values?.patientName, encounterId: encounterId, currentUser: {patient: {display: values?.patientName, }}, proceedWith:  values?.howToProcced, requestedPeriod: requestedPeriod, questionnairInfo: questionnairInfo}});
                    success("Patient successfully added to waiting room.")
                    handleShow();
                    setBtnLoading(false);
                  }
                }).catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                .finally(() => setBtnLoading(false));
              }
            })
            .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
            .finally(() => setBtnLoading(false));
          }
        }
      }
    },
  });


  const isTagPresent = (tagValue) => formik?.values?.questionResponse?.some(item => item.tag === tagValue);
  const getValueQuestionnair = (tagValue) => formik?.values?.questionResponse?.find(item => item.tag === tagValue)?.answer?.[0]?.answer
  const getValueQuestionnairDropDown = (tagValue) => formik?.values?.questionResponse.find((obj)=> obj?.tag === tagValue)?.answer?.find((val)=>val?.answer !== "")?.answer
  const getValueQuestionnairRadio = (tagValue) => formik?.values?.questionResponse.find((obj)=> obj?.tag === tagValue)?.answer?.find((val)=>val?.answer === true)?.option

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    formik.handleSubmit()
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

//   const handleSkip = () => {
//     if (!isStepOptional(activeStep)) {
//       // You probably want to guard against something like this,
//       // it should never occur unless someone's actively trying to break something.
//       throw new Error("You can't skip a step that isn't optional.");
//     }

//     setActiveStep((prevActiveStep) => prevActiveStep + 1);
//     setSkipped((prevSkipped) => {
//       const newSkipped = new Set(prevSkipped.values());
//       newSkipped.add(activeStep);
//       return newSkipped;
//     });
//   };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    getOrganization(adminOrgs?.[0]?.id)
        .then(async(res) => {
            setIsWaitingRoom(res?.data?.isWaitingRoom)
            if(res.data?.assignedQuestionnaire){
              const response = await axios.get(`questionnaire/${res.data?.assignedQuestionnaire?.['telephone-intake']}`)
              formik.setFieldValue("questionnaireId", response?.data?.id)
              setQuestions(response?.data?.item?.map((item) => ({ ...item, name: item?.text, value: item?.id })))
              formik.setFieldValue("questionResponse",
              Questions?.map((item) => {
                      const isTrue = item?.answerType === "freeText" || item?.answerType === "dropDown"
                      const isDropDown = item?.answerType === "dropDown"
                      return {
                          ...item,
                          answer: item?.answerOption?.map((option) => {
                              return {
                                  id: option?.id,
                                  required: item?.required,
                                  answer: isTrue ? "" : false,
                                  option: isTrue ? null : option?.text, // text in the answerOption[]
                                  value: isDropDown ? option.text : null,
                                  name: isDropDown ? option.text : null,
                              }
                          })
                      }
              }))
            }
          
        })
        .catch((res) => {
            failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
        });

        getOrganization()
            .then((res) => {
             const matchedOrg = res?.data?.find(org => org?.id === adminOrgs[0]?.id);
             if(matchedOrg) {
                const modifiedOrg = { ...matchedOrg, value: matchedOrg?.id };
                setOrganizations([modifiedOrg]);
                formik.setFieldValue("orgId", modifiedOrg?.id);
                formik.setFieldValue("orgName", modifiedOrg?.name);
             }
            //  setOrganizations(res.data.map((org) => (org = { ...org, value: org.id })));
        })
         .catch((res) => {
            failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
        });

        getSingleUser({ id : user["custom:unique_id"] , type : "Practitioner"})
          .then((res) => {
            setPractitionerData(res.data)
          })
          .catch((res) => {
            console.log("error fetching practioner list",res)
          })
  }, []);

  useEffect(() => {
    formik.setFieldValue("questionResponse",
    Questions?.map((item) => {
            const isTrue = item?.answerType === "freeText" || item?.answerType === "dropDown"
            const isDropDown = item?.answerType === "dropDown"
            return {
                ...item,
                answer: item?.answerOption?.map((option) => {
                    return {
                        id: option?.id,
                        required: item?.required,
                        answer: isTrue ? "" : false,
                        option: isTrue ? null : option?.text, // text in the answerOption[]
                        value: isDropDown ? option.text : null,
                        name: isDropDown ? option.text : null,
                    }
                })
            }
    }))
  }, [Questions])

  const handleOrgChange = (e) =>{
    formik.setFieldValue("orgId", e.target.value)
    formik.setFieldValue("orgName", organizations.find((org)=> org.value === e.target.value ).name)
  }

  const getAddedPatient = (addedData)=>{
    setPatient(addedData)
  }

  const resetPatient =(data)=>{
    setPatient(data)
  }

  const activeForm = ()=>{
    switch (activeStep) {
        case 0:
            return (
                <Row>
                        <Col>
                            <SelectFiled
                                formik={formik}
                                options={organizations}
                                fieldtype={"TextInput"}
                                keyField={"orgId"}
                                label={"Select Organization"}
                                placeholder={"Select Organization"}
                                required={true}
                                onChange={handleOrgChange}
                                readOnly={true}
                            />
                        </Col>
                </Row>
            )
        case 1:
            return (
               <>
                {Questions?.length > 0 ? Questions?.map((question, index) =>
                  <Fragment key={index}>
                    {/* <h6>{question?.text}{question?.required ? <span style={{ color: "red" }}> *</span> : null}</h6> */}
                    <Fields question={question} Questions={Questions} formik={formik} propIndex={index} setRadioValue={setRadioValue} />
                    {/* <div>{formik?.errors?.questionResponse?.[index] ? <div className="error-text">Required question</div> : null}</div> */}
                  </Fragment>
                ) : <div className="error-text">Assign the questionnaire to proceed.</div>}
               </>
            )
        case 2:
            return (
              <PatientSelection orgId={formik?.values?.orgId} formikInBoundCall={formik} handleAddPatient={()=> setPatientModal(true)} patient={patient} resetPatient={resetPatient}/>
            );
        case 3:
            return (
              <div style={{padding: "10px"}}>
                <Row>
                    <Col style={{ border: "1px solid #c7c7c7", padding: "10px", borderRadius: "10px", marginRight: "5px" }}>
                      <label style={{ display: 'block', cursor: "pointer", width: "100%", height: "100%" }}>
                        <input type='radio' name='radio' value={"COMPLETE_CALL"} onChange={(e) => formik.setFieldValue("howToProcced", e.target.value)} style={{ marginRight: '5px' }}/>
                        Proceed with the call
                      </label>
                    </Col>
                    <Col style={{ border: "1px solid #c7c7c7", padding: "10px", borderRadius: "10px", marginRight: "5px" }}>
                      <label style={{ display: 'block', cursor: "pointer", width: "100%", height: "100%" }}>
                        <input type='radio' name='radio' value={"SCHEDULE"} onChange={(e) => formik.setFieldValue("howToProcced", e.target.value)} style={{ marginRight: '5px' }}/>
                        Schedule an Appointment
                      </label>
                    </Col>
                    {isWaitingRoom ? (
                      <Col style={{ border: "1px solid #c7c7c7", padding: "10px", borderRadius: "10px" }}>
                        <label style={{ display: 'block', cursor: "pointer", width: "100%", height: "100%" }}>
                          <input type='radio' name='radio' value={"WAITING_ROOM"} onChange={(e) => formik.setFieldValue("howToProcced", e.target.value)} style={{ marginRight: '5px' }}/>
                          Add to Waiting Room
                        </label>
                      </Col>
                    ) : null}
                </Row>
                <div>{formik?.errors?.howToProcced ? <div className="error-text">{formik?.errors?.howToProcced}</div> : null}</div>
              </div>
            );
        default:
            return <Col></Col>
    }
  }

  return (
    <>
    {patientModal && <AddPatient formikInBoundCall={formik} modalShow={patientModal} handleShow={()=> setPatientModal(false)} getAddedPatient={getAddedPatient}/>}
    {appointmentModal && <BookAppointment questionnaireResponseId={questionnairResId} formikInBoundCall={formik} type="patient" modalShow={appointmentModal}  handleShow={()=> setAppointmentModal(false)}/>}
    <Modal
    show={modalShow}
    onHide={handleShow}
    size="xl"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    backdropClassName
    backdrop={'static'}
    >
    <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
            Intake Inbound Call
        </Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            // if (isStepOptional(index)) {
            //     labelProps.optional = (
            //     <Typography variant="caption">Optional</Typography>
            //     );
            // }
            if (isStepSkipped(index)) {
                stepProps.completed = false;
            }
            return (
                <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
            );
            })}
        </Stepper>
        {activeStep === steps.length ? (
            <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset}>Reset</Button>
            </Box>
            </React.Fragment>
        ) : (
            <React.Fragment>
                <form className="common-form border-fields" style={{border : "1px solid #c7c7c7", padding: "10px", borderRadius: "5px", marginTop: "10px"}}>           
                    {activeForm()}
                </form>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                    variant="secondary"
                    disabled={activeStep === 0 || btnLoading}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                    >
                    Previous
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={handleNext} isLoading={btnLoading}>
                      {activeStep === steps.length - 1 ? 'Proceed' : 'Next'}
                    </Button>
                </Box>
            </React.Fragment>
        )}
        </Box>
    </Modal.Body>
    </Modal>
    </>

  );
}