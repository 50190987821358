import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import EditIcon from "../../../assets/images/edit.png";
import overRideIcon from "../../../assets/images/over-ride.png";
import DeleteIcon from "../../../assets/images/delete.png";
import { failed, success } from "../../common/Toastify";
import { Button } from "../../common/Button";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Skeleton, TableCell, Tooltip } from "@mui/material";
import ViewIcon from "../../../assets/images/view.png";
import { UpdateNotificationModal } from "./UpdateNotificationModal";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import CustomPagination from "../../common/components/Pagination/CustomPagination";
import { useNotifyQuery } from "../../../hooks/ReactQueryHooks/useNotifyQuery";
import SearchBar from "../../common/components/SearchBar";
import { Spinner } from "react-bootstrap";
import { commonDisableAPI } from "../../api/CommonApis";
import ViewUserNotificationModal from './ViewUserNotificationModal'

function NotificationsList() {
    const navigate = useNavigate();
    const { getConfirmation } = useConfirmDialog();

    const userType = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
    const [modalShow, setModalShow] = React.useState(false);
    const [modalShowContent, setModalShowContent] = React.useState(false);
    const [notificationList, setNotificationList] = React.useState([]);
    const [readOnly, setReadOnly] = React.useState(false);
    const [deleting, setDeleting] = React.useState(false);
    const [isOverRideRedo, setIsOverRideRedo] = React.useState(false);
    const [filterData, setFilterData] = React.useState([]);

    //React Query Hook
    const onSuccess = (data) => {
        const tempArr = []
        data?.forEach((data) => {
            if (data?.type === "custom") {
                tempArr.push(data?.eventId)
            }
        })
        setNotificationList(data.filter((data) => !(data?.type === "default" && tempArr.includes(data?.eventId))));
    };
    const { refetch, isLoading, isFetching } = useNotifyQuery({ onSuccess });

    const handleFilterData = (data) => {
        setFilterData(data);
        setPage(0)
    };

    const [page, setPage] = useState(0);
    const rowsPerPage = 10;
    const totalRows = filterData.length;

    const handleShow = (data, isReadOnly, isOverRide) => {
        setModalShow(modalShow ? false : data);
        setReadOnly(isReadOnly);
        setIsOverRideRedo(isOverRide);
    };
    const handleShowContentModal = (data) => {
        if(data !== ""){
            let contentData = {message : data}
            setModalShowContent(modalShowContent ? false : contentData);
        }  
    };

    const handleDelete = async (id, entryName) => {
        const confirmed = await getConfirmation({
            title: "Attention!",
            entryName,
            actionBtnTitle: "Delete",
        });
        if (confirmed) {
            setDeleting(id);
            commonDisableAPI({ key: "notification", id })
                .then((res) => {
                    refetch()
                    success("Notification Deleted!");
                })
                .catch((err) => failed(err?.response?.data?.message || err.message))
                .finally(() => setDeleting(false));
        }
    };

    const handleOverRideRedo = (data) => {
        setModalShow(data)
        setIsOverRideRedo(true)
    }

    const ellipsis = {
        whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "10rem"
    }

    return (
        <React.Fragment>
            <ViewUserNotificationModal modalShow={modalShowContent} handleShow={handleShowContentModal}/>
            <section className="common-listing">
                {modalShow ? <UpdateNotificationModal modalShow={modalShow} handleShow={handleShow} refreshTable={refetch} isReadOnly={readOnly} isOverRide={isOverRideRedo} userType={userType} /> : null}
                <div className="heading-wrap h-change">
                    <SearchBar page={page} setPage={setPage} list={notificationList} filterData={handleFilterData} />
                    <div className="right-wrap" style={{ margin: "left" }}>
                        <Button onClick={() => navigate("/app/CreateNotification")} className="custom-btn" title="Create Notification" style={{ width: "auto" }}>
                            Create Notification
                        </Button>
                    </div>
                </div>

                <div className="table-wrap">
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Type</th>
                                <th>Event name</th>
                                <th>Organization</th>
                                <th>Content</th>
                                <th style={{ textAlign: "center" }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading || isFetching ? (
                                [1, 2, 3, 4, 5, 6, 7]?.map((val) => (
                                    <tr key={val}>
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                    </tr>
                                ))
                            ) : filterData?.length > 0 ? (
                                filterData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((data) => {
                                    const hasAccess = data?.type === 'custom' || userType?.includes("Super-Admin");
                                    return (
                                        <tr key={data?.id}>
                                            <td className="name-text" style={ellipsis}>{data?.title}</td>
                                            <td>{data?.type}</td>
                                            <td>{data?.eventName || "-"}</td>
                                            <td>{data?.organizationName || "-"}</td>
                                            <td style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "1px" ,cursor:"pointer"}} onClick={() => handleShowContentModal(data?.content ? data?.content : "", false)}>
                                                {/* {data?.delivery === "push" ? data?.content : "Email template"} */}
                                                {data?.content}
                                            </td>
                                            <td>
                                                <div className="action-wrap">
                                                    <Tooltip title={data?.type === "default" ? "Over Ride" : "Redo"}>
                                                        <Link
                                                            to=""
                                                            variant="primary"
                                                            onClick={() => data?.type === "default" ? handleOverRideRedo(data) : handleDelete(data?.id, data?.title)}
                                                            className="delete-btn"
                                                        >
                                                            <img style={{ width: "-webkit-fill-available" }} src={overRideIcon} alt="Delete" />
                                                        </Link>
                                                    </Tooltip>
                                                    {/*<Button style={{ marginRight: "1rem", whiteSpace: "noWrap" }} onClick={() => data?.type === "default" ? handleOverRideRedo(data) : handleDelete(data?.id, data?.title)}>{data?.type === "default" ? "Over-ride" : "Redo"}</Button>*/}
                                                    {hasAccess ? (
                                                        <Tooltip title={"Edit"}>
                                                            <Link to="" variant="primary" onClick={() => handleShow(data, false)} className="success-btn">
                                                                <img src={EditIcon} alt="Edit" />
                                                            </Link>
                                                        </Tooltip>
                                                    ) : (
                                                        <Tooltip title={"View"}>
                                                            <Link to="" variant="primary" onClick={() => handleShow(data, true)} className="success-btn">
                                                                <img src={ViewIcon} alt="View" />
                                                            </Link>
                                                        </Tooltip>
                                                    )}
                                                    <Tooltip title={hasAccess ? "Delete" : "You dont have permission"}>
                                                        <Link
                                                            to=""
                                                            variant="primary"
                                                            onClick={() => hasAccess && (deleting ? null : handleDelete(data?.id, data?.title))}
                                                            className="delete-btn"
                                                            style={{ opacity: hasAccess ? "1" : "0.2" }}
                                                        >
                                                            {deleting === data?.id ? (
                                                                <Spinner size="lg" className="text-current" style={{ color: "white", background: "transparent", margin: "0" }} />
                                                            ) : (
                                                                <img src={DeleteIcon} alt="Delete" />
                                                            )}
                                                        </Link>
                                                    </Tooltip>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <TableCell colSpan="5" style={{ textAlign: "center" }}>
                                        No Data Available
                                    </TableCell>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
                {filterData?.length > 0 ? <CustomPagination tableData={filterData} totalRows={totalRows} rowsPerPage={rowsPerPage} page={page} handlePage={(pageNo) => setPage(pageNo)} /> : null}
            </section>
        </React.Fragment>
    );
}

export default NotificationsList;
