import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../patients-detail.css';
import { addThreshold } from "../../../apps/api/ThresholdGroup/index"
import { failed, success } from "../../../common/Toastify";
import { Button } from "../../../common/Button";
import { conditionOptions, customThresholdFormikObj } from "../Constants";
import TextInput from "../../../common/textfield/TextInput";
import { useFormik } from "formik";
import SelectFiled from "../../../common/textfield/SelectFiled";
import { convertVitalsValueToBaseValue } from "../../../Admin/Schedule/constants";
import { GetVitalsUnit } from "../../Threashold/Constants";

function CreateThresholdModal({ show, onHide, patientId, onUpdateThreshold, orgVitalsUnit }) {
    const [btnLoading, setBtnLoading] = useState(false);

    const formik = useFormik({
        ...customThresholdFormikObj,
        onSubmit: (values) => {
            let conditionData = {}
            if (values.condition === "blood_pressure") {
                conditionData["blood_pressure"] =
                    [{
                        "diastolic": {
                            "type": "medium",
                            "below": convertVitalsValueToBaseValue(values.diastolicBelowM, orgVitalsUnit?.blood_pressure?.systolic),
                            "above": convertVitalsValueToBaseValue(values.diastolicAboveM, orgVitalsUnit?.blood_pressure?.systolic),
                        },
                        "systolic": {
                            "type": "medium",
                            "below": convertVitalsValueToBaseValue(values.systolicBelowM, orgVitalsUnit?.blood_pressure?.systolic),
                            "above": convertVitalsValueToBaseValue(values.systolicAboveM, orgVitalsUnit?.blood_pressure?.systolic),
                        }
                    },
                    {
                        "diastolic": {
                            "type": "high",
                            "below": convertVitalsValueToBaseValue(values.diastolicBelowH, orgVitalsUnit?.blood_pressure?.systolic),
                            "above": convertVitalsValueToBaseValue(values.diastolicAboveH, orgVitalsUnit?.blood_pressure?.systolic),
                        },
                        "systolic": {
                            "type": "high",
                            "below": convertVitalsValueToBaseValue(values.systolicBelowH, orgVitalsUnit?.blood_pressure?.systolic),
                            "above": convertVitalsValueToBaseValue(values.systolicAboveH, orgVitalsUnit?.blood_pressure?.systolic),
                        }
                    }]
            }
            else {
                conditionData[`${values.condition}`] = [
                    { "type": "medium", "below": convertVitalsValueToBaseValue(values.below, GetVitalsUnit({ orgVitalsUnit, conditionKey: values.condition })), "above": convertVitalsValueToBaseValue(values.above, GetVitalsUnit({ orgVitalsUnit, conditionKey: values.condition })) },
                    { "type": "high", "below": convertVitalsValueToBaseValue(values.belowH, GetVitalsUnit({ orgVitalsUnit, conditionKey: values.condition })), "above": convertVitalsValueToBaseValue(values.aboveH, GetVitalsUnit({ orgVitalsUnit, conditionKey: values.condition })) }
                ]
            }
            let payload = {
                patientId: patientId,
                condition: conditionData,
                notificationId: [
                    "abdHHdka"
                ]
            }
            setBtnLoading(true)
            addThreshold(payload).then(async (res) => {
                onHide();
                success(res.message);
                await onUpdateThreshold()
            }).catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                .finally(() => setBtnLoading(false));
        }
    })

    return (
        <Modal
            show={show}
            onHide={() => onHide()}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered className="custom-dialog"
            backdropClassName
            backdrop={'static'}
        >
            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">
                    Create Custom Threshold
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="common-form" onSubmit={formik.handleSubmit}>
                    <SelectFiled keyField={"condition"} label={"Select Condition"} formik={formik} options={conditionOptions} />
                    <Row>
                        {formik.values.condition === "blood_pressure" ?
                            <>
                                <Row>
                                    <Col>
                                        <p>Blood Pressure Systolic(Medium) ({orgVitalsUnit?.blood_pressure?.systolic})</p>
                                        <TextInput keyField={"systolicBelowM"} label={"Alert if Value is Below"} formik={formik} placeholder={"Below systolic value?"} />
                                        <TextInput keyField={"systolicAboveM"} label={"Alert if Value is Above"} formik={formik} placeholder={"Above systolic value?"} />
                                    </Col>
                                    <Col>
                                        <p>Blood Pressure Diastolic(Medium) ({orgVitalsUnit?.blood_pressure?.systolic})</p>
                                        <TextInput keyField={"diastolicBelowM"} label={"Alert if Value is Below"} formik={formik} placeholder={"Below diastolic value?"} />
                                        <TextInput keyField={"diastolicAboveM"} label={"Alert if Value is Above"} formik={formik} placeholder={"Above diastolic value?"} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p>Blood Pressure Systolic(High) ({orgVitalsUnit?.blood_pressure?.systolic})</p>
                                        <TextInput keyField={"systolicBelowH"} label={"Alert if Value is Below"} formik={formik} placeholder={"Below systolic value?"} />
                                        <TextInput keyField={"systolicAboveH"} label={"Alert if Value is Above"} formik={formik} placeholder={"Above systolic value?"} />
                                    </Col>
                                    <Col>
                                        <p>Blood Pressure Diastolic(High) ({orgVitalsUnit?.blood_pressure?.systolic})</p>
                                        <TextInput keyField={"diastolicBelowH"} label={"Alert if Value is Below"} formik={formik} placeholder={"Below diastolic value?"} />
                                        <TextInput keyField={"diastolicAboveH"} label={"Alert if Value is Above(High)"} formik={formik} placeholder={"Above diastolic value?"} />
                                    </Col>
                                </Row>
                            </>
                            : <>
                                <p>Medium Range  (<GetVitalsUnit orgVitalsUnit={orgVitalsUnit} conditionKey={formik.values.condition} />)</p>
                                <Col>
                                    <TextInput keyField={"below"} label={"Alert if Value is Below"} formik={formik} placeholder={"Below systolic value?"} />
                                </Col>
                                <Col>
                                    <TextInput keyField={"above"} label={"Alert if Value is Above"} formik={formik} placeholder={"Below systolic value?"} />
                                </Col>
                                <p>High Range  (<GetVitalsUnit orgVitalsUnit={orgVitalsUnit} conditionKey={formik.values.condition} />)</p>
                                <Col>
                                    <TextInput keyField={"belowH"} label={"Alert if Value is Below"} formik={formik} placeholder={"Below systolic value?"} />
                                </Col>
                                <Col>
                                    <TextInput keyField={"aboveH"} label={"Alert if Value is Above"} formik={formik} placeholder={"Below systolic value?"} />
                                </Col>
                            </>}
                    </Row>

                    <div className="btn-wrap" style={{ display: "flex", justifyContent: "space-even" }}>
                        <Button onClick={() => onHide()} variant="secondary">Cancel</Button>
                        <Button isLoading={btnLoading} type="submit">
                            Create
                        </Button>
                    </div>
                </form>
            </Modal.Body >
        </Modal >
    );
}

export default CreateThresholdModal