import React from 'react';

export default React.createContext(null);


//import ZoomVideo from "@zoom/videosdk";
//import React, { useState } from "react";

//const ZoomContext = React.createContext();
//const zoomClient = ZoomVideo.createClient();

//const ZoomProvider = ({ children }) => {
//    const [zmClient, setZmClient] = useState(zoomClient);
//    const [sessionDetials, setSessionDetials] = useState({});

//    const updateSessionDetials = (key, val) => {
//        setSessionDetials({ ...sessionDetials, [key]: val });
//    }

//    return (
//        <ZoomContext.Provider
//            value={{
//                zmClient,
//                setZmClient,
//                sessionDetials,
//                updateSessionDetials,
//            }}
//        >
//            {children}
//        </ZoomContext.Provider>
//    );
//};

//const ZoomConsumer = ZoomContext.Consumer;
//export { ZoomConsumer, ZoomContext };

//export default ZoomProvider;
