import * as Yup from "yup";

export const frequencyOptions = [
    { value: "Daily", name: "Daily" },
    { value: "Weekly", name: "Weekly" },
    { value: "Monthly", name: "Monthly" },
];

export const noOfTimesOptions = [
    { value: "1", name: "One Time" },
    { value: "2", name: "Two Time" },
    { value: "3", name: "Three Time" },
];

export const timeDurationOptionsMonthly = [
    { value: "30", name: "30 Days" },
    { value: "60", name: "60 Days" },
    { value: "90", name: "90 Days" },
];

export const conditionsNameAndKey = {
    "Blood Glucose": "blood_glucose",
    "Blood Pressure": "blood_pressure",
    "Oximeter": "oxygen",
    "Weight Scale": "weight",
    "Temperature": "temperature",
    "Heart Rate": "heart_rate",
};

export const conditions = {
    blood_glucose: {
        name: "Blood Glucose",
        selected: false,
        frequency: "Daily",
        repeatTime: "1",
        device: "",
    },
    blood_pressure: {
        name: "Blood Pressure",
        selected: false,
        frequency: "Daily",
        repeatTime: "1",
        device: "",
    },
    oxygen: {
        name: "Oximeter",
        selected: false,
        frequency: "Daily",
        repeatTime: "1",
        device: "",
    },
    heart_rate: {
        name: "Heart Rate",
        selected: false,
        frequency: "Daily",
        repeatTime: "1",
        device: "",
    },
    weight: {
        name: "Weight Scale",
        selected: false,
        frequency: "Daily",
        device: "",
    },
    temperature: {
        name: "Temperature",
        selected: false,
        frequency: "Daily",
        repeatTime: "1",
        device: "",
    },
};
export const conditionsCorespondingName = {
    "Blood Glucose": "blood_glucose",
    "Blood Pressure": "blood_pressure",
    "Heart Rate": "heart_rate",
    "Oximeter": "oxygen",
    "Weight Scale": "weight",
    "Temperature": "temperature",
};

export const FormikObj = {
    initialValues: {
        title: "",
        description: "",
        organization: "",
        time: "",
        condition: [],
        isDefault: false,
        RPM: "",
    },
    validationSchema: Yup.object({
        title: Yup.string().required('Please fill the Title field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        description: Yup.string().required('Please fill the Description field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        organization: Yup.string().when("isDefault", (isDefault, schema) =>
            isDefault
                ? schema
                : Yup.string().required('Please select any one Organization').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.')),
        time: Yup.number().typeError("Only numbers are valid").required('Required Time field'),
        condition: Yup.array().min(1, "Please select any one condition checkbox.").required('Please select any one condition first.'),
    }),
}

export const FormikObjEdit = {
    initialValues: {
        title: "",
        description: "",
        time: "",
        organisation: "",
        isDefault: false,
        groups: [],
    },
    validationSchema: Yup.object({
        title: Yup.string().required('Please fill the Title field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        description: Yup.string().required('Please fill the Description field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        time: Yup.number().typeError("Only numbers are valid").required('Required Duration field'),
        organisation: Yup.string().when("isDefault", (isDefault, schema) =>
            isDefault
                ? schema
                : Yup.string().required('Please select any one Organization').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.')),
    }),
}

export const UsersDataFields = [
    'name',
    'phone',
    'birthDate',
    'Address',
    'organization',
    "actions"
];

export const UsersHeadCells = [
    {
        id: 'name',
        label: 'Name',
        type: 'text',
        isDecrypted: true,
        style: { "text-transform": "capitalize" },
        nested: { key: "name", value: ({ index: 0, key: "text" }) },
    },
    {
        id: 'birthDate',
        label: 'Date of birth',
        type: 'text',
        isDecrypted: true,
        nested: { key: "birthDate" },
    },
    {
        id: 'phone',
        label: 'Phone Number',
        type: 'text',
        isDecrypted: true,
        nested: { key: "telecom", value: { index: 0, key: "value" } },
    },
    {
        id: 'address',
        label: 'Address',
        type: 'text',
        isDecrypted: true,
        nested: { key: "address", value: { index: 0, key: "line", } },
    },
    {
        id: 'organization',
        label: 'Organization',
        type: 'text',
        style: { textAlign: "center" },
        nested: { key: "managingOrganization", value: { index: 0, key: "display" } },
    },
    {
        id: 'actions',
        label: 'Actions',
        type: 'text',
        style: { textAlign: "center" },
    },
];