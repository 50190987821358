import React, { useEffect, useState } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import { Button } from "../../common/Button";
import { FormikObj } from "./Constants";
import DateSelector from "../../common/textfield/DateSelector";
import moment from "moment";
import TextInput from "../../common/textfield/TextInput";
import TextArea from "../../common/textfield/TextArea";
import SelectFiled from "../../common/textfield/SelectFiled";
import TimeSelector from "../../common/textfield/TimeSelector";
import { addPatientMedication } from "../api/Patient";
import { failed, success } from "../../common/Toastify";
import { dosageSet, dosagePhrased, texts as dosageTexts, dosageToInterval } from "../../../utils/DoseSupport";

function CreateMedicationSchedule({ patientId, modalShow, handleShow, refreshMed }) {
    const [btnLoading, setBtnLoading] = useState(false);
    const [repeatsTime, setRepeatsTime] = useState({});

    const formik = useFormik({
        ...FormikObj,
        onSubmit: (values) => {
            if (!handleTimeError()) {
                setBtnLoading(true);
                const repeatsTimeTemp = Object.values(repeatsTime).map(value => ({ time: moment(value.time).format("HH:mm") }))
                addPatientMedication({ ...values, patientId, repeatsTime: repeatsTimeTemp })
                    .then((res) => {
                        if (res?.status === true) {
                            success(res.message);
                            handleShow();
                            refreshMed()
                        } else {
                            failed(res.error);
                        }
                    })
                    .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                    .finally(() => setBtnLoading(false));
            }
        },
    });

    const handleRepeatsTimeChange = (val, keyNo) => {
        setRepeatsTime({ ...repeatsTime, [keyNo]: { ...repeatsTime?.[keyNo], time: val, error: true } })
    }
    const handleTimeError = () => {
        let temp = { ...repeatsTime }
        Array.from(Array(Object.keys(temp).length).keys()).forEach(key => {
            temp = { ...temp, [key + 1]: { ...temp?.[key + 1], error: temp?.[key + 1]?.time ? false : "Please provide Time" } };
        })
        setRepeatsTime(temp)
        return Object.values(temp).some((e) => e.error);
    }

    const handleFormChange = (e) => {
        if (e.target.id === "repeats") {
            setRepeatsTime(
                Object.keys(repeatsTime).filter((key) => Array(parseInt(e.target.value)).fill(0).map((e, i) => i + 1).includes(parseInt(key))).reduce((obj, key) => {
                    return Object.assign(obj, {
                        [key]: repeatsTime[key] || {}
                    });
                }, {})
            )
        }
    }

    useEffect(() => {
        formik.setFieldValue("end", moment(formik.values.start).add(1, 'days'))
    }, [formik.values.start])
    
    return (
        <Modal
            show={modalShow}
            onHide={handleShow}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdropClassName
            backdrop={'static'}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Create a medication schedule
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form
                    className="common-form border-fields"
                    onSubmit={formik.handleSubmit}
                    onChange={handleFormChange}
                >
                    <Row>
                        <Col>
                            <DateSelector
                                keyField={"start"}
                                label={"Start Date"}
                                formik={formik}
                                minDate={moment()}
                                maxDate={""}
                                hasValue={moment()}
                                placeholder={"Start Date"}
                            />
                        </Col>
                        <Col>
                            <DateSelector
                                keyField={"end"}
                                label={"End Date"}
                                formik={formik}
                                minDate={moment(formik.values.start)}
                                maxDate={""}
                                hasValue={moment()}
                                placeholder={"End Date"}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextInput
                                keyField={"name"}
                                label={"Medication Name"}
                                formik={formik}
                                placeholder={"Medication Name"}
                            />
                        </Col>
                        <Col>
                            <TextInput
                                keyField={"dose"}
                                label={"Dose"}
                                formik={formik}
                                placeholder={"Dose"}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextArea
                                keyField={"reason"}
                                label={"Reason for Use"}
                                formik={formik}
                                placeholder={"Reason, why should patient take this medication..."}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SelectFiled
                                keyField={"repeats"}
                                label={"Medication Dosage"}
                                formik={formik}
                                options={dosageSet('standard').map((dosage) => 
                                ({ "name": dosagePhrased(dosage), "value": dosage })
                                )}
                            />
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row style={{ display: "grid", gridTemplateColumns: "repeat(2,1fr)" }}>
                        {formik.values.repeats ? Array.from(Array(dosageToInterval(formik.values.repeats)).keys()).map((keyNo) => {
                            return (
                                <Col key={keyNo}>
                                    <TimeSelector
                                        label={`${dosageTexts('dosage')} ${keyNo + 1}`}
                                        value={repeatsTime?.[keyNo + 1]?.time}
                                        onChange={(val) => handleRepeatsTimeChange(val, keyNo + 1)}
                                        error={repeatsTime?.[keyNo + 1]?.error}
                                    />
                                </Col>
                            )
                        }) : null}
                    </Row>

                    <div className="btn-wrap">
                        <Button onClick={() => { handleShow() }} variant="secondary" title="Cancel">
                            Cancel
                        </Button>
                        <Button type="submit" isLoading={btnLoading}>
                            Create
                        </Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default CreateMedicationSchedule;