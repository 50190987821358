import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import TextInput from "../../common/textfield/TextInput";
import SelectFiled from "../../common/textfield/SelectFiled";
import { Button } from "../../common/Button";
import { failed, success } from "../../common/Toastify";
import { EditQuestionCategoryFormikObj, questionnaireForOptions } from "./Constant";
import { editQuestionCategory } from "../../api/Questionnaire";
import { useQuestionQuery } from "../../../hooks/ReactQueryHooks/useQuestionQuery";


const EditCategory = ({ modalShow, handleShow, refreshTable, isReadOnly }) => {
    const [btnLoading, setBtnLoading] = useState(false);    
    const { isLoading, isFetching, data } = useQuestionQuery({ onSuccess: () => { } });

    const formik = useFormik({
        ...EditQuestionCategoryFormikObj,
        onSubmit: (values) => {        
            setBtnLoading(true)
            editQuestionCategory(modalShow?.id, {...values}).then((res) => {
                success(res.message);
                handleShow();
                refreshTable();
            }).catch(res => {
                failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
            }).finally(() => setBtnLoading(false));
        },
    });

    useEffect(() => {
        if (modalShow?.id) {
            formik.setFieldValue("categoryName", modalShow?.categoryName || "");
            formik.setFieldValue("categoryType", modalShow?.categoryType || "");
            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalShow]);

   

    if (!modalShow) {
        return null;
    }

    return (
        <Modal
            show={modalShow}
            onHide={handleShow}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="custom-dialog"
            backdropClassName
            backdrop={'static'}
        >
            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">
                    {isReadOnly ? "View" : "Edit"} Question Category
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form
                    className="common-form border-fields"
                    onSubmit={formik.handleSubmit}
                >
                    <Row>
                        <Col>
                            <TextInput disabled={isReadOnly} keyField={"categoryName"} label={"Category Name"} formik={formik} placeholder={"Category Name"} />
                        </Col>
                        <Col>
                            <SelectFiled
                                keyField={"categoryType"}
                                label={"Category Type"}
                                formik={formik}
                                placeholder={"Select"}
                                options={questionnaireForOptions}
                                disabled={isReadOnly}
                            />
                        </Col>
                    </Row>  
                    {!isReadOnly ? (                
                    <div className="btn-wrap" style={{ display: "flex", marginTop: "2rem" }}>
                            <Button variant="secondary" title="Cancel" onClick={handleShow}>
                                Cancel
                            </Button>
                        <Button type="submit" isLoading={btnLoading} >
                        Update
                        </Button>
                    </div>) : null } 
                   
                        
                </form>
            </Modal.Body>
        </Modal >
    );
}

export default EditCategory;
