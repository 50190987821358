import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Checkbox from "../../common/textfield/Checkbox";
import { Form } from "react-bootstrap";

export function SurveySettings({ formik }) {
    return (
        <>
            <Row>
                <Col>
                    <h5 style={{ color: "rgba(18, 31, 72, 1)" }}>Survey Settings</h5>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Checkbox required={false} keyField={"satisfactionSurvey"} label={"Satisfaction Survey"} formik={formik} checked={formik.values.satisfactionSurvey} />
                </Col>
                {formik.values.satisfactionSurvey ? <Col>
                    <Row>
                        <Col sm={2}><span style={{
                            fontWeight: "600"
                        }}>Survey</span></Col>
                        <Col>
                            {["mandatory", "optional"].map((val) => (
                                <Form.Check style={{ textTransform: "capitalize" }} inline checked={formik.values.survey === val} type={"radio"} label={val} id={val} name="survey" onChange={e => formik.setFieldValue("survey", e.target.id)} />
                            ))}
                        </Col>
                    </Row>
                </Col> : null}
            </Row>
        </>
    );
}
