import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { decryptData } from '../../EncryptDecrypt';
import Background from "../../../assets/images/search-icon.png"

const IndividualSearchBar = ({ list = [], filterData, encoded = false, placeholder = "Search...", page, setPage, onSearch, searchVal, setSearchVal}) => {

    const getName = (nameField) => {
        return typeof nameField === "string" ? nameField : nameField?.[0]?.text
    }

    const getEmail = (emailField) => {
        return emailField?.map((ele) => {
            return ele?.value;
        });
    }

    const filter = () => {
        const data = encoded ?
            list?.filter((data) =>
                decryptData(getName(data?.name))?.toLowerCase()?.includes(searchVal) ||
                decryptData(getEmail(data?.telecom)?.join())?.toString()?.toLowerCase()?.includes(searchVal) 
            ) :
            list?.filter((data) =>
                getName(data?.name)?.toLowerCase()?.includes(searchVal) ||
                getEmail(data?.telecom)?.join()?.toString()?.toLowerCase()?.includes(searchVal)
            )
        filterData(data);
    }

    useEffect(() => {
        filter();
    }, [searchVal, list])

    const handleSearch = (e) => {
        setPage(0);
        const value = e.target.value.toLowerCase();
        setSearchVal(value);
        onSearch(value);
    };
    
    return (
        <Form className="search-wrap">
            <Form.Control
                type="search"
                placeholder={placeholder}
                className="me-2"
                aria-label="Search"
                style={{ backgroundImage: searchVal ? "none" : `url(${Background})` }}
                value={searchVal}
                onChange={handleSearch}
            />
        </Form>
    )
}

export default IndividualSearchBar