import { useQuery } from "react-query";
import { onError } from "./onError";
import { axios } from "../../lib/axios";
import { useSelector } from "react-redux";

export function useCustomRolesQuery({ orgIds, onSuccess, isList }) {
    const userType = useSelector((state) => state?.auth?.user?.['cognito:groups']);
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations);
    const fetch = (orgIds = userType.includes("Super-Admin") ? null : adminOrgs?.map(org => org.id)) => {
        return orgIds ?
            isList ?
                axios.get(`codeSystem?orgIds=${JSON.stringify(orgIds)}&isList=true`)
                : axios.get(`codeSystem?orgIds=${JSON.stringify(orgIds)}`)
            : axios.get("codeSystem")
    }

    const query = useQuery("custom_roles", () => fetch(orgIds),
        {
            select: (data) => data.data,
            onSuccess,
            onError
        }
    );

    return { ...query }
}