import { TextField } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import React, { useState } from 'react';

function TimeSelector({ keyField, error, readOnly = false, onChange, min = "", max= "", label, minutesStep = 5, required = true, value = false, ...props }) {
    const [open, setOpen] = useState(false)
    return (
        <div className="field-wrap createPatientDOB">
            {label ? <label htmlFor={keyField}>
                {label}
                {required ? <span style={{ color: "red" }}> *</span> : " (Optional)"}
            </label> : null}
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <TimePicker
                    value={value}
                    onChange={onChange}
                    closeOnSelect
                    onOpen={() => !readOnly && setOpen(true)}
                    onClose={() => !readOnly && setOpen(false)}
                    open={open}
                    minutesStep={minutesStep}
                    readOnly={readOnly}
                    minTime={min}
                    maxTime={max}
                    renderInput={(params) => <TextField onClick={() => !readOnly && setOpen(true)} {...params} error={false} />}
                    {...props}
                />
            </LocalizationProvider>
            <div>{error ? <div className="error-text">{error}</div> : null}</div>
        </div>
    )
}

export default TimeSelector