import * as Yup from "yup";
import { formattedPhoneNumberLength } from "../../../utils/phoneNumberSupport";
import moment from "moment";
export const OrganizationHeadCells = [
    {
        id: 'name',
        label: 'Name',
        type: 'text',
        nested: { key: "name" },
    },
    {
        id: 'state',
        label: 'Province/State',
        type: 'text',
        nested: { key: "address", value: { index: 0, key: "state" } },
    },
    {
        id: 'parent',
        label: 'Parent Organization',
        type: 'text',
        nested: { key: "partOf", value: { key: "display" } },
    },
    {
        id: 'actions',
        label: 'Actions',
        type: 'text',
        style: { textAlign: "center" },
    },
];
export const OrganizationDataFields = [
    'name',
    'state',
    'parent',
    "actions"
];
export const filterStatusOptions = [ 
    { name: "Active", "value": "true" },
    { name: "Inactive", "value": "false" }
]
const validateImageDimensions = (minWidth, minHeight, maxWidth, maxHeight) => {
    return (value) => {
        if (!value) return true; // If no image is uploaded, validation passes
        const image = new Image();
        image.src = URL.createObjectURL(value);

        return new Promise((resolve) => {
            image.onload = () => {
                const width = image.width;
                const height = image.height;

                if (
                    width >= minWidth &&
                    height >= minHeight &&
                    width <= maxWidth &&
                    height <= maxHeight
                ) {
                    resolve(true); // Image dimensions are within the specified range
                } else {
                    resolve(false); // Image dimensions are not within the specified range
                }
            };
        });
    };
};

export const FormikObj = {
    initialValues: {
        name: "",
        address: "",
        state: "",
        country: "",
        city: "",
        postalCode: "",
        phone_number: "",
        ext: "",
        email: "",
        description: "",
        appointment_cancel_time: "",
        typeOfDeviceUnitType: "",
        deviceUnitType: "",
        oxygen: "",
        heart_rate: "",
        weight: "",
        temperature: "",
        blood_pressure: "",
        blood_glucose: "",
        orgLogoURL: "",
        isWaitingRoom: false,
        hoursOfOperation: false,
        satisfactionSurvey: false,
        survey: "optional",
        sftEnabled: false,
        mfaEnabled: true,
        expirationDays: 1,
        privacyStatement: "",
        supportStatement: "",
        patientApptAutoAcceptance: false,
        practitionerApptAutoAcceptance: false,

        
    },
    // validationSchema: Yup.object({
    //     name: Yup.string().max(30, "Must be 30 characters or less").required("Required name field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
    //     address: Yup.string().required("Required address field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
    //     state: Yup.string().required("Required field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
    //     country: Yup.string().required("Required country field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
    //     city: Yup.string().required("Required city field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
    //     postalCode: Yup.string().when("country", (country, schema) =>
    //         country === "US"
    //             ? Yup.number().typeError("Zip code must be a number").required("Required field")
    //             : Yup.string().required("Required field").matches(/^[A-Za-z]\d[A-Za-z] [0-9][A-Za-z]\d$/, "Postal code must be in A1A 1A1 format.")

    //     ),
    //     phone_number: Yup.string().typeError('you must specify a number').test("len", "Invalid phone number", (val) => {
    //         const val_length_without_dashes = formattedPhoneNumberLength(val)
    //         return val_length_without_dashes === 13;
    //     }).required("Required phone number field")
    //         .matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
    //     // TODO Multilingual Support
    //     ext: Yup.string().matches(/^\d{0,4}$/, 'Must be 0 to 4 digits.'),
    //     email: Yup.string().email("Invalid email address").required("Required email field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
    //     appointment_cancel_time: Yup.number().typeError("Appointment cancellation time must be a number").min(1, "Minimum 1 hour").required("Required appointment cancellation time field"),
    //     typeOfDeviceUnitType: Yup.string().required("Required type Of Device Unit Type field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),

    //     deviceUnitType: Yup.string().when("typeOfDeviceUnitType", (typeOfDeviceUnitType, schema) =>
    //         typeOfDeviceUnitType === "group"
    //             ? schema.required("Required Device Unit Type field")
    //             : schema
    //     ),
    //     oxygen: Yup.string().when("typeOfDeviceUnitType", (typeOfDeviceUnitType, schema) =>
    //         typeOfDeviceUnitType === "individual"
    //             ? schema.required("Required field")
    //             : schema
    //     ),
    //     heart_rate: Yup.string().when("typeOfDeviceUnitType", (typeOfDeviceUnitType, schema) =>
    //         typeOfDeviceUnitType === "individual"
    //             ? schema.required("Required field")
    //             : schema
    //     ),
    //     weight: Yup.string().when("typeOfDeviceUnitType", (typeOfDeviceUnitType, schema) =>
    //         typeOfDeviceUnitType === "individual"
    //             ? schema.required("Required field")
    //             : schema
    //     ),
    //     temperature: Yup.string().when("typeOfDeviceUnitType", (typeOfDeviceUnitType, schema) =>
    //         typeOfDeviceUnitType === "individual"
    //             ? schema.required("Required field")
    //             : schema
    //     ),
    //     blood_pressure: Yup.string().when("typeOfDeviceUnitType", (typeOfDeviceUnitType, schema) =>
    //         typeOfDeviceUnitType === "individual"
    //             ? schema.required("Required field")
    //             : schema
    //     ),
    //     blood_glucose: Yup.string().when("typeOfDeviceUnitType", (typeOfDeviceUnitType, schema) =>
    //         typeOfDeviceUnitType === "individual"
    //             ? schema.required("Required field")
    //             : schema
    //     ),        
    //     orgLogoURL: Yup.mixed()
    //         .test("fileSize", "The file is too large, max 5 Mb.", (value) => {
    //             return value ? value.size <= 5 * 1024 * 1024 : true;
    //         })
    //         .test("type", "Only the .jpg or .png format is acceptable", (value) => {
    //             if (!value) return true;
    //             const acceptedFormats = ["image/jpeg", "image/png"];
    //             return acceptedFormats.includes(value.type);
    //         })
    //         .test(
    //             "dimensions",
    //             "Image dimensions must be under 3075 x 1200 pixels",
    //             validateImageDimensions(100, 100, 3075, 1200)
    //         ),
    //     isWaitingRoom: Yup.boolean(),
    //     hoursOfOperation: Yup.boolean(),

    //    //  sftEnabled: Yup.boolean(),
        
                
    //     privacyStatement: Yup.string().when("sftEnabled", (sftEnabled , schema)=> 
    //         sftEnabled === true 
    //             ? Yup.string().required("Required Privacy Statement field"). max(250, "Must be 250 characters or less") : schema
    //             ),
    //     supportStatement: Yup.string().when("sftEnabled", (sftEnabled, schema )=> 
    //         sftEnabled === true 
    //             ? Yup.string().required("Required Support Statement field"). max(250, "Must be 250 characters or less") : schema
    //             ),

    //     expirationDays: Yup.number().when("sftEnabled", (sftEnabled , schema)=> 
    //     sftEnabled === true 
    //         ? Yup.number().required("Required Expiration Days field").typeError("Expiration days must be a number")
    //         .integer("Expiration days must be an integer")
    //         .min(1, "Expiration days must be between 1 to 7.")
    //         .max(7, "Expiration days must be between 1 to 7.") : schema
    //         ),
        
    //     patientApptAutoAcceptance: Yup.boolean(),
    //     practitionerApptAutoAcceptance: Yup.boolean(),

        

    // }),
}

export const getValidationSchema = (selectedTab) => {
    switch (selectedTab) {
        case "Details":
            return Yup.object({
                name: Yup.string().max(30, "Must be 30 characters or less").required("Required name field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
                address: Yup.string().required("Required address field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
                state: Yup.string().required("Required field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
                country: Yup.string().required("Required country field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
                city: Yup.string().required("Required city field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
                postalCode: Yup.string().when("country", (country, schema) =>
                    country === "US"
                        ? Yup.number().typeError("Zip code must be a number").required("Required field")
                        : Yup.string().required("Required field").matches(/^[A-Za-z]\d[A-Za-z] [0-9][A-Za-z]\d$/, "Postal code must be in A1A 1A1 format.")
                ),
                phone_number: Yup.string().typeError('you must specify a number').test("len", "Invalid phone number", (val) => {
                    const val_length_without_dashes = formattedPhoneNumberLength(val)
                    return val_length_without_dashes === 13;
                }).required("Required phone number field")
                    .matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
                // TODO Multilingual Support
                ext: Yup.string().matches(/^\d{0,4}$/, 'Must be 0 to 4 digits.'),
                email: Yup.string().email("Invalid email address").required("Required email field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
                orgLogoURL: Yup.mixed()
                .test("fileSize", "The file size should be up to 2 MB.", (value) => {
                    return value ? (value.size <= 2 * 1024 * 1024) : true;
                })
                .test("type", "Only the .jpg or .png format is acceptable", (value) => {
                    if (!value) return true;
                    const acceptedFormats = ["image/jpeg", "image/png"];
                    return acceptedFormats.includes(value.type);
                })
                // .test(
                //     "dimensions",
                //     "Image dimensions must be under 300 x 200 pixels",
                //     validateImageDimensions(100, 100, 300, 200)
                // ),
            });
        case "Virtual Visit":
            return Yup.object({
                isWaitingRoom: Yup.boolean(),
                hoursOfOperation: Yup.boolean(),
                patientApptAutoAcceptance: Yup.boolean(),
                practitionerApptAutoAcceptance: Yup.boolean(),
                appointment_cancel_time: Yup.number().typeError("Appointment cancellation time must be a number").min(1, "Minimum 1 hour").required("Required appointment cancellation time field"),
            });
        case "RPM":
            return Yup.object({
                typeOfDeviceUnitType: Yup.string().required("Required type Of Device Unit Type field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),

                deviceUnitType: Yup.string().when("typeOfDeviceUnitType", (typeOfDeviceUnitType, schema) =>
                    typeOfDeviceUnitType === "group"
                        ? schema.required("Required Device Unit Type field")
                        : schema
                ),
                oxygen: Yup.string().when("typeOfDeviceUnitType", (typeOfDeviceUnitType, schema) =>
                    typeOfDeviceUnitType === "individual"
                        ? schema.required("Required field")
                        : schema
                ),
                heart_rate: Yup.string().when("typeOfDeviceUnitType", (typeOfDeviceUnitType, schema) =>
                    typeOfDeviceUnitType === "individual"
                        ? schema.required("Required field")
                        : schema
                ),
                weight: Yup.string().when("typeOfDeviceUnitType", (typeOfDeviceUnitType, schema) =>
                    typeOfDeviceUnitType === "individual"
                        ? schema.required("Required field")
                        : schema
                ),
                temperature: Yup.string().when("typeOfDeviceUnitType", (typeOfDeviceUnitType, schema) =>
                    typeOfDeviceUnitType === "individual"
                        ? schema.required("Required field")
                        : schema
                ),
                blood_pressure: Yup.string().when("typeOfDeviceUnitType", (typeOfDeviceUnitType, schema) =>
                    typeOfDeviceUnitType === "individual"
                        ? schema.required("Required field")
                        : schema
                ),
                blood_glucose: Yup.string().when("typeOfDeviceUnitType", (typeOfDeviceUnitType, schema) =>
                    typeOfDeviceUnitType === "individual"
                        ? schema.required("Required field")
                        : schema
                ), 
            });
        case "File Transfer":
            return Yup.object({
                privacyStatement: Yup.string().when("sftEnabled", (sftEnabled , schema)=> 
                sftEnabled === true 
                    ? Yup.string().required("Required Privacy Statement field"). max(10000, "Must be 10000 characters or less") : schema
                    ),
                supportStatement: Yup.string().when("sftEnabled", (sftEnabled, schema )=> 
                    sftEnabled === true 
                        ? Yup.string().required("Required Support Statement field"). max(10000, "Must be 10000 characters or less") : schema
                        ),
        
                expirationDays: Yup.number().when("sftEnabled", (sftEnabled , schema)=> 
                sftEnabled === true 
                    ? Yup.number().required("Required Expiration Days field").typeError("Expiration days must be a number")
                    .integer("Expiration days must be an integer")
                    .min(1, "Expiration days must be between 1 to 7.")
                    .max(7, "Expiration days must be between 1 to 7.") : schema
                    ),
            });
        default:
            return Yup.object({});
    }
};

export const filterKeysFunc = (parentId, selectedId, parentInputsValue, selectedData) => {
    return Object.keys(parentInputsValue).filter((key) => selectedData.includes(key))
        .reduce((obj, key) => {
            if (key === parentId) {
                return Object.assign(obj, { [key]: selectedId });
            }
            return Object.assign(obj, { [key]: parentInputsValue[key] });
        }, {});
}

export const typeOfDeviceUnitTypeOptions = [
    { value: "group", name: "Group" },
    { value: "individual", name: "Individual" },
]
export const deviceUnitTypeOptions = [
    { value: "canadian", name: "Canadian(°C, KG, mmol/L, mmHg, SpO2%, bpm)" },
    { value: "american", name: "American(°F, LBS, mg/dL, mmHg, SpO2%, bpm)" },
]
export const devicesField = [
    {
        formikKey: "blood_glucose", name: "Blood Glucose", options: [
            { value: "mmol/L", name: "mmol/L" },
            { value: "mg/dL", name: "mg/dL" },]
    },
    {
        formikKey: "blood_pressure", name: "Blood Pressure", options: [
            { value: "mmHg", name: "mmHg" }
        ]
    },
    {
        formikKey: "temperature", name: "Temperature", options: [
            { value: "°C", name: "°C" },
            { value: "°F", name: "°F" },
        ]
    },
    {
        formikKey: "weight", name: "Weight", options: [
            { value: "kg", name: "kg" },
            { value: "lbs", name: "lbs" },
        ]
    },
    {
        formikKey: "oxygen", name: "Oxygen", options: [
            { value: "SpO2%", name: "SpO2%" },
        ]
    },
    {
        formikKey: "heart_rate", name: "Heart Rate", options: [
            { value: "bpm", name: "bpm" },
        ]
    },
]

export const ORGANIZATION_DETAILS = "Organization Details";
export const PARENT_ORGANIZATION = "Parent Organization";
export const NAME = "Name";
export const DESCRIPTION = "Description";
export const ADDRESS = "Address";
export const COUNTRY = "Country";
export const STATE = "State";
export const CITY = "City";
export const ZIP_CODE = "Zip Code";
export const POSTAL_CODE = "Postal Code";
export const PHONE_NUMBER = "Phone Number";
export const EXT = "Ext.";
export const EMAIL_ADDRESS = "Email Address";
export const APPOINTMENT_CANCEL_TIME = "Appointment Cancel Time (Hr)";
export const DEVICE_UNIT_TYPE = "Device's unit Type";
export const TYPE = "Type";
export const WAITING_ROOM_FACILITY = "Waiting Room Facility";
export const CANCEL = "Cancel";
export const UPDATE = "Update";
export const CREATE = "Create";

export const operationTiming = {
    Monday: {
        startTime: null,
        endTime: null
    },
    Tuesday: {
        startTime: null,
        endTime: null
    },
    Wednesday: {
        startTime: null,
        endTime: null
    },
    Thursday: {
        startTime: null,
        endTime: null
    },
    Friday: {
        startTime: null,
        endTime: null
    },
    Saturday: {
        startTime: null,
        endTime: null
    },
    Sunday: {
        startTime: null,
        endTime: null
    }
}

export const timeSelectorStyle = {
    height: "100%",
    verticalAlign: "middle",
    display: "flex",
    alignItems: "center",
    fontWeight: 500
}

export function transformTimingData(waitingRoomTimings) {
    const daysOfWeek = [
        "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"
    ];

    const operationTiming = daysOfWeek.map(day => {
        const startTime = waitingRoomTimings[day].startTime;
        const endTime = waitingRoomTimings[day].endTime;
        // Format startTime and endTime here as needed            
        const formattedStartTime = startTime !== null ? startTime.utc() : "";
        const formattedEndTime = endTime !== null ? endTime.utc() : "";
        return {
            weekDay: day,
            startTime: formattedStartTime,
            endTime: formattedEndTime,
        };
    });

    return operationTiming;
}

export function getDeviceUnit(values) {
    return {
        type: values.typeOfDeviceUnitType,
        blood_glucose: {
            glucose: values.typeOfDeviceUnitType === "group"
                ? values.deviceUnitType === "canadian"
                    ? "mmol/L"
                    : "mg/dL"
                : values.blood_glucose,
        },
        temperature: {
            temperature: values.typeOfDeviceUnitType === "group"
                ? values.deviceUnitType === "canadian"
                    ? "°C"
                    : "°F"
                : values.temperature,
        },
        weight: {
            weight: values.typeOfDeviceUnitType === "group"
                ? values.deviceUnitType === "canadian"
                    ? "kg"
                    : "lbs"
                : values.weight,
        },
        oxygen: {
            oxygen: values.typeOfDeviceUnitType === "group" ? "SpO2%" : values.oxygen,
        },
        heart_rate: {
            heartRate: values.typeOfDeviceUnitType === "group" ? "bpm" : values.heart_rate,
        },
        blood_pressure: {
            diastolic: values.typeOfDeviceUnitType === "group"
                ? "mmHg"
                : values.blood_pressure,
            systolic: values.typeOfDeviceUnitType === "group"
                ? "mmHg"
                : values.blood_pressure,
        },
    };
}
