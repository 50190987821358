import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import TextInput from "../../common/textfield/TextInput";
import { Button } from "../../common/Button";
import { failed, success } from "../../common/Toastify";
import { editProgram } from "../../api/Program";
import { conditions, conditionsCorespondingName, FormikObjEdit, frequencyOptions, noOfTimesOptions } from "./Constant";
import { useListOfRpmDevicesQuery } from "../../../hooks/ReactQueryHooks/useListOfRpmDevicesQuery";
import { useGroupsQuery } from "../../../hooks/ReactQueryHooks/useGroupsQuery";
import MultiSelect from "../../common/textfield/MultiSelect";
import { useMedia } from "react-use";

// edit-program dialog starts
const EditProgram = ({ modalShow, handleShow, refreshTable, isReadOnly }) => {
    const [btnLoading, setBtnLoading] = useState(false);
    const [conditionsState, setconditionsState] = useState([]);
    const [RPMdevices, setRPMDevices] = useState([]);
    const [deviceError, setDeviceError] = useState(false);
    const [groups, setGroups] = useState([]);

    const makeResponsiveTable = useMedia('(max-width: 980px)')

    useGroupsQuery({
        onSuccess: (data) => setGroups(data.map(({ name, id }) => ({ value: id, id, name }))),
        queryParams: `orgId=["${modalShow?.managingOrganization?.[0]?.reference?.split("/")?.[1]}"]&isCustom=true`,
    })
    let programStatus = "edit";
    useListOfRpmDevicesQuery({ programStatus, onSuccess: setRPMDevices })

    const formik = useFormik({
        ...FormikObjEdit,
        onSubmit: (values) => {
            if (Object.keys(conditionsState).some((cond) => conditionsState?.[cond]?.selected && !conditionsState?.[cond]?.device)) {
                setDeviceError(true)
            } else {
                setBtnLoading(true)
                const selectedConditions = Object.keys(conditionsState)
                    .map((cond) => (conditionsState[cond].selected ? conditionsState[cond] : null))
                    .filter((n) => n);
                const device = Object.keys(conditionsState)
                    .map((cond) => {
                        if (conditionsState?.[cond]?.selected) {
                           // let id
                            let id = conditionsState[cond]?.device;
                            //  const id = RPMdevices.filter((obj) => obj?.conditionName === cond)?.[0]?.id
                            // const deviceDetails = RPMdevices.filter((obj) => obj?.conditionName === cond)
                            // if (deviceDetails?.length > 1) {
                            //     if (cond === "heart_rate") {
                            //         const checkArray = selectedConditions?.filter((obj) => obj?.name === "Heart Rate")
                            //         id = deviceDetails?.filter((obj) => obj?.id === checkArray?.[0]?.device)?.[0]?.id
                            //     }
                            // } else {
                            //     id = deviceDetails?.[0]?.id
                            // }
                            return {
                                id,
                                "reference": `RPMDevice/${id}`,
                                "type": "RPMDevice",
                            }
                        }
                        return null
                    }).filter((n) => n);
                const customGroup = groups.filter((group) => values.groups.includes(group.id))
                    .map((group) => ({
                        "id": group.id,
                        "reference": `Group/${group.id}`,
                        "type": "Group"
                    }))
                editProgram({ ...values, condition: selectedConditions, organization: modalShow?.organization?.[0], id: modalShow?.id }, device, values.groups, customGroup,).then((res) => {
                    success(res.message);
                    handleShow();
                    refreshTable();
                }).catch(res => {
                    failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                }).finally(() => setBtnLoading(false));
            }
        },
    });

    const handleConditionsChange = (event, conditionKey) => {
        if (!isReadOnly) {
            const { name, value, checked } = event.target;
            name === "selected"
                ? setconditionsState({
                    ...conditionsState,
                    [conditionKey]: { ...conditionsState[conditionKey], [name]: checked },
                })
                : setconditionsState({
                    ...conditionsState,
                    [conditionKey]: { ...conditionsState[conditionKey], [name]: value },
                });
        }
    };

    useEffect(() => {
        if (modalShow?.id) {
            const newConditions = { ...conditions }
            modalShow.condition.map((item) => {
                newConditions[conditionsCorespondingName[item?.name]] = {
                    name: item?.name,
                    repeatTime: item?.repeatTime,
                    frequency: item?.frequency,
                    selected: true,
                    device: item?.device,
                }
                return item
            });
            setconditionsState(newConditions);
            formik.setFieldValue("title", modalShow?.title || "");
            formik.setFieldValue("description", modalShow?.description || "");
            formik.setFieldValue("time", modalShow?.duration.value);
            formik.setFieldValue("id", modalShow?.id);
            formik.setFieldValue("organisation", modalShow?.managingOrganization?.[0]?.display || "");
            formik.setFieldValue("groups", modalShow?.customGroupId || []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalShow]);

    if (!modalShow) {
        return null;
    }


    return (
        <Modal
            show={modalShow}
            onHide={handleShow}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="custom-dialog"
            backdropClassName
            backdrop={'static'}
        >
            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">
                    {isReadOnly ? "View" : "Edit"} Program
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form
                    className="common-form border-fields"
                    onSubmit={formik.handleSubmit}
                >
                    <Row>
                        <Col>
                            <TextInput readOnly={isReadOnly} keyField={"title"} label={"Title"} formik={formik} />
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextInput
                                readOnly={isReadOnly}
                                keyField={"description"}
                                label={"Description"}
                                formik={formik} />
                        </Col>
                    </Row>
                    <div className="program-conditions-table" style={{ overflowX: makeResponsiveTable ? "scroll" : "hidden", marginBottom: makeResponsiveTable ? "2rem" : "0" }}>
                        <table style={{ width: "max-content" }}>
                            <thead>
                                <tr>
                                    <td>Select Condition</td>
                                    <td style={{ textAlign: "center" }}>Select Device</td>
                                    <td style={{ textAlign: "center" }}>Select Frequency</td>
                                    <td style={{ textAlign: "center" }}>How many Times</td>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(conditionsState).map((conditionKey) => {
                                    const { frequency, selected, repeatTime, name, device } = conditionsState?.[conditionKey];
                                    return (
                                        <tr className="check-select-wrap field-wrap" key={conditionKey}>
                                            <td>
                                                <label className="custom-checkbox">
                                                    {name}
                                                    <input disabled={isReadOnly} style={{ width: "auto" }} name="selected" type="checkbox" checked={selected} onChange={(e) => handleConditionsChange(e, conditionKey)} />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                <select style={deviceError && (selected && !device) ? { margin: "0" } : {}} disabled={isReadOnly || !selected} name="device" placeholder="Device" value={device} onChange={(e) => handleConditionsChange(e, conditionKey)}>
                                                    <option value={""} disabled>Select</option>
                                                    {RPMdevices.filter((item) => item.conditionName === conditionKey).map(({ deviceName, id }) => (
                                                        <option value={id} key={id}>
                                                            {deviceName}
                                                        </option>
                                                    ))}
                                                </select>
                                                <div>
                                                    {deviceError && (selected && !device) ? <div style={{ fontSize: '14px', color: '#dc3545' }}>Required field</div> : null}
                                                </div>
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                <select disabled={isReadOnly || !selected} name="frequency" placeholder="Group" value={frequency} onChange={(e) => handleConditionsChange(e, conditionKey)}>
                                                    {frequencyOptions.map(({ value, name }) => (
                                                        <option value={value} key={value}>
                                                            {name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                {repeatTime ? (
                                                    <>
                                                        <select disabled={isReadOnly || !selected} name="repeatTime" placeholder="Group" value={repeatTime} onChange={(e) => handleConditionsChange(e, conditionKey)}>
                                                            {noOfTimesOptions.map(({ value, name }) => (
                                                                <option value={value} key={value}>
                                                                    {name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </>
                                                ) : null}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <Row>
                        <Col>
                            <TextInput readOnly={isReadOnly} keyField={"time"} label={"Duration(Days)"} formik={formik} placeholder={"30"} />
                        </Col>
                        <Col>
                            <TextInput keyField={"organisation"} readOnly={true} label={"Organization"} formik={formik} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <MultiSelect required={false} defaultValue={modalShow?.customGroupId} readOnly={isReadOnly} keyField={"groups"} label={"Add groups"} formik={formik} options={groups} />
                        </Col>
                        <Col></Col>
                    </Row>
                    <div style={{ color: "red" }}>{formik.errors.condition}</div>
                    {!isReadOnly ?
                        <div className="btn-wrap">
                            <Button onClick={handleShow} variant="secondary" title="Cancel">
                                Cancel
                            </Button>
                            <Button type="submit" isLoading={btnLoading}>
                                Update Details
                            </Button>
                        </div> : null}
                </form>
            </Modal.Body>
        </Modal >
    );
}

export default EditProgram;
