import * as Yup from "yup";

const SUPPORTED_FORMATS = ["application/pdf"];
const FILE_SIZE = 160 * 1024;

export const FormikObj = {
    initialValues: {
        id: "",
        name: "",
        organization: "",
        orgName:"",
        description: "",
        category: "",
        file: null,
        status: 'new'
    },
    validationSchema: Yup.object({
        name: Yup.string().max(50, "Maximum length is 50 characters.").required("Required name field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        organization: Yup.string().required("Required category field"),
        category: Yup.string().required("Required category field"),
        description: Yup.string().max(250, "Maximum length is 250 characters.").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        file: Yup.mixed()
            .nullable()
            .required()
            // .test("FILE_SIZE", "Uploaded file is too big.", (value) => !value || (value && value.size <= FILE_SIZE))
            // .test("FILE_FORMAT", "Uploaded file has unsupported format.", (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type)))
        // isRootLevel: Yup.boolean(),
    }),
}

export const FormikObjEdit = {
    initialValues: {
        id: "",
        name: "",
        organization: "",
        orgName: "",
        description: "",
        category: "",
        file: null,
        status: 'new'
    },
    validationSchema: Yup.object({
        name: Yup.string().max(50, "Maximum length is 50 characters.").required("Required name field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        organization: Yup.string().required("Required category field"),
        category: Yup.string().required("Required category field"),
        description: Yup.string().max(250, "Maximum length is 250 characters.").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        file: Yup.mixed().nullable().notRequired()
    }),
}

export const DocumentsHeadCells = [
    {
        id: 'name',
        label: 'Name',
        type: 'text',
        nested: { key: "name" },
    },
    {
        id: 'category',
        label: 'Category',
        type: 'text',
        nested: { key: "category" },
    },
    {
        id: 'Description',
        label: 'description',
        type: 'text',
        nested: { key: "description" },
    },
    {
        id: 'status',
        label: 'Status',
        type: 'text',
        nested: { key: "status" },
    },
    {
        id: 'actions',
        label: 'Actions',
        type: 'text',
        style: { textAlign: "center" },
    },
];
export const DocumentsDataFields = [
    'name',
    'category',
    'description',
    'status',
    'actions'
];

export const filterStatusOptions = [
    { name: "All", "value": "all" },
    { name: "Active", "value": "new" },
    { name: "Inactive", "value": "inactive" }
]

export const categoryOptions = [
    { value: "Lab Requisition", name: "Lab Requisition" },
    { value: "Diagnostic Requisition", name: "Diagnostic Requisition" },
    { value: "Referral", name: "Referral" },
    { value: "Prescription", name: "Prescription" }
];