import moment from "moment";
import { axios } from "../../../../lib/axios";

export const updateAppointmentRequest = (payload) => {
    return axios.put("appointment", { ...payload, isFromWeb: true })
}

export const getAppointments = ({practitionerId, status}) => {
    return axios.get(`appointment?practitionerId=${practitionerId}&status=${status}`);
};
export const createEncounter = ({ patient, practitioner, appointmentId, appointmentTime, orgId, questionnaireInfo, statusHistory, patientLocation}) => {
    const payload = {
        subject: patient,
        status: "in-progress",
        period: {
            start: appointmentTime
        },
        patientLocation: (patientLocation) ? patientLocation : questionnaireInfo?.location,
        participant: [
            {
                individual: practitioner,
                period: {
                    start: moment().utc().format()
                }
            }
        ],
        type: "encounter",
        appointmentId,
        managingOrganization: {
            reference: `Organization/${orgId}`,
            type: "Organization",
        },
        intakeQuestions: questionnaireInfo,
        statusHistory: statusHistory
    }
    return axios.post("encounter", payload)
}

export const createEncounterInbound = ({ patient, status, practitionerId, practitionerName, proceedWith, orgId, questionnairInfo, requestedPeriod, statusHistory, patientLocation }) => {
    const payload = {
        "subject": patient,
        "managingOrganization": {
            "reference": `Organization/${orgId}`,
            "type": "Organization"
        },
        "practitionerId": practitionerId,
        "participant": [
            {
                "individual": {
                    "reference": `Practitioner/${practitionerId}`,
                    "type": "Practitioner",
                    "display": practitionerName
                }
            }
        ],
        "period": requestedPeriod,
        "type": [
            {
                "coding": [
                    {
                        "code": "VV",
                        "display": "Virtual Visit Encounter Type"
                    }
                ]
            }
        ],
      //  "questionnaireResponse": questionnairInfo,
        "patientLocation": (patientLocation) ? patientLocation : questionnairInfo?.location,
        "intakeQuestions": questionnairInfo,
        "proceedWith": proceedWith,
        "status" : status,
        "statusHistory": statusHistory
    }
    return axios.post("encounter", payload)
}

export const editEncounter = ({encounterId, status, patient, practitionerId, practitionerName, proceedWith, orgId, questionnairInfo, requestedPeriod, patientLocation, statusHistory, actor}) => {
    const payload = {
        "subject": patient,
        "managingOrganization": {
            "reference": `Organization/${orgId}`,
            "type": "Organization"
        },
        "practitionerId": practitionerId,
        "participant": [
            {
                "individual": {
                    "reference": `Practitioner/${practitionerId}`,
                    "type": "Practitioner",
                    "display": practitionerName
                }
            }
        ],
        "requestedPeriod": requestedPeriod,
        "type": [
            {
                "coding": [
                    {
                        "code": "VV",
                        "display": "Virtual Visit Encounter Type"
                    }
                ]
            }
        ],
     //   "questionnaireResponse": questionnairInfo, 
        "intakeQuestions": questionnairInfo,
        "proceedWith": proceedWith,
        "status" : status,
        "patientLocation": (patientLocation) ? patientLocation : questionnairInfo?.location,
        "statusHistory": statusHistory,
        "actor": actor
    }
    return axios.put(`encounter/${encounterId}`, payload)
}

export const bookAppointment = ({serviceCategory, slot, practitionerId, patientId, requestedPeriod, description, comment, sessionToken, isReschedule = false, document = null, intakeQuestions, patientLocation }) => {
    const payload = {
        requestType: practitionerId ? "provider" : "organization",
        status: "proposed",
        description,
        practitionerId,
        comment,
        patientId,
        requestedPeriod,
        document: null,
        isFromWeb: true,
        sessionToken,
        isReschedule,
        intakeQuestions,
        patientLocation,
        slot,
        serviceCategory
    }
    return axios.post("appointment", payload)
}

export const rescheduleAppointment = ({ serviceCategory, keepOldSlot, slot, appointmentId, requestedPeriod, practitionerId, description }) => {
    const payload = {
        appointmentId,
        requestedPeriod,
        practitionerId,
        description,
        requestor: `Practitioner/${practitionerId}`,
        slot,
        keepOldSlot,
        serviceCategory,
    }
    return axios.post("appointment/reschedule", payload)
}
export const checkSchedule = ({ actorId, date, orgId }) => {
    return axios.get(`schedule/check?actorId=${actorId}&date=${date}${orgId ? `&orgId=${orgId}`:``}`)
}
export const getlistPractitioners = ({ orgId, scopeOfPractice }) => {
    return axios.get(`practitioner?org_id=${orgId}&scopeOfPractice=${scopeOfPractice}`)
}
export const getSlots = ({ actorId, date, serviceCategoryId, orgId  }) => {
    return axios.get(`schedule/patient?actorId=${actorId}&date=${date}&serviceCategoryId=${serviceCategoryId}${orgId ? `&orgId=${orgId}`:``}`)
}