import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Switch from '@mui/material/Switch';
import MFASetupModal from './MFASetupModal';
import { getMFASettings, removeMFAAuthAppSettings } from '../api/MFA';
import { useSelector } from 'react-redux';
import { failed, success } from '../common/Toastify';
import { CircularProgress } from '@mui/material';
import { getOrganization } from '../api/Organization';

const MFALanding = () => {
    const username = useSelector((state) => state?.auth?.user?.email);
    const [settingsLoading, setSettingsLoading] = useState(false);
    const [orgSettingsLoading, setOrgSettingsLoading] = useState(false);
    const [isMFAEnabled, setIsMFAEnabled] = useState(false);
    const [mfaModalShow, setMFAModalShow] = useState(false);
    const [orgDefaultMFASettings, setOrgDefaultMFASettings] = useState(false);
    const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations);
    const [organizationId, setOrganizationId] = useState("");
    const admin = userRoles?.includes('Super-Admin');
    const [mfaType, setMfaType] = useState("");

    useEffect(() => {
        if (!admin) {
            setOrganizationId(adminOrgs?.[0]?.id)
        }
    }, []);


    useEffect(() => {
        setOrgSettingsLoading(true);
        getOrganization(organizationId)
            .then((res) => {
                if (res?.data[0]?.mfaSettings?.enabled === true) {
                    setOrgDefaultMFASettings(true);
                }
            })
            .catch((res) => {
                failed(
                    res?.response?.data?.message ||
                    res?.response?.data?.error ||
                    res.message
                );
            })
            .finally(() => setOrgSettingsLoading(false));
    }, [])

    const handleToggle = () => {
        if (!isMFAEnabled) {
            setMFAModalShow(true);
        } else {
            setSettingsLoading(true);
            if (!orgDefaultMFASettings && !admin) {
                removeMFAAuthAppSettings({ accessToken: localStorage?.getItem('accessToken'), challengeType: mfaType })
                    .then((res) => {
                        if (res?.["$metadata"]?.httpStatusCode === 200) {
                            success("MFA Disabled Successfully");
                            setIsMFAEnabled(false);
                            setMfaType("");
                        }
                    })
                    .catch((res) => {
                        console.log("ERROR IN REMOVING MFA: ", res)
                    })
                    .finally(() => setSettingsLoading(false))
            } else {
                failed("MFA cannot be disabled. Enforced by Organization");
                setSettingsLoading(false);
            }
        }

    };

    useEffect(() => {
        setSettingsLoading(true);
        getMFASettings({ username: username })
            .then((res) => {
                if (res?.UserMFASettingList && res.UserMFASettingList.length > 0) {
                    setMfaType(res?.UserMFASettingList[0]);
                    setIsMFAEnabled(true);
                } else {
                    setIsMFAEnabled(false);
                }
            })
            .catch((res) => {
                console.log("ERROR FROM GET MFA SETTINGS: ", res)
            })
            .finally(() => setSettingsLoading(false))

    }, []);

    return (
        <section className="common-listing">
            <div className="heading-wrap">
                <h2>MFA Settings</h2>
            </div>

            {(settingsLoading || orgSettingsLoading) ? (
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "80vh"
                }}>
                    <CircularProgress size={50} />

                </div>
            ) : (
                <>
                    <div className="custom-card p-4">
                        <form className="common-form border-fields">
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label htmlFor="mfaToggle">Multi Factor Authentication:</label>
                                        <Switch
                                            id="mfaToggle"
                                            checked={isMFAEnabled}
                                            onChange={handleToggle}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            {mfaType !== "" && (
                                <Row>
                                    <Col>
                                        <div style={{ marginTop: "10px" }}>
                                            <span>
                                                <b><span style={{ color: "#015da8" }}>MFA Method Enabled:</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                                                {mfaType === "SMS_MFA" ? "SMS based authentication" : "Authenticator App based authentication"}
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </form>
                    </div>
                </>
            )}



            {mfaModalShow && <MFASetupModal modalShow={mfaModalShow} handleClose={() => setMFAModalShow(false)} />}
        </section>
    );
};

export default MFALanding;
