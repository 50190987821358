import { useQuery } from "react-query";
import { axios } from "../../lib/axios";
import { onError } from "./onError";

export function useQuestionQuery({ onSuccess, }) {

    const fetch = () => {
        return axios.get("/question")
    }

    const query = useQuery(
        "questions-list",
        () => fetch(),
        {
            select: (data) => data.data,
            onSuccess,
            onError
        }
    );

    return { ...query };
}
