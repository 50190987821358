import React, { Fragment, useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import PdfViewerComponent from '../../common/components/PdfViewerComponent';
import {usePdfRefDocumentRequestQuery} from '../../../hooks/ReactQueryHooks/usePdfRefDocumentRequestQuery';

const PdfServiceRequestModal = ({ modalShow, handleShow }) => {
    const [docData, setDocData] = useState([]);
    const instanceRef = useRef(null);    
    const onSuccessViewMedication = (data) => {
        if(data){
            setDocData(data);
        }
    };

    usePdfRefDocumentRequestQuery({ onSuccess: onSuccessViewMedication, Id: modalShow?.id, typeData:modalShow?.resourceType }) 

    return (
        <Modal
            backdropClassName
            backdrop={'static'}
            show={modalShow} onHide={handleShow} size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered className="custom-dialog">
            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">View PDF</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="Pdf-viewer">
                <PdfViewerComponent document={docData?.[0]?.content?.[0]?.attachment?.base64String} instanceRef={instanceRef} />
            </div>
            </Modal.Body>
        </Modal>
    )
}
export default PdfServiceRequestModal
