import { useQuery } from "react-query";
import { axios } from "../../lib/axios";
import { onError } from "./onError"

export function useGetAppointmentNoteQuery({ onSuccess, appointmentId }) {

    const fetch = () => {
        return axios.get(`/note?appointmentId=${appointmentId}`);
    };
    const query = useQuery(
        ["appointment-note", appointmentId],
        () => fetch(),
        {
            select: (data) => data.data,
            onSuccess,
            onError
        }
    );

    return { ...query };
}
