import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import { Button } from "../../common/Button";
import { failed, success } from "../../common/Toastify";
import TextInput from "../../common/textfield/TextInput";
import { Add, Remove } from "@mui/icons-material";
import SelectFiled from "../../common/textfield/SelectFiled";
import { answerTypeOptions, EditSurveyFormikObj, purposeOption } from "./Constants";
import { useNavigate } from "react-router-dom";
import { editQuestionnaire, deleteQuestionnaire } from "../../api/Questionnaire";
import moment from "moment";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import { useSelector } from "react-redux";
import { ViewDate } from "../../../utils/DateSupport";


const iconStyle = {
    width: "35px",
    height: "35px",
    borderRadius: "10px",
    backgroundColor: "rgba(0, 93, 168, 0.12)",
    textAlign: "center",
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
}

export function EditSurveys({ modalShow, handleShow, refreshTable, selectedSurvey, isReadOnly }) {
    const [btnLoading, setBtnLoading] = useState(false);
    const { getConfirmation } = useConfirmDialog();
    const [deleting, setDeleting] = useState(false);
    const userType = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
    const hasAccess = userType?.includes("Super-Admin") ? true : !!!modalShow?.isDefault;

    let n = 1000;
    const [rows, setRows] = useState([
        {
            id: n++,
            text: "",
            type: "",
            highRateText: "",
            lowRateText: "",
            required: "",
        }
    ]);
    const navigate = useNavigate();

    useEffect(() => {
        if (modalShow?.id) {
            formik.setFieldValue("title", modalShow?.title || "");
            formik.setFieldValue("description", modalShow?.description || "");
            formik.setFieldValue("question", modalShow?.item?.map((question) => question) || "");
            formik.setFieldValue("purpose", modalShow?.purpose || "");
        }
    }, [modalShow]);

    const formik = useFormik({
        ...EditSurveyFormikObj,
        onSubmit: (values) => {
            setBtnLoading(true);
            const questions = values?.question?.map((row, index) => ({
                text: row.text,
                type: row.type,
                highRatingText: row.highRatingText,
                lowRatingText: row.lowRatingText,
                required: row.required,
            }));

            const requestBody = {
                title: values.title,
                keyString: values.keyString,
                question: questions,
                isDefault: true,
                // purpose: values.purpose,
                purpose: "survey-appointment",
                description: values.description,
            };

            editQuestionnaire(modalShow?.id, requestBody).then((res) => {
                refreshTable();
                handleShow();
                success(res.message);

            }).catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                .finally(() => setBtnLoading(false));
        },
    });


    const addRow = () => {
        const newRow = { id: n++, text: "", type: "stars", highRateText: "", lowRateText: "", required: false, checked: "" };
        formik.setFieldValue("question", [...formik.values.question, newRow]);
    };

    const removeRow = (index) => {
        const updatedQuestions = [...formik.values.question];
        updatedQuestions.splice(index, 1);
        formik.setFieldValue("question", updatedQuestions);
    };

    const handleAnswerTypeChange = (index, newValue) => {
        formik.setFieldValue(`question.[${index}].type`, newValue);
    };

    const handlePurposeChange = (e) => {
        formik.setFieldValue('purpose', e.target.value);
    }

    const handleDelete = async (id, entryName, purposeData) => {
        const confirmed = await getConfirmation({
            title: "Attention!",
            entryName,
            actionBtnTitle: "Delete",
        });
        if (confirmed) {
            setDeleting(id);
            let payload = {
                "status": "retired",
                "purpose": purposeData,
                "type": "survey"
            }
            deleteQuestionnaire(id, payload).then(() => {
                // setFilterData(filterData.filter(data => data.id !== id));
                success("Survey Deleted!");
                handleShow();
                refreshTable();
            }).catch(res => failed(res?.response?.data?.message || res?.response?.data?.error || res.message)).finally(() => setDeleting(false));
        }
    };

    return (
        <Modal
            size="lg"
            show={modalShow}
            onHide={handleShow}
            aria-labelledby="contained-modal-title-vcenter"
            backdropClassName
            backdrop={'static'}
            centered className="custom-dialog">
            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">
                    {isReadOnly ? 'View Survey Questionnaire' : 'Update Survey Questionnaire'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form
                    className="common-form border-fields"
                    onSubmit={formik.handleSubmit}
                >
                    <Row>
                        <Col>
                            <TextInput keyField={"title"} label={"Title"} formik={formik} placeholder={"Write Title"} disabled={isReadOnly} />
                        </Col>
                        <Col>
                            <SelectFiled
                                keyField={"purpose"}
                                label={"Purpose"}
                                formik={formik}
                                disabled={isReadOnly}
                                options={purposeOption}
                                readOnly={true}
                            // onChange={handlePurposeChange}
                            />
                        </Col>
                    </Row>
                    <hr />

                    {formik.values.question?.map((row, index) => (<>
                        <div key={row.id}>
                            <Row >
                                <Col style={{ marginBottom: '-1.5rem' }}>
                                    <TextInput
                                        keyField={`question[${index}].text`}
                                        label={"Survey Question"}
                                        formik={formik}
                                        placeholder={"Write Here"}
                                        disabled={isReadOnly}
                                        isError={formik?.touched?.question?.[index]?.text && formik?.errors?.question?.[index]?.text}
                                    />
                                </Col>
                                <Col style={{ display: "flex", alignItems: "center", gap: "1rem", marginBottom: '-1.5rem' }}>
                                    <SelectFiled
                                        keyField={`question.[${index}].type`}
                                        label={"Answer Type"}
                                        formik={formik}
                                        options={answerTypeOptions}
                                        fieldWrapStyle={{ width: "100%" }}
                                        disabled={isReadOnly}
                                        onChange={(e) => handleAnswerTypeChange(index, e.target.value)}
                                        isError={formik?.touched?.question?.[index]?.type && formik?.errors?.question?.[index]?.type}
                                    />
                                    {index > 0 && (
                                        <div style={iconStyle}>
                                            <Remove
                                                style={{ width: "inherit", height: 'inherit', color: "rgba(0, 93, 168, 1)" }}
                                                onClick={() => !isReadOnly ? removeRow(index) : null}
                                            />
                                        </div>
                                    )}
                                    {index === 0 && (
                                        <div style={iconStyle}>
                                            <Add
                                                style={{ width: "inherit", height: 'inherit', color: "rgba(0, 93, 168, 1)" }}
                                                onClick={() => !isReadOnly ? addRow() : null}
                                            />
                                        </div>
                                    )}

                                </Col>

                            </Row>
                            <Row > <Col style={{ marginBottom: '1.5rem' }}> </Col> </Row>
                            <Row >
                                {formik.values.question[index].type !== "freeText" && (
                                    <>
                                        <Col style={{ marginBottom: '0rem' }}>
                                            <TextInput
                                                keyField={`question[${index}].highRatingText`}
                                                label={"High Rating Text"}
                                                formik={formik}
                                                disabled={isReadOnly}
                                                placeholder={"Write Here"}
                                                required={false}
                                            //  isError={formik?.touched?.question?.[index]?.highRatingText && formik?.errors?.question?.[index]?.highRatingText}
                                            />
                                        </Col>
                                        <Col style={{ marginBottom: '0rem' }}>
                                            <TextInput
                                                keyField={`question[${index}].lowRatingText`}
                                                label={"Low Rating Text"}
                                                formik={formik}
                                                disabled={isReadOnly}
                                                placeholder={"Write Here"}
                                                required={false}
                                            //  isError={formik?.touched?.question?.[index]?.lowRatingText && formik?.errors?.question?.[index]?.lowRatingText}
                                            />
                                        </Col>
                                    </>
                                )}
                                <Col style={{ marginBottom: '0rem' }}>
                                    <div className="field-wrap" style={{ margin: 0, display: "flex", height: "100%", alignItems: "center", marginTop: "20px" }}>
                                        <label className="custom-checkbox" style={{ margin: "-5px 0 0" , width: "fit-content" }}>
                                            Is Mandatory
                                            <input
                                                style={{ width: "auto" }}
                                                keyField={`required-${index}`}
                                                type="checkbox"
                                                disabled={isReadOnly}
                                                checked={formik.values?.question[index].required}
                                                onChange={() => { formik.setFieldValue(`question[${index}].required`, !formik.values?.question[index].required) }}
                                            />
                                            <span keyField={`checked-${index}`} className="checkmark"></span>
                                        </label>
                                    </div>
                                </Col>
                                <Col></Col>
                            </Row>

                        </div>

                    </>
                    ))}
                    <hr />
                    <Row>
                        <Col>
                            <TextInput keyField={"description"} label={"Thank You Message"} disabled={isReadOnly} formik={formik} placeholder={"Write Message"} />
                        </Col>
                        <Col></Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col>
                            <div>
                                <h6>Date Created : {ViewDate(modalShow?.createdAt)}</h6>
                                <h6>Date Last Updated : {ViewDate(modalShow?.updatedAt)} </h6>
                            </div>
                        </Col>
                        <Col>
                            <div>
                                {!isReadOnly ? (<>
                                    <div
                                    // className="btn-wrap"
                                    // style={{ display: "flex", marginTop: "12rem" }}
                                    >
                                        <Row>
                                            <Col sm={12} md={12} lg={4} xl={4} className="text-center" >
                                                <Button variant="secondary" title="Cancel" onClick={handleShow} style={{ width: '100%', marginBottom: '10px', padding: '5px 10px' }} >
                                                    Cancel
                                                </Button> </Col>
                                            <Col sm={12} md={12} lg={4} xl={4} className="text-center"  >
                                                <Button type="submit" isLoading={btnLoading} style={{ width: '100%', marginBottom: '10px', padding: '5px 10px' }} >
                                                    Update
                                                </Button> </Col>
                                            <Col sm={12} md={12} lg={4} xl={4} className="text-center"  >
                                                <Button variant="secondary" title="Delete" onClick={() => hasAccess && (deleting ? null : handleDelete(modalShow?.id, modalShow?.title, modalShow?.purpose))} style={{ width: '100%', marginBottom: '10px', padding: '5px 10px' }} >
                                                    Delete
                                                </Button></Col>
                                        </Row>
                                    </div>
                                </>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                    {/* <div className="btn-wrap" style={{ display: 'flex' }}>
                        <Button variant="secondary" title="Cancel" onClick={handleShow}>
                            Cancel
                        </Button>
                        {!isReadOnly ? <Button type="submit" isLoading={btnLoading}>
                            Update
                        </Button> : null}

                    </div> */}
                </form>

            </Modal.Body>
        </Modal>
    );
}

export default EditSurveys;
