import { useQuery } from "react-query";
import { onError } from "./onError";
import { axios } from "../../lib/axios";

export function usePatientReadingQuery({ id, date, onSuccess }) {
    const conditionArray = JSON.stringify(["blood_glucose","blood_pressure","heart_rate","oxygen","temperature","weight"])
    const fetch = (id, date) => {
        return date ? axios.get(`observation?patientId=${id}&date=${date}&conditionName=${conditionArray}`) : axios.get(`observation?patientId=${id}&conditionName=${conditionArray}`)
    }
    const query = useQuery(
        ["patient-readings", { date, id }],
        () => fetch(id, date),
        {
            select: (data) => data.data,
            onSuccess,
            onError
        }
    );
    return { ...query }
}