import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { WAITING_ROOM_FACILITY, timeSelectorStyle } from "./Constants";
import TimeSelector from "../../common/textfield/TimeSelector";
import Checkbox from "../../common/textfield/Checkbox";
import "./GetCareSetting.css"

const Header = (props) => {
    return (
        <>
            {!props.isMobile ?
                <Row>
                    <Col className="col-md-3 col-lg-4"></Col>
                    <Col className="col-md-4 col-lg-4 input-label">Start Time</Col>
                    <Col className="input-label col-md-4 col-lg-4">End Time</Col>
                </Row>
                : null}
        </>
    )
}
const FieldRow = (props) => {
    return (
        <Row className="days-row" key={props?.index}>
            <Col className="space col-md-2 col-lg-4 mb-2 mb-md-0">
                <div className="days-label" /* style={timeSelectorStyle} */>{props?.day}</div>
            </Col>
            <Col className="col-md-4 mb-3 col-lg-4 mb-md-0">
                <TimeSelector label={props.isMobile ? "Start time" : false} value={props.waitingRoomTimings[props?.day]?.startTime} onChange={val => props.handleTimeChange(props?.day, val, "startTime")} fieldWrapStyle={timeSelectorStyle} />
            </Col>
            <Col className="col-md-4 mb-3 col-lg-4 mb-md-0">
                <TimeSelector label={props.isMobile ? "End time" : false} value={props.waitingRoomTimings[props?.day]?.endTime} onChange={val => props.handleTimeChange(props?.day, val, "endTime")} fieldWrapStyle={timeSelectorStyle} min={props.waitingRoomTimings[props?.day]?.startTime} readOnly={!props.waitingRoomTimings[props?.day]?.startTime} />
            </Col>
        </Row>
    );
}
const HoursOfOperation = (props) => {
    return (
        <Row className="hours-operation-row">
            <Col className="col-lg-5">
                <Header isMobile={props.isMobile} />
                {["Monday", "Tuesday", "Wednesday", "Thursday"].map((day, index) =>
                    <FieldRow {...{ ...props, day, index }} />
                )}
            </Col>
            <Col className="col-lg-1"></Col>
            <Col className="col-lg-5">
                <Header isMobile={props.isMobile} />
                {["Friday", "Saturday", "Sunday"].map((day, index) =>
                    <FieldRow {...{ ...props, day, index }} />
                )}
            </Col>
        </Row>
    );
}

const GetCareSettings = ({ formik, waitingRoomTimings, waitingRoomTimingsError, isMobile, handleTimeChange, }) => {
    return (
        <>
            <Row>
                <Col>
                    <h5 style={{ color: "rgba(18, 31, 72, 1)" }}>Get Care Settings</h5>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Checkbox
                        keyField={"isWaitingRoom"}
                        label={WAITING_ROOM_FACILITY}
                        formik={formik}
                        checked={formik.values.isWaitingRoom}
                    />
                </Col>
            </Row>
            {formik.values?.isWaitingRoom ? (
                <>
                    <hr />
                    <Row>
                        <Col>
                            <Checkbox required keyField={"hoursOfOperation"} label={"Hours of operation (Waiting room) 24/7"} formik={formik} checked={formik.values.hoursOfOperation} />
                        </Col>
                    </Row>
                    {!formik.values?.hoursOfOperation ?
                        <HoursOfOperation waitingRoomTimings={waitingRoomTimings} isMobile={isMobile} handleTimeChange={handleTimeChange} />
                        : null}
                </>
            ) : null}
            <div>{waitingRoomTimingsError ? <div className="error-text">Please provide missing time</div> : null}</div>
        </>
    )
}

export default GetCareSettings;
