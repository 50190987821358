import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../common/components/SearchBar";
import { Button } from "../../common/Button";
import { useSelector } from "react-redux";
import { EditOrganization } from "./EditOrganization";
import CustomTable from "../../common/table/CustomTable";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import { OrganizationDataFields, OrganizationHeadCells, filterStatusOptions } from "./Constants";
import { useOrgQuery } from "../../../hooks/ReactQueryHooks/useOrgQuery";
import { commonDisableAPI } from "../../api/CommonApis";
import { getOrganization } from "../../api/Organization";
import { failed, success } from "../../common/Toastify";
import SelectFiled from "../../common/textfield/SelectFiled";
import { useFormik } from "formik";

function OrganizationList() {
    //React Query 
    const onSuccess = (data) => {  
        formik.setFieldValue("status", "true" );
        setOrganizations(data?.map((org) => org = { ...org, "value": org.id }))  
    }
    const { refetch, isFetching } = useOrgQuery({ onSuccess })
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { getConfirmation } = useConfirmDialog();
    const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
    const [page, setPage] = React.useState(0);
    const [modalShow, setModalShow] = React.useState(false);
    const [filterData, setFilterData] = React.useState([]);
    const [organizations, setOrganizations] = React.useState([]);
    const handleShow = (data) => { setModalShow(modalShow ? false : data) };
    const handleFilterData = (data) => {
        setFilterData(data)
    }
    const formik = useFormik({
        initialValues: { 
            status: "true",
        },
    });
    
    const selectedUserAction = ({ action, data }) => {
        switch (action) {
            case 'edit':
                handleShow(data)
                break;
            default:
                return null;
        }
    };

    const getOrganizationList = (apiArgs) => {
        setIsLoading(true);
        formik.setFieldValue("status", apiArgs.status );
        getOrganization( null, apiArgs.status ).then((res)=> {
            setIsLoading(false);  
            if(apiArgs.status == "true"){  
                setOrganizations(res?.data); 
            } else{   
                setOrganizations(res?.data.filter((data) => data.active == false));
            }
        })
        .catch((res) => {
            failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
        }).finally(() => { setIsLoading(false) });
    };

    const handleStatusFilter = (event) => {
        setIsLoading(true);
        formik.setFieldValue("status", event?.target?.value );
        getOrganization( null, event?.target?.value ).then((res)=> {
            setIsLoading(false); 

            if(event?.target?.value == "true"){ 
                setOrganizations(res?.data); 
            } else{ 
                setOrganizations(res?.data.filter((data) => data.active == false));
            }
        })
        .catch((res) => {
            failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
        }).finally(() => { setIsLoading(false) });

    };
   
      const modifiedData = filterData.map(org => {
        if (org.partOfRefId === "000000") {
          return {
            ...org,
            partOf: {
              display: "-"
            }
          };
        }
        return org;
      });     
     

    return (
        <section className="common-listing">
            {modalShow ? <EditOrganization
                modalShow={modalShow}
                refreshTable={() => getOrganizationList({ status: formik.values.status})}
                handleShow={handleShow}
                userRoles={userRoles}
            /> : null}
            <div className="heading-wrap h-change common-form">
                <SearchBar page={page} setPage={setPage} list={organizations} filterData={handleFilterData} />
                <SelectFiled
                    keyField={"status"}
                    label={false}
                    selectLabel={"Status"}
                    options={filterStatusOptions}
                    onChange={handleStatusFilter}
                    required={false}
                    optional={false}
                    formik={formik}
                />
                <div className="right-wrap" style={{ margin: 'left' }}>
                    {userRoles?.includes('Super-Admin') ? <Button onClick={() => navigate("/app/add-organization")} className="custom-btn" style={{ width: 'auto' }}> + Add Organization </Button> : null}
                </div>
            </div>

            <CustomTable
                tableData={modifiedData}
                headCells={OrganizationHeadCells}
                dataFields={OrganizationDataFields}
                selectedUserAction={selectedUserAction}
                rowOptions={false}
                loading={isLoading}
                fetching={isFetching}
                page={page}
                canDelete = {false}
                setPage={setPage}
            />
        </section >
    );
}

export default OrganizationList;