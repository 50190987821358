import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import EditIcon from "../../../assets/images/edit.png";
import DeleteIcon from "../../../assets/images/delete.png";
import { Link } from "react-router-dom";
import { Skeleton, TableCell, Tooltip } from "@mui/material";
import { Spinner } from "react-bootstrap";
import CustomPagination from "../../common/components/Pagination/CustomPagination";
import SearchBar from "../../common/components/SearchBar";
import { commonDisableAPI } from "../../api/CommonApis";
import { failed, success } from "../../common/Toastify";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import { EditModal } from "./EditModal";
import { useSelector } from "react-redux";
import ViewIcon from "../../../assets/images/view.png";
import { useCustomRolesQuery } from "../../../hooks/ReactQueryHooks/useCustomRolesQuery";

function ManageRole() {
    const { getConfirmation } = useConfirmDialog();
    const userType = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
    const [modalShow, setModalShow] = useState(false);
    const [filterData, setFilterData] = useState([]);
    const [rolesList, setRolesList] = useState([]);
    const [deleting, setDeleting] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const { isLoading, isFetching, refetch: refetchRoles, } = useCustomRolesQuery({ onSuccess: setRolesList, isList: true });

    //Related to Pagination
    const [page, setPage] = useState(0);
    const rowsPerPage = 10;
    const totalRows = filterData.length;

    const handleShow = (data, isReadOnly) => {
        setModalShow(modalShow ? false : data);
        setReadOnly(isReadOnly);
    };
    const handleFilterData = (data) => {
        setFilterData(data);
        setPage(0)
    };

    const handleDelete = async (id, entryName) => {
        const confirmed = await getConfirmation({
            title: "Attention!",
            entryName,
            actionBtnTitle: "Delete",
        });
        if (confirmed) {
            setDeleting(id);
            commonDisableAPI({ key: "codeSystem", id })
                .then(() => {
                    setFilterData(filterData?.filter((data) => data?.id !== id));
                    success("Role Deleted!");
                })
                .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                .finally(() => setDeleting(false));
        }
    };
    return (
        <section className="common-listing">
            {modalShow?.id ? <EditModal modalShow={modalShow} handleShow={handleShow} refetchRoles={refetchRoles} isReadOnly={readOnly} /> : null}
            <div className="heading-wrap h-change">
                <SearchBar page={page} setPage={setPage} list={rolesList} filterData={handleFilterData} />
                <div className="right-wrap" style={{ margin: "left" }}>
                    <Link variant="primary" to="/app/create-role" className="custom-btn" title="Create Roles" style={{ width: "auto" }}>
                        {" "}
                        + Create Role{" "}
                    </Link>
                </div>
            </div>
            <div className="table-wrap">
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Role Name</th>
                            <th>Organization</th>
                            <th style={{ textAlign: "center" }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading || isFetching ? (
                            [1, 2, 3, 4, 5, 6, 7]?.map((val) => (
                                <tr key={val}>
                                    <td>
                                        <Skeleton animation="wave" />
                                    </td>
                                    <td>
                                        <Skeleton animation="wave" />
                                    </td>
                                    <td>
                                        <Skeleton animation="wave" />
                                    </td>
                                </tr>
                            ))
                        ) : filterData?.length > 0 ? (
                            filterData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((data) => {
                                const { display, organization, id, isEditable } = data;
                                const hasAccess = isEditable || userType.includes("Super-Admin");
                                return (
                                    <tr key={id}>
                                        <td className="name-text">{display}</td>
                                        <td>{organization?.display || ""}</td>
                                        <td>
                                            <div className="action-wrap">
                                                {!hasAccess ? (
                                                    <Tooltip title={"View"}>
                                                        <Link to="" variant="primary" onClick={() => handleShow(data, true)} className="success-btn">
                                                            <img src={ViewIcon} alt="View" />
                                                        </Link>
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip title={"Edit"}>
                                                        <Link to="" variant="primary" onClick={() => handleShow(data, false)} className="success-btn">
                                                            <img src={EditIcon} alt="Edit" />
                                                        </Link>
                                                    </Tooltip>
                                                )}
                                                <Tooltip title={hasAccess ? "Delete" : "You dont have permission"}>
                                                    <Link to="" variant="primary" onClick={() => hasAccess && handleDelete(id, display)} className="delete-btn" style={{ opacity: hasAccess ? "1" : "0.2" }}>
                                                        {deleting === id ? (
                                                            <Spinner size="lg" className="text-current" style={{ color: "white", background: "transparent", margin: "0" }} />
                                                        ) : (
                                                            <img src={DeleteIcon} alt="Delete" />
                                                        )}
                                                    </Link>
                                                </Tooltip>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <TableCell colSpan="5" style={{ textAlign: "center" }}>
                                    No Data Available
                                </TableCell>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            {filterData?.length > 0 ? <CustomPagination tableData={filterData} totalRows={totalRows} rowsPerPage={rowsPerPage} page={page} handlePage={(pageNo) => setPage(pageNo)} /> : null}
        </section>
    );
}

export default ManageRole;
