import * as Yup from "yup";

export const PatientsAssignedHeadCells = [
    {
        id: 'name',
        label: 'Name',
        type: 'text',
        isDecrypted: true,
        nested: { key: "name", value: ({ index: 0, key: "text" }) },
    },
    {
        id: 'phone',
        label: 'Phone Number',
        type: 'text',
        isDecrypted: true,
        nested: { key: "telecom", value: { index: 0, key: "value" } },
    },
    {
        id: 'address',
        label: 'Address',
        type: 'text',
        isDecrypted: true,
        nested: { key: "address", value: { index: 0, key: "line", } },
    },
    {
        id: 'organization',
        label: 'Organization',
        type: 'text',
        style: { textAlign: "center" },
        nested: { key: "managingOrganization", value: { index: 0, key: "display" } },
    },
    {
        id: 'actions',
        label: 'Actions',
        type: 'text',
        style: { textAlign: "center" },
    },
];

export const PatientsAssignedDataFields = [
    'name',
    'phone',
    'Address',
    'organization',
    "actions"
];
export const ageGroupOptions = [
    "25-30",
    "30-35",
    "35-40",
    "40-45",
    "45-50",
    "50-55",
    "60-65",
    "65-70",
    "70-75",
    "75-80",
    "80-85",
    "85-90",
    "90-95",
    "95-100",
];
export const FormikObj = {
    initialValues: {
        groupName: "",
        age: [],
        bp: false,
        hr: false,
        oxygen: false,
        weight: false,
        bloodglucose: false,
        temperature: false,
        bpDiastolicbelowM: "",
        bpDiastolicAboveM: "",
        bpSystolicbelowM: "",
        bpSystolicAboveM: "",
        bpDiastolicbelowH: "",
        bpDiastolicAboveH: "",
        bpSystolicbelowH: "",
        bpSystolicAboveH: "",
        belowWeightM: "",
        aboveWeightM: "",
        belowWeightH: "",
        aboveWeightH: "",
        belowhrM: "",
        abovehrM: "",
        belowhrH: "",
        abovehrH: "",
        belowOxygenM: "",
        aboveOxygenM: "",
        belowOxygenH: "",
        aboveOxygenH: "",
        belowBloodglucoseM: "",
        aboveBloodglucoseM: "",
        belowBloodglucoseH: "",
        aboveBloodglucoseH: "",
        belowTempM: "",
        aboveTempM: "",
        belowTempH: "",
        aboveTempH: "",
    },
    validationSchema: Yup.object({
        groupName: Yup.string().required("Required group name field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        age: Yup.array(),
        bp: Yup.boolean(),
        hr: Yup.boolean(),
        oxygen: Yup.boolean(),
        weight: Yup.boolean(),
        bloodglucose: Yup.boolean(),
        temperature: Yup.boolean(),
        bpDiastolicbelowM: Yup.string().when('bp', (bp, schema) => bp ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        bpDiastolicAboveM: Yup.string().when('bp', (bp, schema) => bp ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        bpSystolicbelowM: Yup.string().when('bp', (bp, schema) => bp ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        bpSystolicAboveM: Yup.string().when('bp', (bp, schema) => bp ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        bpDiastolicbelowH: Yup.string().when('bp', (bp, schema) => bp ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        bpDiastolicAboveH: Yup.string().when('bp', (bp, schema) => bp ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        bpSystolicbelowH: Yup.string().when('bp', (bp, schema) => bp ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        bpSystolicAboveH: Yup.string().when('bp', (bp, schema) => bp ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        belowWeightM: Yup.string().when('weight', (weight, schema) => weight ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        aboveWeightM: Yup.string().when('weight', (weight, schema) => weight ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        belowWeightH: Yup.string().when('weight', (weight, schema) => weight ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        aboveWeightH: Yup.string().when('weight', (weight, schema) => weight ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        belowhrM: Yup.string().when('hr', (hr, schema) => hr ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        abovehrM: Yup.string().when('hr', (hr, schema) => hr ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        belowhrH: Yup.string().when('hr', (hr, schema) => hr ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        abovehrH: Yup.string().when('hr', (hr, schema) => hr ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        belowOxygenM: Yup.string().when('oxygen', (oxygen, schema) => oxygen ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        aboveOxygenM: Yup.string().when('oxygen', (oxygen, schema) => oxygen ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        belowOxygenH: Yup.string().when('oxygen', (oxygen, schema) => oxygen ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        aboveOxygenH: Yup.string().when('oxygen', (oxygen, schema) => oxygen ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        belowBloodglucoseM: Yup.string().when('bloodglucose', (bloodglucose, schema) => bloodglucose ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        aboveBloodglucoseM: Yup.string().when('bloodglucose', (bloodglucose, schema) => bloodglucose ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        belowBloodglucoseH: Yup.string().when('bloodglucose', (bloodglucose, schema) => bloodglucose ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        aboveBloodglucoseH: Yup.string().when('bloodglucose', (bloodglucose, schema) => bloodglucose ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        belowTempM: Yup.string().when('temperature', (temperature, schema) => temperature ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        aboveTempM: Yup.string().when('temperature', (temperature, schema) => temperature ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        belowTempH: Yup.string().when('temperature', (temperature, schema) => temperature ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
        aboveTempH: Yup.string().when('temperature', (temperature, schema) => temperature ? Yup.number().typeError('you must specify a number').required('Required Field') : schema),
    })
}

export const GetVitalsUnit = ({ orgVitalsUnit, conditionKey }) => {
    switch (conditionKey) {
        case "blood_glucose":
            return orgVitalsUnit?.blood_glucose?.glucose
        case "blood_pressure":
            return orgVitalsUnit?.blood_pressure?.systolic
        case "heart_rate":
            return orgVitalsUnit?.heart_rate?.heartRate
        case "oxygen":
            return orgVitalsUnit?.oxygen?.oxygen
        case "temperature":
            return orgVitalsUnit?.temperature?.temperature
        case "weight":
            return orgVitalsUnit?.weight?.weight
        default:
            break;
    }
}