import * as Yup from "yup";

export const OrganizationHeadCells = [
    {
        id: 'patientName',
        label: 'Patient Name',
        type: 'text',
        nested: { key: "patientName" },
        sort: true,
    },
    {
        id: 'updatedAt',
        label: 'Date & Time',
        type: 'dateTimeAmPm',
        //  nested: { key: "address", value: { index: 0, key: "line" } },
        nested: { key: "updatedAt" },
        sort: true,
    },
    // {
    //     id: 'status',
    //     label: 'Status',
    //     type: 'text',
    //     //  nested: { key: "address", value: { index: 0, key: "state" } },
    //     nested: { key: "status" },
    // },
    {
        id: 'actions',
        label: 'Actions',
        type: 'text',
        style: { textAlign: "center" },
    },
];
export const OrganizationDataFields = [
    'patientName',
    'updatedAt',
   // 'status',
    "actions"
];

export const SurveyFormikObj = {
    initialValues: {
        requestType: "Questionnaire",
        title: "",
        keyString: "",
        purpose: "",
        type: "Assessment",
        description: '',
        purpose: "survey-appointment",
        isDefault: true,
        question: [{
            id: Date.now(),
            text: "",
            type: "stars",
            highRateText: "",
            lowRateText: "",
            required: false,
        }]
    },
    validationSchema: Yup.object().shape({
        title: Yup.string().max(250, "Must be 250 characters or less").required('Please fill the Title field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        description: Yup.string().max(250, "Must be 250 characters or less").required('Please fill the Thank You Message field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        //  purpose: Yup.string().required("Required field"),
        question: Yup.array().of(
            Yup.object().shape({
                text: Yup.string().max(250, "Must be 250 characters or less").required('Please fill the Survey Question field'),
                type: Yup.string().required('Please select the Survey Type field'),
                highRateText: Yup.string().max(50, "Must be 50 characters or less"),
                lowRateText: Yup.string().max(50, "Must be 50 characters or less"),
                required: Yup.boolean(),
                // Add more validations for other item properties if needed
            })
        ),
    }),
}


export const EditSurveyFormikObj = {
    initialValues: {
        requestType: "Questionnaire",
        title: "",
        keyString: "",
        purpose: "survey-appointment",
        type: "Assessment",
        description: '',
        isDefault: true,
        question: [{
            id: Date.now(),
            text: "",
            type: "stars",
            highRatingText: "",
            lowRatingText: "",
            required: false,
        }]
    },
    validationSchema: Yup.object().shape({
        title: Yup.string().max(250, "Must be 250 characters or less").required('Please fill the Title field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        description: Yup.string().max(250, "Must be 250 characters or less").required('Please fill the Thank You Message field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        //  purpose: Yup.string().required("Required field"),
        question: Yup.array().of(
            Yup.object().shape({
                text: Yup.string().max(250, "Must be 250 characters or less").required('Please fill the Survey Question field'),
                type: Yup.string().required('Please select the Survey Type field'),
                //  highRatingText: Yup.string().max(50, "Must be 50 characters or less"),
                //   lowRatingText: Yup.string().max(50, "Must be 50 characters or less"),
                required: Yup.boolean(),
                // Add more validations for other item properties if needed
            })
        ),
    }),
}

export const purposeOption = [
    { value: "survey-appointment", name: "Survey" },
    // { value: "survey-appointment", name: "Get Care - Appointment" },
    // { value: "survey-waitingroom", name: "Get Care - Waiting Room" },
]



export const answerTypeOptions = [
    { value: "stars", name: "Stars" },
    { value: "freeText", name: "Free Text" },
]
export const SurveyMessageHeadCells = [
    {
        id: 'title',
        label: 'Survey Message',
        type: 'text',
        nested: { key: "title" },
    },
];
export const SurveyMessageDataFields = [
    'title'
];
export const SurveyQuestionHeadCells = [
    {
        id: 'text',
        label: 'Survey Question',
        type: 'text',
        // nested: { key: "item" },
        nested: { key: "text", },
        style: { width: "100%" },
    },
    {
        id: 'type',
        label: 'Answer Type',
        type: 'text',
        // nested: { key: "name" },
        style: { whiteSpace: "nowrap" },
        nested: { key: "type" },
    },
];


export const SurveyQuestionDataFields = [
    'text',
    // 'type' && 'type' == 'freeText' ? 'type' == 'Free Text' : 'type' == 'starts' ? 'type' == 'Stars' :'',
    'type'
];
export const SurveyThankYouHeadCells = [
    {
        id: 'description',
        label: 'Thank You Message',
        type: 'text',
        nested: { key: "description" },
    },
];
export const SurveyThankYouDataFields = [
    'description',
];

export const validateNotBlank = (value) => {
    if (!value || value.trim() === '') {
        return 'This field cannot be blank.';
    }
    return undefined; // Return undefined to indicate that the field is valid
};

export const surveyForOptionsOrganization = [
    { value: "survey-appointment", name: "Get Care - Appointment" },
    { value: "survey-waitingroom", name: "Get Care - Waiting Room" },
];

export const SurveyFormikAssign = {
    initialValues: {
        //  questionnaire: "",
        questionnaireAptField: "",
        questionnaireWroomField: "",
        type: "survey",
        purpose: "",
    },
    validationSchema: Yup.object({
        // questionnaire: Yup.string().required('Please select  field'),
        //  purpose: Yup.string().required('Please select field'),
    }),
}



