import React from 'react'
import { convertVitalsBaseValueToOrgLevelUnit } from '../../../Admin/Schedule/constants';
import { GetVitalsUnit } from '../Constants';

const ConditionData = ({ keyName, data, orgVitalsUnit }) => {
    switch (keyName) {
        case "blood_pressure":
            return data?.condition?.[keyName].map((item, index) => {
                return (
                    <span key={index}>
                        Diastolic {item?.diastolic?.type.charAt(0).toUpperCase() + item?.diastolic?.type.slice(1)} |{" "}
                        {convertVitalsBaseValueToOrgLevelUnit(item?.diastolic?.above, GetVitalsUnit({ orgVitalsUnit, conditionKey: keyName }))}{" "}-{" "}
                        {convertVitalsBaseValueToOrgLevelUnit(item?.diastolic?.below, GetVitalsUnit({ orgVitalsUnit, conditionKey: keyName }))}{" "}
                        <br /> Systolic {item?.systolic?.type.charAt(0).toUpperCase() + item?.systolic?.type.slice(1)} |{" "}
                        {convertVitalsBaseValueToOrgLevelUnit(item?.systolic?.above, GetVitalsUnit({ orgVitalsUnit, conditionKey: keyName }))}{" "}-{" "}
                        {convertVitalsBaseValueToOrgLevelUnit(item?.systolic?.below, GetVitalsUnit({ orgVitalsUnit, conditionKey: keyName }))}{" "}
                        <br />
                    </span>
                );
            })
        case "oxygen":
            return data?.condition?.[keyName].map((item, index) => {
                return (
                    <span key={index}>
                        Oxygen {item?.oxygen?.type.charAt(0).toUpperCase() + item?.oxygen?.type.slice(1)} |{" "}
                        {convertVitalsBaseValueToOrgLevelUnit(item?.oxygen?.above, GetVitalsUnit({ orgVitalsUnit, conditionKey: keyName }))}{" "}-{" "}
                        {convertVitalsBaseValueToOrgLevelUnit(item?.oxygen?.below, GetVitalsUnit({ orgVitalsUnit, conditionKey: keyName }))}{" "}
                        <br />
                    </span>
                );
            })
        case "heart_rate":
            return data?.condition?.[keyName].map((item, index) => {
                return (
                    <span key={index}>
                        Heart Rate {item?.heartRate?.type.charAt(0).toUpperCase() + item?.heartRate?.type.slice(1)} |{" "}
                        {convertVitalsBaseValueToOrgLevelUnit(item?.heartRate?.above, GetVitalsUnit({ orgVitalsUnit, conditionKey: keyName }))}{" "}-{" "}
                        {convertVitalsBaseValueToOrgLevelUnit(item?.heartRate?.below, GetVitalsUnit({ orgVitalsUnit, conditionKey: keyName }))}{" "}
                        <br />
                    </span>
                );
            })
        default:
            return data?.condition?.[keyName].map((item, index) => {
                return (
                    <span key={index}>
                        {item?.type.charAt(0).toUpperCase() + item?.type.slice(1)} |{" "}
                        {convertVitalsBaseValueToOrgLevelUnit(item.above, GetVitalsUnit({ orgVitalsUnit, conditionKey: keyName }))}{" "}-{" "}
                        {convertVitalsBaseValueToOrgLevelUnit(item.below, GetVitalsUnit({ orgVitalsUnit, conditionKey: keyName }))}<br />
                    </span>
                );
            })
    }
}

export default ConditionData