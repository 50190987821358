import * as Yup from "yup";

export const HeadCells = [
    {
        id: 'patientName',
        label: 'Patient Name',
        type: 'text',
        nested: { key: "patientName", value: { index: 0, key: "text" } },
        isDecrypted: true,
    },
    {
        id: 'location',
        label: 'Location',
        type: 'text',
        nested: { key: "location", value: { key: "display" } },
    },
    {
        id: 'priority',
        label: 'Priority',
        type: 'text',
        nested: { key: "priority" },
    },
    {
        id: 'registeredByName',
        label: 'Registered By',
        type: 'text',
        nested: { key: "registeredByName", value: { index: 0, key: "text" } },
        isDecrypted: true,
    },
    {
        id: 'actions',
        label: 'Actions',
        type: 'text',
        style: { textAlign: "center" },
    },
];
export const DataFields = [
    'patientName',
    'location',
    'priority',
    'registeredByName',
    "actions"
];

export const searchFormikObj = {
    initialValues: {
        email: "",
    },
    validationSchema: Yup.object({
        email: Yup.string().email("Invalid email address").required("Required email field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
    }),
}
export const enrollFormikObj = {
    initialValues: {
        first_name: "",
        last_name: "",
        date_of_birth: "",
        phone: "",
        address: "",
        city: "",
        country: "",
        region: "",
        email: "",
        gender: "",
        postalCode: "",
        priority: "",
        note: "",
        location: "",
    },
    validationSchema: Yup.object({
        first_name: Yup.string(),
        last_name: Yup.string(),
        date_of_birth: Yup.string(),
        phone: Yup.string(),
        address: Yup.string(),
        city: Yup.string(),
        email: Yup.string().email("Invalid email address").required("Required email field").matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
        gender: Yup.string(),
        country: Yup.string(),
        region: Yup.string(),
        postalCode: Yup.string(),
        priority: Yup.string().required("Required priority field"),
        note: Yup.string().required("Required priority field").matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
        location: Yup.string().required("Required location field"),
    }),
}

export const priorityOptions = [
    { id: 4, value: 4, name: "Low" },
    { id: 3, value: 3, name: "Normal" },
    { id: 2, value: 2, name: "Quick" },
    { id: 1, value: 1, name: "Very Quick" },
]

export const editFormikObj = {
    initialValues: {
        priority: "",
        note: "",
        location: "",
    },
    validationSchema: Yup.object({
        priority: Yup.string().required("Required priority field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        note: Yup.string().required("Required note field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        location: Yup.string().required("Required location field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
    }),
}