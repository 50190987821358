import { axios } from "../../../lib/axios";

export const setupAuthAppMFA = (data) => {
    return axios.post(`auth/setup-mfa-code-getToken`, { username: data?.username, password: data?.password });
}

export const verifyAuthAppMFA = (data) => {
    return axios.post(`auth/verify-mfa-device`, { accessToken: data?.accessToken, userCode: data?.mfaCode })
}

export const respondToAuthAppMFA = (data) => {
    return axios.post(`auth/respond-mfa`, { username: data?.username, mfaCode: data?.userResponseCode, session: data?.session, challengeName: data?.challengeName })
}

export const loginWithMFAAuthApp = (data) => {
    return axios.post(`auth/admin-login-mfa`, { mfaVerificationResponse: data?.mfaVerificationResponse, username: data?.username })
}

export const getMFASettings = (data) => {
    return axios.post(`auth/get-mfa-options`, { username: data?.username })
}

export const removeMFAAuthAppSettings = (data) => {
    return axios.post(`auth/remove-mfa`, { accessToken: data?.accessToken, challengeType: data?.challengeType })
}

export const enableSMSMfa = (data) => {
    return axios.post(`auth/enable-sms-mfa`, { username: data?.username, password: data?.password, phoneNumber: data?.phoneNumber })
}