import React, { useCallback, useEffect, useState } from "react";
import { Dropdown, Table } from "react-bootstrap";
import ScheduleBtnIcon from "../../../assets/images/event.png";
import { intialVitalOptions } from "./Constants";
import CustomPagination from "../../common/components/Pagination/CustomPagination";
import { decryptData } from "../../EncryptDecrypt";
import CreateThresholdModal from "./Component/createThresholdModel";
import { usePatientReadingQuery } from "../../../hooks/ReactQueryHooks/usePatientReadingQuery";
import moment from "moment";
import { TableCell} from "@mui/material";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { deleteThresholdPatient } from "../api/ThresholdGroup";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import { failed, success } from "../../common/Toastify";
import { getPatientLatestReadingDate } from "../api/Patient";
import { EncounterHistoryList } from "./Component/EncounterHistoryList";
import {ServiceRequestList } from "./Component/ServiceRequestList";
import DownArrow from '../../../assets/images/down-arrow.png';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import medicalIcon from '../../../assets/images/medical-prescription.png';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import SickOutlinedIcon from '@mui/icons-material/SickOutlined';
import VaccinesOutlinedIcon from '@mui/icons-material/VaccinesOutlined';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import DataThresholdingOutlinedIcon from '@mui/icons-material/DataThresholdingOutlined';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { MedicationRequestList } from "./Component/MedicationRequestList";
import { getSingleUser } from "../../api/Individual";
import { MedicalHistory } from "./Component/MedicalHistory";
import { getOrganization } from "../../api/Organization";
import { RpmTable } from "./Component/RPM";
import { Button } from "../../common/Button";
import { DocumentsList } from "./Component/DocumentsList";

const DataTables = (props) => {
    const navigate = useNavigate()
    const [activeKey, setActiveKey] = useState("encounters");
    const permissions = useSelector((state) => state?.auth?.user?.permissions);
    const { getConfirmation } = useConfirmDialog();
    const [vitalsColumn, setVitalsColumn] = useState(intialVitalOptions);
    const [createServicePermision, setcreateServicePermision] = useState(true)
    //const [MedMonthYear, setMedMonthYear] = useState(moment());
    //const [MedSelectedMonth, setMedSelectedMonth] = useState([]);
    const [PatientReadingData, setPatientReadingData] = useState({});
    const [PatientReadingDate, setPatientReadingDate] = useState(moment().format("MM/DD/YYYY"));
    //const [ReadingMonthYear, setReadingMonthYear] = useState(moment());
    //const [ReadingSelectedMonth, setReadingSelectedMonth] = useState([]);
    const [deleting, setDeleting] = useState(false);
    const [requestType,setRequestType] = useState("")
    const [orgnization, setOrganizations] = useState({})
    const [medicalHistory,setMedicalHistory] = useState("conditions")
    const [rpm, setRpm] = useState("vitals")
    const [practionerData, setPractionerData] = useState({})
    const [enableMedicalHistoryButton, setMedicalHistoryButton] = useState(false)
    const requestTypeId = localStorage.getItem("requestTypeId")
    const medicalHistoryType = localStorage.getItem("medicalHistory")
    const rpmType = localStorage.getItem("rpm");
    const user = useSelector((state) => state?.auth?.user);
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)
    //React Query
    const onSuccessPatient = (data) => {
        setPatientReadingData(data)
    }
    
    const { isLoading: isLoadingPatient, isFetching: isFetchingPatient, refetch: refetchPatient } = usePatientReadingQuery({ onSuccess: onSuccessPatient, id: props.patientId, date: PatientReadingDate });

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const handleOption = (vitalOption) => {
        setVitalsColumn({ ...vitalsColumn, [vitalOption]: !vitalsColumn?.[vitalOption] })
    };

    const [page, setPage] = useState(0);
    const rowsPerPage = 5;


    useEffect(() => {
        //props.refreshMed.current = refetch;
        props.refreshReading.current = refetchPatient;
        getPatientLatestReadingDate(props?.patientId)
            .then((response) => {
                //handleMonthYearChange(moment(response?.data?.[0]?.date));
                //handleMonthYearChangeP(moment(response?.data?.[0]?.date))
                setPatientReadingDate(response?.data?.[0]?.effectiveDateTime);
            })
            .catch((error) => { console.log(error); })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const deleteThreshold = async (condition, entryName) => {
        const confirmed = await getConfirmation({
            title: "Attention!",
            entryName,
            actionBtnTitle: "Delete",
        });
        if (confirmed) {
            setDeleting(condition);
            deleteThresholdPatient(props.patientId, condition)
                .then((res) => {
                    props.updateThresholddata();
                    success("Threshold Deleted!");
                })
                .catch((err) => failed(err?.response?.data?.message || err.message))
                .finally(() => setDeleting(false));
        }
    }

    useEffect(()=>{
      if(permissions.some(permission => permission === 'View Lab Requisitions' ||  permission === 'Create Lab Requisitions')) {
        setRequestType("15220000") // set lab default
        if(!permissions.includes("Create Lab Requisitions")) setcreateServicePermision(false)
      } 
      else if((!(permissions.some(permission => permission === 'View Lab Requisitions' ||  permission === 'Create Lab Requisitions')) && (permissions.some(permission => permission === 'Create Diagnostic Imaging Requests' ||  permission === 'View Diagnostic Imaging Requests' )))) {
        setRequestType("103693007") // set diagnostic deafult
        if(!permissions.includes("Create Diagnostic Imaging Requests")) setcreateServicePermision(false)
      }
      else if(!permissions.some(permission => permission === 'View Diagnostic Imaging Requests' || permission === 'View Lab Requisitions' ||  permission === 'Create Lab Requisitions' || permission === 'Create Diagnostic Imaging Requests')) {
        setRequestType("306206005") // set referral as default
        if(!permissions.includes("Create Service Referrals")) setcreateServicePermision(false)
      }
    },[])
    
    useEffect(() => {
      const apiBody = {
        id: user?.["custom:unique_id"],
        type: "Practitioner",
      };
      getSingleUser(apiBody)
        .then((res) => {
          setPractionerData(res?.data)
        })
        .catch((res) => {
          console.log("error in image upload",res)
        })
    }, []);

    const handleRequestType = useCallback((e)=> {
      setRequestType(e.target.id)
      if(e.target.id == "15220000"){
        if(!permissions.includes("Create Lab Requisitions")) setcreateServicePermision(false)
        else setcreateServicePermision(true)
      }
      else if(e.target.id == "103693007"){
        if(!permissions.includes("Create Diagnostic Imaging Requests")) setcreateServicePermision(false)
        else setcreateServicePermision(true)
      }
      else{
        if(!permissions.includes("Create Service Referrals")) setcreateServicePermision(false)
        else setcreateServicePermision(true)
      }
    },[]) 
    
    const handleMedicalHistory=(e)=>{
      setMedicalHistory(e.target.name)
    }

    

    useEffect(() => {
      getOrganization(adminOrgs?.[0]?.id)
          .then((res) => {
              setOrganizations(res.data);
          })
          .catch((res) => {
              failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
          });
    }, []);

    useEffect(() => {
      return ()=> localStorage.removeItem("activeTableKey")
    })
    
    const handleRpm = (e) => {
        setRpm(e.target.name)
    }
    
    return (
      <div className="tabs-btn-wrap tabs-btn-wrap-blc">
        <div className="tab-wrap">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={localStorage.getItem("activeTableKey") || "fifth"}
            onSelect={() => {props.getpatientList(1)}}
          >
            <div className="tab-nav-blc d-flex align-items-center m-gap" style={{justifyContent:"space-between"}}>
              <Nav variant="pills" style={{  }}>

                <Nav.Item>
                    <Nav.Link onClick = {() => setActiveKey("encounters")} eventKey="fifth">Encounters</Nav.Link>
                </Nav.Item>
                {props?.isVirtualCare && permissions.some(permission => permission === 'Create Prescriptions' ||  permission === 'View Prescriptions') && <Nav.Item>
                    <Nav.Link onClick = {() => setActiveKey("prescriptions")} eventKey="eighth">Prescriptions</Nav.Link>
                </Nav.Item>}
                {props?.isVirtualCare && (
                    <Dropdown className="user-dropdown">
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="user-toggle"
                      >
                        <Nav.Item>
                          <Nav.Link eventKey="ninth" onClick={()=> {setMedicalHistoryButton(true); setActiveKey("medicalHistory")}}>
                            Medical History
                            <img
                              src={DownArrow}
                              alt=""
                              style={{ marginLeft: "7px" }}
                            />
                          </Nav.Link>
                        </Nav.Item>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                          <Dropdown.Item
                            name="conditions"
                            onClick={handleMedicalHistory}
                            style={
                              medicalHistoryType == "conditions"
                                ? { color: "#005da8" }
                                : null
                            }
                          >
                            Conditions
                          </Dropdown.Item>
                          <Dropdown.Item
                            name="surgeries/screening"
                            onClick={handleMedicalHistory}
                            style={
                              medicalHistoryType == "surgeries/screening"
                                ? { color: "#005da8" }
                                : null
                            }
                          >
                            Surgeries/Screening
                          </Dropdown.Item>
                          <Dropdown.Item
                            name="familyHistory"
                            onClick={handleMedicalHistory}
                            style={
                              medicalHistoryType == "familyHistory"
                                ? { color: "#005da8" }
                                : null
                            }
                          >
                            Family History
                          </Dropdown.Item>
                          <Dropdown.Item
                            name="observations"
                            onClick={handleMedicalHistory}
                            style={
                              medicalHistoryType == "observations"
                                ? { color: "#005da8" }
                                : null
                            }
                          >
                            Observations
                          </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}

                {props?.isVirtualCare &&
                  permissions.some(
                    (permission) =>
                      permission === "View Service Referrals" ||
                      permission === "View Diagnostic Imaging Requests" ||
                      permission === "View Lab Requisitions" ||
                      permission === "Create Lab Requisitions" ||
                      permission === "Create Service Referrals" ||
                      permission === "Create Diagnostic Imaging Requests"
                  ) && (
                    <Dropdown className="user-dropdown">
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="user-toggle"
                      >
                        <Nav.Item>
                          <Nav.Link onClick = {() => setActiveKey("serviceRequest")} eventKey="seventh">
                            Service Request
                            
                            <img
                              src={DownArrow}
                              alt=""
                              style={{ marginLeft: "7px" }}
                            />
                          </Nav.Link>
                        </Nav.Item>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {(permissions.includes("View Lab Requisitions") ||
                          permissions.includes("Create Lab Requisitions")) && (
                          <Dropdown.Item
                            id="15220000"
                            onClick={handleRequestType}
                            style={
                              requestTypeId == "15220000"
                                ? { color: "#005da8" }
                                : null
                            }
                          >
                            Lab
                          </Dropdown.Item>
                        )}
                        {(permissions.includes(
                          "View Diagnostic Imaging Requests"
                        ) ||
                          permissions.includes(
                            "Create Diagnostic Imaging Requests"
                          )) && (
                          <Dropdown.Item
                            id="103693007"
                            onClick={handleRequestType}
                            style={
                              requestTypeId == "103693007"
                                ? { color: "#005da8" }
                                : null
                            }
                          >
                            Diagnostic
                          </Dropdown.Item>
                        )}
                        {(permissions.includes("View Service Referrals") ||
                          permissions.includes("Create Service Referrals")) && (
                          <Dropdown.Item
                            id="306206005"
                            onClick={handleRequestType}
                            style={
                              requestTypeId == "306206005"
                                ? { color: "#005da8" }
                                : null
                            }
                          >
                            Referral
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                {props?.isRPMEnable && (
                  <>
                    <Dropdown className="user-dropdown">
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="user-toggle"
                      >
                        <Nav.Item>
                          <Nav.Link onClick = {() => setActiveKey("rpm")} eventKey="tenth">
                            RPM
                            
                            <img
                              src={DownArrow}
                              alt=""
                              style={{ marginLeft: "7px" }}
                            />
                          </Nav.Link>
                        </Nav.Item>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                          <Dropdown.Item
                            name="vitals"
                            onClick={handleRpm}
                            style={
                              rpm== "vitals"
                                ? { color: "#005da8" }
                                : null
                            }
                          >
                            Vitals
                          </Dropdown.Item>
                          <Dropdown.Item
                            name="medications"
                            onClick={handleRpm}
                            style={
                                rpm == "medications"
                                ? { color: "#005da8" }
                                : null
                            }
                          >
                            Medications
                          </Dropdown.Item>
                        
                          <Dropdown.Item
                            name="thresholds"
                            onClick={handleRpm}
                            style={
                                rpm == "thresholds"
                                ? { color: "#005da8" }
                                : null
                            }
                          >
                            Thresholds
                          </Dropdown.Item>
                          <Dropdown.Item
                            name="healthConditions"
                            onClick={handleRpm}
                            style={
                                rpm == "healthConditions"
                                ? { color: "#005da8" }
                                : null
                            }
                          >
                            Health Conditions
                          </Dropdown.Item>
                        
                      </Dropdown.Menu>
                    </Dropdown>

                  </>
                )}
                {props?.isVirtualCare && (
                  <Nav.Item>
                    <Nav.Link onClick = {() => setActiveKey("documents")} eventKey="eleventh">Documents</Nav.Link>
                  </Nav.Item>  
                )}
              </Nav>
              {/* {!props.isDoctor && ( */}
                <div className="serviceButtons" style={{display: "flex", justifyContent:"flex-end"}}>
                  {props?.isVirtualCare && activeKey === "serviceRequest" && !["Create Lab Requisitions", "Create Service Referrals", "Create Diagnostic Imaging Requests"].every((permission) => !permissions.includes(permission)) &&
                    createServicePermision && (
                      <button
                        title="Create Service Request"
                        className="custom-btn"
                        onClick={() => orgnization?.sft?.enabled && (orgnization?.sft?.individualDelivery || orgnization?.sft?.locationDelivery) ? navigate(`/app/service-request?practitionerName=${user?.name?.[0].text}&patientName=${props?.patientName}&patientId=${props.patientId}&fromPatient=true`) : (orgnization?.sft?.enabled && (!orgnization?.sft?.individualDelivery && !orgnization?.sft?.locationDelivery)) ? failed("Please enable SFT delivery options.") : failed("Please enable SFT to send document.")}
                      >
                        Create Service Request 
                      </button>
                    )}
                  {props?.isVirtualCare && activeKey === "prescriptions"  && permissions.includes("Create Prescriptions") && (
                    // <Button className="custom-btn"
                    //  title="Create Prescription" variant="primary"
                    //  style={{ padding: "0", }}
                    //  onClick={() =>  orgnization?.sft?.enabled && (orgnization?.sft?.individualDelivery || orgnization?.sft?.locationDelivery) ? navigate(`/app/medication-request?practitionerName=${user?.name?.[0].text}&patientName=${props?.patientName}&patientId=${props.patientId}&fromPatient=true`) : (orgnization?.sft?.enabled && (!orgnization?.sft?.individualDelivery && !orgnization?.sft?.locationDelivery)) ? failed("Please enable SFT delivery options.") : failed("Please enable SFT to send document.")}>
                    //  <img src={medicalIcon} alt="Prev Arrow" />
                    // </Button>
                    <button
                      title="Create Prescription"
                      className="custom-btn white-space"
                      onClick={() =>  orgnization?.sft?.enabled && (orgnization?.sft?.individualDelivery || orgnization?.sft?.locationDelivery) ? navigate(`/app/medication-request?practitionerName=${user?.name?.[0].text}&patientName=${props?.patientName}&patientId=${props.patientId}&fromPatient=true`) : (orgnization?.sft?.enabled && (!orgnization?.sft?.individualDelivery && !orgnization?.sft?.locationDelivery)) ? failed("Please enable SFT delivery options.") : failed("Please enable SFT to send document.")}
                    >
                     <img src={medicalIcon} alt="Prev Arrow" /> Create Prescription
                    </button>
                    
                  )}
                  {enableMedicalHistoryButton && activeKey == "medicalHistory" && props?.isVirtualCare && (
                    <button
                      className="custom-btn"
                      onClick={() => medicalHistory === "conditions" ? navigate("/app/create-condition", {state:{medicalHistory: "Conditions", patientData:{patientId:props?.patientId, patientName: props?.patientName}, practionerData}}) : medicalHistory === "surgeries/screening" ? navigate("/app/surgeries-screening",{state:{medicalHistory: "Surgeries/Screening", patientData:{patientId:props?.patientId, patientName: props?.patientName}, practionerData}}) : medicalHistory === "familyHistory" ? navigate("/app/create-familyHistory", {state:{medicalHistory: "familyHistory", patientData:{patientId:props?.patientId, patientName: props?.patientName, patientEmail: props?.patientEmail}, practionerData}}) : medicalHistory === "observations" ? navigate("/app/create-observation", {state:{medicalHistory: "observations", patientData:{patientId:props?.patientId, patientName: props?.patientName}, practionerData}}) : ""}
                    >
                      {medicalHistory === "conditions" ? <MedicalServicesOutlinedIcon style={{paddingBottom:"4px"}}/>  : medicalHistory === "surgeries/screening" ? <VaccinesOutlinedIcon style={{paddingBottom:"4px"}}/> : medicalHistory === "familyHistory" ? <PeopleAltOutlinedIcon style={{paddingBottom:"4px"}}/> : medicalHistory === "observations" ? <LightbulbOutlinedIcon style={{paddingBottom:"4px"}}/> : ""}
                     {medicalHistory === "conditions" ? " Add Condition" : medicalHistory === "surgeries/screening" ? " Add Surgeries/Screening" : medicalHistory === "familyHistory" ? " Add Family History" : medicalHistory === "observations" ? " Add Observation" : ""}
                    </button>
                  )}
                  {props?.isRPMEnable &&  activeKey == "rpm" ? (
                    <>
                      {rpm == "healthConditions" && <button
                        onClick={() =>
                          props.setDiseaseModal(!props.diseaseModal)
                        }
                        title="Add Disease"
                        className="custom-btn"
                      >
                        <SickOutlinedIcon style={{paddingBottom:"4px"}}/>
                         Add Disease
                      </button>}
                      {rpm == "medications" && <button
                        onClick={() => props.setMedModal(!props.medModal)}
                        title="Create Medication Schedule"
                        className="custom-btn white-space"
                      >
                        <img src={ScheduleBtnIcon} alt="Notification" /> Create
                        Medication Schedule
                      </button>}
                      {rpm == "thresholds" &&<button
                        onClick={() => props.setModalShow(true)}
                        title="Set Custom Threshold"
                        className="custom-btn"
                      >
                        <DataThresholdingOutlinedIcon style={{paddingBottom:"4px"}}/> Set Custom Threshold
                      </button>}
                    </>
                  ) : null}
                  {props.modalShow ? (
                    <CreateThresholdModal
                      show={props.modalShow}
                      onHide={() => props.setModalShow(false)}
                      patientId={props.patientId}
                      orgVitalsUnit={props?.orgVitalsUnit}
                      onUpdateThreshold={() => {
                        props.updateThresholddata();
                      }}
                    />
                  ) : null}
                </div>
              {/* )} */}
            </div>
            <Tab.Content>
              <Tab.Pane eventKey="fourth">
                <div className="table-wrap">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Relationship</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props?.relatedPerson?.length > 0 ? (
                        props?.relatedPerson
                          ?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          ?.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  {decryptData(item?.name?.[0]?.given?.[0])}{" "}
                                  {decryptData(item.name?.[0].family)}
                                </td>
                                <td>
                                  {decryptData(item?.telecom?.[1]?.value)}
                                </td>
                                <td>{decryptData(item?.relationship)}</td>
                                <td>{item?.active ? "Active" : "Disabled"}</td>
                              </tr>
                            );
                          })
                      ) : (
                        <tr>
                          <TableCell
                            colSpan="3"
                            style={{ textAlign: "center" }}
                          >
                            No Data Available
                          </TableCell>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {props?.relatedPerson?.length > 0 ? (
                    <div>
                      <CustomPagination
                        tableData={props.relatedPerson}
                        totalRows={props.relatedPerson.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handlePage={(pageNo) => setPage(pageNo)}
                      />
                    </div>
                  ) : null}
                </div>
              </Tab.Pane>

              <EncounterHistoryList patientId={props?.patientId} practionerData={practionerData} orgnization={orgnization}/>
              <ServiceRequestList
                patientId={props?.patientId}
                requestType={requestType}
                practionerData={practionerData}
                orgnization={orgnization}
              />
              <MedicationRequestList patientId={props?.patientId} practionerData={practionerData} orgnization={orgnization} />
              <MedicalHistory
                patientId={props?.patientId}
                medicalHistory={medicalHistory}
                practionerData={practionerData}
              />
              <RpmTable rpm={rpm} parentProps = {props}/>
              <DocumentsList patientId={props?.patientId}/>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    );
};

export default DataTables;