import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import TextInput from "../../common/textfield/TextInput";
import SelectFiled from "../../common/textfield/SelectFiled";
import { Button } from "../../common/Button";
import { failed, success } from "../../common/Toastify";
import { QuestionnaireFormikObjEdit, questionnaireForOptions } from "./Constant";
import { editQuestionnaire, deleteQuestionnaire } from "../../api/Questionnaire";
import { NavigateNext, ExpandMore } from "@mui/icons-material";
import { Skeleton, Tooltip } from "@mui/material";
import "./style.css"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { useGetQuestionsByCategoryQuery } from "../../../hooks/ReactQueryHooks/useGetQuestionsByCategoryQuery";
import moment from "moment";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import { useSelector } from "react-redux";
import DeleteIcon from "../../../assets/images/cross-icon.png";
import { ViewDate } from "../../../utils/DateSupport";

// edit-program dialog starts
const EditQuestionnaire = ({ modalShow, handleShow, refreshTable, isReadOnly }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [categorySelectedQuestions, setCategorySelectedQuestions] = useState([]);
  const [intialCategorySelectedQuestions, setIntialCategorySelectedQuestions] = useState([]);
  const [questionsCategoryList, setQuestionsCategoryList] = useState();
  const [err, setErr] = useState('');
  const { getConfirmation } = useConfirmDialog();
  const [deleting, setDeleting] = useState(false);
  const userType = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
  const hasAccess = userType?.includes("Super-Admin") ? true : !!!modalShow?.isDefault;
  const formik = useFormik({
    ...QuestionnaireFormikObjEdit,
    onSubmit: (values) => {
      const tempQuestionsArr = []
      let locationQuestions = 0;
      let reasonQuestions = 0;
      let questionTELEPHONE = 0;
      let questionCHANNEL = 0;
      let questionRELATIONSHIP = 0;
      let questionSELFCALLING = 0;
      let questionCONSENT = 0;
      let questionCALLER = 0;
      categorySelectedQuestions?.forEach((question) => {
        tempQuestionsArr?.push({
          text: question?.question,
          id: question?.linkId,
          required: !!question?.required,
          question: question?.question,
          answerType: question?.answerType,
          answerOption: question?.answerOption || question?.options,
          note: question?.note,
          tag: question?.tag || "",
        })
        // question.tag === "LOCATION" && locationQuestions++;
        // question.tag === "REASON" && reasonQuestions++;
        if (question.tag === "LOCATION") {
          locationQuestions += 1;
        }
        if (question.tag === "REASON") {
          reasonQuestions += 1;
        }

        if (question.tag === "TELEPHONE") {
          questionTELEPHONE += 1;
        }
        if (question.tag === "CHANNEL") {
          questionCHANNEL += 1;
        }
        if (question.tag === "RELATIONSHIP") {
          questionRELATIONSHIP += 1;
        }
        if (question.tag === "SELFCALLING") {
          questionSELFCALLING += 1;
        }
        if (question.tag === "CONSENT") {
          questionCONSENT += 1;
        }
        if (question.tag === "CALLER") {
          questionCALLER += 1;
        }

      })
      if (formik.values?.purpose.includes("getcare")) {
        if (!reasonQuestions && !locationQuestions && !questionTELEPHONE && !questionCHANNEL) {
          setErr("Must Select These Questions :- Patient Location, Reason for visit, Return Telephone Number and Preferred Channel");
          return;
        }

       //1
       if (reasonQuestions && !locationQuestions && !questionTELEPHONE && !questionCHANNEL) {
        // setErr("Must Select Both Questions Marked with Location and Reason");
        setErr("Must Select These Questions :- Patient Location, Return Telephone Number and Preferred Channel");
        return;
      }
      if (!reasonQuestions && locationQuestions && !questionTELEPHONE && !questionCHANNEL) {
        setErr("Must Select These Questions :- Reason for visit, Return Telephone Number and Preferred Channel");
        return;
      }
      if (!reasonQuestions && !locationQuestions && questionTELEPHONE && !questionCHANNEL) {
        setErr("Must Select These Questions :- Reason for visit,  Patient Location  and Preferred Channel");
        return;
      }
      if (!reasonQuestions && !locationQuestions && !questionTELEPHONE && questionCHANNEL) {
        setErr("Must Select These Questions :- Patient Location, Reason for visit and Return Telephone Number");
        return;
      }
      // 2 
      if ( reasonQuestions &&  locationQuestions && !questionTELEPHONE && !questionCHANNEL) {
        setErr("Must Select These Questions :- Return Telephone Number and Preferred Channel");
        return;
      }
      if ( reasonQuestions && !locationQuestions &&  questionTELEPHONE && !questionCHANNEL) {
        setErr("Must Select These Questions :-  Patient Location  and Preferred Channel");
        return;
      }
      if ( reasonQuestions && !locationQuestions && !questionTELEPHONE &&  questionCHANNEL) {
        setErr("Must Select These Questions :-  Patient Location and Return Telephone Number");
        return;
      }
      if (!reasonQuestions && locationQuestions && questionTELEPHONE && !questionCHANNEL) {
        setErr("Must Select These Questions :- Reason for visit and Preferred Channel");
        return;
      }
      if (!reasonQuestions &&  locationQuestions && !questionTELEPHONE &&  questionCHANNEL) {
        setErr("Must Select These Questions :- Reason for visit and Return Telephone Number");
        return;
      }
      if (!reasonQuestions && !locationQuestions &&  questionTELEPHONE &&  questionCHANNEL) {
        setErr("Must Select These Questions :- Reason for visit and Patient Location");
        return;
      }
     
      // 3
      if ( reasonQuestions &&  locationQuestions &&  questionTELEPHONE && !questionCHANNEL) {
        setErr("Must Select a Question :-  Preferred Channel");
        return;
      }
      if (reasonQuestions && locationQuestions && !questionTELEPHONE && questionCHANNEL) {
        setErr("Must Select a Question :-  Return Telephone Number");
        return;
      }
      if (reasonQuestions && !locationQuestions && questionTELEPHONE && questionCHANNEL) {
        setErr("Must Select a Question  :-  Patient Location");
        return;
      }
      if (!reasonQuestions && locationQuestions && questionTELEPHONE && questionCHANNEL) {
        setErr("Must Select a Questions :- Reason for visit");
        return;
      }

        if (!locationQuestions) {
          setErr("Must Select a Question :- Patient Location");
          return;
        } else if (locationQuestions > 1) {
          setErr("You Have Selected More Than One Question Marked With Patient Location");
          return;
        }
        if (!reasonQuestions) {
          setErr("Must Select a Question :-  Reason for visit");
          return;
        } else if (reasonQuestions > 1) {
          setErr("You Have Selected More Than One Question Marked With  Reason for visit");
          return;
        }
        if (!questionTELEPHONE) {
          setErr("Must Select a Question :- Return Telephone Number");
          return;
        } else if (questionTELEPHONE > 1) {
          setErr("You Have Selected More Than One Question Marked With Return Telephone Number");
          return;
        }
        if (!questionCHANNEL) {
          setErr("Must Select a Question :- Preferred Channel");
          return;
        } else if (questionCHANNEL > 1) {
          setErr("You Have Selected More Than One Question Marked With Preferred Channel");
          return;
        }
      }

      if (formik.values?.purpose.includes("Video Visit") && categorySelectedQuestions.length === 0) {
        setErr("Must Select a Question");
        return;
      }


      if (formik.values?.purpose.includes("telephone-intake")) {
        if (!reasonQuestions && !locationQuestions && !questionTELEPHONE && !questionRELATIONSHIP && !questionSELFCALLING && !questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Patient Location, Return Telephone Number, Relationship to patient, Calling for yourself, Patient Consent and Caller Name");
          return;
        }
        // 1
        if (reasonQuestions && !locationQuestions && !questionTELEPHONE && !questionRELATIONSHIP && !questionSELFCALLING && !questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-   Patient Location, Return Telephone Number, Relationship to patient, Calling for yourself, Patient Consent and Caller Name");
          return;
        }
        if (!reasonQuestions && locationQuestions && !questionTELEPHONE && !questionRELATIONSHIP && !questionSELFCALLING && !questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit,  Return Telephone Number, Relationship to patient, Calling for yourself, Patient Consent and Caller Name");
          return;
        }
        if (!reasonQuestions && !locationQuestions && questionTELEPHONE && !questionRELATIONSHIP && !questionSELFCALLING && !questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Patient Location, Relationship to patient, Calling for yourself, Patient Consent and Caller Name");
          return;
        }
        if (!reasonQuestions && !locationQuestions && !questionTELEPHONE && questionRELATIONSHIP && !questionSELFCALLING && !questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Patient Location, Return Telephone Number, Calling for yourself, Patient Consent and Caller Name");
          return;
        }
        if (!reasonQuestions && !locationQuestions && !questionTELEPHONE && !questionRELATIONSHIP && questionSELFCALLING && !questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Patient Location, Return Telephone Number, Relationship to patient, Patient Consent and Caller Name");
          return;
        }
        if (!reasonQuestions && !locationQuestions && !questionTELEPHONE && !questionRELATIONSHIP && !questionSELFCALLING && questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Patient Location, Return Telephone Number, Relationship to patient, Calling for yourself and Caller Name");
          return;
        }
        if (!reasonQuestions && !locationQuestions && !questionTELEPHONE && !questionRELATIONSHIP && !questionSELFCALLING && !questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Patient Location, Return Telephone Number, Relationship to patient, Calling for yourself and Patient Consent");
          return;
        }

        // 2

        if (reasonQuestions && locationQuestions && !questionTELEPHONE && !questionRELATIONSHIP && !questionSELFCALLING && !questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-   Return Telephone Number, Relationship to patient, Calling for yourself, Patient Consent and Caller Name");
          return;
        }
        if (reasonQuestions && !locationQuestions && questionTELEPHONE && !questionRELATIONSHIP && !questionSELFCALLING && !questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-   Patient Location,  Relationship to patient, Calling for yourself, Patient Consent and Caller Name");
          return;
        }
        if (reasonQuestions && !locationQuestions && !questionTELEPHONE && questionRELATIONSHIP && !questionSELFCALLING && !questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-   Patient Location, Return Telephone Number,  Calling for yourself, Patient Consent and Caller Name");
          return;
        }
        if (reasonQuestions && !locationQuestions && !questionTELEPHONE && !questionRELATIONSHIP && questionSELFCALLING && !questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-   Patient Location, Return Telephone Number, Relationship to patient,  Patient Consent and Caller Name");
          return;
        }
        if (reasonQuestions && !locationQuestions && !questionTELEPHONE && !questionRELATIONSHIP && !questionSELFCALLING && questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-   Patient Location, Return Telephone Number, Relationship to patient, Calling for yourself and Caller Name");
          return;
        }
        if (reasonQuestions && !locationQuestions && !questionTELEPHONE && !questionRELATIONSHIP && !questionSELFCALLING && !questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :-  Patient Location, Return Telephone Number, Relationship to patient, Calling for yourself and Patient Consent");
          return;
        }
        // 2.2
        if (!reasonQuestions && locationQuestions && questionTELEPHONE && !questionRELATIONSHIP && !questionSELFCALLING && !questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Relationship to patient, Calling for yourself, Patient Consent and Caller Name");
          return;
        }

        if (!reasonQuestions && locationQuestions && !questionTELEPHONE && questionRELATIONSHIP && !questionSELFCALLING && !questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit,  Return Telephone Number,  Calling for yourself, Patient Consent and Caller Name");
          return;
        }
        if (!reasonQuestions && locationQuestions && !questionTELEPHONE && !questionRELATIONSHIP && questionSELFCALLING && !questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit,  Return Telephone Number, Relationship to patient, Patient Consent and Caller Name");
          return;
        }
        if (!reasonQuestions && locationQuestions && !questionTELEPHONE && !questionRELATIONSHIP && !questionSELFCALLING && questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Return Telephone Number, Relationship to patient, Calling for yourself and Caller Name");
          return;
        }
        if (!reasonQuestions && locationQuestions && !questionTELEPHONE && !questionRELATIONSHIP && !questionSELFCALLING && !questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit,  Return Telephone Number, Relationship to patient, Calling for yourself and Patient Consent");
          return;
        }
        //2.3
        if (!reasonQuestions && !locationQuestions && questionTELEPHONE && questionRELATIONSHIP && !questionSELFCALLING && !questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Patient Location, Calling for yourself, Patient Consent and Caller Name");
          return;
        }
        if (!reasonQuestions && !locationQuestions && questionTELEPHONE && !questionRELATIONSHIP && questionSELFCALLING && !questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Patient Location, Relationship to patient, Patient Consent and Caller Name");
          return;
        }
        if (!reasonQuestions && !locationQuestions && questionTELEPHONE && !questionRELATIONSHIP && !questionSELFCALLING && questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Patient Location,  Relationship to patient, Calling for yourself and Caller Name");
          return;
        }
        if (!reasonQuestions && !locationQuestions && questionTELEPHONE && !questionRELATIONSHIP && !questionSELFCALLING && !questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Patient Location, Relationship to patient, Calling for yourself and Patient Consent");
          return;
        }
        // 2.4 
        if (!reasonQuestions && !locationQuestions && !questionTELEPHONE && questionRELATIONSHIP && questionSELFCALLING && !questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Patient Location, Return Telephone Number, Patient Consent and Caller Name");
          return;
        }
        if (!reasonQuestions && !locationQuestions && !questionTELEPHONE && questionRELATIONSHIP && !questionSELFCALLING && questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Patient Location, Return Telephone Number, Calling for yourself and Caller Name");
          return;
        }
        if (!reasonQuestions && !locationQuestions && !questionTELEPHONE && questionRELATIONSHIP && !questionSELFCALLING && !questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Patient Location, Return Telephone Number,  Calling for yourself and Patient Consent");
          return;
        }
        // 2.5 
        if (!reasonQuestions && !locationQuestions && !questionTELEPHONE && !questionRELATIONSHIP && questionSELFCALLING && questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Patient Location, Return Telephone Number, Relationship to patient and Caller Name");
          return;
        }
        if (!reasonQuestions && !locationQuestions && !questionTELEPHONE && !questionRELATIONSHIP && questionSELFCALLING && !questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Patient Location, Return Telephone Number, Relationship to patient, and Patient Consent");
          return;
        }
        // 2.6 
        if (!reasonQuestions && !locationQuestions && !questionTELEPHONE && !questionRELATIONSHIP && !questionSELFCALLING && questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Patient Location, Return Telephone Number, Relationship to patient and Calling for yourself ");
          return;
        }
        // 3 

        if (reasonQuestions && locationQuestions && questionTELEPHONE && !questionRELATIONSHIP && !questionSELFCALLING && !questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-   Relationship to patient, Calling for yourself, Patient Consent and Caller Name");
          return;
        }
        if (reasonQuestions && locationQuestions && !questionTELEPHONE && questionRELATIONSHIP && !questionSELFCALLING && !questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-  Return Telephone Number, Calling for yourself, Patient Consent and Caller Name");
          return;
        }
        if (reasonQuestions && locationQuestions && !questionTELEPHONE && !questionRELATIONSHIP && questionSELFCALLING && !questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :- Return Telephone Number, Relationship to patient, Patient Consent and Caller Name");
          return;
        }
        if (reasonQuestions && locationQuestions && !questionTELEPHONE && !questionRELATIONSHIP && !questionSELFCALLING && questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :- Return Telephone Number, Relationship to patient, Calling for yourself and Caller Name");
          return;
        }
        if (reasonQuestions && locationQuestions && !questionTELEPHONE && !questionRELATIONSHIP && !questionSELFCALLING && !questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :- Return Telephone Number, Relationship to patient, Calling for yourself and Patient Consent");
          return;
        }
        //3.1

        if (reasonQuestions && !locationQuestions && questionTELEPHONE && questionRELATIONSHIP && !questionSELFCALLING && !questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-  Patient Location, Calling for yourself, Patient Consent and Caller Name");
          return;
        }
        if (reasonQuestions && !locationQuestions && questionTELEPHONE && !questionRELATIONSHIP && questionSELFCALLING && !questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-  Patient Location, Relationship to patient,  Patient Consent and Caller Name");
          return;
        }
        if (reasonQuestions && !locationQuestions && questionTELEPHONE && !questionRELATIONSHIP && !questionSELFCALLING && questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-  Patient Location,  Relationship to patient, Calling for yourself  and Caller Name");
          return;
        }
        if (reasonQuestions && !locationQuestions && questionTELEPHONE && !questionRELATIONSHIP && !questionSELFCALLING && !questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :-  Patient Location, Relationship to patient, Calling for yourself and Patient Consent");
          return;
        }
        // 3.2 
        if (reasonQuestions && !locationQuestions && !questionTELEPHONE && questionRELATIONSHIP && questionSELFCALLING && !questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-  Patient Location, Return Telephone Number, Patient Consent and Caller Name");
          return;
        }
        if (reasonQuestions && !locationQuestions && !questionTELEPHONE && questionRELATIONSHIP && !questionSELFCALLING && questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-  Patient Location, Return Telephone Number, Calling for yourself and Caller Name");
          return;
        }
        if (reasonQuestions && !locationQuestions && !questionTELEPHONE && questionRELATIONSHIP && !questionSELFCALLING && !questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :-  Patient Location, Return Telephone Number,  Calling for yourself and Patient Consent ");
          return;
        }
        // 3.3 
        if (reasonQuestions && !locationQuestions && !questionTELEPHONE && !questionRELATIONSHIP && questionSELFCALLING && questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :- Patient Location, Return Telephone Number, Relationship to patient  and Caller Name");
          return;
        }

        if (reasonQuestions && !locationQuestions && !questionTELEPHONE && !questionRELATIONSHIP && questionSELFCALLING && !questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :- Patient Location, Return Telephone Number, Relationship to patient, and Patient Consent");
          return;
        }
        if (reasonQuestions && !locationQuestions && !questionTELEPHONE && !questionRELATIONSHIP && !questionSELFCALLING && questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :-  Patient Location, Return Telephone Number, Relationship to patient and Calling for yourself");
          return;
        }
        // 3.4 
        if (!reasonQuestions && locationQuestions && questionTELEPHONE && questionRELATIONSHIP && !questionSELFCALLING && !questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Calling for yourself, Patient Consent and Caller Name");
          return;
        }
        if (!reasonQuestions && locationQuestions && questionTELEPHONE && !questionRELATIONSHIP && questionSELFCALLING && !questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Relationship to patient, Patient Consent and Caller Name");
          return;
        }
        if (!reasonQuestions && locationQuestions && questionTELEPHONE && !questionRELATIONSHIP && !questionSELFCALLING && questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Relationship to patient, Calling for yourself  and Caller Name");
          return;
        }
        if (!reasonQuestions && locationQuestions && questionTELEPHONE && !questionRELATIONSHIP && !questionSELFCALLING && !questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Relationship to patient, Calling for yourself and Patient Consent ");
          return;
        }
        //3.5 

        if (!reasonQuestions && locationQuestions && !questionTELEPHONE && questionRELATIONSHIP && questionSELFCALLING && !questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Return Telephone Number, Patient Consent and Caller Name");
          return;
        }
        if (!reasonQuestions && locationQuestions && !questionTELEPHONE && questionRELATIONSHIP && !questionSELFCALLING && questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit,  Return Telephone Number, Calling for yourself, and Caller Name ");
          return;
        }
        if (!reasonQuestions && locationQuestions && !questionTELEPHONE && questionRELATIONSHIP && !questionSELFCALLING && !questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Return Telephone Number,Calling for yourself and Patient Consent");
          return;
        }
        // 3.6 
        if (!reasonQuestions && locationQuestions && !questionTELEPHONE && !questionRELATIONSHIP && questionSELFCALLING && questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Return Telephone Number, Relationship to patient and Caller Name");
          return;
        }
        if (!reasonQuestions && locationQuestions && !questionTELEPHONE && !questionRELATIONSHIP && questionSELFCALLING && !questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Return Telephone Number, Relationship to patient and Patient Consent");
          return;
        }
        //3.7
        if (!reasonQuestions && locationQuestions && !questionTELEPHONE && !questionRELATIONSHIP && !questionSELFCALLING && questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Return Telephone Number, Relationship to patient and Calling for yourself");
          return;
        }
        // 3.8 
        if (!reasonQuestions && !locationQuestions && questionTELEPHONE && questionRELATIONSHIP && questionSELFCALLING && !questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Patient Location, Patient Consent and Caller Name");
          return;
        }
        if (!reasonQuestions && !locationQuestions && questionTELEPHONE && questionRELATIONSHIP && !questionSELFCALLING && questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Patient Location, Calling for yourself and Caller Name");
          return;
        }
        if (!reasonQuestions && !locationQuestions && questionTELEPHONE && questionRELATIONSHIP && !questionSELFCALLING && !questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Patient Location, Calling for yourself and Patient Consent ");
          return;
        }
        // 3.9 
        if (!reasonQuestions && !locationQuestions && questionTELEPHONE && !questionRELATIONSHIP && questionSELFCALLING && questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Patient Location,  Relationship to patient and Caller Name");
          return;
        }
        if (!reasonQuestions && !locationQuestions && questionTELEPHONE && !questionRELATIONSHIP && questionSELFCALLING && !questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Patient Location, Relationship to patient and Patient Consent");
          return;
        }
        // 3.10
        if (!reasonQuestions && !locationQuestions && questionTELEPHONE && !questionRELATIONSHIP && !questionSELFCALLING && questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Patient Location, Relationship to patient and Calling for yourself ");
          return;
        }
        // 3. 11 
        if (!reasonQuestions && !locationQuestions && !questionTELEPHONE && questionRELATIONSHIP && questionSELFCALLING && questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Patient Location, Return Telephone Number and Caller Name");
          return;
        }
        if (!reasonQuestions && !locationQuestions && !questionTELEPHONE && questionRELATIONSHIP && questionSELFCALLING && !questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Patient Location, Return Telephone Number and Patient Consent");
          return;
        }
        //3.12 
        if (!reasonQuestions && !locationQuestions && !questionTELEPHONE && !questionRELATIONSHIP && questionSELFCALLING && questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, Patient Location, Return Telephone Number and Relationship to patient");
          return;
        }

       // 4  // 4

       if (reasonQuestions && locationQuestions && questionTELEPHONE && questionRELATIONSHIP && !questionSELFCALLING && !questionCONSENT && !questionCALLER) {
        setErr("Must Select These Questions :-  Calling for yourself, Patient Consent and Caller Name");
        return;
      }
      if (reasonQuestions && locationQuestions && questionTELEPHONE && !questionRELATIONSHIP && questionSELFCALLING && !questionCONSENT && !questionCALLER) {
        setErr("Must Select These Questions :-   Relationship to patient, Patient Consent and Caller Name");
        return;
      }
      if (reasonQuestions && locationQuestions && questionTELEPHONE && !questionRELATIONSHIP && !questionSELFCALLING && questionCONSENT && !questionCALLER) {
        setErr("Must Select These Questions :-  Relationship to patient, Calling for yourself and Caller Name");
        return;
      }
      if (reasonQuestions && locationQuestions && questionTELEPHONE && !questionRELATIONSHIP && !questionSELFCALLING && !questionCONSENT && questionCALLER) {
        setErr("Must Select These Questions :-  Relationship to patient, Calling for yourself and Patient Consent ");
        return;
      }
      // 4.1 // 3
      if (reasonQuestions && locationQuestions && !questionTELEPHONE && questionRELATIONSHIP && questionSELFCALLING && !questionCONSENT && !questionCALLER) {
        setErr("Must Select These Questions :- Return Telephone Number, Patient Consent and Caller Name");
        return;
      }

      if (reasonQuestions && locationQuestions && !questionTELEPHONE && questionRELATIONSHIP && !questionSELFCALLING && questionCONSENT && !questionCALLER) {
        setErr("Must Select These Questions :-  Return Telephone Number, Calling for yourself and Caller Name");
        return;
      }
      if (reasonQuestions && locationQuestions && !questionTELEPHONE && questionRELATIONSHIP && !questionSELFCALLING && !questionCONSENT && questionCALLER) {
        setErr("Must Select These Questions :-  Return Telephone Number, Calling for yourself and Patient Consent");
        return;
      }
      //4.2 // 3
      if (reasonQuestions && !locationQuestions && questionTELEPHONE && questionRELATIONSHIP && questionSELFCALLING && !questionCONSENT && !questionCALLER) {
        setErr("Must Select These Questions :-  Reason for visit , Patient Consent and Caller Name");
        return;
      }
      if (reasonQuestions && !locationQuestions && questionTELEPHONE && questionRELATIONSHIP && !questionSELFCALLING && questionCONSENT && !questionCALLER) {
        setErr("Must Select These Questions :-  Reason for visit, Calling for yourself and Caller Name");
        return;
      }
      if (reasonQuestions && !locationQuestions && questionTELEPHONE && questionRELATIONSHIP && !questionSELFCALLING && !questionCONSENT && questionCALLER) {
        setErr("Must Select These Questions :- Patient Location, Calling for yourself, and Patient Consent ");
        return;
      }
      // 4.3 // 3
      if (!reasonQuestions && locationQuestions && questionTELEPHONE && questionRELATIONSHIP && questionSELFCALLING && !questionCONSENT && !questionCALLER) {
        setErr("Must Select These Questions :-  Reason for visit, Patient Consent and Caller Name");
        return;
      }
      if (!reasonQuestions && locationQuestions && questionTELEPHONE && questionRELATIONSHIP && !questionSELFCALLING && questionCONSENT && !questionCALLER) {
        setErr("Must Select These Questions :-  Reason for visit, Calling for yourself and Caller Name");
        return;
      }
      if (!reasonQuestions && locationQuestions && questionTELEPHONE && questionRELATIONSHIP && !questionSELFCALLING && !questionCONSENT && questionCALLER) {
        setErr("Must Select These Questions :-  Reason for visit,  Calling for yourself and Patient Consent");
        return;
      }
      // 4.4  // 2
      if (!reasonQuestions && locationQuestions && questionTELEPHONE && !questionRELATIONSHIP && questionSELFCALLING && questionCONSENT && !questionCALLER) {
        setErr("Must Select These Questions :-  Reason for visit, Relationship to patient and Caller Name");
        return;
      }
      if (!reasonQuestions && locationQuestions && questionTELEPHONE && !questionRELATIONSHIP && questionSELFCALLING && !questionCONSENT && questionCALLER) {
        setErr("Must Select These Questions :-  Reason for visit, Relationship to patient and  Patient Consent");
        return;
      }
      // 4.5 // 2
      if (!reasonQuestions && locationQuestions && !questionTELEPHONE && questionRELATIONSHIP && questionSELFCALLING && questionCONSENT && !questionCALLER) {
        setErr("Must Select These Questions :-  Reason for visit,  Return Telephone Number and Caller Name");
        return;
      }
      if (!reasonQuestions && locationQuestions && !questionTELEPHONE && questionRELATIONSHIP && questionSELFCALLING && !questionCONSENT && questionCALLER) {
        setErr("Must Select These Questions :-  Reason for visit, Return Telephone Number and Patient Consent");
        return;
      }
      //4.5  // 2
      if (!reasonQuestions && !locationQuestions && questionTELEPHONE && questionRELATIONSHIP && questionSELFCALLING && questionCONSENT && !questionCALLER) {
        setErr("Must Select These Questions :-  Reason for visit, Patient Location and Caller Name");
        return;
      }
      if (!reasonQuestions && !locationQuestions && questionTELEPHONE && questionRELATIONSHIP && questionSELFCALLING && !questionCONSENT && questionCALLER) {
        setErr("Must Select These Questions :-  Reason for visit, Patient Location and Patient Consent");
        return;
      }
      // 4.6 // 1
      if (!reasonQuestions && !locationQuestions && questionTELEPHONE && questionRELATIONSHIP && !questionSELFCALLING && questionCONSENT && questionCALLER) {
        setErr("Must Select These Questions :-  Reason for visit, Patient Location and Calling for yourself");
        return;
      }
      // 4.7  // 1
      if (!reasonQuestions && !locationQuestions && questionTELEPHONE && !questionRELATIONSHIP && questionSELFCALLING && questionCONSENT && questionCALLER) {
        setErr("Must Select These Questions :-  Reason for visit, Patient Location and Relationship to patient");
        return;
      }
      // 4.8 // 1
      if (!reasonQuestions && !locationQuestions && !questionTELEPHONE && questionRELATIONSHIP && questionSELFCALLING && questionCONSENT && questionCALLER) {
        setErr("Must Select These Questions :-  Reason for visit, Patient Location and Return Telephone Number ");
        return;
      }

      // 4.9  // 3
      if (!reasonQuestions && !locationQuestions && questionTELEPHONE && !questionRELATIONSHIP && questionSELFCALLING && questionCONSENT && questionCALLER) {
        setErr("Must Select These Questions :-  Reason for visit, Patient Location and Relationship to patient");
        return;
      }

      if (!reasonQuestions && !locationQuestions && questionTELEPHONE && !questionRELATIONSHIP && questionSELFCALLING && questionCONSENT && questionCALLER) {
        setErr("Must Select These Questions :-  Reason for visit, Patient Location and  Relationship to patient");
        return;
      }
      if (!reasonQuestions && locationQuestions && !questionTELEPHONE && !questionRELATIONSHIP && questionSELFCALLING && questionCONSENT && questionCALLER) {
        setErr("Must Select These Questions :-  Reason for visit, Return Telephone Number and Relationship to patient");
        return;
      }
      if (reasonQuestions && !locationQuestions && !questionTELEPHONE && !questionRELATIONSHIP && questionSELFCALLING && questionCONSENT && questionCALLER) {
        setErr("Must Select These Questions :-   Patient Location, Return Telephone Number and Relationship to patient");
        return;
      }
      // 4.10 // 3

      if (!reasonQuestions && !locationQuestions && questionTELEPHONE && questionRELATIONSHIP && !questionSELFCALLING && questionCONSENT && questionCALLER) {
        setErr("Must Select These Questions :-  Reason for visit, Patient Location and Calling for yourself");
        return;
      }
      if (!reasonQuestions && locationQuestions && !questionTELEPHONE && questionRELATIONSHIP && !questionSELFCALLING && questionCONSENT && questionCALLER) {
        setErr("Must Select These Questions :-  Reason for visit, Return Telephone Number and Calling for yourself");
        return;
      }
      if (reasonQuestions && !locationQuestions && !questionTELEPHONE && questionRELATIONSHIP && !questionSELFCALLING && questionCONSENT && questionCALLER) {
        setErr("Must Select These Questions :-  Patient Location, Return Telephone Number and Calling for yourself ");
        return;
      }

      if (!reasonQuestions && locationQuestions && questionTELEPHONE && !questionRELATIONSHIP && !questionSELFCALLING && questionCONSENT && questionCALLER) {
        setErr("Must Select These Questions :-  Reason for visit, Relationship to patient and Calling for yourself");
        return;
      }

      if ( reasonQuestions && !locationQuestions &&  questionTELEPHONE && !questionRELATIONSHIP && !questionSELFCALLING && questionCONSENT && questionCALLER) {
        setErr("Must Select These Questions :- Patient Location,  Relationship to patient and Calling for yourself");
        return;
      }


      // 4.11  // 2
      if (!reasonQuestions && locationQuestions && !questionTELEPHONE && questionRELATIONSHIP && questionSELFCALLING && !questionCONSENT && questionCALLER) {
        setErr("Must Select These Questions :-  Reason for visit, Return Telephone Number and Patient Consent");
        return;
      }
      if (reasonQuestions && !locationQuestions && !questionTELEPHONE && questionRELATIONSHIP && questionSELFCALLING && !questionCONSENT && questionCALLER) {
        setErr("Must Select These Questions :-  Patient Location, Return Telephone Number and Patient Consent");
        return;
      }
      if (!reasonQuestions &&  locationQuestions &&  questionTELEPHONE && !questionRELATIONSHIP && questionSELFCALLING && !questionCONSENT && questionCALLER) {
        setErr("Must Select These Questions :-  Reason for visit, Relationship to patient and Patient Consent");
        return;
      }
      if ( reasonQuestions && !locationQuestions &&  questionTELEPHONE && !questionRELATIONSHIP &&  questionSELFCALLING && !questionCONSENT && questionCALLER) {
        setErr("Must Select These Questions :-  Patient Location, Relationship to patient and Patient Consent");
        return;
      }

      // 4.12 // 4
      if (!reasonQuestions && locationQuestions && !questionTELEPHONE && questionRELATIONSHIP && questionSELFCALLING && questionCONSENT && !questionCALLER) {
        setErr("Must Select These Questions :-  Reason for visit, Return Telephone Numberand Caller Name");
        return;
      }
      if (reasonQuestions && !locationQuestions && !questionTELEPHONE && questionRELATIONSHIP && questionSELFCALLING && questionCONSENT && !questionCALLER) {
        setErr("Must Select These Questions :-   Patient Location, Return Telephone Number and Caller Name");
        return;
      }
      if (!reasonQuestions && locationQuestions && questionTELEPHONE && !questionRELATIONSHIP && questionSELFCALLING && questionCONSENT && !questionCALLER) {
        setErr("Must Select These Questions :-  Reason for visit, Relationship to patient  and Caller Name");
        return;
      }
      if (reasonQuestions && locationQuestions && !questionTELEPHONE && !questionRELATIONSHIP && questionSELFCALLING && questionCONSENT && !questionCALLER) {
        setErr("Must Select These Questions :- Return Telephone Number, Relationship to patient and Caller Name");
        return;
      }
        // 5
        if (!reasonQuestions && !locationQuestions && questionTELEPHONE && questionRELATIONSHIP && questionSELFCALLING && questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit and Patient Location");
          return;
        }
        if (!reasonQuestions && locationQuestions && !questionTELEPHONE && questionRELATIONSHIP && questionSELFCALLING && questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit and Return Telephone Number");
          return;
        }
        if (!reasonQuestions && locationQuestions && questionTELEPHONE && !questionRELATIONSHIP && questionSELFCALLING && questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit, and Relationship to patient");
          return;
        }
        if (!reasonQuestions && locationQuestions && questionTELEPHONE && questionRELATIONSHIP && !questionSELFCALLING && questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit and Calling for yourself");
          return;
        }
        if (!reasonQuestions && locationQuestions && questionTELEPHONE && questionRELATIONSHIP && questionSELFCALLING && !questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit and Patient Consent");
          return;
        }
        if (!reasonQuestions && locationQuestions && questionTELEPHONE && questionRELATIONSHIP && questionSELFCALLING && questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-  Reason for visit and Caller Name");
          return;
        }
        if (reasonQuestions && !locationQuestions && !questionTELEPHONE && questionRELATIONSHIP && questionSELFCALLING && questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :-   Patient Location and Return Telephone Number ");
          return;
        }
        if (reasonQuestions && !locationQuestions && questionTELEPHONE && !questionRELATIONSHIP && questionSELFCALLING && questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :-   Patient Location and Relationship to patient");
          return;
        }
        if (reasonQuestions && !locationQuestions && questionTELEPHONE && questionRELATIONSHIP && !questionSELFCALLING && questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :- Patient Location and Calling for yourself");
          return;
        }
        if (reasonQuestions && !locationQuestions && questionTELEPHONE && questionRELATIONSHIP && questionSELFCALLING && !questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :-  Patient Location and Patient Consent");
          return;
        }
        if (reasonQuestions && !locationQuestions && questionTELEPHONE && questionRELATIONSHIP && questionSELFCALLING && questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-  Patient Location and Caller Name");
          return;
        }
        if (reasonQuestions && locationQuestions && !questionTELEPHONE && !questionRELATIONSHIP && questionSELFCALLING && questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :-   Return Telephone Number and  Relationship to patient ");
          return;
        }
        if (reasonQuestions && locationQuestions && !questionTELEPHONE && questionRELATIONSHIP && !questionSELFCALLING && questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :-   Return Telephone Number and Calling for yourself");
          return;
        }
        if (reasonQuestions && locationQuestions && !questionTELEPHONE && questionRELATIONSHIP && questionSELFCALLING && !questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :-  Return Telephone Number and Patient Consent");
          return;
        }
        if (reasonQuestions && locationQuestions && !questionTELEPHONE && questionRELATIONSHIP && questionSELFCALLING && questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-  Return Telephone Number and Caller Name");
          return;
        }
        if (reasonQuestions && locationQuestions && questionTELEPHONE && !questionRELATIONSHIP && !questionSELFCALLING && questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :-   Relationship to patient and Calling for yourself");
          return;
        }
        if (reasonQuestions && locationQuestions && questionTELEPHONE && !questionRELATIONSHIP && questionSELFCALLING && !questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :-   Relationship to patient and Patient Consent");
          return;
        }
        if (reasonQuestions && locationQuestions && questionTELEPHONE && !questionRELATIONSHIP && questionSELFCALLING && questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-Relationship to patient  and Caller Name");
          return;
        }
        if (reasonQuestions && locationQuestions && questionTELEPHONE && questionRELATIONSHIP && !questionSELFCALLING && !questionCONSENT && questionCALLER) {
          setErr("Must Select These Questions :-  Calling for yourself and Patient Consent");
          return;
        }
        if (reasonQuestions && locationQuestions && questionTELEPHONE && questionRELATIONSHIP && !questionSELFCALLING && questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :-   Calling for yourself and Caller Name");
          return;
        }
        if (reasonQuestions && locationQuestions && questionTELEPHONE && questionRELATIONSHIP && questionSELFCALLING && !questionCONSENT && !questionCALLER) {
          setErr("Must Select These Questions :- Patient Consent and Caller Name");
          return;
        }
        // 6
        if (reasonQuestions && locationQuestions && questionTELEPHONE && questionRELATIONSHIP && questionSELFCALLING && questionCONSENT && !questionCALLER) {
          setErr("Must Select Question :- Caller Name");
          return;
        }
        if (!reasonQuestions && locationQuestions && questionTELEPHONE && questionRELATIONSHIP && questionSELFCALLING && questionCONSENT && questionCALLER) {
          setErr("Must Select Question :-  Reason for visit");
          return;
        }
        if (reasonQuestions && !locationQuestions && questionTELEPHONE && questionRELATIONSHIP && questionSELFCALLING && questionCONSENT && questionCALLER) {
          setErr("Must Select Question :-  Patient Location");
          return;
        }
        if (reasonQuestions && locationQuestions && !questionTELEPHONE && questionRELATIONSHIP && questionSELFCALLING && questionCONSENT && questionCALLER) {
          setErr("Must Select  Question :-  Return Telephone Number");
          return;
        }
        if (reasonQuestions && locationQuestions && questionTELEPHONE && !questionRELATIONSHIP && questionSELFCALLING && questionCONSENT && questionCALLER) {
          setErr("Must Select  Question :-  Relationship to patient");
          return;
        }
        if (reasonQuestions && locationQuestions && questionTELEPHONE && questionRELATIONSHIP && !questionSELFCALLING && questionCONSENT && questionCALLER) {
          setErr("Must Select  Question :-  Calling for yourself ");
          return;
        }
        if (reasonQuestions && locationQuestions && questionTELEPHONE && questionRELATIONSHIP && questionSELFCALLING && !questionCONSENT && questionCALLER) {
          setErr("Must Select  Question :-   Patient Consent ");
          return;
        }
        // SINGLE

        if (!locationQuestions) {
          setErr("Must Select a Question :- Patient Location");
          return;
        } else if (locationQuestions > 1) {
          setErr("You Have Selected More Than One Question Marked With Patient Location");
          return;
        }
        if (!reasonQuestions) {
          setErr("Must Select a Question :-  Reason for visit");
          return;
        } else if (reasonQuestions > 1) {
          setErr("You Have Selected More Than One Question Marked With  Reason for visit");
          return;
        }
        if (!questionTELEPHONE) {
          setErr("Must Select a Question :- Return Telephone Number");
          return;
        } else if (questionTELEPHONE > 1) {
          setErr("You Have Selected More Than One Question Marked With Return Telephone Number");
          return;
        }
        if (!questionRELATIONSHIP) {
          setErr("Must Select a Question :- Relationship to patient");
          return;
        } else if (questionRELATIONSHIP > 1) {
          setErr("You Have Selected More Than One Question Marked With Relationship to patient");
          return;
        }

        if (!questionSELFCALLING) {
          setErr("Must Select a Question :-  Calling for yourself");
          return;
        } else if (questionSELFCALLING > 1) {
          setErr("You Have Selected More Than One Question Marked With  Calling for yourself");
          return;
        }
        if (!questionCONSENT) {
          setErr("Must Select a Question :- Patient Consent");
          return;
        } else if (questionCONSENT > 1) {
          setErr("You Have Selected More Than One Question Marked With Patient Consent");
          return;
        }
        if (!questionCALLER) {
          setErr("Must Select a Question :-  Caller Name");
          return;
        } else if (questionCALLER > 1) {
          setErr("You Have Selected More Than One Question Marked With Caller Name");
          return;
        }

      }

      setBtnLoading(true);
      values.isDefault = values.isDefault ? values.isDefault : false
      editQuestionnaire(modalShow?.id, {
        ...values,
        question: tempQuestionsArr
      }).then((res) => {
        success(res.message);
        handleShow();
        refreshTable();
      }).catch(res => {
        failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
      }).finally(() => setBtnLoading(false));
    },
  });

  const onSuccess = (data) => {
    setQuestionsCategoryList(data);
    setCategorySelectedQuestions(intialCategorySelectedQuestions?.length ? intialCategorySelectedQuestions : [])
    intialCategorySelectedQuestions?.length && setIntialCategorySelectedQuestions([])
    setErr('');
  };
  const { isLoading, isFetching } = useGetQuestionsByCategoryQuery({ onSuccess, purpose: formik?.values?.purpose, refetchOnWindowFocus: false, });

  useEffect(() => {
    if (modalShow?.id) {
      formik.setFieldValue("title", modalShow?.title || "");
      formik.setFieldValue("purpose", modalShow?.purpose || "");
      formik.setFieldValue("time", modalShow?.duration?.value);
      formik.setFieldValue("isDefault", modalShow?.isDefault || "");
      setIntialCategorySelectedQuestions(modalShow?.item?.map((item) => ({ ...item, question: item?.text })))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalShow]);

  const handleSelectedQuestions = (question) => {
    setErr('')
    if (!isReadOnly) {
      if (!categorySelectedQuestions?.some(item => item?.question === question?.question)) {
        const newArr = [question, ...categorySelectedQuestions]
        setCategorySelectedQuestions(newArr)
        setErr('');
      } else {
        const newArr = categorySelectedQuestions.filter(item => item?.question !== question?.question)
        setCategorySelectedQuestions(newArr)
        setErr('');
      }
    }
  };

  if (!modalShow) {
    return null;
  }

  const handleDrop = (droppedItem) => {
    setErr('');
    if (!droppedItem.destination) return;
    var updatedList = [...categorySelectedQuestions];
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    setCategorySelectedQuestions(updatedList);
    setErr('');
  };

  const handleDelete = async (id, entryName, purposeData) => {
    const confirmed = await getConfirmation({
      title: "Attention!",
      entryName,
      actionBtnTitle: "Delete",
    });
    if (confirmed) {
      setDeleting(modalShow?.id);
      let payload = {
        "status": "retired",
        "purpose": purposeData,
        "type": "questionnaire"
      }
      deleteQuestionnaire(modalShow?.id, payload).then(() => {
        // setFilterData(filterData.filter(data => data.id !== id));
        success("Questionnaire Deleted!");
        handleShow();
        refreshTable();
      }).catch(res => failed(res?.response?.data?.message || res?.response?.data?.error || res.message)).finally(() => setDeleting(false));
    }
  };

  const handleDeleteQuestion = (index) => {
    setErr('');
    const updatedList = [...categorySelectedQuestions];
    updatedList.splice(index, 1);
    setCategorySelectedQuestions(updatedList);
  };

  return (
    <Modal
      show={modalShow}
      onHide={handleShow}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="custom-dialog"
      backdropClassName
      backdrop={"static"}
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title id="contained-modal-title-vcenter">
          {isReadOnly ? "View" : "Edit"} Questionnaire
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          className="common-form border-fields"
          onSubmit={formik.handleSubmit}
        >
          <Row>
            <Col>
              <TextInput
                disabled={isReadOnly}
                keyField={"title"}
                label={"Title"}
                formik={formik}
                placeholder={"Title"}
              />
            </Col>
            <Col>
              <SelectFiled
                disabled={isReadOnly}
                keyField={"purpose"}
                label={"Purpose"}
                formik={formik}
                placeholder={"Select"}
                options={questionnaireForOptions}
                value={formik.values?.purpose}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <h6>Available Categories</h6>
              {isLoading || isFetching ? (
                <ul className="questionnaire-list">
                  {[1, 2, 3, 4, 5, 6, 7]?.map((val) => (
                    <li key={val}>
                      <Skeleton width={"100%"} animation="wave" />
                    </li>
                  ))}
                </ul>
              ) : (
                <ul className="questionnaire-list">
                  {questionsCategoryList?.map((category) =>
                    <Accordion key={category?.id}>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography>{category?.categoryName}</Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ padding: 0 }}>
                        <ul className="questionnaire-list">
                          {category?.questions?.map((question) =>
                            <li key={question?.linkId} onClick={() => handleSelectedQuestions(question, category)}>
                              {question?.question}
                              <NavigateNext style={{ float: "right" }} />
                            </li>
                          )}
                        </ul>
                      </AccordionDetails>
                    </Accordion>
                  )}
                </ul>
              )}
            </Col>
            <Col>
              <h6>Selected Questions</h6>
              {categorySelectedQuestions?.length ? null : (
                <div>No available question selected</div>
              )}
              <Tooltip title={"Drag to Reorder Questions"}>
                <ul className="questionnaire-list">
                  {!isReadOnly ? <DragDropContext onDragEnd={handleDrop}>
                    <Droppable droppableId="list-container">
                      {(provided) => (
                        <div
                          // className="list-container"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {categorySelectedQuestions.map((item, index) => (
                            <Draggable
                              key={item?.question}
                              draggableId={item?.question}
                              index={index}
                            >
                              {(provided) => (
                                <li
                                  key={index}
                                  ref={provided.innerRef}
                                  {...provided.dragHandleProps}
                                  {...provided.draggableProps}
                                >
                                  <div><span> {item?.question} </span></div>
                                  <img
                                    src={DeleteIcon}
                                    alt="Delete"
                                    onClick={() => handleDeleteQuestion(index)}
                                    style={{ cursor: "pointer" }}
                                  />
                                </li>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext> : categorySelectedQuestions?.map((item, key) => (
                    <li key={key} onClick={() => handleSelectedQuestions(item)}>
                      <div> <span> {item.text} </span></div>
                    </li>
                  ))
                  }
                </ul>
              </Tooltip>
              <div style={{ fontSize: "14px", color: "#dc3545", paddingTop: "5px" }}>{err}</div>
            </Col>
          </Row><hr />
          <Row>
            <Col>
              <div>
                <h6>Date Created : {ViewDate(modalShow?.createdAt)}</h6>
                <h6>Date Last Updated : {ViewDate(modalShow?.updatedAt)} </h6>
              </div>
            </Col>
            <Col>
              <div>
                {!isReadOnly ? (<>
                  <div
                  // className="btn-wrap"
                  // style={{ display: "flex", marginTop: "12rem" }}
                  >
                    {/* <Row>
                <Col>
                  <Button variant="secondary" title="Cancel" onClick={handleShow}  style={{ padding: '5px 10px' }} >
                    Cancel
                  </Button> </Col>
                <Col>
                  <Button type="submit" isLoading={btnLoading}  style={{ padding: '5px 10px' }} >
                    Update
                  </Button> </Col>
                <Col>
                  <Button variant="secondary" title="Delete" onClick={() => hasAccess && (deleting ? null : handleDelete(modalShow?.id, modalShow?.title, modalShow?.purpose))}  style={{ padding: '5px 10px' }} >
                    Delete
                  </Button></Col>
              </Row> */}
                    <Row >
                      <Col sm={12} md={12} lg={4} xl={4} className="text-center" >
                        <Button variant="secondary" title="Cancel" onClick={handleShow} style={{ width: '100%', marginBottom: '10px', padding: '5px 10px' }}>
                          Cancel
                        </Button>
                      </Col>
                      <Col sm={12} md={12} lg={4} xl={4} className="text-center" >
                        <Button type="submit" isLoading={btnLoading} style={{ width: '100%', marginBottom: '10px', padding: '5px 10px' }}>
                          Update
                        </Button>
                      </Col>
                      <Col sm={12} md={12} lg={4} xl={4} className="text-center" >
                        <Button variant="secondary" title="Delete" onClick={() => hasAccess && (deleting ? null : handleDelete(modalShow?.id, modalShow?.title, modalShow?.purpose))} style={{ width: '100%', marginBottom: '10px', padding: '5px 10px' }}>
                          Delete
                        </Button>
                      </Col>
                    </Row>

                  </div>
                </>
                ) : null}
              </div>
            </Col>
          </Row>
          <div>
            {formik?.touched["question"] && formik?.errors["question"] ? (
              <div className="error-text">{formik?.errors["question"]}</div>
            ) : null}
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default EditQuestionnaire;
