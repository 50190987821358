import { Pagination } from "@mui/material"

const CustomPagination = ({ tableData, totalRows, rowsPerPage, page, handlePage }) => {
    return (
        <div className="pagination-wrap">
            <p style={{ marginBottom: Math.ceil(totalRows / rowsPerPage) > 1 ? "10px" : "0" }}>Showing {totalRows ? page * rowsPerPage + 1 : 0}-
                {tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length === rowsPerPage
                    ? page * rowsPerPage + rowsPerPage
                    : totalRows} of {totalRows}</p>
            <Pagination
                page={page + 1}
                count={Math.ceil(totalRows / rowsPerPage)}
                color="primary"
                boundarycount={1}
                onChange={(event, newPage) => handlePage(newPage - 1)}
            />
        </div>
    )
}

export default CustomPagination