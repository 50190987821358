import * as Yup from "yup";

export const FormikObj = {
    initialValues: {
        encounterId: "",
        practitionerName: "",
        patientName: "",
        requestType: "",
        recipient: "",
        documentId: "",
    },
    validationSchema: Yup.object({
        encounterId: Yup.string().required('Required field.').matches(/^$|^\S+.*/, 'Only blankspace is not valid.'),
        practitionerName: Yup.string().required('Required field.').matches(/^$|^\S+.*/, 'Only blankspace is not valid.'),
        patientName: Yup.string().required('Required field.').matches(/^$|^\S+.*/, 'Only blankspace is not valid.'),
        requestType: Yup.string().required('Required field.').matches(/^$|^\S+.*/, 'Only blankspace is not valid.'),
        documentId: Yup.string().required('Required field.').matches(/^$|^\S+.*/, 'Only blankspace is not valid.'),
    }),
}

export const locaitonModalFormikObj = {
    initialValues: {
        recepient: "",
        locationId: "",
        name: "",
        HCN: "",
        locationFax_number: "",
    },
    validationSchema: Yup.object({
        recepient: Yup.string().required('Required field.').matches(/^$|^\S+.*/, 'Only blankspace is not valid.'),
        locationId: Yup.string().when("recepient", (recepient, schema) => {
            return recepient === "location"
                ? schema.required('Required field.').matches(/^$|^\S+.*/, 'Only blankspace is not valid.')
                : schema
        })
    }),
}


export const formFieldsToEdit = [
    [{
        fieldType: "TextInput",
        keyField: "encounterId",
        label: "Encounter ID",
        placeholder: "111D1",
        disabled: true
    },
    {
        fieldType: "TextInput",
        keyField: "practitionerName",
        label: "Practitioner Name",
        disabled: true
    },
    {
        fieldType: "TextInput",
        keyField: "patientName",
        label: "Patient Name",
        disabled: true,
    }],
    [{
        fieldType: "TextInput",
        keyField: "requestType",
        label: "Request Type",
        disabled: true,
    },
    {
        fieldType: "SelectFiled",
        keyField: "recipient",
        label: "Select Recipient",
        disabled: true,
        required: false,
    },
    {
        fieldType: "SelectFiled",
        keyField: "questionnaire",
        label: "Select Questionnaire",
        
    }],
]