import React, { useState } from "react";
import { Table } from "react-bootstrap";
import DeleteIcon from "../../../../assets/images/delete.png";
import CustomPagination from "../../../common/components/Pagination/CustomPagination";
import { TableCell, Link } from "@mui/material";
import Tab from "react-bootstrap/Tab";
import { Spinner } from "react-bootstrap";
import { convertVitalsBaseValueToOrgLevelUnit } from "../../../Admin/Schedule/constants";
import { useSelector } from "react-redux";
import { useGetOrgVitalsUnits } from "../../../../hooks/ReactQueryHooks/useGetOrgVitalsUnits";

const ConditionValue = ({ conditionName, conditionData, index, orgVitalsUnit }) => {
    switch (conditionName) {
        case "Blood Pressure":
            return (
                <>
                    <p>{`(Diastolic)${convertVitalsBaseValueToOrgLevelUnit(conditionData?.blood_pressure[index]?.diastolic?.below, orgVitalsUnit?.blood_pressure?.systolic)}
                     - ${convertVitalsBaseValueToOrgLevelUnit(conditionData?.blood_pressure[index]?.diastolic?.above, orgVitalsUnit?.blood_pressure?.systolic)}`}</p>
                    <p>{`(Systolic)${convertVitalsBaseValueToOrgLevelUnit(conditionData?.blood_pressure[index]?.systolic?.below, orgVitalsUnit?.blood_pressure?.systolic)} 
                    - ${convertVitalsBaseValueToOrgLevelUnit(conditionData?.blood_pressure[index]?.systolic?.above, orgVitalsUnit?.blood_pressure?.systolic)}`}</p>
                </>
            )
        case "Weight":
            return (
                <>{index === 0
                    ? <>{convertVitalsBaseValueToOrgLevelUnit(conditionData?.weight[index]?.below, orgVitalsUnit?.weight?.weight)}- {convertVitalsBaseValueToOrgLevelUnit(conditionData?.weight[index]?.above, orgVitalsUnit?.weight?.weight)}</>
                    : <><p>Less than {convertVitalsBaseValueToOrgLevelUnit(conditionData?.weight[1]?.below, orgVitalsUnit?.weight?.weight)} </p> <p>{convertVitalsBaseValueToOrgLevelUnit(conditionData?.weight[1]?.above, orgVitalsUnit?.weight?.weight)} Or Higher</p></>}</>
            )
        case "Blood Glucose":
            return (<>{index === 0
                ? <>{convertVitalsBaseValueToOrgLevelUnit(conditionData?.blood_glucose[index]?.below, orgVitalsUnit?.blood_glucose?.glucose)}- {convertVitalsBaseValueToOrgLevelUnit(conditionData?.blood_glucose[index]?.above, orgVitalsUnit?.blood_glucose?.glucose)}</>
                : <><p>Less than {convertVitalsBaseValueToOrgLevelUnit(conditionData?.blood_glucose[1]?.below, orgVitalsUnit?.blood_glucose?.glucose)}</p> <p>{convertVitalsBaseValueToOrgLevelUnit(conditionData?.blood_glucose[1]?.above, orgVitalsUnit?.blood_glucose?.glucose)} Or Higher</p></>}</>
            )
        case "Oxygen":
            return (<>{index === 0
                ? <>{convertVitalsBaseValueToOrgLevelUnit(conditionData?.oxygen[index]?.below, orgVitalsUnit?.oxygen?.oxygen)}- {convertVitalsBaseValueToOrgLevelUnit(conditionData?.oxygen[index]?.above, orgVitalsUnit?.oxygen?.oxygen)}</>
                : <><p>Less than {convertVitalsBaseValueToOrgLevelUnit(conditionData?.oxygen[1]?.below, orgVitalsUnit?.oxygen?.oxygen)}</p> <p>{convertVitalsBaseValueToOrgLevelUnit(conditionData?.oxygen[1]?.above, orgVitalsUnit?.oxygen?.oxygen)} Or Higher</p></>}</>
            )
        case "Temperature":
            return (<>{index === 0
                ? <> {convertVitalsBaseValueToOrgLevelUnit(conditionData?.temperature[index]?.below, orgVitalsUnit?.temperature?.temperature)}- {convertVitalsBaseValueToOrgLevelUnit(conditionData?.temperature[index]?.above, orgVitalsUnit?.temperature?.temperature)}</>
                : <><p>Less than {convertVitalsBaseValueToOrgLevelUnit(conditionData?.temperature[1]?.below, orgVitalsUnit?.temperature?.temperature)}</p> <p>Or Higher {convertVitalsBaseValueToOrgLevelUnit(conditionData?.temperature[1]?.above, orgVitalsUnit?.temperature?.temperature)}</p></>}</>
            )
        case "Heart Rate":
            return (<>{index === 0
                ? <> {convertVitalsBaseValueToOrgLevelUnit(conditionData?.heart_rate[index]?.below, orgVitalsUnit?.heart_rate?.heartRate)}- {convertVitalsBaseValueToOrgLevelUnit(conditionData?.heart_rate[index]?.above, orgVitalsUnit?.heart_rate?.heartRate)}</>
                : <><p>Less than {convertVitalsBaseValueToOrgLevelUnit(conditionData?.heart_rate[1]?.below, orgVitalsUnit?.heart_rate?.heartRate)}</p> <p>Or Higher {convertVitalsBaseValueToOrgLevelUnit(conditionData?.heart_rate[1]?.above, orgVitalsUnit?.heart_rate?.heartRate)}</p></>}</>
            )
        default:
            return (<>{""}</>)
    }
}

export function ThresholdList({ deleting, deleteThreshold, parentProps, }) {
    const [page, setPage] = useState(0);
    const rowsPerPage = 5;
    const userOrg = useSelector((state) => state?.auth?.user?.organizations);
    const { data: orgVitalsUnit } = useGetOrgVitalsUnits({ orgId: userOrg[0]?.id })

    return (
        <>
            <div className="table-wrap">
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Condition</th>
                            <th>Medium Range</th>
                            <th>High Value</th>
                            <th style={{
                                textAlign: "center"
                            }}>
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {parentProps?.thresholdData?.length > 0 ? parentProps?.thresholdData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((item, index) => {
                            return <tr key={index}>
                                <td>{item?.conditionName}</td>
                                <td><ConditionValue orgVitalsUnit={orgVitalsUnit} conditionName={item?.conditionName} conditionData={item?.condition} index={0} /></td>
                                <td><ConditionValue orgVitalsUnit={orgVitalsUnit} conditionName={item?.conditionName} conditionData={item?.condition} index={1} /></td>
                                <td>
                                    <div className="action-wrap">
                                        <Link className="delete-btn" title="Delete" onClick={() => {
                                            deleteThreshold(item?.sortKey, item?.conditionName);
                                        }}>
                                            {deleting === item.sortKey ? <Spinner size="lg" className="text-current" style={{
                                                color: "white",
                                                background: "transparent",
                                                margin: "0"
                                            }} /> : <img src={DeleteIcon} alt="Delete" />}
                                        </Link>
                                    </div>
                                </td>
                            </tr>;
                        }) : <tr>
                            <TableCell colSpan="4" style={{
                                textAlign: "center"
                            }}>
                                No Data Available
                            </TableCell>
                        </tr>}
                    </tbody>
                </Table>
                {parentProps?.thresholdData?.length > 0 ? <div>
                    <CustomPagination tableData={parentProps?.thresholdData} totalRows={parentProps?.thresholdData?.length} rowsPerPage={rowsPerPage} page={page} handlePage={pageNo => setPage(pageNo)} />
                </div> : null}
            </div>
        </>
    );
}
