import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { axios } from "../../lib/axios";
import { onError } from "../ReactQueryHooks/onError";

export function useGetPatientByScope({ onSuccess }) {
    const practitioner_id = useSelector((state) => state?.auth?.user?.["custom:unique_id"])
    const org_id = useSelector((state) => state?.auth?.user?.organizations[0]?.id)

    const fetchPatients = (practitioner_id, org_id) => {
        return axios.get(`patient/getBySop?practitioner_id=${practitioner_id}&org_id=${org_id}`);
    };
    const query = useQuery(
        "patientsbyscope",
        () => fetchPatients(practitioner_id, org_id),
        {
            select: (data) => data.data,
            onSuccess,
            onError
        }
    );

    return { ...query };
}
