import JoditEditor from 'jodit-react';
import { useEffect, useMemo, useRef, useState } from 'react';
const TextEditor = ({ keyField, formik, label, readOnly = false, required = true, defaultValue = "" }) => {
    const editorRef = useRef(null);
    const [content, setContent] = useState('')

    const config = useMemo(() => (
      label == false ?
      {
      "toolbarButtonSize": "xsmall",
      "toolbarAdaptive": false,
      "showCharsCounter": false,
      "showWordsCounter": false,
      "showXPathInStatusbar": false,
      "buttons": "bold,italic,underline,strikethrough,ul,ol,font,fontsize",
      readonly: readOnly, // all options from https://xdsoft.net/jodit/docs/,
      }  
    :
    {
      readonly: readOnly, // all options from https://xdsoft.net/jodit/docs/,
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }
    ), []);

    const handleChange = (newContent) => {
        setContent(newContent)
        formik.setFieldValue(keyField, newContent);
    }

    useEffect(() => {
        setContent(defaultValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue])

    return (
        <div className="field-wrap">
           {label ? <label>
                {label}
                {required ? <span style={{ color: "red" }}> *</span> : " (Optional)"}
            </label> : null}
            <div className="editor-container">
              <JoditEditor
                  ref={editorRef}
                  value={content}
                  config={config}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={newContent => handleChange(newContent)}
              />
            </div>
            <div>{formik?.touched[keyField] && formik?.errors[keyField] ? <div className="error-text">{formik?.errors[keyField]}</div> : null}</div>
        </div>
    );
};

export default TextEditor;
