import React, { useState } from "react";
import { failed, success } from "../../common/Toastify";
import { Button } from "../../common/Button";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import SearchBar from "../../common/components/SearchBar";
import { useNavigate } from "react-router-dom";
import { useScheduleCategoryQuery } from "../../../hooks/ReactQueryHooks/useScheduleCategoryQuery";
import { ScheduleDataFields, ScheduleHeadCells } from "./Constants";
import CustomTable from "../../common/table/CustomTable";
import { EditSchedule } from "./edit-schedule";
import { deleteScheduleCategory } from "../../api/ScheduleCategory/index"
function ScheduleList() {
    const { getConfirmation } = useConfirmDialog();
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [modalShow, setModalShow] = useState(false);
    const [scheduleCategory, setSchedulecategoryList] = useState([]);
    const [deleting, setDeleting] = useState(false);
    const [filterData, setFilterData] = useState([]);
    const handleShow = (data) => { setModalShow(modalShow ? false : data) };

    //React Query Hook
    const onSuccess = (data) => {
        setSchedulecategoryList(data);
    };
    const { refetch, isLoading, isFetching } = useScheduleCategoryQuery({ onSuccess });

    const handleFilterData = (data) => {
        setFilterData(data);
    };

    const selectedUserAction = ({ action, data }) => {
        switch (action) {
            case "edit":
                handleShow(data);
                break;
            case "delete":
                handleDelete(data?.id, data?.name);
                break;
            default:
                return null;
        }
    };

    const handleDelete = async (id, entryName) => {
        const confirmed = await getConfirmation({
            title: "Attention!",
            entryName,
            actionBtnTitle: "Delete",
        });
        if (confirmed) {
            setDeleting(id);
            deleteScheduleCategory(id)
                .then((res) => {
                    refetch()
                    success("Schedule Category Deleted!");
                })
                .catch((err) => failed(err?.response?.data?.message || err.message))
                .finally(() => setDeleting(false));
        }
    };

    return (
        <section className="common-listing">
            <EditSchedule modalShow={modalShow} refreshTable={refetch} handleShow={handleShow} />
            <div className="heading-wrap h-change">
                <SearchBar page={page} setPage={setPage} list={scheduleCategory} filterData={handleFilterData} />
                <div className="right-wrap" style={{ margin: "left" }}>
                    <Button onClick={() => navigate("/app/add-schedule")} className="custom-btn" title="Create Appointment Type" style={{ width: "auto" }}>
                        Create Schedule Category
                    </Button>
                </div>
            </div>
            <CustomTable
                tableData={filterData}
                headCells={ScheduleHeadCells}
                dataFields={ScheduleDataFields}
                loading={isLoading}
                rowOptions={false}
                deletingItem={deleting}
                canView={false}
                canDelete={true}
                fetching={isFetching}
                canEdit={true}
                selectedUserAction={selectedUserAction}
                page={page}
                setPage={setPage}
            />
        </section>
    );
}

export default ScheduleList;
