const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

export const handleFileUpload = async (file) => {
    const base64 = await convertToBase64(file);
    return base64;
};

export const convertBufferArrayToBase64 = (buffer) => {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
};

const imageUrl = 'https://example.com/image.jpg';
 
export  const imageURLToBase64 = async(url)=> {
    const response = await fetch(url, {
        method: 'GET',
        headers: { "Cache-Control": 'no-cache' },
    });
    const imageData = await response.arrayBuffer();
    const base64String = btoa(String.fromCharCode(...new Uint8Array(imageData)));
  return `data:image/jpeg;base64,${base64String}`;
}

export const removePrefixFromBase64 = (base64String, prefix)=> {
    const index = base64String.indexOf(prefix);
    if (index !== -1) {
      return base64String.substring(index + prefix.length);
    } else {
      return base64String;
    }
}