import React, { useState, } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import TextInput from "../../common/textfield/TextInput";
import SelectFiled from "../../common/textfield/SelectFiled";
import { useNavigate } from "react-router-dom";
import { Button } from "../../common/Button";
import TextArea from "../../common/textfield/TextArea";
import { createNotification, getNotificationContent, updateNotification } from "../../api/Notification";
import { useOrgQuery } from "../../../hooks/ReactQueryHooks/useOrgQuery";
import { FormikObj, deliveryOptions, recipientOptions, notificationTypeOptions, notificationReceiverOption } from "./Constants";
import CircularProgress from "@mui/material/CircularProgress";
import TextEditor from "../../common/textfield/TextEditor";
import MultiSelect from "../../common/textfield/MultiSelect";
import { Tooltip } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNotificationEventQuery } from "../../../hooks/ReactQueryHooks/useNotificationEventQuery";
import { useSelector } from "react-redux";
import { failed, success } from "../../common/Toastify";
import { useGroupsQuery } from "../../../hooks/ReactQueryHooks/useGroupsQuery";
const eventsToCheck = ["sfkZmDPckn","uhMBmFQckl", "hjBMFmcQlk","dsgWxEQumr"]
function CreateNotification() {
    const navigate = useNavigate();
    const [btnLoading, setBtnLoading] = useState(false);
    const [loadRecipients, setLoadRecipients] = useState([]);
    const [prefilledOrgEventData, setPrefilledOrgEventData] = useState({});
    const [loadDeliveryMechanisms, setLoadDeliveryMechanisms] = useState([]);
    const [customGroupId, setCustomGroupId] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [eventList, setEventList] = useState([]);
    const [groups, setGroups] = useState([]);
    const userType = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
    const onSuccess = (data) => {
        setOrganizations(data?.map((org) => (org = { ...org, value: org.id })));
    };
    const { isLoading } = useOrgQuery({ onSuccess });

    useNotificationEventQuery({ onSuccess: (data) => setEventList(data?.map((event) => ({ value: event?.id, name: event?.eventName, id: event?.id }))) })
    const formik = useFormik({
        ...FormikObj,
        onSubmit: (values) => {
            setBtnLoading(true);
            if (Object.keys(prefilledOrgEventData)?.length > 0) {
                updateNotification({
                    ...values,
                    notificationId: prefilledOrgEventData?.id,
                    eventName: eventList?.filter((event) => event?.id === values?.eventId)?.[0]?.name,
                    content: values.deliveryMechanisms.includes("push_notification") || values.deliveryMechanisms.includes("sms") ? values.content : "",
                    emailContent: values.deliveryMechanisms.includes("email") ? values.emailContent : ""
                })
                    .then((res) => {
                        navigate("/app/manage-notifications");
                        success(res.message);
                    })
                    .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                    .finally(() => setBtnLoading(false));
            }
            else {
                createNotification({
                    ...values,
                    eventName: eventList?.filter((event) => event?.id === values?.eventId)?.[0]?.name,
                    content: values.deliveryMechanisms.includes("push_notification") || values.deliveryMechanisms.includes("sms") ? values.content : "",
                    emailContent: values.deliveryMechanisms.includes("email") ? values.emailContent : "",
                })
                    .then((res) => {
                        navigate("/app/manage-notifications");
                        success(res.message);
                    })
                    .catch((err) => failed(err?.response?.data?.message || err.message))
                    .finally(() => setBtnLoading(false));
            }
        },
    });
    
    useGroupsQuery({
        onSuccess: (data) => setGroups(data.map(({ name, id }) => ({ value: id, id, name }))),
        queryParams: `orgId=["${formik?.values?.orgId}"]&isCustom=true`,
        enabled : !!formik?.values?.orgId,
    })

    const handleFormChange = (e) => {
        if (e.target.id === "orgId" || e.target.id === "eventId") {
            if (e.target.id === "orgId" ? e.target.value && formik.values.eventId : formik.values.orgId && e.target.value) {
                const params = e.target.id === "orgId"
                    ? { orgId: e.target.value, eventId: formik.values.eventId }
                    : { orgId: formik.values.orgId, eventId: e.target.value }
                getNotificationContent(params).then((res) => {
                    formik.setFieldValue("title", res.data?.title || "")
                    formik.setFieldValue("content", res.data?.content || "")
                    formik.setFieldValue("emailSubject", res.data?.emailSubject || "")
                    formik.setFieldValue("emailContent", res.data?.emailContent || "")
                    formik.setFieldValue("deliveryMechanisms", res.data?.deliveryMechanisms || [])
                    formik.setFieldValue("recipients", res.data?.recipients || [])
                    setLoadRecipients(res.data?.recipients || [])
                    setLoadDeliveryMechanisms(res.data?.deliveryMechanisms || [])
                    setPrefilledOrgEventData(res.data || {})
                }).catch((err) => { console.log(err); });
            }
        }
        if (e?.target?.id === "type") {
            formik.resetForm()
            formik.setFieldValue("type", e.target.value)
            setLoadRecipients([])
            setLoadDeliveryMechanisms([])
            setPrefilledOrgEventData({})
        }
    };

    return (
        <section className="common-listing">
            <div className="heading-wrap mb-3">
                <Tooltip title="Go back">
                    <div style={{ marginRight: "1rem", cursor: "pointer" }} onClick={() => navigate(-1)}>
                        <ArrowBack />
                    </div>
                </Tooltip>
            </div>
            <div className="custom-card p-4">
                <form className="common-form border-fields" onSubmit={formik.handleSubmit} onChange={handleFormChange}>
                    {isLoading ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "50vh",
                            }}
                        >
                            <CircularProgress size={50} />
                        </div>
                    ) : (
                        <>
                            <Row>
                                <Col>
                                    <SelectFiled keyField={"type"} readOnly={!userType?.includes("Super-Admin")} label={"Notification type"} formik={formik} placeholder={"What is the title of this notification?"} options={notificationTypeOptions} />
                                </Col>
                                <Col>
                                    <SelectFiled keyField={"eventId"} label={"Event/Topic"} formik={formik} placeholder={"Event name of your notification?"} options={eventList} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <MultiSelect keyField={"recipients"} label={"Select Recipients"} formik={formik} options={recipientOptions} defaultValue={loadRecipients} bindMethod={(val) => setLoadRecipients(val)} isSelectAllEnable={true} />
                                </Col>
                                <Col>
                                    {formik?.values?.type === "custom" ? <SelectFiled keyField={"orgId"} label={"Select Organization"} formik={formik} options={organizations} /> : null}
                                </Col>
                                <Col></Col>
                            </Row>
                            <Row>
                                <Col>
                                    <MultiSelect keyField={"deliveryMechanisms"} label={"Select Delivery Mechanism"} formik={formik} options={deliveryOptions} defaultValue={loadDeliveryMechanisms} bindMethod={(val) => setLoadDeliveryMechanisms(val)} />
                                </Col>
                                <Col>
                                    {eventsToCheck.includes(formik?.values?.eventId)  ? <MultiSelect  required={false} keyField={"customGroupId"} label={"Custom Group"} formik={formik} options={groups} defaultValue={customGroupId} bindMethod={(val) => setCustomGroupId(val)} nodata={'No data found'} /> : null}
                                </Col>
                                <Col></Col>
                            </Row>
                            <Row>
                                <Col>
                                    <TextInput keyField={"title"} label={"Notification Title"} formik={formik} placeholder={"Title"} required={true} />
                                </Col>
                                <Col>
                                    {formik?.values?.deliveryMechanisms?.includes("email") ? <TextInput keyField={"emailSubject"} label={"Email Subject"} formik={formik} placeholder={"Subject"} required={true} /> : null}
                                </Col>
                                <Col></Col>
                            </Row>
                            <Row style={{ display: formik?.values?.deliveryMechanisms?.includes("push_notification") || formik?.values?.deliveryMechanisms?.includes("sms") ? "block" : "none" }}>
                                <Col>
                                    <TextArea keyField={"content"} label={"Content"} formik={formik} placeholder={"Content..."} />
                                </Col>
                                <Col></Col>
                            </Row>
                            <Row style={{ display: formik?.values?.deliveryMechanisms?.includes("email") ? "block" : "none" }}>
                                <Col>
                                    <TextEditor keyField={"emailContent"} formik={formik} label={"Email Content"} defaultValue={formik.values.emailContent} />
                                </Col>
                            </Row>

                            <div className="btn-wrap" style={{ display: "flex" }}>
                                <Button onClick={() => navigate("/app/manage-notifications")} variant="secondary" title="Cancel">
                                    Cancel
                                </Button>
                                <Button type="submit" isLoading={btnLoading}>
                                    Create Notification
                                </Button>
                            </div>
                        </>
                    )}
                </form>
            </div>
        </section>
    );
}

export default CreateNotification;
