import moment from "moment";
import { axios } from "../../../../lib/axios";

// --------------- response--------------------------------

const newPayload = ({ requestType, patientId, encounterId, text, document, status = "draft"  }) => {
    const currentDateTime = moment().format("YYYY-MM-DDTHH:mm:ss[Z]")
    return {
        resourceType: "ServiceRequest",
        requestType, //lab,referral,diagnostic
        status,
        intent: "order",
        priority: "routine",
        patientId,
        encounterId,
        locationReferenceId: "",
        time: currentDateTime,
        text,
        occurrenceDateTime: currentDateTime,
    }
}

export const createServiceRequest = (payload) => {
    return axios.post(`/servicerequest`, newPayload(payload));
};

export const editServiceRequest = ({ id, ...payload }) => {
    return axios.put(`/servicerequest/${id}`, { ...payload });
};

export const deleteServiceRequest = (serviceId) => {
    return axios.put(`/servicerequest/${serviceId}`, {
        active: false
    });
};

export const sendSFT = (payload) => {
    return axios.post(`/securefiletransfer`, payload);
};