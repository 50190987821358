import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { decryptData } from '../../EncryptDecrypt';
import Background from "../../../assets/images/search-icon.png"

const SearchBar = ({ list = [], filterData, encoded = false, placeholder = "Search...", page, setPage }) => {
    const [searchVal, setSearchVal] = useState("");
    const getName = (nameField) => {
        return typeof nameField === "string" ? nameField : nameField?.[0]?.text
    }
    const filter = () => {
        const data = encoded ?
            list?.filter((data) =>
                decryptData(getName(data?.name))?.toLowerCase()?.includes(searchVal) ||
                decryptData(data?.telecom?.[1]?.value)?.toLowerCase()?.includes(searchVal) ||
                decryptData(getName(data?.patientName))?.toLowerCase()?.includes(searchVal) ||
                decryptData(data?.message)?.toLowerCase()?.includes(searchVal) ||
                decryptData(data?.patientEmail)?.toLowerCase()?.includes(searchVal) ||
                decryptData(data?.documentName)?.toLowerCase()?.includes(searchVal) ||
                decryptData(data?.category?.[0]?.coding?.[0]?.display)?.toLowerCase()?.includes(searchVal) ||
                decryptData(data?.custodian?.display)?.toLowerCase()?.includes(searchVal) ||
                decryptData(data?.status)?.toLowerCase()?.includes(searchVal) ||
                decryptData(data?.author?.[0]?.display)?.toLowerCase()?.includes(searchVal) ||
                decryptData(data?.category?.[0]?.coding?.[0]?.display)?.toLowerCase()?.includes(searchVal) ||
                decryptData(data?.numPages)?.includes(searchVal) ||
                decryptData(data?.authenticator?.[0]?.display)?.toLowerCase()?.includes(searchVal) ||
                decryptData( data?.submittedBy?.coding?.[0]?.code === "fax" ?  data?.submittedBy?.coding?.[0]?.display : data?.submittedBy?.name)?.toLowerCase()?.includes(searchVal) ||
                decryptData(data?.subject?.name)?.toLowerCase()?.includes(searchVal)
            ) :
            list?.filter((data) =>
                getName(data?.name)?.toLowerCase()?.includes(searchVal) ||
                data?.telecom?.[1]?.value?.toLowerCase()?.includes(searchVal) ||
                data?.title?.toLowerCase()?.includes(searchVal) ||
                data?.display?.toLowerCase()?.includes(searchVal) ||
                data?.question?.toLowerCase()?.includes(searchVal) ||
                data?.name?.toLowerCase()?.includes(searchVal) ||
                data?.categoryName?.toLowerCase()?.includes(searchVal) ||
                data?.categoryType?.toLowerCase()?.includes(searchVal) ||
                data?.documentName?.toLowerCase()?.includes(searchVal) ||
                data?.category?.[0]?.coding?.[0]?.display?.toLowerCase()?.includes(searchVal) ||
                data?.custodian?.display?.toLowerCase()?.includes(searchVal) ||
                data?.status?.toLowerCase()?.includes(searchVal) || 
                data?.sender?.practitioner_name?.toLowerCase()?.includes(searchVal) ||
                data?.sender?.organization_name?.toLowerCase()?.includes(searchVal) ||
                data?.author?.[0]?.display?.toLowerCase()?.includes(searchVal) ||
                data?.category?.[0]?.coding?.[0]?.display?.toLowerCase()?.includes(searchVal) ||
                data?.numPages?.includes(searchVal) ||
                data?.authenticator?.[0]?.display?.toLowerCase()?.includes(searchVal) ||
                data?.submittedBy?.coding?.[0]?.code === "fax" ?  "Fax" : data?.submittedBy?.name?.toLowerCase()?.includes(searchVal) ||
                data?.subject?.display?.toLowerCase()?.includes(searchVal)
                
            )
        filterData(data);
    }
    useEffect(() => {
        filter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchVal, list])
    return (
        <Form className="search-wrap">
            <Form.Control
                type="search"
                placeholder={placeholder}
                className="me-2"
                aria-label="Search"
                style={{ backgroundImage: searchVal ? "none" : `url(${Background})` }}
                value={searchVal}
                onChange={(e) => {
                    page !== 0 && setPage(0);
                    setSearchVal(e.target.value.toLowerCase())
                }}
            />
        </Form>
    )
}

export default SearchBar