import { useQuery } from "react-query";
import { axios } from "../../lib/axios";
import { onError } from "./onError";

export function useAppointmentsRequestsQuery({
  onSuccess,
  practitionerId,
  status = "proposed",
  error = true,
  dashboard = false
}) {

  
  const fetchAppointments = async (nstatus) => {
    return axios.get(`appointment?practitionerId=${practitionerId}&status=${nstatus}`);
  };
  
  const fetchDashboardApps = async () => {
    let requestedRes = await fetchAppointments("proposed");
    let proposedRes= await fetchAppointments("booked");
    let reqApps = {};
    if (status!=="proposed" && status!=="booked"){
      reqApps = await fetchAppointments(status);
    }
    let data = { data : {proposed: proposedRes?.data,
                         requested: requestedRes?.data,
                        otherApps: reqApps?.data}} ;
    return data;

  }

  const fetchOrganizations = () => {
    if(dashboard){
      return fetchDashboardApps(); 
    }else{
      return axios.get(`appointment?practitionerId=${practitionerId}&status=${status}`);}
  };

  const queryOptions = {
    select: (data) => data.data,
    onSuccess,
    refetchInterval: 5000,
  };

  // Conditionally add onError to queryOptions
  if (error) {
    queryOptions.onError = onError;
  }

  const query = useQuery(["appointment", status], () => fetchOrganizations(), queryOptions);

  return { ...query };
}
