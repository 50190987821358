import React, { useState } from "react";
import { failed, success } from "../../common/Toastify";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { QuestionsFormikObj, } from "./Constant";
import { Tooltip } from "@mui/material";
import { ArrowBack, } from "@mui/icons-material";
import { createQuestion } from "../../api/Questionnaire";
import { QuestionForm } from "./QuestionForm";
import * as Yup from "yup";

const CreateQuestion = () => {
    const navigate = useNavigate();
    const [btnLoading, setBtnLoading] = useState(false);
    const [tagValue, setTagValue] = useState();

    let channelDataMap = [
        { id: Date.now(), text: "Video" },
        { id: Date.now() + 1, text: "Phone" }
    ];
    
    const formik = useFormik({
        ...QuestionsFormikObj,
        validationSchema: Yup.object({
            category: Yup.string().required('Please fill the Category field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
            question: Yup.string().required('Please fill the Question field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.').max(400, "Must be 400 characters or less"),
            answerType: Yup.string().required('Please fill the Answer Type field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
            note: Yup.string().when("answerType", (answerType, schema) => {
                if (answerType === "freeText") return schema.required('Please fill the Note field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.')
                return schema
            }),
            note: Yup.string().matches(/^$|^\S+.*/, 'Only blankspaces is not valid.').max(250, "Must be 250 characters or less"),
            required: Yup.boolean(),
            options: Yup.array().when("answerType", (answerType, schema) => {
                if (answerType !== "freeText" && tagValue !== "CHANNEL") return schema.of(
                    Yup.object().shape({
                        text: Yup.string().required('Please fill the answer field'),
                    })
                )
                if (tagValue == "CHANNEL") return schema.of(
                    Yup.object().shape({
                        text: Yup.string(),
                    })
                )
                return schema
            }),
        }),
        onSubmit: (values) => {            
            setBtnLoading(true);
            values.tag = tagValue;
            if (tagValue == 'CHANNEL' || tagValue == 'TELEPHONE' || tagValue == 'REASON' || tagValue == 'CALLER' || tagValue == 'LOCATION' || tagValue == 'RELATIONSHIP' || tagValue == 'SELFCALLING' || tagValue == 'CONSENT') {
                values.required = true;
            }

            if (values.tag == "CHANNEL") {
                values.options = channelDataMap
            } else {
                values.options = values.options;
            }            
            let payload = values;
            payload.category = values?.category?.split('%')?.[1];            
            createQuestion(payload)
                .then((res) => {
                    navigate("/app/manage-questions");
                    success(res.message);
                }).catch((res) => {
                    const errorMessage = res?.response?.data?.message || res?.response?.data?.error || res.message;
                    failed(errorMessage);
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                })
                //.catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                .finally(() => setBtnLoading(false));
        },
    });
    const handleQuestionTagValue = (value) => {
        const typeOfValue = typeof value;
        if (typeOfValue === 'string') {
            setTagValue(value);
        }
        if (typeOfValue === 'object') {
            navigate("/app/create-questions");
            return;
        }
    };

    return (
        <section className="common-listing">
            <div className="heading-wrap mb-3">
                <Tooltip title="Go back">
                    <div style={{ marginRight: "1rem", cursor: "pointer" }} onClick={() => navigate(-1)}>
                        <ArrowBack />
                    </div>
                </Tooltip>
            </div>
            <div className="custom-card p-4">
                <QuestionForm btnLoading={btnLoading} formik={formik} handleShow={() => navigate("/app/manage-questions")} isCreate={true} handleQuestionTagValue={handleQuestionTagValue} />
            </div>
        </section>
    );
}

export default CreateQuestion;
