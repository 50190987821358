import React, { useState } from "react";
import SearchBar from "../../common/components/SearchBar";
import { useNavigate } from "react-router-dom";
import CustomPagination from "../../common/components/Pagination/CustomPagination";
import Table from "react-bootstrap/Table";
import { Skeleton, TableCell, Tooltip } from "@mui/material";
import { decryptData } from "../../EncryptDecrypt";
import AppointmentImg from "../../../assets/images/appointment.svg";
import ViewIcon from "../../../assets/images/view.png";
import { ArrowDropDown, ArrowDropUp, CalendarMonthOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import moment from "moment";
import { useGetUsersByOrg } from "../../../hooks/ReactQueryHooks/useGetUsersByOrg";
import { EditIndividual } from "../../Admin/Individuals/edit-individual";
import BookAppointment from "../AppointmentRequests/BookAppointment";
import ViewAppointmentModalCalender from "./ViewAppointmentModalCalender";
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import { ActionTitle, CreateAppointmentTitle, CreatedTitle, ManagePractitionerTitle, NameTitle, NoDataAvailTitle, PhoneNumber, ViewAppointmentsTooltipTitle, ViewInfoTitle, ViewScheduleTitle } from "./Constants";
import { ViewDate } from "../../../utils/DateSupport";

function ListOfPractitioner() {
    const navigate = useNavigate();
    const [usersList, setUsersList] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [sortBy, setSortBy] = useState("AscName");
    const [appointmentModalShow, setAppointmentModalShow] = useState(false);
    const [filterData, setFilterData] = useState([]);
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations);
    const [listAppointmentsModalShow, setListAppointmentsModalShow] = useState(false);

    const { isLoading, isFetching, refetch } = useGetUsersByOrg({ onSuccess: setUsersList, orgId: adminOrgs?.[0]?.id, type: "Practitioner", })

    const handleShow = (data) => {
        setModalShow(modalShow ? false : data);
    };

    const [page, setPage] = useState(0);
    const rowsPerPage = 10;
    const totalRows = filterData.length;

    const handleSchedule = (data) => {
        navigate("/app/schedule", { state: { data } })
    }

    const sortFilterData = (sort_by) => {
        setSortBy(sort_by)
        switch (sort_by) {
            case "AscName":
                setFilterData(filterData?.sort((a, b) => (decryptData(a.name?.[0]?.text) > decryptData(b.name?.[0]?.text)) ? 1 : ((decryptData(b.name?.[0]?.text) > decryptData(a.name?.[0]?.text)) ? -1 : 0)))
                break;
            case "DscName":
                setFilterData(filterData?.sort((a, b) => (decryptData(a.name?.[0]?.text) < decryptData(b.name?.[0]?.text)) ? 1 : ((decryptData(b.name?.[0]?.text) < decryptData(a.name?.[0]?.text)) ? -1 : 0)))
                break;
            case "AscOrg":
                setFilterData(filterData?.sort((a, b) => (a.managingOrganization?.[0]?.display > b.managingOrganization?.[0]?.display) ? 1 : ((b.managingOrganization?.[0]?.display > a.managingOrganization?.[0]?.display) ? -1 : 0)))
                break;
            case "DscOrg":
                setFilterData(filterData?.sort((a, b) => (a.managingOrganization?.[0]?.display < b.managingOrganization?.[0]?.display) ? 1 : ((b.managingOrganization?.[0]?.display < a.managingOrganization?.[0]?.display) ? -1 : 0)))
                break;
            default:
                setFilterData(filterData)
                break;
        }
    }

    return (
        <section className="common-listing">

            {modalShow ?
                <EditIndividual
                    modalShow={modalShow}
                    handleShow={handleShow}
                    refreshTable={refetch}
                    isReadOnly={true}
                    inactive={false}
                    typeOfUser={"Practitioner"}
                /> : null}

            {appointmentModalShow
                ? <BookAppointment
                    modalShow={(appointmentModalShow)}
                    handleShow={() => setAppointmentModalShow(false)}
                /> : null}

            {listAppointmentsModalShow ? <ViewAppointmentModalCalender
                modalShow={(listAppointmentsModalShow)}
                handleShow={() => setListAppointmentsModalShow(false)} /> : null}


            <div className="heading-wrap">
                <div className="filter-row-blc d-flex flex-wrap">
                    <div className="filter-col d-flex align-items-center">
                        <SearchBar page={page} setPage={setPage} encoded={true} list={usersList} filterData={(data) => setFilterData(data)} />
                    </div>
                </div>
            </div>
            <div className="table-wrap">
                <Table responsive>
                    <thead>
                        <tr>
                            <th style={{ cursor: "pointer" }} onClick={() => sortFilterData(sortBy === "AscName" ? "DscName" : "AscName")}>{NameTitle}
                                {sortBy === "AscName" ? <span>
                                    <ArrowDropUp />
                                </span> : <span>
                                    <ArrowDropDown />
                                </span>}
                            </th>
                            <th>{PhoneNumber}</th>
                            <th>{CreatedTitle}</th>
                            <th
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                {ActionTitle}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading || isFetching ? (
                            [1, 2, 3, 4, 5, 6, 7]?.map((val) => (
                                <tr key={val}>
                                    <td>
                                        <Skeleton animation="wave" />
                                    </td>
                                    <td>
                                        <Skeleton animation="wave" />
                                    </td>
                                    <td>
                                        <Skeleton animation="wave" />
                                    </td>
                                    <td>
                                        <Skeleton animation="wave" />
                                    </td>
                                </tr>
                            ))
                        ) : filterData?.length > 0 ? (
                            filterData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((data) => {
                                const { name, telecom, id, createdAt } = data;
                                return (
                                    <tr key={id}>
                                        <td className="name-text" style={{ textTransform: 'capitalize' }}>{decryptData(name?.[0]?.text || "-")}</td>
                                        <td>{decryptData(telecom?.find((ele) => ele?.system === "phone")?.value || "-")}</td>
                                        <td>{ViewDate(createdAt) || "-"}</td>
                                        <td>
                                            <div className="action-wrap">
                                                <Tooltip title={ViewInfoTitle}>
                                                    <div onClick={() => handleShow(data, true)} className="view-btn">
                                                        <img src={ViewIcon} alt="View" />
                                                    </div>
                                                </Tooltip>
                                                <Tooltip title={ViewScheduleTitle}>
                                                    <div onClick={() => handleSchedule(data)} className="view-btn">
                                                        <CalendarMonthOutlined style={{ color: "#ffff" }} />
                                                    </div>
                                                </Tooltip>
                                                <Tooltip title={ViewAppointmentsTooltipTitle}>
                                                    <div onClick={() => setListAppointmentsModalShow(data)} className="view-btn">
                                                        <img src={AppointmentImg} width={"90%"} alt="View" />
                                                    </div>
                                                </Tooltip>
                                                <Tooltip title={CreateAppointmentTitle}>
                                                    <div onClick={() => setAppointmentModalShow(data)} className="view-btn">
                                                        <AddIcCallIcon style={{ color: "#ffff" }} />
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <TableCell colSpan={"5"} style={{ textAlign: "center" }}>
                                    {NoDataAvailTitle}
                                </TableCell>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            {filterData?.length > 0 ? <CustomPagination tableData={filterData} totalRows={totalRows} rowsPerPage={rowsPerPage} page={page} handlePage={(pageNo) => setPage(pageNo)} /> : null}
        </section>
    );
}

export default ListOfPractitioner;
