import { useQuery } from "react-query";
import { axios } from "../../lib/axios";
import { onError } from "./onError"

export function usePatientsBySOPQuery({ onSuccess, practitioner_id, org_id }) {

    const fetchOrganizations = () => {
        return axios.get(`patient/getBySop?practitioner_id=${practitioner_id}&org_id=${org_id}`);
    };
    const query = useQuery(
        ["patients-by-SOP", practitioner_id, org_id],
        () => fetchOrganizations(),
        {
            select: (data) => data.data,
            onSuccess,
            onError
        }
    );

    return { ...query };
}
