import * as Yup from "yup";

export const genderOptions = [
    { value: "male", name: "Male" },
    { value: "female", name: "Female" },
    { value: "other", name: "Other" },
];

export const designationOption = [
    { value: "nurse", name: "Nurse" },
    { value: "nursePractitioner", name: "Nurse Practitioner" },
    { value: "physician", name: "Physician" },
];

export const typeOptions = [
    { value: "Patient", name: "Patient" },
    // { value: "Related_Person", name: "Related Person" },
    { value: "Practitioner", name: "Practitioner" },
    { value: "Device", name: "Device" },
]
export const nameToVal = {
    "patients" : "Patient",
    "employees" : "Practitioner",
    "super-admin": "Super-Admin"
}
export const listTypeOptionsForAllOrgs = [
    { name: "All", value: "All" }, 
    { name: "Super-Admin", value: "Super-Admin" }
]

export const listTypeOptions = [
    { value: "All", name: "All" },
    { value: "Patient", name: "Patient" },
    { value: "RelatedPerson", name: "Related Person" },
    { value: "Practitioner", name: "Practitioner" },
    //{ value: "Device", name: "Device" },
]

export const createAccountOptions = [
    { value: "true", name: "Yes" },
    { value: "false", name: "No" },
]

export const activeOptions = [
    { value: true, name: "Active" },
    { value: false, name: "Inactive" },
]
export const activeNotOptionsDisabled = [
    { value: true, name: "Active" },
    { value: false, name: "Inactive", disabled: true },
]

export const FormikObjCreate = {
    initialValues: {
        related_to: "",
        first_name: "",
        last_name: "",
        date_of_birth: "",
        phone_number: "",
        ext: "",
        address: "",
        city: "",
        country: "",
        region: "",
        email: "",
        organization: "",
        gender: "",
        type: "",
        postalCode: "",
        role: "",
        create_account: "",
        countrylist: "",
        jurisdiction: [],
        relationship: "",
        general_practitioner: [],
        hcn: "",
        device_name: "",
        clinic_location: "",
        designation: "",
        license: "",
        qualification: "",
        home_phone_number: "",
    },
}
export const FormikObjEdit = {
    initialValues: {
        first_name: "",
        last_name: "",
        date_of_birth: "",
        phone_number: "",
        ext: "",
        address: "",
        city: "",
        country: "",
        region: "",
        email: "",
        organization: "",
        gender: "",
        postalCode: "",
        role: "",
        countrylist: "",
        jurisdiction: [],
        isPatient: false,
        userType: "",
        create_account: "",
        relationship: "",
        related_to: "",
        general_practitioner: [],
        hcn: "",
    },
}
export const FormikEditDeviceObj = {
    initialValues: {
        id: "",
        email: "",
        organization: "",
        device_name: "",
        clinic_location: "",
    },
    validationSchema: Yup.object({
        id: Yup.string(),
        email: Yup.string().email("Invalid email address").required("Required email field").matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
        organization: Yup.string().required("Required organization").matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
        device_name: Yup.string().required("Required device name field").matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
        clinic_location: Yup.string().required("Required clinic location field").matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
    }),
}
//Country List For Scope Of Country
export const countryList = [
    {
        name: "Canada",
        value: "CA",
    },
    {
        name: "United States",
        value: "US",
    }
]

//relations List For Scope Of Country
export const relationsList = [
    {
        name: "Father",
        value: "FATHER",
    },
    {
        name: "Mother",
        value: "MOTHER",
    },
    {
        name: "Son",
        value: "SON",
    },
    {
        name: "Daughter",
        value: "DAUGHTER",
    },
    {
        name: "Brother",
        value: "BROTHER",
    },
    {
        name: "Sister",
        value: "SISTER",
    },
    {
        name: "Spouse/Partner",
        value: "SPOUSE_PARTNER",
    },
    {
        name: "Adult Child",
        value: "ADULT_CHILD",
    },
    {
        name: "Grandparent",
        value: "GRANDPARENT",
    },
    {
        name: "Relative",
        value: "RELATIVE",
    },
    {
        name: "Caretaker",
        value: "CARETAKER",
    },
]

//Region List for Scope of Jurisdiction
export const usregions = [
    {
        name: "Alabama",
        value: "US-AL",
        id: "US-AL",
    },
    {
        name: "Alaska",
        value: "US-AK",
        id: "US-AK",
    },
    {
        name: "American Samoa",
        value: "US-AS",
        id: "US-AS",
    },
    {
        name: "Arizona",
        value: "US-AZ",
        id: "US-AZ",
    },
    {
        name: "Arkansas",
        value: "US-AR",
        id: "US-AR",
    },
    {
        name: "California",
        value: "US-CA",
        id: "US-CA",
    },
    {
        name: "Colorado",
        value: "US-CO",
        id: "US-CO",
    },
    {
        name: "Connecticut",
        value: "US-CT",
        id: "US-CT",
    },
    {
        name: "Delaware",
        value: "US-DE",
        id: "US-DE",
    },
    {
        name: "District of Columbia",
        value: "US-DC",
        id: "US-DC",
    },
    {
        name: "Micronesia",
        value: "US-FM",
        id: "US-FM",
    },
    {
        name: "Florida",
        value: "US-FL",
        id: "US-FL",
    },
    {
        name: "Georgia",
        value: "US-GA",
        id: "US-GA",
    },
    {
        name: "Guam",
        value: "US-GU",
        id: "US-GU",
    },
    {
        name: "Hawaii",
        value: "US-HI",
        id: "US-HI",
    },
    {
        name: "Idaho",
        value: "US-ID",
        id: "US-ID",
    },
    {
        name: "Illinois",
        value: "US-IL",
        id: "US-IL",
    },
    {
        name: "Indiana",
        value: "US-IN",
        id: "US-IN",
    },
    {
        name: "Iowa",
        value: "US-IA",
        id: "US-IA",
    },
    {
        name: "Kansas",
        value: "US-KS",
        id: "US-KS",
    },
    {
        name: "Kentucky",
        value: "US-KY",
        id: "US-KY",
    },
    {
        name: "Louisiana",
        value: "US-LA",
        id: "US-LA",
    },
    {
        name: "Maine",
        value: "US-ME",
        id: "US-ME",
    },
    {
        name: "Marshall Islands",
        value: "US-MH",
        id: "US-MH",
    },
    {
        name: "Maryland",
        value: "US-MD",
        id: "US-MD",
    },
    {
        name: "Massachusetts",
        value: "US-MA",
        id: "US-MA",
    },
    {
        name: "Michigan",
        value: "US-MI",
        id: "US-MI",
    },
    {
        name: "Minnesota",
        value: "US-MN",
        id: "US-MN",
    },
    {
        name: "Mississippi",
        value: "US-MS",
        id: "US-MS",
    },
    {
        name: "Missouri",
        value: "US-MO",
        id: "US-MO",
    },
    {
        name: "Montana",
        value: "US-MT",
        id: "US-MT",
    },
    {
        name: "Nebraska",
        value: "US-NE",
        id: "US-NE",
    },
    {
        name: "Nevada",
        value: "US-NV",
        id: "US-NV",
    },
    {
        name: "New Hampshire",
        value: "US-NH",
        id: "US-NH",
    },
    {
        name: "New Jersey",
        value: "US-NJ",
        id: "US-NJ",
    },
    {
        name: "New Mexico",
        value: "US-NM",
        id: "US-NM",
    },
    {
        name: "New York",
        value: "US-NY",
        id: "US-NY",
    },
    {
        name: "North Carolina",
        value: "US-NC",
        id: "US-NC",
    },
    {
        name: "North Dakota",
        value: "US-ND",
        id: "US-ND",
    },
    {
        name: "Northern Mariana Islands",
        value: "US-MP",
        id: "US-MP",
    },
    {
        name: "Ohio",
        value: "US-OH",
        id: "US-OH",
    },
    {
        name: "Oklahoma",
        value: "US-OK",
        id: "US-OK",
    },
    {
        name: "Oregon",
        value: "US-OR",
        id: "US-OR",
    },
    {
        name: "Palau",
        value: "US-PW",
        id: "US-PW",
    },
    {
        name: "Pennsylvania",
        value: "US-PA",
        id: "US-PA",
    },
    {
        name: "Puerto Rico",
        value: "PR",
        id: "PR",
    },
    {
        name: "Rhode Island",
        value: "US-RI",
        id: "US-RI",
    },
    {
        name: "South Carolina",
        value: "US-SC",
        id: "US-SC",
    },
    {
        name: "South Dakota",
        value: "US-SD",
        id: "US-SD",
    },
    {
        name: "Tennessee",
        value: "US-TN",
        id: "US-TN",
    },
    {
        name: "Texas",
        value: "US-TX",
        id: "US-TX",
    },
    {
        name: "Utah",
        value: "US-UT",
        id: "US-UT",
    },
    {
        name: "Vermont",
        value: "US-VT",
        id: "US-VT",
    },
    {
        name: "Virgin Islands",
        value: "US-VI",
        id: "US-VI",
    },
    {
        name: "Virginia",
        value: "US-VA",
        id: "US-VA",
    },
    {
        name: "Washington",
        value: "US-WA",
        id: "US-WA",
    },
    {
        name: "West Virginia",
        value: "US-WV",
        id: "US-WV",
    },
    {
        name: "Wisconsin",
        value: "US-WI",
        id: "US-WI",
    },
    {
        name: "Wyoming",
        value: "US-WY",
        id: "US-WY",
    },
]

export const caregions = [
    {
        name: "Alberta",
        value: "CA-AB",
        id: "CA-AB",
    },
    {
        name: "British Columbia",
        value: "CA-BC",
        id: "CA-BC",
    },
    {
        name: "Manitoba",
        value: "CA-MB",
        id: "CA-MB",
    },
    {
        name: "New Brunswick",
        value: "CA-NB",
        id: "CA-NB",
    },
    {
        name: "Newfoundland and Labrador",
        value: "CA-NL",
        id: "CA-NL",
    },
    {
        name: "Northwest Territories",
        value: "CA-NT",
        id: "CA-NT",
    },
    {
        name: "Nova Scotia",
        value: "CA-NS",
        id: "CA-NS",
    },
    {
        name: "Nunavut",
        value: "CA-NU",
        id: "CA-NU",
    },
    {
        name: "Ontario",
        value: "CA-ON",
        id: "CA-ON",
    },
    {
        name: "Prince Edward Island",
        value: "CA-PE",
        id: "CA-PE",
    },
    {
        name: "Quebec",
        value: "CA-QC",
        id: "CA-QC",
    },
    {
        name: "Saskatchewan",
        value: "CA-SK",
        id: "CA-SK",
    },
    {
        name: "Yukon",
        value: "CA-YT",
        id: "CA-YT",
    }
]
export const createBasicFields = [
    [{
        size: "3",
        fieldType: "TextInput",
        keyField: "first_name",
        label: "First Name",
        placeholder: "First Name",
    },
    {
        size: "3",
        fieldType: "TextInput",
        keyField: "last_name",
        label: "Last Name",
        placeholder: "Last Name",
    },
    {
        fieldType: "ReactDatePicker",
        keyField: "date_of_birth",
        label: "Date of Birth",
        placeholder: "Select Date",
        hasValue: "date_of_birth",
        displayAge: true,
    },
    {
        fieldType: "SelectFiled",
        keyField: "gender",
        label: "Select Gender",
        options: genderOptions,
    },
    ],
    [
        {
            size: "12",
            fieldType: "TextInput",
            keyField: "address",
            label: "Address",
            placeholder: "Address",
        }
     ],
    [
    {
        size: "6",
        fieldType: "CountrySelect",
        keyField: "country",
        label: "Country",
    },
    {
        size: "6",
        fieldType: "RegionSelect",
        keyField: "region",
    }
],
[
    {
        size: "6",
        fieldType: "TextInput",
        keyField: "city",
        label: "City",
        placeholder: "City",
    },
    {
        size: "6",
        fieldType: "postalCode",
    }
]
]

export const createBasicFieldsPractionerTwo = [
    [
        {
            fieldType: "TextInput",
            keyField: "license",
            label: "License Number",
            placeholder: "License Number",
            hideRequired: true
        },
        
        {
            fieldType: "TextInput",
            keyField: "qualification",
            label: "College",
            placeholder: "College",
            hideRequired: true
        },
        {
            fieldType: "SelectFiled",
            keyField: "designation",
            label: "Designation",
            options: designationOption,
            hideRequired: true
        },
        ],
]

export const editBasicFieldsPractionner = [
    [{
        fieldType: "TextInput",
        keyField: "first_name",
        label: "First Name",
        placeholder: "First Name",
    },
    {
        fieldType: "TextInput",
        keyField: "last_name",
        label: "Last Name",
        placeholder: "Last Name",
    },
    {
        fieldType: "SelectFiled",
        keyField: "designation",
        label: "Designation",
        options: designationOption,
        required: false,
    },
    {
        fieldType: "TextInput",
        keyField: "qualification",
        label: "College",
        placeholder: "College",
        required: false
    }],
    [
        {
            fieldType: "TextInput",
            keyField: "license",
            label: "License Number",
            placeholder: "License Number",
            required: false,
        },
        {
            fieldType: "ReactDatePicker",
            keyField: "date_of_birth",
            label: "Date of Birth",
            placeholder: "Select Date",
            hasValue: "date_of_birth",
            displayAge: true,
        },
        {
            fieldType: "SelectFiled",
            keyField: "gender",
            label: "Select Gender",
            options: genderOptions,
        }],
    [{
        fieldType: "CountrySelect",
        keyField: "country",
        label: "Country",
    },
    {
        fieldType: "RegionSelect",
        keyField: "region",
    },
    {
        fieldType: "TextInput",
        keyField: "city",
        label: "City",
        placeholder: "City",
    }],
    [{
        fieldType: "TextInput",
        keyField: "address",
        label: "Address",
        placeholder: "Address",
    },
    {
        fieldType: "postalCode",
    }, {}],
    [
        {
            type: "phone",
            fieldType: "TextInput",
            keyField: "phone_number",
            label: "Work Phone Number",           
            placeholder: "Work Phone Number",
            required: false,
        },
        {
            fieldType: "TextInput",
            keyField: "ext",
            label: "Ext",
            placeholder: "Ext",
            required: false,
        },
        {
            type: "phone",
            fieldType: "TextInput",
            keyField: "home_phone_number",            
            label: "Home Phone Number",
            placeholder: "Home Phone Number",
            required: false,
        },
    ]
]
export const editBasicFieldsPractionerRowOne = [
    [{
        fieldType: "TextInput",
        keyField: "first_name",
        label: "First Name",
        placeholder: "First Name",
    },
    {
        fieldType: "TextInput",
        keyField: "last_name",
        label: "Last Name",
        placeholder: "Last Name",
    },
    ],
    
]

export const editBasicFieldsPractionerRowThree = [
    [
        {
            size: "12",
            fieldType: "TextInput",
            keyField: "address",
            label: "Address",
            placeholder: "Address",
        },
    ],
    [
        {
            size: "6",
            fieldType: "CountrySelect",
            keyField: "country",
            label: "Country",
        },
        {
            size: "6",
            fieldType: "RegionSelect",
            keyField: "region",
        },
    ],
    [
        {   
            size: "5",
            fieldType: "TextInput",
            keyField: "city",
            label: "City",
            placeholder: "City",
        },
        {
            size: "3",
            fieldType: "postalCode",
        },
        {
            size: "4",
            type: "phone",
            fieldType: "TextInput",
            keyField: "home_phone_number",            
            label: "Home Phone Number",
            placeholder: "Home Phone Number",
            hideRequired: true,
        },
    ],
    [
        {
            size:"4",
            type: "phone",
            fieldType: "TextInput",
            keyField: "phone_number",
            label: "Work Phone Number",           
            placeholder: "Work Phone Number",
            hideRequired: true,
            
        },
        {   
            size: "2",
            fieldType: "TextInput",
            keyField: "ext",
            label: "Ext",
            placeholder: "Ext",
            hideRequired: true,
        },
        {  
            size: "6",
            fieldType: "TextInput",
            keyField: "email",
            label: "Email Address",
            disabled: true
        }
        
    ],
    
]
export const editBasicFieldsPractionerRowEight =[
    [,
    {
        fieldType: "TextInput",
        keyField: "qualification",
        label: "College",
        placeholder: "College",
        hideRequired: true,
    },
    {
        fieldType: "SelectFiled",
        keyField: "designation",
        label: "Designation",
        options: designationOption,
        hideRequired: true,
    }
    ]
]


export const editBasicFields = [
    [{
        fieldType: "TextInput",
        keyField: "first_name",
        label: "First Name",
        placeholder: "First Name",
    },
    {
        fieldType: "TextInput",
        keyField: "last_name",
        label: "Last Name",
        placeholder: "Last Name",
    }],
    [{
        fieldType: "ReactDatePicker",
        keyField: "date_of_birth",
        label: "Date of Birth",
        placeholder: "Select Date",
        hasValue: "date_of_birth",
        displayAge: true,
    },
    {
        fieldType: "SelectFiled",
        keyField: "gender",
        label: "Select Gender",
        options: genderOptions,
    },
    {
        size: "5",
        fieldType: "TextInput",
        keyField: "hcn",
        label: "HCN",
        placeholder: "HCN",
        hideRequired: true
    }
    ],
    [
        {
            size: "12",
            fieldType: "TextInput",
            keyField: "address",
            label: "Address",
            placeholder: "Address",
        }
     ],
    [
    {
        size: "6",
        fieldType: "CountrySelect",
        keyField: "country",
        label: "Country",
    },
    {
        size: "6",
        fieldType: "RegionSelect",
        keyField: "region",
    }
],
[
    {
        size: "6",
        fieldType: "TextInput",
        keyField: "city",
        label: "City",
        placeholder: "City",
    },
    {
        size: "6",
        fieldType: "postalCode",
    }
],
    [
    {
        fieldType: "TextInput",
        keyField: "phone_number",
        type: "phone",
        label: "Phone Number",
        hideRequired : true
    },
    {   
        size: "2",
        fieldType: "TextInput",
        keyField: "ext",
        type: "ext",
        label: "Ext.",
        hideRequired : true
    },
    {
        size: "6",
        fieldType: "TextInput",
        keyField: "email",
        label: "Email Address",
    },
    ],
]