import { createSlice } from "@reduxjs/toolkit";

export const loginSlice = createSlice({
    name: 'login',
    initialState: {
        isLoggedIn: false,
        user: {},
        refreshToken: null
    },
    reducers: {
        userlogin(state, action) {
            state.isLoggedIn = true;
            state.user = action.payload;
        },
        userlogout(state) {
            state.isLoggedIn = false;
            state.user = {}
            state.refreshToken = null
        },
        updateOrganizationLogo: (state, action) => {
            state.user.organizationLogo = action.payload;
        },
        updateOrganizationName: (state, action) => {
            state.user.organizations[0].name = action.payload;
        },
        setRefreshToken: (state, action) => {
            state.refreshToken = action.payload ?  action.payload : state.refreshToken
        },
    }
})

export const { userlogin, userlogout,updateOrganizationLogo,updateOrganizationName, setRefreshToken } = loginSlice.actions;
export default loginSlice.reducer;