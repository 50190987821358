import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { noDataAvailText } from "../Constants";
import { TableCell } from "@mui/material";
import Tab from "react-bootstrap/Tab";
import CustomPagination from "../../../common/components/Pagination/CustomPagination";
import { useSelector } from "react-redux";
import { FullPageSpinner } from "../../../common/Spinner/FullPageSpinner";
import { getPatientData } from "../../../api/Individual";
import {medicalHistoryCondition, medicalHistorySurgeries, medicalHistoryFamilyHistory, medicalHistoryObservations} from "../Constants"
import AddNote from "../../MedicalHistory.js/AddNote";
import ViewNote from "../../MedicalHistory.js/ViewNote";
import ConditionListng from "../../MedicalHistory.js/ConditionListng";
import SurgeriesScreeningListing from "../../MedicalHistory.js/SurgeriesScreeningListing";
import { getAllConditions, getAllFamilyHistory, getAllObservation, getAllSurgeryScreening } from "../../api/MedicalRequest";
import { useGetFamilyHistory, useGetMedicalConditionData, useGetMedicalSurgeryData, useGetObservation } from "../../../../hooks/ReactQueryHooks/useGetMedicalConditionData";
import FamilyHistoryListing from "../../MedicalHistory.js/FamilyHistoryListing";
import ObservationListing from "../../MedicalHistory.js/ObservationListing";


export function MedicalHistory({ patientId, medicalHistory }) {
  const [isListLoading, setIsListLoading] = useState(false)
  const [modalShow, setModalShow] = useState(false);
  const [viewModalShow, setViewModalShow] = useState(false);
  const [patientData, setPatientData] = useState([]);
  const [newNoteData, setNewNoteData] = useState(null)
  const [medicalHistoryData, setMedicalHistoryData] = useState([])
  const [medicationHistoryTitle, setMedicationHistoryTitle ] = useState([])
  const [page, setPage] = useState(0);
  const rowsPerPage = 5;
  const user = useSelector((state) => state?.auth?.user);

  const fetchConditionData = (data) => {
    setMedicalHistoryData(data);
  };
  const {refetchCondition } = useGetMedicalConditionData({ onSuccess : fetchConditionData, patientId})

  const fetchSurgeryData = (data) => {
    setMedicalHistoryData(data);
  };

  const {refetchSurgery } = useGetMedicalSurgeryData({ onSuccess : fetchSurgeryData, patientId})

  const fetchFamilyHistoryData = (data) => {
    setMedicalHistoryData(data);
  };

  const {refetchfamilyHistory } = useGetFamilyHistory({ onSuccess : fetchFamilyHistoryData, patientId})

  const fetchObservationData = (data) => {
    setMedicalHistoryData(data);
  };

  const {refetchObservation } = useGetObservation({ onSuccess : fetchObservationData, patientId})

  const handleShow = (data) => {
    setModalShow(modalShow ? false : data);
  };

  const handleViewNote = (data) =>{
    setViewModalShow(viewModalShow ? false : data);
  }
  
  const handleNewNoteData = (data) => {
    setNewNoteData(data)
  }

  useEffect(() => {
      getPatientData(patientId)
          .then((res) => {
              setPatientData(res?.result)
          })
  }, [])

  useEffect(() => {
    if(medicalHistory === "conditions") setMedicationHistoryTitle(medicalHistoryCondition)
    else if (medicalHistory === "surgeries/screening") setMedicationHistoryTitle(medicalHistorySurgeries)
    else if (medicalHistory === "familyHistory") setMedicationHistoryTitle(medicalHistoryFamilyHistory)
    else if(medicalHistory === "observations") setMedicationHistoryTitle(medicalHistoryObservations)
    getList()
    localStorage.setItem("medicalHistory", medicalHistory)
  }, [medicalHistory])

  const getList =()=>{
    if(medicalHistory === "surgeries/screening"){
      setNewNoteData(null)
      setIsListLoading(true)
      getAllSurgeryScreening(patientId).then((res) => {
        setMedicalHistoryData(res.data)
        setIsListLoading(false)
      })
    }
    else if(medicalHistory === "conditions"){
      setNewNoteData(null)
      setIsListLoading(true)
      getAllConditions(patientId).then((res) => {
        setMedicalHistoryData(res.data)
        setIsListLoading(false)
      })
    } 
    else if(medicalHistory === "familyHistory"){
      setNewNoteData(null)
      setIsListLoading(true)
      getAllFamilyHistory(patientId).then((res) => {
        setMedicalHistoryData(res.data)
        setIsListLoading(false)
      })
    }
    else if(medicalHistory === "observations"){
      setNewNoteData(null)
      setIsListLoading(true)
      getAllObservation(patientId).then((res) => {
        setMedicalHistoryData(res.data)
        setIsListLoading(false)
      })
    }
  }

  return (
    <>
      { isListLoading ? <FullPageSpinner loadingText={`Loading`}/> : null}
      { modalShow && <AddNote modalShow={modalShow}  handleShow={handleShow} refetchCondition={refetchCondition} refetchSurgery={refetchSurgery} refetchfamilyHistory={refetchfamilyHistory} refetchObservation={refetchObservation} handleNewNoteData={handleNewNoteData} />}
      { viewModalShow && <ViewNote modalShow={viewModalShow}  handleShow={handleViewNote} showNoteModal={handleShow} newNoteData={newNoteData}/>}
      <Tab.Pane eventKey="ninth">
      {medicalHistory && <div style={{marginTop: "8px"}}><span style={{color: "grey"}}>Medical History&gt;</span><span style={{fontWeight: "bold", color: "#0064a6"}}>{medicalHistory === "conditions" ? "Conditions" : medicalHistory === "surgeries/screening" ? "Surgeries/Screening" : medicalHistory === "familyHistory" ? "Family History" : medicalHistory === "observations" ? "Observations" : ""}</span></div>}
        <div className="table-wrap">
          <Table responsive>
            <thead>
              <tr>
              {medicationHistoryTitle?.map((title) => (
                  <th
                    key={title}
                    style={{
                      textAlign: title === "Edit" ? "center" : "auto",
                    }}
                  >
                    {title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {medicalHistoryData?.length > 0 ? (
                medicalHistoryData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((data) => {                    
                    return (
                      <>
                      {medicalHistory === "conditions" ? <ConditionListng data={data} handleViewNote={handleViewNote} handleShow={handleShow} patientData={patientData} practionerData={user} setNewNoteData={setNewNoteData}/> : ""}
                      {medicalHistory === "surgeries/screening" ? <SurgeriesScreeningListing data={data} handleViewNote={handleViewNote} handleShow={handleShow} patientData={patientData} practionerData={user} setNewNoteData={setNewNoteData}/> : ""}
                      {medicalHistory === "familyHistory" ? <FamilyHistoryListing data={data} handleViewNote={handleViewNote} handleShow={handleShow} patientData={patientData} practionerData={user} setNewNoteData={setNewNoteData}/> : ""}
                      {medicalHistory === "observations" ? <ObservationListing data={data} handleViewNote={handleViewNote} handleShow={handleShow} patientData={patientData} practionerData={user} setNewNoteData={setNewNoteData}/> : ""}
                      </> 
                    );
                  })
              ) : (
                <tr>
                  <TableCell
                    colSpan={medicationHistoryTitle.length}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {noDataAvailText}
                  </TableCell>
                </tr>
              )}
            </tbody>
          </Table>
          {medicalHistoryData?.length > 0 ? (
            <div>
              <CustomPagination
                tableData={medicalHistoryData}
                totalRows={medicalHistoryData?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                handlePage={(pageNo) => setPage(pageNo)}
              />
            </div>
          ) : null}
        </div>
      </Tab.Pane>
    </>
  );
}