import React, { useState } from "react";
import { Table } from "react-bootstrap";
import EditIcon from "../../../../assets/images/edit.png";
import { DiseaseHeadTitle} from "../Constants";
import { TableCell } from "@mui/material";
import Tab from "react-bootstrap/Tab";
import CustomPagination from "../../../common/components/Pagination/CustomPagination";

export function DiseaseList({ parentProps, setDiseaseEditModal }) {
    const [page, setPage] = useState(0);
    const rowsPerPage = 5;
    return (
        <>
            <div className="table-wrap">
                <Table responsive>
                    <thead>
                        <tr>
                            {DiseaseHeadTitle?.map(title => <th key={title} style={{
                                textAlign: title === "Edit" ? "center" : "auto"
                            }}>{title}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                    {parentProps?.diseases?.length > 0 ? parentProps?.diseases?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((data) => {
                            return <tr key={data?.Id}>
                                <td> <div className="text-wrap">{data?.diseaseName}</div></td>
                                <td>{data?.diseaseDesc}</td>
                                <td>
                                    <div className="action-wrap" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                        <div className="success-btn" style={{
                                            marginRight: "0"
                                        }} onClick={() => {setDiseaseEditModal(data)}} title="Edit">
                                            <img src={EditIcon} alt="Edit" />
                                        </div>
                                    </div>
                                </td>
                            </tr>;
                        }) : <tr>
                            <TableCell colSpan="4" style={{
                                textAlign: "center"
                            }}>
                                No Data Available
                            </TableCell>
                        </tr>}
                    </tbody>
                </Table>
                {parentProps?.diseases?.length > 0 ? <div>
                    <CustomPagination tableData={parentProps?.diseases} totalRows={parentProps?.diseases?.length} rowsPerPage={rowsPerPage} page={page} handlePage={pageNo => setPage(pageNo)} />
                </div> : null}
            </div>
        </>
    );
}
