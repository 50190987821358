import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { axios } from "../../lib/axios";
import { onError } from "./onError";

export function useProgramQuery({ onSuccess, isDefault = false, orgId = null, orgIds = null, programId=null }) {
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)
    const userType = useSelector((state) => state?.auth?.user?.['cognito:groups'])
    programId ? orgIds = null : orgIds = orgIds ? orgIds : userType.includes("Super-Admin") ? null : adminOrgs?.map(org => org.id); 

    const fetch = (orgIds = null) => {
        return orgId ? axios.post("program/get", { orgId }) 
            : orgIds ? axios.post("program/get", { orgIds }) 
                : isDefault ? axios.post("program/get", { isDefault }) 
                        : programId ? axios.post("program/get-program-patients", { id: programId }) : axios.post("program/get")
    }

    const query = useQuery(
        "programs",
        () => fetch(orgIds),
        {
            select: (data) => data.result,
            onSuccess,
            onError
        }
    );

    return { ...query };
}
