import { useQuery } from "react-query";
import { onError } from "./onError";
import { axios } from "../../lib/axios";
import moment from "moment";

export function usePatientMedicationQuery({ date, patientId, onSuccess }) {
    const fetch = (date, patientId) => {
        return axios.get(`medication/${patientId}?date=${moment(date).format("YYYY-MM-DD")}`)
    }

    const query = useQuery(["patient-medication", { status: "active", date, patientId }], () => fetch(date, patientId, onSuccess),
        {
            select: (data) => data?.data?.medication || [],
            onSuccess,
            onError
        }
    );

    return { ...query }
}