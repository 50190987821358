import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { useGetAppointmentNoteQuery } from "../../../hooks/ReactQueryHooks/useGetAppointmentNoteQuery";
import { FullPageSpinner } from "../../common/Spinner/FullPageSpinner";
import { decryptData } from "../../EncryptDecrypt";

export function NoteModal({ modalShow, handleShow }) {
    const [note, setNote] = useState("");

    const onSuccess = (data) => {
        setNote(data?.summary);
    };
    const { isLoading, isFetching } = useGetAppointmentNoteQuery({ onSuccess, appointmentId: modalShow })

    return (
        <>
            {isLoading || isFetching ? <FullPageSpinner /> : null}
            <Modal
                backdropClassName
                backdrop={'static'}
                size="lg" show={modalShow} onHide={handleShow}
                aria-labelledby="contained-modal-title-vcenter"
                centered className="custom-dialog">
                <Modal.Header closeButton className="border-0">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Session Note
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{decryptData(note) || "No note added in the Appointment !"}</p>
                </Modal.Body>
            </Modal>
        </>
    );
}
