import React, { useState } from "react";
import '../../components/login.css';
import Mail from '../../assets/images/mail.png';
import { Button } from "../common/Button";
import { Col, Row } from "react-bootstrap";
import TextInput from "../common/textfield/TextInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { forgotPassword } from "../api/AuthService";
import { failed, success } from "../common/Toastify";
import { useNavigate, useLocation } from "react-router-dom";

function ForgotPassword({ isExpired = false }) {
    const navigate = useNavigate();
    const location = useLocation();
    const [btnLoading, setBtnLoading] = useState(false);
    const formik = useFormik({
        initialValues: {
            email: location.state?.email || "",
        },
        validationSchema: Yup.object({
            email: Yup.string().email().required('Please Enter valid email').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        }),
        onSubmit: (values) => {
            setBtnLoading(true)
            forgotPassword({ ...values, isExpired }).then(({ data }) => {
                if (data.status === true) {
                    success(data.message, 10000);
                    navigate("/")
                } else {
                    failed(data.error);
                }
            }).catch(res => {
                failed(res?.response?.data?.error || res?.response?.data?.message || res?.message);
            }).finally(() => setBtnLoading(false));
        },
    });
    return (
        <form className="custom-form" onSubmit={formik.handleSubmit}>
            <Row>
                <Col>
                    <TextInput disabled={location.state?.email} icon={Mail} keyField={"email"}
                        label={location.state?.email
                            ? "Please submit to reset your expired password"
                            : "Please enter the email address associated with your account"}
                        formik={formik} placeholder={"example@gmail.com"} />
                </Col>
            </Row>
            <div className="btn-wrap" style={{ display: 'flex' }}>
                <Button type="submit" isLoading={btnLoading}>Submit</Button>
            </div>
        </form>
    );
}

export default ForgotPassword;