import { useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { decryptData } from "../../EncryptDecrypt";
import { Link, useNavigate } from 'react-router-dom';
import { useAppointmentsRequestsQuery } from "../../../hooks/ReactQueryHooks/useAppointmentsRequestsQuery";
import FullCalendarComp from "../../common/textfield/FullCalendar";
import CustomPagination from '../../common/components/Pagination/CustomPagination';
import { eventType, typeOptions, downloadTitle, generatePass  } from "../AppointmentRequests/Constants";
import moment from 'moment';
import { ViewAppointmentsTitle } from "./Constants";
import { Tooltip, TableCell } from '@mui/material';
import { CalendarMonth, Close, DescriptionOutlined, Done, ListAlt, VideocamOutlined , FilePresentOutlined} from '@mui/icons-material';
import { useFormik } from 'formik';
import Search from '../AppointmentRequests/Search';
import SelectFiled from '../../common/textfield/SelectFiled';
import { ViewAppointmentModal } from '../AppointmentRequests/ViewAppointmentModal';
import { failed, success } from '../../common/Toastify';
import { getSignature } from '../../Zoom/functions';
import { updateAppointmentRequest } from '../api/AppointmentRequests';
import  RescheduleAppointment  from './Reschedule';
import { NoteModal } from '../AppointmentRequests/NoteModal';

const ViewAppointmentModalCalender = ({ modalShow, handleShow }) => {
    const navigate = useNavigate()
    const [appointments, setAppointments] = useState([]);
    const practitionerId = modalShow?.id;
    const [calendarView, setCalendarView] = useState(true);
    const [filterData, setFilterData] = useState([]);
    const [showNoteModal, setShowNoteModal] = useState(false);
    const [showAppointment, setShowAppointment] = useState(false);
    const [scheduleModal, setScheduleModal] = useState(false);
    

    function handleAppointmentSuccess(data) {
        setAppointments(data);
    }

    const [page, setPage] = useState(0);
    const rowsPerPage = 10;
    const totalRows = filterData.length;

    const formik = useFormik({
        initialValues: {
            type: "booked",
        },
    });

    const handleTypeChange = (e) => {
        formik.setFieldValue("type", e.target.value);
    }

    const handleFilterData = (data) => {
        setFilterData(data);
        page !== 0 && setPage(0);
    };

    const handleUpdateRequest = async (data, status) => {
        const patientId = data?.participant?.filter((data) => data?.actor?.type === "Patient")?.[0]?.actor?.reference?.split("/")?.[1];
        const practitionerObj = data?.participant?.filter((data) => data?.actor?.type === "Practitioner")?.[0]?.actor;
        const practitionerId = practitionerObj.reference?.split("/")?.[1];
        let sessionToken;
        if (status === "booked") {                  
            let topic = practitionerObj.display.split(' ')[0].toLowerCase() + 'fonemed'; 
            topic = topic.replace(" ", "")+Math.round(new Date().getTime()/1000);
            let password = generatePass();
            await getSignature({ topic: topic, role: 0,  password:password})
                .then(async (res) => {
                    sessionToken = res.data.sessionToken;
                }).catch((error) => console.log("getsignature error", { error }))
        }
        await updateAppointmentRequest({ id: data?.id, status, patientId, practitionerId, sessionToken })
            .then((res) => { success(status === "booked" ? "Appointment booked!" : "Appointment cancelled!");})
            .catch((err) => failed(err?.response?.data?.message || err?.response?.data?.error || err?.message))
        setShowAppointment(false);
    };

    useAppointmentsRequestsQuery({
        onSuccess: handleAppointmentSuccess,
        practitionerId: practitionerId,
        status: formik?.values?.type,
        refetchOnWindowsFocus: false,
    });

    

    return (
        <section className="common-listing">
            {showAppointment ? <ViewAppointmentModal isProviderAppointment={true} setScheduleModal = {setScheduleModal} modalShow={showAppointment} handleShow={() => setShowAppointment(false)} handleConnect={() => null} handleCall={() => null} handleUpdateRequest={handleUpdateRequest} appointmentsList={appointments} /> : null}
            {scheduleModal ? <RescheduleAppointment modalShow={scheduleModal} handleShow={() => setScheduleModal(false)}  /> : null}
            {showNoteModal ? <NoteModal modalShow={showNoteModal} handleShow={() => setShowNoteModal(false)} /> : null}
            <Modal
                backdropClassName
                backdrop={'static'}
                size="xl" show={modalShow} onHide={handleShow}
                aria-labelledby="contained-modal-title-vcenter"
                centered className="custom-dialog">
                <Modal.Header closeButton className="border-0">
                    <Modal.Title id="contained-modal-title-vcenter">
                        {ViewAppointmentsTitle} <span style={{ textTransform: "capitalize" }}>{decryptData(modalShow?.name?.[0]?.text)}</span>
                    </Modal.Title>
                    <div style ={{paddingLeft: 20}} ><Tooltip title="Calendar View">
                        <CalendarMonth
                            onClick={() => setCalendarView(true)}
                            style={{ color: calendarView ? '#0055aa' : "#000000", marginRight: "1rem", cursor: "pointer" }}
                        />
                    </Tooltip>
                    <Tooltip title="List View">
                        <ListAlt onClick={() => setCalendarView(false)}
                            style={{ color: calendarView ? '#000000' : "#0055aa", cursor: "pointer" }}
                        />
                    </Tooltip>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    

                    <div className="v-full-calendar__indicators" style={{ display: "flex" }}>
                        {calendarView?
                         <>
                            {Object.keys(eventType).map((keyName) => (
                            <div className="v-full-calendar__indicators" key={keyName}>
                                <div className="v-full-calendar__indicator">
                                    <span style={{ background: eventType?.[keyName]?.backgroundColor }}></span>
                                    {eventType?.[keyName]?.name}
                                </div>
                            </div>
                        ))}
                         </> : <Search list={appointments} filterData={handleFilterData} /> }
                        <form className="common-form border-fields dropdown-filter-rw d-flex align-items-center">
                            <SelectFiled
                                keyField={"type"}
                                label={"Type"}
                                formik={formik}
                                options={typeOptions}
                                onChange={handleTypeChange}
                            />
                        </form>
                    </div>
                    
                    <br />


                    {calendarView ? 
                    <FullCalendarComp eventsList={
                        appointments.map((appointmentData) => ({
                            id: appointmentData.id,
                            title: appointmentData.description,
                            date: moment(appointmentData?.requestedPeriod?.[0]?.start).format("YYYY-MM-DD HH:mm"),
                            startTime: moment(appointmentData?.requestedPeriod?.[0]?.start).format("YYYY-MM-DD HH:mm"),
                            endTime: moment(appointmentData?.requestedPeriod?.[0]?.end).format("YYYY-MM-DD HH:mm"),
                            backgroundColor: eventType?.[appointmentData.status]?.backgroundColor,
                            borderColor: eventType?.[appointmentData.status]?.backgroundColor,
                            textColor: eventType?.[appointmentData.status]?.textColor,

                        }))
                    }
                        eventDisplay="block"
                        eventClick={(e) => setShowAppointment(e.event.id)}
                    />
                    :
                <>
                    <div className="table-wrap">
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Requests ID</th>
                                    <th>Patients Name</th>
                                    <th>Reason</th>
                                    <th>Appointment Time</th>
                                    <th>Attachments</th>
                                    {formik?.values?.type !== "cancelled" ?
                                        <th style={{ textAlign: "center" }}>Action</th> : null}
                                </tr>
                            </thead>
                            <tbody>
                                {filterData?.length > 0 ? (
                                    filterData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((data) => {
                                        const name = data?.participant.filter((data) => data?.actor?.type === "Patient")?.[0]?.actor?.display
                                        return (
                                            <tr onClick={() => setShowAppointment(data?.id)} key={data?.id}>
                                                <td className="name-text">{data?.id}</td>
                                                <td><span style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => navigate("/app/patients-details", { state: { id: data?.patientId, name: name } })}>{name}</span></td>
                                                <td>{data?.description}</td>
                                                <td>{`${moment(data?.requestedPeriod?.[0]?.start).format("YYYY-MM-DD HH:mm")} - ${moment(data?.requestedPeriod?.[0]?.end).format("HH:mm")}`}</td>
                                                <td>
                                                  {data?.document?.length > 0 &&
                                                    data?.document.map((pdfUrl, index) => (
                                                      <Tooltip key={index} title={downloadTitle}>
                                                        <Link
                                                          to={pdfUrl?.url}
                                                          variant="primary"
                                                          className="view-btn"
                                                        >
                                                          <FilePresentOutlined />
                                                        </Link>
                                                      </Tooltip>
                                                    ))}
                                                  {data?.document?.url && (
                                                    <Tooltip title={downloadTitle}>
                                                      <Link
                                                        to={data?.document?.url}
                                                        variant="primary"
                                                        className="view-btn"
                                                      >
                                                        <FilePresentOutlined />
                                                      </Link>
                                                    </Tooltip>
                                                  )}
                                                  {!data?.document?.length > 0 &&
                                                    !data?.document?.url && <div>-</div>}
                                                </td>
                                                {formik?.values?.type !== "cancelled" ?
                                                    <td onClick={(e) => { e.stopPropagation(); }}>
                                                        <div className="action-wrap">
                                                            {formik?.values?.type === "proposed" || data.status === "proposed" ?
                                                                data?.isFromWeb ?
                                                                    <span>Patient haven't accepted</span>
                                                                    : <>
                                                                        <Tooltip title={"Accept"}>
                                                                            <Link to="" variant="primary" onClick={() => handleUpdateRequest(data, "booked")} className="success-btn">
                                                                                <Done style={{ color: "white" }} />
                                                                            </Link>
                                                                        </Tooltip>
                                                                        <Tooltip title={"Reject"}>
                                                                            <Link to="" variant="primary" onClick={() => handleUpdateRequest(data, "cancelled")} className="delete-btn">
                                                                                <Close style={{ color: "white" }} />
                                                                            </Link>
                                                                        </Tooltip>
                                                                    </>

                                                                : null}
                                                            {formik?.values?.type === "booked" || data.status === "booked" ?
                                                                null
                                                                : null}
                                                                
                                                            {formik?.values?.type === "completed" || data.status === "completed" ?
                                                                <Tooltip title={"Session Note"}>
                                                                    <Link to="" variant="primary" onClick={(e) => setShowNoteModal(data.id)} className="view-btn">
                                                                        <DescriptionOutlined sx={{ color: "white" }} />
                                                                    </Link>
                                                                </Tooltip>
                                                                : null}
                                                        </div>
                                                    </td> : null}
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <TableCell colSpan="5" style={{ textAlign: "center" }}>
                                            No Data Available
                                        </TableCell>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                    {filterData?.length > 0
                        ? <CustomPagination tableData={filterData} totalRows={totalRows} rowsPerPage={rowsPerPage} page={page}
                            handlePage={(pageNo) => setPage(pageNo)} />
                        : null}
                </>}
                </Modal.Body>

            </Modal>
        </section>

    )
}

export default ViewAppointmentModalCalender;