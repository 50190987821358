import { axios } from "../../../lib/axios";

export const createOrganization = (data, parentOrg, deviceUnit, orgLogoURL, operationTiming, sft, appointmentAutoAcceptance) => {
    let newData = {
        "resourceType": "Organization",
        "text": {
            "status": "generated"
        },
        "name": data.name,
        "alias": [
            "HL7 International"
        ],
        "telecom": [
            {
                "system": "phone",
                "value": data.phone_number
            },
            {
                "system": "email",
                "value": data.email?.toLowerCase()
            }
        ],
        "address": [
            {
                "line": [data.address],
                "state": data.state,
                "postalCode": data.postalCode.toUpperCase(),
                "country": data.country,
                "city": data.city,
            }
        ],
        "partOf": parentOrg ? {
            "reference": `Organization/${parentOrg?.id}`,
            "type": "Organization",
            "display": parentOrg?.name,
        } : null,
        "isWaitingRoom": data.isWaitingRoom,
        "appointmentCancellationTime": {
            "unit": `${data?.appointment_cancel_time}`,
            "value": "hour"
        },
        description: data?.description,
        deviceUnit,
        orgLogoURL,
        hoursOfOperation: data.isWaitingRoom && data?.hoursOfOperation ? true : false,
        operationTiming,
        sft,
        mfaSettings: {
            enabled: true
        },
        surveySetting: {
            satisfactionSurvey: data?.satisfactionSurvey,
            survey: data?.survey
        },
        appointmentAutoAcceptance
    }
    return axios.post('organization', newData)
};

export const editOrganization = (data, orgName, orgId, deviceUnit, orgLogoURL = undefined, operationTiming, sft, appointmentAutoAcceptance, mfaSettings) => {

    let newData = {
        "id": data.id,
        "active": data.active,
        "resourceType": "Organization",
        "text": {
            "status": "generated"
        },
        "name": data.name,
        "alias": [
            "HL7 International"
        ],
        "telecom": [
            {
                "system": "phone",
                "value": data.phone_number
            },
            {
                "system": "email",
                "value": data.email?.toLowerCase()
            }
        ],
        "address": [
            {
                "line": [data.address],
                "state": data.state,
                "postalCode": data.postalCode.toUpperCase(),
                "country": data.country,
                "city": data.city,
            }
        ],
        "partOf": {
            "reference": `Organization/${orgId}`,
            "type": "Organization",
            "display": orgName,
        },
        "isWaitingRoom": data.isWaitingRoom,
        "appointmentCancellationTime": {
            "unit": `${data?.appointment_cancel_time}`,
            "value": "hour"
        },
        description: data?.description,
        deviceUnit,
        orgLogoURL,
        sft,
        mfaSettings,

        hoursOfOperation: data.isWaitingRoom && data?.hoursOfOperation ? true : false,
        operationTiming,
        surveySetting: {
            satisfactionSurvey: data?.satisfactionSurvey,
            survey: data?.survey
        },
        appointmentAutoAcceptance
    }

    return axios.put(`organization/${data.id}`, newData)
};

export const getOrganization = (id = null, active = null) => {
    if (active == "false") {
        return axios.get(id ? `organization/${id}?active=${active}` : `organization?active=${active}`)
    }
    else {
        return axios.get(id ? `organization/${id}` : "organization")
    }
};

export const getOrganizationChild = (id = null) => {
    return axios.get(`organization?orgId=${id}&onlyChild=${true}`)
};


// ---------------discover/organization--------------------------------
export const createDiscover = (payload) => {
    return axios.post(`/discover/organization`, payload);
};
export const editDiscover = (orgID, payload) => {
    return axios.put(`discover/organization/${orgID}`, payload);
};