import { useQuery } from "react-query";
import { axios } from "../../lib/axios";
import { onError } from "./onError";

export function useQuestionnaireSortingQuery({ orgID, onSuccess, ...props }) {
    const fetch = () => {
        return axios.get(orgID == 'All' ? "/questionnaire?purpose=questionnaire" : `questionnaire?purpose=questionnaire&orgId=${orgID}`);
    }
    const query = useQuery(
        ["questionnaires-sorting-list",orgID],
        fetch,
        {
            select: (data) => data.data,
            onError,
            onSuccess,
            ...props
        }
    );
    return { ...query };
}
