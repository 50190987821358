import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap';
import TextInput from '../../common/textfield/TextInput';
import SelectFiled from '../../common/textfield/SelectFiled';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { createQuestionnaire } from '../../api/Questionnaire';
import { failed, success } from '../../common/Toastify';
import { SurveyFormikObj, answerTypeOptions, purposeOption } from './Constants';
import { Add, Remove } from '@mui/icons-material';
import { Button } from '../../common/Button';

const iconStyle = {
  width: "35px",
  height: "35px",
  borderRadius: "10px",
  backgroundColor: "rgba(0, 93, 168, 0.12)",
  textAlign: "center",
  display: 'flex',
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
}

const CreateCopySurvey = ({ modalData, handleHide, refreshTable }) => {
  const navigate = useNavigate();
  const [btnLoading, setBtnLoading] = useState(false);

  const formik = useFormik({
    ...SurveyFormikObj,
    onSubmit: async (values) => {
      setBtnLoading(true);
      const questions = values?.question?.map((row, index) => ({
        text: row.text,
        type: row.type,
        highRatingText: row.highRateText,
        lowRatingText: row.lowRateText,
        required: row.required,
      }));
      const requestBody = {
        title: values.title,
        keyString: values.keyString,
        question: questions,
        isDefault: true,
        // purpose: values?.purpose,
        purpose: "survey-appointment",
        description: values.description,
        status: 'active'
      };
      createQuestionnaire(requestBody).then((res) => {
        refreshTable();
        handleHide();
        success(res.message);
      }).catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
        .finally(() => setBtnLoading(false));
    },
  });

  const addRow = () => {
    const newRow = { id: Date.now(), text: "", type: "stars", highRateText: "", lowRateText: "", required: false, checked: "" };
    formik.setFieldValue("question", [...formik.values.question, newRow])
  };


  const removeRow = (index) => {
    const updatedQuestions = [...formik.values.question];
    updatedQuestions.splice(index, 1);
    formik.setFieldValue("question", updatedQuestions);
  };


  const handleAnswerTypeChange = (index, newValue) => {
    formik.setFieldValue(`question.[${index}].type`, newValue);
  };

  const handlePurposeChange = (e) => {
    formik.setFieldValue('purpose', e.target.value);
  }

  useEffect(() => {
    if (modalData?.id) {
      formik.setFieldValue("title", modalData?.title || "");
      formik.setFieldValue("description", modalData?.description || "");
      formik.setFieldValue("question", modalData?.item?.map((question) => question) || "");
      formik.setFieldValue("purpose", modalData?.purpose || "");
    }
  }, [modalData]);

  return (
    <Modal
      show={modalData}
      onHide={handleHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="custom-dialog"
      backdropClassName
      backdrop={"static"}
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title id="contained-modal-title-vcenter">
          Create Survey
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          className="common-form border-fields"
          onSubmit={formik.handleSubmit}
        >
          <Row>
            <Col>
              <TextInput
                keyField={"title"}
                label={"Survey Message"}
                formik={formik}
                placeholder={"Write Message"}
              />
            </Col>
            <Col>
              <SelectFiled
                keyField={"purpose"}
                label={"Purpose"}
                formik={formik}
                options={purposeOption}
                readOnly={true}
              // onChange={handlePurposeChange}
              />
            </Col>
          </Row>
          <hr />
          {formik.values.question?.map((row, index) => (
            <div key={row.id}>
              <Row>
                <Col style={{ marginBottom: "-1.5rem" }}>
                  <TextInput
                    keyField={`question[${index}].text`}
                    label={"Survey Question"}
                    formik={formik}
                    placeholder={"Write Here"}
                    isError={
                      formik?.touched?.question?.[index]?.text &&
                      formik?.errors?.question?.[index]?.text
                    }
                  />
                </Col>
                <Col
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                    marginBottom: "-1.5rem",
                  }}
                >
                  <SelectFiled
                    keyField={`question.[${index}].type`}
                    label={"Answer Type"}
                    formik={formik}
                    options={answerTypeOptions}
                    onChange={(e) =>
                      handleAnswerTypeChange(index, e.target.value)
                    }
                    isError={
                      formik?.touched?.question?.[index]?.type &&
                      formik?.errors?.question?.[index]?.type
                    }
                  />
                  {index > 0 && (
                    <div style={iconStyle}>
                      <Remove
                        style={{
                          width: "inherit",
                          height: "inherit",
                          color: "rgba(0, 93, 168, 1)",
                        }}
                        onClick={() => removeRow(index)}
                      />
                    </div>
                  )}
                  {index === 0 && (
                    <div style={iconStyle}>
                      <Add
                        style={{
                          width: "inherit",
                          height: "inherit",
                          color: "rgba(0, 93, 168, 1)",
                        }}
                        onClick={addRow}
                      />
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                {" "}
                <Col style={{ marginBottom: "1.5rem" }}> </Col>{" "}
              </Row>
              <Row>
                {formik.values.question[index].type !== "freeText" && (
                  <>
                    <Col style={{ marginBottom: "0rem" }}>
                      <TextInput
                        keyField={`question[${index}].highRateText`}
                        label={"High Rating Text"}
                        formik={formik}
                        placeholder={"Write Here"}
                        required={false}
                      />
                    </Col>
                    <Col style={{ marginBottom: "0rem" }}>
                      <TextInput
                        keyField={`question[${index}].lowRateText`}
                        label={"Low Rating Text"}
                        formik={formik}
                        placeholder={"Write Here"}
                        required={false}
                      />
                    </Col>
                  </>
                )}
                <Col style={{ marginBottom: "0rem" }}>
                  <div
                    className="field-wrap"
                    style={{
                      margin: 0,
                      display: "flex",
                      height: "100%",
                      alignItems: "center",
                    }}
                  >
                    <label
                      className="custom-checkbox"
                      style={{ margin: 0, width: "fit-content" }}
                    >
                      Is Mandatory
                      <input
                        style={{ width: "auto" }}
                        keyField={`required-${index}`}
                        type="checkbox"
                        checked={formik.values?.question[index].required}
                        onChange={() => {
                          formik.setFieldValue(
                            `question[${index}].required`,
                            !formik.values?.question[index].required
                          );
                        }}
                      />
                      <span
                        keyField={`checked-${index}`}
                        className="checkmark"
                      ></span>
                    </label>
                  </div>
                </Col>
                <Col></Col>
              </Row>
            </div>
          ))}
          <hr />
          <Row>
            <Col>
              <TextInput
                keyField={"description"}
                label={"Thank You Message"}
                formik={formik}
                placeholder={"Write Message"}
              />
            </Col>
            <Col></Col>
          </Row>
          <div className="btn-wrap" style={{ display: "flex" }}>
            <Button
              onClick={() => handleHide()}
              variant="secondary"
              title="Cancel"
            >
              Cancel
            </Button>

            <Button type="submit" isLoading={btnLoading}>
              Create
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default CreateCopySurvey