import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import { Button } from "../../common/Button";
import { failed, success } from "../../common/Toastify";
import TextInput from "../../common/textfield/TextInput";
import TextArea from "../../common/textfield/TextArea";
import Radio from "../../common/textfield/Radio";
import { editScheduleCategory } from "../../api/ScheduleCategory";
import SelectFiled from "../../common/textfield/SelectFiled";
import { timeSlot, FormikObj } from "./Constants"

// organization detail dialog
export function EditSchedule({ modalShow, handleShow, refreshTable }) {
    const [btnLoading, setBtnLoading] = useState(false);

    const handletimeSlot = (event) => {
        const value = event?.target?.value;
        formik.setFieldValue("timeSlot", value);
    };

    const handleRadioChange = (val) => {
        formik.setFieldValue("public", (val) ? val === "true" : false);
    };

    const formik = useFormik({
        ...FormikObj,
        onSubmit: (values) => {
            setBtnLoading(true);
            editScheduleCategory(values)
                .then((res) => {
                    if (res.status === true) {
                        success(res.message);
                        handleShow();
                        refreshTable();
                    } else {
                        failed(res.error);
                    }
                })
                .catch((res) => {
                    failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                })
                .finally(() => setBtnLoading(false));
        },
    });

    useEffect(() => {
        if (modalShow?.id) {
            formik.setFieldValue("organization", modalShow?.organizationName || "");
            formik.setFieldValue("name", modalShow?.name.trim() || "");
            formik.setFieldValue("id", modalShow?.id || "");
            formik.setFieldValue("description", modalShow?.description || "");
            formik.setFieldValue("timeSlot", modalShow?.timeSlot?.value || "");
            formik.setFieldValue("public", modalShow?.public);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalShow]);

    return (
        <Modal
            backdropClassName
            backdrop={'static'}
            size="lg" show={modalShow} onHide={handleShow}
            aria-labelledby="contained-modal-title-vcenter"
            centered className="custom-dialog">
            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">Schedule Category Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="common-form border-fields" onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col>
                            <TextInput keyField={"organization"} label={"Organization"} formik={formik} disabled={true} />
                        </Col>
                        <Col>
                            <TextInput keyField={"name"} label={"Name"} formik={formik} placeholder={"Name"} />
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col>
                            <SelectFiled keyField={"timeSlot"} label={"Select Time Slot"} formik={formik} options={timeSlot} onChange={handletimeSlot} />
                        </Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextArea keyField={"description"} label={"Description"} formik={formik} placeholder={"Description..."} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label htmlFor="public">Visible to patients? <span style={{ color: "red" }}> *</span> </label>
                            <div style={{display: "flex", justifyContent:"centre", flexDirection: "row"}}>  
                                <Row style={{marginLeft : "-10px" , marginRight: "5px"}}><Radio keyField={"public"} name="public" radioLabel={"Yes"} value={true} isChecked={formik.values.public == null || formik.values.public} formik={formik} handleChange={handleRadioChange}  /></Row>
                                <Row style={{marginLeft : "-10px" , marginRight: "5px"}}><Radio keyField={"public"} name="public" radioLabel={"No"} value={false} isChecked={formik.values.public != null && !formik.values.public} formik={formik} handleChange={handleRadioChange}  /></Row>
                            </div>
                        </Col>
                        <Col></Col>
                        <Col></Col>
                    </Row>
                    <div className="btn-wrap" style={{ display: "flex" }}>
                        <Button onClick={handleShow} variant="secondary" title="Cancel">
                            Cancel
                        </Button>
                        <Button type="submit" isLoading={btnLoading}>
                            Update
                        </Button>
                    </div>
                </form>

            </Modal.Body>
        </Modal>
    );
}
