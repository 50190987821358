import { useQuery } from "react-query";
import { axios } from "../../lib/axios";
import { onError } from "./onError"

export function useUsersQuery({ onSuccess, queryParams = null, ...props }) {

    const fetch = () => {
        return queryParams ? queryParams.includes("getUserWithSubOrg=true") ? axios.get(`/getUserWithSubOrg?${queryParams}`) : axios.get(`/users?${queryParams}`) : axios.get(`/users`)
    };
    const query = useQuery(
        "users",
        () => fetch(),
        {
            select: (data) => data.data,
            onSuccess,
            onError,
            ...props
        }
    );

    return { ...query };
}
