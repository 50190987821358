import { CircularProgress } from "@mui/material";

export const FullPageSpinner = ({ loadingText = "loading" }) => {
    const style = {
        height: "100vh",
        width: "100vw",
        position: "fixed",
        top: "0",
        left: "0",
        zIndex: "99999",
        display: "flex",
        backgroundColor: "rgb(0 0 0 / 83%)",
        color: "#fff",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    }
    return (
        <div style={style}>
            <CircularProgress size={'5rem'} thickness={4} />
            <span style={{ textTransform: "capitalize", fontSize: "2rem" }}>{loadingText}</span>
        </div >
    );
};
