import { axios } from "../../../lib/axios";

export const createGroup = (data) => {
    return axios.post("group", data)
}

export const editGroup = (data) => {
    return axios.put(`group`, data)
}

export const getGroup = (groupId) => {
    return axios.get(`group?groupId=${groupId}`)
}
