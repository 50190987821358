import React, { useState,  } from "react";
import SearchBar from "../../common/components/SearchBar";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import CustomTable from "../../common/table/CustomTable";
import { PatientsAssignedDataFields, PatientsAssignedHeadCells,dashboardPatientsAssignedDataFields,dashboardPatientsAssignedHeadCells } from "./Constants";
import { useGetPatientByScope } from "../../../hooks/AppsReactQueryHooks/useGetPatientByScope";
import { ButtonGroup, Button } from "react-bootstrap";
import PatientSelection from "../../Admin/Dashboard/PatientSelection";
import {Row, Col} from "react-bootstrap";
import TextInput from "../../common/textfield/TextInput";
import { useFormik } from "formik";
import { searchPatient } from "../api/Patient";
import { patientSelectionFormikObject } from "../../Admin/Dashboard/Constant";
import { DateSelectorWithoutFormik } from "../../common/textfield/DateSelector";
import moment from "moment";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { failed } from "../../common/Toastify";


function PatientsAssigned({dashboard = false}) {
    const { isLoading, isFetching } = useGetPatientByScope({ onSuccess: (data) => { setPatientsAssigned(data) } })
    const [filterData, setFilterData] = useState([]);
    const [searchData, setSearchData]= useState([]);
    const [btnActive, setBtnActive] = useState('assigned');
    const [btnLoading, setBtnLoading] = useState(false);
    const [page, setPage] = useState(0);
    const navigate = useNavigate();
    const [patientsAssigned, setPatientsAssigned] = useState([]);
    const orgId = useSelector((state) => state?.auth?.user?.organizations?.[0]?.id);

    const handleBtnChange = (val) => {
        setBtnActive(val);
    }

    const handleDateChange = (val) => {
        const isValidDate = moment(val, 'MMM-DD-YYYY').isValid()
      if(isValidDate){
        formik.setFieldValue("birthDate", val.format("YYYY-MM-DD"));
      }else{
        formik.setFieldError('birthDate', 'Invalid date format')
      }
    };

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            birthDate: "",
            healthCardNumber: ""
        },
        ...patientSelectionFormikObject,
        onSubmit: (values) => {
          setBtnLoading(true);
          searchPatient({ ...values, org_id: orgId })
            .then((res) => {
              if (res.status === true) {
                setSearchData(res?.result);
              } else {
                setBtnLoading(false)
                failed(res.error);
              }
            })
            .catch((res) => {
                failed(
                    res?.response?.data?.message ||
                      res?.response?.data?.error ||
                      res.message
                  );
            })
            .finally(() => setBtnLoading(false));
        },
      });

    const handleFilterData = (data) => {
        setFilterData(data)
    }

    const handleView = (data) => {
        navigate("/app/patients-details", { state: { id: data?.id, name: data?.name[0]?.text, email: data?.telecom.find((element) => element.system == "email").value} })
    };

    const handleSearchPatient = ()=>{
        !btnLoading && formik.handleSubmit()
      }

    const selectedUserAction = ({ action, data }) => {
        switch (action) {
            case "view":
                handleView(data);
                break;
            default:
                return null;
        }
    };
    
    return (
        <section className={dashboard? "dashboard patient-wrap" : "common-listing"}>
            {dashboard && <div className="dash-buttons">
                <ButtonGroup style={{maxHeight:"53px",}}>
                    <Button
                        variant="secondary"
                        active={btnActive === "assigned"}
                        onClick = {() =>  handleBtnChange("assigned")}
                        >Patients Assigned ({filterData.length})
                    </Button>
                    <Button 
                        variant="secondary"
                        active={btnActive === "search"}
                        onClick = {() => handleBtnChange("search")}
                        >Search All Patients
                    </Button>
                </ButtonGroup>
                {btnActive==="assigned" && <div className="search-block"><SearchBar page={page} setPage={setPage} list={patientsAssigned} encoded={true} filterData={handleFilterData} /></div>}

                </div>
            }
            {dashboard && btnActive === "search" && <div style={{display:"flex", justifyContent: "flex-start"}}className="dashSearch">
                 <Row>
                    <Col>
                        <TextInput
                        keyField={"firstName"}
                        label={"First Name"}
                        formik={formik}
                        placeholder={"First Name"}
                        hideRequired={true}
                        />
                    </Col>
                    <Col>
                        <TextInput
                        keyField={"lastName"}
                        label={"Last Name"}
                        formik={formik}
                        placeholder={"Last Name"}
                        hideRequired={true}
                        />
                    </Col>
                    <Col>
                        <DateSelectorWithoutFormik
                            formik={formik}
                            keyField={'birthDate'}
                            label="DOB"
                            value={moment(formik.values.birthDate).format("YYYY-MM-DD")}
                            handleChange={handleDateChange}
                            isError={
                                formik?.touched?.end && formik?.errors?.end
                                ? formik?.errors?.end
                                : ""
                            }
                            maxDate={moment()}
                            hideRequired={true}
                        />
                    </Col>
                    <Col>
                        <TextInput
                        keyField={"healthCardNumber"}
                        label={"HCN"}
                        formik={formik}
                        placeholder={"HCN"}
                        hideRequired={true}
                        />
                    </Col>
                    <Col>
                        <button
                            style={{height:"60px", marginTop:"26px", width: "fit-content"}}
                            className="custom-btn"
                            type="button"
                            isLoading={true}
                            onClick={handleSearchPatient}
                        >
                            <SearchOutlinedIcon/> Search
                        </button>
                    </Col>
                </Row></div>}
            {!dashboard && <div className="heading-wrap">
                <SearchBar page={page} setPage={setPage} list={patientsAssigned} encoded={true} filterData={handleFilterData} />
            </div>}
            
            {btnActive === "assigned" && <CustomTable
                tableData={filterData}
                headCells={dashboard ? dashboardPatientsAssignedHeadCells : PatientsAssignedHeadCells}
                dataFields={dashboard ? dashboardPatientsAssignedDataFields : PatientsAssignedDataFields}
                selectedUserAction={selectedUserAction}
                rowOptions={false}
                rowClick={dashboard}
                loading={isLoading}
                fetching={isFetching}
                deletingItem={false}
                canView={true}
                canDelete={false}
                rowsPerPage={dashboard? 5 : 10}
                canEdit={false}
                page={page}
                setPage={setPage}
            />}
            {btnActive === "search" && <CustomTable
                tableData={searchData}
                headCells={dashboardPatientsAssignedHeadCells}
                dataFields={dashboardPatientsAssignedDataFields}
                selectedUserAction={selectedUserAction}
                rowOptions={false}
                rowClick={dashboard}
                loading={btnLoading}
                fetching={isFetching}
                deletingItem={false}
                canView={true}
                canDelete={false}
                rowsPerPage={dashboard? 5 : 10}
                canEdit={false}
                page={page}
                setPage={setPage}
            />}


        </section>
    );
}

export default PatientsAssigned;