import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import TextInput from "../../common/textfield/TextInput";
import { useNavigate } from "react-router-dom";
import { Button } from "../../common/Button";
import { failed, success } from "../../common/Toastify";
import { FormikObj } from "./Constants";
import { createScheduleCategory } from "../../api/ScheduleCategory";
import TextArea from "../../common/textfield/TextArea";
import SelectFiled from "../../common/textfield/SelectFiled";
import Radio from "../../common/textfield/Radio";
import { useOrgQuery } from "../../../hooks/ReactQueryHooks/useOrgQuery";
import { timeSlot } from "./Constants"
import { Tooltip } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useSelector } from "react-redux";
function AddSchedule() {
    const navigate = useNavigate();
    const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
    const [btnLoading, setBtnLoading] = useState(false);
    const [organizations, setOrganizations] = useState([]);
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)

    //Fetching Organization Data
    const onSuccessOrg = (data) => {
        setOrganizations(data?.map((org) => (org = { ...org, value: org?.id })));
    };
    useOrgQuery({ onSuccess: onSuccessOrg });

    const handleOrgChange = (event) => {
        const value = event?.target?.value;
        formik.setFieldValue("organization", value);
    };
    const handletimeSlot = (event) => {
        const value = event?.target?.value;
        formik.setFieldValue("timeSlot", value);
    };
    const handleRadioChange = (val) => {
        formik.setFieldValue("public", (val) ? val === "true" : false);
    };
    const formik = useFormik({
        ...FormikObj,
        onSubmit: (values) => {
            if (values?.name?.toLocaleLowerCase() === "default") {
                formik.setFieldError("name", "Name can't be equal to 'Default'")
            } else {
                setBtnLoading(true);
                createScheduleCategory({ ...values, organization: values?.isRootLevel ? adminOrgs?.[0]?.id : values?.organization })
                    .then((res) => {
                        if (res.status === true) {
                            navigate("/app/manage-schedule-category");
                            success(res.message);
                        } else {
                            failed(res.error);
                        }
                    })
                    .catch((res) => {
                        failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                    })
                    .finally(() => setBtnLoading(false));
            }
        },
    });

    return (
        <section className="common-listing">
            <div className="heading-wrap mb-3">
                <Tooltip title="Go back">
                    <div style={{ marginRight: "1rem", cursor: "pointer" }} onClick={() => navigate(-1)}>
                        <ArrowBack />
                    </div>
                </Tooltip>
            </div>
            <div className="custom-card p-4">
                <form className="common-form border-fields" onSubmit={formik.handleSubmit}>
                    <Row>
                        {userRoles?.includes('Super-Admin')
                            ? <Row>
                                <Col>
                                    <div className="field-wrap" style={{ margin: 0 }}>
                                        <label className="custom-checkbox" style={{ margin: 0, width: "fit-content" }}>
                                            Default Schedule Category
                                            <input style={{ width: "auto" }} type="checkbox" name={"default program"} checked={formik.values.isRootLevel} onChange={() => formik.setFieldValue("isRootLevel", !!!formik?.values?.isRootLevel)} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </Col>
                            </Row>
                            : null}
                        {formik?.values?.isRootLevel
                            ? null
                            : <Col>
                                <SelectFiled keyField={"organization"} label={"Select Organization"} formik={formik} options={organizations} onChange={handleOrgChange} />
                            </Col>}
                        <Col>
                            <TextInput keyField={"name"} label={"Name"} formik={formik} placeholder={"Name"} />
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col>
                            <SelectFiled keyField={"timeSlot"} label={"Select Time Slot"} formik={formik} options={timeSlot} onChange={handletimeSlot} />
                        </Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextArea keyField={"description"} label={"Description"} formik={formik} placeholder={"Description..."} />
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col>
                            <label htmlFor="public">Visible to patients? <span style={{ color: "red" }}> *</span> </label>
                            <div style={{display: "flex", justifyContent:"centre", flexDirection: "row"}}>  
                                <Row style={{marginLeft : "-10px" , marginRight: "5px"}}><Radio keyField={"public"} name="public" radioLabel={"Yes"} value={true} isChecked={formik.values.public == null || formik.values.public} formik={formik}  handleChange={handleRadioChange} /></Row>
                                <Row style={{marginLeft : "-10px" , marginRight: "5px"}}><Radio keyField={"public"} name="public" radioLabel={"No"} value={false} isChecked={formik.values.public != null && !formik.values.public} formik={formik} handleChange={handleRadioChange}  /></Row>
                            </div>
                        </Col>
                        <Col></Col>
                        <Col></Col>
                    </Row>
                    <div className="btn-wrap" style={{ display: "flex" }}>
                        <Button onClick={() => navigate("/app/manage-schedule-category")} variant="secondary" title="Cancel">
                            Cancel
                        </Button>
                        <Button type="submit" isLoading={btnLoading}>
                            Create
                        </Button>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default AddSchedule;
