import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import TextInput from "../../common/textfield/TextInput";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../common/Button";
import { failed, success } from "../../common/Toastify";
import TextArea from "../../common/textfield/TextArea";
import { Tooltip } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import moment from "moment";
import { DateSelectorWithoutFormik } from "../../common/textfield/DateSelector";
import { FormikObjectSurgeryScreening } from "./Constant";
import { editSurgeryScreening } from "../api/MedicalRequest";

const EditSurgeryScreening = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [btnLoading, setBtnLoading] = useState(false);
    const { patientData, practionerData, surgeryScreeningData } = location?.state
    const formik = useFormik({
        ...FormikObjectSurgeryScreening,
        onSubmit: async(values) => {
                setBtnLoading(true);
                editSurgeryScreening({ id: surgeryScreeningData?.id, ...values, practitionerId: practionerData?.id, practitionerName: practionerData?.name?.[0]?.text || practionerData?.name})
                .then((res) => {
                    if (res.status === true) {
                        navigate("/app/patients-details", {state:{id: patientData?.patientId, name: patientData?.patientName}});
                        success(res.message);
                    } else {
                        failed(res.error);
                    }
                })
                .catch((res) => {
                    failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                })
                .finally(() => setBtnLoading(false));
        },
    });

    const handleDateChange = (val) => {
      const isValidDate = moment(val, 'MMM-DD-YYYY').isValid()
      if(isValidDate){
        formik.setFieldValue("performedDateTime", val.format("YYYY-MM-DD"));
      }else{
        formik.setFieldError('performedDateTime', 'Invalid date format')
      }
    }

    useEffect(() => {
        localStorage.setItem("activeTableKey", "ninth");
        formik.setFieldValue("procedureName", surgeryScreeningData?.procedureName)
        // formik.setFieldValue("note", surgeryScreeningData?.note ? surgeryScreeningData?.note?.[0]?.text : "")
        formik.setFieldValue("performedDateTime", surgeryScreeningData?.performedDateTime)
    }, [])

  return (
    <section className="common-listing">
      <div className="heading-wrap mb-3">
        <Tooltip title="Go back">
          <div
            style={{ marginRight: "1rem", cursor: "pointer" }}
            onClick={() => navigate(-1)}
          >
            <ArrowBack />
          </div>
        </Tooltip>
      </div>
      <div className="custom-card p-4">
        <form
          className="common-form border-fields"
          onSubmit={formik.handleSubmit}
        >
          <Row>
            <Col>
              <TextInput
                keyField={"procedureName"}
                label={"Procedure"}
                formik={formik}
                placeholder={"Procedure"}
                disabled={true}
              />
            </Col>
            <Col>
              <DateSelectorWithoutFormik
                formik={formik}
                keyField={'performedDateTime'}
                label="Date"
                value={moment(formik?.values?.performedDateTime)}
                handleChange={handleDateChange}
                maxDate={moment()}
              />
            </Col>
          </Row>
          <Row>
            <Col></Col>
            <Col></Col>
          </Row>
          <div className="btn-wrap" style={{ display: "flex" }}>
            <Button
              onClick={() =>  !btnLoading && navigate("/app/patients-details",{state:{id: patientData?.patientId, name: patientData?.patientName}})}
              variant="secondary"
              title="Cancel"
            >
              Cancel
            </Button>
            <Button type="submit" isLoading={btnLoading}>
              Submit
            </Button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default EditSurgeryScreening