import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { Form, Modal, Row } from "react-bootstrap";
import { Button } from "../common/Button";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { enableSMSMfa, setupAuthAppMFA, verifyAuthAppMFA } from "../api/MFA";
import { useSelector } from "react-redux";
import TextInput from "../common/textfield/TextInput";
import Lock from '../../assets/images/Lock.png';
import CallIcon from '../../assets/images/call-border.png';
import { failed, success } from "../common/Toastify";
import QRCode from "qrcode.react";
import { logout } from "../api/AuthService";
import { userlogout } from "../slice/authService";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const MFASetupModal = ({ modalShow, handleClose, isFromLogin = false, passwordFromLogin = null, usernameFromLogin = null }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const username = useSelector((state) => state?.auth?.user?.email);
    const [isLoading, setIsLoading] = useState(false);
    const [radioSelected, setRadioSelected] = useState("Authenticator App MFA");
    const [authAppSetupToken, setAuthAppToken] = useState("");
    const [passwordVerified, setPasswordVerified] = useState(isFromLogin ? true : false);
    const [authAppSessionToken, setAuthAppSessionToken] = useState("");
    const [secretCodeLoading, setSecretCodeLoading] = useState(false);

    const Logout = () => {
        logout();
        dispatch(userlogout());
        navigate('/login');
    };

    const handleRadioChange = (event) => {
        setRadioSelected(event.target.value);
    };

    useEffect(() => {
        if (isFromLogin) {
            getAuthAppTokens()
        }
    }, [])

    const passFormik = useFormik({
        initialValues: {
            password: "",
        },
        validationSchema: Yup.object({
            password: Yup.string().required('Password Required').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),

        }),
        onSubmit: (values) => {
            setIsLoading(true);
            setupAuthAppMFA({ username: username, password: values.password })
                .then((res) => {
                    if (res?.status === true && res?.associateResponse?.SecretCode) {
                        setAuthAppToken(res?.associateResponse?.SecretCode);
                        setPasswordVerified(true);
                        setAuthAppSessionToken(res?.accessToken);
                    } else {
                        failed("Incorrect password. Please try again.");
                    }
                })
                .catch((res) => {
                    failed(res?.response?.data?.error)
                })
                .finally(() => setIsLoading(false))
        }
    })

    const smsFormik = useFormik({
        initialValues: {
            password: passwordFromLogin || "",
            phoneNumber: "",
        },
        validationSchema: Yup.object({
            password: Yup.string().required('Password Required').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
            phoneNumber: Yup.string().required('Phone Number Required').matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Phone number must be in the format (XXX) XXX-XXXX.'),

        }),
        onSubmit: (values) => {
            const phoneNumberDigitsOnly = values.phoneNumber.replace(/\D/g, '');
            setIsLoading(true);
            enableSMSMfa({ username: usernameFromLogin || username, password: values?.password, phoneNumber: phoneNumberDigitsOnly })
                .then((res) => {
                    if (res?.["$metadata"]?.httpStatusCode === 200) {
                        success("SMS MFA setup successfully");
                        handleClose();
                        Logout();
                    } else {
                        failed("Error setting up MFA. Please try again")
                    }

                })
                .catch((res) => {
                    failed(res?.response?.data?.message || "Error setting up MFA. Please try again")
                })
                .finally(() => setIsLoading(false))
        }
    });

    const formik = useFormik({
        initialValues: {
            authAppVerifyCode: ""
        },
        validationSchema: Yup.object({
            authAppVerifyCode: Yup.string()
                .matches(/^\d{6}$/, 'Authenticator app code must be exactly 6 digits')
                .required('Authenticator app code is required'),

        }),
        onSubmit: (values) => {
            setIsLoading(true);
            verifyAuthAppMFA({ accessToken: authAppSessionToken, mfaCode: values?.authAppVerifyCode })
                .then((res) => {
                    if (res?.["$metadata"]?.httpStatusCode === 200) {
                        success("Authenticator MFA added successfully");
                        handleClose();
                        Logout();
                    } else {
                        failed("Invalid Code. Please try again")
                    }
                })
                .catch((res) => failed(res?.message))
                .finally(() => setIsLoading(false))
        }
    });

    const generateQR = (secretCode) => {
        const user = username || usernameFromLogin;
        const otpauthURL = `otpauth://totp/FLORIZEL:${user}?secret=${secretCode}&issuer=FONEMED`;
        return (
            <div>
                <QRCode value={otpauthURL} />
            </div>
        );
    };

    const getAuthAppTokens = () => {
        setSecretCodeLoading(true);
        setupAuthAppMFA({ username: usernameFromLogin, password: passwordFromLogin })
            .then((res) => {
                if (res?.status === true && res?.associateResponse?.SecretCode) {
                    setAuthAppToken(res?.associateResponse?.SecretCode);
                    setPasswordVerified(true);
                    setAuthAppSessionToken(res?.accessToken);
                } else {
                    failed("Incorrect password. Please try again.");
                }
            })
            .catch((res) => {
                failed(res?.response?.data?.error)
            })
            .finally(() => setSecretCodeLoading(false))
    }


    return (
        <section className="common-listing">
            <Modal
                size="lg"
                show={modalShow}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                backdropClassName
                backdrop={"static"}
                centered
                className="custom-dialog popup-org"
            >

                <Modal.Header closeButton className="border-0">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Enable MFA
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginRight: "150px" }}>
                        <Form.Check
                            type="radio"
                            label="Authenticator App MFA"
                            name="mfaType"
                            value="Authenticator App MFA"
                            checked={radioSelected === "Authenticator App MFA"}
                            onChange={handleRadioChange}
                            style={{ fontSize: "18px", marginRight: "20px" }}
                        />

                        <Form.Check
                            type="radio"
                            label="SMS MFA"
                            name="mfaType"
                            value="SMS MFA"
                            checked={radioSelected === "SMS MFA"}
                            onChange={handleRadioChange}
                            style={{ fontSize: "18px" }}
                        />
                    </div>
                    <hr />

                    {(radioSelected === "Authenticator App MFA" && !passwordVerified) && (
                        <form className="custom-form" onSubmit={passFormik.handleSubmit}>
                            <Row>
                                <TextInput type="password" icon={Lock} keyField={"password"} label={"Please enter current password to request unique token"} formik={passFormik} placeholder={"Enter Password"} />
                            </Row>
                            <div className="action-wrap">
                                <Button type="submit" disabled={isLoading}>
                                    {isLoading ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Submit'}
                                </Button>
                                <Button onClick={handleClose} variant="secondary" title="Cancel" style={{ marginLeft: '10px' }}>Cancel</Button>

                            </div>
                        </form>
                    )}


                    <div>
                        {secretCodeLoading ? (
                            <div>
                                <CircularProgress size={50} />
                            </div>
                        ) : (
                            <div>
                                {(radioSelected === "Authenticator App MFA" && passwordVerified && authAppSetupToken) && (
                                    <form className="custom-form" onSubmit={formik.handleSubmit}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div>
                                                <p><b><span style={{ color: "#005da8" }}>Step 1:</span></b> Install an authenticator app on your mobile device (e.g., Google Authenticator, Microsoft Authenticator).</p>
                                            </div>

                                            <div>
                                                <p><b><span style={{ color: "#005da8" }}>Step 2:</span></b> Open the authenticator app and scan the QR code below or manually enter the secret key.</p>
                                            </div>

                                            <div>
                                                <p><b><span style={{ color: "#005da8" }}>Step 3:</span></b> Enter the 6-digit code generated by the authenticator app into the field below.</p>
                                            </div>
                                        </div>

                                        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                            {generateQR(authAppSetupToken)}
                                        </div>

                                        <div>
                                            <p><b><span style={{ color: "#005da8" }}>Secret Key:</span></b> {authAppSetupToken}</p>
                                        </div>
                                        <hr />

                                        <div>
                                            <TextInput type="code" icon={Lock} keyField={"authAppVerifyCode"} label={"Please enter your unique code from authenticator app"} formik={formik} placeholder={"Enter Code"} />
                                        </div>



                                        <div>
                                            <p><span style={{ color: "red" }}> *</span>Please note that this session will be logged out after MFA setup.</p>
                                        </div>



                                        <div className="action-wrap">
                                            <Button type="submit" disabled={isLoading}>
                                                {isLoading ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Submit'}
                                            </Button>
                                            <Button onClick={handleClose} variant="secondary" title="Cancel" style={{ marginLeft: '10px' }}>Cancel</Button>

                                        </div>
                                    </form>
                                )}

                                {radioSelected === "SMS MFA" && (
                                    <>
                                        <form className="custom-form" onSubmit={smsFormik.handleSubmit}>
                                            {!passwordVerified && (
                                                <Row>
                                                    <TextInput type="password" icon={Lock} keyField={"password"} label={"Enter current password to set up SMS MFA"} formik={smsFormik} placeholder={"Enter Password"} />
                                                </Row>
                                            )}

                                            <Row>
                                                <TextInput
                                                    keyField={"phoneNumber"}
                                                    type="phone"
                                                    icon={CallIcon}
                                                    label={"Enter Phone Number to get Authentication codes"}
                                                    formik={smsFormik}
                                                    placeholder={"Phone Number"}
                                                />
                                            </Row>

                                            <div>
                                                <p><span style={{ color: "red" }}> *</span>Please note that this session will be logged out after MFA setup.</p>
                                            </div>


                                            <div className="action-wrap">
                                                <Button type="submit" disabled={isLoading}>
                                                    {isLoading ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Submit'}
                                                </Button>
                                                <Button onClick={handleClose} variant="secondary" title="Cancel" style={{ marginLeft: '10px' }}>Cancel</Button>

                                            </div>


                                        </form>
                                    </>
                                )}
                            </div>
                        )}
                    </div>


                </Modal.Body>
            </Modal>
        </section>
    )
}

export default MFASetupModal;