import axios from "axios";
import { apiUrl } from "../../environment/index";

export const userLogin = (data) => {
    return axios.post(`${apiUrl}auth/admin-login`, data).then((response) => {
        if (response.data.status === true) {
            localStorage.setItem("authentication", response.data.token);
        }
        return response.data;
    }).catch((error) => {
        console.log(error, "===test");
        return error.response.data
    });
};

export const logout = () => {
    localStorage.clear();
    return true;
};

export const verifyAndSetPass = async ({ username, verificationCode, password, newPassword }) => {
    try {
        const response = await axios.get(`${apiUrl}auth/verify-email?username=${username}&verificationCode=${verificationCode}`);
        try {
            const res = await userLogin({ username, password });
            if(res?.status !== false) {
                try {
                    const res1 = await axios.post(`${apiUrl}auth/reset`, {
                        username,
                        "password": newPassword,
                        "session": res.session,
                    });
                    return res1;
                    
                } catch (err) {
                    return {"data": err?.response?.data};
                }
            } else {
                return {"data": res};
            }
        } catch (err_1) {
            console.log(err_1);
        }
    } catch (error) {
        console.log(error);
        return error;
    }
};

// if @verifyAndSetPass API havent run whole or after verfied email stooped then call the below API
export const verifiedUserSetPass = ({ username, session, confirmPass }) => {
    return axios.post(`${apiUrl}auth/reset`, {
        username,
        "password": confirmPass,
        "session": session,
    }).then((res1) => {
        return res1
    }).catch((err) => { console.log(err) });
};

export const resetPassword = (data) => {
    return axios.post(`${apiUrl}auth/new-password`, data);
};
export const forgotPassword = ({ email, isExpired = false }) => {
    // isExpired is to identify that the user's temporary password has been expired so he need to reset the password.
    return isExpired ? axios.get(`${apiUrl}auth/forgot-password?email=${email?.toLowerCase()}&isExpired=true`) : axios.get(`${apiUrl}auth/forgot-password?email=${email?.toLowerCase()}`);
};
export const verifyMFA = (data) => {
    return axios.post(`${apiUrl}auth/verify-mfa-code`, data);
};


export const getIdentityChallenge = ({ sftID }) => { 
    return axios.get(`${apiUrl}securefiletransfer?sftID=${sftID}`)
        .then((response) => { 
           
        })
        .catch((error) => { return error });
};

export const verifyPatientsIdentity = ( data ) => {  
     console.log(data);
    return axios.post(`${apiUrl}securefiletransfer/verify`, data).then((response) => { 
        return response.data;
    }).catch((error) => {
        console.log(error, "===test");
        return error.response.data
    });
    
};

export const getOrgInfo = ( data ) => {  
     
   return axios.get(`${apiUrl}organization/info/${data.orgId}`).then((response) => { 
       return response.data;
   }).catch((error) => { 
       return error.response.data
   });
   
}; 