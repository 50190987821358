import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "../../common/Button";
import { getOrganization } from "../../api/Organization";
import SelectFiled from "../../common/textfield/SelectFiled";
import { failed, success } from "../../common/Toastify";
import { useFormik } from "formik";
import TextInput from "../../common/textfield/TextInput";
import { createProgram } from "../../api/Program";
import { useNavigate } from "react-router-dom";
import TextArea from "../../common/textfield/TextArea";
import { FormikObj, conditions, conditionsNameAndKey, frequencyOptions, noOfTimesOptions, } from "./Constant";
import { Tooltip } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useListOfRpmDevicesQuery } from "../../../hooks/ReactQueryHooks/useListOfRpmDevicesQuery";
import { useSelector } from "react-redux";
import { useProgramQuery } from "../../../hooks/ReactQueryHooks/useProgramQuery";
import { useMedia } from "react-use";

const CreateProgram = () => {
    const navigate = useNavigate();
    const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
    const [btnLoading, setBtnLoading] = useState(false);
    const [organizations, setOrganizations] = useState([]);
    const [conditionsState, setConditionsState] = useState(conditions);
    const [RPMdevices, setRPMDevices] = useState([]);
    const [deviceError, setDeviceError] = useState(false);
    const [programList, setProgramList] = useState([]);
    const makeResponsiveTable = useMedia('(max-width: 980px)')

    const onRPMSuccess = (data) => {
        data.map((item) => {
            if (item.conditionName === 'blood_pressure' || item.conditionName === 'oxygen') {
                let newItem = { ...item }
                newItem['conditionName'] = 'heart_rate';
                data.push(newItem)
            }
        })
        const newData = data.slice(0, 7);
        setRPMDevices(data);
        const newConditionsData = JSON.parse(JSON.stringify(conditions))
        data.forEach(({ conditionName, id }) => {
            if (!!!newConditionsData[conditionName]["device"]) newConditionsData[conditionName]["device"] = id
        })
        setConditionsState(newConditionsData)
    }

    useProgramQuery({ onSuccess: setProgramList, isDefault: true });
    let programStatus = "create";
    useListOfRpmDevicesQuery({ programStatus, onSuccess: onRPMSuccess })

    const formik = useFormik({
        ...FormikObj,
        onSubmit: (values) => {
            if (Object.keys(conditionsState).some((cond) => conditionsState?.[cond]?.selected && !conditionsState?.[cond]?.device)) {
                setDeviceError(true)
            } else {
                setBtnLoading(true);
                const device = Object.keys(conditionsState)
                    .map((cond) => {
                        if (conditionsState?.[cond]?.selected) {
                            const id = conditionsState?.[cond]?.device
                            return {
                                id,
                                "reference": `RPMDevice/${id}`,
                                "type": "RPMDevice",
                            }
                        }
                        return null
                    }).filter((n) => n);
                createProgram({ ...values, organization: values?.isDefault ? null : values?.organization }, device)
                    .then((res) => {
                        navigate("/app/manage-program");
                        success(res.message);
                    })
                    .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                    .finally(() => setBtnLoading(false));
            }
        },
    });

    useEffect(() => {
        getOrganization()
            .then((res) => {
                setOrganizations(res.data.map((org) => (org = { ...org, value: org.id })));
            })
            .catch((res) => {
                failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
            });
    }, []);

    useEffect(() => {
        const program = programList?.filter((program) => program?.id === formik.values.RPM)[0];
        if (program) {
            const selectedConditions = JSON.parse(JSON.stringify(conditions))
            program?.condition?.forEach((cond) => {
                selectedConditions[conditionsNameAndKey?.[cond?.name]] = {
                    name: cond?.name,
                    selected: true,
                    frequency: cond?.frequency,
                    repeatTime: cond?.repeatTime,
                    device: "",
                }
            })
            const RPMDevicesId = program?.device?.map((rpm) => rpm?.id);
            const filteredRPMDevices = RPMdevices?.filter((rpm) => RPMDevicesId?.includes(rpm?.id));
            filteredRPMDevices?.forEach(({ conditionName, id }) => {
                selectedConditions[conditionName]["device"] = id
            })
            setConditionsState(selectedConditions);
            formik.setFieldValue("title", program?.title);
            formik.setFieldValue("description", program?.description);
            formik.setFieldValue("time", program?.duration?.value);
            formik.setFieldValue("condition", program?.condition);
        }
    }, [formik.values.RPM]);

    const handleConditionsChange = (event, conditionKey) => {
        const { name, value, checked } = event.target;
        name === "selected"
            ? setConditionsState({
                ...conditionsState,
                [conditionKey]: { ...conditionsState[conditionKey], [name]: checked },
            })
            : setConditionsState({
                ...conditionsState,
                [conditionKey]: { ...conditionsState[conditionKey], [name]: value },
            });
    };

    useEffect(() => {
        const selectedConditions = Object.keys(conditionsState)
            .map((cond) => (conditionsState[cond].selected ? conditionsState[cond] : null))
            .filter((n) => n);
        formik.setFieldValue("condition", selectedConditions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conditionsState]);

    return (
        <section className="common-listing">
            <div className="heading-wrap mb-3">
                <Tooltip title="Go back">
                    <div style={{ marginRight: "1rem", cursor: "pointer" }} onClick={() => navigate(-1)}>
                        <ArrowBack />
                    </div>
                </Tooltip>
            </div>
            <div className="custom-card p-4">
                <form className="common-form border-fields" onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col>
                            {userRoles?.includes('Super-Admin')
                                ? null : <SelectFiled
                                    keyField={"RPM"}
                                    label={"Select RPM Template"}
                                    formik={formik}
                                    placeholder={"Select RPM Title"}
                                    required={false}
                                    options={programList?.map(program => ({ name: program.title, value: program.id }))}
                                />}
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextInput keyField={"title"} label={"Title"} formik={formik} placeholder={"Program Name"} />
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextArea keyField={"description"} label={"Description"} formik={formik} placeholder={"Details"} />
                        </Col>
                    </Row>
                    {userRoles?.includes('Super-Admin')
                        ? <Row>
                            <Col>
                                <div className="field-wrap" style={{ margin: 0 }}>
                                    <label className="custom-checkbox" style={{ margin: 0, width: "fit-content" }}>
                                        Default Program
                                        <input style={{ width: "auto" }} type="checkbox" name={"default program"} checked={formik.values.isDefault} onChange={() => formik.setFieldValue("isDefault", !!!formik?.values?.isDefault)} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        : null}
                    <div className="program-conditions-table" style={{ overflowX: makeResponsiveTable ? "scroll" : "hidden", marginBottom: makeResponsiveTable ? "2rem" : "0" }}>
                        <table style={{ width: "max-content" }}>
                            <thead>
                                <tr>
                                    <td>Select Condition</td>
                                    <td style={{ textAlign: "center" }}>Select Device</td>
                                    <td style={{ textAlign: "center" }}>Select Frequency</td>
                                    <td style={{ textAlign: "center" }}>How many Times</td>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(conditionsState).map((conditionKey) => {
                                    const { frequency, selected, repeatTime, name, device } = conditionsState?.[conditionKey];
                                    return (
                                        <tr className="check-select-wrap field-wrap" key={conditionKey}>
                                            <td>
                                                <label className="custom-checkbox">
                                                    {name}
                                                    <input style={{ width: "auto" }} name="selected" type="checkbox" checked={selected} onChange={(e) => handleConditionsChange(e, conditionKey)} />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                <select style={deviceError && !device ? { margin: "0" } : {}} disabled={!selected} name="device" placeholder="Device" value={device} onChange={(e) => handleConditionsChange(e, conditionKey)}>
                                                    <option value={""} disabled>Select</option>
                                                    {RPMdevices.filter((item) => item.conditionName === conditionKey).map(({ deviceName, id }) => (
                                                        <option value={id} key={id}>
                                                            {deviceName}
                                                        </option>
                                                    ))}
                                                </select>
                                                {deviceError && (selected && !device) ? <div style={{ fontSize: '14px', color: '#dc3545' }}>Required field</div> : null}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                <select disabled={!selected} name="frequency" placeholder="Group" value={frequency} onChange={(e) => handleConditionsChange(e, conditionKey)}>
                                                    {frequencyOptions.map(({ value, name }) => (
                                                        <option value={value} key={value}>
                                                            {name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                {repeatTime ? (
                                                    <>
                                                        <select disabled={!selected} name="repeatTime" placeholder="Group" value={repeatTime} onChange={(e) => handleConditionsChange(e, conditionKey)}>
                                                            {noOfTimesOptions.map(({ value, name }) => (
                                                                <option value={value} key={value}>
                                                                    {name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </>
                                                ) : null}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <Row>
                        <Col>
                            <TextInput keyField={"time"} label={"Duration(Days)"} formik={formik} placeholder={"30"} />
                        </Col>

                        <Col>
                            {!formik?.values?.isDefault ? <SelectFiled keyField={"organization"} label={"Select Organization"} formik={formik} options={organizations} /> : null}
                        </Col>
                        <Col></Col>
                    </Row>
                    <div style={{ color: "red" }}>{formik?.touched.condition && formik?.errors.condition ? formik?.errors.condition : null}</div>
                    <div className="btn-wrap" style={{ display: "flex" }}>
                        <Button variant="secondary" title="Cancel" onClick={() => navigate("/app/manage-program")}>
                            Cancel
                        </Button>
                        <Button type="submit" isLoading={btnLoading}>
                            Create Program
                        </Button>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default CreateProgram;
