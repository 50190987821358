import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useFormik } from "formik";
import { Button } from "../../common/Button";
import { failed, success } from "../../common/Toastify";
import TextInput from "../../common/textfield/TextInput";
import SelectFiled from "../../common/textfield/SelectFiled";
import { FormikObj } from "./Constants";
import TextArea from "../../common/textfield/TextArea";
import { useOrgQuery } from "../../../hooks/ReactQueryHooks/useOrgQuery";
import { editGroup } from "../../api/Group";

export function EditGroup({ modalShow, handleShow, refreshTable }) {
    const [btnLoading, setBtnLoading] = useState(false);
    const [organizations, setOrganizations] = useState([]);

    const onSuccessOrg = (data) => {
        setOrganizations(data?.map((org) => org = { ...org, "value": org.id }))
    };
    useOrgQuery({ onSuccess: onSuccessOrg });

    const formik = useFormik({
        ...FormikObj,
        onSubmit: (values) => {
            setBtnLoading(true);
            editGroup({ ...values, groupId: modalShow?.id }).then((res) => {
                success(res.message);
                handleShow();
                refreshTable();
            }).catch(res => {
                failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
            }).finally(() => setBtnLoading(false));
        },
    });

    useEffect(() => {
        if (modalShow?.id) {
            formik.setFieldValue("orgId", modalShow?.orgId || "");
            formik.setFieldValue("name", modalShow?.name || "");
            formik.setFieldValue("description", modalShow?.description || "");
            formik.setFieldValue("member", modalShow?.member || []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalShow?.id]);

    return (
        <Modal
            size="lg"
            show={modalShow}
            onHide={handleShow}
            aria-labelledby="contained-modal-title-vcenter"
            backdropClassName
            backdrop={'static'}
            centered className="custom-dialog">
            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">
                    Group Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="common-form border-fields" onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col>
                            <TextInput keyField={"name"} label={"Name"} formik={formik} placeholder={"Name"} />
                        </Col>
                        <Col>
                            <SelectFiled keyField={"orgId"} label={"Select Organization"} formik={formik} options={organizations} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextArea required={false} keyField={"description"} label={"Description"} formik={formik} placeholder={"Description..."} />
                        </Col>
                    </Row>
                    <div className="btn-wrap">
                        <Button onClick={handleShow} variant="secondary" title="Cancel">Cancel</Button>
                        <Button type="submit" isLoading={btnLoading}>Update</Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}
