import { useQuery } from "react-query";
import { axios } from "../../lib/axios";
import { onError } from "./onError"

export function useListOfRpmDevicesQuery({ programStatus, onSuccess }) {

    const fetch = () => {
        return axios.get("/device/rpm");
    };
    const query = useQuery(
        "list-of-RPM-devices",
        () => fetch(),
        {
            refetchOnWindowFocus: false,
            // select: (data) => data.data,
            select: (data) => {
                data.data.map((item) => {
                    if (item.conditionName === 'blood_pressure' || item.conditionName === 'oxygen') {
                        let newItem = { ...item }
                        newItem['conditionName'] = 'heart_rate';
                        data.data.push(newItem);
                    }
                })
                return (programStatus === 'create' ?
                    data.data.slice(0, 7) : data.data.slice(0, 10));
            },
            onSuccess,
            onError
        }
    );

    return { ...query };
}
