import { useEffect, useRef, useState } from "react";
import PSPDFKit from 'pspdfkit';
import { useGetDocumentById } from "../../../hooks/ReactQueryHooks/useGetDocument";
import { PSPDFKIT_LICENSE_KEY } from "../../../environment";

const AddSignatureImage = async ({ instance, signFieldName, signUrl }) => {
    const annotations = await instance.getAnnotations(0);
    const signatureField = annotations.find(
        (annotation) =>
            annotation instanceof PSPDFKit.Annotations.WidgetAnnotation &&
            annotation.formFieldName === signFieldName
    );
    if (signatureField) {
        const blob = await fetch(signUrl).then((res) => res.blob());
        const imageAttachmentId = await instance.createAttachment(blob);
        const imageAnnotation = new PSPDFKit.Annotations.ImageAnnotation({
            pageIndex: 0, // Specify the page index where you want to add the signature
            boundingBox: signatureField.boundingBox, // [x, y, width, height]
            isSignature: true,
            contentType: 'image/jpeg',
            imageAttachmentId,
        });
        instance.create(imageAnnotation);
    }
    return true
}

export default function PdfViewerComponent({ document, documentId, instanceRef, initialPdfFormValues }) {
    const containerRef = useRef(null);
    const [base64Url, setBase64Url] = useState(null)
    
    const onSuccess = (data) => {
        setBase64Url(data?.content?.[0]?.attachment?.base64String);
    };
    useGetDocumentById({ onSuccess, id : documentId });
    
    useEffect(() => {
        const baseUrl = `${window.location.protocol}//${window.location.host}/`
        const container = containerRef.current;

        if( base64Url || document) {
        (async function () {
            PSPDFKit.load({
                disableWebAssemblyStreaming: true,
                // Container where PSPDFKit should be mounted.
                container,
                // The document to open.
                document : document || base64Url,
                toolbarItems: [],
                // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
                baseUrl,
                licenseKey: PSPDFKIT_LICENSE_KEY,
            }).then(async (instance) => {
                instance.setViewState(viewState => viewState.set("showToolbar", false));
                instanceRef.current = instance;
                const signFieldName = "practitioner-signature_af_image"
                instance.getFormFields().then(formFields => {
                    formFields.forEach(formField => {
                        if (initialPdfFormValues?.[formField.name]) {
                            if (formField.name === signFieldName) {
                                AddSignatureImage({ instance, signFieldName, signUrl: initialPdfFormValues?.[formField.name] })
                            } else {
                                instance.setFormFieldValues({
                                    [formField.name]: initialPdfFormValues?.[formField.name],
                                });
                            }
                        }
                    });
                })
            });
        })();
        }

        return () => PSPDFKit && PSPDFKit.unload(container);
    }, [document, base64Url]);

    return <div ref={containerRef} style={{ width: "100%", height: "80vh" }} />;
}
