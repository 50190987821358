import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import TextInput from "../../common/textfield/TextInput";
import { useNavigate } from "react-router-dom";
import { Button } from "../../common/Button";
import { createOrganization, createDiscover } from "../../api/Organization";
import { failed, success } from "../../common/Toastify";
import CountrySelect from "../../common/textfield/CountrySelect";
import RegionSelect from "../../common/textfield/RegionSelect";
import {
	devicesField,
	deviceUnitTypeOptions,
	filterKeysFunc,
	FormikObj,
	typeOfDeviceUnitTypeOptions,
	ORGANIZATION_DETAILS,
	NAME,
	DESCRIPTION,
	ADDRESS,
	COUNTRY,
	CITY,
	ZIP_CODE,
	POSTAL_CODE,
	PHONE_NUMBER,
	EXT,
	EMAIL_ADDRESS,
	APPOINTMENT_CANCEL_TIME,
	DEVICE_UNIT_TYPE,
	TYPE,
	CANCEL,
	CREATE,
	operationTiming,
	transformTimingData,
	getDeviceUnit,
	getValidationSchema
} from "./Constants";
import { useOrgQuery } from "../../../hooks/ReactQueryHooks/useOrgQuery";
import { FirstParentSelector } from "./FirstParentSelector";
import { ParentTreeSelector } from "./ParentTreeSelector";
import { Tooltip, Switch } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import TextArea from "../../common/textfield/TextArea";
import SelectFiled from "../../common/textfield/SelectFiled";
import { createContactNumber } from "../../../utils/phoneNumberSupport";
import InputFile from "../../common/textfield/InputFile";
import { useSelector } from "react-redux";
import { handleFileUpload } from "../../../utils/ImageToBase64";
import { useMedia } from "react-use";
import { SurveySettings } from "./SurveySettings";
import GetCareSettings from "./GetCareSettings";
import { ProductFeatures } from "./ProductFeatures";
import { SFTSettings } from "./SFTSettings";
import { useGetOrgLicensedProducts } from "../../../hooks/ReactQueryHooks/useGetOrgLicensedProducts";
import './organization.css';
import OrganizationCard from "./OrganizationCard";


function AddOrganization() {
	const navigate = useNavigate();
	const userOrgId = useSelector((state) => state?.auth?.user?.organizations?.[0]?.id);
	const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
	const [inputValue, setInputValue] = useState("");
	const [btnLoading, setBtnLoading] = useState(false)
	const [organizations, setOrganizations] = useState([]);
	const [country, setCountry] = useState('');
	const [region, setRegion] = useState('');
	const [selectedData, setSelectedData] = useState([]);
	const [firstParentSelected, setFirstParentSelected] = useState([]);
	const [parentInputsValue, setParentInputsValue] = useState([]);
	const [waitingRoomTimings, setWaitingRoomTimings] = useState(operationTiming);
	const [waitingRoomTimingsError, setWaitingRoomTimingsError] = useState(false);
	const [file, setFile] = useState();
	const isMobile = useMedia('(max-width: 768px)');
    const [selectedTab, setSelectedTab] = useState("Details");

	const [rpmSwitch, setRpmSwitch] = useState(false);
	const [virtualCareSwitch, setVirtualCareSwitch] = useState(false);
	const [callCenterSwitch, setCallCenterSwitch] = useState(false);
	const { data: orgLicensedProducts } = useGetOrgLicensedProducts({})

	// const [sftSwitch, setSftSwitch] = useState(false);
	const [delivertoIndividualSwitch, setDelivertoIndividualSwitch,] = useState(false);
	const [delivertoLocationSwitch, setDelivertoLocationSwitch] = useState(false);

	const onSuccessOrg = (data) => {
		setOrganizations(data?.map((org) => (org = { ...org, value: org.id })));
	};
	useOrgQuery({ onSuccess: onSuccessOrg });

	const formik = useFormik({
		...FormikObj,
		validationSchema: () => getValidationSchema(selectedTab),
		onSubmit: async (values) => {
		if(selectedTab === "Products"){
			const isHoursOfOperationFalse = !!values.isWaitingRoom && !!!values.hoursOfOperation
			let hasValues = Object.values(waitingRoomTimings).some(value => value?.startTime || value?.endTime)
			if (isHoursOfOperationFalse && !hasValues) {
				setWaitingRoomTimingsError(true)
			} else {
				setWaitingRoomTimingsError(false)
				setBtnLoading(true);
				let base64OrgLogo = "";
				if (values?.orgLogoURL) {
					base64OrgLogo = await handleFileUpload(values?.orgLogoURL);
				}
				const fileExtension = values?.orgLogoURL?.type?.split("/")[1];
				const orgLogoURL = {
					data: base64OrgLogo,
					extension: fileExtension // Add the file extension to the object
				};
				const deviceUnit = getDeviceUnit(values);
				if (values.typeOfDeviceUnitType === "group")
					deviceUnit["groupName"] = values.deviceUnitType;
				const parentOrg = organizations.filter(
					(org) => org?.id === selectedData[selectedData.length - 1]
				)?.[0];
				values.phone_number = createContactNumber(values)
				let sft = values.sftEnabled
					? {
						"enabled": values.sftEnabled,
						"individualDelivery": delivertoIndividualSwitch,
						"locationDelivery": delivertoLocationSwitch,
						"expirationDays": values.expirationDays,
						"privacyStatement": values.privacyStatement,
						"supportStatement": values.supportStatement
					}
					: {
						"enabled": values.sftEnabled,
						// "privacyStatement": values.privacyStatement,
						// "supportStatement": values.supportStatement
					};


				let appointmentAutoAcceptance = { 
					"patientInitiated": values?.patientApptAutoAcceptance, //(via the Mobile App) 
					"practitionerInitiated": values?.practitionerApptAutoAcceptance //(via the Web UI) 
					} 

				createOrganization(values, parentOrg, deviceUnit, orgLogoURL, isHoursOfOperationFalse ? transformTimingData(waitingRoomTimings) : null, sft, appointmentAutoAcceptance)
					.then((res) => {
						const requestBody = {
							licensedProduct: {
								"RPM": rpmSwitch,
								"virtualCare": virtualCareSwitch,
								"callCenter": callCenterSwitch
							},
							//sft: Checksft,
							orgId: res.data.id,
						};
						createDiscover(requestBody).then((res) => {
							// navigate("/app/manage-organization");
							// success(res.message);
						}).catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
							.finally(() => setBtnLoading(false));
						navigate("/app/manage-organization");
						success(res.message);
					})
					.catch((res) => {
						failed(
							res?.response?.data?.message ||
							res?.response?.data?.error ||
							res.message
						);
					})
					.finally(() => setBtnLoading(false));
			}
		}
		},
	});

	useEffect(() => {
        if (formik.values.hoursOfOperation) {
            setWaitingRoomTimingsError(false);
        }
    }, [formik.values.hoursOfOperation]);

	useEffect(() => {
        if (waitingRoomTimings && Object.values(waitingRoomTimings).some(value => value?.startTime || value?.endTime)) {
            setWaitingRoomTimingsError(false);
        }
    }, [waitingRoomTimings]);

	const handleUpdate = (selectedId, parentId, index) => {
		let selected = selectedData;
		if (selectedId) {
			selected[index + 1] = selectedId;
			selected = selected.slice(0, index + 2);
			setSelectedData(selected);
			setParentInputsValue(
				filterKeysFunc(parentId, selectedId, parentInputsValue, selectedData)
			);
		} else if (selectedData.length > index + 1) {
			selected = selected.slice(0, index + 1);
			setSelectedData(selected);
			setParentInputsValue(
				filterKeysFunc(parentId, "", parentInputsValue, selectedData)
			);
		}
	};
	const firstParentHandler = (selectedId) => {
		setFirstParentSelected(selectedId);
		setSelectedData([selectedId]);
	};

	const handleUnitOfDeviceChange = (key, value) => {
		formik.setFieldValue(key, value);
	};

	const handleTimeChange = (day, value, key) => {
		key === "startTime"
			? setWaitingRoomTimings({ ...waitingRoomTimings, [day]: { startTime: value, endTime: null } })
			: setWaitingRoomTimings({ ...waitingRoomTimings, [day]: { ...waitingRoomTimings[day], [key]: value } });
	};


	const handleCountryChange = (newCountry) => {
		setCountry(newCountry);
		if (newCountry !== formik.values.country) {
			formik.setFieldValue("state", ""); // Reset Formik state only if the country is changing
			formik.setFieldValue("postalCode", "");
			formik.setFieldValue("city", "");
			setRegion(""); // Reset component state only if the country is changing
			setInputValue("")
		}
	};
	const handlePostalCodeChange = (e) => {
		const { value } = e.target;
		// Remove spaces and non-alphanumeric characters
		const formattedValue = value.replace(/[^A-Za-z0-9]/g, "");
		// Add a space after every 3 letters
		const spacedValue = formattedValue.replace(
			/^([A-Za-z0-9]{3})([A-Za-z0-9]+)/,
			"$1 $2"
		);
		// Set the formatted value in the state or formik
		setInputValue(spacedValue);
		formik?.setFieldValue("postalCode", spacedValue);
	};

	const handleSwitchChange = (setter) => {
		setter((prevValue) => !prevValue);
	};
	// let margLeft =  formik.values.sftEnabled ? { marginLeft: '1px', marginRight: '-655px' } :  { marginLeft: '1px', marginRight: '-810px' } ;

	const handleSFTToggle = (event) => {
		formik?.setFieldValue("sftEnabled", event.target.checked);
	}

	const handleApptAcceptance = (event, type) => {
        if(type === "patientApptAutoAcceptance") {
            formik?.setFieldValue("patientApptAutoAcceptance", event.target.checked);
        } else if(type === "practitionerApptAutoAcceptance") {
            formik?.setFieldValue("practitionerApptAutoAcceptance", event.target.checked);
        }
    }

    /* Tabs */
	let tabTitles = [];
	if (userRoles?.includes('Super-Admin')) {
        tabTitles = ["Details", "Virtual Visit", "RPM", "File Transfer", "Products" ];
    }
	const tabs = tabTitles.map(title => ({
        id: title,
        title: title,
        onClick: () => {
            if (selectedTab === "Virtual Visit") {
                formik.validateForm().then(errors => {
                    let isHoursOfOperationFalse = !!formik.values.isWaitingRoom && !!!formik.values.hoursOfOperation;
                    let hasValues = Object.values(waitingRoomTimings).some(value => value?.startTime || value?.endTime)
					if (Object.keys(errors).length === 0 && !isHoursOfOperationFalse && hasValues) {
                        setSelectedTab(title);
                        setWaitingRoomTimingsError(false);
                    } else {
                        if (isHoursOfOperationFalse && !hasValues) {
                            setWaitingRoomTimingsError(true);
                        } else {
                            setWaitingRoomTimingsError(false);
							if(Object.keys(errors).length === 0) {
                                setSelectedTab(title);
                            }
                        }
                    }
                });
            } else {
                formik.validateForm().then(errors => {
                    if (Object.keys(errors).length === 0) {
                        setSelectedTab(title);
                    }
                });
            }
        },
        selected: selectedTab === title
    }));

    /* Previous button */
    const handlePrevious = () => {
        const currentIndex = tabTitles.indexOf(selectedTab);
        const previousTab = tabTitles[currentIndex - 1];
        setSelectedTab(previousTab);
    };

    /* Next button */
	const handleNext = () => {
        if (selectedTab === "Virtual Visit") {
            formik.validateForm().then(errors => {
                let isHoursOfOperationFalse = !!formik.values.isWaitingRoom && !!!formik.values.hoursOfOperation;
                let hasValues = Object.values(waitingRoomTimings).some(value => value?.startTime || value?.endTime)
				if (Object.keys(errors).length === 0 && !isHoursOfOperationFalse && hasValues) {
                    const currentIndex = tabTitles.indexOf(selectedTab);
                    const nextTab = tabTitles[currentIndex + 1];
                    setSelectedTab(nextTab);
                    setWaitingRoomTimingsError(false);
                } else {
                    if (isHoursOfOperationFalse && !hasValues) {
                        setWaitingRoomTimingsError(true);
                    } else {
                        setWaitingRoomTimingsError(false);
						if(Object.keys(errors).length === 0) {
                            const currentIndex = tabTitles.indexOf(selectedTab);
                            const nextTab = tabTitles[currentIndex + 1];
                            setSelectedTab(nextTab);
                        }
                    }
                }
            });
        } else {
            formik.validateForm().then(errors => {
            if (Object.keys(errors).length === 0) {
                const currentIndex = tabTitles.indexOf(selectedTab);
                const nextTab = tabTitles[currentIndex + 1];
                setSelectedTab(nextTab);
            }
        });
        }
    };
	const handleExt = (event) => {
        let key = event.key;
        let regex = new RegExp("^[0-9]+$");
        if (!regex.test(key)) {
          event.preventDefault();
          return false;
        }
      }; 
	
	return (
		<section className="common-listing">
			<div className="heading-wrap mb-3">
				<Tooltip title="Go back">
					<div
						style={{ marginRight: "1rem", cursor: "pointer" }}
						onClick={() => navigate(-1)}
					>
						<ArrowBack />
					</div>
				</Tooltip>
				<h1>{ORGANIZATION_DETAILS}</h1>
			</div>
			<div className="custom-card p-4 add-org-wrap">
				<form
					className="common-form border-fields"
					onSubmit={formik.handleSubmit}
				>
					<Row>
                        {tabs.map(tab => (
                            <Col key={tab.id} sm={4} md={3} lg={2} className="mb-3 mb-lg-0" onClick={tab.onClick}>
                                <OrganizationCard title={tab.title} selected={tab.selected} />
                            </Col>
                        ))}
                    </Row><br/>

					{/* Details start */}
					<div style={{ display: selectedTab === "Details" ? 'block' : 'none' }}>
					<Row>
						<Col>
							<FirstParentSelector
								selectedId={firstParentSelected}
								parentId={userOrgId}
								handleSelect={firstParentHandler}
							/>
						</Col>
						<Col></Col>
					</Row>
					{selectedData.map((parentId, index) => (
						<Row>
							<Col>
								<ParentTreeSelector
									selectedId={parentInputsValue?.[parentId]}
									parentId={parentId}
									handleSelect={handleUpdate}
									index={index}
									key={index}
								/>
							</Col>
							<Col></Col>
						</Row>
					))}

					<Row>
						<Col md={8} lg={4}>
							<TextInput
								keyField={"name"}
								label={NAME}
								formik={formik}
								placeholder={NAME}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<TextArea
								keyField={"description"}
								required={false}
								label={DESCRIPTION}
								formik={formik}
								placeholder={DESCRIPTION}
							/>
						</Col>
						<Col></Col>
					</Row>
					<Row>
						<Col>
							<TextInput
								keyField={"address"}
								label={ADDRESS}
								formik={formik}
								placeholder={ADDRESS}
							/>
						</Col>
						<Col> </Col>
					</Row>
					<Row>
						<Col>
							<CountrySelect
								country={country}
								setCountry={handleCountryChange}
								keyField={"country"}
								label={COUNTRY}
								required
								formik={formik}
							/>
						</Col>
						<Col>
							<RegionSelect
								country={country}
								setRegion={setRegion}
								region={region}
								keyField={"state"}
								defaultOptionLabel={
									formik?.values?.country === "US"
										? "Select State"
										: "Select Province"
								}
								label={formik?.values?.country === "US" ? "State" : "Province"}
								required
								formik={formik}
							/>
						</Col>
						<Col>
							<TextInput
								keyField={"city"}
								label={CITY}
								formik={formik}
								placeholder={CITY}
							/>
						</Col>
						<Col>
							{formik?.values?.country === "US" ? (
								<TextInput
									keyField={"postalCode"}
									label={
										formik?.values?.country === "US" ? ZIP_CODE : POSTAL_CODE
									}
									formik={formik}
									placeholder={
										formik?.values?.country === "US" ? ZIP_CODE : POSTAL_CODE
									}
									// max length for both zip code and postal code(plus space)
									maxLength={formik?.values?.country === "US" ? "5" : "7"}
								/>
							) : (
								<TextInput
									onChange={handlePostalCodeChange}
									value={inputValue}
									keyField={"postalCode"}
									label={
										formik?.values?.country === "US" ? ZIP_CODE : POSTAL_CODE
									}
									formik={formik}
									placeholder={
										formik?.values?.country === "US" ? ZIP_CODE : POSTAL_CODE
									}
									// max length for both zip code and postal code(plus space)
									maxLength={formik?.values?.country === "US" ? "5" : "7"}
								/>
							)}
						</Col>
					</Row>
					<Row>
						<Col>
							<TextInput
								keyField={"phone_number"}
								type="phone"
								label={PHONE_NUMBER}
								formik={formik}
								placeholder={PHONE_NUMBER}
							/>
						</Col>
						<Col>
							{/* <TextInput
								keyField={"ext"}
								type="ext"
								label={EXT}
								formik={formik}
								placeholder={EXT}
								required={false}
							/> */}
								<TextInput
                                keyField={"ext"}
                                label={"Ext."}
                                type="text"
                                maxlength="4"
                                formik={formik}
                                placeholder={"Ext."}
                                required={false}
                                onKeyPress={handleExt}
                                autocomplete="off"
                                />
						</Col>
						<Col>
							<TextInput
								keyField={"email"}
								label={EMAIL_ADDRESS}
								formik={formik}
								placeholder={EMAIL_ADDRESS}
							/>
						</Col>
					</Row>
					<Row>
						<Col style={{ maxWidth: "300px" }}>
							<InputFile accept="image/jpeg, image/png" keyField={"orgLogoURL"} label={"Upload Logo"} formik={formik} placeholder={"Upload Logo"} setFile={setFile} file={file} />
						</Col>
					</Row>
					</div>
					{/* Details End */}
					

					{/* RPM start */}
					<div style={{ display: selectedTab === "RPM" ? 'block' : 'none' }}>
						<Row>
							<Col>
								<SelectFiled
									keyField={"typeOfDeviceUnitType"}
									label={DEVICE_UNIT_TYPE}
									formik={formik}
									options={typeOfDeviceUnitTypeOptions}
									onChange={(e) =>
										formik.setFieldValue("typeOfDeviceUnitType", e.target.value)
									}
								/>
							</Col>
							<Col>
								{formik.values.typeOfDeviceUnitType === "group" ? (
									<SelectFiled
										keyField={"deviceUnitType"}
										label={TYPE}
										formik={formik}
										options={deviceUnitTypeOptions}
										onChange={(e) =>
											formik.setFieldValue("deviceUnitType", e.target.value)
										}
									/>
								) : null}
							</Col>
						</Row>
						{formik.values.typeOfDeviceUnitType === "individual" ? (
							<Row>
								{devicesField.map((field) => (
									<Col key={field.formikKey}>
										<SelectFiled
											keyField={field.formikKey}
											label={field.name}
											formik={formik}
											options={field.options}
											onChange={(e) =>
												handleUnitOfDeviceChange(field.formikKey, e.target.value)
											}
										/>
									</Col>
								))}
							</Row>
						) : null}
					</div>
					{/* RPM End */}

					{/* Virtual visit start */}
					<div style={{ display: selectedTab === "Virtual Visit" ? 'block' : 'none' }}>
						{userRoles?.includes('Super-Admin') || (userRoles?.includes('Practitioner') && orgLicensedProducts.virtualCare === true) ? <>
							<GetCareSettings formik={formik} waitingRoomTimings={waitingRoomTimings} waitingRoomTimingsError={waitingRoomTimingsError} isMobile={isMobile} handleTimeChange={handleTimeChange} />
							<hr />
							<SurveySettings formik={formik} />
							<hr />	</> : null}
						{userRoles?.includes('Super-Admin') || userRoles?.includes('Practitioner')  ?
						<>
							<Row>
								<Col><h5 style={{ color: "rgba(18, 31, 72, 1)" }}>Appointment Settings</h5></Col>
							</Row>
							<Row>
								<Col>
								<span >Auto Acceptance - Patient Initiated</span>
										<span style={{ marginRight: '135px' }}>&nbsp;&nbsp;</span>
										<Switch name="patientApptAutoAcceptance" onChange={(e) => handleApptAcceptance(e, "patientApptAutoAcceptance")}  />
								</Col>
							</Row>
							<Row>
								<Col>
								<span >Auto Acceptance - Practitioner Initiated</span>
										<span style={{ marginRight: '100px' }}>&nbsp;&nbsp;</span>
										<Switch name="practitionerApptAutoAcceptance" onChange={(e) => handleApptAcceptance(e, "practitionerApptAutoAcceptance")}  />
								</Col>
							</Row>
							<hr />
							<Row>
								<Col>
									<TextInput
										keyField={"appointment_cancel_time"}
										label={APPOINTMENT_CANCEL_TIME}
										formik={formik}
									/>
								</Col>
							</Row>
						</> : null}
					</div>
					{/* Virtual visit end */}

					{/* File Transfer start */}
					<div style={{ display: selectedTab === "File Transfer" ? 'block' : 'none' }}>
						<Row>
							<Col><h5 style={{ color: "rgba(18, 31, 72, 1)" }}>SFT Settings</h5></Col>
						</Row>

						<Row>
							<Col style={{ display: "flex", alignItems: "center", gap: "185px" }}>SFT
								<Switch name="sftEnabled" onChange={handleSFTToggle} />
							</Col>
						</Row>
						{formik.values.sftEnabled ?
							<>
								<SFTSettings
									delivertoIndividualSwitch={delivertoIndividualSwitch}
									delivertoLocationSwitch={delivertoLocationSwitch}
									onDeliverToIndSwitchChange={() => handleSwitchChange(setDelivertoIndividualSwitch)}
									onDeliverToLocSwitchChange={() => handleSwitchChange(setDelivertoLocationSwitch)}
								/>
								<Row>
									<Col>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<span>Expiration Days </span> <span style={{ color: "red" }}>*</span>
											<span style={{ marginRight: '85px' }}>&nbsp;&nbsp;</span>
											<Tooltip title="Expiration days must be between 1 to 7">
											<div className="expiration-days-input">
												<TextInput
												keyField={"expirationDays"}
												required = { formik.values.sftEnabled ? true : false}
												formik={formik}											
												
											/>
											</div>
											</Tooltip>
										</div>
									</Col>
								</Row>
								<Row>
									<Col>
										<TextArea
											keyField={"privacyStatement"}
											required={formik.values.sftEnabled ? true : false}
											label={'Privacy Statement'}
											formik={formik}
										/>
									</Col>
									<Col></Col>
								</Row>
								<Row>
									<Col>
										<TextArea
											keyField={"supportStatement"}
											required={formik.values.sftEnabled ? true : false}
											label={'Technical Support Statement'}
											formik={formik}
										/>
									</Col>
									<Col></Col>
								</Row>
							</> : null}
					</div>
					{/* File Transfer end */}

					{/* Products start */}    
					{userRoles?.includes('Super-Admin') && (
						<div style={{ display: selectedTab === "Products" ? 'block' : 'none' }}>
						{userRoles?.includes('Super-Admin') ? <>
							<ProductFeatures
								rpmSwitch={rpmSwitch}
								virtualCareSwitch={virtualCareSwitch}
								callCenterSwitch={callCenterSwitch}
								onRpmSwitchChange={() => handleSwitchChange(setRpmSwitch)}
								onVirtualCareSwitchChange={() => handleSwitchChange(setVirtualCareSwitch)}
								onCallCenterSwitchChange={() => handleSwitchChange(setCallCenterSwitch)}
							/>
							</> : null}
						</div>
					)}
					{/* Products end */}

					<div className="btn-wrap" style={{ display: "flex" }}>
                        {selectedTab === 'Details' && (
                            <Button onClick={() => navigate("/app/manage-organization")} variant="secondary">{CANCEL}</Button>
                        )}
                        {selectedTab !== 'Details' && (
                            <Button onClick={handlePrevious} variant="secondary">Previous</Button>
                        )}
                        {selectedTab !== 'Products' && (
                            <Button type="submit" onClick={handleNext} variant="primary">Save & Next</Button>
                        )}
                        {selectedTab === 'Products' && (
                            <Button type="submit" isLoading={btnLoading}>Save</Button>
                        )}
                    </div>
				</form>
			</div>
		</section>
	);
}

export default AddOrganization;