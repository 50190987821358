import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { axios } from "../../lib/axios";
import { onError } from "./onError";

export function usePatientQuery({ onSuccess }) {
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)
    const userType = useSelector((state) => state?.auth?.user?.['cognito:groups'])
    const org_id = userType.includes("Super-Admin") ? null : adminOrgs?.map(org => org.id)

    //fetch patients with org id or no id and for patients List
    const fetchPatients = (id = null) => {
        return axios.get(id ? `patient?org_id=${id}` : "patient");
    };
    const queryPatients = useQuery(
        "patients",
        () => fetchPatients(org_id),
        {
            select: (data) => data.result,
            onSuccess,
            onError
        }
    );

    return { ...queryPatients };
}
