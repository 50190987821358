import { useFormik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import TextInput from "../../common/textfield/TextInput";
import TextArea from "../../common/textfield/TextArea";
import { usePatientsBySOPQuery } from "../../../hooks/ReactQueryHooks/usePatientsBySOPQuery";
import SelectFiled from "../../common/textfield/SelectFiled";
import { Button } from "../../common/Button";
import { CircularProgress, Skeleton } from "@mui/material";
import { DateSelectorWithoutFormik } from "../../common/textfield/DateSelector";
import moment from "moment";
import { bookAppointment, checkSchedule, getSlots, rescheduleAppointment, getlistPractitioners } from "../api/AppointmentRequests";
import { getScheduleSlots, getSlotById, updateScheduleSlot} from "../../api/Schedule";
import { failed, success } from "../../common/Toastify";
import "../AppointmentRequests/style.css"
import { generatePass } from "../AppointmentRequests/Constants";
import { useSelector } from "react-redux";
import { getSignature } from "../../Zoom/functions";
import { updateAppointmentRequest } from '../api/AppointmentRequests';
import { Form } from "react-bootstrap";

const ReschduleAppointment = ({ modalShow, handleShow }) => {
    const user = useSelector((state) => state?.auth?.user);
    const [btnLoading, setBtnLoading] = useState(false);
    const [slotsLoading, setSlotsLoading] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState("");
    const [serviceCategory, setServiceCategory] = useState([]);
    const [reservedCategories, setReservedCategories] = useState ({})
    const [practitionersList, setpractitionersList] = useState([]);
    const [availableSlots, setAvailableSlots] = useState([]);
    const preferredChannelType = modalShow?.intakeQuestions?.preferredChannel === "phone" || modalShow?.intakeQuestions?.preferredChannel === "Phone";

    const formik = useFormik({
        initialValues: {
            title: "",
            patient: "",
            // description: "",
            date: "",
            serviceCategory: "",
            availableSlot: "",
            preferredChannel: modalShow?.intakeQuestions?.preferredChannel,
            returnTelephoneNumber: modalShow?.intakeQuestions?.callerNumber,
            returnTelephoneNumberExt: modalShow?.intakeQuestions?.callerNumberExt,
            practitioner: modalShow?.practitionerId,
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Required Reason for visit field"),
            patient: Yup.string().required("Required Patient field"),
            // description: Yup.string().required("Required Description field"),
            date: Yup.string().required("Required Date field"),
            serviceCategory: Yup.string().required("Required Appointment type field"),
            practitioner: Yup.string().required("Required Practitioner  field"),
            availableSlot: Yup.object().required("Required slots field"),
        }),
        onSubmit: (values) => {
            setBtnLoading(true);
            let slot = null;

            let start =`${moment(values?.date)?.format("YYYY-MM-DD")}T${(values?.availableSlot?.start)}:00Z`;
            let localStartTime = moment(start).format("HH:mm")
            let localStart = `${moment(values?.date)?.format("YYYY-MM-DD")} ${(localStartTime)}`;
            let utcStart = moment(localStart).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
            let end = `${moment(values?.date)?.format("YYYY-MM-DD")}T${(values?.availableSlot?.end)}:00Z`;
            let localEndTime = moment(end).format("HH:mm")
            let localEnd = `${moment(values?.date)?.format("YYYY-MM-DD")} ${(localEndTime)}`;
            let utcEnd = moment(localEnd).utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
            let selectedServiceCategory = values?.serviceCategory;
            if(selectedServiceCategory.endsWith("busyReserved")){
                selectedServiceCategory = selectedServiceCategory.split("-")?.[0];
            }
            selectedServiceCategory = serviceCategory.filter((categoryData) => categoryData.id === selectedServiceCategory)?.[0]
            
            let serviceCategoryPayload = [
                {
                  coding: [
                    {
                      system: "http://florizel.com/AppointmentServiceCategory",
                      code: selectedServiceCategory?.id,
                      display: selectedServiceCategory?.display ? selectedServiceCategory?.display : selectedServiceCategory?.name
                    }
                  ],
                  text: `Appointment for ${selectedServiceCategory?.display ? selectedServiceCategory?.display : selectedServiceCategory?.name}`
                }
            ]
            if(values?.availableSlot.status === "busy-reserved"){
                utcStart= values?.availableSlot?.start
                utcEnd = values?.availableSlot?.end
                slot = values?.availableSlot;
            }
            const requestedPeriod = [{start: utcStart, end: utcEnd}];
            let keepOldSlot = false;
            getSlotById(modalShow?.slotId)
            .then((response) =>{
            let data = response?.data?.[0]
            if(data?.serviceCategory?.[0]?.id){
                keepOldSlot = true;
            }
            rescheduleAppointment({serviceCategory: serviceCategoryPayload, keepOldSlot, slot,  appointmentId: modalShow?.id,  requestedPeriod , practitionerId:  values?.practitioner, description: values?.title })
                .then((res) => {
                    success("Appointment Rescheduled");
                    if(keepOldSlot){
                        updateScheduleSlot({
                            slotId : data?.id,
                            start: data?.start,
                            end: data?.end,
                            status: "busy-reserved",
                            serviceCategory: data?.serviceCategory
                        })
                        .catch(()=>{})
                    }
                    handleShow();

                })
                .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                .finally(() => setBtnLoading(false));
            })
            
            .catch(() => {})
        },
    });
    
    useEffect(() => {
        if (modalShow?.id) {
            formik.setFieldValue("title",  modalShow.description);
            formik.setFieldValue("patient", modalShow.participant?.filter((data) => data?.actor?.type === "Patient")?.[0]?.actor?.display) ;
            formik.setFieldValue("description", modalShow.comment || "");
            formik.setFieldValue("returnTelephoneNumber", modalShow?.intakeQuestions?.callerNumber || "");
            formik.setFieldValue("returnTelephoneNumberExt", modalShow?.intakeQuestions?.callerNumberExt || "");
            formik.setFieldValue("practitioner", modalShow?.practitionerId || "");
            
        }
    }, [modalShow])

    useEffect(() => {
        if (modalShow?.practitionerId) {
            getlistPractitioners({ orgId: modalShow?.orgId, scopeOfPractice: modalShow?.state })
                .then((res) => {
                    const practitioners = res.result.map(practitioner => ({
                        value: practitioner.id,
                        name: practitioner.name[0].text.charAt(0).toUpperCase() + practitioner.name[0].text.slice(1).toLowerCase()
                    })); 
                    setpractitionersList(practitioners);  
                })
                .catch((res) => {
                    failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                });
        }
    }, [modalShow])
    

    useEffect(() => {
        if (formik?.values?.date && formik?.values?.practitioner) { 
            checkSchedule({ actorId: formik?.values?.practitioner, date: moment(formik?.values?.date).format("YYYY-MM-DD") }) 
            .then((res) => { 
                    !res?.data && failed(res?.message)
                    
                    let categories = res?.data?.serviceCategory
                                    ?.filter((categoryData) => moment(categoryData?.date?.split("T")?.[0]).weekday() === moment(formik?.values?.date).weekday())
                                    ?.map((categoryData) => ({ ...categoryData, value: categoryData?.id }))
                    let reservedSlots ={}
                    let newData = [];
                    getScheduleSlots(res?.data?.id, "busy-reserved")
                    .then((resp) =>{
                        resp.data.filter((slot)=> moment(slot?.start).format("YYYY-MM-DD") === moment(formik?.values?.date).format("YYYY-MM-DD"))
                        .map((slot) => {
                            let serviceCategoryId = slot?.serviceCategory?.[0]?.id
                            
                            if (reservedSlots.hasOwnProperty(serviceCategoryId)) {
                                reservedSlots[serviceCategoryId].push({
                                    "start": slot?.start,
                                    "end": slot?.end,
                                    "id": slot?.id,
                                    "serviceCategory": slot.serviceCategory,
                                    "status": "busy-reserved"
                                });
                            } else {
                                reservedSlots[serviceCategoryId] = [{
                                    "start": slot?.start,
                                    "end": slot?.end,
                                    "id": slot?.id,
                                    "serviceCategory": slot.serviceCategory,
                                    "status": "busy-reserved"
                                }];
                            }
                        })

                        Object.keys(reservedSlots).forEach(key => {
                            let reservedCategory = reservedSlots[key]?.[0]?.serviceCategory?.[0];
                            newData.push({
                                name: `Reserved for ${reservedCategory?.name}`,
                                value: `${reservedCategory?.id}-busyReserved`,
                                id: reservedCategory?.id,
                                display: reservedCategory?.name,
                                status: "busy-reserved"
                            });
                            
                        });
                        setReservedCategories(reservedSlots);
                        setServiceCategory([...categories, ...newData])
                    })
                    .catch((res) => {
                        failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                    });

                })
                .catch((res) => {
                    failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                });
        }
    }, [formik?.values?.date, modalShow?.id,  formik?.values?.practitioner])



    const handleServiceCategory = (event) => {
        let targetVal = event?.target?.value;
        formik.setFieldValue("serviceCategory", targetVal);
        setSlotsLoading(true)
        if(targetVal.endsWith("busyReserved")){
            let currentTime = moment();
            let slots = reservedCategories[targetVal.split("-")[0]];
            slots = slots
                    .filter(slot => moment(slot.start).isAfter(currentTime))
                    .sort((a, b) => moment(a.start).diff(moment(b.start)));
            setAvailableSlots(slots)
            setSlotsLoading(false)
        }else{
            getSlots({ actorId: formik?.values?.practitioner, date: moment(formik?.values?.date).format("YYYY-MM-DD"), serviceCategoryId: targetVal })
                .then((res) => {
                    setAvailableSlots(res?.data?.availableSlots)
                }).catch((res) => {
                    failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                }).finally(() => { setSlotsLoading(false) })
        }
    };

    const handleDateChange = (val) => {
        const isValidDate = moment(val, 'MMM-DD-YYYY').isValid()
        if(isValidDate){
            formik.setFieldValue("date", val.format("YYYY-MM-DD"));
            formik.setFieldValue("serviceCategory", "");
            setAvailableSlots([])
        }else{
            formik.setFieldError('date', 'Invalid date format')
        }
    } 

    const handlePractitionerChange = (event) => {   
        formik.setFieldValue("practitioner", event?.target?.value );
        formik.setFieldValue("serviceCategory", ""); 
        setAvailableSlots([])
    } 
    return (
        <Modal
            backdropClassName
            backdrop={'static'}
            size="xl" show={modalShow} onHide={handleShow}
            aria-labelledby="contained-modal-title-vcenter"
            centered className="custom-dialog">
            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">
                    Rescheduling Appointment
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="common-form border-fields" onSubmit={formik.handleSubmit}>
                    
                    <>
                        <Row>
                            <Col>
                                <TextInput keyField={"title"} label={"Reason for visit"} formik={formik} placeholder={"Reason for visit"} />
                            </Col>
                            <Col>
                                <TextInput disabled = {true} keyField={"patient"} label={"Patient Name"} formik={formik} placeholder={"Patient Name"} style={{textTransform: "capitalize"}}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <SelectFiled keyField={"practitioner"} label={"Select Practitioner"} formik={formik} options={practitionersList} onChange={handlePractitionerChange} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DateSelectorWithoutFormik formik={formik} keyField={'date'} label="Date" value={moment(formik?.values?.date)} handleChange={handleDateChange} minDate={moment()} />
                            </Col>
                            <Col>
                                <SelectFiled keyField={"serviceCategory"} label={"Appointment Type"} formik={formik} options={serviceCategory} onChange={handleServiceCategory} />
                            </Col>
                        </Row>
                        {availableSlots?.length > 0 ?
                                <>
                                    <Row>
                                        <Col>Select Slot</Col>
                                    </Row>
                                    <Row style={{/*  justifyContent: "center", */ rowGap: "0.5rem", marginBottom: "1.5rem", marginTop: "0.5rem" }}>
                                        {availableSlots?.map((slot, index) => {
                                        let time;
                                        let localTime;
                                        if(slot?.status === "busy-reserved"){
                                            localTime = moment(slot?.start).format("HH:mm");
                                        }else{
                                            time=`${moment(formik?.values?.date)?.format("YYYY-MM-DD")}T${(slot?.start)}:00Z`;
                                            localTime = moment(time).format("HH:mm");
                                        }
                                        
                                        return ( <Col style={{ background: selectedSlot === slot?.start ? "#6c757d38" : "#ffff", width: "14.666667%" }} sm={2} className="slotStyle" key={index} onClick={() => { formik?.setFieldValue("availableSlot", slot); setSelectedSlot(slot?.start) }}>{localTime}</Col>)
                                        })}
                                    </Row>
                                </>
                                : null}
                        {slotsLoading ?
                            <>
                                <Row>
                                    <Col>Select Slot</Col>
                                </Row>
                                <Skeleton height={50} />
                            </>
                            : null}
                        <Row>
                            {/* <Col>
                                <TextArea keyField={"description"} label={"Description"} formik={formik} placeholder={"Description"} />
                            </Col> */}
                            {modalShow?.intakeQuestions?.preferredChannel && (
                              <Col>
                                <Form.Check.Label style={{ textTransform: "capitalize", fontSize: "14px" }} >{"preferred channel ?"}</Form.Check.Label> <span className="requiredField">&#42;</span><br/>
                                {["phone", "video/chat"].map((val) => (
                                    <Form.Check style={{ textTransform: "capitalize", fontSize: "14px", marginRight: "65px"}} inline checked={preferredChannelType ? val === "phone" : val === "video/chat"} type={"radio"} label={val} id={val} name="preferred channel" disabled={true} />
                                ))}
                            </Col>  
                            )}
                            
                            {modalShow?.intakeQuestions?.callerNumber && (
                              <Col>
                                <Row>
                                    <Col>
                                        <TextInput keyField={"returnTelephoneNumber"} type="phone" label={"Return Telephone Number"} formik={formik} disabled={true} placeholder={"Return Telephone Number"} required={true} />
                                    </Col>
                                    <Col>
                                        <TextInput keyField={"returnTelephoneNumberExt"} label={"Ext."} formik={formik} disabled={true} placeholder={"Ext."} required={false} />
                                    </Col>
                                </Row>
                            </Col>  
                            )}
                        </Row>
                    </>
                    
                    <div className="btn-wrap">
                        <Button
                            onClick={() => {
                                handleShow();
                            }}
                            variant="secondary"
                            title="Cancel"
                        >
                            Cancel
                        </Button>
                        <Button type="submit" isLoading={btnLoading}>
                            Done
                        </Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default ReschduleAppointment;
