import React from 'react'
import { Modal, } from 'react-bootstrap'
import { withLocalTime } from '../../../utils/DateSupport'

const ViewUserNotificationModal = ({ modalShow, handleShow }) => {
    return (
        <Modal
            show={modalShow}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="custom-dialog"
            onHide={handleShow}
            backdropClassName
            backdrop={'static'}
        >
            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">
                    View Notification
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p style={{ fontSize: "1rem" }} >
                    {withLocalTime(modalShow?.message)}
                </p>
            </Modal.Body>
        </Modal>
    )
}

export default ViewUserNotificationModal