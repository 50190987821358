import React, { useContext, useEffect, useRef, useState } from "react";
import './message.css';
import './audio-video.css';
import AddUserIcon from '../../assets/images/add-user.png';
import AddNoteIcon from '../../assets/images/add-note.png';
import labIcon from '../../assets/images/chemistry.png';
import medicalIcon from '../../assets/images/medical-prescription.png';
import diagnosticIcon from '../../assets/images/analytics.png';
import referralIcon from '../../assets/images/referral.png';
import ExtendIcon from '../../assets/images/extend.svg';
import CallIcon from '../../assets/images/call-icon.svg';
import ZoomVideo from '@zoom/videosdk';
import ZoomContext from "../../ContextsAndProvider/Zoom-Context";
import { BindEvents, toggleFullscreen } from "./functions";
import { failed, info, success } from "../common/Toastify";
import { Tooltip } from "@mui/material";
import { MicOffOutlined, MicOutlined, VideocamOffOutlined, VideocamOutlined } from "@mui/icons-material";
import { InviteInSeesion } from "./InviteInSession";
import { Button } from "../common/Button";
import { AddNote } from "./AddNote";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FullPageSpinner } from "../common/Spinner/FullPageSpinner";
import { decryptData } from "../EncryptDecrypt";
import { useBeforeUnload } from "react-use";
import { endWaitingRoomMeeting } from "../apps/api/WaitingRoom";
import { updateAppointmentRequest } from "../apps/api/AppointmentRequests";
import { ZoomChatBox } from "./ZoomChatBox";
import QuestionnaireBox from "./QuestionnaireBox";
import { axios } from "../../lib/axios";
import { useGetOrgLicensedProducts } from "../../hooks/ReactQueryHooks/useGetOrgLicensedProducts";
import { getOrganization } from "../api/Organization";
import { getSingleUser } from "../api/Individual";
import moment from "moment";


function ZoomCreate() {
    const navigate = useNavigate()
    const { state } = useLocation();
    const [isInMeeting, setisInMeeting] = useState(false);
    useBeforeUnload(isInMeeting, 'You are in onGoing meeting, exit will end your meeting!');
    const { topic, sessionToken, password, appointmentData } = state
    const [modalShow, setModalShow] = useState(false);
    const [addNoteModal, setAddNoteModal] = useState(false);
    const [mediaStream, setMediaStream] = useState(null);
    const [chatClient, setChatClient] = useState(null);
    const [currentUser, setCurrentUser] = useState({});
    const [participants, setParticipants] = useState([]);
    const [isCameraLoading, setIsCameraLoading] = useState(false);
    const [currentSession, setCurrentSession] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [loadingText, setLoadingText] = useState('');
    const [sessionChat, setSessionChat] = useState([]);
    const [showChat, setShowChat] = useState(state?.isWaitingRoom ? false : true);
    const [isCameraOn, setIsCameraOn] = useState(false);
    const [isMicOn, setIsMicOn] = useState(false);
    const chatInput = useRef();
    const [isAudioStreaming, setIsAudioStreaming] = useState(false);
    const [orgnization, setOrganizations] = useState({})
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)
    const zmClient = useContext(ZoomContext);
    const audioDecode = useRef();
    const audioEncode = useRef();
    const user = useSelector((state) => state?.auth?.user);
    const practitionerName = appointmentData?.participant.filter((data) => data?.actor?.type === "Practitioner")?.[0]?.actor?.display;
    const practitionerId = appointmentData?.participant.filter((data) => data?.actor?.type === "Practitioner")?.[0]?.actor?.reference?.split("/")?.[1];
    const patientId = appointmentData?.participant.filter((data) => data?.actor?.type === "Patient")?.[0]?.actor?.reference?.split("/")?.[1];
    const patientName = appointmentData?.participant.filter((data) => data?.actor?.type === "Patient")?.[0]?.actor?.display;
    const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
    const permissions = useSelector((state) => state?.auth?.user?.permissions);
    const admin = userRoles?.includes('Super-Admin');
    const { isFetched, data: orgLicensedProducts } = useGetOrgLicensedProducts({})
    const isVirtualCare = admin ? false : orgLicensedProducts?.virtualCare
    const [practionerData, setPractionerData] = useState({})
    const startVideo = () => {
        setIsCameraLoading(true)
        if (mediaStream.isCameraTaken() && mediaStream.isCaptureForbidden()) {
            failed("Check your camera, either it is taken or not available or don't have permission!", 4000);
        } else {
            // console.log(mediaStream?.isSupportVirtualBackground());
            mediaStream.startVideo({ videoElement: document.querySelector('#self-view-video'), virtualBackground: mediaStream?.isSupportVirtualBackground() ? { imageUrl: 'blur' } : false }).then(() => {
                // console.log("----------------current user started video----------");
                // console.log(zmClient.getAllUser());
                setIsCameraOn(true);
                setIsCameraLoading(false)
            }).catch((error) => {
                console.log("---------camera error----------", error);
                setIsCameraLoading(false)
                failed("Check your camera, either it is taken or not available or don't have permission!", 4000);
            })
        }
    }

    const startAudio = () => {
        var isSafari = window.safari !== undefined
        if (isSafari) {
            if (audioEncode.current && audioDecode.current) {
                mediaStream.startAudio()
                setIsAudioStreaming(true);
            } else {
                // console.log('safari audio has not finished initializing')
            }
        } else {
            mediaStream.startAudio()
            setIsAudioStreaming(true);
        }
    }
    useEffect(() => {
        const init = async () => {
            await zmClient.init('en-US');
            try {
                if (!currentSession) {
                    setIsLoading(true);
                    setLoadingText('Joining the session...');
                    setisInMeeting(true)
                    await zmClient.join(decryptData(topic), sessionToken, decryptData(user?.name?.[0]?.text?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')), decryptData(password)).then((res) => {
                        setCurrentSession(res);
                        setCurrentUser(zmClient.getCurrentUserInfo());
                        setIsLoading(false);
                        const chat = zmClient.getChatClient();
                        setSessionChat(chat.getHistory());
                        const stream = zmClient.getMediaStream();
                        setParticipants(zmClient.getAllUser())
                        zmClient.on('chat-on-message', (payload) => {
                            setSessionChat(payload?.sender?.userId === zmClient?.getCurrentUserInfo()?.userId ? chat.getHistory() : [...chat.getHistory(), payload])
                        })

                        zmClient.on('media-sdk-change', (payload) => {
                            if (payload.type === 'audio' && payload.result === 'success') {
                                if (payload.action === 'encode') {
                                    audioEncode.current = true
                                } else if (payload.action === 'decode') {
                                    audioDecode.current = true
                                }
                            }
                        })

                        // if any user joins the session
                        zmClient.on('user-added', (payload) => {
                            localStorage.setItem("userJoined",1)
                            if(payload[0]?.isHost === true) {
                                if(localStorage.getItem("userJoined") !== 1 || localStorage.getItem("userJoined") === null){
                                   payload[0].userId !== currentUser?.userId && info(`New User Joined, ${payload[0].displayName}`)
                                }
                            }else{
                                payload[0].userId !== currentUser?.userId && info(`New User Joined, ${payload[0].displayName}`)
                            }
                            setParticipants(zmClient.getAllUser())
                            // console.log("----- User Joined ----->", payload[0].userId, payload[0].displayName);
                        })

                        // if user left or removed from the session
                        zmClient.on('user-removed', (payload) => {
                            //info(`${payload[0]?.userId} left the session`)
                            setParticipants(zmClient.getAllUser())
                            // console.log(payload[0]?.userId, ' left the session', zmClient.getAllUser());
                        })

                        BindEvents(zmClient, stream)
                        setMediaStream(stream);
                        setChatClient(chat);
                    }).catch((err) => {
                        setIsLoading(false);
                        failed(err.reason || err.type)
                        navigate(-1)
                    });
                }
            } catch (e) {
                setIsLoading(false);
                failed(e.reason);
                navigate(-1)
            }
        };
        if (topic && sessionToken && password) init();
        return () => {
            ZoomVideo.destroyClient();
            zmClient.off('user-removed', () => { })
            zmClient.off('user-added', () => { })
            zmClient.off('media-sdk-change', () => { })
            zmClient.off('chat-on-message', () => { })
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [password, sessionToken, topic, user.name, zmClient]);

    useEffect(() => {
        const apiBody = {
          id: user?.["custom:unique_id"],
          type: "Practitioner",
        };
        getSingleUser(apiBody)
          .then((res) => {
            setPractionerData(res?.data)
          })
          .catch((res) => {
            console.log("error in image upload",res)
          })
    }, []);

    const handleCamera = () => {
        isCameraOn ? mediaStream.stopVideo() : startVideo()
        isCameraOn && setIsCameraOn(false)
    }
    const handleMic = () => {
        !isAudioStreaming && startAudio()
        isMicOn ? mediaStream.muteAudio() : mediaStream.unmuteAudio();
        setIsMicOn(!isMicOn)
    }

    const handleEndSession = () => {
        localStorage.removeItem("questionResponseId");
        setIsLoading(true)
        setLoadingText("Ending Session")
        localStorage.removeItem("userJoined")
        zmClient.leave(true).then(() => {
            document.getElementById("participants-view").replaceChildren();
            state?.isWaitingRoom && endWaitingRoomMeeting({ id: state?.id, patientId: state?.patientId })
                .then((res) => success(res.message))
                .catch((err) => console.log(err))
            
            if (state?.appointmentData) {
                const requestedPeriod = [{start: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"), end: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]")}] 
                return updateAppointmentRequest({ encounterId: state?.encounterId, id: state?.appointmentData?.id, status: "completed", patientId, practitionerId, sessionToken: "" })
                    .then(async(res) => { 
                        if(res?.status === true){
                            const actor = {
                                display: practionerData.name[0].text,
                                reference: `Practitioner/${practitionerId}`,
                            };
                            await axios.put(`encounter/${state?.encounterId}`, { status: "finished", actor: actor, requestedPeriod, period: {start: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"), end: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]")} })
                            navigate("/app/encounter-details",{ state: { encounterId: state?.encounterId, patientId: patientId, practionerData, practitionerId: practitionerId } })
                        }
                    })
                    .catch((err) => console.log(err?.response?.data?.message || err?.response?.data?.error || err?.message))
            }
        }).catch((err) => console.log(err)).finally(() => {
            setIsLoading(true)
            setLoadingText("Ending Session")
        })
    }
    const handleSendMsg = (e) => {
        e.preventDefault();
        chatInput.current.value ? chatClient.sendToAll(chatInput.current.value) : chatInput.current.focus();
        chatInput.current.value = ""
    }
    useEffect(() => {
        participants.forEach((participant) => {
            if (participant.userId === currentUser.userId) return null
            const CanvasElem = document.querySelector(`#participant-canvas-${participant.userId}`)
            const AvatarElem = document.querySelector(`#participant-avatar-${participant.userId}`)
            if (participant.bVideoOn) {
                AvatarElem.style.display = "none";
                mediaStream.renderVideo(CanvasElem, participant.userId, 960, 540, 0, 0, 2)
            } else {
                CanvasElem.style.display = "none";
            }
        })
    }, [currentUser.userId, mediaStream, participants])

    useEffect(() => {
        getOrganization(adminOrgs?.[0]?.id)
            .then((res) => {
                setOrganizations(res.data);
            })
            .catch((res) => {
                failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
            });
      }, []);

    const tempStyleParticipants = {
        gridTemplateColumns: "repeat(2,1fr)",
        gridTemplateRows: "repeat(2,1fr)",
        display: "grid",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "0",
    }

    const handleView = (data) => {
        const baseUrl = window.location.origin;
        const patientId = data?.patientId;
        const patientName = data?.participant[0]?.actor?.display;
        const url = `${baseUrl}/app/patients-details?patientId=${patientId}&patientName=${patientName}`;
        window.open(url, "_blank");
    };

    const handleServiceRequest = (requestType) => {
        window.open(`/app/encounter-service-request?encounterId=${state?.encounterId}&practitionerName=${practitionerName}&patientName=${patientName}&patientId=${appointmentData?.patientId}&requestType=${requestType}&appointmentId=${appointmentData?.id}&fromPatient=false`, '_blank')
    }
    const handleMedicationRequest = () => {
        window.open(`/app/encounter-medication-request?encounterId=${state?.encounterId}&practitionerName=${practitionerName}&practitionerId=${practitionerId}&patientName=${patientName}&patientId=${patientId}&fromPatient=false`, '_blank')
    }

    if (!(topic && sessionToken && password)) {
        return <h1>Do not have the meeting details!</h1>
    }


    return (
        <section className="common-listing">
            {isLoading && <FullPageSpinner loadingText={loadingText} />}
            <div className="heading-wrap diff-style">
                <h1>
                    <span className="big-head" style={{ textTransform: "capitalize" }}>Welcome {currentUser.displayName}</span>
                </h1>

                {state?.isWaitingRoom ? null : <div className="right-wrap">
                    <Button className="custom-btn" title="Open Dashboard in a new tab" variant="primary" onClick={() => handleView(appointmentData)}>Patient Dashboard</Button>

                    <Button className="custom-btn" title="Invite" variant="primary" onClick={() => setModalShow(true)}> <img src={AddUserIcon} alt="Prev Arrow" />Invite</Button>
                    <InviteInSeesion
                        show={modalShow}
                        topic={decryptData(topic)}
                        password={decryptData(password)}
                        onHide={() => setModalShow(false)}
                    />
                    <Button className="custom-btn ms-2" title="Add Note" variant="primary" style={{ padding: "0" }} onClick={() => setAddNoteModal(true)}>+<img src={AddNoteIcon} alt="Prev Arrow" /></Button>
                    <AddNote
                        show={addNoteModal}
                        encounterId={state?.encounterId}
                        patientName={currentUser.displayName}
                        onHide={() => setAddNoteModal(false)}
                    />
                    {isVirtualCare && permissions.includes("Create Prescriptions") && (<Button className="custom-btn ms-2" title="Create Prescription" variant="primary" style={{ padding: "0" }} onClick={()=>  orgnization?.sft?.enabled && (orgnization?.sft?.individualDelivery || orgnization?.sft?.locationDelivery) ?  handleMedicationRequest() : (orgnization?.sft?.enabled && (!orgnization?.sft?.individualDelivery && !orgnization?.sft?.locationDelivery)) ? failed("Please enable SFT delivery options.") : failed("Please enable SFT to send document.")}><img src={medicalIcon} alt="Prev Arrow" /></Button>)}
                    {isVirtualCare && permissions.includes("Create Lab Requisitions") && (<Button className="custom-btn ms-2" title="Add Lab service request" variant="primary" style={{ padding: "0" }} onClick={() => orgnization?.sft?.enabled && (orgnization?.sft?.individualDelivery || orgnization?.sft?.locationDelivery)? handleServiceRequest("Lab") : (orgnization?.sft?.enabled && (!orgnization?.sft?.individualDelivery && !orgnization?.sft?.locationDelivery)) ? failed("Please enable SFT delivery options.") : failed("Please enable SFT to send document.") }><img src={labIcon} alt="Prev Arrow" /></Button>)}
                    {isVirtualCare && permissions.includes("Create Diagnostic Imaging Requests") && (<Button className="custom-btn ms-2" title="Add Diagnostic service request" variant="primary" style={{ padding: "0" }} onClick={() => orgnization?.sft?.enabled && (orgnization?.sft?.individualDelivery || orgnization?.sft?.locationDelivery)? handleServiceRequest("Diagnostic") : (orgnization?.sft?.enabled && (!orgnization?.sft?.individualDelivery && !orgnization?.sft?.locationDelivery)) ? failed("Please enable SFT delivery options.") : failed("Please enable SFT to send document.")}><img src={diagnosticIcon} alt="Prev Arrow" /></Button>)}
                    {isVirtualCare && permissions.includes("Create Service Referrals") && (<Button className="custom-btn ms-2" title="Add Referral service request" variant="primary" style={{ padding: "0" }} onClick={() => orgnization?.sft?.enabled && (orgnization?.sft?.individualDelivery || orgnization?.sft?.locationDelivery)? handleServiceRequest("Referral") : (orgnization?.sft?.enabled && (!orgnization?.sft?.individualDelivery && !orgnization?.sft?.locationDelivery)) ? failed("Please enable SFT delivery options.") : failed("Please enable SFT to send document.")}><img src={referralIcon} alt="Prev Arrow" /></Button>)}
                    <Button className="custom-btn ms-2" title={`Open ${showChat ? "Questionnaire" : "Chat"}`} variant="primary" onClick={() => setShowChat(!showChat)}>Open {showChat ? "Questionnaire" : "Chat"}</Button>
                </div>}
            </div>

            <div className="call-chat-outer">
                <div className="call-screen-wrap" style={{ aspectRatio: "16 / 9", width: "60%"}} id={'room'}>

                    <div className="my-view-block d-flex h-100 position-relative">
                        {/* -----------user's video------------- */}
                        <div className="my-view-video flex-fill d-flex justify-content-center">
                            <div className="inner">
                                <video className={`${participants.length > 1 ? "video-wrap" : ""} main-video-wrap`} id="self-view-video"></video>
                                {/*<canvas width="1920" height="1080" className="main-video-wrap" id="self-view-canvas"></canvas>*/}
                            </div>
                        </div>
                    </div>
                    {/* -----------participant's video------------- */}
                    <div id="participants-view" style={{ width: "100%" }}>
                        <div id="participants-view-inner" style={participants.length > 2 ? tempStyleParticipants : null}>
                            {participants.map((participant) => {
                                const name = participant?.displayName?.split(' ');
                                if (participant.userId === currentUser.userId) return null
                                return (
                                    <div className="participant-view" id={`${participant.userId}`} key={participant.userId}>
                                        <div className="dropdown-container" style={{ display: "none" }}>
                                            <div className="three-dots"></div>
                                            <div className="dropdown">
                                                <div id={`pin-${participant.userId}`}>Pinned</div>
                                            </div>
                                        </div>
                                        <canvas style={{ display: participant.bVideoOn ? "block" : "none" }} className="participant-canvas" id={`participant-canvas-${participant.userId}`}></canvas>
                                        <div style={{ display: participant.bVideoOn ? "none" : "flex" }} className="participant-avatar" id={`participant-avatar-${participant.userId}`}><div>{name?.[0]?.[0] || ""}{name?.[1]?.[1] || ""}</div></div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {/* -----------Tool bar for handling the session actions------------- */}
                    <div className="tool-wrap">
                        <Tooltip title="Speaker" onClick={handleMic}><span>{isMicOn ? <MicOutlined sx={{ color: "grey" }} /> : <MicOffOutlined sx={{ color: "grey" }} />}</span></Tooltip>
                        <Tooltip title="Video Cam" onClick={() => !isCameraLoading && handleCamera()}><span>{isCameraOn ? <VideocamOutlined sx={{ color: "grey" }} /> : <VideocamOffOutlined sx={{ color: "grey" }} />}</span></Tooltip>
                        {/*<Tooltip title="Screen Share" onClick={handleScreenShare}><span>{isScreenShareOn ? <CancelPresentationOutlined sx={{ color: "grey" }} /> : <PresentToAllOutlined sx={{ color: "grey" }} />}</span></Tooltip>*/}
                        {/*{state?.isWaitingRoom ? null : <Tooltip title="Chat" onClick={() => setShowChat(!showChat)}><span><img src={ChatIcon} alt="Chat" /></span></Tooltip>}*/}
                        <Tooltip title="Full Screen" onClick={toggleFullscreen}><span><img src={ExtendIcon} alt="Full Screen" /></span></Tooltip>
                        <Tooltip className="call-wrap" title="End Call" onClick={handleEndSession}><span><img src={CallIcon} alt="Call" /></span></Tooltip>
                    </div>
                </div>
                <ZoomChatBox show={showChat} currentUser={currentUser} sessionChat={sessionChat} chatInput={chatInput} handleSendMsg={handleSendMsg} />
                <QuestionnaireBox show={!showChat} onHide={() => setShowChat(true)} encounterId={state?.encounterId} patientId={state?.appointmentData?.patientId} appointmentId={appointmentData?.id} slotStart={appointmentData?.requestedPeriod?.[0]?.start} slotEnd={appointmentData?.requestedPeriod?.[0]?.end} patientName={appointmentData?.participant?.[0]?.actor?.display} />
            </div>
        </section>
    );
}

export default ZoomCreate;