import * as Yup from "yup";

export const recipientOptions = [
    // { value: "users", id: "users", name: "All users" },
    { value: "patient", id: "patient", name: "Patient" },
    { value: "relatedperson", id: "relatedperson", name: "Related person" },
    { value: "practitioner", id: "practitioner", name: "Practitioner" },
];
export const deliveryOptions = [
    { value: "email", id: "email", name: "Email" },
    { value: "push_notification", id: "push_notification", name: "Push Notification" },
    { value: "sms", id: "sms", name: "SMS" },
];

export const notificationTypeOptions = [
    { value: "default", name: "standard out of the box" },
    { value: "custom", name: "Custom notification" },
];

export const FormikObj = {
    initialValues: {
        type: "custom",
        orgId: "",
        eventId: "",
        recipients: [],
        deliveryMechanisms: [],
        customGroupId : [],
        title: "",
        content: "",
        emailSubject: "",
        emailContent: "",
    },
    validationSchema: Yup.object({
        type: Yup.string()
            .required("Required Notification type field")
            .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
        orgId: Yup.string().when(["type"], (type, schema) =>
            type === "custom"
                ? schema.required("Required Organization field")
                : schema),
        recipients: Yup.array().min(1)
            .required("Required Recipants field"),
        eventId: Yup.string()
            .required("Required Event field")
            .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
        deliveryMechanisms: Yup.array().min(1)
            .required("Required Delivery Mechanisms field"),
        title: Yup.string()
            .max(50, "Must be 50 characters or less")
            .required("Required Notification title field")
            .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
        content: Yup.string().when(["deliveryMechanisms"], (deliveryMechanisms, schema) =>
            deliveryMechanisms.includes("push_notification") || deliveryMechanisms.includes("sms")
                ? schema.required("Required content field").matches(/^$|^\S+.*/, "Only blankspaces is not valid.")
                : schema
        ),
        emailSubject: Yup.string().when(["deliveryMechanisms"], (deliveryMechanisms, schema) =>
            deliveryMechanisms.includes("email")
                ? schema.required("Required email subject field")
                : schema
        ),
        emailContent: Yup.string().when(["deliveryMechanisms"], (deliveryMechanisms, schema) =>
            deliveryMechanisms.includes("email")
                ? schema.required("Required email content field")
                : schema
        ),
    }),
};
