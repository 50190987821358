import React, { useState } from "react";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setRefreshToken, userlogin } from "../slice/authService"
import { userLogin } from "../api/AuthService"
import { failed, success } from "../common/Toastify";
import TextInput from "../common/textfield/TextInput";
import { Button } from "../common/Button";
import User from '../../assets/images/person.png';
import Lock from '../../assets/images/Lock.png';
import './login.css';
import MfaRespondModal from "./MfaRespondModal";
import { getMFASettings } from "../api/MFA";
import { getOrganization } from "../api/Organization";
import MFASetupModal from "../MFA/MFASetupModal";
import FonemedLogo from "../../assets/images/FoneMed-Logo.jpg";
import { useSelector } from "react-redux";


function Login() {
    const [mfaResponseModalShow, setMFAResponseModalShow] = useState(false);
    const [authAppMFASessionToken, setAuthAppMFASessionToken] = useState("");
    const [username, setUsername] = useState("");
    const [mfaAuthenticationResponse, setMfaAuthenticationResponse] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [btnLoading, setBtnLoading] = useState(false);
    const [mfaSetupModalShow, setMfaSetupModalShow] = useState(false);
    const [loginFormShow, setLoginFormShow] = useState(true);
    const [mfaChallenge, setMfaChallenge] = useState("");

    const formik = useFormik({
        initialValues: {
            username: "",
            password: "",
        },
        validationSchema: yup.object({
            username: yup.string().required('Username Required').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
            password: yup.string().required('Password Required').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        }),
        onSubmit: (values) => {
            setBtnLoading(true);
            setUsername(values?.username);
            userLogin({ password: values.password, username: values.username?.toLowerCase() }).then((res) => {
                if (res.status === true) {
                    switch (res.authType) {
                        case "verify":
                            navigate("/auth/setpassword", { state: values });
                            break;
                        case "reset":
                            navigate("/auth/verifiedUserSetPass", { state: { username: values.username, session: res.session } });
                            break;
                        case "success":
                            let userResponse = res;
                            let orgId = res?.user?.organizations[0]?.id;
                            let isUserMFAEnabled = false;
                            let isOrgMFAEnabled = false;
                            let userRole = res?.user?.["cognito:groups"];
                            let admin = userRole?.includes("Super-Admin");

                            getMFASettings({ username: values?.username })
                                .then((res) => {
                                    if (res?.UserMFASettingList && res.UserMFASettingList.length > 0) {
                                        isUserMFAEnabled = true;
                                    }
                                    getOrganization(orgId)
                                        .then((res) => {
                                            if (res?.data?.mfaSettings?.enabled === true) {
                                                isOrgMFAEnabled = true;
                                            }
                                            if (isOrgMFAEnabled === true && isUserMFAEnabled === false) {
                                                setLoginFormShow(false);
                                                setMfaSetupModalShow(true);
                                            } else if (isUserMFAEnabled === false && admin) {
                                                setLoginFormShow(false);
                                                setMfaSetupModalShow(true);
                                            } else {
                                                dispatch(userlogin(userResponse.user));
                                                dispatch(setRefreshToken(userResponse?.refresh_token));
                                                success("Successfully Logged In");
                                            }
                                        })
                                        .catch((res) => {
                                            failed(
                                                res?.response?.data?.message ||
                                                res?.response?.data?.error ||
                                                res.message
                                            );
                                        });

                                })
                                .catch((res) => {
                                    failed(res?.message)
                                })
                            break;
                        case "mfa":
                            navigate("/auth/verifyMFA", { state: { username: values.username, password: values.password } });
                            break;
                        default:
                            break;
                    }
                    
                } else {
                    if (res.authType === 'expire') {
                        navigate("/auth/reset-temporary-pass", { state: { email: values?.username } });
                    }

                    if (res?.authType === "validate-mfa") {
                        setMfaChallenge(res?.mfaType)
                        setAuthAppMFASessionToken(res?.session);
                        setMFAResponseModalShow(true);
                    }

                    if (res.message !== "MFA is Enabled") {
                        failed(res.message);
                    }

                }

            }).finally(() => setBtnLoading(false));
        },
    });

    const handleMfaResponse = (data) => {
        setLoading(true);
        localStorage.setItem("authentication", data.token)
        dispatch(userlogin(data.user));
        success("Successfully Logged In");
    };

    return (
        <>
            {!loginFormShow && <>
                <div className="form-wrap">
                    <a href="#as" className="logo" title="Fonemed A Healthier Tomorrow">
                        <img src={FonemedLogo} alt="Fonemed A Healthier Tomorrow" />
                    </a>
                </div>
            </>}
            {loginFormShow &&
                <form className="custom-form" onSubmit={formik.handleSubmit}>
                    <h4>Login</h4>

                    <TextInput icon={User} keyField={"username"} label={"User name"} formik={formik} placeholder={"User name"} />
                    <TextInput type="password" icon={Lock} keyField={"password"} label={"Password"} formik={formik} placeholder={"Password"} />
                    <div className="page-link">
                        <Link to="/auth/forgotpassword" title="Forgot Password">Forgot Password?</Link>
                        <Link to="" title="Privacy Policy">Privacy Policy </Link>
                    </div>
                    <div className="btn-wrap" style={{ textAlign: "center" }}>
                        <Button type="submit" className="custom-btn" isLoading={btnLoading}>Login</Button>
                    </div>
                </form>
            }
            {mfaSetupModalShow && <MFASetupModal modalShow={mfaSetupModalShow} handleClose={() => {
                setMfaSetupModalShow(false);
                setLoginFormShow(true);
            }} isFromLogin={true} passwordFromLogin={formik?.values?.password} usernameFromLogin={formik?.values?.username} />}
            {mfaResponseModalShow && <MfaRespondModal modalShow={mfaResponseModalShow} handleClose={() => setMFAResponseModalShow(false)} username={username} session={authAppMFASessionToken} challengeName={mfaChallenge} onSuccess={handleMfaResponse} passwordFromLogin={formik?.values?.password} />}
        </>
    );
}

export default Login;