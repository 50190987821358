import { useQuery } from "react-query";
import { onError } from "./onError";
import { axios } from "../../lib/axios";
import moment from "moment";

export function usePatientEncounterHistoryQuery({ startDate, endDate, patientId, onSuccess, practitionerId, ...props }) {
    const fetch = () => {
        if(practitionerId) return axios.get(`encounter?id=${patientId}&practitionerId=${practitionerId}&startDate=${moment(startDate).format("YYYY-MM-DD")}&endDate=${moment(endDate).format("YYYY-MM-DD")}`)
        else return axios.get(`encounter?id=${patientId}&startDate=${moment(startDate).format("YYYY-MM-DD")}&endDate=${moment(endDate).format("YYYY-MM-DD")}`)
    }

    const query = useQuery(
        ["patient-medication", startDate, endDate, patientId],
        () => fetch(),
        {
            select: (data) => {
                return data?.data?.filter(item => item.status === 'finished');
            },
            onSuccess,
            onError,
            ...props
        }
    );

    return { ...query }
}