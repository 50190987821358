import React, { useState } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useFormik } from "formik";
import TextInput from "../../common/textfield/TextInput";
import SelectFiled from "../../common/textfield/SelectFiled";
import { useNavigate } from "react-router-dom";
import { Button } from "../../common/Button";
import { failed, success } from "../../common/Toastify";
import { FormikObj } from "./Constants";
import { useOrgQuery } from "../../../hooks/ReactQueryHooks/useOrgQuery";
import TextArea from "../../common/textfield/TextArea";
import { Tooltip } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useGetUsersByOrg } from "../../../hooks/ReactQueryHooks/useGetUsersByOrg";
import { decryptData } from "../../EncryptDecrypt";
import Select from 'react-select';
import { createGroup } from "../../api/Group";

function AddGroup() {
    const navigate = useNavigate();
    const [btnLoading, setBtnLoading] = useState(false)
    const [organizations, setOrganizations] = useState([]);
    const [usersList, setUsersList] = useState([]);

    const formik = useFormik({
        ...FormikObj,
        onSubmit: (values) => {
            setBtnLoading(true)
            createGroup({
                ...values,
                member: values?.member.map(item => ({
                    entity: {
                        id: item.id,
                        reference: `${item?.type?.[0]}/${item.id}`,
                        type: item?.type?.[0]
                    },
                    inactive: false,
                })),
                memberCount: values?.member.length,
            }).then((res) => {
                navigate("/app/manage-groups");
                success(res.message);
            }).catch(res => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                .finally(() => setBtnLoading(false));
        },
    });

    const onSuccessOrg = (data) => {
        setOrganizations(data?.map((org) => org = { ...org, "value": org.id }))
    };

    useOrgQuery({ onSuccess: onSuccessOrg });

    const onUsersSuccess = (data) => {
        setUsersList(data?.map((user) => ({ type: user.type, value: user.id, label: decryptData(user.name?.[0]?.text?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')), id: user.id })))
    };

    const { isLoading, isFetching, } = useGetUsersByOrg({ orgId: formik?.values?.orgId, onSuccess: onUsersSuccess, refetchOnWindowFocus: false, });

    return (
        <section className="common-listing">
            <div className="heading-wrap mb-3">
                <Tooltip title="Go back">
                    <div style={{ marginRight: "1rem", cursor: "pointer" }} onClick={() => navigate(-1)}>
                        <ArrowBack />
                    </div>
                </Tooltip>
            </div>
            <div className="custom-card p-4">
                <form className="common-form border-fields" onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col>
                            <TextInput keyField={"name"} label={"Name"} formik={formik} placeholder={"Name"} />
                        </Col>
                        <Col></Col>
                        <Col> </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SelectFiled keyField={"orgId"} label={"Select Organization"} formik={formik} options={organizations} />
                        </Col>
                        <Col>
                            <div className="field-wrap">
                                <label>Select Users</label>
                                <Select
                                    onChange={(data) => formik.setFieldValue("member", data)}
                                    isMulti
                                    isLoading={isLoading || isFetching}
                                    name="member"
                                    options={usersList}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                />
                                <div>{formik?.touched["member"] && formik?.errors["member"] ? <div className="error-text">{formik?.errors["member"]}</div> : null}</div>
                            </div>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextArea required={false} keyField={"description"} label={"Description"} formik={formik} placeholder={"Description..."} />
                        </Col>
                        <Col></Col>
                    </Row>
                    <div className="btn-wrap" style={{ display: 'flex' }}>
                        <Button onClick={() => navigate("/app/manage-groups")} variant="secondary" title="Cancel">Cancel</Button>
                        <Button type="submit" isLoading={btnLoading}>Create</Button>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default AddGroup;