import { axios } from "../../lib/axios";
import { encryptData } from "../EncryptDecrypt";

//Get Related Person Api Call
export const commonDisableAPI = ({ key, id, status = null, type = null }) => {
    return status
        ? axios.delete(`${key}/${id}?status=${status}`)
        : axios.delete(type
            ? `${key}/${id}?type=${type}`
            : `${key}/${id}`);
};

export const getPerson = ({ email = null, orgIds = null, isEnrollPatient = null }) => {
    if (isEnrollPatient && email && orgIds) {
        return axios.post("person/list", {
            email: encryptData(email?.toLowerCase()),
            orgIds,
            isEnrollPatient,
        })
    } else if (email) {
        return axios.post("person/list", { email: encryptData(email?.toLowerCase()) })
    } else if (orgIds) {
        return axios.post("person/list", { orgIds })
    }
}