import { Navigate, useLocation, useRoutes, useNavigate, Outlet } from 'react-router-dom';
import Login from '../components/Login/login';
import SFT from '../components/SFT/sft';
import { useSelector } from 'react-redux';
import { useProtectedRoutes } from './protected';
import { AuthAppLayout, publicRoutes } from './public';
import { useEffect } from 'react';
import { useQueryParams } from '../hooks/useQueryParams';
import ZoomJoinLandignPage from '../components/Zoom/ZoomJoinLandignPage';
import QuestionnaireBigBox from '../components/Zoom/QuestionnaireBigBox';
import Header from '../components/common/header/header';
import ServiceRequest from '../components/apps/ServiceRequest/ServiceRequest';
import CreateMedicationRequest from '../components/apps/MedicationRequest/CreateMedicationRequest';

const WrapperWithHeaderOnly = ({ children }) => {
    return (
        <div className="outer-wrapper">
            <Header hideBody={true} />
            <div className="body-outer">
                <div className="body-wrapper">
                    {children}
                </div>
            </div>
        </div>
    )
}

export const AppRoutes = () => {
    let queryParams = useQueryParams();
    let navigate = useNavigate();
    const { pathname } = useLocation();
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    const user = useSelector((state) => state?.auth?.user);
    const permissions = user?.permissions
    const redirectRoute = `/app/${permissions?.[0] === 'Appointment'
        ? permissions?.[1]?.toLowerCase()?.split(' ')?.join('-')
        : permissions?.[0] === 'Waiting Room Patients' || permissions?.[0] === 'Waiting Room Enrollment'
            ? 'waiting-room'
            : permissions?.[0] === 'Manage Provider Schedule & Appointments'
                ? 'providers-schedule-appointments'
                : permissions?.[0]?.toLowerCase()?.split(' ')?.join('-')}`;
    const afterLogedInRoute = user?.['cognito:groups']?.includes('Practitioner')
        ? '/app/dashboard'
        : user?.['cognito:groups']?.includes('Device')
            ? '/app/join-waiting-room-meet'
            : user?.['cognito:groups']?.includes('Super-Admin')
                ? '/app/dashboard'
                : '/login';

    const commonRoutes = [
        { path: '/app/zoom-meeting-join', element: queryParams.get('topic') && queryParams.get('password') ? <ZoomJoinLandignPage topic={queryParams.get('topic')} pass={queryParams.get('password')} /> : <h1>Do not have any seesion details!!</h1> },
        {
            path: '/app/zoom-question-tab', element: <WrapperWithHeaderOnly><QuestionnaireBigBox /></WrapperWithHeaderOnly>
        },
        {
            path: '/app/encounter-service-request', element: <WrapperWithHeaderOnly><ServiceRequest /></WrapperWithHeaderOnly>
        },
        {
            path: '/app/encounter-medication-request', element: <WrapperWithHeaderOnly><CreateMedicationRequest /></WrapperWithHeaderOnly>
        },
        { path: '/', element: isLoggedIn ? <Navigate to={afterLogedInRoute} /> : <Navigate to='/login' /> },
        { path: '/login', element: isLoggedIn ? <Navigate to={afterLogedInRoute} /> : <AuthAppLayout><Login /></AuthAppLayout> },
        { path: '/sft', element: <AuthAppLayout><SFT /></AuthAppLayout> },
        // { path: '/*', element: <Navigate to='/app/dashboard' /> },
    ];
    const { protectedRoutes } = useProtectedRoutes();
    const routes = isLoggedIn ? protectedRoutes : publicRoutes;
    const element = useRoutes([...routes, ...commonRoutes]);
    const publicPathNames = ['/', '/login', '/auth/setpassword', '/auth/verifyMFA', '/auth/forgotpassword', '/auth/resetpassword', '/app/zoom-meeting-join', '/sft'];
    useEffect(() => {
        if (!isLoggedIn && !publicPathNames.includes(pathname)) {
            // <Navigate to='/login' />;
            navigate('/login');
        }
    }, [isLoggedIn, pathname]);
    return <>{element}</>;
};
