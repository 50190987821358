import React, { useEffect, useState } from "react";
import { getOrganizationChild } from "../../api/Organization";

export const ParentTreeSelector = ({ selectedId, parentId, handleSelect, index }) => {
    const [organizations, setOrganizations] = useState([]);

    useEffect(() => {
        getOrganizationChild(parentId).then((res) => setOrganizations(res?.data)).catch((err) => console.log(err));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parentId]);

    return (
        <div className="field-wrap">
            <label htmlFor={parentId}>
                Parent (Optional)
            </label>
            <select id={parentId} placeholder={"select"} onChange={(e) => handleSelect(e.target.value, parentId, index)} value={selectedId}>
                <option value={""}>
                    {organizations.length > 0 ? "Select" : "No children"}
                </option>
                {organizations?.map(({ id, name }) => (
                    <option value={id} key={id}>
                        {name}
                    </option>
                ))}
            </select>
        </div>
    );
};
