import React, { useState } from "react";

const Checkbox = ({ label, keyField, formik, required = false, hideRequired = false,readOnly = false, checked = false, ...props }) => {
  const [isChecked, setIsChecked] = useState(checked)
  return (
    <div>
      <div className="customCheckBox">
        <label htmlFor={keyField}>
          {label}
          {hideRequired ? null : required ? <span style={{ color: "red" }}> *</span> : " (Optional)"}
        </label>
        <input
          type="checkbox"
          id={keyField}
          name={keyField}
          defaultChecked={isChecked}
          onChange={() => setIsChecked(!isChecked)}
          {...formik.getFieldProps(keyField)}
          {...props}
          style={{ width: "24px", height: "24px", }}
          disabled={readOnly}
        />
      </div>
      <div>
        {formik?.touched[keyField] && formik?.errors[keyField] ? (
          <div className="error-text">{formik?.errors[keyField]}</div>
        ) : null}
      </div>
    </div>
  );
};

export default Checkbox;
