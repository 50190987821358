import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import { Table } from "react-bootstrap";
import CustomPagination from "../../../common/components/Pagination/CustomPagination";
import { TableCell } from "@mui/material";
import { Button } from "../../../common/Button";

export function RelatedPerson({ modalShow, handleShow }) {
    const [page, setPage] = useState(0);
    const rowsPerPage = 5;
    return (
        <>
            <Modal
                backdropClassName
                backdrop={'static'}
                size="lg" show={modalShow} onHide={handleShow}
                aria-labelledby="contained-modal-title-vcenter"
                centered className="custom-dialog">
                <Modal.Header closeButton className="border-0">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Related Persons
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Button  
                        style={{fontSize: "14px"}}
                        onClick={()=>window.open("/app/create-individual", "_blank", "noopener,noreferrer")}
                        >Add Related Person</Button>
                    <div className="table-wrap">
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Relationship</th>
                            <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {modalShow?.length > 0 ? (
                            modalShow
                            ?.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            ?.map((item, index) => {
                                return (
                                <tr key={index}>
                                    <td style={{fontSize: "16px", textTransform: "capitalize"}}>
                                    {item?.name?.[0]?.given?.[0]}{" "}
                                    {item.name?.[0].family}
                                    </td>
                                    <td style={{fontSize: "16px"}}>
                                    {item?.telecom?.[1]?.value}
                                    </td>
                                    <td style={{fontSize: "16px"}}>{item?.relationship}</td>
                                    <td style={{fontSize: "16px"}}>{item?.active ? "Active" : "Disabled"}</td>
                                </tr>
                                );
                            })
                        ) : (
                            <tr>
                            <TableCell
                                colSpan="7" style={{textAlign: "-webkit-center"}}
                            >
                                No Data Available
                            </TableCell>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                    {modalShow?.length > 0 ? (
                        <div>
                        <CustomPagination
                            tableData={modalShow}
                            totalRows={modalShow?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            handlePage={(pageNo) => setPage(pageNo)}
                        />
                        </div>
                    ) : null}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
