import { useFormik } from "formik"
import { useState } from "react";
import { Modal, Row } from "react-bootstrap"
import TextInput from "../common/textfield/TextInput";
import { Button } from "../common/Button";
import { CircularProgress } from "@mui/material";
import * as Yup from 'yup';
import { loginWithMFAAuthApp, respondToAuthAppMFA } from "../api/MFA";
import { failed, success } from "../common/Toastify";
import Lock from '../../assets/images/Lock.png';
import { userLogin } from "../api/AuthService";
import { FullPageSpinner } from "../common/Spinner/FullPageSpinner";

const MfaRespondModal = ({ modalShow, handleClose, username, session, challengeName, onSuccess, passwordFromLogin }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [newMfaChallenge, setNewMfaChallenge] = useState("");
    const [resendLoading, setResendLoading] = useState(false);
    const [newSessionToken, setNewSessionToken] = useState("");
    const [fullPageLoader, setFullPageLoader] = useState(false);


    const formik = useFormik({
        initialValues: {
            userResponseCode: ""
        },
        validationSchema: Yup.object({
            userResponseCode: Yup.string()
                .matches(/^\d{6}$/, 'Authenticator app code must be exactly 6 digits')
                .required('Response code is required'),

        }),
        onSubmit: (values) => {
            setFullPageLoader(true);
            respondToAuthAppMFA({ username: username, userResponseCode: values?.userResponseCode, session: newSessionToken || session, challengeName: challengeName })
                .then((res) => {
                    if (res?.AuthenticationResult?.AccessToken) {
                        localStorage.setItem('accessToken', res?.AuthenticationResult?.AccessToken);

                        loginWithMFAAuthApp({ username: username, mfaVerificationResponse: res })
                            .then((res) => {
                                if (res?.authType === "success") {
                                    onSuccess(res);
                                } else (
                                    failed("Authentication Failed. Please try again.")
                                )
                                setFullPageLoader(false);
                            })
                    } else {
                        failed("Incorrect OTP. Please try again.");
                        setFullPageLoader(false);
                    }

                })
                .catch((res) => {
                    failed(res?.message);
                    setFullPageLoader(false);
                })
                .finally(() => setIsLoading(false))
        }

    });

    const handleResend = () => {
        setResendLoading(true);
        userLogin({ password: passwordFromLogin, username: username }).then((res) => {
            switch (res.authType) {
                case "validate-mfa":
                    setNewMfaChallenge(res?.mfaType);
                    setNewSessionToken(res?.session);
                    success("OTP resent successfully");
                default:
                    break;
            }

        }).finally(() => setResendLoading(false));
    }

    return (
        <>
            {fullPageLoader && <FullPageSpinner loadingText="Verifying User Credentials..." />}
            <Modal
                size="lg"
                show={modalShow}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                backdropClassName
                backdrop={"static"}
                centered
                className="custom-dialog popup-org"
            >

                <Modal.Header closeButton className="border-0">
                    <Modal.Title id="contained-modal-title-vcenter">
                        {challengeName === "SMS_MFA" ? "SMS Authentication" : "Authenticator Token"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="custom-form" onSubmit={formik.handleSubmit}>
                        <Row>
                            <TextInput
                                type="code"
                                icon={Lock}
                                keyField="userResponseCode"
                                label={
                                    challengeName === "SMS_MFA"
                                        ? "Please enter 6 digit one time code received on your phone"
                                        : "Please enter unique code from authenticator app"
                                }
                                formik={formik}
                                placeholder="Enter Code"
                            />
                        </Row>

                        {challengeName === "SMS_MFA" && (
                            <Row>
                                <div style={{ display: "flex", flexDirection: "row", marginBottom: "8px" }}>
                                    <span>Didn't receive OTP?</span>
                                    <div style={{ color: "#015da8", marginLeft: "8px", cursor: "pointer", textDecoration: "none" }}
                                        onClick={() => handleResend()}
                                        onMouseOver={(e) => e.target.style.textDecoration = "underline"}
                                        onMouseOut={(e) => e.target.style.textDecoration = "none"}>
                                        <span style={{ textDecoration: "none" }}>Resend code.</span>
                                    </div>
                                </div>
                            </Row>
                        )}
                        <div className="action-wrap">
                            <Button type="submit" disabled={isLoading || resendLoading}>
                                {(isLoading || resendLoading) ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Submit'}
                            </Button>

                            <Button onClick={handleClose} variant="secondary" title="Cancel" style={{ marginLeft: "10px" }}>Cancel</Button>

                        </div>
                    </form>



                </Modal.Body>
            </Modal>
        </>
    )
}

export default MfaRespondModal;