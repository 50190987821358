import { useQuery } from "react-query";
import { axios } from "../../lib/axios";
import { onError } from "./onError"


export function useSurveyReslutQuery({ onSuccess, }) {

    const fetch = () => {
        return axios.get("/questionnaireresponse?responseType=surveyResponse")
    }

    const query = useQuery(
        "organizations",
        () => fetch(),
        {
            select: (data) => data.data,
            onSuccess,
            // onError
        }
    );

    return { ...query };
}

