/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from "react";
import './patients-detail.css';
import { useLocation, useNavigate } from "react-router-dom";
import { decryptData, encryptData } from "../../EncryptDecrypt";
import { getPatientReading, getThreshold, getRelatedPersons, getDisease } from "../api/Patient";
import { failed } from "../../common/Toastify";
import MedicationSchedule from "./create-medication-schedule";
import { ConditionValueByOrgUnits, getOptiionsForChart, initialChartData, patientsDetailsOptions, conditionNameOptions } from "./Constants";
import PatientCustomCard from "./patient-custom-card";
import DiseaseChart from "./DiseaseChart";
import DataTables from "./DataTables";
import EditMedicationSchedule from "./edit-medication-schedule";
import AddDisease from "./Component/AddDisease.js";
import EditDisease from "./Component/EditDisease.js";
import { ArrowBack } from "@mui/icons-material";
import { Tooltip, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import moment from "moment";
import { useGetOrgVitalsUnits } from "../../../hooks/ReactQueryHooks/useGetOrgVitalsUnits";
import { useGetOrgLicensedProducts } from "../../../hooks/ReactQueryHooks/useGetOrgLicensedProducts";

function PatientsDetails() {
    const navigate = useNavigate()
    const location = useLocation();
    const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
    const admin = userRoles?.includes('Super-Admin');
    const [patientData, setPatientData] = useState({})
    let patientName = decryptData(location?.state?.name)
    let patientId = location?.state?.id || patientData?.id
    let patientEmail = location?.state?.email

    if (location.search != ''){
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        patientId = urlParams.get('patientId');
        patientName = urlParams.get('patientName');
    }
    
    const isDoctor = location?.state?.isDoctor
    const [modalShow, setModalShow] = useState(false);
    const [patientsReadingData, setPatientsReadingData] = useState([]) //for reading table
    const [thresholdData, setThresholdData] = useState([]) //for threshold
    const [relatedPerson, setRelatedPerson] = useState([]) //for threshold
    const [medModal, setMedModal] = useState(false);
    const [medEditModal, setMedEditModal] = useState(false);
    const [diseaseModal, setDiseaseModal] = useState(false);
    const [diseaseEditModal, setDiseaseEditModal] = useState(false);
    const [diseases, setDiseases] = useState([])
    const [startDate, setStartDate] = useState(patientsDetailsOptions.initialValues.startDate);
    const [endDate, setEndDate] = useState(new Date())
    const [conditionName, setConditionName] = useState(conditionNameOptions.initialValues.conditionName);
    const [chartData, setChartData] = useState(initialChartData);
    const refetchPatientMedicationQuery = useRef()
    const refetchPatientReadingQuery = useRef()
    const userOrg = useSelector((state) => state?.auth?.user?.organizations);
    const { data: orgVitalsUnit } = useGetOrgVitalsUnits({ orgId: userOrg[0]?.id })
    const { isFetched, data: orgLicensedProducts } = useGetOrgLicensedProducts({})
    const isRPMEnable = admin ? false : orgLicensedProducts?.RPM
    const isVirtualCare = admin ? false : orgLicensedProducts?.virtualCare
    const noLicensedProduct = admin ? false : !orgLicensedProducts?.RPM && !orgLicensedProducts?.virtualCare && !orgLicensedProducts?.callCenter

    // const handleStartDate = (val) => {
    //     setStartDate(val.format("MM/DD/YYYY"));
    //     const apiBody = (conditionName) => { return { "conditionName": encryptData(conditionName), patientId, startDate: val.format("MM/DD/YYYY"), endDate } }
    //     if (conditionName == "blood_pressure") updateBpChartData(apiBody("blood_pressure"))
    //     if (conditionName == "blood_glucose") updateSugarChartData(apiBody("blood_glucose"))
    //     if (conditionName == "weight") updateWtChartData(apiBody("weight"))
    //     if (conditionName == "heart_rate") updateHeartRateChartData(apiBody("heart_rate"))
    //     if (conditionName == "temperature") updateTempChartData({ patientId, "conditionName": encryptData("temperature"), "startDate": val.toISOString(), "endDate": endDate.toISOString() })
    //     if (conditionName == "oxygen") updateOxygenChartData(apiBody("oxygen"));
    // };

    const handleStartDate = (val) => {
        const startDateValue = val ? new Date(val) : null;
        setStartDate(startDateValue);
    
        const apiBody = (conditionName) => {
            return {
                "conditionName": encryptData(conditionName),
                patientId,
                startDate: startDateValue ? startDateValue.toISOString() : null,
                endDate
            };
        };
        if (conditionName == "blood_pressure") updateBpChartData(apiBody("blood_pressure"))
        if (conditionName == "blood_glucose") updateSugarChartData(apiBody("blood_glucose"))
        if (conditionName == "weight") updateWtChartData(apiBody("weight"))
        if (conditionName == "heart_rate") updateHeartRateChartData(apiBody("heart_rate"))
        if (conditionName == "temperature") updateTempChartData({ patientId, "conditionName": encryptData("temperature"), "startDate": val.toISOString(), "endDate": endDate.toISOString() })
        if (conditionName == "oxygen") updateOxygenChartData(apiBody("oxygen"));
    };

    // const handleEndDate = (val) => {
    //     setEndDate(val.format("MM/DD/YYYY"));
    //     const apiBody = (conditionName) => { return { "conditionName": encryptData(conditionName), patientId, startDate, endDate: val.format("MM/DD/YYYY") } }
    //     if (conditionName == "blood_pressure") updateBpChartData(apiBody("blood_pressure"))
    //     if (conditionName == "blood_glucose") updateSugarChartData(apiBody("blood_glucose"))
    //     if (conditionName == "weight") updateWtChartData(apiBody("weight"))
    //     if (conditionName == "temperature") updateTempChartData({ patientId, "conditionName": encryptData("temperature"), "startDate": startDate.toISOString(), "endDate": val.toISOString() })
    //     if (conditionName == "heart_rate") updateHeartRateChartData(apiBody("heart_rate"));
    //     if (conditionName == "oxygen") updateOxygenChartData(apiBody("oxygen"));
    // };
    const handleEndDate = (val) => {
        const endDateValue = val ? new Date(val) : null;
    setEndDate(endDateValue);

    const apiBody = (conditionName) => {
        return {
            "conditionName": encryptData(conditionName),
            patientId,
            startDate,
            endDate: endDateValue ? endDateValue.toISOString() : null
        };
    };
        if (conditionName == "blood_pressure") updateBpChartData(apiBody("blood_pressure"))
        if (conditionName == "blood_glucose") updateSugarChartData(apiBody("blood_glucose"))
        if (conditionName == "weight") updateWtChartData(apiBody("weight"))
        if (conditionName == "temperature") updateTempChartData({ patientId, "conditionName": encryptData("temperature"), "startDate": startDate.toISOString(), "endDate": val.toISOString() })
        if (conditionName == "heart_rate") updateHeartRateChartData(apiBody("heart_rate"));
        if (conditionName == "oxygen") updateOxygenChartData(apiBody("oxygen"));
    };
    const getPatientData = (data) =>{
        setPatientData(data)
    }

    /**
     * data fetched for intial data loading
     */
    useEffect(() => {
        if(patientId === undefined){ 
            navigate("/app/dashboard");
        }else{
            if(isRPMEnable){
                getPatientReading(null, { "conditionName": encryptData(conditionName), patientId, startDate: startDate ?  moment(startDate).format("MM/DD/YYYY") : null, endDate: endDate?  moment(endDate).format("MM/DD/YYYY") : null }).then((res) => {
                    setChartData({
                        series: [
                            {
                                name: "Systolic",
                                data: res.data?.map((data) => ConditionValueByOrgUnits({ condition: "blood_pressure", conditionData: decryptData(data?.valueQuantity?.value?.systolic), orgVitalsUnit })),
                            },
                            {
                                name: "Diastolic",
                                data: res.data?.map((data) => ConditionValueByOrgUnits({ condition: "blood_pressure", conditionData: decryptData(data?.valueQuantity?.value?.diastolic), orgVitalsUnit })),
                            },
                        ],
                        options: {
                            chart: {
                                type: "line",
                                toolbar: {
                                    show: false
                                }
                            },
                            xaxis: {
                                categories: res.data?.map((data) => data?.effectiveDateTime),
                            }
                        }
                    });
                }).catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message));
                getPatientReading(patientId).then((res) => {
                    setPatientsReadingData(res?.data)
                }).catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message));
                /**
                 * get threshold data
                 */
                getThreshold(patientId).then((res) => {
                    setThresholdData(res.data)
                }).catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message));
        
                getAllDiseases(patientId)
            }
            /**
            * get related person Data
            */
            getRelatedPersons(patientId).then((res) => {
                setRelatedPerson(res.data)
            }).catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRPMEnable])

    /**
     * update blood pressure chart data
     * @param {condition} condition 
     */
    const updateBpChartData = async (apiBody) => {
        await getPatientReading(null, apiBody)
            .then((res) => {
                setChartData({
                    series: [
                        {
                            name: "Systolic",
                            data: res.data?.map((data) => ConditionValueByOrgUnits({ condition: "blood_pressure", conditionData: decryptData(data?.valueQuantity?.value?.systolic), orgVitalsUnit })),
                        },
                        {
                            name: "Diastolic",
                            data: res.data?.map((data) => ConditionValueByOrgUnits({ condition: "blood_pressure", conditionData: decryptData(data?.valueQuantity?.value?.diastolic), orgVitalsUnit })),
                        },
                    ],
                    options: getOptiionsForChart(res.data)
                });
            }).catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message));
    };
    /**
     * update weight chart data
     * @param {condition} condition 
     */
    const updateWtChartData = async (apiBody) => {
        await getPatientReading(null, apiBody)
            .then((res) => {
                setChartData({
                    series: [
                        {
                            name: 'Weight Scale',
                            data: res.data?.map((data) => ConditionValueByOrgUnits({ condition: "weight", conditionData: decryptData(data?.valueQuantity?.value?.weight), orgVitalsUnit }))
                        }
                    ],
                    options: getOptiionsForChart(res.data)
                });
            })
            .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message));
    };
    /**
     * gets all diseases
     * @param {patientId} 
     */
    const getAllDiseases = (patientId) => {
        getDisease(patientId)
            .then((res) => {
            setDiseases(res?.data)
            })
            .catch((res) => {
            failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
        });
    }

    /**
     * update glucose chart data
     * @param {condition} condition 
     */
    const updateSugarChartData = async (apiBody) => {
        await getPatientReading(null, apiBody)
            .then((res) => {
                setChartData({
                    series: [
                        {
                            name: 'Sugar',
                            data: res.data?.map((data) => ConditionValueByOrgUnits({ condition: "blood_glucose", conditionData: decryptData(data?.valueQuantity?.value?.glucose), orgVitalsUnit }))
                        }
                    ],
                    options: getOptiionsForChart(res.data)
                });
            })
            .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message));
    };
    /**
 * update heart rate chart data
 * @param {condition} condition
 */
    const updateHeartRateChartData = async (apiBody) => {
        await getPatientReading(null, apiBody)
            .then((res) => {
                setChartData({
                    series: [
                        {
                            name: "Heart Rate",
                            data: res.data?.map((data) => decryptData(data?.valueQuantity?.value?.heartRate)),
                        },
                    ],
                    options: getOptiionsForChart(res.data)
                });
            })
            .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message));
    };
    /**
  * update heart rate chart data
  * @param {condition} condition
  */
    const updateOxygenChartData = async (apiBody) => {
        await getPatientReading(null, apiBody)
            .then((res) => {
                setChartData({
                    series: [
                        {
                            name: 'Oxygen',
                            data: res.data?.map((data) => decryptData(data?.valueQuantity?.value?.oxygen)),
                        },
                    ],
                    options: getOptiionsForChart(res.data)
                });
            })
            .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message));
    };
    /**
     * update temperature chart data
     * @param {condition} condition 
     */
    const updateTempChartData = async (apiBody) => {
        await getPatientReading(null, apiBody)
            .then((res) => {
                setChartData({
                    series: [
                        {
                            name: 'Temperature',
                            data: res.data?.map((data) => ConditionValueByOrgUnits({ condition: "temperature", conditionData: decryptData(data?.valueQuantity?.value?.temperature), orgVitalsUnit }))
                        }
                    ],
                    options: getOptiionsForChart(res.data)
                });
            })
            .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message));
    };
    const getpatientList = (eventKey) => {
        getPatientReading(patientId)
            .then((res) => setPatientsReadingData(res.data))
            .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message));
    }
    /**
     * update threshold data after creating
     */
    const updateThresholddata = () => {
        getThreshold(patientId)
            .then((res) => setThresholdData(res.data))
            .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message));
    }
    const refreshMed = () => {
        refetchPatientMedicationQuery.current()
    }

    if (isFetched && noLicensedProduct) return <Typography variant="h4" align="center" component="h4" mt={"20%"}>No product access given</Typography>

    return (
        <section className="detail-listing">
            {medModal ? <MedicationSchedule patientId={patientId} modalShow={medModal} handleShow={() => setMedModal(!medModal)} refreshMed={refreshMed} /> : null}
            {medEditModal ? <EditMedicationSchedule patientId={patientId} modalShow={medEditModal} handleShow={() => setMedEditModal(!medEditModal)} refreshMed={refreshMed} /> : null}
            {diseaseModal ? <AddDisease patientId={patientId} refreshDisease={getAllDiseases} modalShow={diseaseModal} handleShow={() => setDiseaseModal(!diseaseModal)} /> : null}
            {diseaseEditModal ? <EditDisease patientId={patientId} modalShow={diseaseEditModal} refreshDisease={getAllDiseases} handleShow={() => setDiseaseEditModal(!diseaseEditModal)}/> : null}
            
            <div className="detail-chart-wrap mt-3">
                {/* Patient Profile Custom Card*/}
                <PatientCustomCard from={location?.state?.path} relatedPerson={relatedPerson} getPatientData={getPatientData} isRPMEnable={isRPMEnable} patientName={patientName} patientEmail={patientEmail} patientId={patientId} orgVitalsUnit={orgVitalsUnit} disease={diseases?.[0]} />
                {/* Patient chart report*/}
                <DiseaseChart startDate={startDate} endDate={endDate} setConditionName={setConditionName} patientId={patientId} chartData={chartData} handleStartDate={handleStartDate} handleEndDate={handleEndDate} updateBpChartData={updateBpChartData} updateWtChartData={updateWtChartData} updateSugarChartData={updateSugarChartData} updateOxygenChartData={updateOxygenChartData} updateHeartRateChartData={updateHeartRateChartData} updateTempChartData={updateTempChartData} disease={diseases?.[0]} patientData={patientData}/>
            </div>
            <DataTables isRPMEnable={isRPMEnable} isVirtualCare = {isVirtualCare} patientName={patientName} patientEmail={patientEmail} orgVitalsUnit={orgVitalsUnit} isDoctor={isDoctor} diseaseModal={diseaseModal} setDiseaseEditModal={setDiseaseEditModal} modalShow={modalShow} diseases={diseases} refreshMed={refetchPatientMedicationQuery} refreshReading={refetchPatientReadingQuery} setDiseaseModal={setDiseaseModal} setMedEditModal={setMedEditModal} setModalShow={setModalShow} patientId={patientId} patientsReadingData={patientsReadingData} thresholdData={thresholdData} relatedPerson={relatedPerson} medModal={medModal} setMedModal={setMedModal} getpatientList={getpatientList} updateThresholddata={updateThresholddata} />
        </section>
    );
}

export default PatientsDetails;