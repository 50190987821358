import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../common/components/SearchBar";
import { Button } from "../../common/Button";
import CustomTable from "../../common/table/CustomTable";
import SelectFiled from "../../common/textfield/SelectFiled";
import { useFormik } from "formik";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import {
  DataFields,
  HeadCells,
  listSendOptions,
  DataFieldsFax,
  HeadCellsFax,
} from "./Constants";
import { commonDisableAPI } from "../../api/CommonApis";
import { failed, success } from "../../common/Toastify";
import { useSFTQuery } from "../../../hooks/ReactQueryHooks/useSFTQuery";
import { useOrgQuery } from "../../../hooks/ReactQueryHooks/useOrgQuery";
import { getSFTFullList } from "../../api/SFT";
import "./manage-sft.css";

function SFTList() {
  const navigate = useNavigate();
  const { getConfirmation } = useConfirmDialog();
  const [organizationsData, setOrganizationsData] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setisFetching] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [sftList, setSFTList] = useState([]);
  const [tableDataFields, setTableDataFields] = useState(DataFieldsFax);
  const [tableHeadCells, setTableHeadCells] = useState(HeadCellsFax);
  const userRoles = useSelector(
    (state) => state?.auth?.user?.["cognito:groups"]
  );
  const admin = userRoles?.includes("Super-Admin");
  const adminOrgs = useSelector((state) => state?.auth?.user?.organizations);
  const formik = useFormik({
    initialValues: {
      org: admin ? "All" : adminOrgs?.[0]?.id,
      type: "FAX",
    },
  });

  useOrgQuery({
    onSuccess: (data) => {
      setOrganizationsData(data);
      setOrganizations(
        data?.map((org) => ({ name: org?.name, value: org?.id }))
      ); 
      admin
        ? getSFTList({ isAllUsersAllOrgs: true,  type: "FAX"  })
        : getSFTList({ orgId: adminOrgs?.[0]?.id,  type: "FAX" });
    },
    refetchOnWindowFocus: false,
  });

  const getSFTList = (apiArgs) => {
    setIsLoading(true);
    getSFTFullList(apiArgs)
      .then((res) => {
        setIsLoading(false);
        setisFetching(false);
        setSFTList(res?.data); 
      })
      .catch((res) => {
        failed(
          res?.response?.data?.message ||
            res?.response?.data?.error ||
            res.message
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleFilterData = (data) => {
    setFilterData(data);
  };
  const handleOrgChange = (event) => {
    const value = event?.target?.value;
    formik.setFieldValue("org", value);
    setTableDataFields(DataFieldsFax);
    setTableHeadCells(HeadCellsFax);
    const type = formik?.values?.type;
    if (admin) {
      if (value === "All") {
        getSFTList({ type: type });
      } else {
        getSFTList({ orgId: value, type });
      }
    } else {
      getSFTList({ orgId: value, type });
    }
    setPage(0);
  };

  const handleSendChange = (event) => {
    const value = event?.target?.value;
    const orgId = formik?.values?.org;
    formik.setFieldValue("type", value);
    orgId === "All"
        ? getSFTList({ isAllUsersAllOrgs: true,  type: value  })
        : getSFTList({ orgId, type: value })
    if (value === "Email") {
      setTableDataFields(DataFields);
      setTableHeadCells(HeadCells);
    } else {
      setTableDataFields(DataFieldsFax);
      setTableHeadCells(HeadCellsFax);
    }

    setPage(0);
  };

  // Display first character of patient's and practitioner's first and last name in capital letter.
  if(filterData) {
    filterData?.map((data) => {
        const modifiedPatientName = data?.subject?.name?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        data.subject.name = modifiedPatientName;
        const modifiedPracName = data?.sender?.practitioner_name?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        data.sender.practitioner_name = modifiedPracName;
    });
  }

  return (
    <section className="common-listing">
      <div className="heading-wrap">
        <div className="filter-col d-flex align-items-center">
          <SearchBar
            page={page}
            setPage={setPage}
            list={sftList}
            filterData={handleFilterData}
            encoded={false}
          />
          <form className="common-form border-fields dropdown-filter-rw d-flex align-items-center" style={{ marginBottom: '-30px' }}>
            <SelectFiled
              keyField={"org"}
              label={false}
              formik={formik}
              selectLabel={"Select Organization"}
              options={
                admin
                  ? [{ name: "All", value: "All" }, ...organizations]
                  : organizations
              }
              onChange={handleOrgChange}
              required={false}
              optional={false}
              readOnly={isLoading}
            />
            <SelectFiled
              keyField={"type"}
              label={false}
              formik={formik}
              options={listSendOptions}
              onChange={handleSendChange}
              required={false}
              optional={false}
              readOnly={isLoading || !formik?.values?.org}
            />
          </form>
        </div>
      </div>

      <CustomTable
        tableData={filterData}
        headCells={tableHeadCells}
        dataFields={tableDataFields}
        rowOptions={false}
        canView={false}
        canEdit={false}
        canDelete={false}
        canStatus={true}
        loading={isLoading}
        fetching={isFetching}
        page={page}
        setPage={setPage}
      />
    </section>
  );
}

export default SFTList;
