/* eslint-disable eqeqeq */
import React, { useState } from "react";
import "./manage-group.css";
import { Link, useNavigate } from "react-router-dom";
import { useGetThresholdGroup } from "../../../../src/hooks/AppsReactQueryHooks/useGetThresholdGroup";
import CreateGroupDialog from "./Component/create";
import AddPatientDialog from "./Component/addPatient";
import { useSelector } from "react-redux";
import { Button } from "../../common/Button";
import { deleteThresholdGroup } from "../api/ThresholdGroup";
import { failed, success } from "../../common/Toastify";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import { Skeleton } from "@mui/material";
import ConditionData from "./Component/ConditionData";
import { useGetOrgVitalsUnits } from "../../../hooks/ReactQueryHooks/useGetOrgVitalsUnits";

function ManageGroup() {
    const [modalShow, setModalShow] = useState(false);
    const [createGroupShow, setCreateGroupShow] = useState(false);
    const [thresholdGroup, setThresholdGroup] = useState([]);
    const [deleting, setDeleting] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const { getConfirmation } = useConfirmDialog();
    const { refetch, isLoading } = useGetThresholdGroup({
        onSuccess: (data) => {
            setThresholdGroup(data);
        },
    });
    const org_id = useSelector((state) => state?.auth?.user?.organizations?.[0]?.id);
    const permissions = useSelector((state) => state?.auth?.user?.permissions);
    const hasManageThresholdPermission = permissions?.includes('Manage Threshold Groups');
    const { data: orgVitalsUnit } = useGetOrgVitalsUnits({ orgId: org_id })
    const [editData, setEditData] = useState(false);
    const [addPatient, setAddpatient] = useState([]);
    const navigate = useNavigate();
    const updateThresholdgroup = () => {
        refetch();
        setEditData(false);
        setAddpatient([]);
    };

    const handleViewPatients = (data) => {
        let patient = {
            patientIds: data?.patientIds,
            groupId: data?.id
        }
        navigate("/app/threshold-patient-list", { state: patient });
    };
    const handleDelete = async (id, entryName, hasPatientsAssigined) => {
        const confirmed = await getConfirmation({
            title: "Attention!",
            message: hasPatientsAssigined ? `Patients are registered in this group, are you sure want to delete ` : `Are you sure you would like to delete `,
            entryName,
            actionBtnTitle: "Delete",
        });
        if (confirmed) {
            setDeleting(id);
            deleteThresholdGroup(id)
                .then((res) => {
                    setThresholdGroup(thresholdGroup.filter((data) => data?.id !== id));
                    success("Threshold Group Deleted!");
                })
                .catch((err) => failed(err?.response?.data?.message || err.message))
                .finally(() => setDeleting(false));
        }
    };

    return (
        <section className="common-listing">
            <div className="heading-wrap diff-style">
                <div className="right-wrap">
                    {hasManageThresholdPermission && <Link
                        to=""
                        variant="primary"
                        onClick={() => {
                            setCreateGroupShow(true);
                            setEditData(false);
                        }}
                        className="custom-btn"
                        title="Create Group"
                        style={{ width: "auto" }}
                    >
                        + Create Group
                    </Link>}
                    {createGroupShow ? <CreateGroupDialog
                        show={createGroupShow}
                        onHide={() => setCreateGroupShow(false)}
                        onUpdateThreshold={() => {
                            updateThresholdgroup();
                        }}
                        orgId={org_id}
                        data={editData}
                        isReadOnly={readOnly}
                    /> : null}
                </div>
            </div>
            <ul className="group-wrap">
                {isLoading ?
                    [1, 2, 3].map((key) => <li key={key}>
                        <Skeleton width={200}></Skeleton>
                        <Skeleton width={200}></Skeleton>
                        <Skeleton width={200}></Skeleton>
                        <Skeleton width={200}></Skeleton>
                        <span className="pat-wrap">
                            <Skeleton width={100}></Skeleton>
                        </span>
                    </li>)
                    : thresholdGroup.map((data) => (
                        <li key={data?.id}>
                            <h4 className="margin-bottom-16">{data?.groupName}</h4>
                            <div className="data-placement">
                                {Object.keys(data?.condition).map((keyName, index) => (
                                    <p key={index}>
                                        <b style={{ textTransform: "capitalize" }}>
                                            {keyName.split("_").join(" ")}
                                        </b>
                                        <br />
                                        <ConditionData keyName={keyName} data={data} orgVitalsUnit={orgVitalsUnit} />
                                    </p>
                                ))}
                            </div>
                            <span className="pat-wrap">
                                {data?.patientIds === null ? (
                                    "0 Patients"
                                ) : (
                                    <>
                                        {data?.patientIds?.length} Patients{" "}
                                        {data?.patientIds?.length > 0 ?
                                            <span style={{ textDecoration: "underline", cursor: "pointer", marginLeft: "6px", color: "#0064a6", fontWeight: "100" }}
                                                onClick={() => handleViewPatients(data)}>View All</span>
                                            : null}
                                    </>
                                )}
                            </span>
                            <br />
                            {<Button
                                variant="primary"
                                onClick={() => {
                                    setCreateGroupShow(true);
                                    setEditData(data);
                                    setReadOnly(!hasManageThresholdPermission);
                                }}
                                className="custom-btn"
                                title="Edit Group"
                            >
                                {hasManageThresholdPermission ? "Edit Group" : "View Group"}
                            </Button>}
                            {hasManageThresholdPermission && <Button isLoading={deleting === data?.id} variant="primary" className="custom-btn" title="Delete Group"
                                onClick={() => (deleting ? null : handleDelete(data?.id, data?.groupName, data?.patientIds?.length > 0))} style={{ marginLeft: "20px" }}>
                                Delete Group
                            </Button>}
                            <Button
                                variant="primary"
                                onClick={() => {
                                    setModalShow(true);
                                    setAddpatient(data);
                                }}
                                className="custom-btn white-btn add-patient"
                                title="Add Patient"
                            >
                                Add Patient
                            </Button>
                        </li>
                    ))}
            </ul>
            <AddPatientDialog show={modalShow} onHide={() => { setModalShow(false); updateThresholdgroup(); }} data={addPatient} />
        </section >
    );
}

export default ManageGroup;
