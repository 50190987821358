import { Col, Row } from "react-bootstrap";
import { Modal } from "react-bootstrap";

const NoteModal = ({ show, onHide, note, patientName }) => {
    return (
        <section>
            <Modal show={show} onHide={onHide} size="md" centered className='custom-dialog'>
                <Modal.Header closeButton className="border-0">
                    <Modal.Title id="contained-modal-title-vcenter" style={{ textTransform: "capitalize" }}>
                        {patientName}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row>
                        <Col>
                            <div>
                                <b>
                                    Note
                                </b>
                                <br />
                                {note}
                            </div></Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </section>
    )
}

export default NoteModal;