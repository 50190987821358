import { useQuery } from "react-query";
import { axios } from "../../lib/axios";
import { onError } from "./onError"
import { useSelector } from "react-redux";

const getCode = (requestType)=>{
    switch (requestType) {
        case 'Lab':
          return "MBL"
        case 'Diagnostic':
          return "RADDX"
        case 'Referral':
          return "DX"
        default:
          return "PHARM" // medication
    }
}

export function useClinicLocationsQuery({ onSuccess, isSftEnable = false, requestType = "", orgIds = false, orgnizations= [] }) {
    const userType = useSelector((state) => state?.auth?.user?.['cognito:groups']);
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations);

    const fetch = () => {
        return orgIds
            ? axios.get(`location?orgIds=${JSON.stringify(orgIds)}`)
            : userType.includes("Super-Admin")
                ? axios.get("location")
                : isSftEnable
                    ? axios.get(`location?sft=true&code=${getCode(requestType)}&orgIds=${JSON.stringify(orgnizations)}`)
                    : axios.get(`location?orgIds=${JSON.stringify(orgnizations)}`);
    };
    
    const query = useQuery(
        "clinic-locations",
        () => fetch(),
        {
            select: (data) => data.data,
            onSuccess,
            onError
        }
    );

    return { ...query };
}
