import React, { useEffect, useState } from "react";
import { failed, success } from "../../common/Toastify";
import { Button } from "../../common/Button";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import SearchBar from "../../common/components/SearchBar";
import { Link, useNavigate } from "react-router-dom";
import EditIcon from "../../../assets/images/edit.png";
import DeleteIcon from "../../../assets/images/delete.png";
import SelectFiled from "../../common/textfield/SelectFiled";
import { filterStatusOptions } from "./Constants";
import CustomPagination from "../../common/components/Pagination/CustomPagination";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { Spinner, Table } from "react-bootstrap";
import { Skeleton, TableCell, Tooltip } from "@mui/material";
import { EditDocument } from "./EditDocument";
import { useGetDocumentQuery } from "../../../hooks/ReactQueryHooks/useGetDocument";
import { deleteDocument, getDocument } from "../../api/FillableDocument.js";
import { decryptData } from "../../EncryptDecrypt";
import ViewIcon from "../../../assets/images/view.png";
import { AttachementModal } from "../../apps/AppointmentRequests/AttachementModal";
import { useSelector } from "react-redux";


function DocumentList() {
    const { getConfirmation } = useConfirmDialog();
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [modalShow, setModalShow] = useState(false);
    const [docData, setDocData] = useState(null)
    const [documentList, setDocumentList] = useState([]);
    const [deleting, setDeleting] = useState(false);
    const [isViewDoc, setIsViewDoc] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [filterData, setFilterData] = useState([]);
    const [statusFilter, setStatusFilter] = useState('all');
    const [sortBy, setSortBy] = useState("AscName");
    const [attachmentUrl, setAttachmentUrl] = useState('');
    const [showAttachmentModal, setShowAttachmentModal] = useState(false);
    const rowsPerPage = 10;
    const totalRows = filterData.length;
    const handleShow = (data) => { setModalShow(modalShow ? false : data) };
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)
    const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);

    //React Query Hook
    // const onSuccess = (data) => {
    //     setDocumentList(data);
    // };

    // const { refetch } = useGetDocumentQuery({ onSuccess, statusFilter, viewDoc: isViewDoc });

    const handleDelete = async (id, entryName) => {
        const confirmed = await getConfirmation({
            title: "Attention!",
            entryName,
            actionBtnTitle: "Delete",
        });
        if (confirmed) {
            setDeleting(id);
            deleteDocument(id)
                .then((res) => {
                  getListOfDocument()
                    success("Document Deleted!");
                })
                .catch((err) => failed(err?.response?.data?.message || err.message))
                .finally(() => setDeleting(false));
        }
    };

    const handleStatusFilter = (event) =>{ 
      setIsLoading(true);
      setStatusFilter(event?.target?.value)
      getDocument(adminOrgs?.[0]?.id, event?.target?.value, userRoles).then((res)=> {
        setIsLoading(false);
        setDocumentList(res?.data);
      })
      .catch((res) => {
          failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
      }).finally(() => { setIsLoading(false) });
    }

    const sortFilterData = (sort_by) => {
      setSortBy(sort_by)
      switch (sort_by) {
        case "AscName":
              setFilterData(filterData?.sort((a, b) => (decryptData(a?.documentName) > decryptData(b?.documentName)) ? 1 : ((decryptData(b?.documentName) > decryptData(a?.documentName)) ? -1 : 0)))
              break;
          case "DscName":
              setFilterData(filterData?.sort((a, b) => (decryptData(a?.documentName) < decryptData(b?.documentName)) ? 1 : ((decryptData(b?.documentName) < decryptData(a?.documentName)) ? -1 : 0)))
              break;
              case "AscOrg":
                setFilterData(filterData?.sort((a, b) => (decryptData(a?.custodian?.display) > decryptData(b?.custodian?.display)) ? 1 : ((decryptData(b?.custodian?.display) > decryptData(a?.custodian?.display)) ? -1 : 0)))
                break;
            case "DscOrg":
                setFilterData(filterData?.sort((a, b) => (decryptData(a?.custodian?.display) < decryptData(b?.custodian?.display)) ? 1 : ((decryptData(b?.custodian?.display) < decryptData(a?.custodian?.display)) ? -1 : 0)))
                break;
          case "AscCategory":
              setFilterData(filterData?.sort((a, b) => (decryptData(a.category?.[0]?.coding?.[0]?.display) > decryptData(b.category?.[0]?.coding?.[0]?.display)) ? 1 : ((decryptData(b.category?.[0]?.coding?.[0]?.display) > decryptData(a.category?.[0]?.coding?.[0]?.display)) ? -1 : 0)))
              break;
          case "DscCategory":
              setFilterData(filterData?.sort((a, b) => (decryptData(a.category?.[0]?.coding?.[0]?.display) < decryptData(b.category?.[0]?.coding?.[0]?.display)) ? 1 : ((decryptData(b.category?.[0]?.coding?.[0]?.display) < decryptData(a.category?.[0]?.coding?.[0]?.display)) ? -1 : 0)))
              break;
          default:
              setFilterData(filterData)
              break;
      }
  }

  const handleShowFile = async(data) => {
    setIsViewDoc(true)
    setShowAttachmentModal(true)
    setAttachmentUrl(data?.content?.[0]?.attachment?.url)
    setDocData(data)
  }

  const getListOfDocument = async () =>{
    const documentList = await getDocument(adminOrgs?.[0]?.id, statusFilter, userRoles)
    setDocumentList(documentList?.data);
  }

  useEffect(() => {
    getListOfDocument()
  }, [])
  

    return (
      <section className="common-listing">
        <EditDocument modalShow={modalShow} refreshTable={getListOfDocument} handleShow={handleShow} />
        {showAttachmentModal ? <AttachementModal modalShow={showAttachmentModal} url={attachmentUrl} docData={docData} handleShow={() => setShowAttachmentModal(false)} fromDocument={true} /> : null}
        <div className="heading-wrap h-change common-form">
          <SearchBar
            page={page}
            setPage={setPage}
            list={documentList}
            filterData={(data) => setFilterData(data)}
          />
          <SelectFiled
            keyField={"document"}
            label={false}
            selectLabel={"Status"}
            options={filterStatusOptions}
            onChange={handleStatusFilter}
            required={false}
            optional={false}
          />
          <div className="right-wrap" style={{ margin: "left" }}>
            <Button
              onClick={() => navigate("/app/add-documents")}
              className="custom-btn"
              title="Add Document"
              style={{ width: "auto" }}
            >
              Add Document
            </Button>
          </div>
        </div>
        <div className="table-wrap">
                <Table responsive>
                    <thead>
                        <tr>
                            <th style={{ cursor: "pointer" }} onClick={() => sortFilterData(sortBy === "AscName" ? "DscName" : "AscName")}>
                              Document Name
                              {sortBy === "AscName" ? <span>
                                    <ArrowDropUp />
                                </span> : <span>
                                    <ArrowDropDown />
                                </span>}
                              </th>
                            {userRoles?.includes("Super-Admin") ? (<th style={{ cursor: "pointer" }} onClick={() => sortFilterData(sortBy === "AscOrg" ? "DscOrg" : "AscOrg")}>
                              Organization
                            {sortBy === "AscOrg" ? <span>
                                    <ArrowDropUp />
                                </span> : <span>
                                    <ArrowDropDown />
                                </span>}
                            </th>) : null}
                            <th style={{ cursor: "pointer" }} onClick={() => sortFilterData(sortBy === "AscCategory" ? "DscCategory" : "AscCategory")}> 
                                Category
                                {sortBy === "AscCategory" ? <span>
                                    <ArrowDropUp />
                                </span> : <span>
                                    <ArrowDropDown />
                                </span>}
                            </th>
                            <th>Description</th>
                            <th>Status</th>
                            <th style={{ textAlign: "center"}}>
                                Action
                            </th>
                        </tr>
                    </thead> 
                    <tbody>
                        {isLoading ? (
                            [1, 2, 3, 4, 5]?.map((val) => (
                                <tr key={val}>
                                    <td>
                                        <Skeleton animation="wave" />
                                    </td>
                                    <td>
                                        <Skeleton animation="wave" />
                                    </td>
                                    <td>
                                        <Skeleton animation="wave" />
                                    </td>
                                    <td>
                                        <Skeleton animation="wave" />
                                    </td>
                                    <td>
                                        <Skeleton animation="wave" />
                                    </td>
                                </tr>
                            ))
                        ) : filterData?.length > 0 ? (
                            filterData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((data) => {
                                const {id, documentName, category, description, status} = data;
                                return (
                                  <tr key={id}>
                                    <td className="name-text">{documentName}</td>
                                    {userRoles?.includes("Super-Admin") ? (<td>{data?.custodian?.display}</td>) : null}
                                    <td>{category?.[0]?.coding?.[0]?.display}</td>
                                    <td style={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          maxWidth: "170px",
                                          cursor:"pointer"
                                        }}>
                                          <Tooltip title={description}>
                                          {description}
                                          </Tooltip>
                                        </td>
                                    <td>{status == "new" ? "Active" : "Inactive" }</td>
                                    <td>
                                      <div className="action-wrap" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                        <Tooltip title={"View"}>
                                            <Link
                                              to=""
                                              variant="primary"
                                              className="view-btn"
                                              // style={{ opacity: user?.["custom:unique_id"] === data?.requester?.reference.split("/")[1] }}
                                              onClick={() =>  handleShowFile(data, false)}
                                            >
                                              <img src={ViewIcon} alt="View PDF" />
                                            </Link>
                                        </Tooltip>
                                        <Tooltip title="Edit">
                                          <Link
                                            to=""
                                            className="success-btn"
                                            onClick={()=> handleShow(data)}
                                          >
                                            <img src={EditIcon} alt="Edit" />
                                          </Link>
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                          <Link
                                            to=""
                                            className="delete-btn"
                                            onClick={()=> handleDelete(data?.id, documentName)}
                                          >
                                            {deleting === id ? (
                                              <Spinner
                                                size="lg"
                                                className="text-current"
                                                style={{
                                                  color: "#ffff",
                                                  background: "transparent",
                                                  margin: "0",
                                                }}
                                              />
                                            ) : (
                                              <img
                                                src={DeleteIcon}
                                                alt="Delete"
                                              />
                                            )}
                                          </Link>
                                        </Tooltip>
                                      </div>
                                    </td>
                                  </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <TableCell colSpan={"7"} style={{ textAlign: "center" }}>
                                    No Data Available
                                </TableCell>
                            </tr>
                        )}
                    </tbody>
                </Table>
        </div>
        {filterData?.length > 0 ? <CustomPagination tableData={filterData} totalRows={totalRows} rowsPerPage={rowsPerPage} page={page} handlePage={(pageNo) => setPage(pageNo)} /> : null}
      </section>
    );
}

export default DocumentList;
