import React, { useEffect, useRef, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import TextInput from "../../common/textfield/TextInput";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../common/Button";
import { failed, success } from "../../common/Toastify";
import TextArea from "../../common/textfield/TextArea";
import { Tooltip } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useSelector } from "react-redux";
import InputFile from "../../common/textfield/InputFile";
import moment from "moment";
import { DateSelectorWithoutFormik } from "../../common/textfield/DateSelector";
import {  FormikObjectObservaton, observationNameOptions } from "./Constant";
import { createConditions, createObservation } from "../api/MedicalRequest";
import SelectFiled from "../../common/textfield/SelectFiled";

const CreateObservation = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [btnLoading, setBtnLoading] = useState(false);
    const {medicalHistory, patientData, practionerData } = location?.state
    const formik = useFormik({
        ...FormikObjectObservaton,
        onSubmit: async(values) => {
                setBtnLoading(true);
                createObservation({ ...values, patientId: patientData?.patientId, patientName: patientData?.patientName, practitionerId: practionerData?.id, practitionerName: practionerData?.name?.[0]?.text })
                    .then((res) => {
                        if (res.status === true) {
                            navigate("/app/patients-details", {state:{id: patientData?.patientId, name: patientData?.patientName}});
                            success(res.message);
                        } else {
                            failed(res.error);
                        }
                    })
                    .catch((res) => {
                        failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                    })
                    .finally(() => setBtnLoading(false));
        },
    });

    const handleObservationChange = (event) => {
      formik.setFieldValue("observationName", event?.target?.value);
    }
    useEffect(() => {
      localStorage.setItem("activeTableKey", "ninth");
    }, [])

  return (
    <section className="common-listing">
      <div className="heading-wrap mb-3">
        <Tooltip title="Go back">
          <div
            style={{ marginRight: "1rem", cursor: "pointer" }}
            onClick={() => navigate(-1)}
          >
            <ArrowBack />
          </div>
        </Tooltip>
      </div>
      <div className="custom-card p-4">
        <form
          className="common-form border-fields"
          onSubmit={formik.handleSubmit}
        >
          <Row>
            <Col>
              <SelectFiled
                keyField={"observationName"}
                label={"Observation"}
                formik={formik}
                options={observationNameOptions}
                onChange={handleObservationChange}
              />
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Col></Col>
            <Col></Col>
          </Row>
          <Row>
            <Col>
              <TextArea
                keyField={"note"}
                label={"Note"}
                formik={formik}
                placeholder={"Note..."}
                required={false}
              />
            </Col>
          </Row>
          <div className="btn-wrap" style={{ display: "flex" }}>
            <Button
              onClick={() => !btnLoading && navigate("/app/patients-details", {state:{id: patientData?.patientId, name: patientData?.patientName}})}
              variant="secondary"
              title="Cancel"
            >
              Cancel
            </Button>
            <Button type="submit" isLoading={btnLoading}>
              Submit
            </Button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default CreateObservation
