import React from "react";
import GreenArrowIcon from "../../../assets/images/green-arrow.png";
import RedArrowIcon from "../../../assets/images/red-arrow.png";
import CircularProgress from '@mui/material/CircularProgress';

const OrganizationCard = ({ title, selected }) => {
    return (
        <>
        <div className={"box-wrap"} style={selected ? {color: '#005da8', background: "#e9f5ff"}:{}}>
            <div className="top-wrap">
                <div className="texts-wrap">{title}</div>
            </div>
        </div>    
        </>
    );
};

export default OrganizationCard;
