import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "./EncounterDetail.css"
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ArrowBack, ExpandMore } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetPatientById } from "../../../hooks/ReactQueryHooks/useGetPatientById";
import moment from "moment";
import { usePatientEncounterHistoryQuery } from "../../../hooks/ReactQueryHooks/usePatientEncounterHistoryQuery";
import { useEncounterQuestionnaireResponseQuery } from "../../../hooks/ReactQueryHooks/useQuestionnaireResponseQuery";
import { useSelector } from "react-redux";
import { Button } from '../../common/Button';
import ServiceLocationModal from "../ServiceRequest/ServiceLocationModal";
import html2pdf from "html2pdf.js";
import { axios } from '../../../lib/axios';
import { getOrganization } from "../../api/Organization";
import { failed } from "../../common/Toastify";
import { getSingleUser } from "../../api/Individual";
import { imageURLToBase64 } from "../../../utils/ImageToBase64";
import { FullPageSpinner } from "../../common/Spinner/FullPageSpinner";
import { ViewDate, ViewTime } from "../../../utils/DateSupport";

const EncounterDetail = () => {
const navigate = useNavigate()
const [patientData, setPatientData] = useState(null);
const [encounteredData, setEncounteredData] = useState([])
const [encounterQuestionairData, setEncounterQuestionairData] = useState([])
const {organizations, organizationLogo} = useSelector((state) => state?.auth?.user)
const {state} = useLocation();
const { patientId, practionerData, encounterId, practitionerId, from } = state
const [openModal, setOpenModal] = useState(false);
const [base64Url, setBase64Url] = useState("");
const user = useSelector((state) => state?.auth?.user)
const [orgnization, setOrganizations] = useState({})
const [practitionerDetails, setPractitionerDetails] = useState([])
const [base64SignImg, setBase64SignImg] = useState("");
const [base64OrgImg, setBase64OrgImg] = useState("");
const [loadingData, setLoadingData ] = useState(false)

const onSuccessPatient = (data) => {
    setPatientData(data);
};
useGetPatientById({ onSuccess: onSuccessPatient, patientId });

// const onSuccessForEncounterHistory = (data) => {
//   setEncounteredData(data?.map((item) => ({ ...item, name: item?.id + (item?.period?.start ? `(${moment(item?.period?.start).format("YYYY-MM-DD")})` : ''), value: item?.id })));
// }

const onSuccessForEncounterQuestionnaireResponseQuery = (data) => {
  setEncounterQuestionairData(data);
}

// usePatientEncounterHistoryQuery({ onSuccess: onSuccessForEncounterHistory, startDate: moment().subtract(3, 'months').format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD"), patientId: patientId, refetchOnWindowFocus: false, practitionerId: practitionerId });

useEncounterQuestionnaireResponseQuery({ onSuccess: onSuccessForEncounterQuestionnaireResponseQuery, encounterId})

useEffect(() => {
      getOrganization(user?.organizations?.[0]?.id)
          .then((res) => {
              setOrganizations(res.data);
          })
          .catch((res) => {
              failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
          });
    }, []);

useEffect(() => {
 if(from  === "fromServiceReq")  localStorage.setItem("activeTableKey", "seventh");
 else if (from  === "fromMedicationReq") localStorage.setItem("activeTableKey", "eighth");
 else if(from === "pastEncounter") localStorage.setItem("activeTableKey", "fifth");
}, [])

useEffect(() => {
  const fetchData = async () => {
    if (encounterId) {
      try {
        const response = await axios.get(`encounter/${encounterId}`);
        setEncounteredData(response?.data);
        setBase64OrgImg(organizationLogo);
        /* Oragnization url to base64 */
        // signImageURLToBase64(organizationLogo)
        //   .then(base64String => {
        //   setBase64OrgImg(base64String);
        //   })
        //   .catch(error => {
        //   console.log('Error:', error);
        //   });
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };
  fetchData();
}, []);

const handleSendDocument = () => {
  const element = document.getElementById("encounter-detail");

  html2pdf().from(element).set({ 
    margin: [0.5, 0.5, 0.5, 0.5], 
    filename: `Encounter/${encounterId}`, 
    image: { type: 'jpeg', quality: 2 },
    html2canvas: { scale: 2, useCORS: true, letterRendering: true },
    jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
    pagebreak: { before: ['.service-info-block', '.questions-wrapper'], avoid: ['.header', '.common-listing'] },
  }).toPdf().output('datauristring').then((pdfDataUri) => {
    const base64String = pdfDataUri.split(',')[1];
    setBase64Url(base64String);
    setOpenModal(encounteredData);
  });
};

  useEffect(() => {
    if (practitionerId) {
      setLoadingData(true);
      getSingleUser({ id: practitionerId, type: "Practitioner" })
        .then((res) => {
          if (res?.status === false || (res?.status === true && res?.message == "Not Found") || (res?.status === true && Object.keys(res?.data).length == 0)) {
            axios.get(`practitioner/${practitionerId}`)
              .then((practitioner) => {
                setPractitionerDetails(practitioner?.result);
                setBase64SignImg(practitioner?.result?.signatureURL?.base64Data)
                setLoadingData(false);
                /* Signature image to base64 */
                // signImageURLToBase64(practitioner?.result?.signatureURL?.url)
                // .then(base64String => {
                //   setBase64SignImg(base64String)
                // })
                // .catch(error => {
                //   console.error('Error converting image URL to base64:', error);
                // });
              })
              .catch((error) => {
                setLoadingData(false);
                failed(error?.response?.data?.message || error?.response?.data?.error || error.message);
              });
          } else {
            setPractitionerDetails(res?.data);
            setBase64SignImg(res?.data?.signatureURL?.base64Data)
            setLoadingData(false);
            /* Signature image to base64 */
            // signImageURLToBase64(res?.data?.signatureURL?.url)
            // .then(base64String => {
            //   setBase64SignImg(base64String)
            // })
            // .catch(error => {
            //   console.error('Error converting image URL to base64:', error);
            // });
          }
        })
        .catch((error) => {
          setLoadingData(false);
          failed(error?.response?.data?.message || error?.response?.data?.error || error.message);
        });
    }
  }, [practitionerId]);

  async function signImageURLToBase64(url) {
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: { "Cache-Control": 'no-cache' },
    });
      const blob = await response.blob();
      const base64String = await blobToBase64(blob);
      return base64String;
    } catch (error) {
      console.error('Error converting image URL to base64:', error);
      throw error;
    }
  }
  
  function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
    });
  }

  if(loadingData) return <FullPageSpinner loadingText="Loading"/>; 
  
return (
    <>
     {openModal ? <ServiceLocationModal
        onHide={() => setOpenModal(false)}
        modalShow={openModal}
        paramsObj={{patientId, orgId: organizations?.[0]?.id , base64: base64Url}}
        practitionerData={user}
        requestType={"Encounter Report"}
        categoryVar={"Encounter Report"}
        patientData={patientData}
        orgName={organizations?.[0]?.name}
        fromServiceRequest={false}
        fromMedicationRequest={false}
        fromEncounter={true}
        encounterId={encounterId}
        documentName={`Encounter/${encounterId}`}
      /> : null}
      <section className="common-listing">
        <div className="heading-wrap mb-3 d-flex align-items-center justify-content-between">
          <div className="page-title-block d-flex align-items-center">
            <span style={{ cursor: "pointer" }} onClick={() => navigate("/app/patients-details" ,{state: { id: patientId, name: patientData?.name?.[0].text, email: patientData?.telecom.find((element) => element.system == "email").value}})}>
              <ArrowBack />
            </span>
          </div>
          <Button onClick={() => orgnization?.sft?.enabled && (orgnization?.sft?.locationDelivery) ? handleSendDocument() : (orgnization?.sft?.enabled && (!orgnization?.sft?.locationDelivery)) ? failed("Please enable delivery to location to send document") : failed("Please enable SFT to send document.")}>Send Document</Button>
        </div>

        <section id="encounter-detail">
          <div className="header">
            <Row className="pt-4">
              <Col sm={2} className="ps-4">
                <div className="circular-container">
                <img src={base64OrgImg} className="circular-image"/>
                </div>
                <h6 className="user-name-title fw-bold" style={{textAlign:'center'}}>{organizations?.[0]?.name}</h6>
              </Col>
              <Col lg={6} className="border-left ps-3 pe-5">
                <Row>
                  <Col sm={5}>
                    <h6>Encounter Date</h6> {ViewDate(encounteredData?.period?.end) || "-"} </Col>
                  <Col sm={4}><h6>Completion Time</h6> {ViewTime(encounteredData?.period?.end) || "-"} </Col>
                  <Col sm={3}><h6>Encounter Id</h6> {encounterId || "-"} </Col>
                </Row><br/>
                <Row>
                  <Col sm={5}>
                    <h6>Provider Name</h6> {practitionerDetails?.name?.[0]?.text?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') || "-"} </Col>
                  <Col sm={7}><h6>Provider Designation</h6> {practitionerDetails?.designation === "nursePractitioner" ? "Nurse Practitioner" : practitionerDetails?.designation?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') || "-"} </Col>
                </Row>
              </Col>
              <Col lg={4} className="border-left ps-5">
                <h6>CONFIDENTIAL: </h6>
                <p className="label-grey">This information may have been disclosed to you from records whose confidentiality is protected by Federal law, which prohibits you from further disclosing it without the written consent of the person to whom it pertains or as otherwise permitted by Federal Regulations. If you receive this in error, please notify our office immediately at 1-877-870-8068.</p>
              </Col>
            </Row>
          </div> <br/>

            {/* Patient Details */}
            <b>Patient Details</b> <br/><br/>
            <Row className="">
              <Col sm={2}><h6 className="label-grey">Name</h6> {patientData?.name?.[0].text?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') || "-"}</Col>
              <Col sm={2}><h6 className="label-grey">DOB-(Age)</h6>{patientData?.birthDate ? (<>{ViewDate(patientData?.birthDate)} - {'(' + moment().diff(moment(patientData?.birthDate, 'MM-DD-YYYY'), 'years') + ')'}</>) : "-"}</Col>
              <Col sm={2}><h6 className="label-grey">Gender</h6>{patientData?.gender?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') || "-"}</Col>
              <Col sm={2}><h6 className="label-grey">HCN</h6>{patientData?.identifier?.find((ele) => ele?.type === "HCN")?.value || "-"}</Col>
              <Col sm={2}><h6 className="label-grey">Phone</h6> {patientData?.telecom?.find((ele) => ele?.system === 'phone')?.value || "-"}</Col>
              <Col sm={2}><h6 className="label-grey">Address</h6>{patientData?.address?.[0].line?.[0] || "-"}</Col>
            </Row><hr/>

          {/* Questionnaire Details */}
            {/* {encounteredData?.questionnaireResponse || encounterQuestionairData?.length > 0 ?  <h6><b>Questionnaire</b></h6> : null}  */}
            {encounteredData?.intakeQuestions || encounterQuestionairData?.length > 0 ?  <h6><b>Questionnaire</b></h6> : null} 
            {/* {encounteredData?.questionnaireResponse && ( */}
            {encounteredData?.intakeQuestions && (
              // <Row className="align-items-center mt-3">
              //   {encounteredData?.questionnaireResponse?.callerName && encounteredData?.questionnaireResponse?.callerRelationship !== "Self" 
              //     ? <Col sm={2}>
              //         <h6 className="label-grey">Caller Name</h6>
              //         {encounteredData?.questionnaireResponse?.callerName}
              //       </Col>
              //     : null}
              //   {encounteredData?.questionnaireResponse?.callerRelationship && encounteredData?.questionnaireResponse?.callerRelationship !== "Self" 
              //     ? <Col sm={3}>
              //       <h6 className="label-grey">Relationship to Patient</h6>
              //       {encounteredData?.questionnaireResponse?.callerRelationship}
              //       </Col>
              //     : null}
              //   {encounteredData?.questionnaireResponse?.callerNumber && (
              //     <Col sm={3}>
              //       <h6 className="label-grey">Return Telephone Number</h6>
              //       {encounteredData?.questionnaireResponse?.callerNumber} { encounteredData?.questionnaireResponse?.callerNumberExt &&  encounteredData?.questionnaireResponse?.callerNumberExt != "" ? 'ext. ' + encounteredData?.questionnaireResponse?.callerNumberExt : '' }
              //     </Col>
              //   )}
              //   <Col sm={4}></Col>
              // </Row>
              <Row className="align-items-center mt-3">
                {encounteredData?.intakeQuestions?.callerName && encounteredData?.intakeQuestions?.callerRelationship !== "Self" 
                  ? <Col sm={2}>
                      <h6 className="label-grey">Caller Name</h6>
                      {encounteredData?.intakeQuestions?.callerName}
                    </Col>
                  : null}
                {encounteredData?.intakeQuestions?.callerRelationship && encounteredData?.intakeQuestions?.callerRelationship !== "Self" 
                  ? <Col sm={3}>
                    <h6 className="label-grey">Relationship to Patient</h6>
                    {encounteredData?.intakeQuestions?.callerRelationship}
                    </Col>
                  : null}
                {encounteredData?.intakeQuestions?.callerNumber && (
                  <Col sm={3}>
                    <h6 className="label-grey">Return Telephone Number</h6>
                    {encounteredData?.intakeQuestions?.callerNumber} { encounteredData?.intakeQuestions?.callerNumberExt &&  encounteredData?.intakeQuestions?.callerNumberExt != "" ? 'ext. ' + encounteredData?.intakeQuestions?.callerNumberExt : '' }
                  </Col>
                )}
                <Col sm={4}></Col>
              </Row>
            )}
            
              {encounterQuestionairData?.length > 0 && encounterQuestionairData?.map((encounter, index) => (
                <div key={`encounter-${index}`}>
                <div className="fw-bold mt-3">{encounter?.questionnaireTitle}</div>
                {encounter?.item?.map((data) => (
                  <>
                  {data?.answer?.[0]?.valueString && (
                    <div className="accordion-view-block mt-2" key={data?.linkId}>
                      <div className="header-accordion p-3 pt-2 pb-2">
                        {data?.text}
                      </div>
                      <div className="body-accordion p-3">
                        <div className="m-0" dangerouslySetInnerHTML={{__html: `${data?.answer?.[0]?.valueString}`}} />
                      </div>
                    </div>
                  )}
                  {data?.questionType === "radio" && (
                    data?.answer?.some(ele => ele?.valueBoolean === true) && (
                      <div className="accordion-view-block mt-3" key={data?.linkId}>
                        <div className="header-accordion p-3 pt-2 pb-2">
                          {data?.text}
                        </div>
                        <div className="body-accordion p-3">
                          <div className="m-0">
                            {data?.answer?.map(ele => (
                              ele?.valueBoolean === true && (
                                <div key={ele.id}>{ele?.option}</div>
                              )
                            ))}
                          </div>
                        </div>
                      </div>
                    )
                  )}
                  {data?.questionType === "checkbox" && (
                    data?.answer?.some(ele => ele?.valueBoolean === true) && (
                      <div className="accordion-view-block mt-3" key={data?.linkId}>
                        <div className="header-accordion p-3 pt-2 pb-2">
                          {data?.text}
                        </div>
                        <div className="body-accordion p-3">
                          <div className="m-0">
                            {data?.answer?.map(ele => (
                              ele?.valueBoolean === true && (
                                <div key={ele.id}>{ele?.option}</div>
                              )
                            ))}
                          </div>
                        </div>
                      </div>
                    )
                  )}
                  </>
                ))}
                </div>
              ))}

              {encounteredData?.documentDelivery && encounteredData?.documentDelivery.some(delivery => delivery?.type !== "HOSP") && (
               <> 
                {/* Service Request sent */} 
                {encounteredData?.documentDelivery.some(delivery => delivery?.type !== "PHARM") ?  
                  <div className="service-info-block">
                    <div className="additional-info-block mt-3">
                      <div className="title-header p-3">
                          <h5 className="m-0">Service request sent to...</h5>
                      </div>
                      {encounteredData?.documentDelivery?.filter(delivery => delivery?.type !== "PHARM" && delivery?.type !== "HOSP")?.map((ele, index) => (
                        <div className="body-info p-3 pt-0 pb-2" key={index}>
                            <Row>
                            <Col md={2}>
                              <p className="info-title m-0 mb-2">Name</p>
                              <p className="info-details m-0">{ele?.display?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</p>
                            </Col>
                            <Col md={2}>
                              <p className="info-title m-0 mb-2">Fax Number</p>
                              <p className="info-details m-0">{ele?.telecom?.find((data) => data?.system === "fax")?.value || "-"}</p>
                            </Col>
                              <Col md={2}>
                                <p className="info-title m-0 mb-2">Type</p>
                                <div className="type-list-block">
                                  {ele?.type === "MBL" && (<span>Lab</span>)}
                                  {ele?.type === "RADDX" && (<span>Diagnostics</span>)}
                                  {ele?.type === "DX" && (<span>Referral</span>)}
                                </div>
                              </Col>
                          </Row>
                        </div>
                      ))}
                    </div>
                  </div>
                : null}

                {/* Medication Request sent */} 
                {encounteredData?.documentDelivery.some(delivery => delivery?.type === "PHARM") ?  
                  <div className="additional-info-block mt-3">
                    <div className="title-header p-3">
                        <h5 className="m-0">Prescription sent to...</h5>
                    </div>
                    {encounteredData?.documentDelivery?.filter(delivery => delivery?.type === "PHARM")?.map((ele, index) => (
                      <div className="body-info p-3 pt-0 pb-2" key={index}>
                          <Row>
                          <Col md={2}>
                            <p className="info-title m-0 mb-2">Name</p>
                            <p className="info-details m-0">{ele?.display?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</p>
                          </Col>
                          <Col md={2}>
                            <p className="info-title m-0 mb-2">Fax Number</p>
                            <p className="info-details m-0">{ele?.telecom?.find((data) => data?.system === "fax")?.value || "-"}</p>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </div>
                : null}
              </>
              )}

                {/* Practitioner Details */}
                <h6 className="mt-3">{practitionerDetails ? <b>Practitioner</b> : null }</h6>
                <div className="body-info pt-0 pb-2">
                  <Row>
                    <Col md={4}>
                      {base64SignImg && (<img width={80} src={base64SignImg} style={{ marginLeft: "10px" }} />)}
                      {practitionerDetails && practitionerDetails?.name && (<h6 className="info-title mt-2 mb-2" style={{fontSize: "16px"}}> {practitionerDetails?.name?.[0]?.text?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</h6>)}
                      {practitionerDetails && practitionerDetails?.designation && (<h5 className="info-details m-0" style={{fontSize: "14px" , color: "#878686"}}>{practitionerDetails?.designation === "nursePractitioner" ? "Nurse Practitioner" : practitionerDetails?.designation?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</h5>)}
                    </Col>
                  </Row>
                </div>

            {/* {encounterQuestionairData.length > 0 ? <>
            <hr />
            <div className="questions-wrapper">
              <h6>Questionnaire</h6>
              {encounterQuestionairData[0]?.item.map((data)=>(
                <Accordion key={data?.linkId}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    sx={{ fontWeight: "600" }}
                  >
                    {data?.text}
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: 0 }}>
                  <div dangerouslySetInnerHTML={{__html: `${data?.answer?.[0]?.valueString}`}} />
                  </AccordionDetails>
              </Accordion>
              ))}
            </div> 
            </> : null} */}
        </section>
     </section>
    </>
  );
}

export default EncounterDetail
