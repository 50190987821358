import { useQuery } from "react-query";
import { axios } from "../../lib/axios";
import { onError } from "./onError"

export function useDiscoverIdQuery({ orgID, onSuccess, ...props }) {   

    // const fetchOrganizations = () => {
    //     return axios.get(`discover/organization/${props.modalId}`);
    // };

    const fetchOrganizations = () => {
        return axios.get(`discover/organization/${props.modalId}`)
            .then(response => {             
                return response;
            })
            .catch(error => {                
                throw error; 
            });
    };   
    const query = useQuery(
        "discover/organization",
        () => fetchOrganizations(),
        {
            select: (data) => data.data,
            onSuccess,
            onError,
            ...props
        }
    );

    return { ...query };
}
