import moment from "moment";
import { axios } from "../../../../lib/axios";
import { encryptData } from "../../../EncryptDecrypt";

export const getPatientReading = (id = null, apiBody) => {
    return id ? axios.get(`observation?patientId=${id}`) : axios.get(`observation?patientId=${apiBody.patientId}&conditionName=${apiBody?.conditionName}&startDate=${apiBody.startDate}&endDate=${apiBody.endDate}&isWeb=true`)
}

export const getPatientReadingLatest = (apiBody)=>{
    return axios.get(`observation?patientId=${apiBody?.patientId}&conditionName=${apiBody?.conditionName}`)
}
export const getPatientLatestReadingDate = (patientId) => {
    return axios.get(`observation?isGetLatest=${true}&patientId=${patientId}`)
}
export const getThreshold = (id = null, apiBody) => {
    return id ? axios.get(`threshold/get?patientId=${id}`) : axios.get("threshold/get", apiBody)
}
export const getRelatedPersons = (id = null, apiBody) => {
    return id ? axios.get(`relatedPerson?patientRefId=${id}`) : axios.get("relatedPerson", apiBody)
}
export const getDisease = (id = null) => {
    return axios.get(`disease/${id}`)
}

export const addDisease = (apiBody) => {
    return axios.post("disease", apiBody)
}

export const editDisease = (apiBody) => {
    return axios.put("disease", apiBody)
}

export const addThreshold = (apiBody) => {
    return axios.post("threshold/add", apiBody)
}
export const addThresholdGroup = (apiBody) => {
    return axios.post("threshold/create-group", apiBody)
}
export const addPatientMedication = ({ start, end, name, dose, repeats, reason, patientId, repeatsTime }) => {
    const data = {
        patientId,
        "effectivePeriod": {
            start: moment(start).format("YYYY-MM-DDT00:00:00.000[Z]"),
            end: moment(end).format("YYYY-MM-DDT23:59:59.000[Z]"),// "2023-01-23T18:30:00.000Z"
        },
        "medication": [
            {
                name: encryptData(name),
                dose,
                interval: repeats,
                "repeats": repeatsTime, // "time": "T01:15:00.639Z",
                reason: encryptData(reason),
            }
        ]
    }

    return axios.post("medication", data)
}

export const editPatientMedication = ({ id, start, end, name, dose, interval, reason, patientId, repeatsTime, medId }) => {
    const data = {
        "isMobile": false,
        "effectivePeriod": {
            start: moment(start).format("YYYY-MM-DDT00:00:00.000[Z]"),
            end: moment(end).format("YYYY-MM-DDT23:59:59.000[Z]"),// "2023-01-23T18:30:00.000Z"
        },
        "medication": {
            [medId]: {
                name: encryptData(name),
                dose,
                interval,
                "repeats": repeatsTime, // "time": "T01:15:00.639Z",
                reason: encryptData(reason),
            }
        }
    }

    return axios.put(`medication/${patientId}/${id}`, data)
}

export const searchPatient = (apiBody) => {
    const dob = apiBody?.birthDate ? moment(apiBody?.birthDate).format("MM-DD-YYYY") : "";
    return axios.get(`patient?orgId=${apiBody?.org_id}&firstName=${apiBody?.firstName}&lastName=${apiBody?.lastName}&birthDate=${dob}&healthCardNumber=${apiBody?.healthCardNumber}`)
}