import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import * as yup from "yup";
import { useProgramQuery } from "../../../hooks/ReactQueryHooks/useProgramQuery";
import { enrollPatientsIntoRpm } from "../../api/Individual";
import { Button } from "../../common/Button";
import SelectFiled from "../../common/textfield/SelectFiled";
import { failed, success } from "../../common/Toastify";
import { useNavigate } from "react-router-dom";

export function AddRPMModal({ modalShow, handleShow, refreshPatients, programId, orgId }) {

    const onSuccess = (data) => {
        setProgramList(data)
    }
    const navigate = useNavigate();
    useProgramQuery({ onSuccess, orgIds: [orgId] })
    const [btnLoading, setBtnLoading] = useState(false);
    const [programList, setProgramList] = useState([]);
    useEffect(() => {
        formik.setFieldValue("RPM", programId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalShow])
    const formik = useFormik({
        initialValues: {
            RPM: "",
        },
        validationSchema: yup.object({
            RPM: yup.string().required("Required RPM field"),
        }),
        onSubmit: (values) => {
            setBtnLoading(true);
            enrollPatientsIntoRpm([...modalShow], values.RPM)
                .then((res) => {
                    if (res.status === true) {
                        navigate("/app/manage-individuals");
                        success(res.message);
                        handleShow();
                        refreshPatients();
                    } else {
                        failed(res.error);
                    }
                })
                .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                .finally(() => setBtnLoading(false));
        },
    });

    return (
        <Modal
            show={modalShow}
            onHide={handleShow}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdropClassName
            backdrop={'static'}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Assign RPM program
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form
                    className="common-form border-fields"
                    onSubmit={formik.handleSubmit}
                >
                    <Row>
                        <Col>
                            {programList?.length > 0 ?
                                <SelectFiled
                                    keyField={"RPM"}
                                    label={"Select RPM"}
                                    formik={formik}
                                    placeholder={"Select RPM Title"}
                                    options={programList?.map(program => ({ name: program.title, value: program.id }))}
                                /> :
                                "Program not Found"
                            }
                        </Col>
                    </Row>
                    <div className="btn-wrap">
                        <Button onClick={handleShow} variant="secondary" title="Cancel">Cancel</Button>
                        <Button type="submit" isLoading={btnLoading}>Assign RPM</Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}