import * as Yup from "yup";

export const HeadCells = [
    {
        id: 'name',
        label: 'Name',
        type: 'text',
        nested: { key: "name" },
    },
    {
        id: 'organisation',
        label: 'Organization',
        type: 'text',
        nested: { key: "organizationName", },
    },
    {
        id: 'members_count',
        label: 'Members count',
        nested: { key: "memberCount", },
        style: { textAlign: "center" },
    },
    {
        id: 'actions',
        label: 'Actions',
        type: 'text',
        style: { textAlign: "center" },
    },
];
export const DataFields = [
    'name',
    "organisation",
    "members_count",
    "actions"
];

export const UsersHeadCells = [
    {
        id: 'name',
        label: 'Name',
        type: 'text',
        isDecrypted: true,
        nested: { key: "name", value: ({ index: 0, key: "text" }) },
    },
    {
        id: 'phone',
        label: 'Phone Number',
        type: 'text',
        isDecrypted: true,
        nested: { key: "telecom", value: { index: 0, key: "value" } },
    },
    {
        id: 'address',
        label: 'Address',
        type: 'text',
        isDecrypted: true,
        nested: { key: "address", value: { index: 0, key: "line", } },
    },
    {
        id: 'organization',
        label: 'Organization',
        type: 'text',
        style: { textAlign: "center" },
        nested: { key: "managingOrganization", value: { index: 0, key: "display" } },
    },
    {
        id: 'actions',
        label: 'Actions',
        type: 'text',
        style: { textAlign: "center" },
    },
];

export const UsersDataFields = [
    'name',
    'phone',
    'Address',
    'organization',
    "actions"
];

export const FormikObj = {
    initialValues: {
        member: [],
        orgId: "",
        name: "",
        description: "",
        quantity: 50,
        type: "Group",
        isCustom: true,
    },
    validationSchema: Yup.object({
        member: Yup.array().min(1, "Required field"),
        orgId: Yup.string().required("Required organization").matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
        name: Yup.string().max(60, "Must be 60 characters or less").required("Required name field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        description: Yup.string(),
        type: Yup.string().required("Required description field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        isCustom: Yup.boolean(),
    }),
}