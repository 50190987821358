export const ViewAppointmentsTitle = "Viewing Appointments for";
export const ManagePractitionerTitle = "Manage Practitioners";
export const NameTitle = "Name";
export const PhoneNumber = "Phone Number";
export const CreatedTitle = "Created";
export const ActionTitle = "Action";
export const NoDataAvailTitle = "No Data Available";
export const ViewInfoTitle = "View Info";
export const ViewScheduleTitle = "View Schedule";
export const ViewAppointmentsTooltipTitle = "View Appointments"
export const CreateAppointmentTitle = "Create Appointment";