import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { decryptData } from '../../EncryptDecrypt';
import Background from "../../../assets/images/search-icon.png"

const Search = ({ list = [], filterData, encoded = false, placeholder = "Search..." }) => {
    const [searchVal, setSearchVal] = useState("");
    const filter = () => {
        if (!list || !Array.isArray(list)) {
            return;
        }
        const data = list?.filter((data) => decryptData(data?.participant?.filter((data) => data?.actor?.type === "Patient")?.[0]?.actor?.display)?.toLowerCase()?.includes(searchVal))
        filterData(data);
    }
    useEffect(() => {
        filter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchVal, list])
    return (
        <Form className="search-wrap">
            <Form.Control
                type="search"
                placeholder={placeholder}
                className="me-2"
                aria-label="Search"
                style={{ backgroundImage: searchVal ? "none" : `url(${Background})` }}
                value={searchVal}
                onChange={(e) => setSearchVal(e.target.value.toLowerCase())}
            />
        </Form>
    )
}

export default Search