import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { axios } from "../../lib/axios";
import { onError } from "../ReactQueryHooks/onError";

export function useGetThresholdGroup({ onSuccess }) {
    //const practitioner_id = useSelector((state) => state?.auth?.user?.["custom:unique_id"])
    const org_id = useSelector((state) => state?.auth?.user?.organizations[0]?.id)

    const fetchThreshold = (org_id) => {
        return axios.get(`threshold/get-group?orgId=${org_id}`);
    };
    const query = useQuery(
        "thresholdgroup",
        () => fetchThreshold(org_id),
        {
            select: (data) => data.data,
            onSuccess,
            onError
        }
    );

    return { ...query };
}
