import React from 'react';
import { CountryDropdown } from 'react-country-region-selector';

function CountrySelect({ country, setCountry, keyField, formik, label, required = true, disabled = false, readOnly = false, whitelist = ['US', 'CA'] }) {
    const handleChange = (val) => {
        setCountry && setCountry(val);
        formik.setFieldValue(keyField, val);
    };
    return (
        <div className="field-wrap">
            <label htmlFor={keyField}>
                {label}
                {required ? <span style={{ color: "red" }}> *</span> : " (Optional)"}
            </label>
            <CountryDropdown
                value={country || formik?.values?.[keyField]}
                onChange={(val) => handleChange(val)}
                whitelist={whitelist}
                valueType="short"
                disabled={disabled}
                id={keyField}
                style={{ pointerEvents: readOnly ? "none" : "all" }}
            />
            <div>{formik?.touched[keyField] && formik?.errors[keyField] ? <div className="error-text">{formik?.errors[keyField]}</div> : null}</div>
        </div>
    )
}

export default CountrySelect