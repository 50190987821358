import { useQuery } from "react-query";
import { axios } from "../../lib/axios";
import { onError } from "./onError"

export function useGetPractScheduleByDate({ onSuccess, practId, date, isGetAll = false }) {

    const fetch = () => {
        return axios.get(isGetAll ? `schedule/check?actorId=${practId}&isGetAll=true` : `schedule/check?actorId=${practId}&date=${date}`);
    };
    const query = useQuery(
        "schedule-by-date",
        () => fetch(),
        {
            refetchOnWindowFocus: false,
            select: (data) => data,
            onSuccess,
            onError
        }
    );

    return { ...query };
}