import React from "react";
import Modal from 'react-bootstrap/Modal';
import { decryptData } from "../../../EncryptDecrypt";
import { practitionerNoteTitle } from "../Constants";

export function NoteModal({ modalShow, handleShow }) {

    return (
        <>
            <Modal
                backdropClassName
                backdrop={'static'}
                size="lg" show={modalShow} onHide={handleShow}
                aria-labelledby="contained-modal-title-vcenter"
                centered className="custom-dialog">
                <Modal.Header closeButton className="border-0">
                    <Modal.Title id="contained-modal-title-vcenter">
                        {practitionerNoteTitle}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{decryptData(modalShow) || "No reason added in the Appointment !"}</p>
                </Modal.Body>
            </Modal>
        </>
    );
}
