import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SearchBar from "../../common/components/SearchBar";
import CustomTable from "../../common/table/CustomTable";
import { UsersDataFields, UsersHeadCells } from "./Constant";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import { failed, success } from "../../common/Toastify";
import { ArrowBack } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { decryptData } from "../../EncryptDecrypt";
import { Button } from "../../common/Button";
import AddUser from "./AddUser";
import { deleteEnrollPatients } from "../../api/Program"
import { useUsersQuery } from "../../../hooks/ReactQueryHooks/useUsersQuery";

const ProgramUsersListing = () => {
    let { state } = useLocation();
    const navigate = useNavigate();
    const { getConfirmation } = useConfirmDialog();
    const [filterData, setFilterData] = useState([]);
    const [page, setPage] = useState(0);
    const [deleting, setDeleting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [programUserData, setprogramUserData] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [alreadyAddedUserIds, setAlreadyAddedUserIds] = useState(false);
    const groupType =  "Patient" ; 
    const programId = state?.id; 
    const groupIsDefault = !!state?.isDefault;
     
    const onProgramSuccess = (data) => {
        if (data) {  
            setUsersList(data) 
            const programUsers  = data.filter((item) => item?.program?.id === programId);   
            setprogramUserData(programUsers);  
            setAlreadyAddedUserIds(programUsers?.map((item) => item?.id))
            setSelectedUsers(  usersList?.filter((item) => programUsers?.includes(item.id)) ); 
        }
    } 

    const { isLoading, isFetching, refetch: refetchProgramUsers  } = useUsersQuery({
        queryParams:  `orgId=${state?.organization?.[0] || []}&type=${groupType}&getUserWithSubOrg=true`,
        onSuccess: onProgramSuccess,
        refetchOnWindowFocus: false,
    });
    
    const handleFilterData = (data) => {
        setFilterData(data); 
        setPage(0)
    };

    const handleView = (data) => { 
        navigate("/app/patients-details", { state: { id: data?.id, name: data?.name[0]?.text, isDoctor: true } })
    };

    const selectedUserAction = ({ action, data }) => {
        switch (action) {
            case "view":
                handleView(data);
                break;
            case "delete":
                handleDelete(data?.id, data?.program?.id, decryptData(data?.name?.[0]?.text));
                break;
            default:
                return null;
        }
    };

    const handleDelete = async (id, programId, entryName) => {
        const confirmed = await getConfirmation({
            title: "Attention!",
            entryName,
            actionBtnTitle: "Delete",
        });
        if (confirmed) { 
            setDeleting(id);
            deleteEnrollPatients(id, programId)
                .then((res) => {
                    
                    if (res.status === true) { 
                        success(res.message);
                        refetchProgramUsers(); 
                    } else {
                        failed(res.error);
                    }
                })
                .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                .finally(() => setDeleting(false));
        }
    };


    return (
        <section className="common-listing">
            {showModal
                ? <AddUser
                    modalShow={showModal}
                    refreshTable={refetchProgramUsers}
                    usersList={usersList}
                    programId={programId}
                    existingGroupMembers={programUserData}
                    alreadyAddedUserIds={alreadyAddedUserIds}
                    groupIsDefault={groupIsDefault}
                    groupUsersType={groupType}
                    selectedUsers={selectedUsers}
                    handleShow={() => setShowModal(false)}
                /> : null}
            
            <div className="heading-wrap h-change">
                <Tooltip title="Go back">
                    <div style={{ marginRight: "1rem", cursor: "pointer" }} onClick={() => navigate(-1)}>
                        <ArrowBack />
                    </div>
                </Tooltip>
                <h1>{groupType || "Individuals"} List</h1>
                <SearchBar page={page} setPage={setPage} list={programUserData} filterData={handleFilterData} encoded={true} />
                <div className="right-wrap" style={{ margin: 'left' }}>
                    <Button isLoading={isLoading || isFetching} onClick={() => setShowModal(true)} className="custom-btn" title="Add Group" style={{ width: 'auto' }}>Add</Button>
                </div>
            </div>
            <CustomTable
                tableData={filterData}
                headCells={UsersHeadCells}
                dataFields={UsersDataFields}
                selectedUserAction={selectedUserAction} 
                rowOptions={false}
                loading={isLoading}
                fetching={isFetching}
                deletingItem={deleting}
                canDelete={true}
                canEdit={false}
                canView = {true}
                page={page}
                setPage={setPage}
            />
        </section>
    );
};

export default ProgramUsersListing;
