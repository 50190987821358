import React, { useEffect } from 'react';
import { BrowserRouter as Router, } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AppRoutes } from './Routes';
import { useIdleTimer } from 'react-idle-timer';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from './components/api/AuthService';
import { userlogin, userlogout } from './components/slice/authService';
import { axios } from './lib/axios';
import { apiUrl } from './environment';

function App() {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);
    const tokenExpiry = useSelector((state) => state?.auth?.user?.exp);
    const refreshTokenString = useSelector((state) => state?.auth?.refreshToken);
    const userDetails = useSelector((state) => state?.auth?.user);

    const loginAPICall = async(data)=>{
        if(data) {
            const response = await axios.post(`${apiUrl}auth/admin-login`, data);
            if (response?.status === true) {
                let data = {
                    ...userDetails,
                    exp: response?.exp,
                }
                dispatch(userlogin(data));
                localStorage.setItem("authentication", response?.token);
            } 
        }
    }

    useEffect(() => {
        if (tokenExpiry) {
            const expTimeStamp = tokenExpiry * 1000;
            const fiveMinBeforeExpTime = expTimeStamp -  5 * 60 * 1000;

            if(Date.now() >= fiveMinBeforeExpTime) {
                loginAPICall({refresh_token: refreshTokenString})
            }
        }
      }, []);
    
    // Check whether the token is expired or not with every action
    const handleOnAction = (e) => {
        if (tokenExpiry) {
            // Converted tokenExpiry(Seconds) to milliseconds
            const expTimeStamp = tokenExpiry * 1000;;
            // Substract 5 min from expity time
            const fiveMinBeforeExpTime = expTimeStamp -  5 * 60 * 1000;
            // Call login API before 5 min of expiry time
            if(Date.now() >= fiveMinBeforeExpTime) {
                loginAPICall({refresh_token: refreshTokenString})
            }
        }
    }

    // Handle when user is inactive
    const handleOnIdle = () => {
        if(isLoggedIn) {
            logout();
            dispatch(userlogout());
        }
    };

    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * 55, // 1 hour
        onIdle: handleOnIdle,
        onAction: handleOnAction,
        debounce: 500
    });

    return (
        <div className="App" onMouseMove={handleOnAction}>
            <Router>
                <AppRoutes />
            </Router>
            <ToastContainer />
        </div>
    );
}

export default App;
