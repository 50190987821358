import moment from 'moment';
import React from 'react'
import EditIcon from "../../../assets/images/edit.png";
import AddNotes from "../../../assets/images/AddNote.png";
import ViewNotes from "../../../assets/images/ViewNote.png";
import { Link, Tooltip } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { ViewDate } from '../../../utils/DateSupport';

export const getStatus = (status) => {
  switch (status) {
      case "active":
          return "Active"
      case "inactive":
          return "Inactive"
      case "remission":
          return "Remission"
      case "resolved":
          return "Resolved"
      case "recurrence":
          return "Recurrence"
      case "relapse":
          return "Relapse"
      default:
          break;
  }
}

const ConditionListng = ({data, handleViewNote, handleShow, patientData, practionerData, setNewNoteData}) => {
  const navigate = useNavigate()
  return (
    <tr key={data?.id}>
      <td>{data?.conditionName}</td>
      <td>{ViewDate(data?.recordedDate)}</td>
      <td>{data?.recorder?.display?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</td>
      <td>{ViewDate(data?.createdAt)}</td>
      <td>{getStatus(data?.clinicalStatus?.coding?.[0]?.display)}</td>
      {!data?.note && (
        <td>
          <Tooltip title={"Add Note"}>
            <Link
              to=""
              variant="primary"
              className="success-btn"
              onClick={() =>  handleShow(data, false)}
              style={{cursor: "pointer"}}
            >
              <img src={AddNotes} alt="Add Note" style={{width : "28px"}}/>
            </Link>
          </Tooltip>
        </td>
      )}
      {data?.note?.length > 0 && (
          <td>
            <Tooltip title={"View Note"}>
              <Link
                to=""
                variant="primary"
                className="success-btn"
                onClick={() => {
                  setNewNoteData(null);
                  handleViewNote(data, false);
                }}
                style={{cursor: "pointer"}}
              >
                <img src={ViewNotes} alt="View Note" style={{width : "28px"}}/>
              </Link>
          </Tooltip>
          </td>
        )}
      <td>
        <div className="action-wrap" style={{display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
          <Tooltip title={"Edit"}>
            <Link
              to=""
              variant="primary"
              className="success-btn"
              onClick={() =>
                navigate("/app/edit-condition", {
                  state: {
                    conditionData: data,
                    patientData: {
                      patientId: patientData?.id,
                      patientName: patientData?.name?.[0]?.text,
                    },
                    practionerData: {
                      id: practionerData?.["custom:unique_id"],
                      name: practionerData?.name?.[0]?.text,
                    },
                  },
                })
              }
            >
              <img src={EditIcon} alt="Edit" />
            </Link>
          </Tooltip>
        </div>
      </td>
    </tr>
  );
}

export default ConditionListng