import { useQuery } from "react-query";
import { axios } from "../../lib/axios";
import { onError } from "./onError";

export function useQuestionnCategoryQuery({ onSuccess, }) {

    const fetch = () => {
        return axios.get("/question/category")
    }

    const query = useQuery(
        "category-list",
        () => fetch(),
        {
            select: (data) => data.data,
            onSuccess,
            onError
        }
    );       
    return { ...query };
}
