import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import { useSelector } from "react-redux";
import { FilterList } from "@mui/icons-material"
import { TableCell, Menu, MenuItem, Tooltip, FormControlLabel, Checkbox } from "@mui/material";
import { filterOptions, intialVitalOptions } from "../Constants";
import { DateSelectorWithoutFormik } from "../../../common/textfield/DateSelector";
import moment from "moment";
import { usePatientReadingQuery } from "../../../../hooks/ReactQueryHooks/usePatientReadingQuery";
import PatientReadingTable from "../patient-reading-table";
import {getPatientLatestReadingDate} from "../../api/Patient";
import { deleteThresholdPatient } from "../../api/ThresholdGroup";
import { MedicationsList } from "./MedicationsList";
import { DiseaseList } from "./DiseasesList";
import { ThresholdList } from "./ThresholdList";
import { useConfirmDialog } from "../../../../hooks/useConfirmDialog";
import { failed, success } from "../../../common/Toastify";

export function RpmTable({rpm, parentProps} ) {
  const [vitalsColumn, setVitalsColumn] = useState(intialVitalOptions);
  const [PatientReadingData, setPatientReadingData] = useState([]);
  const [filterCol, setFilterCol] = useState({});
  const [PatientReadingDate, setPatientReadingDate] = useState(null/* moment().format("MM/DD/YYYY") */);
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const { getConfirmation } = useConfirmDialog();
  const open = Boolean(anchorEl);
  const deleteThreshold = async (condition, entryName) => {
    const confirmed = await getConfirmation({
        title: "Attention!",
        entryName,
        actionBtnTitle: "Delete",
    });
    if (confirmed) {
        setDeleting(condition);
        deleteThresholdPatient(parentProps.patientId, condition)
            .then((res) => {
                parentProps.updateThresholddata();
                success("Threshold Deleted!");
            })
            .catch((err) => failed(err?.response?.data?.message || err.message))
            .finally(() => setDeleting(false));
    }
    }

  const onSuccessPatient = (data) => {
    setPatientReadingData(data)
}

  const { isLoading: isLoadingPatient, isFetching: isFetchingPatient/* , refetch: refetchPatient  */} = usePatientReadingQuery({ onSuccess: onSuccessPatient, id: parentProps?.patientId, date: PatientReadingDate });

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  // Get column name
  const getVitalColumnName = (type)=>{
    switch (type) {
        case 'Blood Pressure':
          return "blood_pressure"
        case 'Blood Glucose':
          return "blood_glucose"
        case 'Weight':
          return "weight"
        case 'Temperature':
          return "temperature"
        case 'Oxygen':
          return "oxygen"
        case 'Heart Rate':
          return "heart_rate"
        default:
          return "vitals"
    }
  }

  // Function to return the PatientReadingData which matches the selected columns
  const filterPatientData = (data, columns) => {
    return data?.filter(patient => {
      return columns.some(column => patient?.code?.text?.includes(column));
    });
  };

  // Filter data according to selected columns
  const updateFilteredData = (columns) => {
    const selectedColumns = {};
    for (const key in columns) {
      if (Object.hasOwnProperty.call(columns, key)) {
        const mappedKey = getVitalColumnName(key);
        selectedColumns[mappedKey] = columns[key];
      }
    }
  
    const trueKeys = Object.entries(selectedColumns).filter(([_, value]) => value === true).map(([key]) => key);
  
    const filteredData = filterPatientData(PatientReadingData, trueKeys);
    setFilterCol(filteredData);
  }; 

  const handleOption = (vitalOption) => {
    const updatedColumns = { ...vitalsColumn, [vitalOption]: !vitalsColumn?.[vitalOption] };
    setVitalsColumn(updatedColumns);
    updateFilteredData(updatedColumns);
  };

  useEffect(() => {
   updateFilteredData(vitalsColumn);
  }, [vitalsColumn, PatientReadingData])


//   useEffect(() => {
//     parentProps.refreshReading.current = refetchPatient;
//     getPatientLatestReadingDate(parentProps?.patientId)
//         .then((response) => {
//             // setPatientReadingDate(response?.data?.[0]?.effectiveDateTime);
//         })
//         .catch((error) => { console.log(error); })
// }, []);

  return (
    <Tab.Pane eventKey="tenth">
      {rpm && <div style={{marginTop: "8px"}}><span style={{color: "grey"}}>RPM&gt;</span>
      <span style={{fontWeight: "bold", color: "#0064a6"}}>
        {rpm === "vitals" ? "Vitals" : rpm === "medications" ? "Medications" : rpm === "thresholds" ? "Thresholds" 
        : rpm === "healthConditions" ? "Health Conditions" : ""}</span></div>}
      {rpm === 'vitals' && <>
        <div className="mnth-date-wrap">
            <div style={{ marginRight: "1rem" }}>
            <Tooltip title="Filter Columns">
                <FilterList onClick={handleClick} />
            </Tooltip>
            <Menu
            anchorEl={anchorEl}
            id="account-menu"
            onClose={handleClick}
            open={open}
            PaperProps={{
                elevation: 0,
                sx: {
                overflow: "hidden",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                padding: "0 1rem",
                mt: 2.5,
                ml: 3.5,
                },
            }}
            transformOrigin={{
                horizontal: "left",
                vertical: "bottom",
            }}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            >
            {filterOptions?.map((option, index) => (
                <MenuItem
                key={index}
                onClick={() => handleOption(option)}
                >
                <FormControlLabel
                    sx={{ zIndex: "-1" }}
                    control={
                    <Checkbox checked={vitalsColumn?.[option]} />
                    }
                    label={option}
                />
                </MenuItem>
            ))}
            </Menu>
        </div>
        <DateSelectorWithoutFormik
            label={false}
            maxDate={moment()}
            value={PatientReadingDate}
            handleChange={(val) => setPatientReadingDate(val.format("MM/DD/YYYY"))}
            type={'readonly'}
        />
        </div>
        <div className="table-wrap diff-head">
        <PatientReadingTable
            orgVitalsUnit={parentProps?.orgVitalsUnit}
            patientsReadingData={filterCol}
            PatientReadingDate={PatientReadingDate}
            isLoading={isLoadingPatient}
            isFetching={isFetchingPatient}
            thresholdData={parentProps?.thresholdData}
            columnsToShow={vitalsColumn}
        />
        </div>
      </>}
      {rpm === "medications" &&
        <MedicationsList
        refreshMed={parentProps.refreshMed}
        patientId={parentProps.patientId}
        setMedEditModal={parentProps.setMedEditModal}
        />}
      

    {rpm === "thresholds" && <ThresholdList
        deleting={deleting}
        deleteThreshold={deleteThreshold}
        parentProps={parentProps}
    />}

    {rpm ==="healthConditions"&&  <DiseaseList
        parentProps={parentProps}
        setDiseaseEditModal={parentProps.setDiseaseEditModal}
    />}
    </Tab.Pane>
  );
}