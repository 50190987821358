import { axios } from "../../../lib/axios";

export const createHcn = (data) => {
    let newData = {
        "description": data?.description,
        "display": data?.display,
        "authority": data?.authority,
        "state": data.country+ "-"+data?.state
    }
    return axios.post("authority", newData)
}

export const editHcn = (data) => {
    let newData = {
        "description": data?.description,
        "display": data?.display,
        "authority": data?.authority,
        "state": data?.state
    }
    return axios.put(`authority/${data?.id}`, newData)
}
//Delete HCN Api Call
export const deleteHCN = (id) => {
    return axios.delete(`authority/${id}`)
}