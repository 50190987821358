import React, { useEffect, useRef, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import TextInput from "../../common/textfield/TextInput";
import { useNavigate } from "react-router-dom";
import { Button } from "../../common/Button";
import { failed, success } from "../../common/Toastify";
import { FormikObj, categoryOptions } from "./Constants";
import TextArea from "../../common/textfield/TextArea";
import SelectFiled from "../../common/textfield/SelectFiled";
import { useOrgQuery } from "../../../hooks/ReactQueryHooks/useOrgQuery";
import { Switch, Tooltip } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useSelector } from "react-redux";
import InputFile from "../../common/textfield/InputFile";
import { addDocument } from "../../api/FillableDocument.js";
import { handleFileUpload } from "../../../utils/ImageToBase64";

function AddDocuments() {
    const navigate = useNavigate();
    const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
    const user = useSelector((state) => state?.auth?.user)
    const [btnLoading, setBtnLoading] = useState(false);
    const [organizations, setOrganizations] = useState([]);
    const [file, setFile] = useState();
    const userData = useRef();
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)

    //Fetching Organization Data
    const onSuccessOrg = (data) => {
        setOrganizations(data?.map((org) => (org = { ...org, value: org?.id })));
    };

    useOrgQuery({ onSuccess: onSuccessOrg });

    const handleOrgChange = (event) => {
        const orgId = event?.target?.value;
        const organization = organizations?.find((data)=> data?.id === event?.target?.value)
        formik.setFieldValue("organization", orgId);
        formik.setFieldValue("orgName", organization?.name)
    };
    const handleCategoryChange = (event) => {
        const value = event?.target?.value;
        formik.setFieldValue("category", value);
    };

    const handleStatusChange = (event) => {
        const value = event?.target?.checked;
        formik.setFieldValue("status", value ? "new" : "inactive");
    }
   
    const formik = useFormik({
        ...FormikObj,
        onSubmit: async(values) => {  
                setBtnLoading(true);
                let base64Document
                if (values?.file) {
                  base64Document = await handleFileUpload(values?.file);
                  base64Document = base64Document.split(',')[1]  
                }
                addDocument({ ...values, orgId: userRoles?.includes("Super-Admin") ?  values?.organization : adminOrgs?.[0]?.id, orgName: userRoles?.includes("Super-Admin") ?  values?.orgName : adminOrgs?.[0]?.name, practitionerId: user?.["custom:unique_id"], base64Document: base64Document })
                    .then((res) => { 
                        if (res.status === true) {
                            navigate("/app/documents");
                            success(res.message);
                        } else {
                            failed(res.error);
                        }
                    })
                    .catch((res) => {
                        failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                    })
                    .finally(() => setBtnLoading(false));
        },
    });

    useEffect(() => {
      formik.setFieldValue("organization",adminOrgs?.[0]?.name)
    }, [])

    return (
      <section className="common-listing">
        <div className="heading-wrap mb-3">
          <Tooltip title="Go back">
            <div
              style={{ marginRight: "1rem", cursor: "pointer" }}
              onClick={() => navigate(-1)}
            >
              <ArrowBack />
            </div>
          </Tooltip>
        </div>
        <div className="custom-card p-4">
          <form
            className="common-form border-fields"
            onSubmit={formik.handleSubmit}
          >
            <Row>
                {userRoles?.includes("Super-Admin") ? (<Col>
                  <SelectFiled
                    keyField={"organization"}
                    label={"Select Organization"}
                    formik={formik}
                    options={organizations}
                    onChange={handleOrgChange}
                    />
                </Col>) : null}
              <Col>
                <TextInput
                  keyField={"name"}
                  label={"Document Name"}
                  formik={formik}
                  placeholder={"Document Name"}
                />
              </Col>
              <Col>
              <SelectFiled
                  keyField={"category"}
                  label={"Category"}
                  formik={formik}
                  options={categoryOptions}
                  onChange={handleCategoryChange}
                />
              </Col>
            </Row>
            <Row>
              <Col></Col>
              <Col></Col>
              <Col></Col>
              <Col></Col>
            </Row>
            <Row>
              <Col>
                <TextArea
                  keyField={"description"}
                  label={"Description"}
                  formik={formik}
                  placeholder={"Description..."}
                  required={false}
                />
              </Col>
              <Col>
                <InputFile
                  keyField={"file"}
                  label={"Upload Documents"}
                  formik={formik}
                  placeholder={"Upload Documents"}
                  required={true}
                  setFile={setFile}
                  file={file}
                  accept = "application/pdf"
                  imageUrl={userData?.current?.photo?.url}
                />
              </Col>
            </Row>
            <Row>
                <Col>
                    <label>Status</label> 
                    <Switch name="status" onClick={handleStatusChange} defaultChecked />
                </Col>
            </Row>
            <div className="btn-wrap" style={{ display: "flex" }}>
              <Button
                onClick={() => navigate("/app/documents")}
                variant="secondary"
                title="Cancel"
              >
                Cancel
              </Button>
              <Button type="submit" isLoading={btnLoading}>
                Save
              </Button>
            </div>
          </form>
        </div>
      </section>
    );
}

export default AddDocuments;
