import { useQuery } from "react-query";
import { axios } from "../../lib/axios";
import { onError } from "../ReactQueryHooks/onError";

export function useGetPatientById({ onSuccess, patientId }) {

    const fetchPatients = () => {
        return axios.get(`patient?patient_id=${patientId}`);
    };
    const query = useQuery(
        "patientsbyid",
        () => fetchPatients(),
        {
            select: (data) => data.result,
            onSuccess,
            onError
        }
    );

    return { ...query };
}

export function useGetPatientByUsers({ onSuccess, patientId, patientEmail }) {
    const fetchPatients = () => {
        return patientEmail ? axios.get(`users?id=${patientId}&email=${patientEmail}`) : axios.get(`users?id=${patientId}&type=${"Patient"}`);
    };
    const query = useQuery(
        ["patientsbyUsers", patientId],
        () => fetchPatients(),
        {
            select: (data) => data?.data,
            onSuccess,
            onError
        }
    );

    return { ...query };
}
