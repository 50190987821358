import React, { useState, useCallback } from "react";
import AsyncSelect from "react-select/async";
import { debounce } from "../../../hooks/useDebounce";
import { getRelatedPersonsList } from "../../api/Individual";
import { decryptData, encryptData } from "../../EncryptDecrypt";
import { failed } from "../Toastify";

const FilterSelect = ({ keyField, label, formik, required = false, id, placeholder = "Search...", }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [isSearching, setIsSearching] = useState(false);
    const getAsyncOptions = (id, inputText) => {
        const encrypted_inputText = encryptData(inputText.toLowerCase());
        return getRelatedPersonsList(id, encrypted_inputText)
            .then((res) => {
                setIsSearching(false);
                return res?.result?.map((user) => ({
                    label: decryptData(user?.name?.[0]?.text?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')),
                    value: user?.id,
                }));
            })
            .catch((res) => {
                failed(
                    res?.response?.data?.message ||
                    res?.response?.data?.error ||
                    res.message
                );
            });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadOptions = useCallback(
        debounce((inputText, callback) => {
            setIsSearching(true);
            getAsyncOptions(id, inputText).then((options) =>
                callback(options)
            );
        }, 1000),
        []
    );

    const onChange = (e) => {
        if(e){
            setSearchTerm(e);
            formik.setFieldValue(keyField, e.value);  
        } else if(!e){
            setSearchTerm("");
            formik.setFieldValue(keyField, "");  
        }
    };

    return (
        <div className="field-wrap">
            <label htmlFor={keyField}>
                {label}
                {required ? (
                    <span style={{ color: "red" }}> *</span>
                ) : (
                    " (Optional)"
                )}
            </label>

            <AsyncSelect
                cacheOptions
                placeholder={placeholder}
                value={searchTerm}
                onChange={onChange}
                loadOptions={loadOptions}
                defaultValue={[]}
                isClearable
                isLoading={isSearching}
                defaultOptions={[]}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                })}
                styles={{
                    valueContainer: (baseStyles, state) => ({
                        ...baseStyles,
                        height: '41px !important',
                        textTransform: "capitalize"
                    }),
                }}
            />
            <div>{formik?.touched[keyField] && formik?.errors[keyField] ? <div className="error-text">{formik?.errors[keyField]}</div> : null}</div>
        </div>
    );
};

export default FilterSelect;
