import React, { useEffect, useRef, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import TextInput from "../../common/textfield/TextInput";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../common/Button";
import { failed, success } from "../../common/Toastify";
import TextArea from "../../common/textfield/TextArea";
import SelectFiled from "../../common/textfield/SelectFiled";
import { Tooltip } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import moment from "moment";
import { DateSelectorWithoutFormik } from "../../common/textfield/DateSelector";
import { FormikObjectCondition, conditionStatusOptions } from "./Constant";
import { editCondition } from "../api/MedicalRequest";

const EditCondition = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [btnLoading, setBtnLoading] = useState(false);
    const { patientData, practionerData, conditionData } = location?.state

    const formik = useFormik({
        ...FormikObjectCondition,
        onSubmit: async(values) => {
                setBtnLoading(true);
                editCondition({ id: conditionData?.id, ...values, practitionerId: practionerData?.id, practitionerName: practionerData?.name?.[0]?.text || practionerData?.name })
                    .then((res) => {
                        if (res.status === true) {
                            navigate("/app/patients-details", {state:{id: patientData?.patientId, name: patientData?.patientName}});
                            success(res.message);
                        } else {
                            failed(res.error);
                        }
                    })
                    .catch((res) => {
                        failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                    })
                    .finally(() => setBtnLoading(false));
        },
    });

    const handleDateChange = (val) => {
        const isValidDate = moment(val, 'MMM-DD-YYYY').isValid()
        if(isValidDate){
          formik.setFieldValue("diagnosisDate", val.format("YYYY-MM-DD"));
        }else{
          formik.setFieldError('diagnosisDate', 'Invalid date format')
        }
    }

    const handleConditionStatusChange = (event) => {
      formik.setFieldValue("status", event?.target?.value);
    }

    useEffect(() => {
        localStorage.setItem("activeTableKey", "ninth");
        formik.setFieldValue("conditionName", conditionData?.conditionName)
        formik.setFieldValue("status", conditionData?.clinicalStatus?.coding?.[0]?.display)
        // formik.setFieldValue("note",  conditionData?.note ? conditionData?.note?.[0]?.text : "")
        formik.setFieldValue("diagnosisDate", conditionData?.recordedDate)
    }, [])
    

  return (
    <section className="common-listing">
      <div className="heading-wrap mb-3">
        <Tooltip title="Go back">
          <div
            style={{ marginRight: "1rem", cursor: "pointer" }}
            onClick={() => navigate(-1)}
          >
            <ArrowBack />
          </div>
        </Tooltip>
      </div>
      <div className="custom-card p-4">
        <form
          className="common-form border-fields"
          onSubmit={formik.handleSubmit}
        >
          <Row>
            <Col>
              <TextInput
                keyField={"conditionName"}
                label={"Condition"}
                formik={formik}
                placeholder={"Name"}
                disabled={true}
              />
            </Col>
            <Col>
              <DateSelectorWithoutFormik
                formik={formik}
                keyField={'diagnosisDate'}
                label="Diagnosis Date"
                value={moment(formik?.values?.diagnosisDate)}
                handleChange={handleDateChange}
                maxDate={moment()}
              />
            </Col>
            <Col>
              <SelectFiled
                keyField={"status"}
                label={"Status"}
                formik={formik}
                options={conditionStatusOptions}
                onChange={handleConditionStatusChange}
              />
            </Col>
          </Row>
          <Row>
            <Col></Col>
            <Col></Col>
          </Row>
          <div className="btn-wrap" style={{ display: "flex" }}>
            <Button
              onClick={() => !btnLoading && navigate("/app/patients-details", {state:{id: patientData?.patientId, name: patientData?.patientName}})}
              variant="secondary"
              title="Cancel"
              disabled={btnLoading}
            >
              Cancel
            </Button>
            <Button type="submit" isLoading={btnLoading}>
              Submit
            </Button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default EditCondition
