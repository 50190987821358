import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Button } from "../../common/Button";
import TimeSelector from "../../common/textfield/TimeSelector";
import { failed, success } from "../../common/Toastify";
import { createSchedule, getScheduleSlots, updateSchedule } from "../../api/Schedule";
import { DateSelectorWithoutFormik } from "../../common/textfield/DateSelector";
import { useFormik } from "formik";
import { daysBetweenDates, FormikObj, intialData, intialUnAvailabilityData } from "./constants";
import { useScheduleCategoryQuery } from "../../../hooks/ReactQueryHooks/useScheduleCategoryQuery";
import { UnAvailability } from "./UnAvailability";
import { useSelector } from "react-redux";
import { useGetPractScheduleByDate } from "../../../hooks/ReactQueryHooks/useGetPractScheduleByDate";
import { FullPageSpinner } from "../../common/Spinner/FullPageSpinner";
import { useLocation } from "react-router-dom";
import { decryptData } from "../../EncryptDecrypt";
import MultiSelect from "../../common/textfield/MultiSelect";

const CreateSchedule = () => {
    const [scheduleDaysCategory, setScheduleDaysCategory] = useState(JSON.parse(JSON.stringify(intialData)));
    const [unAvailability, setUnAvailability] = useState(intialUnAvailabilityData);
    const [locations, setLocations] = useState([]);
    const [recentSchedule, setRecentSchedule] = useState({});
    const [btnLoading, setBtnLoading] = useState(false);
    const [scheduleLoading, setScheduleLoading] = useState(false);
    const [disableStart, setDisableStart] = useState(false);
    const [disableEnd, setDisableEnd] = useState(false);
    const [isPast, setIsPast] = useState(false);
    const location = useLocation();
    const logedInUserData = useSelector((state) => state?.auth?.user);
    const userID = location?.state?.data ? location?.state?.data?.id : logedInUserData?.["custom:unique_id"];
    const userName = location?.state?.data ? decryptData(location?.state?.data?.name?.[0]?.text) : decryptData(logedInUserData?.name?.[0]?.text);
    
    
    useScheduleCategoryQuery({
        onSuccess: (data) => setLocations(data.map((item) => ({
            name: `${item?.name} (${item?.timeSlot?.value} min)`,
            value: item.id,
            id: item.id,
            timeSlot: item.timeSlot,
        })))
    })
    const { refetch } = useGetPractScheduleByDate({
        onSuccess: (data) => {
            data?.data && location?.pathname === "/app/edit-schedule" && currentRecentSchedule(data?.data)
        },
        practId: userID,
        date: moment(location?.state?.date).format("YYYY-MM-DD"),
    })
    const currentRecentSchedule = (data) => {
        data = (Array.isArray(data)) ? data[0] : data;
        setRecentSchedule(data)       
        formik.setFieldValue("start", moment(data.planningHorizon.start));
        formik.setFieldValue("end", moment(data.planningHorizon.end));
        formik.setFieldValue("startTime", moment(data.planningHorizon.start));
        formik.setFieldValue("endTime", moment(data.planningHorizon.end));

        setDisableStart(moment().isAfter(moment(data.planningHorizon.start)))
        setDisableEnd(moment().isAfter(moment(data.planningHorizon.end) || !formik.values.start))
        setIsPast(moment().isAfter(moment(data.planningHorizon.start)) && moment().isAfter(moment(data.planningHorizon.end)));
        
        setScheduleLoading(true)
        const categoryUpdate = JSON.parse(JSON.stringify(intialData))
        data?.serviceCategory?.map((categoryData) => {
            const day = moment(categoryData?.date).format("dddd")
            categoryUpdate?.[day]?.push(categoryData?.id)
            return categoryData
        })
        setScheduleDaysCategory(categoryUpdate)
        getScheduleSlots(data?.id)
            .then(slots => {
                const schedule = {}
                slots?.data.map((slot) => {
                    schedule[slot?.id] = {
                        date: moment(slot?.start),
                        from: moment(slot?.start),
                        to: moment(slot?.end),
                        status: slot?.status,
                        serviceCategory: slot?.serviceCategory?.[0] || null
                    }
                    return slot
                })
                setScheduleLoading(false)
                setUnAvailability(schedule);
                formik.setFieldError("start", null);
                formik.setFieldError("end", null);
                formik.setFieldError("startTime", null);
                formik.setFieldError("endTime", null);
            })
            .catch((err) => { console.log(err); })
    }

    const handleCategoryChange = ({ day, val }) => {
        setScheduleDaysCategory({
            ...scheduleDaysCategory,
            [day]: val,
        });
    };

    const formik = useFormik({
        ...FormikObj,
        onSubmit: (values) => {
            if(moment(values?.start).isAfter(moment(values?.end))) {
                // this should never occur unless it is free text entry.
                formik.setFieldError("start", "Start date must be before end date");
                return false;
            }
            if(moment(values?.end).isBefore(moment(values?.start).add(6,"d"))) {
                // make a minimum window of 7 days
                formik.setFieldError("end", "End date must be at least 7 days after start date");
                return false;
            }
            if((moment(values?.startTime).hour() > moment(values?.endTime).hour())
                || (moment(values?.startTime).hour() == moment(values?.endTime).hour() && moment(values?.startTime).minute() > moment(values?.endTime).minute())) {
                // this should never occur unless it is free text entry.
                formik.setFieldError("startTime", "Start time must be before end time");
                return false;
            }
            const serviceCategory = []
            const daysInSchedule = daysBetweenDates(values?.start, values?.end)
            daysInSchedule.forEach(date => {
                const day = moment(date).utc().format('dddd');
                if(scheduleDaysCategory?.[day].length === 0) {
                    return day
                }
                else {
                    [...new Set(scheduleDaysCategory?.[day])].map((key) => {
                        const locationData = locations.filter((location) => location?.id === key)[0]
                        serviceCategory.push(
                            {
                                "id": locationData?.id,
                                "name": locationData?.name,
                                "timeSlot": locationData?.timeSlot,
                                "date": date.utc().format("YYYY-MM-DDTHH:mm:ss"),
                            }
                        )
                        return key
                    })
                    return day;
                }
            })
            
            const planningHorizon = {
                "start": moment(values?.start?.format("DD-MMM-YYYY") + ' ' + values?.startTime?.utc().format("HH:mm:00")).format("YYYY-MM-DDTHH:mm:ss[Z]"),
                "end": moment(values?.end?.format("DD-MMM-YYYY") + ' ' + values?.endTime?.utc().format("HH:mm:00")).format("YYYY-MM-DDTHH:mm:ss[Z]")
            }
            if (recentSchedule?.id) {
                setBtnLoading(true);
                updateSchedule({ scheduleId: recentSchedule?.id, planningHorizon, serviceCategory, practId: userID, name: userName })
                    .then((res) => { success(res.message); refetch() })
                    .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                    .finally(() => setBtnLoading(false));
            } else {
                setBtnLoading(true);
                createSchedule({ planningHorizon, serviceCategory, practId: userID, name: userName })
                    .then((res) => { success(res.message); refetch() })
                    .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                    .finally(() => setBtnLoading(false));
            }
        },
    });

    const handleDateRangeChange = (fieldName, val) => {
        if(val == null || val == "") {
            formik.setFieldValue(fieldName, val || "");
            if(fieldName == "start") setDisableEnd(true);
        }
        else {            
            let isValidDate = moment(val, 'MMM-DD-YYYY').isValid();
            if(isValidDate) {
                formik.setFieldValue(fieldName, val);
                formik.setFieldError(fieldName, null);
            }
            else {
                formik.setFieldError(fieldName, "Invalid date format");
            }
        }
    }

    return (
        <section className="common-listing">
            {scheduleLoading ? <FullPageSpinner loadingText={"Loading schedule"} /> : null}
            <div className="heading-wrap h-change">
                <h1>{location?.state?.data
                    ? <>Schedule of <span style={{ textTransform: 'capitalize' }}>{userName}</span></>
                    : ""}</h1>
            </div>
            <div className="custom-card p-4">
                <form className="common-form border-fields" onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col md={3}>
                        <DateSelectorWithoutFormik disabled={disableStart || isPast} formik={formik} keyField={'start'} type="readonly" handleChange={(val) => handleDateRangeChange("start", val)} label={"Start Date"} value={formik.values.start} minDate={moment()} maxDate={false} isError={(formik?.touched?.start && formik?.errors?.start) ? formik?.errors?.start : ""} />
                        </Col>
                        <Col md={3}>
                        <DateSelectorWithoutFormik disabled={disableEnd || !formik.values.start || isPast} formik={formik} keyField={'end'} type="readonly" handleChange={(val) => handleDateRangeChange("end", val)} label={"End Date"} value={formik.values.end} minDate={moment(formik.values.start).add(6, 'days')} isError={(formik?.touched?.end && formik?.errors?.end) ? formik?.errors?.end : ""} />
                        </Col>
                        <Col md={3}>
                            <TimeSelector
                                minutesStep={5}
                                label={"Start Time"}
                                disabled={isPast}
                                value={formik.values.startTime}
                                onChange={(val) => handleDateRangeChange("startTime", val)}
                                error={(formik?.touched?.startTime && formik?.errors?.startTime) ? formik?.errors?.startTime : ""}
                            />
                        </Col>
                        <Col md={3}>
                            <TimeSelector
                                minutesStep={5}
                                label={"End Time"}
                                disabled={isPast}
                                value={formik.values.endTime}
                                minTime={formik.values.startTime}
                                onChange={(val) => handleDateRangeChange("endTime", val)}
                                error={(formik?.touched?.endTime && formik?.errors?.endTime) ? formik?.errors?.endTime : ""}
                            />
                        </Col>
                    </Row>

                    <div>
                        {Object.keys(scheduleDaysCategory).map((day, index) => (
                            <Accordion key={index}>
                                <AccordionSummary expandIcon={<ExpandMore />} sx={{ fontWeight: "600" }}>{day}</AccordionSummary>
                                <AccordionDetails>
                                    {locations.length > 0 ? <MultiSelect disabled={isPast} isSelectAll={scheduleDaysCategory?.[day].length > 0 && scheduleDaysCategory?.[day].length == locations.length} required={false} defaultValue={location?.pathname === "/app/edit-schedule" ? scheduleDaysCategory?.[day] : []} isSelectAllEnable={true} options={locations} keyField={""} formik={formik} label={"Appointment Types"} bindMethod={(val) => handleCategoryChange({ day, val })} /> : null}
                                </AccordionDetails>
                            </Accordion>
                        ))}
                        <Button type="submit" isLoading={btnLoading} disabled={isPast} style={{ marginTop: "2rem" }}>
                            Save
                        </Button>
                    </div>
                </form>
                <UnAvailability scheduleCategory={locations} currentDate={location?.state?.date} startDate={formik.values.start} endDate={formik.values.end} scheduleId={recentSchedule?.id} unAvailability={unAvailability} setUnAvailability={setUnAvailability} refetch={refetch} />
            </div>
        </section>
    );
};

export default CreateSchedule;