import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ApexChart from 'react-apexcharts';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { encryptData } from '../../EncryptDecrypt';
import { DateSelectorWithoutFormik } from '../../common/textfield/DateSelector';
import { getProgram } from '../../api/Program';
import { useGetPatientById } from '../../../hooks/ReactQueryHooks/useGetPatientById';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import EditProgram from "../../Admin/Program/EditProgram.js";
import ChartRPM from './chart.js';
import {Tooltip } from "@mui/material";

const DiseaseChart = ({ startDate, endDate, setConditionName, patientId, chartData, handleStartDate, handleEndDate, updateBpChartData, updateWtChartData, updateSugarChartData, updateHeartRateChartData, updateOxygenChartData, updateTempChartData, disease}) => {
    const [btnActive, setBtnActive] = useState("blood_pressure");
    const [headerActiveButton, setHeaderActiveButton] = useState("rpm")
    const [currentProgram, setCurrentProgram ] = useState([]);
    const [rpm, setRpm] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const [healthChart, setHealthChart] = useState(false);

    const { data: patientData } = useGetPatientById({
        patientId,
        onSuccess: (data) => {
          if (data?.program && data?.program?.length > 0){
            const currentProgramData = data?.program;
            const programDetails = getProgram(currentProgramData?.id)
            .then(res => {
                setCurrentProgram(res?.result?.condition);
                setRpm(res?.result)
          });
        }
      },
    });

    const handleProgramShow = () => {
        modalShow ? setModalShow(false) : setModalShow(rpm)
    }

    const handleChartShow = () => {
      healthChart ? setHealthChart(false) : setHealthChart(chartData);
    }
    
    const handleChartButtonClick = (condition, updateFunction) => {
        setConditionName(condition);
        updateFunction({ conditionName: encryptData(condition), patientId, startDate, endDate });
        setBtnActive(condition);
    }

    const handleWidget = (careMode) => {
      setHeaderActiveButton(careMode);
    }

      return (
        <div className="chart-wrap">
          {modalShow ?
                <EditProgram
                    modalShow={modalShow}
                    handleShow={handleProgramShow}
                    isReadOnly={true}
                /> : null}
          {healthChart ? <ChartRPM modalShow= {healthChart} handleShow={handleChartShow} isReadOnly={true}/>: null}
          <div className="chart-head">
            <ButtonGroup  aria-label="Basic example" style={{width:'100%'}}>
              <Button
                variant="secondary"
                active={headerActiveButton === "vv"}
                id="vv"
               onClick = {() => handleWidget("vv")}
               >Virtual Visit</Button>
              <Button 
               variant="secondary"
               active={headerActiveButton === "rpm"}
               id="rpm"
               onClick = {() => handleWidget("rpm")}
               >RPM</Button>
            </ButtonGroup>
            <Container style={{color:"rgba(0, 0, 0, 0.58)", padding:"10px 0px"}}>
              <Row>
                <Col >
                  <div>Program:{
                    <span 
                      onClick={() => handleProgramShow()}
                      style={{ textDecoration: "underline", cursor: "pointer", color: "#0064a6", fontWeight: "100" }}>
                      {rpm?.title}
                    </span>}</div>
                </Col>
              </Row>
              <Row>
              <Col>
                  <div>Health Condition: <span style={{color:"#121f48"}}>{disease?.diseaseName}</span></div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div>Symptoms: <span style={{color:"#121f48"}}>{disease?.diseaseDesc}</span></div>
                </Col>
              </Row>

            </Container>
            <ButtonGroup  aria-label="Basic example" style={{width:'100%', marginRight:"0px"}}>
            {/* this only shows the tabs and chart data that pertain to the RPM program the patient is enrolled in based on conditions */}
            {((currentProgram?.length > 0 && currentProgram?.find(condition => condition?.name?.toLowerCase().includes("pressure"))) || currentProgram?.length === 0) && (
                <Button
                  
                  variant="secondary"
                  active={btnActive === "blood_pressure"}
                  id="blood_pressure"
                  onClick={() => handleChartButtonClick('blood_pressure', updateBpChartData)}
                >
                  Blood Pressure
                </Button>
              )}
              {((currentProgram?.length > 0 && currentProgram?.find(condition => condition?.name?.toLowerCase().includes("glucose")))  || currentProgram?.length === 0) && (
                <Button
                  variant="secondary"
                  active={btnActive === "blood_glucose"}
                  id="blood_glucose"
                  onClick={() => handleChartButtonClick('blood_glucose', updateSugarChartData)}
                >
                  Blood Glucose
                </Button>
              )}
              {((currentProgram?.length > 0 && currentProgram?.find(condition => condition?.name?.toLowerCase().includes("weight")))  || currentProgram?.length === 0) && (
                <Button
                  variant="secondary"
                  active={btnActive === "weight"}
                  id="weight"
                  onClick={() => handleChartButtonClick('weight', updateWtChartData)}
                >
                  Weight
                </Button>
              )}
              {((currentProgram?.length > 0 && currentProgram?.find(condition => condition?.name?.toLowerCase().includes("heart", "rate")))  || currentProgram?.length === 0) && (
                <Button
                  variant="secondary"
                  active={btnActive === "heart_rate"}
                  id="heart_rate"
                  onClick={() => handleChartButtonClick('heart_rate', updateHeartRateChartData)}
                >
                  Heart Rate
                </Button>
              )}
              {((currentProgram?.length > 0 && currentProgram?.find(condition => condition?.name?.toLowerCase().includes("ox")))  || currentProgram?.length === 0) && (
                <Button
                  variant="secondary"
                  active={btnActive === "oxygen"}
                  id="oxygen"
                  onClick={() => handleChartButtonClick('oxygen', updateOxygenChartData)}
                >
                  Oxygen
                </Button>
              )}
              {((currentProgram?.length > 0 && currentProgram?.find(condition => condition?.name?.toLowerCase().includes("temp")))  || currentProgram?.length === 0) && (
                <Button
                  variant="secondary"
                  active={btnActive === "temperature"}
                  id="temperature"
                  onClick={() => handleChartButtonClick('temperature', updateTempChartData)}
                >
                  Temperature
                </Button>
              )}
            </ButtonGroup>
          </div>
          
          <div className="chart-body">
            <div className='chat-date-blc' style={{marginBottom: "0px"}}>
              <Row>
                <Col  md={{span:4}} className='diseaseDatePicker'>
                  <DateSelectorWithoutFormik
                    label={false}
                    maxDate={new Date()}
                    value={startDate}
                    handleChange={handleStartDate}
                    placeholder={"From"}
                    required={false}
                    type={'readonly'}
                    componentName="diseaseChartDate"
                  />
                </Col>
                <Col md={{span:4}} className='diseaseDatePicker'>
                  <DateSelectorWithoutFormik
                    label={false}
                    minDate={startDate}
                    maxDate={new Date()}
                    value={endDate}
                    handleChange={handleEndDate}
                    placeholder={"To"}
                    required={false}
                    type={'readonly'}
                    componentName="diseaseChartDate"
                  />
                </Col>
                <Col md={{span:4}} style={{display:"flex" ,justifyContent: "flex-end"}}>
                    
                    <div style={{display: "flex"}}><h3>Health Chart 
                    <Tooltip title = "Enlarge">
                      <OpenInFullIcon  onClick = {() => handleChartShow()}style={{height:"20px", width:"px", paddingBottom:"2px", cursor: "pointer", paddingLeft:"5px"}}/>
                    </Tooltip>
                    </h3> </div>
                </Col>
              </Row>
            </div>
            
            <div id="chart">
              <ApexChart id="mychart" options={chartData.options} series={chartData.series} type="line" width="100%" height="150px" />
            </div>
          </div>
        </div>
      )
};

export default DiseaseChart;