import { useQuery } from "react-query";
import { axios } from "../../lib/axios";
import { onError } from "./onError"

export function useOrgQuery({ onSuccess, orgId = null, ...props }) {
    const fetchOrganizations = () => {
        return axios.get(orgId ? `organization/${orgId}` : "organization");
    };
    
    const query = useQuery(
        "organizations",
        () => fetchOrganizations(),
        {
            select: (data) => data.data,
            onSuccess,
            onError,
            ...props
        }
    );

    return { ...query };
}
