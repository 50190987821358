import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import TextArea from "../../common/textfield/TextArea";
import { useFormik } from "formik";
import { Col, Row } from "react-bootstrap";
import { Button } from "../../common/Button";
import { FormikObjectNote } from "./Constant";
import { editCondition, editFamilyHistory, editObservation, editSurgeryScreening } from "../api/MedicalRequest";
import { failed, success } from "../../common/Toastify";
import { useSelector } from "react-redux";

const AddNote = ({ modalShow, handleShow, refetchCondition, refetchSurgery, refetchfamilyHistory, refetchObservation, handleNewNoteData }) => {
    const [btnLoading, setBtnLoading] = useState(false);
    const user = useSelector((state) => state?.auth?.user);

    const formik = useFormik({
      ...FormikObjectNote,
      onSubmit: async (values) => {
        setBtnLoading(true);
        if (modalShow?.resourceType === "Condition") {
          editCondition({
            id: modalShow?.id,
            ...values,
            newNote: true,
            practitionerId: user?.["custom:unique_id"],
            practitionerName: user?.name?.[0]?.text,
          })
            .then(async (res) => {
              if (res.status === true) {
                handleShow(false);
                const newData = await refetchCondition();
                handleNewNoteData(
                  newData?.data?.find((obj) => obj?.id === modalShow?.id)
                );
                success("Note added successfully");
              } else {
                handleShow(false);
                failed(res.error);
              }
            })
            .catch((res) => {
              failed(
                res?.response?.data?.message ||
                  res?.response?.data?.error ||
                  res.message
              );
            })
            .finally(() => setBtnLoading(false));
        } else if (modalShow?.resourceType === "Procedure") {
          editSurgeryScreening({
            id: modalShow?.id,
            ...values,
            newNote: true,
            practitionerId: user?.["custom:unique_id"],
            practitionerName: user?.name?.[0]?.text,
          })
            .then(async (res) => {
              if (res.status === true) {
                handleShow(false);
                success("Note added successfully");
                const newData = await refetchSurgery();
                handleNewNoteData(
                  newData?.data?.find((obj) => obj?.id === modalShow?.id)
                );
              } else {
                handleShow(false);
                failed(res.error);
              }
            })
            .catch((res) => {
              failed(
                res?.response?.data?.message ||
                  res?.response?.data?.error ||
                  res.message
              );
            })
            .finally(() => setBtnLoading(false));
        } else if (modalShow?.resourceType === "FamilyMemberHistory") {
          editFamilyHistory({
            id: modalShow?.id,
            ...values,
            newNote: true,
            practitionerId: user?.["custom:unique_id"],
            practitionerName: user?.name?.[0]?.text,
          })
            .then(async (res) => {
              if (res.status === true) {
                handleShow(false);
                success("Note added successfully");
                const newData = await refetchfamilyHistory();
                handleNewNoteData(
                  newData?.data?.find((obj) => obj?.id === modalShow?.id)
                );
              } else {
                handleShow(false);
                failed(res.error);
              }
            })
            .catch((res) => {
              failed(
                res?.response?.data?.message ||
                  res?.response?.data?.error ||
                  res.message
              );
            })
            .finally(() => setBtnLoading(false));
        } else if (modalShow?.resourceType === "Observation") {
          editObservation({
            id: modalShow?.id,
            ...values,
            newNote: true,
            practitionerId: user?.["custom:unique_id"],
            practitionerName: user?.name?.[0]?.text,
          })
            .then(async (res) => {
              if (res.status === true) {
                handleShow(false);
                success("Note added successfully");
                const newData = await refetchObservation();
                handleNewNoteData(
                  newData?.data?.find((obj) => obj?.id === modalShow?.id)
                );
              } else {
                handleShow(false);
                failed(res.error);
              }
            })
            .catch((res) => {
              failed(
                res?.response?.data?.message ||
                  res?.response?.data?.error ||
                  res.message
              );
            })
            .finally(() => setBtnLoading(false));
        }
      },
    });

    return (
        <Modal
            backdropClassName
            backdrop={'static'}
            show={modalShow} onHide={handleShow} size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered className="custom-dialog">
            <Modal.Header closeButton className="border-0">
              <Modal.Title id="contained-modal-title-vcenter">
                <span style={{color: "grey", fontSize: "smaller"}}>{modalShow?.resourceType === "Condition" ? "Condition" : modalShow?.resourceType === "Procedure" ? "Procedure" : modalShow?.resourceType === "FamilyMemberHistory" ? "Family History" : "Observation"}&gt;</span><span style={{color: "#0064a6", fontSize: "smaller"}}>{modalShow?.conditionName || modalShow?.procedureName || modalShow?.condition?.[0]?.name || modalShow?.observationName}</span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="common-form border-fields" onSubmit={formik.handleSubmit}>
                        <Row>
                            <Col>
                                <TextArea keyField={"note"} label={"Note"} formik={formik} placeholder={"Note..."} required={true} />
                            </Col>
                        </Row>
                        <div className="btn-wrap">
                            <Button
                                onClick={() => handleShow()}
                                variant="secondary"
                                title="Cancel"
                            >
                                Cancel
                            </Button>
                            <Button type="submit" isLoading={btnLoading}>
                                Submit
                            </Button>
                        </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}
export default AddNote
