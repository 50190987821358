import React, { useState } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as Yup from "yup";
import { useFormik } from "formik";
import TextInput from "../common/textfield/TextInput";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../common/Button";
import { failed, success } from "../common/Toastify";
import { verifyAndSetPass } from "../api/AuthService";
import Lock from '../../assets/images/Lock.png';
import { useQueryParams } from "../../hooks/useQueryParams";

function SetPassword() {
    const navigate = useNavigate();
    const { state } = useLocation()
    let queryParams = useQueryParams();
    const username = state?.username || queryParams.get('email')
    const [btnLoading, setBtnLoading] = useState(false)
    const formik = useFormik({
        initialValues: {
            verificationCode: "",
            password: state?.password || "",
            newPassword: "",
            confirmPass: "",
        },
        validationSchema: Yup.object({
            verificationCode: Yup.number().typeError('you must specify a number').required('Please Enter your Verification Code'),
            newPassword: Yup.string()
                .required('Please Enter your password')
                .matches(
                    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
                ),
            confirmPass: Yup.string().required("Required confirm password field").oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
        }),
        onSubmit: (values) => {
            setBtnLoading(true)
            verifyAndSetPass({ ...values, username }).then(({ data, response }) => {
                if(data?.status === false) {
                    failed(data?.message || data?.error)
                } else {
                    if (data?.status === true) {
                    success(data?.message);
                    navigate("/");
                    }else if(response?.status == 401){
                        failed(response?.data?.message);
                    }else{
                        failed(data?.error);
                    } 
                }
            }).catch(res => {
                failed(res?.response?.data?.message || res?.response?.data?.message || res?.message);
            }).finally(() => setBtnLoading(false));
        },
    });

    return (
        <form className="custom-form" onSubmit={formik.handleSubmit}>
            <Row>
                <Col>
                    <TextInput style={{ background: "#F6F6F6", borderRadius: "10px", margin: "0", padding: "10px" }} keyField={"verificationCode"} label={"Verification Code"} formik={formik} placeholder={"999999"} />
                </Col>
            </Row>
            {state?.password ? null : <Row>
                <Col>
                    <TextInput type="password" icon={Lock} keyField={"password"} label={"Old Password"} formik={formik} placeholder={"Old Password"} />
                </Col>
            </Row>}
            <Row>
                <Col>
                    <TextInput type="password" icon={Lock} keyField={"newPassword"} label={"New Password"} formik={formik} placeholder={"New Password"} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <TextInput type="password" icon={Lock} keyField={"confirmPass"} label={"Confirm Password"} formik={formik} placeholder={"Confirm Password"} />
                </Col>
            </Row>
            <div className="btn-wrap" style={{ display: 'flex' }}>
                <Button type="submit" isLoading={btnLoading}>Submit</Button>
            </div>
        </form>
    );
}

export default SetPassword;