import * as Yup from "yup";

export const FormikObj = {
    initialValues: {
        name: "",
        organization: "",
        description: "",
        timeSlot: "",
        isRootLevel: false,
        public: true
    },
    validationSchema: Yup.object({
        name: Yup.string().required("Required name field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        organization: Yup.string().when("isRootLevel", (isRootLevel, schema) =>
            isRootLevel
                ? schema
                : Yup.string().required("Required organization field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.')),
        description: Yup.string().required("Required description field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        timeSlot: Yup.string().required("Required timeSlot field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        isRootLevel: Yup.boolean(),
        public: Yup.boolean()
    }),
}

export const timeSlot = [
    { value: "10", name: "10 minutes" },
    { value: "15", name: "15 minutes" },
    { value: "20", name: "20 minutes" },
    { value: "30", name: "30 minutes" },
    { value: "40", name: "40 minutes" },
    { value: "45", name: "45 minutes" },
    { value: "50", name: "50 minutes" },
    { value: "60", name: "60 minutes" },
    { value: "90", name: "90 minutes" },
];

export const ScheduleHeadCells = [
    {
        id: 'name',
        label: 'Name',
        type: 'text',
        nested: { key: "name" },
    },
    {
        id: 'description',
        label: 'Description',
        type: 'text',
        nested: { key: "description" },
    },
    {
        id: 'organizationName',
        label: 'Organization',
        type: 'text',
        nested: { key: "organizationName" },
    },
    {
        id: 'actions',
        label: 'Actions',
        type: 'text',
        style: { textAlign: "center" },
    },
];
export const ScheduleDataFields = [
    'name',
    'description',
    'organizationName',
    'actions'
];