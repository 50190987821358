import { useQuery } from "react-query";
import { axios } from "../../lib/axios";
import { onError } from "./onError"

export function useGetOrgVitalsUnits({ onSuccess = () => { }, orgId, }) {
    const fetchOrganizations = () => {
        return axios.get(`organization/${orgId}`);
    };

    const query = useQuery(
        ["appointment"],
        () => fetchOrganizations(),
        {
            select: (data) => data.data?.deviceUnit,
            onSuccess,
            onError,
            refetchInterval: 5000,
        }
    );

    return { ...query };
}
