import { useQuery } from "react-query";
import { axios } from "../../lib/axios";
import { onError } from "./onError";

export function useGetPdfDocumentsQuery({ orgId,onSuccess, }) {

    const fetch = () => {
       // return axios.get("/documentreference?status=all")   
    return axios.get(`/documentreference?status=new&orgId=${orgId}`)
    }

    const query = useQuery(
        "document-reference-list",
        fetch,
        {
            select: (data) => data.data,
            onSuccess,
            onError
        }
    );

    return { ...query };
}
