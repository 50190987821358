import * as Yup from "yup";

export const questionnaireForOptions = [
    // { value: "Service Request", name: "Service Request" },
    { value: "Video Visit", name: "Video Visit" },
    { value: "getcare-appointment", name: "Get Care - Appointment" },
    { value: "getcare-waitingroom", name: "Get Care - Waiting Room" },
    { value: "telephone-intake", name: "Intake - Inbound Call" },
];

export const categoryOptions = [
    { value: "Chief Complaint", name: "Chief Complaint" },
    { value: "Medical History", name: "Medical History" },
    { value: "Conditions", name: "Conditions" },
    { value: "Medications", name: "Medications" },
    { value: "Allergies", name: "Allergies" },
    { value: "Immunizations", name: "Immunizations" },
    { value: "Assessment", name: "Assessment" },
    { value: "Impression", name: "Impression" },
    { value: "Plan", name: "Plan" },
    { value: "Flags", name: "Flags" },
    { value: "Additional Notes", name: "Additional Notes" },
];

export const answerTypeOptions = [
    { value: "freeText", name: "Free Text" },
    { value: "dropDown", name: "Dropdown" },
    { value: "checkbox", name: "Checkbox" },
    { value: "radio", name: "Radio" },
]

export const intiallCategorySelectedQuestions = {
    "Chief Complaint": [],
    "Medical History": [],
    Conditions: [],
    Medications: [],
    Allergies: [],
    Immunizations: [],
    Assessment: [],
    Impression: [],
    Plan: [],
    Flags: [],
    "Additional Notes": [],
}

export const addRow = ({ formik}) => {
    const newRow = { id: Date.now(), text: "" };
    formik.setFieldValue("options", [...formik.values.options, newRow]);
};

export const removeRow = ({ index, formik}) => {
    const updatedOptions = formik.values.options.filter((_, i) => i !== index);
    formik.setFieldValue("options", updatedOptions);
};

export const handleFormChange = ({ e, formik }) => {
    if (e?.target?.id === "category") {
        const inputCategoryString = e.target.value;
        const parts = inputCategoryString.split('%');
    }
}

export const QuestionsFormikObj = {
    initialValues: {
        category: "",
        question:  "",
        answerType: "freeText",
        note: "",
        tag: "",
        options: [{
            id: Date.now(),
            text: "",
        }],
        required: false,        
    },
    validationSchema: Yup.object({
        category: Yup.string().required('Please fill the Category field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
       
        question: Yup.string().required('Please fill the Question field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.').max(100, "Must be 100 characters or less"),
        answerType: Yup.string().required('Please fill the Answer Type field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        note: Yup.string().when("answerType", (answerType, schema) => {
        if (answerType === "freeText") return schema.required('Please fill the Note field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.')
            return schema
        }),
        note: Yup.string().matches(/^$|^\S+.*/, 'Only blankspaces is not valid.').max(250, "Must be 250 characters or less"),
        required: Yup.boolean(),
        options: Yup.array().when("answerType", (answerType, schema) => {
            if (answerType !== "freeText") return schema.of(
                Yup.object().shape({
                    text: Yup.string().required('Please fill the answer field'),
                })
            )
            return schema
        }),
    }),
}

export const QuestionsFormikObjEdit = {
    initialValues: {
        category: "",
        question:  "",
        answerType: "freeText",
        note: "",
        tag: "",
        options: [{
            id: Date.now(),
            text: "",
        }],
        required: false,
    },
    validationSchema: Yup.object({
        category: Yup.string().required('Please fill the Category field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        question: Yup.string().required('Please fill the Question field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.').max(100, "Must be 100 characters or less"),
        answerType: Yup.string().required('Please fill the Answer Type field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        note: Yup.string().when("answerType", (answerType, schema) => {
            if (answerType === "freeText") return schema.required('Please fill the Note field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.')
            return schema
        }),
        required: Yup.boolean(),
        options: Yup.array().when("answerType", (answerType, schema) => {
            if (answerType !== "freeText") return schema.of(
                Yup.object().shape({
                    text: Yup.string().required('Please fill the answer field'),
                })
            )
            return schema
        }),
    }),
}

export const QuestionnaireFormikObj = {
    initialValues: {
        title: "",
        type: "questionnaire",
        required: false,
        isDefault: false,
        note: "note",
        purpose: "Video Visit",
        status:"active" // unknown active  draft
    },
    validationSchema: Yup.object({
        title: Yup.string().required('Please fill the Title field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.').max(50, "Must be 50 characters or less"),
        // keyString: Yup.string().required('Please fill the Key String field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        // questionnaireFor: Yup.string().required('Please select the Questionnaire For field'),
        type: Yup.string().required('Please fill the Type field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        //note: Yup.string().required('Please fill the Type field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        required: Yup.boolean(),
        isDefault: Yup.boolean(),
        // question: Yup.array().required('Select any one question'),
        // question: Yup.array().min(1, 'Select any one question'),
        //question: Yup.array()
        //    .required('Select at least one question')
        //    .min(1, 'Select at least one question'),
    }),

}

export const QuestionnaireFormikObjEdit = {
    initialValues: {
        title: "",
        type: "questionnaire",
        required: false,
        isDefault: false,
        note: "",
        purpose: "Video Visit",
    },
    validationSchema: Yup.object({
        title: Yup.string().required('Please fill the Title field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.').max(50, "Must be 50 characters or less"),
        // keyString: Yup.string().required('Please fill the Key String field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        //  questionnaireFor: Yup.string().required('Please select the Questionnaire For field'),
        type: Yup.string().required('Please fill the Type field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        //note: Yup.string().required('Please fill the Type field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
         required: Yup.boolean(),
         isDefault: Yup.boolean(),
        //question: Yup.array().min(1, 'Select any one question'),
    }),
}

export const iconStyle = {
    width: "35px",
    height: "35px",
    borderRadius: "10px",
    backgroundColor: "rgba(0, 93, 168, 0.12)",
    textAlign: "center",
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
}

export const QuestionCategoryFormikObj = {
    initialValues: {
        categoryName: "",
        categoryType: "",

    },
    validationSchema: Yup.object({
        categoryName: Yup.string().required('Please fill the Category Name field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.').max(50, "Must be 50 characters or less"),
        categoryType: Yup.string().required('Please select the Category Type field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),

    }),
}

export const EditQuestionCategoryFormikObj = {
    initialValues: {
        categoryName: "",
        categoryType: "",

    },
    validationSchema: Yup.object({
        categoryName: Yup.string().required('Please fill the Category Name field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.').max(50, "Must be 50 characters or less"),
        categoryType: Yup.string().required('Please select the Category Type field').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),

    }),
}

export const questionnaireForOptionsOrganization = [    
    { value: "getcare-appointment", name: "Get Care - Appointment" },
    { value: "getcare-waitingroom", name: "Get Care - Waiting Room" },
];

export const QuestionnaireFormikAssign = {
    initialValues: {
        questionnaireAptField: "",
        questionnaireWroomField: "",
        questionnaireVvisitField:[],
        questionnaireInboundCallField: "",
        type: "questionnaire",       
        purpose: "",       
    },
    validationSchema: Yup.object({
       //  questionnaire: Yup.string().required('Please select  field'),
       //  purpose: Yup.string().required('Please select field'),
    }),
}


// Inbound call
export const questionTagFreeTextOptions = [ 
    { value: "TELEPHONE", name: "Return Telephone Number" },
    { value: "CALLER", name: "Caller Name" },
     { value: "REASON", name: "Reason for Visit" },
   
   
];

export const questionTagRadioOptions = [    
    { value: "SELFCALLING", name: "Calling for yourself" },  
    { value: "CONSENT", name: "Patient Consent" },     
];

export const questionTagDropDownOptions = [    
    { value: "LOCATION", name: "Patient Location" },  
    { value: "RELATIONSHIP", name: "Relationship to patient" },  
];

// Getcare 
export const questionTagFreeTextOptionsGetCare = [ 
    { value: "TELEPHONE", name: "Return Telephone Number" },   
     { value: "REASON", name: "Reason for Visit" },
   
   
];

export const questionTagRadioOptionsGetCare = [ 
    { value: "CHANNEL", name: "Preferred Channel" },   
];

export const questionTagDropDownOptionsGetCare = [    
    { value: "LOCATION", name: "Patient Location" },    
];