import { axios } from "../../../lib/axios";

const getTypeVal = (permissions) => {
    if (permissions.some(e => e.type === 'Administrative') && permissions.some(e => e.type === 'Clinical')) return "Administrative_Clinical"
    else if (permissions.some(e => e.type === 'Administrative')) return "Administrative"
    else if (permissions.some(e => e.type === 'Clinical')) return "Clinical"
}
export const createRole = ({ permissions, organization, name, description }) => {
    const newData = {
        "resourceType": "CodeSystem",
        "system": "http://florizel.fonemed.com/CodeSystem/platform-roles",
        "code": name.replaceAll(' ', '').toLowerCase(),
        "display": name,
        "definition": description,
        organization: {
            "reference": `Organization/${organization?.id || "0"}`,
            "type": "Organization",
            "display": organization?.name || "isDefault",
        },
        permissions: permissions.map(({ id, module, type }) => ({ id, module, type })),
        type: getTypeVal(permissions),
    }
    return axios.post(`codeSystem`, newData)
};
export const editRole = ({ permissions, id, name, description, organization }) => {
    const newData = {
        id,
        "display": name,
        "definition": description,
        permissions: permissions.map(({ id, module, type }) => ({ id, module, type })),
        type: getTypeVal(permissions),
        organization: organization
            ? organization
            : {
                "reference": `Organization/0`,
                "type": "Organization",
                "display": "isDefault",
            },
    }
    return axios.put(`codeSystem`, newData)
};
