import React, { useEffect, useState } from "react";
import "../Styles/dashboard.css";
import { Link, useNavigate, } from "react-router-dom";
import { useSelector } from "react-redux";
import { ButtonGroup, Table, Button } from "react-bootstrap";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { decryptData, encryptData } from "../../EncryptDecrypt";
import { Skeleton, TableCell, Tooltip } from "@mui/material";
import CustomPagination from "../../common/components/Pagination/CustomPagination";
import SearchBar from "../../common/components/SearchBar";
import "./Style.css"
import { getDocumentDashboard } from "../../api/FillableDocument.js";
import EditIcon from "../../../assets/images/edit.png";
import ViewIcon from "../../../assets/images/view.png";
import moment from "moment";
import { AttachementModal } from "../../apps/AppointmentRequests/AttachementModal";
import { ViewDateTime } from "../../../utils/DateSupport";

function DocumentDashboard({dashboard}) {
    const [btnActive, setBtnActive] = useState(!dashboard ? 'new' : 'assigned');
    const [filterData, setFilterData] = useState([]);
    const [documentList, setDocumentList] = useState([]);
    const [sortBy, setSortBy] = useState("AscName");
    const [isLoading, setIsLoading] = useState(false);
    const [docData, setDocData] = useState(null)
    const [attachmentUrl, setAttachmentUrl] = useState('');
    const [showAttachmentModal, setShowAttachmentModal] = useState(false);
    const [assignedCount, setAssignedCount] = useState(0); 
    const [acknowledgedCount, setAcknowledgedCount] = useState(0); 
    const [completedCount, setCompletedCount] = useState(0); 
    const [cancelledCount, setCancelledCount] = useState(0);
    const [newCount, setNewCount] = useState(0);
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)
    const permissions = useSelector((state) => state?.auth?.user?.permissions);
    const user = useSelector((state) => state?.auth?.user)
    const [page, setPage] = useState(0);
    const navigate = useNavigate()
    const rowsPerPage = 10;
    const totalRows = filterData.length;

    const sortFilterData = (sort_by) => {
        setSortBy(sort_by)
        switch (sort_by) {
            case "AscDate":
                setFilterData(documentList?.sort((a, b) => (decryptData(a?.createdAt) > decryptData(b?.createdAt)) ? 1 : ((decryptData(b?.createdAt) > decryptData(a?.createdAt)) ? -1 : 0)))
                break;
            case "DscDate":
                setFilterData(documentList?.sort((a, b) => (decryptData(a?.createdAt) < decryptData(b?.createdAt)) ? 1 : ((decryptData(b?.createdAt) < decryptData(a?.createdAt)) ? -1 : 0)))
                break;
            case "AscSource":
                setFilterData(documentList?.sort((a, b) => (decryptData(a.author?.[0]?.display) > decryptData(b?.author?.[0]?.display)) ? 1 : ((decryptData(b?.author?.[0]?.display) > decryptData(a?.author?.[0]?.display)) ? -1 : 0)))
                break;
            case "DscSource":
                setFilterData(documentList?.sort((a, b) => (decryptData(a.author?.[0]?.display) < decryptData(b?.author?.[0]?.display)) ? 1 : ((decryptData(b?.author?.[0]?.display) < decryptData(a?.author?.[0]?.display)) ? -1 : 0)))
                break;
            case "AscCategory":
                  setFilterData(documentList?.sort((a, b) => (decryptData(a?.category?.[0]?.coding?.[0]?.display) > decryptData(b?.category?.[0]?.coding?.[0]?.display)) ? 1 : ((decryptData(b?.category?.[0]?.coding?.[0]?.display) > decryptData(a?.category?.[0]?.coding?.[0]?.display)) ? -1 : 0)))
                  break;
            case "DscCategory":
                  setFilterData(documentList?.sort((a, b) => (decryptData(a?.category?.[0]?.coding?.[0]?.display) < decryptData(b?.category?.[0]?.coding?.[0]?.display)) ? 1 : ((decryptData(b?.category?.[0]?.coding?.[0]?.display) < decryptData(a?.category?.[0]?.coding?.[0]?.display)) ? -1 : 0)))
                  break;
            case "AscUploadedBy":
                  setFilterData(documentList?.sort((a, b) => (decryptData(a?.submittedBy?.coding?.[0]?.code === "fax" ?  a?.submittedBy?.coding?.[0]?.display : a?.submittedBy?.name) > decryptData(b?.submittedBy?.coding?.[0]?.code === "fax" ?  b?.submittedBy?.coding?.[0]?.display : b?.submittedBy?.name)) ? 1 : ((decryptData(b?.submittedBy?.coding?.[0]?.code === "fax" ?  b?.submittedBy?.coding?.[0]?.display : b?.submittedBy?.name) > decryptData(a?.submittedBy?.coding?.[0]?.code === "fax" ?  a?.submittedBy?.coding?.[0]?.display : a?.submittedBy?.name)) ? -1 : 0)))
                  break;
            case "DscUploadedBy":
                  setFilterData(documentList?.sort((a, b) => (decryptData(a?.submittedBy?.coding?.[0]?.code === "fax" ?  a?.submittedBy?.coding?.[0]?.display : a?.submittedBy?.name) < decryptData(b?.submittedBy?.coding?.[0]?.code === "fax" ?  b?.submittedBy?.coding?.[0]?.display : b?.submittedBy?.name)) ? 1 : ((decryptData(b?.submittedBy?.coding?.[0]?.code === "fax" ?  b?.submittedBy?.coding?.[0]?.display : b?.submittedBy?.name) < decryptData(a?.submittedBy?.coding?.[0]?.code === "fax" ?  a?.submittedBy?.coding?.[0]?.display : a?.submittedBy?.name)) ? -1 : 0)))
                  break;
            case "AscPract":
                setFilterData(documentList?.sort((a, b) => {
                    const dateA = decryptData(a?.authenticator?.[0]?.display);
                    const dateB = decryptData(b?.authenticator?.[0]?.display);
                    if (!dateA && !dateB) return 0;
                    if (!dateA) return 1;
                    if (!dateB) return -1;
                    return dateA > dateB ? 1 : dateA < dateB ? -1 : 0;
                }));
                break;
            case "DscPract":
                setFilterData(documentList?.sort((a, b) => {
                    const dateA = decryptData(a?.authenticator?.[0]?.display);
                    const dateB = decryptData(b?.authenticator?.[0]?.display);
                    if (!dateA && !dateB) return 0;
                    if (!dateA) return 1;
                    if (!dateB) return -1;
                    return dateA < dateB ? 1 : dateA > dateB ? -1 : 0;
                }));
                break;
            case "AscPages":
                setFilterData(documentList?.sort((a, b) => {
                    const pagesA = parseInt(decryptData(a?.numPages), 10);
                    const pagesB = parseInt(decryptData(b?.numPages), 10);
                    if (isNaN(pagesA) && isNaN(pagesB)) return 0; // Both are undefined/null/non-numeric, treat as equal
                    if (isNaN(pagesA)) return 1; // A is undefined/null/non-numeric, treat as greater
                    if (isNaN(pagesB)) return -1; // B is undefined/null/non-numeric, treat as greater
                    return pagesA - pagesB; // Normal numerical comparison
                }));
                break;
            case "DscPages":
                setFilterData(documentList?.sort((a, b) => {
                    const pagesA = parseInt(decryptData(a?.numPages), 10);
                    const pagesB = parseInt(decryptData(b?.numPages), 10);
                    if (isNaN(pagesA) && isNaN(pagesB)) return 0; // Both are undefined/null/non-numeric, treat as equal
                    if (isNaN(pagesA)) return 1; // A is undefined/null/non-numeric, treat as greater
                    if (isNaN(pagesB)) return -1; // B is undefined/null/non-numeric, treat as greater
                    return pagesB - pagesA; // Reverse numerical comparison for descending
                }));
                break;
            case "AscPatient":
                setFilterData(documentList?.sort((a, b) => {
                    const dateA = decryptData(a?.subject?.display);
                    const dateB = decryptData(b?.subject?.display);
                    if (!dateA && !dateB) return 0;
                    if (!dateA) return 1;
                    if (!dateB) return -1;
                    return dateA > dateB ? 1 : dateA < dateB ? -1 : 0;
                }));
                break;
            case "DscPatient":
                setFilterData(documentList?.sort((a, b) => {
                    const dateA = decryptData(a?.subject?.display);
                    const dateB = decryptData(b?.subject?.display);
                    if (!dateA && !dateB) return 0;
                    if (!dateA) return 1;
                    if (!dateB) return -1;
                    return dateA < dateB ? 1 : dateA > dateB ? -1 : 0;
                }));
                break;
            default:
                setFilterData(filterData)
                break;
        }
    }

    const handleShowFile = async(data) => {
        setShowAttachmentModal(true)
        setAttachmentUrl(data?.content?.[0]?.attachment?.url)
        setDocData(data)
    }


    const handleBtnChange = (val) => {
        setBtnActive(val);
    }

    const getListOfDocumentDashboard = async (val) =>{
        setIsLoading(true)
        let list
        if(dashboard){
            list = await getDocumentDashboard({status : val, practitionerId: user?.["custom:unique_id"], orgId: adminOrgs?.[0]?.id })
        }else{
            list = await getDocumentDashboard({status : val, orgId: adminOrgs?.[0]?.id })
        }
        if(list.status === true){
            setDocumentList(list?.data);
        }
        setIsLoading(false)
    }

    const redirectToPatientDashboard = (data)=>{
        const baseUrl = window.location.origin;
        const url = `${baseUrl}/app/patients-details?patientId=${data?.id}&patientName=${data?.name}`;
        window.open(url, "_blank");
    }
    
    useEffect(() => {
        getListOfDocumentDashboard(btnActive)
    }, [btnActive])

    async function fetchAssignedList() {
        try {
            setIsLoading(true);
    
            const promises = [
                getDocumentDashboard({ status: "new", practitionerId: user?.["custom:unique_id"], orgId: adminOrgs?.[0]?.id }),
                getDocumentDashboard({ status: "assigned", practitionerId: user?.["custom:unique_id"], orgId: adminOrgs?.[0]?.id }),
                getDocumentDashboard({ status: "acknowledged", practitionerId: user?.["custom:unique_id"], orgId: adminOrgs?.[0]?.id }),
                getDocumentDashboard({ status: "completed", practitionerId: user?.["custom:unique_id"], orgId: adminOrgs?.[0]?.id }),
                getDocumentDashboard({ status: "cancelled", practitionerId: user?.["custom:unique_id"], orgId: adminOrgs?.[0]?.id })
            ];
    
            if (!dashboard) {
                promises[0] = getDocumentDashboard({ status: "new", orgId: adminOrgs?.[0]?.id });
                promises[1] = getDocumentDashboard({ status: "assigned", orgId: adminOrgs?.[0]?.id });
                promises[2] = getDocumentDashboard({ status: "acknowledged", orgId: adminOrgs?.[0]?.id });
                promises[3] = getDocumentDashboard({ status: "completed", orgId: adminOrgs?.[0]?.id });
                promises[4] = getDocumentDashboard({ status: "cancelled", orgId: adminOrgs?.[0]?.id });
            }
    
            const [newList, assignedList, acknowledgedData, completedList, cancelledList] = await Promise.all(promises);
    
            setNewCount((newList?.data).length);
            setAssignedCount((assignedList?.data).length);
            setAcknowledgedCount((acknowledgedData?.data).length);
            setCompletedCount((completedList?.data).length);
            setCancelledCount((cancelledList?.data).length);
    
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching assigned lists:', error);
            setIsLoading(false);
        }
    }
     useEffect(() => {
        fetchAssignedList();
    }, [])
    
    return (
        <>
                {showAttachmentModal ? <AttachementModal fromDocument={true} modalShow={showAttachmentModal} url={attachmentUrl} docData={docData} handleShow={() => setShowAttachmentModal(false)}/> : null}
                <section className="common-listing">
                    <div className="dash-buttons" >
                        <ButtonGroup>
                            {!dashboard && <Button
                                variant="secondary"
                                active={btnActive === "new"}
                                onClick = {() =>  handleBtnChange("new")} 
                            >New ({newCount})
                            </Button>}
                            <Button
                                variant="secondary"
                                active={btnActive === "assigned"}
                                onClick = {() =>  handleBtnChange("assigned")}
                                >Assigned ({assignedCount})
                            </Button>
                            <Button 
                                variant="secondary"
                                active={btnActive === "acknowledged"}
                                onClick = {() => handleBtnChange("acknowledged")}
                                >Acknowledged ({acknowledgedCount})
                            </Button>
                            <Button
                                variant="secondary"
                                active={btnActive === "completed"}
                                onClick = {() => handleBtnChange("completed")}
                                >Completed ({completedCount})
                            </Button>
                            <Button 
                                variant="secondary"
                                active={btnActive === "cancelled"}
                                onClick = {() => handleBtnChange("cancelled")}
                                >Cancelled  ({cancelledCount})
                            </Button>
                        </ButtonGroup>
                        
                        <div className="search-block">
                            <SearchBar
                                page={page}
                                setPage={setPage}
                                list={documentList}
                                filterData={(data) => setFilterData(data)}
                            />
                            {!dashboard && permissions?.includes("Upload Documents") && <Button
                                onClick={() => navigate("/app/upload-document", {state: {status : btnActive}})}
                                className="custom-btn ms-3"
                                title="Add Document"
                                style={{ width: "auto" }}
                                >
                                Upload
                            </Button>}
                        </div>
                    </div>
                    <div className="table-wrap">
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th style={{ cursor: "pointer" }} onClick={() => sortFilterData(sortBy === "AscDate" ? "DscDate" : "AscDate")}>
                                    Date/Time
                                    {sortBy === "AscDate" ? <span>
                                            <ArrowDropUp />
                                        </span> : <span>
                                            <ArrowDropDown />
                                        </span>}
                                    </th>
                                    <th style={{ cursor: "pointer" }} onClick={() => sortFilterData(sortBy === "AscSource" ? "DscSource" : "AscSource")}>
                                    Source
                                    {sortBy === "AscSource" ? <span>
                                            <ArrowDropUp />
                                        </span> : <span>
                                            <ArrowDropDown />
                                        </span>}
                                    </th>
                                    <th style={{ cursor: "pointer" }} onClick={() => sortFilterData(sortBy === "AscCategory" ? "DscCategory" : "AscCategory")}> 
                                        Category
                                        {sortBy === "AscCategory" ? <span>
                                            <ArrowDropUp />
                                        </span> : <span>
                                            <ArrowDropDown />
                                        </span>}
                                    </th>
                                    <th style={{ cursor: "pointer" }} onClick={() => sortFilterData(sortBy === "AscUploadedBy" ? "DscUploadedBy" : "AscUploadedBy")}> 
                                        Uploaded by
                                        {sortBy === "AscUploadedBy" ? <span>
                                            <ArrowDropUp />
                                        </span> : <span>
                                            <ArrowDropDown />
                                        </span>}
                                    </th>
                                    <th style={{ cursor: "pointer" }} onClick={() => sortFilterData(sortBy === "AscPages" ? "DscPages" : "AscPages")}>
                                        Pages
                                        {sortBy === "AscPages" ? <span>
                                            <ArrowDropUp />
                                        </span> : <span>
                                            <ArrowDropDown />
                                        </span>}
                                    </th>
                                    {!dashboard && <th style={{ cursor: "pointer" }} onClick={() => sortFilterData(sortBy === "AscPract" ? "DscPract" : "AscPract")}> 
                                        Practitioner
                                        {sortBy === "AscPract" ? <span>
                                            <ArrowDropUp />
                                        </span> : <span>
                                            <ArrowDropDown />
                                        </span>}
                                    </th>}
                                    <th style={{ cursor: "pointer" }} onClick={() => sortFilterData(sortBy === "AscPatient" ? "DscPatient" : "AscPatient")}> 
                                        Patient
                                        {sortBy === "AscPatient" ? <span>
                                            <ArrowDropUp />
                                        </span> : <span>
                                            <ArrowDropDown />
                                        </span>}
                                    </th>
                                    <th style={{ textAlign: "center"}}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ? (
                                    [1, 2, 3, 4, 5, 6, 7, 8]?.map((val) => (
                                        <tr key={val}>
                                            <td>
                                                <Skeleton animation="wave" />
                                            </td>
                                            <td>
                                                <Skeleton animation="wave" />
                                            </td>
                                            <td>
                                                <Skeleton animation="wave" />
                                            </td>
                                            <td>
                                                <Skeleton animation="wave" />
                                            </td>
                                            <td>
                                                <Skeleton animation="wave" />
                                            </td>
                                            {!dashboard && <td>
                                                <Skeleton animation="wave" />
                                            </td>}
                                            <td>
                                                <Skeleton animation="wave" />
                                            </td>
                                            <td>
                                                <Skeleton animation="wave" />
                                            </td>
                                        </tr>
                                    ))
                                ) : filterData?.length > 0 ? (
                                    filterData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((data) => {
                                        const {id, createdAt, author, category, numPages, authenticator, subject, submittedBy, status} = data;
                                        return (
                                        <tr key={id}>
                                            <td>{ViewDateTime(createdAt)}</td>
                                            <td>{author?.[0]?.display?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') || '-'}</td>
                                            <td>{category?.[0]?.coding?.[0]?.display || '-'}</td>
                                            <td>{submittedBy?.coding?.[0]?.code === "fax" ?  submittedBy?.coding?.[0]?.display : submittedBy?.name?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') || '-'}</td>
                                            <td>{numPages || '-'}</td>
                                            {!dashboard && <td>{authenticator?.[0]?.display?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') || '-'}</td>}
                                            <td><span style={ (subject?.display && subject?.reference?.split("/")?.[1]) ? { cursor: "pointer", textDecoration: "underline"} : null} onClick={()=> (subject?.display && subject?.reference?.split("/")?.[1]) ? redirectToPatientDashboard({ id: subject?.reference?.split("/")?.[1], name: encryptData(subject?.display) }) : null}>{subject?.display?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') || '-'}</span></td>
                                            <td>
                                            <div className="action-wrap" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                                <Tooltip title={"View"}>
                                                    <Link
                                                        to=""
                                                        variant="primary"
                                                        className="view-btn"
                                                        onClick={() =>  handleShowFile(data, false)}
                                                    >
                                                    <img src={ViewIcon} alt="View PDF" />
                                                    </Link>
                                                </Tooltip>
                                                <Tooltip title="Edit">
                                                <Link
                                                    to=""
                                                    className="success-btn"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        navigate("/app/edit-document", {state: {id, createdAt, author, category, numPages, authenticator, subject, submittedBy, status, fromDocumentDashboard: dashboard ? false : true}});
                                                      }}
                                                >
                                                    <img src={EditIcon} alt="Edit" />
                                                </Link>
                                                </Tooltip>
                                            </div>
                                            </td>
                                        </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <TableCell colSpan={"7"} style={{ textAlign: "center" }}>
                                            No Data Available
                                        </TableCell>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                    {filterData?.length > 0 ? <CustomPagination tableData={filterData} totalRows={totalRows} rowsPerPage={rowsPerPage} page={page} handlePage={(pageNo) => setPage(pageNo)} /> : null}
                </section>
        </>
    );
}

export default DocumentDashboard;