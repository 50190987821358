import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as Yup from "yup";
import { useFormik } from "formik";
import TextInput from "../common/textfield/TextInput";
import { useNavigate } from "react-router-dom";
import { Button } from "../common/Button";
import { failed, success } from "../common/Toastify";
import Lock from "../../assets/images/Lock.png";
import { changePassword } from "../api/Profile";

function ChangePassword() {
    const navigate = useNavigate();
    const [btnLoading, setBtnLoading] = useState(false);
    const formik = useFormik({
        initialValues: {
            password: "",
            confirmPass: "",
            currentPass: "",
        },
        validationSchema: Yup.object({
            currentPass: Yup.string().required("Please Enter your current password"),
            password: Yup.string()
                .required("Please Enter your password")
                .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"),
            confirmPass: Yup.string()
                .required("Required confirm password field")
                .oneOf([Yup.ref("password"), null], "Passwords must match"),
        }),
        onSubmit: (values) => {
            setBtnLoading(true);
            changePassword(values)
                .then((data) => {
                    success(data.message);
                    navigate("/");
                })
                .catch((res) => {
                    failed(res?.response?.data?.message || res?.response?.data?.message || res.message);
                })
                .finally(() => setBtnLoading(false));
        },
    });

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "60px" }}>
            <form className="custom-form" onSubmit={formik.handleSubmit} style={{ width: "100%", maxWidth: "350px" }}>
                <Row>
                    <Col>
                        <TextInput icon={Lock} keyField={"currentPass"} label={"Current Password"} formik={formik} placeholder={"Current Password"} type={"password"} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextInput icon={Lock} keyField={"password"} label={"New Password"} formik={formik} placeholder={"New Password"} type={"password"} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextInput icon={Lock} keyField={"confirmPass"} label={"Confirm Password"} formik={formik} placeholder={"Confirm Password"} type={"password"} />
                    </Col>
                </Row>
                <div className="btn-wrap" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Button type="submit" isLoading={btnLoading}>
                        Submit
                    </Button>
                </div>
            </form>
        </div>
    );
}

export default ChangePassword;
