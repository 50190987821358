import { useQuery } from "react-query";
import { axios } from "../../lib/axios";
import { onError } from "./onError"

export function useScheduleCategoryQuery({ onSuccess }) {

    const fetch = () => {
        return axios.get("serviceCategory");
    };
    const query = useQuery(
        "scheduleCategory",
        () => fetch(),
        {
            select: (data) => data?.data ,
            onSuccess,
            onError
        }
    );

    return { ...query };
}
