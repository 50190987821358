import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarIcon from '@mui/icons-material/Star';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Skeleton, TableCell, Tooltip, Rating, Icon } from "@mui/material";
import starUnchecked from '../../../assets/images/starUnchecked.png';
import starChecked from '../../../assets/images/starChecked.png';

const iconStyle = {
    width: "35px",
    height: "35px",
    borderRadius: "10px",
    backgroundColor: "rgba(0, 93, 168, 0.12)",
    textAlign: "center",
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
}

export function ViewSurveyResult({ modalShow, handleShow, refreshTable }) {

    return (
        <Modal
            size="lg"
            show={modalShow}
            onHide={handleShow}
            aria-labelledby="contained-modal-title-vcenter"
            backdropClassName
            backdrop={'static'}
            centered className="custom-dialog">
            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">
                    Patient Survey #{modalShow.id}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="custom-card p-4">
                    <h5>{modalShow.patientName?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</h5>
                    <div className="table-wrap">
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Question </th>
                                    <th>Answered</th>
                                </tr>
                            </thead>
                            <tbody>
                                {modalShow ? (
                                    modalShow?.item?.map((val) => (
                                        <tr key={val.linkId}>

                                            <td>
                                                {val.text}
                                            </td>
                                            <td>
                                                {(val.questionType == 'freeText') ? val.answer[0].valueString :

                                                    <Rating
                                                        name="rating"
                                                        value={val.answer[0].valueString}                                                      
                                                        precision={1} 
                                                        max={5} 
                                                        style={{ color: 'blue' }} 
                                                        readOnly
                                                        icon={<img src={starChecked} alt="" height="30px" />}
                                                    />
                                                }
                                            </td>
                                        </tr>
                                    ))
                                ) : null
                                    
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ViewSurveyResult;
