import React, { useContext, useEffect, useState, } from "react";
import "./sidebar.css";
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import DashIcon from '../../../assets/images/space_dashboard.png';
import PersonIcon from '../../../assets/images/person-white.png';
import RightArrow from '../../../assets/images/down-arrow.png';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { adminMenu, appsMenu } from "./constant";
import ZoomContext from "../../../ContextsAndProvider/Zoom-Context";
import { useGetOrgLicensedProducts } from "../../../hooks/ReactQueryHooks/useGetOrgLicensedProducts";
import { FullPageSpinner } from "../Spinner/FullPageSpinner";
import AppIcon from "../../../assets/images/apps_icon.png"; 
import { getOrganization } from "../../api/Organization";
import { failed } from "../Toastify";

function Sidebar() {
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location?.pathname;
    const permissions = useSelector((state) => state?.auth?.user?.permissions);
    const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
    const adminOrgs =  useSelector((state) => state?.auth?.user?.organizations)
    const admin = userRoles?.includes('Super-Admin');
    const practitioner = userRoles?.includes('Practitioner');
    const hasAdminPermissions = permissions?.includes('Manage Organization') || permissions?.includes('Manage Individuals') || permissions?.includes('Manage Roles') || permissions?.includes('Manage Program') || permissions?.includes('Manage  Schedule Category') || permissions?.includes('Manage Clinic Locations') || permissions?.includes('Manage Notifications');
    const hasClinicalPermissions = permissions?.includes('Patients Assigned') || permissions?.includes('Manage Threshold Groups') || permissions?.includes("Appointment") || permissions?.includes('Waiting Room Patients') || permissions?.includes('Waiting Room Enrollment') || permissions?.includes("Manage Provider Schedule & Appointments");
    const zmClient = useContext(ZoomContext)
    const { isLoading, data: orgLicensedProducts } = useGetOrgLicensedProducts({ refetchInterval: 300000 })
    const isRPMEnable = admin ? true : orgLicensedProducts?.RPM
    const isVirtualCareEnable = admin ? true : orgLicensedProducts?.virtualCare
    const disabled_RPM_VirtualCare = admin ? false : !orgLicensedProducts?.RPM && !orgLicensedProducts?.virtualCare
    const [isWaitingRoom, setIsWaitingRoom] = useState(null)

    useEffect(() => {
        if ((pathname !== "/app/zoom-meeting-create" || pathname !== "/app/zoom-meeting-join") && zmClient?.getSessionInfo()?.isInMeeting) {
            zmClient.leave().then(() => {
                localStorage.removeItem("userJoined")
            }).catch((err) => console.log(err))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])
    

    useEffect(() => {
        getOrganization(adminOrgs?.[0]?.id)
        .then((res) => {
            setIsWaitingRoom(res?.data?.isWaitingRoom)
    })
     .catch((res) => {
        failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
    });
    }, [])
    
    const onLinkClick = (e, URL) => {
        e.preventDefault();
        e.ctrlKey ? window.open(URL, '_blank') : navigate(URL);
    }
    return (
        <>
        {isLoading ? <FullPageSpinner /> : null}
        <section className="sidebar-wrap">
            <Nav className="flex-md-column sidebar-inner">
                {!disabled_RPM_VirtualCare && hasClinicalPermissions ? <Nav.Item>
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-disabled">Apps</Tooltip>}>
                                <div className="icon-wrap">
                                    <img src={AppIcon} alt="Apps" />
                                </div>
                            </OverlayTrigger>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {/* <p>Apps</p>
                            <Dropdown.ItemText>RPM</Dropdown.ItemText> */}
                            {appsMenu(permissions, isRPMEnable)?.map(({ name, URL, enabled, children }, index) => (
                                <div key={index}>
                                    {enabled ? (
                                        !children
                                        ? <Dropdown.Item onClick={(e) => onLinkClick(e, URL)} href={URL} className={`dropdown-item ${pathname === URL ? "active" : ""} `}>{name}</Dropdown.Item>
                                        : <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic" title="Dashboard">
                                                {name}
                                                <img className="arrow" src={RightArrow} alt="" />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {children?.map(({ name, URL, enabled }, index) => (
                                                    enabled && (
                                                    <Dropdown.Item onClick={(e) => onLinkClick(e, URL)} href={URL} key={index} className={`dropdown-item ${pathname === URL ? "active" : ""} `}>{name}</Dropdown.Item>
                                                    )
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    ) : null}
                                </div>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </Nav.Item> : null}

                {hasAdminPermissions || admin ? <Nav.Item>
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-disabled">Admin</Tooltip>}>
                                <div className="icon-wrap">
                                    <img src={PersonIcon} alt="Admin" />
                                </div>
                            </OverlayTrigger>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="submenu">
                            {/* <p>Admin</p>
                            <Dropdown.ItemText>RPM</Dropdown.ItemText> */}
                            {adminMenu(permissions, admin, isRPMEnable, isVirtualCareEnable, disabled_RPM_VirtualCare, isWaitingRoom)?.map(({ name, URL, enabled, children }, index) => (
                                <div key={index}>
                                    {enabled ? (
                                        !children
                                            ? <Dropdown.Item onClick={(e) => onLinkClick(e, URL)} href={URL} className={`dropdown-item ${pathname === URL ? "active" : ""}`}>{name}</Dropdown.Item>
                                            : <Dropdown>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic" title="Dashboard">
                                                    {name}
                                                    <img className="arrow" src={RightArrow} alt="" />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {children?.map(({ name, URL, enabled }, index) => (
                                                        enabled && (
                                                        <Dropdown.Item onClick={(e) => onLinkClick(e, URL)} href={URL} key={index} className={`dropdown-item ${pathname === URL ? "active" : ""}`}>{name}</Dropdown.Item>
                                                        )
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                    ) : null}
                                    
                                </div>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </Nav.Item> : null}

                {(permissions?.includes('Manage Individuals') && permissions?.includes('Manage Organization')) || admin || practitioner ? <Nav.Item>
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-disabled">My Dashboard</Tooltip>}>
                                <div className="icon-wrap">
                                    <img src={DashIcon} alt="Dashboard" />
                                </div>
                            </OverlayTrigger>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item  onClick={(e) => onLinkClick(e, "/app/dashboard")} className={`dropdown-item`}>My Dashboard</Dropdown.Item>
                            { isVirtualCareEnable && (permissions?.includes('Access Document Dashboard') && practitioner) && <Dropdown.Item  onClick={(e) => onLinkClick(e, "/app/document-dashboard")} className={`dropdown-item`}>Document Dashboard</Dropdown.Item> }
                        </Dropdown.Menu>
                    </Dropdown>
                </Nav.Item> : null}
            </Nav>
        </section >
        </>
    );
}

export default Sidebar;     