import { axios } from "../../../../lib/axios";

export const addThreshold = (apiBody) => {
    return  axios.post("threshold/add",apiBody)
}
export const addThresholdGroup = (apiBody) => {
    return  axios.post("threshold/create-group",apiBody)
}
export const updateThresholdGroup = (apiBody) => {
    return  axios.put("threshold/edit-group",apiBody)
}
export const addPatientToThreshold = (apiBody) => {
    return  axios.post("threshold/add-patients",apiBody)
}
//delete single threshold groupexport 
export const deleteThresholdGroup = (id) => {
    return axios.delete(`threshold/delete-group?id=${id}`)
}
export const deleteThresholdGroupPatient = (id,patientId) => {
    return axios.delete(`threshold/remove-patient?id=${id}&patientId=${patientId}`)
}
export const deleteThresholdPatient = (patientId,condition) => {
    return axios.delete(`threshold/delete?patientId=${patientId}&condition=${condition}`)
}