import * as Yup from "yup";

export const FormikObj = {
    initialValues: {
        state: "",
        country: "",
        authority: "",
        display: "",
        description: "",
    },
    validationSchema: Yup.object({
        authority: Yup.string().required("Required authority field").matches(/^\d+(\.\d+)*$/, "Invalid input. Only numbers and periods are allowed."),
        state: Yup.string().required("Required region field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        country: Yup.string().required("Required country field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        display: Yup.string().required("Required display field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        description: Yup.string().required("Required description field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
    }),
}

export const HcnHeadCells = [
    {
        id: 'display',
        label: 'Display',
        type: 'text',
        nested: { key: "display" },
    },
    {
        id: 'authority',
        label: 'OID',
        type: 'text',
        nested: { key: "authority" },
    },
    {
        id: 'description',
        label: 'Description',
        type: 'text',
        nested: { key: "description" },
    },
    {
        id: 'actions',
        label: 'Actions',
        type: 'text',
        style: { textAlign: "center" },
    },
];
export const HcnDataFields = [
    'description',
    'authority',
    'display',
    'actions'
];