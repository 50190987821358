import React, { useState, } from 'react'
import { AuthAppLayout } from '../../Routes/public';
import * as yup from 'yup';
import { useFormik } from 'formik';
import TextInput from '../common/textfield/TextInput';
import { Button } from '../common/Button';
import User from '../../assets/images/person.png';
import ZoomJoin from './ZoomJoin';

const ZoomJoinLandignPage = ({ topic, pass }) => {
    const [btnLoading, setBtnLoading] = useState(false)

    const formik = useFormik({
        initialValues: {
            username: "",
        },
        validationSchema: yup.object({
            username: yup.string().required('Username Required').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        }),
        onSubmit: (values) => {
            setBtnLoading(values.username);
        },
    });
    return (
        <>
            {!btnLoading ?
                <AuthAppLayout>
                    <form className="custom-form" onSubmit={formik.handleSubmit}>
                        <h4>Join Meeting</h4>
                        <TextInput icon={User} keyField={"username"} label={"User name"} formik={formik} placeholder={"User name"} />
                        <div className="btn-wrap" style={{ textAlign: "center" }}>
                            <Button type="submit" className="custom-btn" isLoading={btnLoading}>Join</Button>
                        </div>
                    </form>
                </AuthAppLayout>
                :
                <ZoomJoin topic={topic} pass={pass} navigateOnEndMeet={() => window.close()} userName={btnLoading} />
            }
        </>

    )
}

export default ZoomJoinLandignPage