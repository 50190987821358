import { useQuery } from "react-query";
import { axios } from "../../lib/axios";
import { onError } from "./onError"

export function useGetUsersByOrg({ onSuccess, orgId, type, ...props }) {

    const fetch = () => {
        return orgId
            ? type
                ? axios.get(`/users?orgId=${orgId}&type=${type}`)
                : axios.get(`/users?orgId=${orgId}`)
            : null
    };
    const query = useQuery(
        ["users", orgId],
        () => fetch(),
        {
            select: (data) => data?.data,
            onSuccess,
            onError,
            ...props
        }
    );

    return { ...query };
}
