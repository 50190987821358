import React from 'react'
import { Col, Row } from 'react-bootstrap'
import TextInput from '../../common/textfield/TextInput'
import SelectFiled from '../../common/textfield/SelectFiled'
import ReactDatePicker from '../../common/textfield/ReactDatePicker'
import CountrySelect from '../../common/textfield/CountrySelect'
import RegionSelect from '../../common/textfield/RegionSelect'

const Fields = ({ fields, formik, isReadOnly, handlePostalCodeChange, typeOfUser }) => {

    return (
        <>
            {fields?.map((row) => {
                return (
                    <Row>
                        {row?.map((col) => { 
                            switch (col.fieldType) {
                                case "TextInput":
                                    return (
                                        <Col xs lg={col?.size}>

                                        {isReadOnly ? 
                                        <TextInput
                                        style={{ textTransform: col?.keyField === "email" ? "none" : "capitalize" }}
                                       // type={col.type == undefined || col.type == 'phone'}
                                       type={ col.type == 'phone' || col.type }
                                        keyField={col.keyField}
                                        label={col.label}
                                        formik={formik}
                                        placeholder={col.placeholder || col.label}                                               
                                        required={col?.required}
                                        readOnly={isReadOnly}
                                    />
                                        
                                        : 

                                        (col.keyField !== "hcn" || (col.keyField === "hcn" && typeOfUser === "Patient")) ?
                                        <TextInput
                                        style={{ textTransform: col?.keyField === "email" ? "none" : "capitalize" }}                                      
                                        type={col.type }
                                        keyField={col.keyField}
                                        label={col.label}
                                        formik={formik}
                                        placeholder={col.placeholder || col.label}                                               
                                        required={col?.required}
                                        disabled={col?.disabled || false}
                                        hideRequired={col?.hideRequired || false}
                                        
                                        />: null
                                        }
                                        
                                        
                                            
                                        </Col>
                                    )
                                case "SelectFiled":
                                    return (
                                        <Col>
                                            <SelectFiled
                                                keyField={col.keyField}
                                                label={col.label}
                                                formik={formik}
                                                options={col?.options}
                                                readOnly={isReadOnly}
                                                required={col?.required}
                                                hideRequired = {col?.hideRequired}
                                            />
                                        </Col>
                                    )
                                case "ReactDatePicker":
                                    return (
                                      <Col>
                                        {isReadOnly ? (
                                          <TextInput
                                            keyField={col.keyField}
                                            label={col.label}
                                            formik={formik}
                                            placeholder={
                                              col.placeholder || col.label
                                            }
                                            hasValue={
                                              formik?.values?.[col?.hasValue]
                                            }
                                            displayAge={col?.displayAge}
                                            readOnly={isReadOnly}
                                          />
                                        ) : (
                                          <ReactDatePicker
                                            keyField={col.keyField}
                                            label={col.label}
                                            formik={formik}
                                            placeholder={
                                              col.placeholder || col.label
                                            }
                                            hasValue={
                                              formik?.values?.[col?.hasValue]
                                            }
                                            displayAge={formik.errors.date_of_birth ? false  : col?.displayAge}
                                            // readOnly={isReadOnly}
                                          />
                                        )}
                                      </Col>
                                    );
                                case "CountrySelect":
                                    return (
                                        <Col xs lg={col?.size}>
                                            <CountrySelect
                                                keyField={col.keyField}
                                                label={col.label}
                                                formik={formik}
                                                readOnly={isReadOnly}
                                            />
                                        </Col>
                                    )
                                case "RegionSelect":
                                    return (
                                        <Col  xs lg={col?.size}>
                                            <RegionSelect
                                                defaultOptionLabel={
                                                    formik?.values?.country === "US"
                                                        ? "Select State"
                                                        : "Select Province"
                                                }
                                                keyField={col.keyField}
                                                label={
                                                    formik?.values?.country === "US" ? "State" : "Province"
                                                }
                                                formik={formik}
                                                readOnly={isReadOnly}
                                            />
                                        </Col>
                                    )
                                case "postalCode":
                                    return (
                                        <Col xs lg={col?.size}>
                                            <TextInput
                                                onChange={handlePostalCodeChange}
                                                keyField={"postalCode"}
                                                label={
                                                    formik?.values?.country === "US"
                                                        ? "Zip Code"
                                                        : "Postal Code"
                                                }
                                                formik={formik}
                                                placeholder={
                                                    formik?.values?.country === "US"
                                                        ? "Zip Code"
                                                        : "Postal Code"
                                                }
                                                readOnly={isReadOnly}
                                                // max length for both zip code and postal code(plus space)
                                                maxLength={formik?.values?.country === "US" ? "5" : "7"}
                                            />
                                        </Col>
                                    )
                                default:
                                    return (<Col></Col>)
                            }
                        })}
                    </Row>
                )
            })}
        </>
    )
}

export default Fields