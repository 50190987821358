import clsx from 'clsx';
import * as React from 'react';
import { Spinner } from 'react-bootstrap';

const variants = {
    primary: '',
    secondary: ' white-btn',
};


export const Button = React.forwardRef(
    (
        {
            type = 'button',
            className = '',
            variant = 'primary',
            size = 'md',
            isLoading = false,
            startIcon,
            endIcon,
            disabled,
            style,
            ...props
        },
        ref
    ) => {
        return (
            <button
                ref={ref}
                type={type}
                className={clsx(
                    'custom-btn',
                    variants[variant],
                    className
                )}
                disabled={isLoading || disabled}
                {...props}
                style={{ width: "fit-content", ...style }}
            >
                {isLoading && <Spinner style={{ background: "transparent", width: "1rem", height: "1rem" }} size="sm" className="text-current" />}
                {!isLoading && startIcon}
                {props.children ? <span className="mx-2">{props.children}</span> : null} {!isLoading && endIcon}
            </button >
        );
    }
);