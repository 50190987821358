import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import Background from "../../../assets/images/search-icon.png"

const Search = ({ list = [], filterData, encoded = false, placeholder = "Search Patient..." }) => {
    const [searchVal, setSearchVal] = useState("");
    const filter = () => {
        const data = list?.filter((data) => (data?.subject.display?.toLowerCase()?.includes(searchVal)))
        filterData(data);
    }
    useEffect(() => {
        filter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchVal, list])
    return (
        <Form className="search-wrap">
            <Form.Control
                type="search"
                placeholder={placeholder}
                className="me-2"
                aria-label="Search"
                style={{ backgroundImage: searchVal ? "none" : `url(${Background})` }}
                value={searchVal}
                onChange={(e) => setSearchVal(e.target.value.toLowerCase())}
            />
        </Form>
    )
}

export default Search