import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { EncounterHistoryTitle, downloadBtnText, noDataAvailText } from "../Constants";
import moment from "moment";
import { Skeleton, TableCell, Tooltip } from "@mui/material";
import Tab from "react-bootstrap/Tab";
import { DateSelectorWithoutFormik } from "../../../common/textfield/DateSelector";
import { usePatientEncounterHistoryQuery } from "../../../../hooks/ReactQueryHooks/usePatientEncounterHistoryQuery";
import CustomPagination from "../../../common/components/Pagination/CustomPagination";
import { decryptData, encryptData } from "../../../EncryptDecrypt";
import { NoteModal } from "./NoteModal";
import { useAppointmentsRequestsQuery } from "../../../../hooks/ReactQueryHooks/useAppointmentsRequestsQuery";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FilePresentOutlined } from "@mui/icons-material";
import ViewIcon from "../../../../assets/images/view.png";
import { ViewAppointmentModal } from "../../AppointmentRequests/ViewAppointmentModal";
import RefreshIcon from '@mui/icons-material/Refresh';
import { failed, success } from "../../../common/Toastify";
import ServiceLocationModal from "../../ServiceRequest/ServiceLocationModal";
import { getPatientData } from "../../../api/Individual";
import viewApptIcon from "../../../../assets/images/Tick 1.svg"
import  RescheduleAppointment  from '../../ManageProviderSchedule&Appointments/Reschedule';
import { getSignature } from "../../../Zoom/functions";
import { createEncounter, updateAppointmentRequest } from "../../api/AppointmentRequests";
import { generatePass } from "../../AppointmentRequests/Constants";
import { axios } from "../../../../lib/axios";
import { ViewDateTime } from "../../../../utils/DateSupport";



export function EncounterHistoryList({ patientId, practionerData, orgnization }) {
    const [encounterStartDate, setEncounterStartDate] = useState(moment().subtract(0.6, 'years').format("YYYY-MM-DD"));
    const [encounterEndDate, setEncounterEndDate] = useState(moment().format("YYYY-MM-DD"));
    const [encounterData, setEncounterData] = useState([]);
    const [showNoteModal, setShowNoteModal] = useState(false);
    const [page, setPage] = useState(0);
    const rowsPerPage = 5;
    const [appointmentsData, setAppointmentsData] = useState([]);
    const [filterApptData, seFilterApptData] = useState([]);
    const [showAppointment, setShowAppointment] = useState(false);
    const [scheduleModal, setScheduleModal] = useState(false);
    const [serviceLocationModal, setserviceLocationModal] = useState(false);
    const [patientData, setPatientData] = useState([]);
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations) 
    const user = useSelector((state) => state?.auth?.user);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    const onSuccess = (data) => {       
        setEncounterData(data);
    };
    let practitioner = user?.["custom:unique_id"];
    if (encounterData !== undefined) {
        if (encounterData.length > 0) {
            practitioner = encounterData[0]?.participant?.[0]?.individual?.reference?.split(
                "/"
            )?.[1];
        }
    }

    const { isLoading, isFetching, } = usePatientEncounterHistoryQuery({ onSuccess, startDate: encounterStartDate, endDate: encounterEndDate, patientId: patientId, refetchOnWindowFocus: false });


    function handleAppointmentsSuccess(data) { 
        setAppointmentsData(data);
    }
    function getAppointmentDataId(id) { 
        return axios.get(`appointment/${id}`);
    }    
    useAppointmentsRequestsQuery({

        onSuccess: handleAppointmentsSuccess,
        practitionerId: practitioner,
        status: "all",
        refetchOnWindowFocus: false,
        error: false

    });
    const handleAppointmentClick = (id) => { 
        const data = appointmentsData.filter(appointment => appointment.id == id);        
        seFilterApptData(data);
        setShowAppointment(id);
    };

    const handleAppointmentClickApt = (id) => { 
        getAppointmentDataId(id)
            .then(response => {                
                 seFilterApptData([response.data]);
                 setShowAppointment(id);
            })
            .catch(error => {
                console.error('Error fetching appointment data', error);
            });
    };

    const showServiceLocationModal = (id) => {
        setserviceLocationModal({id : id ,encounter: {"reference": `Encounter/${id}`} , resourceType: "Encounter"});
    };
    
    useEffect(() => {
        getPatientData(patientId)
            .then((res) => {
                setPatientData(res?.result)
            })
    }, [])

    const handleUpdateRequest = async (data, status) => {
        setLoading(true)
        const patientId = data?.participant?.filter((data) => data?.actor?.type === "Patient")?.[0]?.actor?.reference?.split("/")?.[1];
        const practitionerId = data?.participant?.filter((data) => data?.actor?.type === "Practitioner")?.[0]?.actor?.reference?.split("/")?.[1];
        let sessionToken;
        if (status === "booked") {                  
            let topic = user?.name[0]?.text?.toLowerCase() + 'fonemed';                
            let password= generatePass();
            await getSignature({ topic: topic, role: 0,  password:password})
                .then(async (res) => {
                    sessionToken = res.data.sessionToken;
                }).catch((error) => console.log("getsignature error", { error }))
        }
        await updateAppointmentRequest({ id: data?.id, status, patientId, practitionerId, sessionToken })
            .then((res) => { success(status === "booked" ? "Appointment booked!" : "Appointment cancelled!");})
            .catch((err) => failed(err?.response?.data?.message || err?.response?.data?.error || err?.message))
            .finally(() => setLoading(false));
        setShowAppointment(false);
    };

    const handleConnect = async (data) => {
        if(data?.sessionToken){
        setLoading(true)
        const base64Url = await data?.sessionToken?.split(".")[1],
            base64 = await base64Url?.replace(/-/g, "+")?.replace(/_/g, "/"),
            buff = await Buffer.from(base64, "base64"),
            payloadinit = await buff.toString("ascii"),
            parseddata = JSON.parse(payloadinit || "{}"),
            patient = data?.participant.filter((data) => data?.actor?.type === "Patient")?.[0]?.actor,
            practitioner = data?.participant.filter((data) => data?.actor?.type === "Practitioner")?.[0]?.actor;        
            let actor = {
                display: practitioner.display,
                reference: practitioner.reference
            }            
            let encounterId = data?.encounterId ? data?.encounterId : null;
            if(!data?.encounterId){
                await createEncounter({ patient, practitioner, appointmentId: data?.id, appointmentTime: data?.requestedPeriod?.[0]?.start, orgId: user?.organizations?.[0]?.id  })
                    .then((res) => {
                        encounterId = res?.data?.id
                        updateAppointmentRequest({ isHostJoined: true, sessionToken: data?.sessionToken, encounterId: res?.data?.id, id: data?.id, status: "booked", patientId: patient?.reference?.split("/")?.[1], practitionerId: practitioner?.reference?.split("/")?.[1] })
                            .then((res) => res)
                            .catch((err) => failed(err?.response?.data?.message || err?.response?.data?.error || err?.message))
                            .finally(() => setLoading(false));
                    })
                    .catch((error) => console.log("encounter error", { error }))
                    .finally(() => setLoading(false))  
            }else{
                updateAppointmentRequest({ isHostJoined: true, sessionToken: data?.sessionToken, encounterId: data?.encounterId, id: data?.id, status: "booked", patientId: patient?.reference?.split("/")?.[1], practitionerId: practitioner?.reference?.split("/")?.[1] })
                .then(async(res) => {
                    const requestedPeriod = [{start: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"), end: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]")}] 
                    await axios.put(`encounter/${res?.result?.encounterId}`, { status: "in-progress-appointment", requestedPeriod: requestedPeriod, actor: actor }) 
                })
                .catch((err) => failed(err?.response?.data?.message || err?.response?.data?.error || err?.message))
                .finally(() => setLoading(false));
            }  
            let topic=parseddata?.tpc.trim();
        await getSignature({ topic: topic, role: 1, password: parseddata?.password })
            .then(async (res) => {
                await navigate("/app/zoom-meeting-create", { state: { encounterId, appointmentData: data, topic: encryptData(parseddata?.tpc), sessionToken: res?.data?.sessionToken, password: encryptData(parseddata?.password)} });
                await success("connected successfully");
            }).catch((error) => console.log("getsignature error", { error }))
            .finally(() => setLoading(false))
        }else{ failed("Session token does not exist!") }
    }

    const handleCall = async(data) =>{
            const patient = await data?.participant.filter((data) => data?.actor?.type === "Patient")?.[0]?.actor
            const practitioner = await data?.participant.filter((data) => data?.actor?.type === "Practitioner")?.[0]?.actor;           

            const encounterId = data?.encounterId ? data?.encounterId : null;
            // navigate("/app/phone-call-create", { state: {practitionerName: practitioner?.display, practitionerId: practitioner?.reference?.split("/")?.[1], patientId: patient?.reference?.split("/")?.[1], patientName: patient?.display, encounterId, appointmentData: data, currentUser: {patient}, questionnairInfo: data?.questionnaireResponse } });
            navigate("/app/phone-call-create", { state: {practitionerName: practitioner?.display, practitionerId: practitioner?.reference?.split("/")?.[1], patientId: patient?.reference?.split("/")?.[1], patientName: patient?.display, encounterId, appointmentData: data, currentUser: {patient}, questionnairInfo: data?.intakeQuestions } });
    }

    return (
        <Tab.Pane eventKey="fifth">
            {showNoteModal ? <NoteModal modalShow={showNoteModal} handleShow={() => setShowNoteModal(false)} /> : null}
            {scheduleModal ? <RescheduleAppointment modalShow={scheduleModal} handleShow={() => setScheduleModal(false)}  /> : null}
            {showAppointment ? <ViewAppointmentModal modalShow={showAppointment} setScheduleModal = {setScheduleModal} handleShow={() => setShowAppointment(false)} handleConnect={handleConnect} handleCall={handleCall} handleUpdateRequest={handleUpdateRequest} appointmentsList={filterApptData} /> : null}
            {serviceLocationModal ? <ServiceLocationModal
                onHide={() => setserviceLocationModal(false)}
                modalShow={serviceLocationModal}
                paramsObj={{patientId, fromListing: true}}
                practitionerData={user}
                requestType={"Encounter Report"}
                categoryVar={"Encounter Report"}
                patientData={patientData}
                orgName={adminOrgs?.[0]?.name}
                fromEncounter={true}
            /> : null}
            <div className="mnth-date-wrap">
                <DateSelectorWithoutFormik
                    label={"Start Date"}
                    minDate={""}
                    maxDate={moment(encounterEndDate)}
                    value={moment(encounterStartDate)}
                    handleChange={(val) => setEncounterStartDate(val.format("MM/DD/YYYY"))}
                    type={'readonly'}
                />
                <DateSelectorWithoutFormik
                    label={"End Date"}
                    minDate={moment(encounterStartDate).add(1, 'days')}
                    maxDate={moment()}
                    value={moment(encounterEndDate)}
                    handleChange={(val) => setEncounterEndDate(val.format("MM/DD/YYYY"))}
                    type={'readonly'}
                />
            </div>
            <div className="table-wrap">
                <Table responsive>
                    <thead>
                        <tr>
                            {EncounterHistoryTitle?.map(title => <th key={title} style={{
                                textAlign: title === "Edit" ? "center" : "auto"
                            }}>{title}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading || isFetching
                            ? [1, 2, 3, 4, 5, 6].map(val => (
                                <tr key={val}>
                                    {EncounterHistoryTitle?.map(index => <td key={index}>
                                        <Skeleton animation="wave" />
                                    </td>)}
                                </tr>
                            ))
                            : encounterData?.length > 0
                                ? encounterData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map(({ id, period, participant, reason, documentDelivery}) => {
                                    let EncData = encounterData?.find((data) => data?.id == id);                                   
                                    const appointment = appointmentsData?.find((appointment) => appointment?.encounterId === id);
                                    const comment = appointment?.comment || "-";
                                    const description = appointment?.description || "-";
                                    const document = appointment?.document?.url;                                 
                                    return (
                                        <tr key={id}>
                                            <td>{ViewDateTime(period?.end)}</td>
                                            <td>{participant?.[participant.length - 1]?.individual?.display?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') || "" || "-"}</td>
                                            {/* <td>{description}</td> */}
                                            {/* <td onClick={() => handleAppointmentClick(appointment?.id)}>{appointment ? <img src={viewApptIcon} alt="View Appointment"/> : null} </td> */}                                           
                                            <td onClick={() => appointment?.id != undefined ? handleAppointmentClick(appointment?.id) :  handleAppointmentClickApt( EncData?.appointmentId )}>
                                                 { EncData?.appointmentId == undefined ? (        
                                                        ''
                                                    ) : (
                                                    <img src={viewApptIcon} alt="View Appointment"/> 
                                                )}
                                            </td>
                                            <td>
                                                <div className="action-wrap" style={{justifyContent: "start"}}>
                                                    <div onClick={() => navigate("/app/encounter-details", { state: { encounterId: id, patientId: patientId, practionerData, practitionerId: participant?.[0]?.individual?.reference?.split("/")?.[1], from: "pastEncounter" } })} style={{ cursor: "pointer" }}>
                                                    <Tooltip title={"View"}>
                                                        <Link
                                                            to=""
                                                            variant="primary"
                                                            className="view-btn"
                                                        >
                                                            <img src={ViewIcon} alt="View" />
                                                        </Link>
                                                    </Tooltip>
                                                    </div>
                                                    {documentDelivery?.some(item => item.type === "HOSP") && (
                                                        <Tooltip title={"Resend Document"}>
                                                            <Link
                                                                to=""
                                                                variant="primary"
                                                                className="view-btn"
                                                                onClick={() => orgnization?.sft?.enabled && (orgnization?.sft?.locationDelivery) ? showServiceLocationModal(id) : (orgnization?.sft?.enabled && (!orgnization?.sft?.locationDelivery)) ? failed("Please enable delivery to location to send document") : failed("Please enable SFT to send document.")}
                                                            >
                                                                <RefreshIcon style={{color : 'white'}}/>
                                                            </Link>
                                                        </Tooltip>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                                : <tr>
                                    <TableCell colSpan={EncounterHistoryTitle.length} style={{
                                        textAlign: "center"
                                    }}>
                                        {noDataAvailText}
                                    </TableCell>
                                </tr>}
                    </tbody>
                </Table>
                {
                    encounterData?.length > 0 ? <div>
                        <CustomPagination tableData={encounterData} totalRows={encounterData?.length} rowsPerPage={rowsPerPage} page={page} handlePage={pageNo => setPage(pageNo)} />
                    </div> : null
                }
            </div>
        </Tab.Pane>
    );
}
