import React from 'react';
import { RegionDropdown } from 'react-country-region-selector';

function RegionSelect({ defaultOptionLabel = "Select Region", country, region, setRegion, keyField, formik, label, required = true, disabled = false, readOnly = false, props }) {
    const handleChange = (val) => {
        //val = val === "Indian and Northen Affairs" ? "INA" : val
        setRegion && setRegion(val);
        formik.setFieldValue(keyField, val);
    };
    return (
        <div className="field-wrap">
            <label htmlFor={keyField}>
                {label}
                {required ? <span style={{ color: "red" }}> *</span> : " (Optional)"}
            </label>
            <RegionDropdown
                country={country || formik?.values?.["country"]}
                value={region || formik?.values?.[keyField]}
                onChange={(val) => handleChange(val)}
                countryValueType="short"
                valueType="short"
                disableWhenEmpty={true}
                defaultOptionLabel={defaultOptionLabel}
                disabled={disabled}
                id={keyField}
                blacklist={{ US: ["AA", "AE", "AP", "AS", "FM", "GU", "MH","MP","PW","PR","VI"] }}
                style={{ pointerEvents: readOnly ? "none" : "all" }}
            //customOptions={["Indian and Northen Affairs"]}
            />
            <div>{formik?.touched[keyField] && formik?.errors[keyField] ? <div className="error-text">{formik?.errors[keyField]}</div> : null}</div>
        </div>
    )
}

export default RegionSelect