import React from "react";
import GreenArrowIcon from "../../../assets/images/green-arrow.png";
import RedArrowIcon from "../../../assets/images/red-arrow.png";
import CircularProgress from '@mui/material/CircularProgress';

const DashboardCard = ({ title, count=false, icon, percentage=0, loading=false, selected }) => {
    return (
        <>
            {loading ? (
                <div className="box-wrap"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6vh",
                    }}
                >
                    <CircularProgress />
                </div>
            ) : (
                <div className={"box-wrap"} style={selected ? {color: '#005da8', background: "#e9f5ff"}:{}}>
                    <div className="top-wrap">
                        <div className="icon-wrap">
                            <img src={icon} alt={title} />
                        </div>
                        <div className="texts-wrap">
                            {title}
                            <span>{count}</span>
                        </div>
                    </div>
                    {/* <p>
                        <span
                            style={{
                                color:
                                    Math.sign(percentage) === 1
                                        ? "#06B155"
                                        : "#FF0000",
                            }}
                        >
                            {percentage}%
                        </span>
                        <img
                            src={
                                Math.sign(percentage) === 1
                                    ? GreenArrowIcon
                                    : RedArrowIcon
                            }
                            alt=""
                        />
                        Compared to last month
                    </p> */}
                </div>
            )}
        </>
    );
};

export default DashboardCard;
