import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "../../common/Button";
import SelectFiled from "../../common/textfield/SelectFiled";
import TextInput from "../../common/textfield/TextInput";
import TextArea from "../../common/textfield/TextArea";
import { addRow, answerTypeOptions, categoryOptions, handleFormChange, iconStyle, removeRow } from "./Constant";
import { Add, Remove } from "@mui/icons-material";
import { getQuestionCategory } from "../../api/Questionnaire";
import { fabClasses } from "@mui/material";
import { questionnaireForOptions, questionTagFreeTextOptions, questionTagRadioOptions, questionTagDropDownOptions, questionTagFreeTextOptionsGetCare, questionTagRadioOptionsGetCare, questionTagDropDownOptionsGetCare } from "./Constant";
import moment from "moment";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import { useSelector } from "react-redux";
import { deleteQuestion } from "../../api/Questionnaire";
import { failed, success } from "../../common/Toastify";
import DeleteIcon from "../../../assets/images/Tag.svg";
// import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from "react-router-dom";

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { ViewDate } from "../../../utils/DateSupport";

export function QuestionForm({ btnLoading, formik, handleShow, isCreate, handleQuestionTagValue, isReadOnly, refreshTable, modalShow, onIsModalTagChange }) {
    const [catOption1, setCatOption1] = useState();
    const [selectedCategoryType, setSelectedCategoryType] = useState();
    const [questionInput, setQuestionInput] = useState(formik.values.question || "");
    const [deleting, setDeleting] = useState(false);
    const userType = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
    const { getConfirmation } = useConfirmDialog();
    const [showTag, setShowTag] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [stackName, setStackName] = useState();
    const [questionTagValue, setQuestionTagValue] = useState(handleShow.length > 0 && stackName != undefined ? stackName : formik.values.tag);
    const [isVideoVisit, setIsVideoVisit] = useState(false);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const [isModalTag, setIsModalTag] = useState(false);

    useEffect(() => {
        getQuestionCategory().then((res) => {
            if (!isCreate) {
                const cat = res.data?.filter(item => item?.id === formik.values.category)[0];
                if (cat != undefined) {
                    formik.setFieldValue("category", `${cat.categoryName}%${cat.id}`);
                    setSelectedCategoryType(cat.categoryType);
                    setIsVideoVisit(cat.categoryType === "Video Visit");

                }
            }
            setCatOption1(res.data)
        }).catch((err) => console.log(err));
    }, []);

    const updateStackName = () => {
        if (handleShow.length > 0) {
            switch (modalShow?.tag) {
                case 'TELEPHONE':
                    setStackName('Return Telephone Number');
                    break;
                case 'CHANNEL':
                    setStackName('Preferred Channel');
                    break;
                case 'REASON':
                    setStackName('Reason for visit');
                    break;
                case 'LOCATION':
                    setStackName('Patient Location');
                    break;
                case 'RELATIONSHIP':
                    setStackName('Relationship to patient');
                    break;
                case 'SELFCALLING':
                    setStackName('Calling for yourself');
                    break;
                case 'CONSENT':
                    setStackName('Patient Consent');
                    break;
                case 'CALLER':
                    setStackName('Caller Name');
                    break;
                default:
                    // setStackName(modalShow?.tag);
                    // modalShow?.tag;
                    break;
            }
        }
    };
    useEffect(() => {
        updateStackName();
    }, [handleShow]);

    useEffect(() => {
        updateStackName();
    }, [modalShow?.tag]);

    // Handle the change in the SelectField and update selectedCategoryName
    const handleCategoryChange11 = (event) => {
        setQuestionInput("");
        setQuestionTagValue();
        const selectedValue = event.target.value;
        const parts = selectedValue.split('%');
        const categoryName = parts[0];
        formik.handleChange(event);
        const selectedOption = catOption1.find(option => `${option.categoryName}%${option.id}` === selectedValue);
        setSelectedCategoryType(selectedOption ? selectedOption.categoryType : "");
        setIsVideoVisit(selectedOption?.categoryType === "Video Visit");
    };

    const getCategoryTypeName = (categoryType) => {
        const category = questionnaireForOptions.find(option => option.value === categoryType);
        return category ? category.name : categoryType;
    };

    const handleQuestionInputChange = (event) => {
        setQuestionInput(event.target.value);
        formik.handleChange(event);
    };
    const hasAccess = userType?.includes("Super-Admin") ? true : null;

    const handleDelete = async (id, entryName) => {
        const confirmed = await getConfirmation({
            title: "Attention!",
            entryName,
            actionBtnTitle: "Delete",
        });
        if (confirmed) {
            setDeleting(formik?.values?.id);
            deleteQuestion(formik?.values?.id).then(() => {
                // setFilterData(filterData.filter(data => data.id !== id));
                refreshTable();
                handleShow();
                success("Question Deleted!");
            }).catch(res => failed(res?.response?.data?.message || res?.response?.data?.error || res.message)).finally(() => setDeleting(false));
        }
    };
    const handleQuestionTag = () => {
        setShowTag(true);
    };
    const handleClick = (event) => {
        // setAnchorEl(event.currentTarget);
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    
    const handleClose = (selectedValue) => {
        setIsModalTag(false);
        setAnchorEl(null);
        if (typeof selectedValue == 'object') {
            // navigate("/app/create-questions");
            return;
        }
        if (selectedValue !== undefined) {
            setQuestionTagValue(selectedValue);
            if (typeof handleQuestionTagValue === 'function') {
                handleQuestionTagValue(selectedValue);
            }

            switch (selectedValue) {
                case 'TELEPHONE':
                    setStackName('Return Telephone Number');
                    break;
                case 'CHANNEL':
                    setStackName('Preferred Channel');
                    break;
                case 'REASON':
                    setStackName('Reason for visit');
                    break;
                case 'LOCATION':
                    setStackName('Patient Location');
                    break;
                case 'RELATIONSHIP':
                    setStackName('Relationship to patient');
                    break;
                case 'SELFCALLING':
                    setStackName('Calling for yourself');
                    break;
                case 'CONSENT':
                    setStackName('Patient Consent');
                    break;
                case 'CALLER':
                    setStackName('Caller Name');
                    break;
                default:
                    setStackName('');
                    break;
            }

        }

    };

    const handleDeleteChip = () => {
        setQuestionTagValue();
        setStackName();
        setIsModalTag(true);
        if (typeof onIsModalTagChange === 'function') {
            onIsModalTagChange(isModalTag);
        }
    };

    const handleChangeAnsType = (e) => {
        setQuestionTagValue();
    };

    let channelDataMap = [
        { id: Date.now(), text: "Video" },
        { id: Date.now() + 1, text: "Phone" }
    ];

    return (
        catOption1 == undefined ? <p>Loading...</p> :
            <form className="common-form border-fields" onSubmit={formik.handleSubmit} onChange={e => handleFormChange({ e, formik })}>
                <Row>
                    <Col md={5}>
                        <SelectFiled
                            keyField={"category"}
                            label={"Category"}
                            formik={formik}
                            placeholder={"Select"}
                            options={catOption1.map((option) => ({
                                value: `${option.categoryName}%${option.id}`,
                                name: `${option.categoryName} ( ${getCategoryTypeName(option?.categoryType)} )`,
                            }))}
                            onChange={handleCategoryChange11}
                            disabled={isReadOnly}
                        />
                    </Col>
                    <Col  md={7}>
                        <TextInput keyField={"question"} label={"Enter Question"} formik={formik} placeholder={"Whats your question?"} value={questionInput} disabled={isReadOnly}
                            onChange={handleQuestionInputChange} />
                    </Col>
                    {/* <Col></Col> */}
                </Row>
                {/* {(isVideoVisit) ? <>
                    <Row>
                        <Col>
                            <div className="field-wrap" style={{ margin: 0 }}>
                                <label className="custom-checkbox" style={{ margin: 0, width: "fit-content" }}>
                                    Is Mandatory
                                    <input style={{ width: "auto" }} type="checkbox" name={"Is Mandatory"} checked={formik.values.required} onChange={() => formik.setFieldValue("required", !!!formik?.values?.required)} disabled={isReadOnly} />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <SelectFiled keyField={"answerType"} label={"Answer type"} formik={formik} placeholder={"Answer type"} options={[{ value: "freeText", name: "Free Text" }]} disabled={isReadOnly} />
                        </Col>
                        <Col>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row className={formik.values.answerType === "freeText" ? "" : "mt-4"}>
                        <Col>
                            <TextArea keyField={"note"} label={"Note"} formik={formik} placeholder={"Write here..."} required={false} disabled={isReadOnly} />
                        </Col>
                        <Col></Col>
                    </Row>
                </> : ''} */}

                {(selectedCategoryType != undefined && (selectedCategoryType === 'getcare-appointment' || selectedCategoryType === 'getcare-waitingroom' || selectedCategoryType === 'telephone-intake')) ? <>
                    <Row>
                        <Col>
                            <div className="field-wrap" style={{ margin: 0 }}>
                                <label className="custom-checkbox" style={{ margin: 0, width: "fit-content" }}>
                                    Is Mandatory
                                    {!isVideoVisit && (questionTagValue == 'CHANNEL' || questionTagValue == 'TELEPHONE' || questionTagValue == 'REASON' || questionTagValue == 'CALLER' || questionTagValue == 'LOCATION' || questionTagValue == 'RELATIONSHIP' || questionTagValue == 'SELFCALLING' || questionTagValue == 'CONSENT') ? <>
                                        <input style={{ width: "auto" }} type="checkbox" name={"Is Mandatory"} checked={true} onChange={() => formik.setFieldValue("required", !!!formik?.values?.required)} disabled={isReadOnly} />
                                        <span className="checkmark"></span> </>
                                        : <>
                                            <input style={{ width: "auto" }} type="checkbox" name={"Is Mandatory"} checked={formik.values.required} onChange={() => formik.setFieldValue("required", !!!formik?.values?.required)} disabled={isReadOnly} />
                                            <span className="checkmark"></span> </>}
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col className="col-md-4">
                            <SelectFiled keyField={"answerType"} label={"Answer type"} formik={formik} placeholder={"Answer type"} options={answerTypeOptions} disabled={isReadOnly}
                                // onChange={handleChangeAnsType} 
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    handleChangeAnsType();
                                }}
                            />
                        </Col>
                        <Col>
                            {(!isVideoVisit && formik.values.answerType !== "checkbox") ? <>
                                {/* && (formik.values.answerType === "freeText" || formik.values.answerType === "radio")) ? */}
                                <img
                                    src={DeleteIcon}
                                    alt="Delete"
                                    // onClick={() => handleQuestionTag()}
                                    onClick={handleClick}
                                    style={{ cursor: "pointer" }}
                                />
                                {!isReadOnly && (

                                    <Menu
                                        id="demo-positioned-menu"
                                        aria-labelledby="demo-positioned-button"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                    >
                                        {/* <MenuItem style={{ marginRight: 'auto' }} onClick={() => handleClose()}>X</MenuItem> */}
                                        {/* <MenuItem onClick={handleClose}>{questionTagFreeTextOptions}</MenuItem> */}
                                        {selectedCategoryType === 'telephone-intake' && formik.values.answerType === "freeText" ? questionTagFreeTextOptions.map((option) => (
                                            <MenuItem key={option.value} onClick={() => handleClose(option.value)}>
                                                {option.name}
                                            </MenuItem>
                                        )) : (
                                            selectedCategoryType === 'telephone-intake' && formik.values.answerType === "radio" ?
                                                questionTagRadioOptions.map((option) => (
                                                    <MenuItem key={option.value} onClick={() => handleClose(option.value)}>
                                                        {option.name}
                                                    </MenuItem>
                                                )) : (
                                                    selectedCategoryType === 'telephone-intake' && formik.values.answerType === "dropDown" ?
                                                        questionTagDropDownOptions.map((option) => (
                                                            <MenuItem key={option.value} onClick={() => handleClose(option.value)}>
                                                                {option.name}
                                                            </MenuItem>
                                                        ))

                                                        : (
                                                            (selectedCategoryType === 'getcare-appointment' || selectedCategoryType === 'getcare-waitingroom') && formik.values.answerType === "freeText" ? questionTagFreeTextOptionsGetCare.map((option) => (
                                                                <MenuItem key={option.value} onClick={() => handleClose(option.value)}>
                                                                    {option.name}
                                                                </MenuItem>
                                                            ))
                                                                : (
                                                                    (selectedCategoryType === 'getcare-appointment' || selectedCategoryType === 'getcare-waitingroom') && formik.values.answerType === "radio" ? questionTagRadioOptionsGetCare.map((option) => (
                                                                        <MenuItem key={option.value} onClick={() => handleClose(option.value)}>
                                                                            {option.name}
                                                                        </MenuItem>
                                                                    ))
                                                                        : (
                                                                            (selectedCategoryType === 'getcare-appointment' || selectedCategoryType === 'getcare-waitingroom') && formik.values.answerType === "dropDown" ? questionTagDropDownOptionsGetCare.map((option) => (
                                                                                <MenuItem key={option.value} onClick={() => handleClose(option.value)}>
                                                                                    {option.name}
                                                                                </MenuItem>
                                                                            ))
                                                                                : null))))
                                        )}
                                    </Menu>)}
                            </> : null}
                            {(formik.values.answerType === "freeText" || formik.values.answerType === "radio" || formik.values.answerType === "dropDown") && (questionTagValue != undefined) && (stackName != undefined) ? <>
                                <Stack direction="row" spacing={1} className="d-inline-flex ms-1 ms-sm-4" >
                                    <Chip label={stackName} onDelete={handleDeleteChip}
                                        color={isReadOnly ? "default" : "primary"}
                                        disabled={isReadOnly}
                                    />
                                    {/* <Chip label="Deletable" variant="outlined" onDelete={handleDelete} /> */}
                                </Stack>
                            </> : null}
                        </Col>
                        <Col className="d-none">
                            {!isVideoVisit && (formik.values.answerType === "freeText" || formik.values.answerType === "radio") && (questionTagValue != undefined) ? <>

                                {/* <Stack direction="row" spacing={1}>
                                <Chip label={questionTagValue} onDelete={handleDeleteChip} 
                                        color={isReadOnly ? "default" : "primary"}
                                        disabled={isReadOnly}
                                    /> */}
                                {/* <Chip label="Deletable" variant="outlined" onDelete={handleDelete} /> */}
                                {/* </Stack> */}
                            </> : null}
                        </Col>

                    </Row>


                    <Row>
                        {formik.values.answerType == "freeText"
                            ? null
                            : ((questionTagValue === "CHANNEL" && formik.values.answerType === "radio") && (selectedCategoryType == 'getcare-appointment' || selectedCategoryType == 'getcare-waitingroom'))
                                ?
                                <>
                                    <Col>
                                        {channelDataMap?.map((row, index) => {
                                            // const disableRemove = channelDataMap?.length < 1;
                                            return <div key={row.id}>
                                                <Row className="mt-2">
                                                    <Col style={{
                                                        marginBottom: '-1.5rem'
                                                    }}>
                                                        {index == 0 ? <TextInput
                                                            keyField={`options[0].text`}
                                                            label={"Answers"}
                                                            formik={formik}
                                                            value={"Video"}
                                                            isError={null}
                                                            disabled={true}
                                                            required={false}
                                                            propsOptionData={0}
                                                        /> : null}
                                                    </Col>
                                                    <Col style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "1rem",
                                                        marginBottom: '-1.5rem'
                                                    }}>
                                                    </Col>
                                                    <Col></Col>
                                                </Row>

                                                <Row className="mt-2">
                                                    <Col style={{
                                                        marginBottom: '-1.5rem'
                                                    }}>
                                                        {index == 1 ? <TextInput
                                                            keyField={`options[1].text`}
                                                            //    label={"Answers"}
                                                            formik={formik}
                                                            value={"Phone"}
                                                            isError={null}
                                                            disabled={true}
                                                            required={false}
                                                            propsOptionData={0}
                                                        /> : null}
                                                    </Col>
                                                    <Col style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "1rem",
                                                        marginBottom: '-1.5rem'
                                                    }}>
                                                    </Col>
                                                    <Col></Col>
                                                </Row>
                                            </div>;
                                        })}
                                    </Col>
                                </>
                                :
                                <>
                                    <Col>
                                        {formik.values?.options?.map((row, index) => {
                                            const disableRemove = formik.values?.options?.length < 2;
                                            const isChannelQuestion = questionTagValue === "CHANNEL" && formik.values.answerType === "radio" && (selectedCategoryType === 'getcare-appointment' || selectedCategoryType === 'getcare-waitingroom');

                                            return <div key={row.id}>
                                                <Row className={index === 0 ? "" : "mt-2"}>                                                    
                                                    <Col style={{
                                                        marginBottom: '-1.5rem'
                                                    }}>
                                                        {isChannelQuestion ? channelDataMap : (
                                                            <TextInput
                                                                keyField={`options[${index}].text`}
                                                                label={index === 0 ? "Answers" : false}
                                                                formik={formik}
                                                                placeholder={"Write your answer"}
                                                                isError={formik?.touched?.options?.[index]?.text && formik?.errors?.options?.[index]?.text}
                                                                disabled={isReadOnly}
                                                            />
                                                        )}                                                       
                                                    </Col>
                                                    <Col style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "1rem",
                                                        marginBottom: '-1.5rem'
                                                    }}>
                                                        {!isReadOnly ? (<>
                                                            <div style={{ ...iconStyle, cursor: disableRemove ? "auto" : "pointer" }}>
                                                                <Remove
                                                                    style={{
                                                                        width: "inherit",
                                                                        height: 'inherit',
                                                                        color: "rgba(0, 93, 168, 1)",
                                                                        opacity: disableRemove ? "0.3" : "1"
                                                                    }}
                                                                    onClick={() => !disableRemove && removeRow({ index, formik: formik })}
                                                                />
                                                            </div>
                                                            <div style={iconStyle}>
                                                                <Add
                                                                    style={{
                                                                        width: "inherit",
                                                                        height: 'inherit',
                                                                        color: "rgba(0, 93, 168, 1)"
                                                                    }}
                                                                    onClick={() => addRow({ formik: formik })}
                                                                />
                                                            </div>
                                                        </>) : null}
                                                    </Col>
                                                    <Col></Col>
                                                </Row>
                                            </div>;
                                        })}
                                    </Col>                                    
                                </>}
                    </Row>
                    
                    <Row className={formik.values.answerType === "freeText" ? "" : "mt-4"}>
                        <Col md={12}>
                            <TextArea keyField={"note"} label={"Note"} formik={formik} placeholder={"Write here..."} required={false} disabled={isReadOnly} />
                        </Col>
                        {/* <Col></Col> */}
                    </Row>
                </> : (

                    (selectedCategoryType != undefined && (selectedCategoryType === 'Video Visit')) ? <>
                        <Row>
                            <Col>
                                <div className="field-wrap" style={{ margin: 0 }}>
                                    <label className="custom-checkbox" style={{ margin: 0, width: "fit-content" }}>
                                        Is Mandatory
                                        <>
                                            <input style={{ width: "auto" }} type="checkbox" name={"Is Mandatory"} checked={formik.values.required} onChange={() => formik.setFieldValue("required", !!!formik?.values?.required)} disabled={isReadOnly} />
                                            <span className="checkmark"></span> </>
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <Col className="col-md-4">
                                <SelectFiled keyField={"answerType"} label={"Answer type"} formik={formik} placeholder={"Answer type"} options={answerTypeOptions} disabled={isReadOnly}
                                    // onChange={handleChangeAnsType} 
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        handleChangeAnsType();
                                    }}
                                />
                            </Col>
                            <Col>
                                {(formik.values.answerType === "freeText" || formik.values.answerType === "radio" || formik.values.answerType === "dropDown") && (questionTagValue != undefined) && (stackName != undefined) ? <>
                                    <Stack direction="row" spacing={1} className="d-inline-flex ms-1 ms-sm-4" >
                                        <Chip label={stackName} onDelete={handleDeleteChip}
                                            color={isReadOnly ? "default" : "primary"}
                                            disabled={isReadOnly}
                                        />
                                        {/* <Chip label="Deletable" variant="outlined" onDelete={handleDelete} /> */}
                                    </Stack>
                                </> : null}
                            </Col>

                        </Row>


                        <Row>
                            {formik.values.answerType == "freeText"
                                ? null
                                : <>
                                    <Col>
                                        {formik.values?.options?.map((row, index) => {
                                            const disableRemove = formik.values?.options?.length < 2;
                                            return <div key={row.id}>
                                                <Row className={index === 0 ? "" : "mt-2"}>
                                                    <Col style={{
                                                        marginBottom: '-1.5rem'
                                                    }}>
                                                        <TextInput
                                                            keyField={`options[${index}].text`}
                                                            label={index === 0 ? "Answers" : false}
                                                            formik={formik}
                                                            placeholder={"Write your answer"}
                                                            isError={formik?.touched?.options?.[index]?.text && formik?.errors?.options?.[index]?.text}
                                                            disabled={isReadOnly}
                                                        />
                                                    </Col>
                                                    <Col style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "1rem",
                                                        marginBottom: '-1.5rem'
                                                    }}>
                                                        {!isReadOnly ? (<>
                                                            <div style={{ ...iconStyle, cursor: disableRemove ? "auto" : "pointer" }}>
                                                                <Remove
                                                                    style={{
                                                                        width: "inherit",
                                                                        height: 'inherit',
                                                                        color: "rgba(0, 93, 168, 1)",
                                                                        opacity: disableRemove ? "0.3" : "1"
                                                                    }}
                                                                    onClick={() => !disableRemove && removeRow({ index, formik: formik })}
                                                                />
                                                            </div>
                                                            <div style={iconStyle}>
                                                                <Add
                                                                    style={{
                                                                        width: "inherit",
                                                                        height: 'inherit',
                                                                        color: "rgba(0, 93, 168, 1)"
                                                                    }}
                                                                    onClick={() => addRow({ formik: formik })}
                                                                />
                                                            </div>
                                                        </>) : null}
                                                    </Col>
                                                    <Col></Col>
                                                </Row>
                                            </div>;
                                        })}
                                    </Col>

                                </>}
                        </Row>

                        <Row className={formik.values.answerType === "freeText" ? "" : "mt-4"}>
                            <Col md={12}>
                                <TextArea keyField={"note"} label={"Note"} formik={formik} placeholder={"Write here..."} required={false} disabled={isReadOnly} />
                            </Col>
                            {/* <Col></Col> */}
                        </Row>
                    </> : null


                )}
                {!isReadOnly && isCreate ? (
                    <div className="btn-wrap" style={{ display: "flex" }}>
                        <Button variant="secondary" title="Cancel" onClick={handleShow}>
                            Cancel
                        </Button>
                        <Button type="submit" isLoading={btnLoading}>
                            {isCreate ? "Create" : "Update"}
                        </Button>
                    </div>
                ) : null}

                {isReadOnly && !isCreate || !isReadOnly && !isCreate ? <hr /> : null}
                <Row>
                    {isReadOnly && !isCreate || !isReadOnly && !isCreate ? (<>
                        <Col>
                            <div>
                                <h6>Date Created : {ViewDate(formik?.values?.createdAt)}</h6>
                                <h6>Date Last Updated : {ViewDate(formik?.values?.updatedAt)} </h6>
                            </div>
                        </Col> </>
                    ) : null}
                    {!isReadOnly && !isCreate ? (<>
                        <Col>
                            <div>

                                <div
                                // className="btn-wrap"
                                // style={{ display: "flex", marginTop: "12rem" }}
                                >

                                    <Row >
                                        <Col sm={12} md={12} lg={4} xl={4} className="text-center" >
                                            <Button variant="secondary" title="Cancel" onClick={handleShow} style={{ width: '100%', marginBottom: '10px', padding: '5px 10px' }}>
                                                Cancel
                                            </Button>
                                        </Col>
                                        <Col sm={12} md={12} lg={4} xl={4} className="text-center" >
                                            <Button type="submit" isLoading={btnLoading} style={{ width: '100%', marginBottom: '10px', padding: '5px 10px' }}>
                                                Update
                                            </Button>
                                        </Col>
                                        <Col sm={12} md={12} lg={4} xl={4} className="text-center" >
                                            <Button variant="secondary" title="Delete" onClick={() => handleDelete(formik?.values?.id, formik?.values?.question)} style={{ width: '100%', marginBottom: '10px', padding: '5px 10px' }}>
                                                Delete
                                            </Button>
                                        </Col>
                                    </Row>

                                </div>

                            </div>
                        </Col>
                    </>
                    ) : null}
                </Row>

            </form>);

}
