import { useQuery } from "react-query";
import { onError } from "./onError";
import { axios } from "../../lib/axios";

export function useServiceRequestQuery({patientId, onSuccess, ...props }) {
    const fetch = () => {
       //  return axios.get(`servicerequest/list?&active=true&patientId=${patientId}`)
       return axios.get(`servicerequest?patientId=${patientId}`)
    }

    const query = useQuery(
        ["service-request",patientId],
        () => fetch(),
        {
            select: (data) => data?.data,
            onSuccess,
            onError,
            ...props
        }
    );

    return { ...query }
}