import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ButtonGroup, Table, Button } from "react-bootstrap";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { Skeleton, TableCell, Tooltip } from "@mui/material";
import ViewIcon from "../../../../assets/images/view.png";
import { getAllDocumentsByPatientId, getDocumentDashboard } from "../../../api/FillableDocument.js";
import { decryptData } from "../../../EncryptDecrypt";
import moment from "moment";
import CustomPagination from "../../../common/components/Pagination/CustomPagination";
import { AttachementModal } from "../../AppointmentRequests/AttachementModal";
import Tab from "react-bootstrap/Tab";
import { ViewDateTime } from "../../../../utils/DateSupport";

export function DocumentsList({ patientId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [docData, setDocData] = useState(null)
  const [attachmentUrl, setAttachmentUrl] = useState('');
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [sortBy, setSortBy] = useState("AscName");
  const [page, setPage] = useState(0);
  const rowsPerPage = 5;
  const totalRows = documentList.length;

  const getListOfDocumentDashboard = async () => {
    setIsLoading(true);
    const list = await getAllDocumentsByPatientId(patientId);
    if (list.status === true) {
      setDocumentList(list?.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getListOfDocumentDashboard();
  }, []);

  const sortFilterData = (sort_by) => {
    setSortBy(sort_by)
    switch (sort_by) {
        case "AscDate":
            setDocumentList(documentList?.sort((a, b) => (decryptData(a?.createdAt) > decryptData(b?.createdAt)) ? 1 : ((decryptData(b?.createdAt) > decryptData(a?.createdAt)) ? -1 : 0)))
            break;
        case "DscDate":
            setDocumentList(documentList?.sort((a, b) => (decryptData(a?.createdAt) < decryptData(b?.createdAt)) ? 1 : ((decryptData(b?.createdAt) < decryptData(a?.createdAt)) ? -1 : 0)))
            break;
        case "AscSource":
            setDocumentList(documentList?.sort((a, b) => (decryptData(a.author?.[0]?.display) > decryptData(b?.author?.[0]?.display)) ? 1 : ((decryptData(b?.author?.[0]?.display) > decryptData(a?.author?.[0]?.display)) ? -1 : 0)))
            break;
        case "DscSource":
            setDocumentList(documentList?.sort((a, b) => (decryptData(a.author?.[0]?.display) < decryptData(b?.author?.[0]?.display)) ? 1 : ((decryptData(b?.author?.[0]?.display) < decryptData(a?.author?.[0]?.display)) ? -1 : 0)))
            break;
        case "AscCategory":
              setDocumentList(documentList?.sort((a, b) => (decryptData(a?.category?.[0]?.coding?.[0]?.display) > decryptData(b?.category?.[0]?.coding?.[0]?.display)) ? 1 : ((decryptData(b?.category?.[0]?.coding?.[0]?.display) > decryptData(a?.category?.[0]?.coding?.[0]?.display)) ? -1 : 0)))
              break;
        case "DscCategory":
              setDocumentList(documentList?.sort((a, b) => (decryptData(a?.category?.[0]?.coding?.[0]?.display) < decryptData(b?.category?.[0]?.coding?.[0]?.display)) ? 1 : ((decryptData(b?.category?.[0]?.coding?.[0]?.display) < decryptData(a?.category?.[0]?.coding?.[0]?.display)) ? -1 : 0)))
              break;
        case "AscPrac":
            setDocumentList(documentList?.sort((a, b) => (decryptData(a.authenticator?.[0]?.display) > decryptData(b.authenticator?.[0]?.display)) ? 1 : ((decryptData(b.authenticator?.[0]?.display) > decryptData(a.authenticator?.[0]?.display)) ? -1 : 0)))
            break;
        case "DscPrac":
            setDocumentList(documentList?.sort((a, b) => (decryptData(a.authenticator?.[0]?.display) < decryptData(b.authenticator?.[0]?.display)) ? 1 : ((decryptData(b.authenticator?.[0]?.display) < decryptData(a.authenticator?.[0]?.display)) ? -1 : 0)))
            break;
        case "AscStatus":
            setDocumentList(documentList?.sort((a, b) => (decryptData(a.status) > decryptData(b.status)) ? 1 : ((decryptData(b.status) > decryptData(a.status)) ? -1 : 0)))
            break;
        case "DscStatus":
            setDocumentList(documentList?.sort((a, b) => (decryptData(a.status) < decryptData(b.status)) ? 1 : ((decryptData(b.status) < decryptData(a.status)) ? -1 : 0)))
            break;       
        default:
            setDocumentList(documentList)
            break;
    }
}

  const handleShowFile = async(data) => {
    setShowAttachmentModal(true)
    setAttachmentUrl(data?.content?.[0]?.attachment?.url)
    setDocData(data)
}

  return (
    <>
    {showAttachmentModal ? <AttachementModal fromDocument={true} modalShow={showAttachmentModal} url={attachmentUrl} docData={docData} handleShow={() => setShowAttachmentModal(false)}/> : null}
    <Tab.Pane eventKey="eleventh">
      <div className="table-wrap">
        <Table responsive>
          <thead>
            <tr>
              <th style={{ cursor: "pointer" }} onClick={() => sortFilterData(sortBy === "AscDate" ? "DscDate" : "AscDate")}> Date/Time {sortBy === "AscDate" ? <span> <ArrowDropUp /> </span> : <span> <ArrowDropDown /></span>} </th>
              <th style={{ cursor: "pointer" }} onClick={() => sortFilterData(sortBy === "AscCategory" ? "DscCategory" : "AscCategory")}> Category {sortBy === "AscCategory" ? <span> <ArrowDropUp /> </span> : <span> <ArrowDropDown /></span>}</th>
              <th style={{ cursor: "pointer" }} onClick={() => sortFilterData(sortBy === "AscSource" ? "DscSource" : "AscSource")}> Source {sortBy === "AscSource" ? <span> <ArrowDropUp /> </span> : <span> <ArrowDropDown /></span>}</th>
              <th style={{ cursor: "pointer" }} onClick={() => sortFilterData(sortBy === "AscPrac" ? "DscPrac" : "AscPrac")}> Practitioner {sortBy === "AscPrac" ? <span> <ArrowDropUp /> </span> : <span> <ArrowDropDown /></span>}</th>
              <th style={{ cursor: "pointer" }} onClick={() => sortFilterData(sortBy === "AscStatus" ? "DscStatus" : "AscStatus")}> Status {sortBy === "AscStatus" ? <span> <ArrowDropUp /> </span> : <span> <ArrowDropDown /></span>}</th>
              <th style={{ textAlign: "center" }}>Action</th>
            </tr>
          </thead>
          <tbody>
             {isLoading ? (
              [1, 2, 3, 4, 5, 6, 7, 8]?.map((val) => (
                <tr key={val}>
                  <td>
                    <Skeleton animation="wave" />
                  </td>
                  <td>
                    <Skeleton animation="wave" />
                  </td>
                  <td>
                    <Skeleton animation="wave" />
                  </td>
                  <td>
                    <Skeleton animation="wave" />
                  </td>
                  <td>
                    <Skeleton animation="wave" />
                  </td>
                    <td>
                      <Skeleton animation="wave" />
                    </td>
                  <td>
                    <Skeleton animation="wave" />
                  </td>
                  <td>
                    <Skeleton animation="wave" />
                  </td>
                </tr>
              ))
            ) : documentList?.length > 0 ? (
              documentList?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((data) => {
                const {id, createdAt, author, category, authenticator, status} = data;
                return (
            <tr key={id}>
              <td>{ViewDateTime(createdAt)}</td>
              <td>{category?.[0]?.coding?.[0]?.display || '-'}</td>
              <td>{author?.[0]?.display?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') || '-'}</td>
              <td>{authenticator?.[0]?.display?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') || '-'}</td>
              <td style={{textTransform: "capitalize"}}>{status}</td>
              <td>
                <div
                  className="action-wrap"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip title={"View"}>
                    <div  className="view-btn" onClick={() =>  handleShowFile(data)} style={{borderRadius : "50%"}}>
                      <img src={ViewIcon} alt="View PDF" />
                    </div>
                  </Tooltip>
                </div>
              </td>
            </tr>
            );
                })
            ) : (
              <tr>
                <TableCell colSpan={"7"} style={{ textAlign: "center" }}>
                  No Data Available
                </TableCell>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {documentList?.length > 0 ? <CustomPagination tableData={documentList} totalRows={totalRows} rowsPerPage={rowsPerPage} page={page} handlePage={(pageNo) => setPage(pageNo)} /> : null}
    </Tab.Pane>
    </>
  );
}
