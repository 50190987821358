import { useQuery } from "react-query";
import { onError } from "./onError";
import { axios } from "../../lib/axios";

export function useMedicationRequestQuery({patientId, onSuccess, ...props }) {
    const fetch = () => {
        return axios.get(`medicationrequest?patientId=${patientId}`)
    }

    const query = useQuery(
        ["medication-request",patientId],
        () => fetch(),
        {
            select: (data) => data?.data,
            onSuccess,
            onError,
            ...props
        }
    );

    return { ...query }
}

export function useViewMedicationRequestQuery({encounterId, onSuccess, ...props }) {
    const fetch = () => {
        return axios.get(`medicationrequest?encounterId=${encounterId}`)
    }

    const query = useQuery(
        ["view-medication",encounterId],
        () => fetch(),
        {
            select: (data) => data?.data,
            onSuccess,
            onError,
            ...props
        }
    );

    return { ...query }
}