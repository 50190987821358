import * as Yup from "yup";

export const org_roles = {
    "Organisational Admin List": {
        selected: false,
    },
    "Create Organisational Admin": {
        selected: false,
    },
    "Case Manager List": {
        selected: false,
    },
    "Create Case Manager": {
        selected: false,
    },
    "Patient List": {
        selected: false,
    },
    "Create Patient": {
        selected: false,
    },
    "Organisation List": {
        selected: false,
    },
    "Create Organisation": {
        selected: false,
    },
};
export const clinical_roles = {
    "Program List": {
        selected: false,
    },
    "Create Program": {
        selected: false,
    },
};

export const FormikObj = {
    initialValues: {
        name: "",
        description: "",
        organization: "",
        isDefault: false,
    },
    validationSchema: Yup.object({
        name: Yup.string()
            .max(30, "Must be 30 characters or less")
            .required("Required name field")
            .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
        description: Yup.string()
            .required("Required description field")
            .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
        organization: Yup.string().when("isDefault", (isDefault, schema) =>
            isDefault
                ? schema
                : Yup.string().max(30, "Must be 30 characters or less")
                    .required("Required organization field")
                    .matches(/^$|^\S+.*/, "Only blankspaces is not valid.")
        ),
    }),
}

export const FormikObjEdit = {
    initialValues: {
        id: "",
        name: "",
        description: "",
    },
    validationSchema: Yup.object({
        id: Yup.string().max(30, "Must be 30 characters or less").required("Required name field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        name: Yup.string().max(30, "Must be 30 characters or less").required("Required name field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        description: Yup.string().required("Required name field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
    }),
}