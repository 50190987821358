import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useThresholdPatientListQuery } from "../../../hooks/AppsReactQueryHooks/useThresholdPatientListQuery";
import SearchBar from "../../common/components/SearchBar";
import CustomTable from "../../common/table/CustomTable";
import { PatientsAssignedDataFields, PatientsAssignedHeadCells } from "./Constants";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import { failed, success } from "../../common/Toastify";
import { deleteThresholdGroupPatient } from "../api/ThresholdGroup";
import { ArrowBack } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { decryptData } from "../../EncryptDecrypt";

const ThresholdPatientList = () => {
    let { state } = useLocation();
    const navigate = useNavigate();
    const { getConfirmation } = useConfirmDialog();
    const [filterData, setFilterData] = useState([]);
    const [page, setPage] = useState(0);
    const [deleting, setDeleting] = useState(false);
    const [thresholdPatientList, setThresholdPatientList] = useState([]);
    const onSuccess = (data) => {
        setThresholdPatientList(data);
    };
    const { isLoading, isFetching } = useThresholdPatientListQuery({ patientIds: state?.patientIds || [], onSuccess });
    const handleFilterData = (data) => {
        setFilterData(data);
    };
    const handleView = (data) => {
        navigate("/app/patients-details", { state: { id: data?.id, name: data?.name[0]?.text } });
    };
    const selectedUserAction = ({ action, data }) => {
        switch (action) {
            case "view":
                handleView(data);
                break;
            case "delete":
                handleDelete(data?.id, decryptData(data?.name?.[0]?.text));
                break;
            default:
                return null;
        }
    };
    const handleDelete = async (id, entryName) => {
        const confirmed = await getConfirmation({
            title: "Attention!",
            entryName,
            actionBtnTitle: "Delete",
        });
        if (confirmed) {
            setDeleting(id);
            deleteThresholdGroupPatient(state.groupId, id)
                .then((res) => {
                    const newData = filterData.filter((data) => data.id !== id)
                    setFilterData(newData);
                    let patient = {
                        patientIds: state.patientIds.filter((data) => data !== id),
                        groupId: state?.groupId
                    }
                    navigate("/app/threshold-patient-list", { replace: true, state: patient });
                    success("Patient Deleted!");
                })
                .catch((err) => failed(err?.response?.data?.message || err.message))
                .finally(() => setDeleting(false));
        }
    };
    return (
        <section className="common-listing">
            <div className="heading-wrap">
                <Tooltip title="Go back">
                    <div style={{ marginRight: "1rem", cursor: "pointer" }} onClick={() => navigate(-1)}>
                        <ArrowBack />
                    </div>
                </Tooltip>
                <SearchBar page={page} setPage={setPage} list={thresholdPatientList} filterData={handleFilterData} />
            </div>
            <CustomTable
                tableData={filterData}
                headCells={PatientsAssignedHeadCells}
                dataFields={PatientsAssignedDataFields}
                selectedUserAction={selectedUserAction}
                rowOptions={false}
                loading={isLoading}
                fetching={isFetching}
                deletingItem={deleting}
                canView={true}
                canDelete={true}
                canEdit={false}
                page={page}
                setPage={setPage}
            />
        </section>
    );
};

export default ThresholdPatientList;
