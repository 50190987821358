import React, { useEffect, useRef, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import TextInput from "../../common/textfield/TextInput";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../common/Button";
import { failed, success } from "../../common/Toastify";
import Select from 'react-select';
import { FormikObj, categoryOptions, filterStatusOptions, handleExt } from "./Constant";
import SelectFiled from "../../common/textfield/SelectFiled";
import { Tooltip } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useSelector } from "react-redux";
import InputFile from "../../common/textfield/InputFile";
import { handleFileUpload } from "../../../utils/ImageToBase64";
import { getPatientList, getSingleUser } from "../../api/Individual";
import { decryptData } from "../../EncryptDecrypt";
import { getLocationQuery, getOrgnizationForLocation } from "../../api/ClinicLocation";
import { addDocument, addDocumentDashboard } from "../../api/FillableDocument.js";

function UploadDocument() {
    const navigate = useNavigate();
    const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
    const user = useSelector((state) => state?.auth?.user)
    const [btnLoading, setBtnLoading] = useState(false);
    const [practionerList, setPractionerList] = useState([]);
    const [patientList, setPatientList] = useState([]);
    const [locations, setLocations] = useState([]);
    const [file, setFile] = useState();
    const userData = useRef();
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)

    const formik = useFormik({
        ...FormikObj,
        onSubmit: async(values) => {
                setBtnLoading(true);
                let base64Document
                if (values?.document) {
                  base64Document = await handleFileUpload(values?.document);
                  base64Document = base64Document.split(',')[1]  
                }
                const document = {"data": base64Document, "extension":"pdf"}
                addDocumentDashboard({ ...values, isForSRFax: true, code: "manual", orgId: adminOrgs?.[0]?.id, document: document, loginPractitionerName : user?.name?.[0]?.text })
                    .then((res) => {
                        if (res.status === true) {
                            navigate("/app/document-dashboard");
                            success(res.message);
                        } else {
                            failed(res.error);
                        }
                    })
                    .catch((res) => {
                        failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                    })
                    .finally(() => setBtnLoading(false));
        },
    });

    const handleSource = (e)=>{
      formik.setFieldValue("locationId", e?.value)
      formik.setFieldValue("locationName", e?.value === "other" ? "Other" : locations?.find((obj)=> obj?.value === e?.value)?.label)
    }

    const handleCategory = (e)=>{
      formik.setFieldValue("categoryCode", e.target.value)
      formik.setFieldValue("categoryDisplay", categoryOptions?.find((obj)=> obj?.value === e.target.value)?.name)
    }
    
    const handlePractitionerChange = (e)=>{
      formik.setFieldValue("practitionerId", e?.value  ? e?.value : "")
      formik.setFieldValue("selectedPractitionerName", e?.value  ? practionerList?.find((obj)=> obj?.value === e?.value)?.label : "")
      formik.setFieldValue("status", e?.value ? "assigned" : "new")
    }

    const handlePatientChange = (e)=>{
      formik.setFieldValue("patientId", e?.value ? e?.value : "")
      formik.setFieldValue("assignPatient", e?.value ? patientList?.find((obj)=> obj?.value === e?.value)?.label : "")
    }

    useEffect(() => {
      getSingleUser({ orgId : user?.organizations[0]?.id , type : "Practitioner"})
        .then((res) => {
          setPractionerList(res.data.map((ele) => ({
            id: ele.id,
            label: decryptData(ele.name[0]?.text?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')),
            value: ele.id,
          })))
        })
        .catch((res) => {
          console.log("error fetching practioner list",res)
        })

        getPatientList(user?.organizations[0]?.id)
        .then((res) => {
          setPatientList(res?.result?.map((ele) => ({
            id: ele.id,
            label: decryptData(ele.name[0]?.text?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')),
            value: ele.id,
          })))
        })
        .catch((res) => {
          console.log("error fetching practioner list",res)
        })

        fetchLocation()
    }, []);

    const fetchLocation = () =>{
      getOrgnizationForLocation().then((res)=>{
          getLocationQuery({orgnizationIds: [user?.organizations[0]?.id], userRoles}).then((data)=>{
              setLocations([...data?.data.map((obj)=> {
                return {
                  id: obj?.id,
                  value: obj?.id,
                  label: obj?.name?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
                }
              }), {id: "other", value : "other", label : "Other"}]);
          })
      })
  }

  const handlePagesChange = (e)=>{
    let inputValue = e.target.value;
    if (inputValue.startsWith('0') && inputValue !== '0') {
      const newValue = inputValue.replace(/^0+/, '');
      formik.setFieldValue("pages", newValue)
    } else if (inputValue === '0') {
      formik.setFieldValue("pages", '')
    } else {
      formik.setFieldValue("pages", inputValue)
    }
  }

    return (
      <section className="common-listing">
        <div className="heading-wrap mb-3">
          <Tooltip title="Go back">
            <div
              style={{ marginRight: "1rem", cursor: "pointer" }}
              onClick={() => navigate(-1)}
            >
              <ArrowBack />
            </div>
          </Tooltip>
        </div>
        <div className="custom-card p-4">
          <form
            className="common-form border-fields"
            onSubmit={formik.handleSubmit}
          >
            <Row>
              <Col>
              <div className="field-wrap">
                <label className="searchSelectLabel">Source <span style={{ color: "red" }}> *</span> </label> 
                <Select
                    onChange={handleSource}
                    isMulti={false}
                    name="locationId"
                    options={locations}
                    className="search-select"
                    classNamePrefix="select"
                    autoFocus
                    isLoading={locations?.length > 0 ? false : true}
                />
                <div style={{marginTop: "15px"}}>{formik?.touched["locationId"] && formik?.errors["locationId"] ? <div className="error-text">{formik?.errors["locationId"]}</div> : null}</div>
                </div>
              </Col>
              <Col>
                <SelectFiled
                  keyField={"categoryCode"}
                  label={"Category"}
                  formik={formik}
                  options={categoryOptions}
                  onChange={handleCategory}
                />
              </Col>
              <Col>
                <TextInput
                  keyField={"pages"}
                  label={"Pages"}
                  type="text"
                  formik={formik}
                  placeholder={"Pages"}
                  onChange={handlePagesChange}
                  onKeyPress={handleExt}
                  autoComplete="off"
                  required={false}
                />
              </Col>
              <Col>
                <SelectFiled
                    keyField={"status"}
                    label={"Status"}
                    formik={formik}
                    options={filterStatusOptions}
                    disabled={true}
                />
              </Col>
            </Row>
            <Row>
              <Col>
              <div className="field-wrap">
                <label className="searchSelectLabel">Assign Practitioner (Optional)</label>
                <Select
                    onChange={handlePractitionerChange}
                    isMulti={false}
                    name="practitionerId"
                    options={practionerList}
                    className="search-select"
                    classNamePrefix="select"
                    isClearable={true}
                    autoFocus
                    isLoading={practionerList?.length > 0 ? false : true}
                />
                <div>{formik?.touched["practitionerId"] && formik?.errors["practitionerId"] ? <div className="error-text">{formik?.errors["practitionerId"]}</div> : null}</div>
                </div>
              </Col>
              <Col>
              <div className="field-wrap">
                <label className="searchSelectLabel">Assign Patient (Optional)</label>
                <Select
                    onChange={handlePatientChange}
                    isMulti={false}
                    name="patientId"
                    options={patientList}
                    className="search-select"
                    classNamePrefix="select"
                    isClearable={true}
                    autoFocus
                    isLoading={patientList?.length > 0 ? false : true}
                />
                <div>{formik?.touched["patientId"] && formik?.errors["patientId"] ? <div className="error-text">{formik?.errors["patientId"]}</div> : null}</div>
                </div>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col>
                <InputFile
                  keyField={"document"}
                  label={"Upload Document"}
                  formik={formik}
                  placeholder={"Upload Document"}
                  required={true}
                  setFile={setFile}
                  file={file}
                  accept = "application/pdf"
                  imageUrl={userData?.current?.photo?.url}
                />
              </Col>
            </Row>
            <div className="btn-wrap" style={{ display: "flex" }}>
              <Button
                onClick={() => navigate("/app/document-dashboard")}
                variant="secondary"
                title="Cancel"
              >
                Cancel
              </Button>
              <Button type="submit" isLoading={btnLoading}>
                Save
              </Button>
            </div>
          </form>
        </div>
      </section>
    );
}

export default UploadDocument;