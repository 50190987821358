import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import SelectFiled from '../../common/textfield/SelectFiled'
import Fields from '../Dashboard/Fields'; 
import { ExpandMore } from '@mui/icons-material';
import { isAnswered } from '../../../utils/questionnaireSupport';
import TextEditor from '../../common/textfield/TextEditor';

const QuestionnaireRenderer = ({formik, questionResponse, parentIndex}) => {

    return (questionResponse?.map((item, index) => {
               return (<Accordion key={index} >
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography>{item?.question}
                            {item?.required ? <span style={{ color: "red" }}> *</span> : null}
                            {item?.required ? formik.errors.isQuestionsError && !isAnswered(item) && (
                                <div className="error-message" style={{ color: "red", fontSize: "12px" }}>Mandatory Question</div>
                            ) : null}
                        </Typography>
                    </AccordionSummary>
                    {item.questionType === "freeText" && (
                    <AccordionDetails sx={{ padding: 0 }}>
                        <TextEditor 
                        key={`text-editor-${parentIndex}-${index}`}
                        keyField={`questionResponse[${index}]answer[0].answer`} 
                        formik={formik} 
                        label={false} />
                    </AccordionDetails>
                    )} 
                    {(item.questionType === "radio" || item.questionType === "checkbox") && (
                    <AccordionDetails sx={{ padding: 0 }}>
                        <Fields 
                            question={item}
                            formik={formik}
                            Questions={questionResponse}
                            propIndex={index}
                            hideMandatory={true}
                        />
                    </AccordionDetails>
                    )}
                    {item.questionType === "dropDown" && (
                    <AccordionDetails sx={{ padding: 0 }}>
                        <SelectFiled 
                            key={`dropdown-editor-${parentIndex}-${index}`}
                            keyField={`questionResponse[${index}].answer[0].answer`} 
                            style={{fontSize: 16}}
                            formik={formik} 
                            options={item.answerOption} />                                 
                    </AccordionDetails>
                    )}
                </Accordion>);
            })
    )
};
export default QuestionnaireRenderer;