import React, { useEffect, useState } from 'react'
import { FullPageSpinner } from '../common/Spinner/FullPageSpinner'
import { useGetOrgLicensedProducts } from '../../hooks/ReactQueryHooks/useGetOrgLicensedProducts';
import { Button } from '../common/Button';
import { AddNote } from './AddNote';
import AddNoteIcon from '../../assets/images/add-note.png';
import CallIcon from "../../assets/images/call-border.png"
import labIcon from '../../assets/images/chemistry.png';
import medicalIcon from '../../assets/images/medical-prescription.png';
import diagnosticIcon from '../../assets/images/analytics.png';
import referralIcon from '../../assets/images/referral.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { failed, success } from '../common/Toastify';
import { useSelector } from 'react-redux';
import { getOrganization } from '../api/Organization';
import SelectFiled from '../common/textfield/SelectFiled';
import { QuestionnaireFormikObj } from './constant';
import { isAnswered } from '../../utils/questionnaireSupport';
import { useFormik } from 'formik';
import { createQuestionnaireResponse, editQuestionnaireResponse, getVideoVisitQuestionnaire } from '../api/Questionnaire';
import "./PhoneCallCreate.css"
import { endWaitingRoomMeeting } from '../apps/api/WaitingRoom';
import { axios } from '../../lib/axios';
import moment from 'moment';
import { updateAppointmentRequest } from '../apps/api/AppointmentRequests';
import { getSingleUser } from '../api/Individual';
import QuestionnaireRenderer from '../Admin/Questionnaire/QuestionnaireRenderer'


function PhoneCallCreate() {
    const { state } = useLocation();    
    const {encounterId, currentUser, practitionerName, appointmentData, practitionerId, patientId, patientName, proceedWith, requestedPeriod, period, questionnairInfo } = state
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('');
    const [isDisabled, setIsDisabled] = useState(false)
    const [orgnization, setOrganizations] = useState({})
    const [addNoteModal, setAddNoteModal] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false)
    const [textEditorKey, setTextEditorKey] = useState(0);
    const [questionnaireList, setQuestionnaireList] = useState([]);
    const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(questionnaireList.length && questionnaireList[0]?.id || '');
    const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
    const admin = userRoles?.includes('Super-Admin');
    const { isFetched, data: orgLicensedProducts } = useGetOrgLicensedProducts({})
    const isVirtualCare = admin ? false : orgLicensedProducts?.virtualCare
    const permissions = useSelector((state) => state?.auth?.user?.permissions);
    const user = useSelector((state) => state?.auth?.user);
    const [practionerData, setPractionerData] = useState({})

    const navigate = useNavigate()
    const formik = useFormik({
        ...QuestionnaireFormikObj,
        onSubmit: (values, { setErrors, setFieldValue }) => {
            return new Promise((resolve, reject) => {
                if (values?.questionResponse.length === 0 || values.questionResponse === undefined) {
                    reject("Invalid values");
                    return;
                }
    
                for (let i = 0; i < values?.questionResponse.length; i++) {
                    const item = values?.questionResponse[i];
                    if (item.required && !isAnswered(item)) {
                        setErrors({ isQuestionsError: `Question : ${item.question} is mandatory.` });
                        reject(`Question : ${item.question} is mandatory.`);
                        return;
                    }
                }
    
                setBtnLoading(true);
                setIsDisabled(false);
                let QRid = values.questionResponseId  || localStorage.getItem("questionResponseId")
                if(QRid){
                    editQuestionnaireResponse(QRid, { patientID: patientId, encounterId: encounterId, questionResponse: values?.questionResponse, appointmentId: "" , questionnaire: selectedQuestionnaire})
                    .then((res) => {
                        setFieldValue("questionResponseId", res.data.id)
                        success(res.message);
                        resolve(res);
                    })
                    .catch((res) => {
                        failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                        reject(res);
                    })
                    .finally(() => {
                        setBtnLoading(false);
                    });
                }else{
                    createQuestionnaireResponse({ patientID: patientId, encounterId: encounterId, questionResponse: values?.questionResponse, appointmentId: "", questionnaire: selectedQuestionnaire})
                    .then((res) => {
                        setFieldValue("questionResponseId", res.data.id)
                        localStorage.setItem("questionResponseId", res.data.id);
                        success(res.message);
                        resolve(res);
                    })
                    .catch((res) => {
                        failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                        reject(res);
                    })
                    .finally(() => {
                        setBtnLoading(false);
                    });
                }
               
            });
        },
    });
    
    useEffect(() => {
        const apiBody = {
          id: user?.["custom:unique_id"],
          type: "Practitioner",
        };
        getSingleUser(apiBody)
          .then((res) => {
            setPractionerData(res?.data)
          })
          .catch((res) => {
            console.log("error in image upload",res)
          })
      }, []);

    const handleServiceRequest = (requestType) => {
        window.open(`/app/encounter-service-request?encounterId=${state?.encounterId}&practitionerName=${practitionerName}&patientName=${patientName}&patientId=${patientId}&requestType=${requestType}&fromPatient=false`, '_blank')
    }
    const handleMedicationRequest = () => {
        window.open(`/app/encounter-medication-request?encounterId=${state?.encounterId}&practitionerName=${practitionerName}&practitionerId=${practitionerId}&patientName=${patientName}&patientId=${patientId}&fromPatient=false`, '_blank')
    }

    const handleSelectChange = (event) => {
        const selectedQuestionnaireId = event.target.value;        
        const questionResponse = questionnaireList
            .filter(item => item?.id === selectedQuestionnaireId)?.[0]?.item
            .map((item, index) => ({
                id: item?.linkId,
                question: item?.text,
                key:{index},
                questionType: item?.answerType,
                answerType: item?.answerType,
                answerOption: item?.answerOption?.map(x => ({value: ((x.value) ? x.value : x.text), name: (x.text) ? x.text: x.value, text: x.text, option: (x.value) ? x.value : x.text})),
                answer: item.answerOption?.map(item => ({ answer: "", id: item?.id, option: (item?.value) ? item.value : item?.text })),
                required: item?.required,
            })) || [];            
        formik.setFieldValue("questionResponse", questionResponse);
        // formik.setFieldValue("questionResponseId", "");
        setSelectedQuestionnaire(selectedQuestionnaireId);
        setTextEditorKey(prevKey => prevKey + 1);
    };

    const handleEndSession = async() => {
        localStorage.removeItem("questionResponseId");
        if(questionnaireList?.length > 0){
        if (formik.values?.questionResponse.length === 0 || formik.values.questionResponse === undefined) {
            failed("Complete questionnaire before proceeding");
            return;
        }
        for (let i = 0; i < formik.values?.questionResponse.length; i++) {
            const item = formik.values?.questionResponse[i];
            if (item.required && !isAnswered(item)) {
                formik.setErrors({ isQuestionsError: `Question : ${item.question} is mandatory.` });
                return;
            }
        }
    } else {
        if (formik.values?.questionResponse.length === 0 || formik.values.questionResponse === undefined) {
            failed("Complete questionnaire before proceeding");
            return;
        }
    }
        setIsLoading(true)
        setLoadingText("Ending Session")
        if(questionnaireList?.length > 0){
            formik.handleSubmit()
        }
        localStorage.removeItem("userJoined")
        state?.isWaitingRoom && endWaitingRoomMeeting({ id: state?.id, patientId: state?.patientId }).then((res) => success(res.message)).catch((err) => console.log(err))
        const appintmentTime = [{start: moment(requestedPeriod?.[0]?.start).utc().format("YYYY-MM-DDTHH:mm:ss[Z]"), end: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]")}]
        const encounterData = await axios.get(`encounter/${state?.encounterId}`);
        const encounterPeriod = encounterData?.data?.period
        if (appointmentData || appointmentData?.id) {
          return updateAppointmentRequest({encounterId: state?.encounterId, id: state?.appointmentData?.id, status: "completed", patientId, practitionerId, sessionToken: ""})
            .then(async(res) => {
                if(res.status === true){
                    return axios.put(`encounter/${state?.encounterId}`, {
                        status: "finished",
                        proceedWith,
                        requestedPeriod: appintmentTime,
                        // questionnaireResponse: questionnairInfo,
                        intakeQuestions: questionnairInfo,
                        period: {start: encounterPeriod?.start, end: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]") }
                      }).then((res) => {
                        setIsLoading(false);
                        setLoadingText(false);
                        navigate("/app/encounter-details",{ state: { encounterId: encounterId, patientId: patientId, practionerData, practitionerId: practitionerId } })
                      })
                      .catch((err) =>console.log(err?.response?.data?.message || err?.response?.data?.error || err?.message));
                }
             
            }).catch((err) =>console.log(err?.response?.data?.message ||err?.response?.data?.error || err?.message));
        } else {
          await axios.put(`encounter/${encounterId}`, {
              status: "finished",
              proceedWith,
              requestedPeriod: appintmentTime,
            //   questionnaireResponse: questionnairInfo, 
                intakeQuestions: questionnairInfo,
              period: {start: encounterPeriod?.start, end: moment().format("YYYY-MM-DDTHH:mm:ss[Z]") }
            })
            .then((res) => {
              setIsLoading(false);
              setLoadingText(false);
              navigate("/app/encounter-details",{ state: { encounterId: encounterId, patientId: patientId, practionerData, practitionerId: practitionerId } })
            })
            .catch((err) =>
              console.log(
                err?.response?.data?.message ||
                  err?.response?.data?.error ||
                  err?.message
              )
            );
        }
    }

    useEffect(() => {
        getOrganization(adminOrgs?.[0]?.id)
            .then((res) => {
                setOrganizations(res.data);
            })
            .catch((res) => {
                failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
            });
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getOrganization(adminOrgs?.[0]?.id);
                const assignedQuestionnaire = res?.data?.assignedQuestionnaire || {};
                const videoVisitIds = assignedQuestionnaire["video-visit"] || [];
                if('video-visit' in assignedQuestionnaire && videoVisitIds.length > 0) {
                    const VVRes = await getVideoVisitQuestionnaire(JSON.stringify(videoVisitIds))
                    const modifiedData = VVRes?.data?.map(item => ({
                        ...item,
                        name: item.title,
                        value: item.id
                    }));
                    setQuestionnaireList(modifiedData);
                }
                
            } catch (error) {
                console.error('Error fetching organization data:', error);
            }
        };
        fetchData();
    }, []);

    const handleView = () => {
        const baseUrl = window.location.origin;
        const patientIdView = patientId;
        const patientNameView = patientName;
        const url = `${baseUrl}/app/patients-details?patientId=${patientIdView}&patientName=${patientNameView}`;
        window.open(url, "_blank");
    };

    const handleCancelButton = () => {
            if(formik?.values?.questionResponseId) {
                localStorage.removeItem("questionResponseId");
                editQuestionnaireResponse(formik?.values?.questionResponseId, { encounterId: "remove"})
                .then((res) => {
                    setSelectedQuestionnaire(""); 
                    formik.resetForm()
                })
                .catch((error) => {
                    console.log("error---",error);
                })
            } else {
                localStorage.removeItem("questionResponseId")
                setSelectedQuestionnaire(""); 
                formik.resetForm()
            }  
        }

  return (
    <div>
        <section className="common-listing">
            {isLoading && <FullPageSpinner loadingText={loadingText} />}
            <div style={{display: "flex" , justifyContent: "space-between"}}>
                <div className="head-wrap">
                        <h1>
                            <span className="big-head" style={{fontSize: "24px", textTransform: "capitalize"}}>{currentUser?.patient?.display}</span>
                        </h1>
                        <div style={{marginLeft : "10px"}}>
                            <img src={CallIcon} alt='call'/>
                            <span>{questionnairInfo?.callerNumber}</span>
                        </div>
                </div>
                <div className="heading-wrap mb-3">
                    {state?.isWaitingRoom ? null : <div className="right-wrap">
                    <Button className="custom-btn" title="Open Dashboard in a new tab" variant="primary" onClick={() => handleView()}>Patient Dashboard</Button>
                    <Button className="custom-btn ms-2" title="Add Note" variant="primary" style={{ padding: "0" }} onClick={() => setAddNoteModal(true)}>+<img src={AddNoteIcon} alt="Prev Arrow" /></Button>
                        <AddNote
                            show={addNoteModal}
                            encounterId={state?.encounterId}
                            patientName={currentUser?.patient?.display}
                            onHide={() => setAddNoteModal(false)}
                        />
                        {isVirtualCare && permissions.includes("Create Prescriptions") && (<Button className="custom-btn ms-2" title="Create Prescription" variant="primary" style={{ padding: "0" }} onClick={()=>  orgnization?.sft?.enabled && (orgnization?.sft?.individualDelivery || orgnization?.sft?.locationDelivery) ?  handleMedicationRequest() : (orgnization?.sft?.enabled && (!orgnization?.sft?.individualDelivery && !orgnization?.sft?.locationDelivery)) ? failed("Please enable SFT delivery options.") : failed("Please enable SFT to send document.")}><img src={medicalIcon} alt="Prev Arrow" /></Button>)}
                        {isVirtualCare && permissions.includes("Create Lab Requisitions") && (<Button className="custom-btn ms-2" title="Add Lab service request" variant="primary" style={{ padding: "0" }} onClick={() => orgnization?.sft?.enabled && (orgnization?.sft?.individualDelivery || orgnization?.sft?.locationDelivery)? handleServiceRequest("Lab") : (orgnization?.sft?.enabled && (!orgnization?.sft?.individualDelivery && !orgnization?.sft?.locationDelivery)) ? failed("Please enable SFT delivery options.") : failed("Please enable SFT to send document.") }><img src={labIcon} alt="Prev Arrow" /></Button>)}
                        {isVirtualCare && permissions.includes("Create Diagnostic Imaging Requests") && (<Button className="custom-btn ms-2" title="Add Diagnostic service request" variant="primary" style={{ padding: "0" }} onClick={() => orgnization?.sft?.enabled && (orgnization?.sft?.individualDelivery || orgnization?.sft?.locationDelivery)? handleServiceRequest("Diagnostic") : (orgnization?.sft?.enabled && (!orgnization?.sft?.individualDelivery && !orgnization?.sft?.locationDelivery)) ? failed("Please enable SFT delivery options.") : failed("Please enable SFT to send document.")}><img src={diagnosticIcon} alt="Prev Arrow" /></Button>)}
                        {isVirtualCare && permissions.includes("Create Service Referrals") && (<Button className="custom-btn ms-2" title="Add Referral service request" variant="primary" style={{ padding: "0" }} onClick={() => orgnization?.sft?.enabled && (orgnization?.sft?.individualDelivery || orgnization?.sft?.locationDelivery)? handleServiceRequest("Referral") : (orgnization?.sft?.enabled && (!orgnization?.sft?.individualDelivery && !orgnization?.sft?.locationDelivery)) ? failed("Please enable SFT delivery options.") : failed("Please enable SFT to send document.")}><img src={referralIcon} alt="Prev Arrow" /></Button>)}
                        <Button className="custom-btn ms-2" title={"Proceed"} variant="primary" onClick={handleEndSession}>Proceed</Button>
                    </div>}
                </div>
            </div>
            <div className="questionnair-wrapper">
                <div className="heading-wrap h-change" style={{ display: 'flex', alignItems: 'center' }}>
                    <h1 className="big-head">Questionnaire</h1>
                </div>
                <hr />
                <div className="chat-sidebar" >
                    <div className="individual-chart-wrap">
                        <div className="chat-body common-form" style={{ paddingTop: "1rem", justifyContent: "center", alignItems: "center" }}>
                            <SelectFiled
                                keyField={"questionnaire"}
                                label={"Select Questionnaire"}
                                formik={formik}
                                readOnly={isDisabled}
                                options={questionnaireList}
                                onChange={handleSelectChange}
                                value={selectedQuestionnaire} 
                            />
                            <hr />
                            <QuestionnaireRenderer formik={formik} questionResponse={formik.values.questionResponse} parentIndex={textEditorKey} />
                            {/* {formik.errors.isQuestionsError && (
                                <div className="error-message" style={{ color: "red" }}>{formik.errors.isQuestionsError}</div>
                            )} */}
                        </div>

                        <div className="msg-footer">
                            <div className="btn-wrap" style={{ display: 'flex', justifyContent: "end", gap: "1rem" }}>
                                <Button type="submit" isLoading={btnLoading} onClick={formik.handleSubmit}>Save</Button>
                                <Button onClick={handleCancelButton} variant="secondary" title="Cancel">Cancel</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default PhoneCallCreate
