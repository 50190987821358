import React, { useEffect, useState } from "react";
import { getOrganizationChild } from "../../api/Organization";

export const FirstParentSelector = ({ selectedId, parentId, handleSelect }) => {
    const [organizations, setOrganizations] = useState([]);

    useEffect(() => {
        getOrganizationChild(parentId).then((res) => setOrganizations(res?.data)).catch((err) => console.log(err));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="field-wrap">
            <label htmlFor={parentId}>
                Parent (Optional)
            </label>
            <select id={parentId} onChange={(e) => handleSelect(e.target.value)} value={`${selectedId}`}>
                <option value={""}>
                    Select
                </option>
                {organizations?.map(({ id, name }) => (
                    <option value={id} key={id}>
                        {name}
                    </option>
                ))}
            </select>
        </div>
    );
};
