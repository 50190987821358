import { axios } from "../../../lib/axios";

//Get Notification Api Call
export const getNotification = () => {
    return axios.get(`/notification/get?id`);
};

//Create Notification Api Call
export const createNotification = (payload) => {
    return axios.post(`notification`, payload);
};

//Update Notification Api Call
export const updateNotification = (payload) => {
    return axios.put(`notification`, payload);
};

// Update notification count
export const updateNotificationCount = (payload) => {
    return axios.put(`patients/notification`, payload);
};

// Get Notification Content
export const getNotificationContent = ({ orgId, eventId }) => {
    return axios.get(`notification/content?orgId=${orgId}&eventId=${eventId}&isWeb=true`);
};
