import { useQuery } from "react-query";
import { axios } from "../../lib/axios";
import { onError } from "./onError"
import { useSelector } from "react-redux";

export function useGetOrgSftQuery({ onSuccess = () => { }, orgId = null, ...props }) {
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)

    const fetch = () => {
        return axios.get(`organization/${orgId || adminOrgs[0].id}`);
    };

    const query = useQuery(
        ["getOrgLicensedProducts"],
        fetch,
        {
            // select: (res) => res?.data?.sft,
            select: (res) => res?.data,
            onSuccess,
            onError,
            refetchOnWindowFocus: false,
            ...props,
        }
    );

    return { ...query };
}
