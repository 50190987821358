import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import './manage-group.css';
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from 'react-select';
import { decryptData } from "../../EncryptDecrypt";
import { Button } from "../../common/Button";
import { editGroup } from "../../api/Group";
import { enrollPatientsIntoRpm } from "../../api/Individual";
import { failed, success } from "../../common/Toastify";
import { useNavigate } from "react-router-dom";

function AddUser({ modalShow, refreshTable, handleShow, alreadyAddedUserIds, usersList, programId, selectedUsers, groupIsDefault, groupUsersType, existingGroupMembers }) {
    const [userOption, setUserOption] = useState([])
    const [btnLoading, setBtnLoading] = useState(false)
    const navigate = useNavigate();
    
    useEffect(() => {
        const users = usersList?.filter((item) => groupIsDefault ? alreadyAddedUserIds?.includes(item.id) : !alreadyAddedUserIds?.includes(item.id)).map((item) => ({
            id: item.id,
            label: decryptData(item.name[0]?.text?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')),
            value: item.id,
            type: item.type,
        }))
        setUserOption(users);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalShow])

    const formik = useFormik({
        initialValues: {
            member: [],
        },
        validationSchema: Yup.object({
            member: Yup.array().min(1, "Required field"),
        }),
        onSubmit: (values) => {
            setBtnLoading(true);
            const member =  [...values?.member].map((item) => {
                    return  item.id;

                });
            enrollPatientsIntoRpm(member, programId)
                .then((res) => {
                    if (res.status === true) { 
                        refreshTable()
                        success(res.message);
                        handleShow(); 
                    } else {
                        failed(res.error);
                    }
                })
                .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                .finally(() => setBtnLoading(false));  
        }, 
    });
    return (
        <Modal
            show={modalShow}
            onHide={handleShow}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered className="custom-dialog"
            backdropClassName
            backdrop={'static'}
        >
            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">
                    Add Users
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="common-form" onSubmit={formik.handleSubmit}>
                    <div className="field-wrap">
                        <label>Select Users</label>
                        <Select
                            onChange={(data) => formik.setFieldValue("member", data)}
                            isMulti
                            name="id"
                            options={userOption}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            autoFocus
                        />
                        <div>{formik?.touched["userIds"] && formik?.errors["userIds"] ? <div className="error-text">{formik?.errors["userIds"]}</div> : null}</div>
                    </div>
                    <div className="btn-wrap" style={{ display: 'flex' }}>
                        <Button className="margin-10" variant="secondary" onClick={handleShow} title="Cancel">Cancel</Button>
                        <Button type="submit" isLoading={btnLoading}>Add Users</Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}


export default AddUser
