import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { axios } from "../../lib/axios";
import { onError } from "./onError"

export function useGetNotificationsQuery({onSuccess, callMe, ...props}) {
    const userId = useSelector((state) => state?.auth?.user?.["custom:unique_id"]);

    const fetchOrganizations = () => {
        if (callMe) {
            return axios.get(`patients/notification/${userId}`);
        }
        return {}
    };

    const query = useQuery(
        "Notifications",
        () => fetchOrganizations(),
        {
            select: (data) => data,
            onSuccess,
            refetchInterval: 8000,
            onError,
            ...props
        }
    );

    return { ...query };
}
