import { useQuery } from "react-query";
import { axios } from "../../lib/axios";
import { onError } from "./onError";

export function useGetPermissions({ orgId, onSuccess }) {
    const fetch = () => {
        return orgId ? axios.get(`permission?orgId=${orgId}`) : axios.get(`permission`)
    };

    const query = useQuery(
        orgId ? ["permissions", orgId] : "permissions",
        () => fetch(),
        {
            select: (data) => {
                const permissions = data.data.map(permission => ({ id: permission.id, module: permission.module, type: permission.type, selected: false }));
                const admin = permissions.filter(permission => permission.type === "Administrative");
                const clinical = permissions.filter(permission => permission.type === "Clinical");
                const practitioner = permissions.filter(permission => permission.type === "Practitioner");
                return { admin, clinical, practitioner };
            },
            onError,
            onSuccess: onSuccess,
        }
    );

    return { ...query };
}
