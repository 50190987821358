import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import { Button } from "../../common/Button";
import { failed, success } from "../../common/Toastify";
import TextInput from "../../common/textfield/TextInput";
import CountrySelect from "../../common/textfield/CountrySelect";
import RegionSelect from "../../common/textfield/RegionSelect";
import { FormikObj } from "./Constants";
import TextArea from "../../common/textfield/TextArea";
import { editHcn } from "../../api/HcnAuthority";

// organization detail dialog
export function EditHcn({ modalShow, handleShow, refreshTable }) {
    const [btnLoading, setBtnLoading] = useState(false);
    const [country, setCountry] = useState("");
    const [region, setRegion] = useState("");

    const formik = useFormik({
        ...FormikObj,
        onSubmit: (values) => {
            values.state = `CA-${values.state}`
            setBtnLoading(true);
            editHcn(values)
                .then((res) => {
                    if (res.status === true) {
                        success(res.message);
                        handleShow();
                        refreshTable();
                    } else {
                        failed(res.error);
                    }
                })
                .catch((res) => {
                    failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                })
                .finally(() => setBtnLoading(false));
        },
    });

    useEffect(() => {
        if (modalShow?.id) {
            formik.setFieldValue("country", "CA");
            formik.setFieldValue("state", modalShow?.state?.split("-")[1]);
            formik.setFieldValue("authority", modalShow?.authority || "");
            formik.setFieldValue("id", modalShow?.id || "");
            formik.setFieldValue("display", modalShow?.display.trim() || "");
            formik.setFieldValue("description", modalShow?.description || "");
            setCountry("CA");
            setRegion(modalShow?.state?.split("-")[1]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalShow]);

    return (
        <Modal
            backdropClassName
            backdrop={'static'}
            size="lg" show={modalShow} onHide={handleShow}
            aria-labelledby="contained-modal-title-vcenter"
            centered className="custom-dialog">
            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">HCN Authority Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="common-form border-fields" onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col>
                            <CountrySelect country={country} setCountry={setCountry} keyField={"country"} label={"Country"} required formik={formik} whitelist={["CA"]} />
                        </Col>
                        <Col>
                            <RegionSelect
                                defaultOptionLabel={formik?.values?.country === "US" ? "Select State" : "Select Province"}
                                country={country} setRegion={setRegion} region={region} keyField={"state"}
                                label={formik?.values?.country === "US" ? "State" : "Province"}
                                formik={formik} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextInput keyField={"authority"} label={"Authority"} formik={formik} placeholder={"Authority"} />
                        </Col>
                        <Col>
                            <TextInput keyField={"display"} label={"Display"} formik={formik} placeholder={"Display"} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextArea keyField={"description"} label={"Description"} formik={formik} placeholder={"Description..."} />
                        </Col>
                        <Col></Col>
                    </Row>
                    <div className="btn-wrap">
                        <Button onClick={handleShow} variant="secondary" title="Cancel">Cancel</Button>
                        <Button type="submit" isLoading={btnLoading}>Update</Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}
