import React, { useState, useEffect } from "react";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import { verifyPatientsIdentity, getOrgInfo } from "../api/AuthService"
import { failed, success } from "../common/Toastify";
import TextInput from "../common/textfield/TextInput";
import { Button } from "../common/Button";
import './sft.css'

function SFT() { 
    const [fileLink, setfileLink] = useState(null);
    const { search } = useLocation()
    const [btnLoading, setBtnLoading] = useState(false)
    const [showError, setshowError] = useState(false);
    const sftID = new URLSearchParams(search).get("DownloadId");
    const orgId = new URLSearchParams(search).get("orgId");
    const [showModal, setShowModal] = useState(true);
    const [supportModal, setSupportModal] = useState(false);
    const [privacyStatment, setPrivacyStatment] = useState(null);
    const [supportStatment, setsupportStatment] = useState(null);   
    
    useEffect(() => {
        
        getOrgInfo({ orgId }).then((result) => {
            if (result.status === true) {
                if (result.data?.privacyStatement && result.data?.supportStatement) {
                    setPrivacyStatment(result.data?.privacyStatement);
                    setsupportStatment(result.data?.supportStatement);
                } else {
                    setfileLink(null);
                    setshowError(true);
                }
            }
        }).finally();
    }, [orgId]);

    const formik = useFormik({
        initialValues: {
            identity: ""
        },
        validationSchema: yup.object({
            identity: yup.string().required('Identity Challenge is Required').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'), 
        }),
        onSubmit: (values) => {
            setBtnLoading(true);  
            verifyPatientsIdentity({ sftID: sftID, identity_challenge: values.identity, orgId}).then((result) => { 
                if (result.status === true) {
                    setfileLink(result.data);
                    success(result.message);
                } else { 
                    setfileLink(null);
                    failed(result.message);
                }
            }).finally(() => setBtnLoading(false));
        },
    });

    const handleClick = () => { 
        setfileLink(null);
      };

    const handleClickModel = () => {
        setShowModal(false);  
        setfileLink(null);   
    };

    const handleSupportClick = () => {
        setSupportModal(true);
    };
    const handleCloseSupportModal = () => {
        setSupportModal(false);
    };

    return (
        <div>
        
            {privacyStatment ? (
                <>
                <Modal show={showModal} centered backdrop="static" size="lg" >
                    <Modal.Header>
                        <Modal.Title>Privacy statement</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {privacyStatment}
                    </Modal.Body>
                    <Modal.Footer> 
                        <Button variant="primary" onClick={handleClickModel}>
                            I Accept
                        </Button> 
                    </Modal.Footer>
                </Modal>
                </>
            ) : (
                showError ? (
                <>
                    <Modal show={showModal} centered backdrop="static" size="lg" >
                        <Modal.Header>
                            <Modal.Title>General Error</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <>There was a issue retreeiving your file. Please  contact your support.</>
                        </Modal.Body>
                        <Modal.Footer> 
                            <Button variant="primary" onClick={handleClickModel}>
                                I Accept
                            </Button> 
                        </Modal.Footer>
                    </Modal>
                </>
                ): null
            )}
             
            
            
        <h4>Secure Document Delivery</h4>
        {fileLink === null ? (
        <form className="custom-form-sft" onSubmit={formik.handleSubmit}>
            
            <TextInput   keyField={"identity"} label={"IDENTITY CHALLENGE"} formik={formik} placeholder={"Identity Challenge Code"} />  
            <div className="btn-wrap" style={{ textAlign: "center"  }}>
                <Button type="submit" className="custom-btn" isLoading={btnLoading}>Validate</Button>
            </div> 
        </form>
        ) : ( 
            <div>
                <h4>Validate Successful!</h4>
                <p><b>File Type: </b> {fileLink.type} </p> 
                <div className="download-button" >
                    <a className="custom-btn custom-btn"  href={fileLink.url} download={true}>Download</a>
                    <a className="custom-btn custom-btn white-btn add-patient"  onClick={handleClick}  >Cancel</a>
                </div>
            </div> 
        )}
        
        <div class="page-link">
            <a title="Privacy Policy" href="#" onClick={handleSupportClick} style={{float:"right"}}>Support </a>
        </div>

         
            { 
                supportStatment ? (
                    <>
                        <Modal show={supportModal} onHide={handleCloseSupportModal} centered size="lg">
                            <Modal.Header>
                                <Modal.Title>Technical Support</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {supportStatment}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseSupportModal}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </>
                ) : (
                    <>
                        <Modal show={supportModal} onHide={handleCloseSupportModal} centered size="lg">
                            <Modal.Header>
                                <Modal.Title>General Error</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <>There was a issue retreeiving your file. Please  contact your support.</>
                            </Modal.Body>
                            <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseSupportModal}>
                                        Close
                                    </Button>
                            </Modal.Footer>
                        </Modal>
                    </>
                )
            }
             
            
        </div>
            
    );
}

export default SFT;