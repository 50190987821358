import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ApexChart from 'react-apexcharts';
const ChartRPM = ({ modalShow, handleShow,  }) => {
    const [btnLoading, setBtnLoading] = useState(false);
    const [conditionsState, setconditionsState] = useState([]);
    const [RPMdevices, setRPMDevices] = useState([]);
    const [deviceError, setDeviceError] = useState(false);
    const [groups, setGroups] = useState([]);


    return (
        <Modal
            show={modalShow}
            onHide={handleShow}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="custom-dialog"
            backdropClassName
            backdrop={'static'}
        >
            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">
                    Health Chart
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ApexChart id="mychart" options={modalShow?.options} series={modalShow?.series} type="line" width="100%" height="500px" />
            </Modal.Body>
        </Modal >
    );
}

export default ChartRPM;
