import React from 'react'
import moment from 'moment';
import EditIcon from "../../../assets/images/edit.png";
import ViewNotes from "../../../assets/images/ViewNote.png";
import AddNotes from "../../../assets/images/AddNote.png";
import { Link, Tooltip } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { ViewDate } from '../../../utils/DateSupport';

const FamilyHistoryListing = ({data, handleViewNote, handleShow, patientData, practionerData, setNewNoteData}) => {
  const navigate = useNavigate()
  return (
    <tr key={data?.id}>
      <td>{data?.condition?.[0]?.name}</td>
      <td>{data?.condition?.[0]?.onsetAge?.value + " " + "Years"}</td>
      <td>{data?.relationship?.coding?.[0]?.display}</td>
      <td>{data?.recorder?.display.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</td>
      <td>{ViewDate(data?.createdAt)}</td>
      {!data?.note && 
      <td>
        <Tooltip title={"Add Note"}>
            <Link
              to=""
              variant="primary"
              className="success-btn"
              onClick={() => handleShow(data, false)}
              style={{cursor: "pointer"}}
            >
              <img src={AddNotes} alt="Add Note" style={{width : "28px"}}/>
            </Link>
          </Tooltip>
      </td>
      }
      {data?.note?.length > 0 &&
      <td>
        <Tooltip title={"View Note"}>
            <Link
              to=""
              variant="primary"
              className="success-btn"
              onClick={() => {
                setNewNoteData(null)
                handleViewNote(data, false);
              }}
              style={{cursor: "pointer"}}
            >
              <img src={ViewNotes} alt="View Note" style={{width : "28px"}}/>
            </Link>
          </Tooltip>
      </td>
      }
      <td>
        <div className="action-wrap" style={{display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
          <Tooltip title={"Edit"}>
            <Link
              to=""
              variant="primary"
              className="success-btn"
              onClick={() => navigate("/app/edit-familyHistory", {state: {familyHistoryData: data, patientData: {patientId : patientData?.id, patientName: patientData?.name?.[0]?.text}, practionerData:{id: practionerData?.["custom:unique_id"] , name: practionerData?.name?.[0]?.text}}})}
            >
              <img src={EditIcon} alt="Edit" />
            </Link>
          </Tooltip>
        </div>
      </td>
    </tr>
  )
}

export default FamilyHistoryListing