import { axios } from "../../../lib/axios";

export const createScheduleCategory = (data) => {
    let newData = {
        name: data?.name,
        timeSlot: {
            unit: "minutes",
            value: data?.timeSlot ? data?.timeSlot : 15
        },
        public: (data?.public) ? data.public : false,
        description: data?.description,
        orgId: data.organization,
        isRootLevel: data?.isRootLevel
    }
    return axios.post("serviceCategory", newData)
}

export const editScheduleCategory = (data) => {
    let newData = {
        name: data?.name,
        timeSlot: {
            unit: "minutes",
            value: data?.timeSlot
        },
        public: (data?.public) ? data.public : false,
        description: data?.description
    }
    return axios.put(`serviceCategory/${data?.id}`, newData)
}
//Delete Schedule Api Call
export const deleteScheduleCategory = (id) => {
    return axios.delete(`serviceCategory/${id}`)
}