import React, { useState } from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Checkbox from "../../common/textfield/Checkbox";
import { Form } from "react-bootstrap";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

export function ProductFeatures({ formik, rpmSwitch, virtualCareSwitch, callCenterSwitch, onRpmSwitchChange, onVirtualCareSwitchChange, onCallCenterSwitchChange }) {

    return (
        <>
            <Row>
                <Col>
                    <h5 style={{ color: "rgba(18, 31, 72, 1)" }}>Product Features</h5>
                </Col>
            </Row>

            <Row>
                <Col >
                    <FormControlLabel
                        control={
                            <>
                                <span style={{ marginLeft: '10px', marginRight: '8px' }}>RPM</span>
                                <span style={{ marginRight: '88px' }}>&nbsp;&nbsp;</span>
                                <Switch
                                    checked={rpmSwitch}
                                    onChange={onRpmSwitchChange} // Use the callback function
                                    color="primary"
                                />
                            </>
                        }
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormControlLabel   
                        control={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ marginLeft: '10px', marginRight: '8px' }}>Virtual Care</span>
                                 <span style={{ marginRight: '25px' }}>&nbsp;&nbsp;</span>
                                <Switch
                                    checked={virtualCareSwitch}
                                    onChange={onVirtualCareSwitchChange}
                                    color="primary"
                                />
                            </div>
                        }
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormControlLabel
                        control={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ marginLeft: '10px', marginRight: '8px' }}>Call Center</span>
                                <span style={{ marginRight: '30px' }}>&nbsp;&nbsp;</span>
                                <Switch
                                    checked={callCenterSwitch}
                                    onChange={onCallCenterSwitchChange}
                                    color="primary"
                                />
                            </div>
                        }
                    />
                </Col>
            </Row>
        </>
    );
}
