import React, { useEffect, useState } from "react";
import TextInput from "../../common/textfield/TextInput";
import { Col, Row, Table } from "react-bootstrap";
import { DateSelectorWithoutFormik } from "../../common/textfield/DateSelector";
import { patientListTitle, patientSelectionFormikObject } from "./Constant";
import { Skeleton, TableCell } from "@mui/material";
import { Button } from "../../common/Button";
import { useFormik } from "formik";
import { failed } from "../../common/Toastify";
import { searchPatient } from "../../apps/api/Patient";
import CustomPagination from "../../common/components/Pagination/CustomPagination";
import moment from "moment";
import * as momentTimezone from "moment-timezone";
import { ViewDate } from "../../../utils/DateSupport";

const PatientSelection = ({ orgId, formikInBoundCall, handleAddPatient, dashboard =false, patient, resetPatient }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [isRegion, setIsRegion] = useState(false);
  const [addPatientButEnable, setAddPatientButEnable] = useState(false);
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const getValueQuestionnair = (tagValue) => formikInBoundCall?.values?.questionResponse?.find(item => item.tag === tagValue)?.answer?.[0]?.answer
  const getValueQuestionnairRadio = (tagValue) => formikInBoundCall?.values?.questionResponse.find((obj)=> obj?.tag === tagValue)?.answer?.find((val)=>val?.answer === true)?.option

  const formik = useFormik({
    initialValues: {
      org_id:"",
      firstName: getValueQuestionnairRadio("SELFCALLING") === "Yes" ?  localStorage.getItem("firstName")  : "",
      lastName: getValueQuestionnairRadio("SELFCALLING") === "Yes" ? localStorage.getItem("lastName") : "",
      birthDate: "",
      healthCardNumber: ""
    },
    ...patientSelectionFormikObject,
    onSubmit: (values) => {  
      setBtnLoading(true);
      setIsLoading(true);
      const convertDateFormat = (originalDate) => {
        const dateObject = new Date(originalDate);
        const day = String(dateObject.getDate()).padStart(2, '0');
        const month = String(dateObject.getMonth() + 1).padStart(2, '0');
        const year = dateObject.getFullYear();
        const formattedDate = `${month}-${day}-${year}`;
        return formattedDate;
      };
      const timeZoneGuess = moment.tz.guess();
      const timeZoneParts = timeZoneGuess.split('/');
      let region = timeZoneParts[0];     
      let dateData = "";
      if(values.birthDate == "" || values.birthDate == NaN-NaN-NaN  || values.birthDate == "NaN-NaN-NaN" ){
        dateData = "";
      }else{  
        if( ( region == "America" || region == "Canada" ) && isRegion == true  ){
          let dateDataNew = convertDateFormat(values.birthDate );;
          let currentDate = new Date(dateDataNew);
          currentDate.setDate(currentDate.getDate() + 1);
           dateData = convertDateFormat(currentDate);  
           setIsRegion(false);
        }else{
          dateData = convertDateFormat(values.birthDate );;
        }        
      } 
      values.birthDate = dateData;  
      searchPatient({ ...values, org_id: orgId })
        .then((res) => {
          if (res.status === true) {
            setFilterData(res?.result);
            setAddPatientButEnable(true)
            setBtnLoading(false);
            setIsLoading(false);
          } else {
            setBtnLoading(false);
            setIsLoading(false);
            failed(res.error);
          }
        })
        .catch((res) => {
          failed(
            res?.response?.data?.message ||
              res?.response?.data?.error ||
              res.message
          );
        })
        .finally(() => setBtnLoading(false));
    },
  });

  // useEffect(() => {
  //   if(getValueQuestionnairRadio("SELFCALLING") == "Yes"){
  //     if(!formik.values.firstName && !firstNamePopulated){
  //       formik.setFieldValue("firstName", getValueQuestionnair("CALLER"));
  //       setFirstNamePopulated(true);
  //     }
  //     if(!formik.values.lastName && !lastNamePopulated){
  //       formik.setFieldValue("lastName", formikInBoundCall?.values?.questionResponse?.find(item => item.tag === "CALLER")?.answer?.[1]?.answer);
  //       setLastNamePopulated(true);
  //     }
  //   }
  // }, [getValueQuestionnair, getValueQuestionnairRadio, firstNamePopulated, lastNamePopulated])

 const handleDateChange = (val) => { 
  const isValidDate = moment(val, 'MMM-DD-YYYY').isValid()
  setIsRegion(true);
    if(val == null || val == ""){
      formik.setFieldValue("birthDate", val || ""  );
    }else{
      if(isValidDate){
        formik.setFieldValue("birthDate", val.format("YYYY-MM-DD") );
      }else{
        formik.setFieldError('birthDate', 'Invalid date format')
      }
    }    
  };

  const handlePatientClick = (data) => {
    const baseUrl = window.location.origin;
    const patientId = data?.id;
    const patientName = data?.name?.[0]?.text;
    const url = `${baseUrl}/app/patients-details?patientId=${patientId}&patientName=${patientName}`;
    window.open(url, "_blank");
  };

  const handleSearchPatient = ()=>{
    resetPatient(null)
    formik.handleSubmit()
  }


 let formattedDate;
 let hcnValue
  useEffect(() => {
    formik.resetForm()
    if(patient){
      const dateParts = patient?.birthDate.split("-");
       formattedDate = dateParts[2] + "-" + dateParts[0] + "-" + dateParts[1];
      const hcnIdentifier = patient?.identifier?.find(identifier => identifier?.type === "HCN");
       hcnValue = hcnIdentifier ? hcnIdentifier?.value : '-';
      formikInBoundCall.setFieldValue("patientId", patient?.id); 
      formikInBoundCall.setFieldValue("patientName", patient?.name?.[0]?.text)
    }
  }, [patient])

  return (
    <>
        <Row>
          <Col sm={dashboard && 2}>
            <TextInput
              keyField={"firstName"}
              label={"First Name"}
              formik={formik}
              placeholder={"First Name"}
              hideRequired={true}
            />
          </Col>
          <Col sm={dashboard && 2}>
            <TextInput
              keyField={"lastName"}
              label={"Last Name"}
              formik={formik}
              placeholder={"Last Name"}
              hideRequired={true}
            />
          </Col>
          <Col sm={dashboard && 2}>
            <DateSelectorWithoutFormik
              formik={formik}
              keyField={'birthDate'}
              label="DOB"
              value={moment(formik.values.birthDate).format("YYYY-MM-DD")}
              handleChange={handleDateChange}
              isError={
                formik?.touched?.end && formik?.errors?.end
                  ? formik?.errors?.end
                  : ""
              }
              maxDate={moment()}
              hideRequired={true}
            />
          </Col>
          <Col sm={dashboard && 2}>
            <TextInput
              keyField={"healthCardNumber"}
              label={"HCN"}
              formik={formik}
              placeholder={"HCN"}
              hideRequired={true}
            />
          </Col>
        </Row>
        {formik?.errors["allSearchFieldsEmpty"] ? (<div style={{fontSize : "14px", color: "#dc3545", paddingTop: "5px", marginTop: "-20px", marginBottom: "20px"}}>{formik?.errors["allSearchFieldsEmpty"]} </div>) : null}
        <div style={{display: "flex", justifyContent: "center"}}>
          <div style={{marginRight: "25px"}}>
            <Col>
              <Button
                type="button"
                isLoading={btnLoading}
                onClick={handleSearchPatient}
                style={{fontSize : "14px", padding : "8px 10px"}}
              >
                Search Patient
              </Button>
            </Col>
            </div>
            <div>
            {!dashboard && addPatientButEnable && <Col>
              <Button variant="secondary" onClick={handleAddPatient} style={{fontSize : "14px", padding : "8px 10px"}}>
                + Add New Patient
              </Button>
            </Col>}
            </div>
        </div>
      <div className="table-wrap">
        <Table responsive>
          <thead>
            <tr>
              <th></th>
              {patientListTitle?.map((title) => (
                <th>{title}</th>
              ))}
            </tr>
          </thead>
          {patient ? <tbody>
            <tr>
              <td>
                {!dashboard && <input  style={{cursor: "pointer"}} type='radio' name={`radio`} value={patient?.id} checked={true} onChange={(e)=> {formikInBoundCall.setFieldValue("patientId", e.target.value); formikInBoundCall.setFieldValue("patientName", patient?.name?.[0]?.text)}}/>}
              </td>
              <td><span style={{cursor: "pointer"}} onClick={() => handlePatientClick(patient)}>{patient?.name?.[0]?.text?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</span></td>
              <td>{patient?.gender}</td>
              <td>{ViewDate(patient?.birthDate)}</td>
              <td>{hcnValue}</td>
              <td>{patient?.address?.[0]?.line?.[0]}</td>
              <td>{patient?.address?.[0]?.city}</td>
              <td>{patient?.address?.[0]?.state || patient?.address?.[0]?.province}</td>
            </tr>
          </tbody> : 
          <tbody>
            {isLoading ? (
              [1, 2, 3, 4, 5, 6, 7, 8]?.map((val) => (
                <tr key={val}>
                  <td>
                    <Skeleton animation="wave" />
                  </td>
                  <td>
                    <Skeleton animation="wave" />
                  </td>
                  <td>
                    <Skeleton animation="wave" />
                  </td>
                  <td>
                    <Skeleton animation="wave" />
                  </td>
                  <td>
                    <Skeleton animation="wave" />
                  </td>
                  <td>
                    <Skeleton animation="wave" />
                  </td>
                  <td>
                    <Skeleton animation="wave" />
                  </td>
                  <td>
                    <Skeleton animation="wave" />
                  </td>
                </tr>
              ))
            ) : filterData?.length > 0 ? (
              filterData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((data, index) => { 
                  var formattedDateString = data?.birthDate ?  ViewDate(data?.birthDate) : "-";
                  // const hcnIdentifier = data?.identifier?.find(identifier => identifier?.type === "HCN");
                  // const hcnValue = hcnIdentifier ? hcnIdentifier?.value : '-'; 
                  const hcnIdentifier = data?.identifier?.find(identifier => identifier?.type === "HCN") || {};
                  const hcnValue = hcnIdentifier && hcnIdentifier?.value  ? hcnIdentifier?.value : '-'; 
                  return (
                    <>
                      <tr key={index}>
                        <td>
                          {!dashboard && <input style={{cursor: "pointer"}} type='radio' name={`radio`} value={data?.id} onChange={(e)=> {formikInBoundCall.setFieldValue("patientId", e.target.value); formikInBoundCall.setFieldValue("patientName", data?.name?.[0]?.text)}}/>}
                        </td>
                        <td><span style={{cursor: "pointer"}} onClick={() => handlePatientClick(data)}>{data?.name?.[0]?.text?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</span></td>
                        <td style={{textTransform: "capitalize"}}>{data?.gender}</td>
                        <td>{formattedDateString}</td>
                        <td>{hcnValue}</td>
                        <td>{data?.address?.[0]?.line?.[0]}</td>
                        <td style={{textTransform: "capitalize"}}>{data?.address?.[0]?.city}</td>
                        <td>{data?.address?.[0]?.state || data?.address?.[0]?.province}</td>
                      </tr>
                    </>
                  );
                })
            ) : (
              <tr>
                <TableCell colSpan="8" style={{ textAlign: "center" }}>
                  No Records Found
                </TableCell>
              </tr>
            )}
          </tbody>}
        </Table>
        {filterData?.length > 0 ? (
          <div>
            <CustomPagination
              tableData={filterData}
              totalRows={filterData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              handlePage={(pageNo) => setPage(pageNo)}
            />
          </div>
        ) : null}
      </div>
      <div>
        {formikInBoundCall?.touched["patientId"] && formikInBoundCall?.errors["patientId"] ? (
          <div className="error-text">
            {formikInBoundCall?.errors["patientId"]}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default PatientSelection;
