import { axios } from "../../../lib/axios";

export const createDocument = ({ document, practitionerId, requestType, patientId, orgId, medicationRequestId = "", serviceRequestId = "", encounterId = "", orgName , category, documentName }) => {
    const newPayload = {
        contentType: "pdf",
        category: requestType,
        description: "",
        practitionerId,
        patientId,
        documentName,
        category,
        orgId,
        orgName,
        status: "active",
        medicationRequestId,
        serviceRequestId,
        encounterId,
        document: {
            data: document,
            extension: "pdf"
        }
    }
    return axios.post(`/document`, newPayload);
};