import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useMedia } from 'react-use';
import moment from 'moment';
import { ViewDate } from '../../../utils/DateSupport';

const FullCalendarComp = ({ eventsList = [], ...props }) => {
    const isWide = useMedia('(min-width: 480px)');
    return (
        <FullCalendar
            headerToolbar={isWide
                ? {
                    start: 'prev,next today',
                    center: 'title',
                    end: 'dayGridMonth,timeGridWeek,timeGridDay'
                }
                : {
                    start: 'prev,next today',
                    center: false,
                    end: 'title'
                }
            }
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            events={eventsList?.length > 0 ?  eventsList?.map((obj)=> {
                return{
                    ...obj,
                    title : ViewDate(obj.date)
                }
            }) : []}
            locale='en' // Set locale directly
            eventTimeFormat={{
                hour: 'numeric',
                minute: '2-digit',
                omitZeroMinute: false,
                meridiem: false,
                hour12: false,
            }}
            {...props}

        //     locales={[
        //     {
        //         locale: 'en',
        //         eventTimeFormat: {
        //             hour: 'numeric',
        //             minute: '2-digit',
        //             omitZeroMinute: false,
        //             meridiem: false,
        //             hour12: false,
        //         },
        //     },
        // ]}
        //     {...props}
        />
    );
};

export default FullCalendarComp;
