export const adminMenu = (permissions, admin, isRPMEnable, isVirtualCareEnable, disabled_RPM_VirtualCare, isWaitingRoom) => {
    const menuItems =

    [
        {
            name: "Organizations",
            URL: "/app/manage-organization",
            enabled: permissions?.includes('Manage Organization') || admin,
        },
        {
            name: "Roles",
            URL: "/app/manage-roles",
            enabled: permissions?.includes('Manage Roles') || admin,
        },
        {
            name: "Users",
            children: [
                {
                    name: "Create",
                    URL: "/app/create-individual",
                    enabled: permissions?.includes('Manage Individuals') || admin
                },
                {
                    name: "Manage",
                    URL: "/app/manage-individuals",
                    enabled: permissions?.includes('Manage Individuals') || admin
                },
            ],
            enabled: permissions?.includes('Manage Individuals') || admin,
        },
        {
            name: "Programs",
            children: [
                {
                    name: "Create Program",
                    URL: "/app/create-program",
                    enabled: isRPMEnable ? permissions?.includes('Manage Program') || admin : false,
                },
                {
                    name: "Program List",
                    URL: "/app/manage-program",
                    enabled: isRPMEnable ? permissions?.includes('Manage Program') || admin : false,
                },
            ],
            enabled: isRPMEnable ? permissions?.includes('Manage Program') || admin : false,
        },
        {
            name: "Questionnaire",
            children: [
                {
                    name: "Question Categories",
                    URL: "/app/manage-category",
                    enabled: disabled_RPM_VirtualCare ? false : permissions?.includes('Manage Questionnaire') || admin,
                },
                {
                    name: "Questions",
                    URL: "/app/manage-questions",
                    enabled: disabled_RPM_VirtualCare ? false : permissions?.includes('Manage Questionnaire') || admin,
                },
                {
                    name: "Questionnaires",
                    URL: "/app/manage-questionnaire",
                    enabled: disabled_RPM_VirtualCare ? false : permissions?.includes('Manage Questionnaire') || admin,
                },
            ],
            enabled: disabled_RPM_VirtualCare ? false : permissions?.includes('Manage Questionnaire') || admin,
        },
        {
            name: "Surveys",
            children: [
                {
                    name: "Surveys",
                    URL: "/app/manage-surveys",
                    enabled: isVirtualCareEnable,
                },
                {
                    name: "Survey Results",
                    URL: "/app/manage-surveys-results",
                    enabled: isVirtualCareEnable && (admin || permissions?.includes('Satisfaction Survey Results')),
                }                
            ],
            enabled: isVirtualCareEnable,
        },
        {
            name: "Notifications",
            URL: "/app/manage-notifications",
            enabled: permissions?.includes('Manage Notifications') || admin,
        },
        {
            name: "HCN Authority",
            URL: "/app/hcn-list",
            enabled: admin,
        },
        {
            name: "Schedule Category",
            URL: "/app/manage-schedule-category",
            enabled: permissions?.includes('Manage Schedule Category') || admin,
        },
        {
            name: "Locations",
            URL: "/app/manage-clinic-locations",
            enabled: permissions?.includes('Manage Locations') || admin,
        },
        {
            name: "Groups",
            URL: "/app/manage-groups",
            enabled: permissions?.includes('Manage Groups') || admin,
        },
        {
            name: "Queue",
            URL: "/app/manage-queue",
            enabled: isWaitingRoom ? (permissions?.includes('Manage Queue') || admin) : null,
        },
        {
            name: "Documents",
            URL: "/app/documents",
            enabled: isVirtualCareEnable && (permissions?.includes('Manage Documents') || admin),
        },
        {
            name: "SFT Audit",
            URL: "/app/manage-sft",
            enabled: isVirtualCareEnable && (permissions?.includes('Manage SFT') || admin),
        },
    ].filter(item => {
        if (item.children) {
            item.children = item.children.filter(child => child.enabled === true);
            return item.children.length > 0;
        }
        return item.enabled === true;
});

    return menuItems;
};

export const appsMenu = (permissions, isRPMEnable) => {
    const menuItems =
    [
        {
            name: "Patients Assigned",
            URL: "/app/patients-assigned",
            enabled: permissions?.includes('Patients Assigned'),
        },
        {
            name: "Appointments",
            URL: "/app/appointment",
            enabled: permissions?.includes("Appointment"),
        },
        {
            name: "Providers Schedule & Appointments",
            URL: "/app/providers-schedule-appointments",
            enabled: permissions?.includes("Manage Provider Schedule & Appointments"),
        },
        //{
        //    name: "Schedule",
        //    children: [
        //        {
        //            name: "My Schedule",
        //            URL: "/app/my-schedule",
        //            enabled: permissions?.includes('Manage Schedule'),
        //        },
        //        {
        //            name: "Past Schedule",
        //            URL: "/app/manage-schedule",
        //            enabled: permissions?.includes('Manage Schedule'),
        //        },
        //    ],
        //    enabled: permissions?.includes('Manage Schedule'),
        //},
        {
            name: "Threshold Group",
            URL: "/app/threshold-groups",
            enabled: isRPMEnable ? (permissions?.includes('Manage Threshold Groups') || permissions?.includes('View Threshold Groups')) : false,
        },
        // {
        //     name: "Waiting Room",
        //     URL: "/app/waiting-room",
        //     enabled: permissions?.includes('Waiting Room Patients') || permissions?.includes('Waiting Room Enrollment'),
        // },
    ].filter(item => {
        if (item.children) {
            item.children = item.children.filter(child => child.enabled === true);
            return item.children.length > 0;
        }
        return item.enabled === true;
    });

    return menuItems;
};