import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextInput from "../../common/textfield/TextInput";
import { useEffect, useState } from "react";
import { failed, success } from "../../common/Toastify";
import { Button } from "../../common/Button";
import { useGetPermissions } from "../../../hooks/ReactQueryHooks/useGetPermissions";
import { editRole } from "../../api/ManageRoles";
import TextArea from "../../common/textfield/TextArea";
import { FormikObjEdit } from "./constant";
import { Skeleton } from "@mui/material";

export function EditModal({ modalShow, handleShow, refetchRoles, isReadOnly }) {

    const [btnLoading, setBtnLoading] = useState(false);
    const [noSelectedRoles, setNoSelectedRoles] = useState(false);
    const [Admin_roles, setAdmin_roles] = useState([]);
    const [Clinical_roles, setClinical_roles] = useState([]);

    const formik = useFormik({
        ...FormikObjEdit,
        onSubmit: (values) => {
            const permissions = [...Admin_roles, ...Clinical_roles].filter((role) => role.selected);
            if (permissions.length > 0) {
                noSelectedRoles && setNoSelectedRoles(false)
                setBtnLoading(true);
                editRole({ ...values, permissions, organization: modalShow?.organization })
                    .then((res) => {
                        if (res.status === true) {
                            refetchRoles();
                            handleShow();
                            success(res.message);
                        } else {
                            failed(res.error);
                        }
                    })
                    .catch((res) => {
                        failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                    }).finally((done) => setBtnLoading(false))
            } else setNoSelectedRoles(true);
        },
    });

    const onSuccess = ({ admin, clinical }) => {
        setAdmin_roles(admin?.map((item) => modalShow.permissions?.some((permission) => permission.id === item.id) ? { ...item, selected: true } : item));
        setClinical_roles(clinical?.map((item) => modalShow.permissions?.some((permission) => permission.id === item.id) ? { ...item, selected: true } : item));
    }

    const { isLoading, isFetching } = useGetPermissions({ orgId: modalShow.organization?.reference?.split("/")[1], onSuccess });

    useEffect(() => {
        if (modalShow?.id) {
            formik.setFieldValue("id", modalShow?.id || "");
            formik.setFieldValue("name", modalShow?.display || "");
            formik.setFieldValue("description", modalShow?.definition || "");
            formik.setFieldValue("organization", modalShow?.organization?.display || "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalShow?.id]);

    const handleAdminRole = (e) => {
        if (!isReadOnly) {
            const { name, checked } = e.target;
            const newAdmin_roles = Admin_roles.map(data => data.id === name ? { ...data, selected: checked } : data);
            setAdmin_roles(newAdmin_roles);
        }
    }
    const handleClinicRole = (e) => {
        if (!isReadOnly) {
            const { name, checked } = e.target;
            const newClinical_roles = Clinical_roles.map(data => data.id === name ? { ...data, selected: checked } : data);
            setClinical_roles(newClinical_roles);
        }
    }

    return (
        <Modal
            backdropClassName
            backdrop={'static'}
            size="lg" show={modalShow} onHide={handleShow}
            aria-labelledby="contained-modal-title-vcenter"
            centered className="custom-dialog">
            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">
                    {isReadOnly ? "View" : "Edit"} Role
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="common-form border-fields" onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col>
                            <TextInput keyField={"name"} label={"Role Name"} formik={formik} placeholder={"Role Name"} readOnly={isReadOnly} />
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextArea
                                keyField={"description"}
                                label={"Description"}
                                formik={formik}
                                placeholder={"Description..."}
                                readOnly={isReadOnly}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextInput readOnly keyField={"organization"} label={"Select Organization"} formik={formik} />
                        </Col>
                        <Col></Col>
                    </Row>

                    <div className="check-select-wrap">
                        <h5>Administrative Permissions</h5>
                        <div className="form-outer-grid">
                            {isLoading || isFetching ? (
                                [1, 2, 3, 4]?.map((val) => (
                                    <div className="field-wrap" style={{ margin: 0 }} key={val}>
                                        <label className="custom-checkbox" style={{ margin: 0 }}>
                                            <Skeleton width={100} animation="wave" />
                                            <input style={{ width: "auto" }} type="checkbox" name={"id"} checked={false} disabled />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                ))) :
                                Admin_roles?.map(({ module, selected, id }) => (
                                    <div className="field-wrap" style={{ margin: 0 }} key={id}>
                                        <label className="custom-checkbox" style={{ margin: 0 }}>{module}
                                            <input disabled={isReadOnly} style={{ width: "auto" }} type="checkbox" name={id} checked={selected} onChange={handleAdminRole} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                ))}
                        </div>
                    </div>

                    <div className="check-select-wrap">
                        <h5>Clinical Permissions</h5>
                        <div className="form-outer-grid">
                            {isLoading || isFetching ? (
                                [1, 2, 3, 4]?.map((val) => (
                                    <div className="field-wrap" style={{ margin: 0 }} key={val}>
                                        <label className="custom-checkbox" style={{ margin: 0 }}>
                                            <Skeleton width={100} animation="wave" />
                                            <input style={{ width: "auto" }} type="checkbox" name={"id"} checked={false} disabled />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                ))) :
                                Clinical_roles?.map(({ module, selected, id }) => (
                                    <div className="field-wrap" style={{ margin: 0 }} key={id}>
                                        <label className="custom-checkbox" style={{ margin: 0 }}>{module}
                                            <input disabled={isReadOnly} style={{ width: "auto" }} type="checkbox" name={id} checked={selected} onChange={handleClinicRole} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div style={{ color: "red" }}>{noSelectedRoles ? "Please select atleast one Role!" : null}</div>
                    {isReadOnly
                        ? null
                        : <div className="btn-wrap" style={{ display: "flex" }}>
                            <Button onClick={handleShow} variant="secondary" title="Cancel">
                                Cancel
                            </Button>
                            <Button type="submit" isLoading={btnLoading}>
                                Update
                            </Button>
                        </div>
                    }
                </form>
            </Modal.Body>
        </Modal>
    );
}