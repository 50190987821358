import React, { useState, useEffect } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import { Button } from "../../../common/Button";
import { FormikDiseaseObj } from "../Constants";
import TextInput from "../../../common/textfield/TextInput";
import TextArea from "../../../common/textfield/TextArea";
import { editDisease } from "../../api/Patient";
import { failed, success } from "../../../common/Toastify";


function EditDisease({ patientId, modalShow, handleShow, refreshDisease }) {
    const [btnLoading, setBtnLoading] = useState(false);
    const formik = useFormik({
        ...FormikDiseaseObj,
        onSubmit: (values) => {
            setBtnLoading(true);
            let payload = {
                diseaseId: modalShow?.id,
                diseaseName: values?.diseaseName,
                diseaseDesc: values?.diseaseDesc,
                patientId: patientId
            }
            editDisease(payload).then((res) => {
                handleShow();
                success(res.message);
                refreshDisease(patientId);
            }).catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
            .finally(() => setBtnLoading(false));
        },
    });

    useEffect(() => {
        if(modalShow?.id){
            formik.setFieldValue("diseaseName", modalShow?.diseaseName || "");
            formik.setFieldValue("diseaseDesc", modalShow?.diseaseDesc || "");
        }
    }, [modalShow])



    return (
        <Modal
            show={modalShow}
            onHide={handleShow}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdropClassName
            backdrop={'static'}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Edit Disease
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="common-form border-fields" onSubmit={formik.handleSubmit} >           
                    <Row>
                        <Col>
                            <TextInput
                                keyField={"diseaseName"}
                                label={"Disease Name"}
                                formik={formik}
                                placeholder={"Disease Name"}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextArea
                                keyField={"diseaseDesc"}
                                label={"Desease Description"}
                                formik={formik}
                                placeholder={"Write about the disease, symptoms..."}
                            />
                        </Col>
                    </Row>
                    <div className="btn-wrap">
                        <Button onClick={() => { handleShow() }} variant="secondary" title="Cancel">
                            Cancel
                        </Button>
                        <Button type="submit" isLoading={btnLoading}>
                            Update Disease
                        </Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default EditDisease;