import { axios } from "../../../lib/axios";

export const getSFTFullList = ({ id = null, orgId = null, type=null, isSuperAdmin = false, isAllUsersAllOrgs = false }) => { 
    if (isSuperAdmin) {
        return axios.get(`/securefiletransfer`)
    } else if (isAllUsersAllOrgs && type) {
        return axios.get(`/securefiletransfer?type=${type}`)
    } else if (orgId && type) {
        return axios.get(`/securefiletransfer?orgId=${orgId}&type=${type}`)
    } else if (orgId) {
        return axios.get(`/securefiletransfer?orgId=${orgId}`)
    } else if (type) {
        return axios.get(`/securefiletransfer?type=${type}`)
    } 
}; 