import { Outlet } from 'react-router-dom';
import FonemedLogo from "../assets/images/FoneMed-Logo.jpg";
import Verify from '../components/Verify';
import SetPassword from '../components/ResetPassword/SetPassword';
import ForgotPassword from '../components/ForgetPassword/forgot-password';
import ResetPassword from '../components/ResetPassword/ResetPassword';
import VerifiedUserSetPass from '../components/ResetPassword/VerifiedUserSetPass';

export const AuthAppLayout = ({ children }) => {
    return (
        <section className="login-wrapper">
            <div className="left-wrap"></div>
            <div className="right-wrap">
                <div className="form-wrap">
                    <a href="#as" className="logo" title="Fonemed A Healthier Tomorrow">
                        <img src={FonemedLogo} alt="Fonemed A Healthier Tomorrow" />
                    </a>
                    {children}
                    <Outlet />
                </div>
            </div>
        </section>
    );
};

export const publicRoutes = [
    {
        path: '/auth',
        element: <AuthAppLayout />,
        children: [
            { path: 'setpassword', element: <SetPassword /> },
            { path: 'verifiedUserSetPass', element: <VerifiedUserSetPass /> },
            { path: 'verifyMFA', element: <Verify /> },
            { path: 'forgotpassword', element: <ForgotPassword isExpired={false} /> },
            { path: 'resetpassword', element: <ResetPassword /> },
            { path: 'reset-temporary-pass', element: <ForgotPassword isExpired={true} /> },
            //{ path: '*', element: <Navigate to="/" /> },
        ],
    },
];
