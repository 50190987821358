import moment from "moment";
import * as Yup from "yup";

export const RouteOptions = [
    { value: "Buccal", name: "Buccal" },
    { value: "Enteral", name: "Enteral" },
    { value: "Inhalation", name: "Inhalation" },
    { value: "Infused", name: "Infused" },
    { value: "Intramuscular", name: "Intramuscular" },
    { value: "Intrathecal", name: "Intrathecal" },
    { value: "Intravenous", name: "Intravenous" },
    { value: "Nasal", name: "Nasal" },
    { value: "Otic", name: "Otic" },
    { value: "Opthalmic", name: "Opthalmic" },
    { value: "Oral", name: "Oral" },
    { value: "Rectal", name: "Rectal" },
    { value: "Subcutaneous", name: "Subcutaneous" },
    { value: "Sublingual", name: "Sublingual" },
    { value: "Topical", name: "Topical" },
    { value: "Transdermal", name: "Transdermal" },
    { value: "Vaginal", name: "Vaginal" },
    { value: "Other", name: "Other" },
]

export const FrequencyOptions = [
    { value: "Daily", name: "Daily" },
    { value: "Every Other Day", name: "Every Other Day" },
    { value: "2 Times per Day", name: "2 Times per Day" },
    { value: "3 Times per Day", name: "3 Times per Day" },
    { value: "4 Times per Day", name: "4 Times per Day" },
    { value: "Once per Week", name: "Once per Week" },
    { value: "Once per Month", name: "Once per Month" },
    { value: "Every 2hrs", name: "Every 2hrs" },
    { value: "Every 3hrs", name: "Every 3hrs" },
    { value: "Every 4hrs", name: "Every 4hrs" },
    { value: "Every 6hrs", name: "Every 6hrs" },
    { value: "Every 8hrs", name: "Every 8hrs" },
    { value: "Every 12hrs", name: "Every 12hrs" },
    { value: "PRN", name: "PRN" },
    { value: "Other", name: "Other" },
]

export const routeCodes = {
    Buccal: "47625008",
    Enteral: "417985001",
    Inhalation: "421056002",
    Infused: "424494006",
    Intramuscular: "78421000",
    Intrathecal: "72607000",
    Intravenous: "418114005",
    Nasal: "46713006",
    Opthalmic: "54485002",
    Oral: "26643006",
    Otic: "10547007",
    Rectal: "12063002",
    Subcutaneous: "34206005",
    Sublingual: "37839007",
    Topical: "6064005",
    Transdermal: "45890007",
    Vaginal: "432020007",
}

export const medicationObj = {
    medicationName: "",
    dosage: "",
    units: "",
    routeCode: "",
    route: "",
    otherRoute: "",
    frequency: "",
    otherFrequency: "",
    duration: "",
    quantity: "",
    directions: "",
    numberOfRefills: "",
    reasonForPrescription: "",
    instructions: "",
    allowSubstitutions: false,
}

export const FormikObj = {
    initialValues: {
        encounterId: "",
        documentId:"",
        note: "",
        patientId: "",
        patientName: "",
        practitionerId: "",
        practitionerName: "",
        licenseNumber: "LICE4545",
    },
     validationSchema: Yup.object({
         encounterId: Yup.string().required('Required field.').matches(/^$|^\S+.*/, 'Only blankspace is not valid.'),
         documentId: Yup.string().required('Required field.').matches(/^$|^\S+.*/, 'Only blankspace is not valid.'),
     }),
}

export const locaitonModalFormikObj = {
    initialValues: {
        recepient: "",
        locationId: "",
        locationFax_number: "",
    },
    validationSchema: Yup.object({
        recepient: Yup.string().required('Required field.').matches(/^$|^\S+.*/, 'Only blankspace is not valid.'),
        locationId: Yup.string().when("recepient", (recepient, schema) => {
            return recepient === "location"
                ? schema.required('Required field.').matches(/^$|^\S+.*/, 'Only blankspace is not valid.')
                : schema
        })
    }),
}

export const locaitonModalRecipientOptions = [
    { name: "Patient", value: "patient" },
    { name: "Location", value: "location" }
]

export const formFields = [
    {
        fieldType: "TextInput",
        keyField: "medicationName",
        label: "Medication Name",
    },
    {
        fieldType: "TextInput",
        keyField: "dosage",
        label: "Dosage",
    },
    {
        fieldType: "TextInput",
        keyField: "units",
        label: "Units",
    },
    {
        fieldType: "SelectFiled",
        keyField: "route",
        label: "Route",
        options: RouteOptions,
    },
    {
        fieldType: "TextInput",
        keyField: "otherRoute",
        label: "Other Route",
    },
    {
        fieldType: "SelectFiled",
        keyField: "frequency",
        label: "Frequency",
        options: FrequencyOptions,
    },
    {
        fieldType: "TextInput",
        keyField: "otherFrequency",
        label: "Other Frequency",
    },
    {
        fieldType: "TextInput",
        keyField: "duration",
        label: "Duration",
    },
    {
        fieldType: "TextInput",
        keyField: "quantity",
        label: "Quantity",
    },
    {
        fieldType: "TextInput",
        keyField: "directions",
        label: "Directions",
        hideRequired: true,
    },
    {
        fieldType: "TextInput",
        keyField: "numberOfRefills",
        label: "Number of refills",
    },
    {
        fieldType: "TextInput",
        keyField: "reasonForPrescription",
        label: "Reason for Prescription",
        hideRequired: true,
    }
]