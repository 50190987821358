import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import TextInput from "../../common/textfield/TextInput";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../common/Button";
import { failed, success } from "../../common/Toastify";
import TextArea from "../../common/textfield/TextArea";
import SelectFiled from "../../common/textfield/SelectFiled";
import { Tooltip } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { DateSelectorWithoutFormik } from "../../common/textfield/DateSelector";
import { FormikObjectFamilyHistory, familyHistoryOptions } from "./Constant";
import { editFamilyHistory } from "../api/MedicalRequest";
import moment from "moment";
import { calculateAgeCode, calculateAgeUnit, safeAgeString } from "../../../utils/DateSupport";

const EditFamilyHistory = () =>  {
    const navigate = useNavigate();
    const location = useLocation();
    const [btnLoading, setBtnLoading] = useState(false);
    const { patientData, practionerData, familyHistoryData } = location?.state

    const formik = useFormik({
        ...FormikObjectFamilyHistory,
        onSubmit: async(values) => {
                setBtnLoading(true);
                editFamilyHistory({
                  id: familyHistoryData?.id,
                  ...values,
                  // ageValue: Number(safeAgeString(values?.date).split(",")[0].split(" ")[0]),
                  // unit: calculateAgeUnit(safeAgeString(values?.date).split(",")[0].split(" ")[1]),
                  // code: calculateAgeCode(safeAgeString(values?.date).split(",")[0].split(" ")[1]),
                  // onsetString: safeAgeString(values?.date),
                  relationship: values.relation !== "EXT" ? familyHistoryOptions?.find((data) => data?.value === values?.relation).name : values?.relationship,
                  practitionerId: practionerData?.id,
                  practitionerName: practionerData?.name?.[0]?.text || practionerData?.name
                })
                  .then((res) => {
                    if (res.status === true) {
                      navigate("/app/patients-details", {
                        state: {
                          id: patientData?.patientId,
                          name: patientData?.patientName,
                        },
                      });
                      success(res.message);
                    } else {
                      failed(res.error);
                    }
                  })
                  .catch((res) => {
                    failed(
                      res?.response?.data?.message ||
                        res?.response?.data?.error ||
                        res.message
                    );
                  })
                  .finally(() => setBtnLoading(false));
        },
    });

    // const handleDateChange = (val) => {
    //   formik.setFieldValue("date", val.format("YYYY-MM-DD"));
    // }

 
    const handleFamilyHistoryChange = (event) => {
       formik.setFieldValue("relation", event?.target?.value )
       formik.setFieldValue("relationshipCode", event?.target?.value)
    }

    useEffect(() => {
       localStorage.setItem("activeTableKey", "ninth");
        formik.setFieldValue("conditionName", familyHistoryData?.condition?.[0]?.name)
        // formik.setFieldValue("note",  familyHistoryData?.note ? familyHistoryData?.note?.[0]?.text : "")
        formik.setFieldValue("ageValue", familyHistoryData?.condition?.[0]?.onsetAge?.value)
        formik.setFieldValue("relationship", familyHistoryData?.relationship?.coding?.[0]?.code === "EXT" ? familyHistoryData?.relationship?.coding?.[0]?.display : "")
        formik.setFieldValue("relation", familyHistoryData?.relationship?.coding?.[0]?.code)
        formik.setFieldValue("relationshipCode", familyHistoryData?.relationship?.coding?.[0]?.code)
    }, [])

    const handleAge = (event) => {
      let key = event.key;
      let regex = new RegExp("^[0-9]+$");
      if (!regex.test(key)) {
        event.preventDefault();
        return false;
      }
    };

  return (
    <section className="common-listing">
      <div className="heading-wrap mb-3">
        <Tooltip title="Go back">
          <div
            style={{ marginRight: "1rem", cursor: "pointer" }}
            onClick={() => navigate(-1)}
          >
            <ArrowBack />
          </div>
        </Tooltip>
      </div>
      <div className="custom-card p-4">
        <form
          className="common-form border-fields"
          onSubmit={formik.handleSubmit}
        >
          <Row>
            <Col>
              <TextInput
                keyField={"conditionName"}
                label={"Family History"}
                formik={formik}
                placeholder={"Family History"}
                disabled={true}
              />
            </Col>
            <Col>
              {/* <DateSelectorWithoutFormik
               label="Age Of Onset"
               value={moment(formik?.values?.date)}
               formik={formik}
               handleChange={handleDateChange}
               maxDate={moment()}
              /> */}
              <TextInput
                keyField={"ageValue"}
                label={"Age Of Onset"}
                formik={formik}
                type={"text"}
                onKeyPress={handleAge}
                placeholder={"Age Of Onset"}
              />
              {/* <span style={{marginTop: "-25px", display: "block" }}>{safeAgeString(formik?.values?.date)}</span> */}
            </Col>
          </Row>
          <Row>
          <Col>
              <SelectFiled
                keyField={"relation"}
                label={"Relationship"}
                formik={formik}
                options={familyHistoryOptions}
                onChange={handleFamilyHistoryChange}
              />
            </Col>
            <Col>
                {formik.values.relationshipCode === "EXT" ? (<TextInput
                    keyField={"relationship"}
                    label={"Other"}
                    formik={formik}
                    placeholder={"Other"}
                />) : null}
            </Col>
          </Row>
          <div className="btn-wrap" style={{ display: "flex" }}>
            <Button
              onClick={() => !btnLoading && navigate("/app/patients-details", {state:{id: patientData?.patientId, name: patientData?.patientName}})}
              variant="secondary"
              title="Cancel"
              disabled={btnLoading}
            >
              Cancel
            </Button>
            <Button type="submit" isLoading={btnLoading}>
              Submit
            </Button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default EditFamilyHistory