/** The TEXTS object stores language-specific texts using a nested structure. 
 * In this case, only English texts are defined, with a single key-value pair for the dosage text.
 * the texts function
 * Returns the value associated with the 'locale' and 'key' in the 'TEXTS' object
 * the text is retrieved from the TEXTS object using the provided locale and key. 
 * The ?. optional chaining operator is used to safely access nested properties. 
 * If the text is not found (resulting in null or undefined), an error is thrown
 * @param {string} key - the dosage to return
 * @param {string} locale - the language used
 * @param {string} result - the dosage to add to the array of dosages, if not null or undefined
 * @returns {array} An array of dosages
 * 
*/
export const TEXTS = {
    en: {
        dosage: 'dosage'
    }
};

export const texts = (key, locale = 'en', result) => {
  result = TEXTS[locale]?.[key]
    if(result === null || result === undefined) {
      throw new Error(`"Text not found for locale: ${locale} and key: ${key}"`);
    }
    return result;
};

/** dosageSet
 * 
 * Returns an array of dosage names based on the argument given
 * @param {string} type - The type of dosages to return
 * @returns {array} An array of dosage names
*/
export const dosageSet = (dosage) => {
    return {
        'standard': [
            "Daily",
            "QOD",
            "BID",
            "TID",
            "QID",
            "Qweekly",
            "Qmonthly",
            "Q2h",
            "Q3h",
            "Q4h",
            "Q6h",
            "Q8h",
            "Q12h",
        ]
    }[dosage];
};

/** dosagePhrased
 * dosagePhrased takes in a dosage and locale and returns the phrase for that dosage
 * @param {string} dosage - the dosage to be phrased
 * @param {string} locale - the locale to phrase the dosage in
 * @return {string} - the phrase for the dosage in the specified locale
*/
export const dosagePhrased = (dosage, locale='en') => {
    return {
        'en': {
            "Daily": "Daily - Once a day",
            "QOD": "QOD - Once every other day",
            "BID": "BID - 2 times a day",
            "TID": "TID - 3 times a day",
            "QID": "QID - 4 times a day",
            "Qweekly": "Qweekly - Once a week",
            "Qmonthly": "Qmonthly - Once a month",
            "Q2h": "Q2h - Every 2 hrs",
            "Q3h": "Q3h - Every 3 hrs",
            "Q4h": "Q4h - Every 4 hrs",
            "Q6h": "Q6h - Every 6 hrs",
            "Q8h": "Q8h - Every 8 hrs",
            "Q12h": "Q12h - Every 12 hrs",  
        }
    }[locale][dosage];
};

/** dosageToInterval
 * dosageToInterval takes in a dosage, and returns the number of intervals for that dosage
 * @param {string} dosage - the dosage to be returned as an interval integer
 * @return {integer} - the number of intervals of a dosage as an integer
 */
export const dosageToInterval = (dosage) => {
    switch (dosage) {
      case 'Daily':
        return 1;
      case 'QOD':
        return 1;
      case 'BID':
        return 2;
      case 'TID':
        return 3;
      case 'QID':
        return 4;
      case 'Qweekly':
        return 1;
      case 'Qmonthly':
        return 1;
      case 'Q2h':
        return 12;
      case 'Q3h':
        return 8;
      case 'Q4h':
        return 6;
      case 'Q6h':
        return 4;
      case 'Q8h':
        return 3;
      case 'Q12h':
        return 2;
      default:
        return 0;
    }
} 