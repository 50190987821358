import React, { useState } from "react";
import SearchBar from "../../common/components/SearchBar";
import CustomTable from "../../common/table/CustomTable";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import { OrganizationDataFields, OrganizationHeadCells } from "./Constants";
import  ViewSurveyResult  from "./ViewSurveyResult";
import { useSurveyReslutQuery } from "../../../hooks/ReactQueryHooks/useSurveyReslutQuery";

function SurveysResultsList() {    
    const onSuccess = (data) => {
        setOrganizations(data?.map((org) => org = { ...org, "value": org.id }))
    }
    const { isLoading, isFetching } = useSurveyReslutQuery({ onSuccess })
    const { getConfirmation } = useConfirmDialog();
    const [page, setPage] = React.useState(0);
    const [modalShow, setModalShow] = React.useState(false);
    const [filterData, setFilterData] = React.useState([]);
    const [organizations, setOrganizations] = React.useState([]);
    const [deleting, setDeleting] = useState(false);
    const handleShow = (data) => { setModalShow(modalShow ? false : data) };  
   
    const handleFilterData = (data) => {
        setFilterData(data)
    }       
    const selectedUserAction = ({ action, data }) => {
        switch (action) {
            case 'view':
                handleShow(data)
                break;
          
            default:
                return null;
        }
    };

    // Display first character of patient's first and last name in capital letter.
    if(organizations) {
        organizations?.map((patient) => {
            const modifiedName = patient?.patientName?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
            patient.patientName = modifiedName;
        });
    }

    return (
        <section className="common-listing">
            {modalShow ? <ViewSurveyResult
                modalShow={modalShow}
                // refreshTable={refetch}
                handleShow={handleShow}
            /> : null}
            <div className="heading-wrap h-change">
                {/* <SearchBar page={page} setPage={setPage} list={organizations} filterData={handleFilterData} /> */}
            </div>

            <CustomTable
                tableData={organizations || []}
                headCells={OrganizationHeadCells}
                dataFields={OrganizationDataFields}
                selectedUserAction={selectedUserAction}
                rowOptions={false}
                loading={isLoading}
                fetching={isFetching}
                deletingItem={deleting}
                canView = {true}
                canEdit = {false}
                canDelete = {false}
                page={page}
                setPage={setPage}
                sortingHeadCellIndex={1}
                sortingHeadOrder={'desc'}
            />
        </section>
    );
}

export default SurveysResultsList;