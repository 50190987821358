import { useQuery } from "react-query";
import { axios } from "../../lib/axios";
import { onError } from "./onError"

export function useGetMedicalConditionData({ onSuccess, patientId }) {

    const fetch = () => {
       return axios.get(`/condition?patientId=${patientId}`)
    }

    const query = useQuery(
        ["condition", patientId],
        () => fetch(),
        {
            select: (data) => data.data,
            onSuccess,
            staleTime : Infinity,
            onError
        }
    );

    return { ...query, refetchCondition: query.refetch };
}

export function useGetMedicalSurgeryData({ onSuccess, patientId }) {

    const fetch = () => {
       return axios.get(`/procedure?patientId=${patientId}`)
    }

    const query = useQuery(
        ["procedure", patientId],
        () => fetch(),
        {
            select: (data) => data.data,
            onSuccess,
            staleTime : Infinity,
            onError,
        }

    );

    return { ...query, refetchSurgery: query.refetch };
}

export function useGetFamilyHistory({ onSuccess, patientId }) {

    const fetch = () => {
       return axios.get(`/familyhistory?patientId=${patientId}`)
    }

    const query = useQuery(
        ["familyHistory", patientId],
        () => fetch(),
        {
            select: (data) => data.data,
            onSuccess,
            staleTime : Infinity,
            onError,
        }

    );

    return { ...query, refetchfamilyHistory: query.refetch };
}

export function useGetObservation({ onSuccess, patientId }) {

    const fetch = () => {
       return axios.get(`/observation?patientId=${patientId}&isSocialHistory=true`)
    }

    const query = useQuery(
        ["observation", patientId],
        () => fetch(),
        {
            select: (data) => data.data,
            onSuccess,
            staleTime : Infinity,
            onError,
        }

    );

    return { ...query, refetchObservation: query.refetch };
}